import React from 'react';
import ModalFilterInput from '../../render/renderFilter/ModalFilterInput'

import FilterTemplate from '../../render/renderFilter/FilterTemplate'
import pageUtil from '../../component/pageComponent/pageUtil'
import renderModalError from '../../render/renderModalError'
import Table from '../../component/table/Table'
import renderBadgeForBill from './renderBadgeForBill'
import axios from '../../util/axiosVerify'
import axiosUtil from '../../util/axiosUtil'
//import { GiConsoleController } from 'react-icons/gi';
import console from '../../util/consoleVerify'
import renderGrid from '../../render/renderForm/renderGrid'


const {filterAxios,reloadAxiosAll,saveTableTemplate,
      genCsv,genXlsx} =pageUtil
const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,catchErrorToMessage}=axiosUtil

function TransactionForBill(props) {

const {basicDataSt,myheader,pageFilter,pageFilterForm,
      captureTransaction,
      transactionInputState,
      transactionBranchInputState,

      transactionForBillFilterData,
      setTransactionForBillFilterData,

      setReloadBill,
      reloadBill,
      transactionType,
      captureSelectedLine
}=props
const {basicData}=basicDataSt

//console.log('TransactionForBill')

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

//console.log(1)
let tempTransactionTableTemplate=basicDataSt.tableTemplate.transactionTableTemplate
//let tempSelectedLine=tempTransactionTableTemplate.selectedLine
//tempSelectedLine={...tempSelectedLine,showCol:false}
//tempTransactionTableTemplate={...tempTransactionTableTemplate,
//                            selectedLine:tempSelectedLine
//                         }

const defaultFilterData={
    count:0,
    data0:null,
    dataUrl:"p35transaction",
    lastRecordId:null,
    limitRow:basicData.limitRow,//3,
    pageNumber:1,
    initPageNumber:true,
    qry:{},
    reloadData:false,
    sort:{id:-1},
    message:null,
    showModalError:false,
    isCsv:false,
    isXlsx:false,
    tableTemplate:tempTransactionTableTemplate,

    inputState:transactionInputState,
    inputState2:transactionBranchInputState,
    editData:null,
    
    showModalFilter1:true,
    showModalFilter2:false,
    
    //limitRow2:basicData.limitRow,
    //sort2:{id:-1},
    isCsv2:false,
    isXlsx2:false,
    searchAllBranch:false,
    tableHeadColor:"",
    badgeState:{checkListShow:true},
    showTable:basicData.showTable
}

const genFilterData=()=>{

    if(transactionForBillFilterData){
        return  transactionForBillFilterData
    }
    else {
        return defaultFilterData
    }
}


const [filterData,setFilterData]=React.useState(genFilterData())

const setFilterDataFunc=(data)=>{
    setFilterData(data)
    setTransactionForBillFilterData(data)
}


React.useEffect(()=>{
    if(reloadBill){

        setReloadBill(false)
        setFilterData({
            ...filterData,
            reloadData:true,
            editData:null
        })
    }
},[reloadBill])

/*
const [editData,setEditData]=React.useState(null)

const [filterInputState,setFilterInputState]=React.useState(transactionInputState)
const [filterInputState2,setFilterInputState2]=React.useState(transactionBranchInputState)
*/

const genEndBillAndEffect=(dataArray)=>{
    let tempArray=[]
 
    if(dataArray){
        if(dataArray.length){


            dataArray.map((i,idx)=>{
                let tempEffect={}
                
                if(i.effect){
                    if(i.effect.length){
                        i.effect.map((k,idxK)=>{
                            tempEffect={...tempEffect,
                                [`effectId${idxK}`]:`${k.effectId}.${k.effectName}`,
                                [`effectAmount${idxK}`]:k.effectAmount
                            }
                        })
                    }
                }

                let tempEndBill={}

                if(i.endBill){
                    if(i.endBill.length){
                        i.endBill.map((k,idxK)=>{
                            tempEndBill={...tempEndBill,
                                [`endBillId${idxK}`]:`${k.endBillId}.${k.endBillName}`,
                                [`endBillAmount${idxK}`]:k.endBillAmount
                            }
                        })
                    }
                }

                tempArray=[...tempArray,{...i,...tempEndBill,...tempEffect,selectedLine:false}]

            })

            //console.log('tempArray.....')
            //console.log(tempArray)
        }
    }

    return tempArray
}



React.useEffect(()=>{
    //console.log('useEffect FilterData......................')
   
    //console.log(filterData.pageNumber)
    
    //setFilterData({...filterData,initPageNumber:false})
    if(filterData.reloadData){
    //if((filterData.pageNumber>0)&&(!filterData.initPageNumber)){
        const {dataUrl,pageNumber,limitRow,sort,qry,searchAllBranch}=filterData

        //console.log('qry...............')
        //console.log(qry)

        //console.log('searchAllBranch........')
        //console.log(searchAllBranch)

        if(searchAllBranch){

                axios.post(`/${dataUrl}/getbranchtransactionlimit`,
                        {pageNumber,limitRow,sort,...qry},
                        myheader,
                        shouldRender)
                .then(result=>{
                    //console.log('getlimit')
                    //console.log(result.data.data)
                    
                    //genEndBillAndEffect(result.data.data)
                    const tempResult= { ...filterData,
                                        data0:genEndBillAndEffect(result.data.data),
                                        //data0:result.data.data,
                                        count:result.data.count,
                                        lastRecordId:result.data.lastRecordId,
                                        reloadData:false,
                                    }

                                    
                    if(filterData.isCsv2){
                        //const tempObj={...tempResult,
                        //        tableTemplate:filterData.tableTemplate}
                        //genCsv(tempObj)
                        setFilterDataFunc({...tempResult,isCsv2:false})
                    }
                    else if(filterData.isXlsx2){
                        //const tempObj={...tempResult,
                        //    tableTemplate:filterData.tableTemplate}
                        //genXlsx(tempObj)
                        setFilterDataFunc({...tempResult,isXlsx2:false})
                    }
                    else{
                        setFilterDataFunc(tempResult)
                    }
                })
                .catch(error=>{
                    //console.log('errooo')
                    //catchErrorToMessage(error,setMessage)
                    //setMessage(error.response.data.message)
                    const tempError={...filterData,
                                        reloadData:false,
                                        message:catchErrorToMessage(error),
                                        showModalConfirm:false,
                                        showModalError:true,
                                    }
                    setFilterDataFunc(tempError)
                })
        }
        else{
            axios.post(`/${dataUrl}/getlimit`,
                    {pageNumber,limitRow,sort,...qry},
                    myheader,
                    shouldRender)
            .then(result=>{
                //console.log('getlimit')
                //console.log(result.data.data)
                //console.log('getlimit')
                //console.log(result.data.data)
                //console.log('compare')
                //console.log(genEndBillAndEffect(result.data.data))


                const tempResult= { ...filterData,
                    //data0:result.data.data,
                    data0:genEndBillAndEffect(result.data.data),
                    count:result.data.count,
                    lastRecordId:result.data.lastRecordId,
                    reloadData:false,
                }

                if(filterData.isCsv)
                {
                    //const tempObj={...tempResult,
                    //        tableTemplate:filterData.tableTemplate}
                    //console.log(tempObj)
                    //genCsv(tempObj,"transactionTableTemplate")
                    setFilterDataFunc({...tempResult,isCsv:false})
                }
                else if(filterData.isXlsx){
                    //const tempObj={...tempResult,
                    //    tableTemplate:filterData.tableTemplate}
                    //console.log(tempObj)
                    //genXlsx(tempObj,"transactionTableTemplate")
                    setFilterDataFunc({...tempResult,isXlsx:false})
                }
                else{
                    setFilterDataFunc(tempResult)
                }
            })
            .catch(error=>{
                //console.log('errooo')
                //catchErrorToMessage(error,setMessage)
                //setMessage(error.response.data.message)
                const tempError={...filterData,
                                    reloadData:false,
                                    message:catchErrorToMessage(error),
                                    showModalConfirm:false,
                                    showModalError:true,
                                }
                setFilterDataFunc(tempError)
            })
        }
    }
    

    if(filterData.isCsv){
        //console.log('isCsv')
        genCsv({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isCsv:false})
    }
    if(filterData.isXlsx){
        //console.log('isXlsx')
        genXlsx({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isXlsx:false})
    }

    if(filterData.isCsv2){
        //console.log('isCsv')
        genCsv({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isCsv2:false})
    }
    if(filterData.isXlsx2){
        //console.log('isXlsx')
        genXlsx({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isXlsx2:false})
    }


},[filterData])


//const [tableTemplate,setTableTemplate]=React.useState(tempTransactionTableTemplate)

//console.log(4)

const setReloadDataFunc=()=>{

    /*
    //console.log('setReloadDataFunc.................')
    const {dataUrl,pageNumber,limitRow,sort,qry}=filterData
    //console.log(qry)
    
    axios.post(`/${dataUrl}/getlimit`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
    .then(result=>{
        //console.log('getlimit')
        //console.log(result.data.data)
        const tempResult= { ...filterData,
                            data0:result.data.data,
                            count:result.data.count,
                            lastRecordId:result.data.lastRecordId,
                            reloadData:false,
                         }
        setFilterDataFunc(tempResult)
    })
    .catch(error=>{
        //console.log('errorrr')
        //catchErrorToMessage(error,setMessage)
        //setMessage(error.response.data.message)
        const tempError={...filterData,
                            reloadData:false,
                            message:catchErrorToMessage(error),
                            showModalConfirm:false,
                            showModalError:true,
                        }
        setFilterDataFunc(tempError)
    })

    */
}

const saveTableTemplateFunc=(tableTemplate)=>{
    //console.log('saveTableTemplateFunc')
    saveTableTemplate(tableTemplate,'p35branch',
        "transactionTableTemplate",myheader,shouldRender)
}

const filterAxiosFunc=(option,inputState)=>{
    //console.log('filterAxiosFunc')

    
    filterAxios(option,inputState,FilterTemplate.transactionFilter,
               filterData,null,myheader,
               shouldRender,basicData.branchId)
    .then(tempResult=>{
        //console.log(result.data0)
        const result={...tempResult,data0:genEndBillAndEffect(tempResult.data0)}
        //console.log(result.data0)
        //const result=tempResult

        if(filterData.isCsv)
        {
            //const tempObj={...result,tableTemplate:filterData.tableTemplate}
            //genCsv(tempObj,"transactionTableTemplate")
            setFilterDataFunc({...result,isCsv:false,searchAllBranch:false})
        }
        else if(filterData.isXlsx){
            //const tempObj={...result,tableTemplate:filterData.tableTemplate}
            //genXlsx(tempObj,"transactionTableTemplate")
            setFilterDataFunc({...result,isXlsx:false,searchAllBranch:false})
        }
        else{
            setFilterDataFunc({...result,searchAllBranch:false})
            //setTransactionInputState(filterInputState)
        }
    })
    .catch(error=>{
        setFilterDataFunc(error)
        //setTransactionInputState(filterInputState)
    })

}

const filterAxiosFunc2=(option,inputState)=>{
    //console.log('filterAxiosFunc2')
    //console.log(option)
    //console.log(inputState)
    filterAxios(option,inputState,FilterTemplate.transactionBranchFilter,
               filterData,"getbranchtransactionlimit",myheader,
               shouldRender,basicData.branchId)
    .then(tempResult=>{

        const result={...tempResult,data0:genEndBillAndEffect(tempResult.data0)}
        //console.log(result.data0)
        //console.log(filterData)
        if(filterData.isCsv2)
        {
            //const tempObj={...result,tableTemplate:filterData.tableTemplate}
            //genCsv(tempObj)
            setFilterDataFunc({...result,isCsv2:false,searchAllBranch:true})
        }
        else if(filterData.isXlsx2){
            //const tempObj={...result,tableTemplate:filterData.tableTemplate}
            //genXlsx(tempObj)
            setFilterDataFunc({...result,isXlsx2:false,searchAllBranch:true})
        }
        else{
            setFilterDataFunc({...result,searchAllBranch:true})
            //setTransactionInputState(filterInputState)
        }
    })
    .catch(error=>{
        setFilterDataFunc(error)
        //setTransactionInputState(filterInputState)
    })

    
}


const backToFilter=()=>{
    //console.log('backToFilter')
    /*
    setFilterDataFunc({...filterData,data0:null})
    */
}

const setShowModalFilterFunc=(data)=>{setFilterDataFunc({...filterData,showModalFilter1:data})}
const setShowModalFilterFunc2=(data)=>{setFilterDataFunc({...filterData,showModalFilter2:data})}

const setTableTemplateFunc=(data)=>{setFilterDataFunc({...filterData,tableTemplate:data})}
const setEditDataFunc=(data)=>{setFilterDataFunc({...filterData,editData:data})}

const setInputStateFunc=(data)=>{setFilterDataFunc({...filterData,inputState:data})}
const setInputStateFunc2=(data)=>{setFilterDataFunc({...filterData,inputState2:data})}

const setSortFunc=(data)=>{setFilterDataFunc({...filterData,sort:data})}
const setSortFunc2=(data)=>{setFilterDataFunc({...filterData,sort2:data})}

const setLimitRowFunc=(data)=>{setFilterDataFunc({...filterData,limitRow:data})}
const setLimitRowFunc2=(data)=>{setFilterDataFunc({...filterData,limitRow2:data})}

const setPageNumberFunc=(data)=>{
    setFilterDataFunc({...filterData,
        pageNumber:data,
        reloadData:true
})}
const setPageNumberFunc2=(data)=>{setFilterDataFunc({...filterData,pageNumber2:data})}

const setFilterDataData0=(data)=>{setFilterDataFunc({...filterData, data0:data})}

const setShowModalErrorFunc=(data)=>{setFilterDataFunc({...filterData,showModalError:data})}

const setIsCsv=(data)=>{
    if(data){
        setFilterData({...filterData,isCsv:true,isXlsx:false})
    }
    else{
        setFilterData({...filterData,isCsv:false})
    }
}
const setIsXlsx=(data)=>{
    if(data){
        setFilterData({...filterData,isXlsx:true,isCsv:false})
    }
    else{
        setFilterData({...filterData,isXlsx:false})
    }
}

const setIsCsv2=(data)=>{
    if(data){
        setFilterData({...filterData,isCsv2:true,isXlsx2:false})
    }
    else{
        setFilterData({...filterData,isCsv2:false})
    }
}
const setIsXlsx2=(data)=>{
    if(data){
        setFilterData({...filterData,isXlsx2:true,isCsv2:false})
    }
    else{
        setFilterData({...filterData,isXlsx2:false})
    }
}



const setShowTable=(data)=>{
    //console.log('setSwapState PartnerForBill...')
    //console.log(data)
    
    setFilterData({
        ...filterData,
        showTable:data
    })
}


const updateFilterDataFunc=(index,i)=>{
    //console.log('updateFilterDataFunc') 
    //console.log(index)
    //console.log(i)
    //const temp=updateFilterData(index,i,filterData)
    //console.log('temp')
    //console.log(temp)

    let tempArray=[]

    filterData.data0.map(j=>{
        if(j._id==i._id){
            tempArray=[...tempArray,i]
        }
        else{
            tempArray=[...tempArray,j]
        }
    })

    //console.log(tempArray)
    //setFilterData({...filterData,...temp})

    setFilterDataFunc({...filterData,data0:tempArray})
}


const cancelSelectProductFunc=()=>{
    let tempArray=[]

    filterData.data0.map(j=>{
        tempArray=[...tempArray,{...j,selectedLine:false}]
    })

    setFilterDataFunc({...filterData,data0:tempArray})
}

const selectAllFunc=()=>{
    let tempArray=[]

    filterData.data0.map(j=>{
        tempArray=[...tempArray,{...j,selectedLine:true}]
    })

    setFilterDataFunc({...filterData,data0:tempArray})
}


/*
const genCsvFunc=()=>{
    //console.log('genCsvFunc')
    const tempObj={...filterData,tableTemplate:filterData.tableTemplate}
    genCsv(tempObj)
}

const genXlsxFunc=()=>{
    //console.log('genCsvFunc')
    const tempObj={...filterData,tableTemplate:filterData.tableTemplate}
    genXlsx(tempObj)
}
*/

const renderTableAndBadge=()=>{
    //console.log('renderTableAndBadge')
    return(
        <div className="w-100 h-100">
            <div className="w-100">
                {
                 renderBadgeForBill({
                    filterData,
                    setShowTable:setShowTable,

                    setPageNumber:setPageNumberFunc,
                    bgColor:"#72a2d9",
                    backToFilter:backToFilter,
                    editData:filterData.editData,
                    captureEditData:captureTransaction,
                    setReloadDataFunc:setReloadDataFunc,
                    captureSelectedLine:captureSelectedLine,
                    //bullEyeShow:true,
                    unSelectShow:true,
                    selectAllShow:true,
                    cancelSelectProduct:cancelSelectProductFunc,
                    selectAll:selectAllFunc,
                    //genCsv:genCsvFunc
                }) 
                }
            </div>

            <div className="w-100 h90">
                {filterData.showTable
                ?<Table
                    colorHead={filterData.searchAllBranch?"#dd7b00":"#9e7339"}
                    //tableTemplate={basicDataSt.tableTemplate.transactionTableTemplate}   
                    tableTemplate={filterData.tableTemplate}
                    setTableTemplate={setTableTemplateFunc}

                    filterData={filterData.data0}
                    setFilterData={setFilterDataData0}
                    
                    editData={filterData.editData}
                    setEditData={setEditDataFunc}//{updateEditData}
                    saveTableTemplateFunc={saveTableTemplateFunc}
                    isSubTable={false}
                    useInput={false}
                    //updateFilterData={()=>{}}//{updateEditData}
                    updateFilterData={updateFilterDataFunc}
                    basicData={basicData}
                />
                :renderGrid(filterData,filterData.tableTemplate,setEditDataFunc,updateFilterDataFunc)
                }
            </div>

        </div>
    )
}




return(
<div className="w-100" >
        {
        <ModalFilterInput
            title={"ค้นหาบิล"}//filterTitle}
            show={filterData.showModalFilter1} 
            setShow={setShowModalFilterFunc}

            filterTemplate={pageFilter.transactionFilter}

            inputState={filterData.inputState} 
            setInputState={setInputStateFunc}
            
            limitRow={filterData.limitRow}//limitRow} 
            setLimitRow={setLimitRowFunc}//setLimitRow}

            sort={{id:-1}}//sort} 
            setSort={setSortFunc}//setSort}
            
            isCsv={filterData.isCsv}
            setIsCsv={setIsCsv}

            isXlsx={filterData.isXlsx}
            setIsXlsx={setIsXlsx}

            filterAxios={filterAxiosFunc}//filterAxiosFunc}
            basicData={{...basicData,transaction:transactionType.data0}}

            LB={pageFilterForm.transactionFilterForm}
        />
        }
      
        {
        <ModalFilterInput
                    title={"ค้นหาบิล2"}//filterTitle}
                    show={filterData.showModalFilter2} 
                    setShow={setShowModalFilterFunc2}

                    filterTemplate={pageFilter.transactionBranchFilter}

                    inputState={filterData.inputState2} 
                    setInputState={setInputStateFunc2}
                    
                    limitRow={filterData.limitRow}//limitRow} 
                    setLimitRow={setLimitRowFunc}//setLimitRow}

                    sort={{id:-1}}//sort} 
                    setSort={setSortFunc}//setSort}
                    
                    isCsv={filterData.isCsv2}
                    setIsCsv={setIsCsv2}

                    isXlsx={filterData.isXlsx2}
                    setIsXlsx={setIsXlsx2}

                    filterAxios={filterAxiosFunc2}//filterAxiosFunc}
                    basicData={{...basicData,transaction:transactionType.data0}}

                    LB={pageFilterForm.transactionBranchFilterForm}
                    defaultShowFilter={false}
        />
        }

        {
        filterData.data0
        ?renderTableAndBadge()
        :null
        }
        
        {  
        filterData.showModalError
        ?renderModalError({
            setShow:setShowModalErrorFunc,
            message:filterData.message
        })
        :null
        }
</div>

)

}
export default TransactionForBill;


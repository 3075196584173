import React from 'react';
import {MdVisibility,MdVisibilityOff} from 'react-icons/md';
import {FaBan,FaCheck,FaKeyboard} from 'react-icons/fa'
import console from '../../util/consoleVerify'
import showUtil from '../../util/showUtil'
import MyKeyPad from '../../page/login/MyKeyPad'

import '../Modal.css'

const {showPassword}=showUtil


function ChangeUserPasswordModal({
    pageData,
    basicData,
    setShow,
    submitFunction,
    loadData
    //submitCancel
}) {
    //console.log('ChangeUserPasswordModal')
    
    const refSubmitForm=React.createRef() //ตกลง
    const refCancelForm=React.createRef() //ยกเลิก    
    
    const refId=React.createRef() //ตกลง
    const refPassword=React.createRef() //ตกลง

    let [formInputState,setFormInputState]=React.useState({...loadData,password:""})
    const [hidePassword,setHidePassword]=React.useState(true)


    React.useEffect(()=>{

        //console.log('formInputState')
        //console.log(formInputState)

    },[formInputState])

    const [showKeyPad,setShowKeyPad]=React.useState({
        show:false,
        inputStateName:"",
        myI:""
     })
  //==============================
  //==============================
  //==============================
  //==============================
  const delFunc=()=>{
    const {show,inputStateName,myI}=showKeyPad
    let tempStr=formInputState["password"].slice(0, -1)
    setFormInputState({
       ...formInputState,
       ["password"]:tempStr
    })
}
const charFunc=(myChar)=>{


 const {show,inputStateName,myI}=showKeyPad
    setFormInputState ({
       ...formInputState,
       ["password"]:formInputState["password"]+myChar
    })
 
}
const entFunc=()=>{
 const {show,inputStateName,myI}=showKeyPad

    setShowKeyPad ({
       show:false,
       inputStateName:"",
       myI:""
    })
    refSubmitForm.current.focus()
}

//====================
const renderKeyPad=()=>{
 return <MyKeyPad
    charFunc={charFunc}
    delFunc={delFunc}
    entFunc={entFunc}
 />
}


  //====================
   const changeInputState = (e,i)=>{
        setFormInputState({...formInputState,[i]:e.target.value})
   }

//==============================  

const renderBody=()=>{
    const objKeys=["id","newPassword1"]

    return (objKeys.map((i,idx)=>{
        return (
            <div key={idx}
                 className="w-100 flex-center-center jc-start" 
                 style={{marginBottom:"0.2rem"}}>
                
                {
                 i=="newPassword1"
                 ?<div style={{display:"none"}}>
                     <input
                        type="password"
                        value={"dummy"}
                        onChange={e=>{}}
                     />
                </div>   
                :null
                }
                
                { i=="newPassword1"
                ?<div className="w-30"
                     style={{display:"flex",justifyContent:"space-between"}}
                 >
                    {pageData.changeUserPassword[i]}
                    <FaKeyboard className="sm-icon"
                     onClick={e=>{

                        setShowKeyPad({
                           ...showKeyPad,
                           show:!showKeyPad.show,
                           inputStateName:"formInputState",
                           myI:i
                        })
                     }}
                    />
                </div>      
                :<div className="w-30">
                    {pageData.changeUserPassword[i]}
                </div>
                }

                <div className="w-70" style={{position:"relative"}}>
                     {i=="id"
                     ?<input
                        ref={refId}
                        type={"number"}
                        value={formInputState[i]}
                        onKeyDown={e=>{

                            if(e.key=="Enter"){
                                refPassword.current.focus()
                            }
                        }}
                        disabled={"disabled"}
                     />
                     : <input
                           
                           ref={refPassword}
                           //type={hidePassword?"password":"text"}
                           //value={formInputState["password"]}
                           type="text"//type={hidePassword?"password":"text"}
                           value={hidePassword?showPassword(formInputState["password"]):formInputState["password"]}
                          
                           onChange={e=>{
                            //changeInputState(e,"password")
                            }}
                          
                           onKeyDown={e=>{
                                
                                let tempValue=""

                                if(e.key||e.key===0){
                                    if(e.key=="Enter"){
                                        refSubmitForm.current.focus()
                                        tempValue=formInputState["password"]
                                    }
                                    else if(e.key=="Backspace"){
                                        let tempStr=formInputState["password"].slice(0, -1)
                                        tempValue=tempStr
        
                                    }
                                    else {
                                        const myReg=new RegExp('[a-zA-Z0-9@#&_]', 'i')
                                        const tempStr=e.key.toString()
                                        const strLength=tempStr.length
                                        //console.log(myReg.test(eKey))
                                        if(myReg.test(tempStr)&&strLength==1){
                                            tempValue=formInputState["password"]+e.key
                                        }
                                        else {
                                            tempValue=formInputState["password"]
                                        }
                                    }
                                    setFormInputState({
                                        ...formInputState,
                                        ["password"]:tempValue
                                    })
                                }
                          
                                /*
                                if(tempValue===""){
                                    if(e.key=="Enter"||e.key=="Backspace"){}
                                    else {
                                        setShowKeyPad({
                                            ...showKeyPad,
                                            show:true
                                        })
                                    }
                                }
                                */


                                
                                /*
                                let tempFormInputState=""
                                if(formInputState["password"]){
                                    tempFormInputState=formInputState["password"]
                                }
                            
                                if(e.key=="Enter"){
                                    refSubmitForm.current.focus()
                                }
                                else if(e.key=="Shift"){}
                                else if(e.key=="Backspace"){
                                   let tempStr=tempFormInputState.slice(0, -1)
                                   setFormInputState({
                                     ...formInputState,
                                     ["password"]:tempStr
                                  })
                                }
                                else{
                                  const myReg=new RegExp('[a-zA-Z0-9@#&?_]', 'i')
                                  //console.log(myReg.test(e.key))
                                  if(myReg.test(e.key)){
                                        const tempValue=tempFormInputState+e.key
                                        setFormInputState({
                                           ...formInputState,
                                           ["password"]:tempValue
                                        })
                                  }
                                }

                                */
                            }}
                            
                            autoFocus={"autoFocus"}
                            disabled={showKeyPad.show?"disabled":""}
                        />
                     }
                 

                     {
                        i=="newPassword1"
                        ? hidePassword
                            ?<MdVisibilityOff 
                                className="sm-icon" 
                                style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                onClick={e=>{
                                    setHidePassword(!hidePassword)
                                }}
                            />
                            :<MdVisibility 
                                className="sm-icon" 
                                style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                onClick={e=>{
                                    setHidePassword(!hidePassword)
                                }}
                            />
                        :null
                    }

                </div>
           
            </div>
        )
    }))
}

//====================
//====================

const renderFooter=()=>{
    return(
    <div style={{display:"flex",position:"fixed",bottom:"1rem",right:"2rem",zIndex:"100"}}
    >
        <div>
            <button
                ref={refSubmitForm}
                onKeyDown={e=>{
                    //if(e.key=="ArrowRight"){
                        //refCancelForm.current.focus()
                    //}
                }}
                onClick={e=>{
                    if(submitFunction){
                        submitFunction(formInputState)
                    }
                }}
            >
                <FaCheck/>
            </button>
        </div>
        
        <div>
            <button
                ref={refCancelForm}
                onKeyDown={e=>{
                    //if(e.key=="ArrowLeft"){
                    //    refCancelForm.current.focus()
                    //}
                }}
                onClick={e=>{
                    setShow(false)
                }}
            >
                <FaBan/>
            </button>
        </div>

    </div>
    )
}


//=========================
return (
    <div className="Modal-background">
        <div className="Modal-box" 
             style={{paddingBottom:"1rem",minWidth:"70%",minHeight:"100px"}}>
            <div className="Modal-header">
                <div>
                    <h5>{pageData.changeUserPassword.formHead}</h5>
                </div>
                {
                  renderFooter()
                }
            </div>
            <div className="Modal-body" >
                <div className="ModalInsideBody">
                {
                  renderBody()
                }
                </div>
            </div>
            {showKeyPad.show&&
                <div style={{position:"fixed",width:"100vw",
                height:"30vh",bottom:"0",left:"0",
                backgroundColor:"#666"}}>
                {
                    renderKeyPad()
                }
                </div>
            }
        </div>
    </div>
  );
}


ChangeUserPasswordModal.defaultProps={
    lb:"Form",
    setShow:()=>{},
    submitFunction:null,
    //submitCancel:null
}


export default ChangeUserPasswordModal;


import React from 'react';
import {MainContext} from '../../context/MainContext'
import BackEndNavbar from '../../component/navbar/BackEndNavbar';

import PageComponent  from '../../component/pageComponent/PageComponent';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import console from '../../util/consoleVerify'

function User() {

//console.log('User')

const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
      mainContextUser,setMainContextUser
}=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
<div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
  <div className="h-5 w-100">
        <BackEndNavbar/>
    </div>
    <div className="h-95 w-100">
        <PageComponent
            setReloadBasicData={setReloadBasicData}
            filterDataTemplate={filterDataTemplate}
            filterTemplate={basicDataSt.pageFilter.userFilter}
            inputState={inputState.userInputState}
            basicDataSt={basicDataSt}
            myheader={myheader}
            
            formTemplate={basicDataSt.modalFormTemplate.userForm}
            editFormTemplate={basicDataSt.modalFormTemplate.userForm}
            
            stateTemplate={StateTemplate.userState}
            pageFilterForm={basicDataSt.pageFilterForm.userFilterForm}

            modalFormTemplate={basicDataSt.pageData.ModalForm}
            addFormTitle={basicDataSt.pageData.ModalForm.userAddForm}
            editFormTitle={basicDataSt.pageData.ModalForm.userEditForm}
            bgColor={"#92A8D1"}
            calDigit={100}
            controller={"getlimitbyadmin"}
            mainContextData={mainContextUser}
            setMainContextData={setMainContextUser}
        />
    </div>
</div>
)

}
export default User;
import React from 'react';
import {MdRefresh,MdEdit,MdSave} from 'react-icons/md';
import console from '../util/consoleVerify'


function ModalConfirmReload({
    setShow,
    submitFunction
    }){


    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 

//===============================
//===============================
    return(

    <div className="Modal-background">
        <div className="Modal-box2 flex-center xc6 lc6 mc10 sc12">
         
            <div className="flex-center-center">
                <div style={{margin:"1rem"}}>
                    <button
                        className='flex-center-center'
                        
                        ref={refModalConfirm1}
                        onClick={e=>{
                            submitFunction()
                        }}
                        onKeyDown={e=>{
                            if(e.key=="ArrowRight"){
                                refModalConfirm2.current.focus()
                            }
                        }}
                    >   
                        <MdSave style={{fontSize:"2rem"}}/>
                        <div>+</div>
                        <MdRefresh style={{fontSize:"2rem"}}/>
                    </button>
                </div>
                <div style={{margin:"1rem"}}>
                    <button
                        className='flex-center-center'
                        style={{margin:"1rem"}}
                        ref={refModalConfirm2}
                        onKeyDown={e=>{
                            if(e.key=="ArrowLeft"){
                                refModalConfirm1.current.focus()
                            }
                        }}
                        onClick={e=>{
                            if(setShow){setShow(false)}
                        }}
                    >
                        <MdSave style={{fontSize:"2rem"}}/>
                        <div>+</div>
                        <MdEdit style={{fontSize:"2rem"}}/>
                    </button>
                </div>

            </div>
         
        </div>
    </div>
    )
}

ModalConfirmReload.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}


export default ModalConfirmReload


import React from 'react';
import uuid from 'react-uuid'
import {FaPlusSquare,FaMinusSquare,FaCheck,FaBan} from 'react-icons/fa'; 
import {MdEdit,MdAddCircle,MdDelete,} from 'react-icons/md'; 
import Table from '../../component/table/Table'
import console from '../../util/consoleVerify'


function TransactionTypeSetting(props) {

const {submitFunc,cancelFunc,basicData,pageData,tableTemplate,
      editTransactionType,saveTableTemplateWithTableNameFunc,
      
    }=props



//console.log('TransactionTypeSetting............')
//console.log(editTransactionType)

//console.log(basicData.printPage)
//console.log(basicData.transaction)
//console.log(basicData.transaction)

const refSubmitForm=React.createRef() //ตกลง
const refCancelForm=React.createRef() //ตกลง

const [inputState,setInputState]=React.useState(editTransactionType)

const [endBillTableTemplate,setEndBillTableTemplate]=
    React.useState(tableTemplate.basicDataEndBillTableTemplate)

const [editEndBill,setEditEndBill]=React.useState(null)
const [showEndBillTypeModal,setShowEndBillTypeModal]=React.useState(false)

const [effectTableTemplate,setEffectTableTemplate]=
    React.useState(tableTemplate.basicDataEffectTypeWithSignTableTemplate)
const [editEffect,setEditEffect]=React.useState(null)
const [showEffectTypeModal,setShowEffectTypeModal]=React.useState(false)
const [showEditEffectTypeModal,setShowEditEffectTypeModal]=React.useState(false)


const saveEndBillTableTemplate=(tableTemplate)=>{
    //console.log(tableTemplate)
    const tableName="basicDataEndBillTableTemplate"
    saveTableTemplateWithTableNameFunc(tableTemplate,tableName)
}

const saveEffectWithSignTableTemplate=(tableTemplate)=>{
    //console.log(tableTemplate)
    const tableName="basicDataEffectTypeWithSignTableTemplate"
    saveTableTemplateWithTableNameFunc(tableTemplate,tableName)
}

const renderFooter=()=>{
    return(
        <div className="Modal-footer">
               <div>
                   <button
                       style={{visibility:submitFunc?"visible":"hidden"}}  
                       onClick={e=>{

                           cancelFunc()
                           if(submitFunc){
                            submitFunc(inputState)
                           }
                       }}
                    
                   >
                       <FaCheck/>
                   </button>
               </div>
               <div>
                   <button
                       onClick={e=>{
                          cancelFunc()
                       }}
                   >
                       <FaBan/>
                   </button>
               </div>
           </div>
    )
}

const addUserLevel=(idx)=>{

    let tempArray=[]

    inputState.userLevel.map((o,idxO)=>{
        if(idxO==idx){
            tempArray=[...tempArray,o,"admin"]
        }
        else{
            tempArray=[...tempArray,o]
        }
    })
    setInputState({...inputState,userLevel:tempArray})
}

const deleteUserLevel=(idx)=>{
    let tempArray=[]

    inputState.userLevel.map((o,idxO)=>{
        if(idxO!=idx){
            tempArray=[...tempArray,o]
        }
    })
    setInputState({...inputState,userLevel:tempArray})
}


const renderUserLevel=()=>{
return(
<div className='w-100'>
    {inputState.userLevel.map((k,idxK)=>{
        return(
            <div className="w-100"
                 key={idxK}
                 style={{display:"flex",margin:"0.2rem 0"}}
            >
                <div className="w-30" style={{display:"flex"}}>
                    <div className="w-50" 
                         style={{display:"flex",justifyContent:"flex-end"}}
                    >
                        <FaPlusSquare className="sm-icon"
                            onClick={e=>{
                                addUserLevel(idxK)
                            }}
                        />
                    </div>
                    <div className="w-50">
                        {idxK==0
                        ?null
                        :<FaMinusSquare className="sm-icon"
                            onClick={e=>{
                                deleteUserLevel(idxK)
                            }}
                        />
                        }
                    </div>
                </div>
               
                <div className="w-70">
                <select className="xc12"
                    onChange={e=>{
                        let tempArray=[]
                        inputState.userLevel.map((n,idxN)=>{
                            if(idxN==idxK){
                                tempArray=[...tempArray,e.target.value]
                            }
                            else{
                                tempArray=[...tempArray,n]
                            }
                            setInputState({...inputState,userLevel:tempArray})
                        })
                        //setInputState({...inputState,transactionType:e.target.value})
                    }}
                >
                    <option>....</option>
                    {
                    basicData.userLevel.map((j,idx2)=>(
                    <option key={idx2}
                            value={j}
                            selected={inputState.userLevel[idxK]==j?"selected":""}
                    >{j}</option>
                    ))
                    }
                </select>
                </div>
            </div>
        )
    })}
</div>
)
}



const renderEndBill=()=>{
    return(
        <Table
            colorHead={"#4b6d62"}
            tableTemplate={endBillTableTemplate}
            setTableTemplate={setEndBillTableTemplate}

            filterData={inputState.endBill}
            setFilterData={(data)=>{
                setInputState({...inputState,
                    endBill:data
                })
            }}
            
            editData={editEndBill}
            setEditData={setEditEndBill}
            saveTableTemplateFunc={saveEndBillTableTemplate}
            isSubTable={false}
            updateFilterData={()=>{}}
            useInput={false}
        
        />
    )
}

const renderEffect=()=>{

    return(
        <Table
            colorHead={"#4b6d62"}
            tableTemplate={effectTableTemplate}
            setTableTemplate={setEffectTableTemplate}

            filterData={inputState.effect}
            setFilterData={(data)=>{
                setInputState({...inputState,
                    effect:data
                })
            }}
            
            editData={editEffect}
            setEditData={setEditEffect}
            saveTableTemplateFunc={saveEffectWithSignTableTemplate}
            isSubTable={false}
            updateFilterData={()=>{}}
            useInput={false}
        
        />
    )
}



const renderBody=()=>{

    return (
    <div className="w-100"
        
    >
         <div className='w-100'
             style={{display:"flex",justifyContent:"center",alignItems:"center",
                     margin:"0.3rem 0"
                 }}
        >
            <div className='w-30'

            >{pageData.transactionTypeForm.id}</div>
            <div className='w-70'>
                <div>{inputState.id}</div>
            </div>
        </div> 

        <div className='w-100'
             style={{display:"flex",justifyContent:"center",alignItems:"center",
                     margin:"0.3rem 0"
                 }}
        >
            <div className='w-30'

            >{pageData.transactionTypeForm.newId}</div>
            <div className='w-70'>
                <input
                    type="number"
                    value={inputState.newId}
                    onChange={e=>{
                        setInputState({...inputState,newId:e.target.value})
                    }}
                />
            </div>
        </div> 


        <div className='w-100'
             style={{display:"flex",justifyContent:"center",alignItems:"center",
                     margin:"0.3rem 0"
                 }}
        >
            <div className='w-30'

            >{pageData.transactionTypeForm.transactionType}</div>
            <div className='w-70'>
                <input
                    value={inputState.transactionType}
                    onChange={e=>{
                        setInputState({...inputState,transactionType:e.target.value})
                    }}
                />
            </div>
        </div> 


        <div className='w-100'
             style={{display:"flex",
                     justifyContent:"center",
                     alignItems:"center",
                     margin:"0.3rem 0"
                 }}
        >
            <div className='w-30'
            >{pageData.transactionTypeForm.useInnerVat}</div>
            <div className='w-70'>
                <input
                    type="checkbox"
                    checked={inputState.useInnerVat}
                    onChange={e=>{
                        setInputState({...inputState,useInnerVat:e.target.checked})
                    }}
                />
            </div>   
        </div>  

        <div className='w-100'
             style={{display:"flex",
                     justifyContent:"center",
                     alignItems:"center",
                     margin:"0.3rem 0"
                 }}
        >
            <div className='w-30'
            >{pageData.transactionTypeForm.innerVatRate}</div>
            <div className='w-70'>
                <input
                    type="number"
                    value={inputState.innerVatRate}
                    onChange={e=>{
                        setInputState({...inputState,innerVatRate:e.target.value})
                    }}
                />
            </div>   
        </div>  

        <div className='w-100'
             style={{display:"flex",
                     justifyContent:"center",
                     alignItems:"center",
                     margin:"0.3rem 0"
                 }}
        >
            <div className='w-30'
            >{pageData.transactionTypeForm.printPageSetting}</div>
            <div className='w-70'>
               <select
                    onChange={e=>{
                        //console.log('e.target.value')
                        //console.log(e.target.value)
                        const temp=e.target.value.split("@mmpos@")
                        setInputState({
                            ...inputState,
                            printId:temp[0],
                            printPageName:temp[1]
                        })

                    }}
               >
                 <option>...</option>
                 {
                 basicData.printPage.map((ll,idxLL)=>{
                     return <option key={idxLL}
                            value={`${ll.printId}@mmpos@${ll.printPageName}`}
                          >
                         {`${ll.printId}.${ll.printPageName}`}
                        </option>
                 })
                 }
               </select>
            </div>   
        </div>             


        <div className='w-100'
             style={{display:"flex",
                     justifyContent:"center",
                     alignItems:"center",
                     margin:"0.3rem 0"
                 }}
        >
            <div className='w-30'
            >{pageData.transactionTypeForm.printId}</div>
            <div className='w-70'>
               {inputState.printId}
            </div>   
        </div>  

        <div className='w-100'
             style={{display:"flex",
                     justifyContent:"center",
                     alignItems:"center",
                     margin:"0.3rem 0"
                 }}
        >
            <div className='w-30'
            >{pageData.transactionTypeForm.printPageName}</div>
            <div className='w-70'>
               {inputState.printPageName}
            </div>   
        </div>           


               
        <div className="w-100">
            <div className="w-100">
                {pageData.transactionTypeForm.userLevel}
            </div>
            {
             renderUserLevel()
            }
        </div>

        <div className="w-100" style={{marginTop:"1rem"}}>
            <div className="w-100" style={{display:"flex",margin:"0 0 1rem 0"}}>
                {pageData.transactionTypeForm.endBill}
                <MdAddCircle className="md-icon" style={{margin:"0 0.5rem"}}
                    onClick={e=>{
                        setShowEndBillTypeModal(true)
                    }}
                />
                <MdDelete className="md-icon"  style={{margin:"0 0.5rem"}}
                    onClick={e=>{
                        if(editEndBill){
                            let tempArray=[]
                            inputState.endBill.map(x=>{
                                if(x._id!=editEndBill._id){
                                    tempArray=[...tempArray,x]
                                }
                            })
                            setInputState({...inputState,endBill:tempArray})
                        }
                    }}
                />
            </div>
            {
             renderEndBill()
            }
        </div>
        
        <div className="w-100" style={{marginTop:"1rem"}}>
            <div className="w-100" style={{display:"flex",margin:"0 0 1rem 0"}}>
                {pageData.transactionTypeForm.effect}
                

                {
                /*

                <MdAddCircle className="md-icon" style={{margin:"0 0.5rem"}}
                    onClick={e=>{
                        setShowEffectTypeModal(true)
                        //setShowEffectTypeModal(true)
                    }}
                />
                <MdDelete className="md-icon"  style={{margin:"0 0.5rem"}}
                    onClick={e=>{
                        if(editEffect){
                            let tempArray=[]
                            inputState.effect.map((x,idxX)=>{
                                if(idxX>6){
                                
                                    if(x._id!=editEffect._id){
                                        tempArray=[...tempArray,x]
                                    }

                                }
                                else {

                                    tempArray=[...tempArray,x]
                                }


                            })
                            setInputState({...inputState,effect:tempArray})
                        }
                    }}
                />
                
                */
                }
                <MdEdit className="md-icon"  style={{margin:"0 0.5rem"}}
                onClick={e=>{
                    if(editEffect){
                        setShowEditEffectTypeModal(true)
                    }
                }}
                />
            </div>
            {
             renderEffect()
            }
        </div>     



    </div>
 )}
//===================================
const renderEditEffectTypeModal=()=>{
  
        const {effectId,effectName,effectSign,effectEndBillId}=pageData.effectTypeForm
     

        return(
        <div className="Modal-background">
        <div className="Modal-box" 
             style={{width:"70%",backgroundColor:"white"}}>

                <div className="flex-center-center w-100 mb-1">
                    <div className="w-30">{effectId}</div>
                    <div className="w-70">
                        <input
                            value={editEffect.effectId}
                            onChange={e=>{}}
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div className="flex-center-center w-100 mb-1">
                    <div className="w-30">{effectName}</div>
                    <div className="w-70">
                        <input
                            value={editEffect.effectName}
                            onChange={e=>{}}
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div className="flex-center-center w-100 mb-1">
                    <div className="w-30">{effectEndBillId}</div>
                    <div className="w-70">
                        <input
                            value={editEffect.effectEndBillId}
                            onChange={e=>{}}
                            disabled="disabled"
                        />
                    </div>
                </div>
                <div className="flex-center-center w-100 mb-1">
                    <div className="w-30">{effectSign}</div>
                    <div className="w-70">
                        <select className="xc12"
                            onChange={e=>{
                                let tempObj={...editEffect,effectSign:e.target.value}
                                setEditEffect(tempObj)
                            }}
                        >
                            <option>....</option>
                            {editEffect.effectId==1
                            ?["+","-","=","unChange"].map((j,idxJ)=>(
                            <option key={idxJ} 
                                    value={j}
                                    selected={j==editEffect.effectSign?"selected":""}
                            >{j}</option>
                            ))
                            :["+","-","unChange"].map((j,idxJ)=>(
                                <option key={idxJ} 
                                        value={j}
                                        selected={j==editEffect.effectSign?"selected":""}
                                >{j}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="w-100" 
                    style={{display:"flex",justifyContent:"flex-end",
                            margin:"1rem"
                           }}
                >  
                        <button
                            onClick={e=>{
                                //console.log('editEffect')
                                //console.log(editEffect)

                                //console.log('inputState')
                                //console.log(inputState)
                                setShowEditEffectTypeModal(false)
                                let tempArray=[]
                                //console.log(inputState)
                                
                                inputState.effect.map((r,idxR)=>{
                                    if(r._id==editEffect._id){
                                        tempArray=[...tempArray,editEffect]
                                    }
                                    else{
                                        tempArray=[...tempArray,r]
                                    }
                                })
                                setInputState({...inputState,effect:tempArray})
                            }}
                        >
                            <FaCheck/>
                        </button>

                        <button
                            onClick={e=>{
                                setShowEditEffectTypeModal(false)
                            }}
                        >
                            <FaBan/>
                        </button>
                </div>
        </div>
       
    </div>
    )
}

const renderEndBillTypeModal=()=>{
    return(
        <div className="Modal-background">
            <div className="Modal-box" 
            style={{width:"96%",backgroundColor:"white"}}>

                    <select className="xc12"
                        onChange={e=>{
                            let tempArray=[]
                            let tempObj=null
                            basicData.endBill.map((s,idxS)=>{
                                if(s.endBillId==e.target.value){
                                    tempObj={...s,_id:uuid()}
                                }
                            })
                            //console.log('tempObj')
                            //console.log(tempObj)

                            if(editEndBill){
                                
                                inputState.endBill.map((q,idxQ)=>{
                                    if(q._id==editEndBill._id){
                                        tempArray=[...tempArray,q,tempObj]
                                    }
                                    else{
                                        tempArray=[...tempArray,q]
                                    }
                                })
                            }
                            else{
                                tempArray=[...inputState.endBill,tempObj]
                            }
                            setInputState({...inputState,endBill:tempArray})
                            setShowEndBillTypeModal(false)
                        }}
                    >
                        <option>....</option>
                        {
                        basicData.endBill.map((j,idxJ)=>(
                        <option key={idxJ} 
                                value={j.endBillId}
                        >{`${j.endBillId}, ${j.endBillName}`}</option>
                        ))
                        }
                    </select>

                    <div className="w-100" 
                        style={{display:"flex",justifyContent:"flex-end",
                                margin:"1rem"
                               }}
                    >
                                <button
                                    onClick={e=>{
                                        setShowEndBillTypeModal(false)
                                    }}
                                >
                                    <FaBan/>
                                </button>
                    </div>
            </div>
           
        </div>
    )
}


const renderEffectTypeModal=()=>{

    //const [a,b,c,d,e,...remaining]=basicData.effectType
    const remaining=basicData.effectType
    return(
        <div className="Modal-background">
            <div className="Modal-box" 
            style={{width:"96%",backgroundColor:"white"}}>

                    <select className="xc12"
                        onChange={e=>{
                            let tempArray=[]
                            let tempObj=null
                            basicData.effectType.map((s,idxS)=>{
                                if(s.effectId==e.target.value){
                                    tempObj={...s,_id:uuid(),effectSign:"+"}
                                }
                            })
                            //console.log('tempObj')
                            //console.log(tempObj)

                            if(editEffect){
                                
                                let passCheck=false

                                inputState.effect.map((q,idxQ)=>{
                                    if(q._id==editEffect._id&&idxQ>=6){
                                        tempArray=[...tempArray,q,tempObj]
                                        passCheck=true
                                    }
                                    else{
                                        tempArray=[...tempArray,q]
                                    }
                                })

                                
                                if(passCheck){
                                    setInputState({...inputState,effect:tempArray})
                                }

                                setShowEffectTypeModal(false)
                            }
                            else{
                                //tempArray=[...inputState.effect,tempObj]
                                setShowEffectTypeModal(false)

                            }
                            
                           
                        }}
                    >
                        <option>....</option>
                        {
                            //basicData.effectType.map((l,idxL)=>{
                            remaining.map((l,idxL)=>{
                                return(
                                    <option key={idxL} 
                                            value={l.effectId}
                                    >{`${l.effectId}, ${l.effectName}`}</option>
                                )
                            })
                        }
                    </select>

                    <div className="w-100" 
                        style={{display:"flex",justifyContent:"flex-end",
                                margin:"1rem"
                               }}
                    >
                                <button
                                    onClick={e=>{
                                        setShowEffectTypeModal(false)
                                    }}
                                >
                                    <FaBan/>
                                </button>
                    </div>
            </div>
           
        </div>
    )
}



return (
     <div className="Modal-background">
       <div className="Modal-box" style={{width:"96%",backgroundColor:"white"}}>
            <div className="Modal-header">
                  <h3>{pageData.transactionTypeForm.formHead}</h3>
            </div>
            <div className="Modal-body" >
                <div className="ModalInsideBody">
                 {                
                  renderBody()
                 }
                </div>
            </div>

            {
              renderFooter()
            }
        </div>

        {showEndBillTypeModal
         ?renderEndBillTypeModal()
         :null
        }

        {showEffectTypeModal
         ?renderEffectTypeModal()
         :null
        }

        {showEditEffectTypeModal
         ?renderEditEffectTypeModal()
         :null
        }

    </div>
)


}
export default TransactionTypeSetting;

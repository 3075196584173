import React from 'react';

//import {Route,Switch,Redirect} from 'react-router-dom';
import {MdEdit} from 'react-icons/md';
import axios from '../../util/axiosVerify'

import axiosUtil from '../../util/axiosUtil'
import ctUtil from '../../util/ctUtil'
import StateUtil from '../../model/StateUtil'
import pageUtil from '../../component/pageComponent/pageUtil'

import ShopChangePassword from '../../render/renderForm/ShopChangePassword'

import BasicValueSetting from '../../render/renderForm/BasicValueSetting'

//import BasicValueSetting2 from '../../render/renderForm/BasicValueSetting2'

import AddUser from '../../render/renderForm/AddUser'

import RouteAuth from '../../render/renderForm/RouteAuth'
import Language from '../../render/renderForm/Language'
import PrintPage from '../../render/renderForm/PrintPage'
import StickerPage from '../../render/renderForm/StickerPage';
//import WidthLeft from '../../render/renderForm/WidthLeft'
import renderModalError from '../../render/renderModalError'
import ModalTable from './ModalTable'
import DateSetting from './DateSetting'
import ShopPayment from '../../page/login/ShopPayment'

import LogInLangTemplate from '../../page/login/LogInLangTemplate'

import TransactionSetting from '../../render/renderForm/TransactionSetting'
import console from '../../util/consoleVerify'
import {FaBan,FaCheck} from 'react-icons/fa'



const {createTableTemplateForPage,convertTableTemplateObjToArray}=ctUtil
const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,
    addSingleFileNameInsteadOfPhotoUrl,
    catchErrorToMessage}=axiosUtil
const {genBlankState,genRefAndValue,revRefAndValue,combineLoadDataBlankState}=StateUtil 

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete
} = pageUtil

function ShopInfo(props) {
const { pageData,basicData,FormTemplate,StateTemplate,
        basicDataTableTemplate,pageDataStandard,languageObj,
        myheader, setReloadBasicData,tableTemplate,
        setShowPrintFunc,languageArray,user,shop,allBranch
    }=props

//console.log('shopInfo........')
//console.log(allBranch)
//console.log(basicData)
//console.log('pageData')
//console.log(pageData)

//console.log('basicData')
//console.log(basicData)
//const {pageData,basicData,FormTemplate,StateTemplate,
//basicDataTableTemplate,pageDataStandard}=props

//const {shopInfoState} = StateTemplate
//const {shopInfoForm} = FormTemplate
const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const [inputState,setInputState]=React.useState(null)

const [showShopInfo,setShowShopInfo]=React.useState(false)
const [showShopChangePassword,setShowShopChangePassword]=React.useState(false)
const [showAddUser,setShowAddUser]=React.useState(false)
const [showPrintPage,setShowPrintPage]=React.useState(false)
const [showStickerPage,setShowStickerPage]=React.useState(false)
const [showTransaction,setShowTransaction]=React.useState(false)
const [showDateSetting,setShowDateSetting]=React.useState(false)
const [showShopPayment,setShowShopPayment]=React.useState(false)

const [error,setError]=React.useState({
    showModalError:false,
    message:null
})

const [basicValueSetting,setBasicValueSetting]=React.useState({
    key:null,
    show:false,
    lb:null,
    array:null
})

const [basicValueSetting2,setBasicValueSetting2]=React.useState({
    key:null,
    show:false,
    lb:null,
    array:null
})

const [showRouteAuth,setShowRouteAuth]=React.useState(false)
const [showLanguage,setShowLanguage]=React.useState(false)
const [showWidthLeft,setShowWidthLeft]=React.useState(false)
const [showModalTable,setShowModalTable]=React.useState(false)
const [myTableTemplate,setMyTableTemplate]=React.useState(tableTemplate)

const [modalTableState,setModalTableState]=React.useState({
    key:null,
    showModalTable:false,
    pageForm:null,
    tableTemplate:null,
    data:null,
    blankData:null,
    effecting:null
})

const setShowShopChangePasswordFunc=()=>{
    setShowShopChangePassword(true)
}

const setShowAddUserFunc=()=>{
    setShowAddUser(true)    
}

const setShowPrintPageFunc=()=>{
    setShowPrintPage(true)    
}

const setShowStickerPageFunc=()=>{
    setShowStickerPage(true)    
}

const setShowTransactionFunc=()=>{
    setShowTransaction(true)
}

const setShowDateSettingFunc=()=>{
    setShowDateSetting(true)
}

const setShowShopPaymentFunc=()=>{
    setShowShopPayment(true)
}

const setShowShopPaymentFunc2=()=>{
    setShowShopPayment(false)
}

const addPrintPage=(filterData,idx)=>{
    //if(filterData.length<10){

        //console.log('1')
        const lastIdx=filterData.length-1
        const newId=parseInt(filterData[lastIdx].printId)+1

        let tempArray=filterData
        //console.log('2')
        const tempObj={...filterData[idx],
            printId:newId,
            printPageName:`${filterData[idx].printPageName}-copy`
        }
        tempArray=[...tempArray,tempObj]

        //console.log('3')
        axios.post('/p35basicdata/updatecustom',
                    {id:basicData.id,printPage:tempArray},
                    myheader,shouldRender)
        .then(result=>{
            console.log('result')
            
            setShowPrintPage(false)
            //setReloadBasicData(true)
            //window.location.reload()
        })
        .catch(error=>{  
            //console.log('error')
            
            const tempObj={showModalError:true,message:catchErrorToMessage(error)}
            setError(tempObj)  
        })



    //}
}


const deletePrintPage=(printId)=>{
    let tempArray=[]
    basicData.printPage.map(i=>{
        if(i.printId!=printId){
            tempArray=[...tempArray,i]
        }
    })

    axios.post('/p35basicdata/updatecustom',
                {id:basicData.id,printPage:tempArray},
                myheader,shouldRender)
    .then(result=>{
        //console.log('result')
        setShowPrintPage(false)
        //setReloadBasicData(true)
        window.location.reload()
    })
    .catch(error=>{  
        //console.log('error')
        
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
}

const updatePrintPageFunc=(filterData)=>{
    axios.post('/p35basicdata/updatecustom',
    {   id:basicData.id, printPage:filterData},
    myheader,shouldRender)
    .then(result=>{
        //console.log('result')
        setShowPrintPage(false)
        //setReloadBasicData(true)
        window.location.reload()
    })
    .catch(error=>{  
    //console.log('error')

    const tempObj={showModalError:true,message:catchErrorToMessage(error)}
    setError(tempObj)  
    })
}

const updatePrintPageFunc2=(pageSetup,filterData)=>{

    const {_id,...remaining}=pageSetup
    let tempObj=remaining
    
    //console.log('tempObj')
    //console.log(tempObj)

    let tempArray2=[]
    tempObj.printSection.map(m=>{
        const {_id,...remaining2}=m
        tempArray2=[...tempArray2,remaining2]
    })

    tempObj={...tempObj,printSection:tempArray2}

  
    let tempArray=[]
    filterData.map(i=>{
        if(i.printId==pageSetup.printId){
              tempArray=[...tempArray,tempObj]
        }
        else{
            tempArray=[...tempArray,i]
        }
    })


    //console.log(tempArray)

    
    axios.post('/p35basicdata/updatecustom',
        {id:basicData.id, printPage:tempArray},
        myheader,shouldRender)
    .then(result=>{
        setShowPrintPage(false)
        window.location.reload()
        //setReloadBasicData(true)
    })
    .catch(error=>{  
        //console.log('error')
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
    
}
//=======================

const addStickerPage=(filterData,idx)=>{
    if(filterData.length<10){

        const lastIdx=filterData.length-1
        const newId=parseInt(filterData[lastIdx].id)+1

        let tempArray=filterData
    
        const tempObj={...filterData[idx],
            id:newId,
            recordName:`${filterData[idx].recordName}-copy`
        }

        tempArray=[...tempArray,tempObj]


        axios.post('/p35basicdata/updatecustom',
                    {id:basicData.id,stickerPage:tempArray},
                    myheader,shouldRender)
        .then(result=>{
            //console.log('result')
            setShowStickerPage(false)
            //setReloadBasicData(true)
            window.location.reload()
        })
        .catch(error=>{  
            //console.log('error')
            
            const tempObj={showModalError:true,message:catchErrorToMessage(error)}
            setError(tempObj)  
        })
    }
}


const deleteStickerPage=(id)=>{
    let tempArray=[]
    basicData.stickerPage.map(i=>{
        if(i.id!=id){
            tempArray=[...tempArray,i]
        }
    })

    axios.post('/p35basicdata/updatecustom',
                {id:basicData.id,stickerPage:tempArray},
                myheader,shouldRender)
    .then(result=>{
        //console.log('result')
        setShowStickerPage(false)
        //setReloadBasicData(true)
        window.location.reload()
    })
    .catch(error=>{  
        //console.log('error')
        
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
}


const updateStickerPage=(filterData)=>{
    axios.post('/p35basicdata/updatecustom',
    {   id:basicData.id, stickerPage:filterData},
    myheader,shouldRender)
    .then(result=>{
    //console.log('result')
        setShowStickerPage(false)
        //setReloadBasicData(true)
        window.location.reload()
    })
    .catch(error=>{  
    //console.log('error')

    const tempObj={showModalError:true,message:catchErrorToMessage(error)}
    setError(tempObj)  
    })
}

const updateStickerPageFunc2=(inputState)=>{
    const {_id,date,productData,recordBy,...remaining2}=inputState
    const {stickerPage}=basicData
    //let tempObj=remaining
    
    let tempArray=[]
    stickerPage.map(i=>{
        const {_id,...remaining}=i

        if(i.id==inputState.id){
            const tempObj={...remaining,...remaining2}
            tempArray=[...tempArray,tempObj]
        }
        else{
            tempArray=[...tempArray,remaining]
        }

    })
    
    axios.post('/p35basicdata/updatecustom',
        {id:basicData.id, stickerPage:tempArray},
        myheader,shouldRender)
    .then(result=>{
        setShowStickerPage(false)
        window.location.reload()
        //setReloadBasicData(true)
    })
    .catch(error=>{  
        //console.log('error')
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
}





//======================
const addLanguage=(i)=>{
    //console.log('addLanguage')
    //console.log(i)

    /*
    const lastIdx=filterData.length-1
    const newId=parseInt(filterData[lastIdx].id)+1

    let tempArray=[]
    Object.keys(filterData[0].template).map(i=>{
        const tempObj={key:i,lb:filterData[0].template[i]}
        tempArray=[...tempArray,tempObj]
    })
  
    const tempObj={
        id:newId,
        formLanguage:newId.toString(),
        template:tempArray
    }

    axios.post('/p35formtemplate/addcustom',
                tempObj,
                myheader,shouldRender)
    .then(result=>{
        //console.log('result')
        setShowLanguage(false)
        setReloadBasicData(true)
    })
    .catch(error=>{  
        //console.log('error')
        
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
    */
}
const deleteLanguage=(id)=>{
    //console.log('deleteLan.........')
    axios.post('/p35formtemplate/deletecustom',
        {id:id},
        myheader,shouldRender)
    .then(result=>{
        setShowLanguage(false)
        setReloadBasicData(true)
    })
    .catch(error=>{  
    //console.log('error')
    const tempObj={showModalError:true,message:catchErrorToMessage(error)}
    setError(tempObj)  
    })
}

const updateLanguageSettingFunc=(filterData,arrayOfChange)=>{
    //console.log('updateLanguageSettingFunc')
    
    let tempPromise=[]

    if(arrayOfChange){
        if(arrayOfChange.length>0){
       
            arrayOfChange.map(i=>{
                
                filterData.map(j=>{
                    if(i==j.id){
                        const temp=axios.post(`/p35formtemplate/updatecustom`,j,myheader,shouldRender)
                        tempPromise=[...tempPromise,temp]
                    }
                })
                
            })
        }
    }
    
    Promise.all(tempPromise)
    .then(result=>{
        //console.log('result')
        setShowLanguage(false)
        //setReloadBasicData(true)
        window.location.reload()
    })
    .catch(error=>{  
        //console.log('error')
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
    
}
const updateLanguageSettingFunc2=(editData)=>{
    //console.log('updateLanguageSettingFunc2')
    
    let tempArray=[]
    Object.keys(editData.template).map(i=>{
        
        const tempObj={
            key:i,
            lb:editData.template[i]
        }
        tempArray=[...tempArray,tempObj]
    })
  
    const tempObj={
        id:editData.id,
        formLanguage:editData.formLanguage,
        template:tempArray
    }

    axios.post(`/p35formtemplate/updatecustom`,tempObj,myheader,shouldRender)    
    .then(result=>{
        //console.log('result')
        setShowLanguage(false)
        setReloadBasicData(true)
    })
    .catch(error=>{  
        //console.log('error')
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
    
}

const updateAddUserFunc=(inputState)=>{
    //console.log('updateAddUserFunc.........')
    axios.post(`/user/adduser`,inputState,myheader,shouldRender)
    .then(result=>{
        //resoladdve(true)
        setShowAddUser(false)
    })
    .catch(error=>{  
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
}


const updateWidthLeftFunc=(formInputState)=>{
    //console.log('updateWidthLeftFunc.........')
    const {id}=basicData
    const temp={id,widthLeft:formInputState.widthLeft}
      
    axios.post(`/p35basicdata/updatecustom`,temp,myheader,shouldRender)
    .then(result=>{
        setReloadBasicData(true)
        setShowWidthLeft(false)
    })
    .catch(error=>{  
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })

}

const updateShopInfoFunc=(inputState)=>{
    //console.log('updateShopInforFunc')
    //console.log(inputState)

    const {file1,photoUrl1,...remaining}=inputState
    const {id}=basicData
    const tempFormInputState1={id,shopInfo:remaining}
        
    let promise1=axios.post(`/p35basicdata/updatecustom`,tempFormInputState1,myheader,shouldRender)

    let promise2=null

    if(file1){
        const tempFormInputState2=addSingleFileNameInsteadOfPhotoUrl({id,file1,photoUrl1})  

        const fd=genFD({
            inputState:tempFormInputState2,
            template:{
                id:StateTemplate.shopInfoState.id,
                file1:StateTemplate.shopInfoState.file1,
                photoUrl1:StateTemplate.shopInfoState.photoUrl1,
            }
        })

        promise2=axios.post(`/p35basicdata/updatecustom`,fd,myheader,shouldRender)
    }
    else{
        promise1=axios.post(`/p35basicdata/updatecustom`,{...tempFormInputState1,photoUrl1},myheader,shouldRender)
    }

    Promise.all([promise1,promise2])
        .then(result=>{
            setReloadBasicData(true)
            setShowShopInfo(false)
        })
        .catch(error=>{
            const tempObj={showModalError:true,message:catchErrorToMessage(error)}
            setError(tempObj)
            
         })
}


const updateBasicValueSettingFunc=(inputState)=>{
    //console.log('updateBasicDataFunc........')
    const tempObj={id:basicData.id,[basicValueSetting.key]:inputState}

    //console.log(tempObj)

    axios.post(`/p35basicdata/updatecustom`,tempObj,myheader,shouldRender)
    .then(result=>{
        //console.log('result')
        //resolve(true)
        //setReloadBasicData(true)
        setBasicValueSetting({...basicValueSetting,show:false})
        window.location.reload()
    })
    .catch(error=>{  
        //console.log('error')
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)
        
    })
    
}

const updateRouteAuthFunc=(routeAuth)=>{
   
    let tempArray=[]

    basicData.originalRouteAuth.map(i=>{
        let keepChecking=true

        routeAuth.map(j=>{
            if(keepChecking){
                if(i.id==j.id){
                    const {_id,...remaining}=j
                    tempArray=[...tempArray,remaining]
                    keepChecking=false
                }
            }
        })

        if(keepChecking){
            const {_id,...remaining}=i
            tempArray=[...tempArray,remaining]
        }

    })

    const tempBasicData ={id:basicData.id,routeAuth:tempArray}

    axios.post(`/p35basicdata/updatecustom`,tempBasicData,myheader,shouldRender)
    .then(result=>{
        //resolve(true)
        //setReloadBasicData(true)
        setShowRouteAuth(false)
        window.location.reload()
    })
    .catch(error=>{  
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)
        
    })
}

const updateShopChangePasswordFunc=(inputState)=>{
    axios.post(`/shop/shopchangepassword`,inputState,myheader,shouldRender)
    .then(result=>{
        //resolve(true)
        setShowShopChangePassword(false)
        window.location.reload()
    })
    .catch(error=>{  
        const tempObj={showModalError:true,message:catchErrorToMessage(error)}
        setError(tempObj)  
    })
}

const setShowBasicValueSettingFunc=(value)=>{
    setBasicValueSetting({...basicValueSetting,show:value})
}

const setShowBasicValueSettingFunc2=(value)=>{
    setBasicValueSetting2({...basicValueSetting2,show:value})
}


const setShowRouteAuthFunc=()=>{
    setShowRouteAuth(true)
}

const setShowLanguageFunc=()=>{
    setShowLanguage(true)
}


const setShowWidthLeftFunc=()=>{
    setShowWidthLeft(true)
}

const setShowModalErrorFunc=(value)=>{
    setError({...error,showModalError:value})
}

const genArrayFromArrayObj=(Array,key)=>{
    let tempArray=[]
    Array.map(i=>{
        tempArray=[...tempArray,i[key]]
    })
    return tempArray
}

const updateBasicDataFunc=(value,key,effectOfBranch)=>{
    if(value){
        
        let tempArray=[]

        value.map(i=>{
            const {_id,...remaining}=i
            tempArray=[...tempArray,remaining]
        })

        let tempObj2={[modalTableState.key]:tempArray}


        let tempObj={id:basicData.id,...tempObj2}
        
        if(key=="effectType"){
            if(effectOfBranch){
                let tempArray3=[]
                effectOfBranch.map(p=>{
                    const {_id,...remaining}=p
                    tempArray3=[...tempArray3,remaining]
                })
                tempObj={...tempObj,effecting:tempArray3}
            }
        }

        axios.post(`/p35basicdata/updatecustom`,tempObj,myheader,shouldRender)
        .then(result=>{
            //resolve(true)
            //setReloadBasicData(true)
            setModalTableState({
                key:null,
                showModalTable:false,
                pageForm:null,
                tableTemplate:null,
                data:null,
                blankData:null
            })
            window.location.reload()
            //setBasicValueSetting({...basicValueSetting,show:false})
        })
        .catch(error=>{  
            const tempObj={showModalError:true,message:catchErrorToMessage(error)}
            setError(tempObj)
            
        })
    }
}


const saveWorkDayHoliday=(activeDay,holiday)=>{
    //console.log('saveWorkDayHoliday')
    //console.log(activeDay)

    let tempActiveDay=[]
    activeDay.map(i=>{
        if(i.active){
            const {day,startHour,startMinute,endHour,endMinute}=i
            tempActiveDay=[...tempActiveDay,
                {day:parseInt(day),
                startHour:parseInt(startHour),
                startMinute:parseInt(startMinute),
                endHour:parseInt(endHour),
                endMinute:parseInt(endMinute)
                }
            ]
        }

    })

    let tempHoliday=[]
    holiday.map(i=>{
        const {date,month}=i
        tempHoliday=[...tempHoliday,{date:parseInt(date),month:parseInt(month)}]

    })

    //console.log('holiday')
    //console.log(holiday)

    const tempObj={id:basicData.id,holiday:tempHoliday,activeDay:tempActiveDay}

    axios.post(`/p35basicdata/updatecustom`,tempObj,myheader,shouldRender)
        .then(result=>{
            //resolve(true)
            //setReloadBasicData(true)
            setShowDateSetting(false)
            
            window.location.reload()
            //setBasicValueSetting({...basicValueSetting,show:false})
        })
        .catch(error=>{  
            const tempObj={showModalError:true,message:catchErrorToMessage(error)}
            setError(tempObj)
            
        })
    
}


const savePrintTableTemplateFunc=(tableTemplate)=>{
    //console.log('savePrintTableTemplateFunc')

    const tableName="printTableTemplate"
    saveTableTemplate(tableTemplate,'p35branch',tableName,myheader,shouldRender)

}

const saveTableTemplateFunc=(tableTemplate)=>{
    const key=modalTableState.key
    let tableName

    if(key=="transaction"){
        tableName="basicDataTransactionTableTemplate"
    }
    if(key=="endBill"){
        tableName="basicDataEndBillTableTemplate"
    }
    if(key=="effectType"){
        tableName="basicDataEffectTypeTableTemplate"
    }
    //console.log(tableTemplate)
    //console.log(tempObj)
    //setMyTableTemplate({...myTableTemplate,[tableName]:tempObj})
    saveTableTemplate(tableTemplate,'p35branch',tableName,myheader,shouldRender)
}

const saveTableTemplateWithTableNameFunc=(tableTemplate,tableName)=>{
    //console.log('saveTableTemplateWithTableNameFunc')
    //console.log(tableTemplate)
    //console.log(tableName)
    //setMyTableTemplate({...myTableTemplate,[tableName]:tempObj})
    saveTableTemplate(tableTemplate,'p35branch',tableName,myheader,shouldRender)
}

const tempFunc2=(key,lb,array)=>{
    //console.log('tempFunc2.........')

    if(key=="transaction"){
        setShowTransaction(true)
        setModalTableState({
            key:"transaction",
            showModalTable:false,
            pageForm:null,
            tableTemplate:null,
            data:null,
            blankData:null
        })
    }


    else if (key=="endBill"||key=="effectType"){
        let capKey
        let blankData
        let idKey
        if(key=="endBill"){
            capKey="EndBill"
            blankData={
                endBillActive:true,
                endBillId:Date.now(),
                endBillName:"",
                endBillEffect:"+",
                endBillInPercentage:true,
                endBillRate:0,
                endBillInPercentageOf:"total"
            }
            idKey="endBillId"
        }

        if(key=="effectType"){
            capKey="EffectType"
            blankData={
                effectId:Date.now(),
                effectName:"",
                effectEndBillId:null,
                effectEndBillName:"total"
            }
            idKey="effectId"
        }

        setModalTableState({
            key:key,
            showModalTable:true,
            pageForm:pageData[`${key}Form`],
            tableTemplate:myTableTemplate[`basicData${capKey}TableTemplate`],
            data:basicData[key],
            blankData:blankData,
            effecting:capKey=="EffectType"?basicData.effecting:null,
            idKey:idKey,
        })
    }
    else if(key=="branch"){
        setBasicValueSetting2({
            key:key,
            show:true,
            lb:lb,
            array:basicData.branch
        })
    }
    else {
        setBasicValueSetting({
            key:key,
            show:true,
            lb:lb,
            array:array
        })
    }
}

const renderSettingLine=(lb,func)=>{
    return (
        <div style={{display:"flex"}}>   
            <div>{lb}</div>
            
            <MdEdit className="sm-icon" style={{marginLeft:"0rem"}}
                onClick={()=>func()}
            />
        </div>
    )
}
//-------------------------------------------------
//-------------------------------------------------
const renderShopPayment=()=>{
    return(
    <div className="Modal-background">
        <div className="Modal-box">
         
            <div className="Modal-body">
                <div className ="flex-center-center">
                    
                    <ShopPayment
                        pageData={LogInLangTemplate[basicData.languageSetting]}
                        setShowShopMonthlyFee={setShowShopPaymentFunc2}
                        showCaptcha={false}
                    />
                    
                </div>
            </div>

            {
            /*
            <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                            //setShow(false)
                            //if(setMessage){setMessage(null)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>
            */
            }
        </div>
    </div>
    )
}


const renderSetting=()=>{
    const {formHead,changePassword,addNewUser,routeAuth,
           language,printPage,stickerPage,transactionType,
           widthLeft,dateSetting,shopPayment
           //effectType,
           //effecting,
           //transaction 
        
        } = pageData.setting
    return(
        <div className="w-100"
        style={{
                marginBottom:"0.5rem",
                padding:"0.5rem"
        }}>
            <div className="w-100 bd-pureWhite"  style={{padding:"0.5rem"}}>
                <h5>{formHead}</h5>
    
                {renderSettingLine(changePassword,setShowShopChangePasswordFunc)}
                {renderSettingLine(addNewUser,setShowAddUserFunc)}
                {renderSettingLine(routeAuth,setShowRouteAuthFunc)}
                {renderSettingLine(language,setShowLanguageFunc)}
                {renderSettingLine(printPage,setShowPrintPageFunc)}
                {renderSettingLine(stickerPage,setShowStickerPageFunc)}

                {
                renderSettingLine(transactionType,setShowTransactionFunc)
                }
                {
                renderSettingLine(dateSetting,setShowDateSettingFunc)   
                }
                {
                renderSettingLine(shopPayment,setShowShopPaymentFunc)   
                }
            </div>
        </div>
    )   
}


const renderBasicValueLine=(key,func,array)=>{

    const lb=pageData.basicValue[key]


    return(
        <div className="xc6 sc12" style={{margin:"0.2rem 0"}}>
            <div style={{display:"flex",width:"100%"}}>   
                <div className="w-35">{lb}</div>
                
                <div className="w-50">
                    <select>
                        {
                        array.map((i,idx)=><option key={idx}>{i}</option>)
                        }

                    </select>
                </div>
                
                <MdEdit className="sm-icon" style={{marginLeft:"0rem"}}
                    onClick={()=>func(key,lb,array)}
                />
            </div>
        </div>
    )
    
}

const renderBasicValue=()=>{

    return(
        <div className="w-100"
        style={{
                marginBottom:"0.5rem",
                padding:"0.5rem"
        }}>
            <div className="w-100 bd-pureWhite"
                 style={{padding:"0.5rem"}}
            >
                <div  
                    >
                    <h5>{pageData.basicValue.formHead}</h5>
                </div>
                <div className="flex-center-center jc-start">
                    
                    {renderBasicValueLine("title",tempFunc2,basicData.title)}
                    {renderBasicValueLine("unit",tempFunc2,basicData.unit)}
                    {renderBasicValueLine("userLevel",tempFunc2,basicData.userLevel)}

                    
                    {
                        //renderBasicValueLine("transactionStatus",tempFunc2,basicData.transactionStatus)
                    }
                    {
                        //renderBasicValueLine("jobStatus",tempFunc2,basicData.jobStatus)
                    }

                    {
                        //renderBasicValueLine("tableStatus",tempFunc2,basicData.tableStatus)
                    }
                    {
                        renderBasicValueLine("customerType",tempFunc2,basicData.customerType)
                    }
                    {
                        renderBasicValueLine("supplierType",tempFunc2,basicData.supplierType)
                    }
                    {
                        renderBasicValueLine("paymentType",tempFunc2,basicData.paymentType)
                    } 
                    {
                        renderBasicValueLine("taskType",tempFunc2,basicData.taskType)
                    } 
             
                    {renderBasicValueLine("endBill",tempFunc2,genArrayFromArrayObj(basicData.endBill,"endBillName"))}

                    {renderBasicValueLine("effectType",tempFunc2,genArrayFromArrayObj(basicData.effectType,"effectName"))}

                    {
                    //renderBasicValueLine("transaction",tempFunc2,genArrayFromArrayObj(basicData.transaction,"transactionType"))
                    }

                </div>
            </div>
        </div>
        
    )
}


return(
<div className="w-100 h-100" style={{overflowY:"auto"}} >

    {
    pageData&&renderSetting()
    }

    {
    pageData&&renderBasicValue()
    }

    {showPrintPage&&basicData.printPage
    ?<PrintPage
        pageData={pageData}
        submitFunction={updatePrintPageFunc}
        //submitFunction2={updatePrintPageFunc2}
        setShow={(value)=>setShowPrintPage(value)}
        printPage={basicData.printPage}
        basicData={basicData}
        addPrintPage={addPrintPage}
        deletePrintPage={deletePrintPage}
        tableTemplate={tableTemplate}
        saveTableTemplateFunc={savePrintTableTemplateFunc}
        setShowPrintFunc={setShowPrintFunc}
        myheader={myheader}
    />
    :null
    }
    {showStickerPage&&basicData.stickerPage
    ?<StickerPage
        pageData={pageData}
        submitFunction={updateStickerPage}
        submitFunction2={updateStickerPageFunc2}
        setShow={(value)=>setShowStickerPage(value)}
        stickerPage={basicData.stickerPage}
        basicData={basicData}
        addStickerPage={addStickerPage}
        deleteStickerPage={deleteStickerPage}
   
        tableTemplate={null}
        saveTableTemplateFunc={()=>{}}
        setShowPrintFunc={()=>{}}
        myheader={myheader}

    />
    :null
    }

    {
     showShopChangePassword
     ?<ShopChangePassword
        pageData={pageData}
        setShow={setShowShopChangePassword}
        submitFunction={updateShopChangePasswordFunc}
    />
     :null
    }

    {
     showAddUser
     ?<AddUser
        pageData={pageData}
        basicData={basicData}
        shop={shop}
        tableTemplate={tableTemplate}
        myheader={myheader}
        setShow={setShowAddUser}
        submitFunction={updateAddUserFunc}
        setReloadBasicData={setReloadBasicData}
        allBranch={allBranch}
    />
     :null
    }

    {
    basicValueSetting.show
    ?<BasicValueSetting
        pageData={pageData}
        basicData={basicData}
        basicValueSetting={basicValueSetting}
        setShow={setShowBasicValueSettingFunc}
        submitFunction={updateBasicValueSettingFunc}
    />
    :null
    }

    {/*
    basicValueSetting2.show
    ?<BasicValueSetting2
        pageData={pageData}
        basicData={basicData}
        basicValueSetting={basicValueSetting2}
        setShow={setShowBasicValueSettingFunc2}
        submitFunction={updateBasicValueSettingFunc2}
    />
    :null
    */}
    
    {
    showRouteAuth
    ?<RouteAuth
        myheader={myheader}
        pageData={pageData}
        basicData={basicData}
        setShow={setShowRouteAuth}
        submitFunction={updateRouteAuthFunc}
        basicDataTableTemplate={basicDataTableTemplate}
    />
    :null
    }

    {
     showLanguage
     ?<Language
        pageData={pageData}
        basicData={basicData}
        setShow={setShowLanguage}
        submitFunction={updateLanguageSettingFunc}
        submitFunction2={updateLanguageSettingFunc2}
        myheader={myheader}
        languageArray={languageArray}

     />
     :null   
    }

    {modalTableState.showModalTable
    ?<ModalTable
        modalTableState={modalTableState}
        submitFunc={updateBasicDataFunc}
        cancelFunc={(value)=>setModalTableState({...modalTableState,showModalTable:value})}
        basicData={basicData}
        user={user}
        saveTableTemplateFunc={saveTableTemplateFunc}
        pageData={pageData}
    />
    :null
    }

    {showTransaction
    ?<TransactionSetting
        basicData={basicData}
        pageData={pageData}
        submitFunc={updateBasicDataFunc}
        cancelFunc={()=>setShowTransaction(false)}
        tableTemplate={tableTemplate}
        saveTableTemplateWithTableNameFunc={saveTableTemplateWithTableNameFunc}
        myheader={myheader}
    />
    :null
    }

    {showDateSetting
    ?<DateSetting
        basicData={basicData}
        pageData={pageData}
        setShow={setShowDateSetting}
        confirmFunc2={saveWorkDayHoliday}
    />
    :null
    
    }

    {showShopPayment
    ?renderShopPayment()
    :null
    }


    {  
    error.showModalError
    ?renderModalError({
        setShow:setShowModalErrorFunc,
        message:error.message
    })
    :null
    }
</div>
)
}
export default ShopInfo;

/*
          
  





*/

import React from 'react';
import { BsNutFill } from 'react-icons/bs';
import {FaBan,FaCheck,FaFolder,FaCaretSquareDown} from 'react-icons/fa';
import {MdEdit,MdDelete,MdAddCircle} from 'react-icons/md';
import uuid from 'react-uuid'

import Table from '../../component/table/Table'
import console from '../../util/consoleVerify'



function PageFormForTableTemplate(props) {


const {setShow,submitFunction,loadData,formTitle}=props

const [inputState,setInputState]=React.useState(
    {id:loadData.id,
     shopId:loadData.shopId,
     updatedAt:new Date().toISOString(),
     tableTemplate:loadData.tableTemplate
    }
)

const [editTableName,setEditTableName]=React.useState(null)
const [editData,setEditData]=React.useState(null)
const [editTableIdx,setEditTableIdx]=React.useState(null)

React.useEffect(()=>{
    //console.log('editData........hhh........')
    //console.log(editData)
},[editData])

const blankColumn={
    colName:"",
    colOrder:1,
    lb:'',
    type:"number", 
    width:60, 
    showCol:true,  
    showColHead:true,
    showSum:false
}

const blankTable={
    tableName:"New Table",
    template:[
        blankColumn
    ]
}


const [tableTemplate,setTableTemplate]=React.useState({
    selectedLine:{colOrder:1 ,lb:'',type:"radio", width:60, showCol:true,  showColHead:true},
    colName:{colOrder:2 ,lb:'colName',type:"string", width:150, showCol:true,  showColHead:true},
    colOrder:{colOrder:3 ,lb:'colOrder',type:"number", width:50,   showCol:true,  showColHead:true},
    lb:{colOrder:4 ,lb:'lb',type:"string", width:100,   showCol:true,  showColHead:true},
    type:{colOrder:5 ,lb:'type',type:"strubg", width:100,   showCol:true,  showColHead:true},  
    width:{colOrder:6 ,lb:'width',type:"number", width:80,   showCol:true,  showColHead:true},
    showCol:{colOrder:7 ,lb:'showCol',type:"boolean", width:60,   showCol:true,  showColHead:true},
    showColHead:{colOrder:8 ,lb:'showColHead',type:"boolean", width:60,   showCol:true,  showColHead:true},
    showSum:{colOrder:9 ,lb:'showSum',type:"boolean", width:60,   showCol:true,  showColHead:true},
})

const editColumnForm=[
    {key:"colName",     type:"string"},
    {key:"colOrder",    type:"number"},
    {key:"lb",          type:"string"},
    {key:"type",        type:"string"},
    {key:"width",       type:"number"},
    {key:"showCol",     type:"checkbox"},
    {key:"showColHead", type:"checkbox"},
    {key:"showSum",     type:"checkbox"}   
]


const renderEditForm=(i)=>{
    return (
        <div className="w-100" 
            style={{display:"flex",flexWrap:"wrap"}}
        >
            {
                 editColumnForm.map((m,idxM)=>{
                     return(
                        <div key={idxM} className="xc6 flex-center-center jc-start"
                             style={{marginBottom:"0.2rem"}}
                        >
                            <div className='xc5'>
                                {m.key}
                            </div>
                            <div className='xc5'>
                                {m.type=="checkbox"
                                ?<input
                                    type="checkbox"
                                    checked={editData[m.key]}
                                    onChange={e=>{
                                        let tempCheck
                                        if(editData[m.key]){
                                            tempCheck=false
                                        }
                                        else {
                                            tempCheck=true
                                        }
            
                                        setEditData({
                                            ...editData,
                                            [m.key]:tempCheck
                                        })
                                    }}
                                />
                                :<input
                                    type={m.type}
                                    value={editData[m.key]}
                                    onChange={e=>{
                                        setEditData({
                                            ...editData,
                                            [m.key]:e.target.value
                                        })
                                    }}
                                />
            
                                }
                            </div>

                        </div>
                     )
                 })
            }

            <div className='w-100 flex-center-center jc-end'
                 style={{marginBottom:"1rem"}}
            >
                

                <button
                    onClick={e=>{
                        let tempArray=[]
                        let tempTable=i
                        let allTableArray=[]

                        inputState.tableTemplate.map((k,idxK)=>{
                            
                            if(k.tableName==i.tableName){                                     
                                
                                k.template.map((q,idxQ)=>{
                                    if(editData._id==q._id){
                                        tempArray=[...tempArray,editData]
                                    }
                                    else{
                                        tempArray=[...tempArray,q]
                                    }

                                })


                                tempTable={...tempTable,template:tempArray}
                                allTableArray=[...allTableArray,tempTable]
                            }
                            else{
                                allTableArray=[...allTableArray,k]
                            }
                            
                        })
                        
                        //console.log(allTableArray)

                        setInputState({
                            ...inputState,
                            tableTemplate:allTableArray
                        })
                        setEditData(null)
                    }}
                >
                    <FaCheck/>OK
                </button>

                <button
                    onClick={e=>{
                        setEditData(null)
                    }}
                >
                    <FaBan/>
                </button>

            </div>
        </div>
    )
}
  



const renderFooter=()=>{
    return(
    <div className='' 
        style={{
                position:"fixed",
                bottom:"2.5rem",
                right:"2rem",
                zIndex:"100"
                
                }}
    >
        <div style={{display:"flex"}}>
            <div style={{marginRight:"10rem"}}>
                
                <button className=''
                    style={{backgroundColor:"blue"}}
                    onClick={e=>{
                        setInputState({
                            ...inputState,
                            tableTemplate:[...inputState.tableTemplate,blankTable]
                        })
                    }}
                >
                    <MdAddCircle className=""/>
                </button>


                <button
                    style={{backgroundColor:"blue",
                           visibility:editTableName?"visible":"hidden",
                    }}
                    onClick={e=>{
                        let tempArray=[]
                        inputState.tableTemplate.map((n,idxN)=>{
                            if(idxN==editTableIdx){}
                            else{
                                tempArray=[...tempArray,n]
                            }
                        })
                        setInputState({...inputState,
                            tableTemplate:tempArray
                        })
                        setEditTableIdx(null)
                        setEditTableName("")
                    }}
                >
                    <div className='flex-center-center'>
                        <MdDelete className=""/>
                        {editTableName
                            ?<div>{`${editTableIdx}.${editTableName}`}</div>
                            :null
                        }
                    </div>
                </button>

            </div>

            <div style={{marginLeft:"",display:"flex"}}>
                <button
                    onClick={e=>{
                        if(submitFunction){
                            submitFunction(inputState,loadData)
                        }
                    }}
                >
                    <FaCheck/>
                </button>
       
                <button
                    onClick={e=>{
                        setShow(false)
                    }}
                >
                    <FaBan/>
                </button>
            </div>
        </div>
    </div>
    )
}

//-----------------------------------------

return (
    <div className="w-100 h-100"
         style={{
            
            overflow:"scroll"
        
        }}>
        <div className="w-100">
            {formTitle}
        </div>
        {
            
          inputState.tableTemplate.map((i,idxI)=>{
              return(
                  <div className='w-100 bd-pureWhite' key={idxI}
                       style={{marginBottom:"1rem"}}
                  >
                          <div className='w-100'
                               style={{display:"flex"}}
                          >
                            <FaCaretSquareDown className="md-icon"
                                style={{margin:"0 0.5rem"}}
                                onClick={e=>{
                                    setEditTableName(i.tableName)
                                    setEditTableIdx(idxI)
                                    setEditData(null)
                                }}  
                            /> 

                            <MdAddCircle className="md-icon"
                                style={{margin:"0 0.5rem"}}
                                onClick={e=>{
                                    
                                    let tempArray=[]
                                    let tempTable=i
                                    let allTableArray=[]

                                    inputState.tableTemplate.map((k,idxK)=>{
                                        
                                        if(k.tableName==i.tableName){                                     
                                            tempArray=[...k.template,{...blankColumn,_id:uuid()}]
                                            tempTable={...tempTable,template:tempArray}
                                            allTableArray=[...allTableArray,tempTable]
                                        }
                                        else{
                                            allTableArray=[...allTableArray,k]
                                        }
                                        
                                    })
                                    
                                    //console.log(allTableArray)

                                    setInputState({
                                        ...inputState,
                                        tableTemplate:allTableArray
                                    })
                                }}
                            />
                            {
                            editData
                            ?<MdDelete className='md-icon'
                                onClick={e=>{
                                 
                                    let tempArray=[]
                                    let tempTable=i
                                    let allTableArray=[]
            
                                    inputState.tableTemplate.map((k,idxK)=>{
                                        
                                        if(k.tableName==i.tableName){                                     
                                            
                                            k.template.map((q,idxQ)=>{
                                                if(editData._id==q._id){
                                                    //tempArray=[...tempArray,editData]
                                                }
                                                else{
                                                    tempArray=[...tempArray,q]
                                                }
            
                                            })

                                            tempTable={...tempTable,template:tempArray}
                                            allTableArray=[...allTableArray,tempTable]
                                        }
                                        else{
                                            allTableArray=[...allTableArray,k]
                                        }
                                        
                                    })
                                    
                                    //console.log(allTableArray)
            
                                    setInputState({
                                        ...inputState,
                                        tableTemplate:allTableArray
                                    })
                                    setEditData(null)


                                }}

                            />
                            :null
                            }

                          </div>

                        
                          { editTableName==i.tableName&&editData
                            ?renderEditForm(i)
                            :null
                          }

                          <div className="w-100">
                            {editTableIdx==idxI&&editTableName
                            ?
                            <div className="w-100" style={{display:"flex"}}>
                                <div className="xc10">
                                    <input
                                        type="text"
                                        value={editTableName}
                                        onChange={e=>{
                                            setEditTableName(e.target.value)
                                        }}
                                    />
                                </div>
                                <button className='xc2'
                                    onClick={e=>{
                                        //console.log('..........editTableName,,,,,,,')
                                        //console.log(editTableName)
                                        let allTableArray=[]
                
                                        inputState.tableTemplate.map((k,idxK)=>{
                                            
                                            if(idxK==idxI){
                                                //console.log('idxK...........')    
                                                //console.log(idxK)                                
                                                const temp3={...k,tableName:editTableName}
                                                allTableArray=[...allTableArray,temp3]
                                                //allTableArray=[...allTableArray,k]
                                            }
                                            else{
                                                allTableArray=[...allTableArray,k]
                                            }
                                            
                                        })
                                        
                                        //console.log(allTableArray)
                
                                        setInputState({
                                            ...inputState,
                                            tableTemplate:allTableArray
                                        })
                                        setEditTableName("")
                                    }}
                                >
                                    <FaCheck/>
                                </button>
                            </div>
                            :<div>
                                {i.tableName}
                            </div>
                            }
                          </div>
                      <div className='w-100'>
                        {editTableIdx==idxI
                        ?<Table
                                colorHead={"red"}
                                tableTemplate={tableTemplate}
                                setTableTemplate={setTableTemplate}

                                filterData={i.template}
                                setFilterData={()=>{}}
                                
                                editData={editData}
                                setEditData={setEditData}
                                saveTableTemplateFunc={()=>{}}
                                isSubTable={false}
                                updateFilterData={()=>{}}
                                useInput={false}
                        />
                        :null
                        }
                      </div>
                     
                  </div>
              )
          })  

        }

        {
        renderFooter()
        }
    </div>
)

}



PageFormForTableTemplate.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}



export default PageFormForTableTemplate;
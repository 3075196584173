
const stickerInputState ={
    id:1,
    recordName:"abcd",
    date:new Date().toISOString(),
    recordBy:"Peter",
    
    heightP:"",
    widthP:100,

    useMm:false,
    paddingTopP:5,
    paddingBottomP:5,
    paddingLeftP:5,
    paddingRightP:5,
    
    //barcodeValue:'P-0101',
    showBarCode:true,
    heightB:20,
    widthB:1,
    xPerLine:1,

    showBorder:true,
    gridRowGap:2,
    gridColumnGap:2,
    
    showCode:true,
    fontSizeCode:10,

    showName:true,
    fontSizeName:10,
    
    showPrice:true,
    fontSizePrice:10,
    
    showUnit:true,
    fontSizeUnit:10,

    scale:3.7,
    marginTopBarcode:37,
    marginBottomBarcode:37,
    
    productData : [
        {
           code:"1234a",
           id:"1234a",
           barcode:"1234b",
           sn:"1234c",
           name:"coloa",
           price:100,
           unit:"ขวด",
           qty:2,
           black:true
        },
        {  
           code:"4aa5a",
           id:"4aa5a",
           barcode:"4aa5b",
           sn:"4aa5c",
           name:"pepsi",
           price:2,
           unit:"ขวด",
           qty:5,
           black:true
        },
    ]
    
}

export default stickerInputState

import React from 'react';
import GallerySinglePhoto_add from '../../component/galleryone_add/GallerySinglePhoto_add'
import photoUtil from '../../component/galleryone_add/photoUtil'
import axios from '../../util/axiosVerify'
import pageUtil from '../../component/pageComponent/pageUtil'

import {FaBan,FaCheck,FaFolder} from 'react-icons/fa';
import {MdEdit} from 'react-icons/md';

import console from '../../util/consoleVerify'



const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete,
    genCsv,genXlsx
} = pageUtil

function GroupPageForm({
    lb,
    loadData,
    setShow,
    submitFunction,
    pageData,
    stateTemplate,
    myheader,
    filterData,
    clickRefReload
}) {
    
    const {changeArrayFile}=photoUtil

    const shouldRender= React.useRef(true)
    React.useEffect(()=>{
        return () => {
            //console.log('end.................GroupPageForm................')
            shouldRender.current=false
        } 
    },[])

    const refSubmitForm=React.createRef() //ตกลง
    const refCancelForm=React.createRef() //ยกเลิก     

    const [formInputState,setFormInputState]=React.useState({
        id:loadData.id,
        newId:loadData.id,
        groupName:loadData.groupName,
        gcode:loadData.gcode,
        level:loadData.level,
        file:null,
        photoUrl1:loadData.photoUrl1
    })

    const [showGroupDetail,setShowGroupDetail]=React.useState(false)

  //===================================
  //===================================

  //image
  // 1 = photoUrl1
  // 2 = photoUrl1 , photoUrl2
  
  //---------------
  //We limiet only 2 image which is photoUrl1,photoUrl2 perform
  //image1
  
  const [showImage1,setShowImage1]=React.useState(true)
  const [arrayFile1,setArrayFile1]=React.useState([])
  const [fileUrl1,setFileUrl1]=React.useState([])

  React.useEffect(()=>{
    //console.log('useEffect ArrayFile1')    
            changeArrayFile({ arrayFile:arrayFile1,
                fileUrl:fileUrl1,
                setFileUrl:setFileUrl1,
                inputState:formInputState,
                setInputState:setFormInputState,
                keyName:"photoUrl1",
                //fileName,
                //serverFolder,
                //fileName:"file",
                //serverFolder:"/upload/customer",
                setShowImage:setShowImage1})    
  },[arrayFile1])
  //----------------
//==============================  
const submitFunctionEditNoPhoto=()=>{

    //submitFunctionEditNoPhoto=(formInputState,filterData,productState,myheader,shouldRender,manyImage)

    //console.log('submitFunctionEditFunc')
    //console.log(tempFormInputState)
    const tempFormInputState={
        id:formInputState.id,
        newId:formInputState.newId,
        groupName:formInputState.groupName,
        gcode:formInputState.gcode,
        level:formInputState.level
    }
    if(formInputState){
        
            submitFunctionEdit(tempFormInputState,
                    {dataUrl:"p35group"},
                    stateTemplate,
                    myheader,
                    shouldRender
            )
            .then(result=>{
                //setFilterData(result)
                setShow(false)
                setShowGroupDetail(false)
                clickRefReload(filterData)
                //refReload.current.click()
                //setFilterData({...filterData,reloadData:true})
            })
            .catch(error=>{
                //setFilterData(error)
              }
            )
        
    }
    

}


//==============================
const renderFooter2=()=>{
    return(
    <div className='' 
        style={{display:"flex",
                 position:"fixed",
                 bottom:"4rem",
                 right:"2rem",
                 zIndex:"100"
                }}
    >
            <div style={{marginLeft:"8rem"}}>
                <button
                    style={{color:"red"}}
                    ref={refSubmitForm}
                    onKeyDown={e=>{
                        if(e.key=="ArrowRight"){
                            refCancelForm.current.focus()
                        }
                    }}
                    onClick={e=>{
                        //submitFunction(formInputState)
                        submitFunctionEditNoPhoto()
                    }}
                >
                    <FaCheck/>
                </button>
            </div>
          
            <div>
                <button
                    style={{color:"red"}}

                    ref={refCancelForm}
                    onKeyDown={e=>{
                        if(e.key=="ArrowLeft"){
                            refCancelForm.current.focus()
                        }
                    }}
                    onClick={e=>{
                        setShow(false)
                        setShowGroupDetail(false)
                    }}
                >
                    <FaBan/>
                </button>
            </div>
    </div>
    )
}

//==============================
//formHead: "แก้ไขกลุ่มสินค้า"
//gcode: "โค้ดกลุ่ม"
//groupName: "ชื่อกลุ่ม"
//id: "ไอดี"
//level: "ระดับกลุ่ม"

const handleChange=(e,k)=>{

    if(k=="gcode"){
        const temp=e.target.value.split("/")
        const tempLength=temp.length
        console.log(temp)
        console.log(temp[tempLength-1])
        setFormInputState({...formInputState,
            [k]:e.target.value,
            level:tempLength,
            groupName:temp[tempLength-1]
            }
        )
    }
    else{
        setFormInputState({...formInputState,
            [k]:e.target.value}
        )
    }
}

const ObjectKey=[
    "id","newId","groupName","level","gcode"
]

const renderFormBody=()=>{
    return ObjectKey.map((k,idxK)=>{
        return (
        <div className="flex-center-center jc-start w-50"
            key={idxK} style={{padding:"0.3rem"}} >
            <div className="xc4"
            >
               {pageData.editGroupForm[k]}
            </div>
            <div className="xc8">
              <input
                  type="text"
                  value={formInputState[k]}
                  onChange={e=>{
                     handleChange(e,k)
                  }}
                  
                  disabled={k=="level"||k=="groupName"||k=="id"?"disabled":""}
               />
            </div>
         </div>
        )
    })

    
}

//=============================
const renderGroupDetailForm=()=>{
    return(
        <div className="Modal-box bgc-trans h-100" 
             style={{paddingBottom:"7rem",width:"100%"}}>

            {   pageData.editGroupForm&&
                renderFormBody()
            }
            
            
            {
                renderFooter2()
            }
        </div>
    )

}


//====================
const renderFooter=()=>{
    return(
    <div className='' 
        style={{display:"flex",
                 position:"fixed",
                 bottom:"4rem",
                 right:"2rem",
                 zIndex:"100"
                }}
    >
            <div style={{marginLeft:"8rem"}}>
                <button
                    ref={refSubmitForm}
                    onKeyDown={e=>{
                        if(e.key=="ArrowRight"){
                            refCancelForm.current.focus()
                        }
                    }}
                    onClick={e=>{
                        submitFunction(formInputState)
                    }}
                >
                    <FaCheck/>
                </button>
            </div>
          
            <div>
                <button
                    ref={refCancelForm}
                    onKeyDown={e=>{
                        if(e.key=="ArrowLeft"){
                            refCancelForm.current.focus()
                        }
                    }}
                    onClick={e=>{
                        setShow(false)
                    }}
                >
                    <FaBan/>
                </button>
            </div>
    </div>
    )
}
//===============================
return (
    <div className="h-100 w-100 bgc-trans">
        {showGroupDetail&&pageData
        
        ?renderGroupDetailForm()
        :<div className="Modal-box bgc-trans h-100" 
             style={{paddingBottom:"7rem",width:"100%"}}>
            <div className="Modal-header bgc-trans">
                <div>
                </div>
                {renderFooter()}
            </div>
            <div className="Modal-body bgc-trans">
                <div style={{minWidth:"2rem"}}>
                    <MdEdit className='md-icon'
                            onClick={e=>{
                                console.log(pageData)
                                setShowGroupDetail(true)
                            }}
                    />
                </div>
                <div className="ModalInsideBody bgc-trans">
                    {`${loadData.id}.${loadData.groupName}`}
                </div>
                <div className="ModalInsideBody bgc-trans">
                    {`${loadData.gcode}`}
                </div>
                <div className="ModalInsideBody bgc-trans">
                    {`${loadData.level}`}
                </div>
            </div>

            <div className="xc12 h-100 flex-center-center">
                    <div className="xc6 sc10 bd-lightGray h-100">
                        {
                        showImage1
                        ?<GallerySinglePhoto_add 
                            fileUrl={fileUrl1}
                            arrayFile={arrayFile1}
                            setArrayFile={setArrayFile1}
                            keyName={"photoUrl1"}

                            setShowImage={setShowImage1}
                            inputState={formInputState}
                            setInputState={setFormInputState}
                        />
                        :null
                        }   
                    </div>    
            </div>
        </div>
        }
    </div>
  );
}

GroupPageForm.defaultProps={
    lb:"Form",
    loadData:null,
    setShow:()=>{},
    submitFunction:null,   
}


export default GroupPageForm;

import React from 'react';
import './TaskCalendar.css';
import axios from '../../util/axiosVerify'

import console from '../../util/consoleVerify'

import {FaPlusCircle,FaMinusCircle, FaLessThanEqual, FaBullseye} from 'react-icons/fa';
import {FaWindowClose} from 'react-icons/fa';
import axiosUtil from '../../util/axiosUtil';
import { GiBrokenTablet, GiConsoleController } from 'react-icons/gi';
import { GenIcon } from 'react-icons/lib';

//==================
function TaskCalendar(props) {

const {
        onMyClick,value,style,styleIcon,
        showDateOption,timeOption,
        days,thaiMonths,useTimeSetting,
        basicData,myheader,
        findTaskId
    }=props


//console.log(new Date(value))
//console.log(new Date(value).toISOString())

const shouldRender= React.useRef(true)

React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const taskList=[
    {id:1,taskName:"ประชุม",partnerId:1,name:"สวัสดี",title:"นาย",
     tableName:"T1",
     startDate:new Date(2022,5,9,10,0),endDate:new Date(2022,5,9,12,0)},
]


const [tableInDate,setTableInDate]=React.useState(null)

const findTaskOfMonth=(date)=>{
    //console.log('findTaskOfMonth')
    //console.log(date)

    const firstDate=new Date(date.getFullYear(),date.getMonth(),1)
    const lastDate=new Date(date.getFullYear(),date.getMonth()+1,0)

    const lastDate3=lastDate.getDate()

    const firstDate2=new Date(firstDate.setHours(0,0,0)).toISOString()
    const lastDate2=new Date(lastDate.setHours(24,0,0)).toISOString()

    //console.log('testDate')
    //console.log(testDate)

    //console.log(`firstDate = ${firstDate}`)
    //console.log(`lastDate = ${lastDate}`)
    //console.log(`firstDateISO = ${firstDate2}`)
    //console.log(`lastDateISO = ${lastDate2}`)

    const tempObj={pageNumber:1,limitRow:100,sort:{id:-1},
                  startDate:{$gte:firstDate2},
                  endDate:{$lt:lastDate2}
                }

    let finalArray=[]

    for (let i=1;i<=lastDate3;i++){
        //console.log(`i = ${i}`)
        finalArray=[...finalArray,{date:i,table:[]}]
    }

    //console.log(finalArray)
    //let tempResult=[]

   //axios.post(`/${dataUrl}/${myController}`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
   axios.post(`/p35task/getlimit`,tempObj,myheader,shouldRender)
   .then(result=>{
        //console.log('result')
        //console.log(result.data.data)
        const tempResult= result.data.data  
        
        tempResult.map((j,idxJ)=>{
                //console.log(j)
                const tempStartDate=new Date(j.startDate).getDate()

                //console.log('startDate')
                //console.log(tempStartDate)
                
                const temp2=new Date(j.endDate)


                const tempEndDate=temp2.getDate()
                //console.log('endDate')
                //console.log(tempEndDate)

                const tempEndDateHour=parseInt(temp2.getHours())
                //console.log('tempEndDateHour')
                //console.log(tempEndDateHour)

                const tempEndDateMin=parseInt(temp2.getMinutes())
                //console.log('tempEndDateMin')
                //console.log(tempEndDateMin)

                let check00=false

                if(tempEndDateHour==0&&tempEndDateMin==0){
                    check00=true
                }
                //console.log(`${idxJ}:startDate = ${tempStartDate}`)
                //console.log(`${idxJ}:endDate= ${tempEndDate}`)

                //console.log('check00')
                //console.log(check00)

                let tempArray2=[]
                finalArray.map((k,idxK)=>{
                    //console.log('k.....')
                    //console.log(k.date)
                    if(k.date>=tempStartDate&&k.date<=tempEndDate){
                        //console.log('found....')
                        //console.log(k)
                        if(k.date==tempEndDate&&check00){
                            tempArray2=[...tempArray2,k]
                        }
                        else{
                            const tempTable=[...k.table,j.tableName]
                            tempArray2=[...tempArray2,{...k,table:tempTable}]
                        }
                        
                    }
                    else{
                        tempArray2=[...tempArray2,k]
                    }
                })
                finalArray=tempArray2
                
        })

        //console.log('finalArray')
        //console.log(finalArray)

        setTableInDate(finalArray)
        //console.log('finalArray.....')
        //console.log(finalArray)
        //finalArray
   })

   
}


React.useEffect(()=>{
    findTaskOfMonth(new Date())
},[])

const genDate=(value)=>{
    //value is date in ISOstring which is UK time
    
    //console.log('genDate')
    //console.log(value)
    //findTaskOfMonth(new Date(value))
    if(value){
        let tempDate=new Date(value)

        if(timeOption){
            const {hour,minute,second}=timeOption
            tempDate=new Date(tempDate.setHours(hour,minute,second))
        }

        return tempDate//.toLocaleString('en-GB')

    }
    else{
        return new Date()//.toLocaleString('en-GB')
    }
    
    
}


const hourTemplate=[
    0,1,2,3,4,5,6,
    7,8,9,10,11,12,
    13,14,15,16,17,18,
    19,20,21,22,23
]
  
const minTemplate=[
    0,15,30,45,
]


const genDateString=(date)=>{
    
    const temp=new Date(date)
    const tempDate=temp.getDate()
    const tempMonth=temp.getMonth()+1
    const tempYear=temp.getFullYear()
    const tempDay=temp.getDay()
    let tempDay2=""
    if(tempDay==0){tempDay2="Sun-อาทิตย์"}
    else if(tempDay==1){tempDay2="Mon-จันทร์"}
    else if(tempDay==2){tempDay2="Tue-อังคาร"}
    else if(tempDay==3){tempDay2="Wed-พุธ"}
    else if(tempDay==4){tempDay2="Thu-พฤหัส"}
    else if(tempDay==5){tempDay2="Fri-ศุกร์"}
    else if(tempDay==6){tempDay2="Sat-เสาร์"}

    return tempDay2+" "+tempDate+"/"+tempMonth+"/"+tempYear
}

const genActivity=(date)=>{

    const tempDay=new Date(date).getDay()
    
    let tempActiveDayStartTime=null
    let tempActiveDayEndTime=null

    basicData.activeDay.map(d=>{
        //console.log('basicData.activeDate.d')
        //console.log(d)
        if(d.day==tempDay){
            tempActiveDayStartTime=(d.startHour*100+d.startMinute)/100
            tempActiveDayEndTime=(d.endHour*100+d.endMinute)/100
        }
    })


    let tempObj={
        taskId:null,
        taskName:null,
        partnerId:null,
        partnerName:null,
        date:date,
        startDateTime:null,
        startTime:null,
        startHour:null,
        startMinute:null,
        active:false,
        reserved:false
    }

    let tempArray=[]
    

    hourTemplate.map(hr=>{
        minTemplate.map(min=>{
            const tempTime=(hr*100+min)/100

            let tempActiveTime=false
            if(tempTime>=tempActiveDayStartTime&&
               tempTime<tempActiveDayEndTime){
                tempActiveTime=true
            }

            tempArray=[...tempArray,{
                ...tempObj,
                startDateTime:new Date(date.setHours(hr,min,0)),
                startTime:tempTime,
                startHour:hr,
                startMinute:min,
                active:tempActiveTime,
            }]

        })
    })

    return tempArray

}

/*
active: false
date: Wed Jun 01 2022 13:11:48 GMT+0700 (Indochina Time) {}

taskId: null
taskName: null
partnerId: null
partnerName: null

startHour: 0
startMinute: 0
startTime: 0

*/
const setDateFunc=(date)=>{

    if(showDaily){
        setReload(true)
        setDate(date)
    }
    else{
        setDate(date)
    }
}


const genActivityTask=(task,activity)=>{

    if(task.length>0){
        let tempArray1=[]

        //console.log('genActivityTask')
        //console.log(task)
        
        let tempTask=task

        activity.map(j=>{

            let tempArray2=[]
            
            j.activity.map(k=>{

                let tempObj=k
                let tempTaskArray=[]
                tempTask.map(t=>{
                    
                    let tempTaskObj=t

                    if(t.tableName==j.tableName){
                        //console.log('111111')                     
                        if(
                            new Date(k.startDateTime)>=new Date(t.startDate) &&
                            new Date(k.startDateTime)<new Date(t.endDate)
                        ){
                            //console.log('222222')

                            if(!t.isRecord){
                                tempObj={...tempObj,
                                    taskId:t.id,
                                    taskName:t.taskName,
                                    partnerId:t.partnerId,
                                    title:t.title,
                                    name:t.name,
                                    reserved:true,
                                    id:t.id
                                }
                                tempTaskObj={...tempTaskObj,isRecord:true}
                            }
                            else {
                                tempObj={...tempObj,
                                    reserved:true,
                                    id:t.id
                                }
                            }
                        
                        }

                    }

                    tempTaskArray=[...tempTaskArray,tempTaskObj]

                })
                tempTask=tempTaskArray
                tempArray2=[...tempArray2,tempObj]

            })

            tempArray1=[...tempArray1,{...j,activity:tempArray2}]

        })
        
    
        return tempArray1
    }
    else {
        return activity
    }
}




/*
const [task,setTask]=React.useState([])
const [count,setCount]=React.useState(0)
const [lastRecordId,setLastRecordId]=React.useState(0)
*/
const [date,setDate]=React.useState(genDate(value))
const [monthYear,setMonthYear]=React.useState({month:null,year:null})
const [activeDate,setActiveDate]=React.useState([])

const [activity,setActivity]=React.useState(null)

const [showDaily,setShowDaily]=React.useState(false)
const [intervalHeight,setIntervalHeight]=React.useState(0.8)


const [reload,setReload]=React.useState(false)


const checkActiveDate=(i)=>{

    let temp="0.2"

    let keepCheck=true
    activeDate.map(k=>{
        if(keepCheck){
            if(k==i){
                temp="1"
                keepCheck=false
            }
        }
    })
    return temp
}


//const tempA=new Date(2022,6,8,12,0)
//Fri Jul 08 2022 12:00:00 GMT+0700 (Indochina Time)


React.useEffect(()=>{

    const thisMonth=date.getMonth()+1
    const thisYear=date.getFullYear()

    //console.log('ssss')

    if(thisMonth!=monthYear.month||thisYear!=monthYear.year){
            const lastDate=new Date(date.getFullYear(),date.getMonth()+1,0).getDate()
            
            let unqDateArray=[]
            let thisMonthActivity=[]

            for (let d=1;d<=lastDate;d++){

                    const temp=new Date(date).setDate(d)
                    const tempDay=new Date(temp).getDay()
                            //let tempActive=false
                    let tempActiveDay=false

                    basicData.activeDay.map(atd=>{
                        if(!tempActiveDay){
                            if(atd.day==tempDay){
                                tempActiveDay=true
                            }
                        } 
                    })

                    basicData.holiday.map(hld=>{
                        //console.log('basicData.holiday.hld')
                        //console.log(hld)

                        if(hld.date==d&&hld.month==thisMonth){
                            tempActiveDay=false
                        }
                    })
                    
                    const tempObj={date:d,active:tempActiveDay}

                    if(tempActiveDay){
                        unqDateArray=[...unqDateArray,d]
                    }
                    thisMonthActivity=[...thisMonthActivity,tempObj]

            }

            setActivity(thisMonthActivity)
            setMonthYear({month:thisMonth,year:thisYear})
            setActiveDate(unqDateArray)
            
    }

},[date])


React.useEffect(()=>{

    if(reload){
        if(basicData.table){

            const tempActivity=genActivity(date)

            let tempArray=[]
            basicData.table.map(t=>{
                if(t.tableActive){
                    const tempObj={
                        tableName:t.tableName,
                        activity:tempActivity
                    }
                    tempArray=[...tempArray,tempObj]
                }            
            })

            //console.log('10')
            //console.log(tempArray)

            //let tempArray3=tempArray

            const tempDate1=new Date(date)
            const temp0000Date=new Date(tempDate1.setHours(0,0,0))
            const temp2359Date=new Date(tempDate1.setHours(23,59,59))

            
            axios.post(`/p35task/getlimit`,
                                    {pageNumber:1,limitRow:50,sort:{id:1},
                                     endDate:{$gt:temp0000Date},
                                     startDate:{$lt:temp2359Date}
                                    },
                                    myheader,shouldRender)
            .then(result=>{
                //console.log(result.data.data)
                let tempArray6=[]

                result.data.data.map(j=>{
                    tempArray6=[...tempArray6,{
                        ...j,
                        startDate:new Date(j.startDate),
                        endDate:new Date(j.endDate)
                    }]
                })

                const tempArray4=genActivityTask(tempArray6,tempArray)
                //const tempArray4=genActivityTask(taskList,tempArray)
                //const tempArray4=tempArray

                setReload(false)
                setActivity(tempArray4)
                setShowDaily(true)
            })
            
         
        }
        
    }

},[reload])



const getSelectedDateThisMonth=(i,date)=>{

    let temp=date.setDate(i)
    temp=new Date(temp)

    setDate(temp)

    onMyClick(temp.toISOString())

    //const dateUK="2021-12-31T18:50:41.750Z"
    //Sat Jan 01 2022 01:50:41 GMT+0700 (Indochina Time)
}


const genThaiMonth=(date)=>{

    const thaiMonth=thaiMonths[date.getMonth()]
    const thaiYear=date.getFullYear()//+543

    const temp=`${thaiMonth}-${thaiYear}`
    return temp
}

const genPrevMonthDay=(date)=>{

    const lastFullDayPrevMonth=new Date(date.getFullYear(),date.getMonth(),0)
    const lastDayPrevMonth=lastFullDayPrevMonth.getDay()
    const lastDatePrevMonth=lastFullDayPrevMonth.getDate()

    let prevMonthDay =[]
    for (let i=lastDatePrevMonth-lastDayPrevMonth;i<=lastDatePrevMonth;i++){
        prevMonthDay=[...prevMonthDay,i]
    }

    return prevMonthDay
}

const genThisMonthDay=(date)=>{
    
    const lastDate=new Date(date.getFullYear(),date.getMonth()+1,0).getDate()
    let thisMonthDay=[]
    
    for (let j=1;j<=lastDate;j++){
        thisMonthDay=[...thisMonthDay,j]
    }
   
    return thisMonthDay
}


const genNextMonthDay=(date)=>{
    const lastFullDayThisMonth=new Date(date.getFullYear(),date.getMonth()+1,0)
    const lastDayThisMonth=lastFullDayThisMonth.getDay()

    let nextMonthDay=[]
    for (let k=1;k<=6-lastDayThisMonth;k++){
        nextMonthDay=[...nextMonthDay,k]
    }
    return nextMonthDay
}


const genColor=(date)=>{
    let tempColor="#798EA4"
    const temp=new Date(date).getDay()
    
    if(temp==0){tempColor="#CD212A"}//red
    else if(temp==1){tempColor="yellow"}
    else if(temp==2){tempColor="pink"}
    else if(temp==3){tempColor="#00A591"}//green
    else if(temp==4){tempColor="orange"}
    else if(temp==5){tempColor="#92A8D1"}//blue
    else if(temp==6){tempColor="#6B5B95"}//violet

    return tempColor
}


const renderActivity=()=>{
    //console.log('renderActivity')
    //console.log(activity)
    return activity.map((j,idxJ)=>{
        return (
            <div className='bd-pureWhite'
                key={idxJ}
                style={{
                    height:"100%",minWidth:"8rem",maxWidth:"8rem",
                    backgroundColor:"deepBluee",
                    //overflowY:"scroll",
                }}
            >
                <div style={{
                    width:"100%",
                    height:"3rem",
                    zIndex:"10"
                    }}>
                    {j.tableName}
                </div>
                <div style={{
                    width:"100%",
                    //height:"90%",
                    overflow:"auto"
                    }}>
                {j.activity
                  ?j.activity.map((a,idxA)=>{
                      //console.log(a)
                      return a.active
                          ?<div key={idxA}
                                className="w-100"
                                style={{
                                        height:`${intervalHeight}rem`,
                                        borderTop:a.startMinute==0?"0.5px dotted gray":null,
                                        backgroundColor:a.reserved?"#a7c6eb":"#A0DAA9",
                                        cursor:a.reserved?"pointer":""
                                }}

                                onClick={e=>{
                                    //console.log('aa..')
                                    //console.log(a)
                                    findTaskId(a.id)
                                }}
                           >
                                <div
                                    style={{fontSize:"0.7rem",color:"#564b47"}}
                                >
                                    {a.startMinute==0
                                        ?`${a.startHour}:${a.startMinute}0`
                                        :'-'
                                    }
                                </div>

                                <div
                                     style={{width:"100%",
                                             paddingRight:"1rem"
                                            }}

                                >
                                    <div style={{width:"100%",fontSize:"1rem",color:"#bb3016",textAlign:"right"}}>
                                        {a.taskId}
                                    </div>
                                    <div style={{width:'100%',fontSize:"1rem",color:"#bb3016",textAlign:"right"}}>
                                        {a.taskName}
                                    </div>
                                    <div style={{width:'100%',fontSize:"1rem",color:"#bb3016",textAlign:"right"}}>
                                        {a.title}
                                    </div>
                                    <div style={{width:'100%',fontSize:"1rem",color:"#bb3016",textAlign:"right"}}>
                                        {a.name}
                                    </div>
                                   
                                </div>
                          </div>
                          :null
                      
                  })  
                  :null
                }
                </div>

                <div style={{
                    width:"100%",
                    height:"3rem",
                    zIndex:"10"
                    }}>
                    {j.tableName}
                </div>

            </div>
        )
    })
}


const genTable=(i)=>{
    let tempArray=[]

    tableInDate.map(j=>{
        if(i==j.date){
            tempArray=j.table
        }
    })

    //let tempArray2=[...new Set(tempArray)]
    tempArray.sort()

    return (
        <div className="" 
             style={{width:"100%",
                     height:"80%",
                     display:"block",
                     //display:"flex",
                     //flexWrap:"wrap",
                     //alignItems:"flex-start",
                     //justifyContent:"flex-start"
                     
                     overflow:"auto"
                     }}>
            {tempArray.map((q,idxQ)=>{
               
                    return(
                        <div className=""
                             key={idxQ}
                             style={{
                                   //marginLeft:"0.2rem",
                                   color:"brown",
                                   fontSize:"0.8rem",
                                   width:"100%",
                                   //height:"1.2rem",
                                   marginBottom:"0.3rem",
                                   padding:"0",
                                   lineHeight:"1.2rem",
                                   
                                }}
                        >
                        {q}
                        </div>
                    )
                
            })}
        </div>
    )
}

//==================================
return (
    <div className="" 
         style={{width:"100%",height:"100%"}}
    >

        <div className="w-100 h-100">
            <div className="month"
                style={{height:"2.5rem"}}
            >
                <div className="arrow"
                      onClick={e=>{
                      
                        const temp=date.setFullYear(date.getFullYear()-1)
                        const temp2=new Date(temp)
                        setDateFunc(temp2)
                        findTaskOfMonth(temp2)
                      }}
                 >
                    <i>&lt;&lt;</i>
                </div>


                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setMonth(date.getMonth()-1)
                        temp=new Date(temp)
                        setDateFunc(temp)
                        findTaskOfMonth(temp)

                      }}
                 >
                    <i>&lt;</i>
                </div>


                <div className="date">
                    <div>{genThaiMonth(date)}</div>
                </div>
               
                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setMonth(date.getMonth()+1)
                        temp=new Date(temp)
                        setDateFunc(temp)
                        findTaskOfMonth(temp)

                      }}
                >
                    <i>&gt;</i>
                </div>

                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setFullYear(date.getFullYear()+1)
                        temp=new Date(temp)
                        setDateFunc(temp)
                        findTaskOfMonth(temp)

                      }}
                >
                    <i>&gt;&gt;</i>
                </div>
               

            </div>



            {
             showDaily
            ?<div className='flex-center-center'
                  style={{backgroundColor:genColor(date),height:"2.5rem"}}
             >
                    <div className="arrow"
                        style={{width:"10%"}}
                        onClick={e=>{
                            let temp=date.setDate(date.getDate()-7)
                            temp=new Date(temp)
                            setDateFunc(temp)
                        }}
                    >
                        <i>&lt;&lt;</i>
                    </div>


                    <div className="arrow"
                        style={{width:"10%"}}
                        onClick={e=>{
                            let temp=date.setDate(date.getDate()-1)
                            temp=new Date(temp)
                            setDateFunc(temp)
                        }}
                    >
                        <i>&lt;</i>
                    </div>

                    <div>
                        {genDateString(date)}
                    </div>

                    
                  
                    <FaPlusCircle className='sm-icon'
                        onClick={e=>{
                            setIntervalHeight(intervalHeight+0.1)
                        }}
                    />    
                    
                    <FaMinusCircle className='sm-icon'
                        onClick={e=>{
                            setIntervalHeight(intervalHeight-0.1)
                        }}
                    />    

                    <FaWindowClose className='sm-icon'
                        onClick={e=>{
                            setShowDaily(false)
                        }}
                    />
                        

                    <div className="arrow"
                        style={{width:"10%"}}
                        onClick={e=>{
                            let temp=date.setDate(date.getDate()+1)
                            temp=new Date(temp)
                            setDateFunc(temp)
                        }}
                    >
                        <i>&gt;</i>
                    </div>

                    <div className="arrow"
                        style={{width:"10%"}}
                        onClick={e=>{
                            let temp=date.setDate(date.getDate()+7)
                            temp=new Date(temp)
                            setDateFunc(temp)
                        }}
                    >
                        <i>&gt;&gt;</i>
                    </div>   


            </div>
            :<div className="weekdays"
                 style={{height:"3rem"}}
            >
                {
                    days.map((i,index)=>{
                        return(
                            <div key={index}
                            >
                                {i}
                            </div>
                        )        
                    })
                }
            </div>
            
            }

            {showDaily
            ?<div 
                style={{width:"100%",
                        //height:"90%",
                        overflowX:"scroll",
                        overflowY:"hidden",
                        display:"flex",
                        flexWrap:"nowrap",

                    }}
            >
                {
                  renderActivity()
                }
            </div>
            :<div className="days h-90">
                
                {
                genPrevMonthDay(date).map((i,index)=>(
                    <div key={index} className="prev-date bd-pureWhite"
                         style={{height:"11vh"}}
                    >
                        {i}
                    </div>
                ))
                }

                {
                genThisMonthDay(date).map((i,index)=>{
                    const temp=checkActiveDate(i)
                    
                    return(
                            <div className={i==date.getDate()?"today":""}
                                style={{
                                    cursor:"pointer",
                                    height:"11vh",
                                    opacity:temp,
                                    border:"1px solid white",
                                    backgroundColor:temp=="1"?"#A0DAA9":null,
                                    display:"block"
                                }}
                                key={index} 
                                
                                onClick={e=>{
                                  
                                    if(temp==1){
                                        setReload(true)
                                        
                                        const temp3=date.setDate(i)
                                        const temp4=new Date(temp3)
                                        setDate(temp4)
                                     
                                    }
                                }}
                            >
                                <div style={{width:"100%",fontSize:"0.8rem"}}>
                                    {i}
                                </div>
                                { tableInDate
                                  ?genTable(i)
                                  :null
                                }
                              
                            </div>
                        )
                    
                })
                }
                
                {
                genNextMonthDay(date).map((i,index)=>(
                    <div key={index} 
                         className="next-date bd-pureWhite"
                        style={{height:"11vh"}}
                    >
                        {i}
                    </div>
                ))
                }

            </div>   
            
            }

           
        </div>
       
    </div>
  );
}


TaskCalendar.defaultProps={
    onMyClick:()=>{},
    findTaskId:()=>{},
    showDateOption:"date",
    timeOption:null,

    value:new Date().toISOString(),
    days:["Su/อา ","Mo/จ","Tu/อ","Wd/พ","Th/พฤ","Fr/ศ","Sa/ส"],
    thaiMonths:[
        "01","02","03","04","05","06",
        "07","08","09","10","11","12"
    ],
    useTimeSetting:false
}

export default TaskCalendar;




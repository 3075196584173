import React from 'react';
import {MdVisibility,MdVisibilityOff} from 'react-icons/md';

import {FaKeyboard
} from 'react-icons/fa'
import {FaBan,FaCheck} from 'react-icons/fa'
import console from '../../util/consoleVerify'
import showUtil from '../../util/showUtil'
import MyKeyPad from '../../page/login/MyKeyPad'
import '../Modal.css'

const {showPassword} = showUtil

function AddUserModal({
    pageData,
    basicData,
    setShow,
    submitFunction,
    loadData,
    shop,
    branch
    //submitCancel
}) {
    //console.log('AddUser')
    
    const refSubmitForm=React.createRef() //ตกลง
    const refCancelForm=React.createRef() //ยกเลิก    
    
    
    const refId=React.createRef() //ตกลง
    const refUsername=React.createRef() //ตกลง
    const refPassword=React.createRef() //ตกลง
    const refUserLevel=React.createRef() //ตกลง
    const refName=React.createRef() //ตกลง
    const refSurname=React.createRef() //ตกลง
    const refActive=React.createRef()
    const refBranchId=React.createRef()
    const refBranchName=React.createRef()

    const [hidePassword,setHidePassword]=React.useState(true)

    let [formInputState,setFormInputState]=React.useState(loadData)
  
    React.useEffect(()=>{

        //console.log('formInputState')
        //console.log(formInputState)

    },[formInputState])

    const [showKeyPad,setShowKeyPad]=React.useState({
        show:false,
        inputStateName:"",
        myI:""
     })
  //==============================
  //==============================
  //==============================
  //==============================



  const genInputRef=(i)=>{
    if(i=="id"){return refId}
    else if(i=="username"){return refUsername}
    else if(i=="password"){return refPassword}
    else if(i=="userLevel"){return refUserLevel}
    else if(i=="name"){return refName}
    else if(i=="surname"){return refSurname}
    else if(i=="active"){return refActive}
    else if(i=="brancId"){return refBranchId}
    else if(i=="branchName"){return refBranchName}
    else return null
  }


  const goToNextRef=(e,i)=>{
    if(e.key=="Enter"){
        if(i=="id"){refUsername.current.focus()}
        if(i=="username"){refPassword.current.focus()}
        if(i=="password"){refUserLevel.current.focus()}
        if(i=="userLevel"){refName.current.focus()}
        if(i=="name"){refSurname.current.focus()}
        if(i=="surname"){refActive.current.focus()}
        if(i=="active"){refBranchId.current.focus()}
        if(i=="branchId"){refSubmitForm.current.focus()}
    }
   }

   const changeInputState = (e,i)=>{
       if(i=="active"){
        setFormInputState({...formInputState,[i]:e.target.checked})
       }
       else {
        setFormInputState({...formInputState,[i]:e.target.value})
       }
   }

//==============================  
const clearForm=()=>{
    //setFormInputState(blankData)
}

//==============================
//===========================
const genInputType=(i)=>{
   if (i=="id") {
        return "number"
    } 
    else if(i=="active"){
        return "checkbox"
    }
    else if(i=="password"){
        return "password"
    }
    else {
        return "text"
    }
}
//===================

const renderBody=()=>{
    const objKeys=["id","username","password","userLevel",
                   "name","surname","active",
                  "branchId","branchName"
                    //"branch"
                    ]

    return (objKeys.map((i,idx)=>{

        if(i=="userLevel"){
            return(
            <div key={idx}
                 className="w-100 flex-center-center jc-start" 
                 style={{marginBottom:"0.2rem"}}>
               
                <div className="w-30">
                    {pageData.addUser[i]}
                </div>
                
                <div className="w-70">
                            <select
                                ref={genInputRef(i)}
                                onChange={e=>{
                                    changeInputState(e,i)
                                }}
                                onKeyDown={e=>{goToNextRef(e,i)}}
                            >
                            <option value="" hidden>...</option>
                            {
                                basicData[i].map((j,idx)=>
                                <option key={idx}
                                selected={formInputState[i]==j?"selected":""}
                                >{j}</option>
                                )
                            }
                            </select>
                    </div>
            </div>
            )
        }
        else if(i=="branchId"){
            return(
            <div key={idx}
                 className="w-100 flex-center-center jc-start" 
                 style={{marginBottom:"0.2rem"}}>
               
                <div className="w-30">{pageData.addUser[i]}</div>
                <div className="w-70">
                    <select
                            ref={genInputRef(i)}
                            onChange={e=>{
                                const temp=e.target.value.split(".")
                                //console.log('temp')
                                //console.log(temp)

                                setFormInputState({...formInputState,
                                    "branchId":parseInt(temp[0]),
                                    "branchName":temp[1]
                                })
                            }}
                            onKeyDown={e=>{
                                goToNextRef(e,i)
                            }}
                        >
                                <option value="" hidden>...</option>
                                {
                                    branch.map((j,idx)=>{
                                    const temp=`${j.id}.${j.branchName}`
                                    return(
                                        <option key={idx}
                                            selected={
                                               (formInputState["branchId"]+"."+formInputState["branchName"])==temp
                                               ?"selected"
                                               :""
                                            }
                                        >
                                        {`${j.id}.${j.branchName}`}
                                        </option>
                                    )
                                    }
                                    )
                                }
                    </select>
                </div>
            </div>
            )
        }
        else if(i=="monthlyFee"){
            return (
            <div key={idx}
                className="w-100 flex-center-center jc-start" 
                style={{marginBottom:"0.2rem"}}>
               
                <div className="w-30">{pageData.addUser[i]}</div>
                <div className="w-70" style={{position:"relative"}}>
                   {shop.monthlyFee}
                </div>
            </div>
            )
        }
       
        else if(i=="password"){
            return(
                <div key={idx}
                className="w-100 flex-center-center jc-start" 
                style={{marginBottom:"0.2rem"}}>
               
                {
                 i=="password"
                 ?<div style={{display:"none"}}>
                     <input
                        type="password"
                        value={"dummy"}
                        onChange={e=>{}}
                     />
                </div>   
                :null
                }


                <div className="w-30" style={{display:"flex",justifyContent:"space-between"}}>
                    {pageData.addUser[i]}
                    <FaKeyboard className="sm-icon"
                     onClick={e=>{

                        setShowKeyPad({
                           ...showKeyPad,
                           show:!showKeyPad.show,
                           inputStateName:"formInputState",
                           myI:i
                        })
                     }}
                    />
                </div>



                <div className="w-70" style={{position:"relative"}}>
                    <input
                        ref={genInputRef(i)}
                        type="text"//type={hidePassword?"password":"text"}
                        value={hidePassword?showPassword(formInputState[i]):formInputState[i]}
                        //type={hidePassword?"password":"text"}
                        //value={formInputState[i]}
                        checked={formInputState[i]}
                        onChange={e=>{
                            //changeInputState(e,i)
                        }}
                        onKeyDown={e=>{
                          
                            let tempValue=""

                            if(e.key||e.key===0){
                               if(e.key=="Enter"){
                                  goToNextRef(e,i)
                                  tempValue=formInputState[i]
                               }
                               else if(e.key=="Backspace"){
                                  let tempStr=formInputState[i].slice(0, -1)
                                  tempValue=tempStr
 
                               }
                               else {
                                  const myReg=new RegExp('[a-zA-Z0-9@#&_]', 'i')
                                  const tempStr=e.key.toString()
                                  const strLength=e.key.length
                                  //console.log(myReg.test(eKey))
                                  if(myReg.test(tempStr)&&strLength==1){
                                     tempValue=formInputState[i]+e.key
                                  }
                                  else {
                                     tempValue=formInputState[i]
                                  }
                               }
                               setFormInputState({
                                  ...formInputState,
                                  [i]:tempValue
                               })
                            }
                          
                            /*
                            if(tempValue===""){
                               if(e.key=="Enter"||e.key=="Backspace"){}
                               else {
                                  setShowKeyPad({
                                     ...showKeyPad,
                                     show:true
                                  })
                               }
                            }
                            */








                            /*
                            if(e.key=="Enter"){
                                goToNextRef(e,i)
                            }
                            
                            else if(e.key=="Shift"){}
                            else if(e.key=="Backspace"){
                               let tempStr=formInputState[i].slice(0, -1)
                               setFormInputState({
                                 ...formInputState,
                                 [i]:tempStr
                              })
                            }
                            else{
                              const myReg=new RegExp('[a-zA-Z0-9@#&?_]', 'i')
                              //console.log(myReg.test(e.key))
                              if(myReg.test(e.key)){
                                    const tempValue=formInputState[i]+e.key
                                    setFormInputState({
                                       ...formInputState,
                                       [i]:tempValue
                                    })
                              }
                            }
                            */
                        }}
                        disabled={showKeyPad.show?"disabled":""}

                    />
                    {
                    hidePassword
                    ?<MdVisibilityOff 
                        className="sm-icon" 
                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                        onClick={e=>{
                            setHidePassword(!hidePassword)
                        }}
                    />
                    :<MdVisibility 
                        className="sm-icon" 
                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                        onClick={e=>{
                            setHidePassword(!hidePassword)
                        }}
                    />
                    }
                </div>
            </div>
            )
        }
        else{
            return (
            <div key={idx}
                className="w-100 flex-center-center jc-start" 
                style={{marginBottom:"0.2rem"}}>
               

               <div className="w-30">{pageData.addUser[i]}</div>
                <div className="w-70" style={{position:"relative"}}>
                    <input
                        ref={genInputRef(i)}
                        type={genInputType(i)}
                        value={formInputState[i]}
                        checked={formInputState[i]}
                        onChange={e=>{
                            changeInputState(e,i)
                        }}
                        onKeyDown={e=>{goToNextRef(e,i)}}
                        autoFocus={i=="id"?"autoFocus":null}
                        disabled={i=="id"?"disabled":null}
                    />
                </div>
            </div>
            )
        }
        

    }))

    
}

        
//===========================
/*
const renderBody=()=>{
    const objKeys=["id","username","password","userLevel","name","surname",
                    //"branch"
                  ]

    return (objKeys.map((i,idx)=>{
        return (
            <div key={idx}
                 className="w-100 flex-center-center jc-start" 
                 style={{marginBottom:"0.2rem"}}>
                
                <div className="w-30">{pageData.addUser[i]}</div>
                
                
                {i=="userLevel"||i=="branch"
                 ?i=="branch"
                    ?<div className="w-70">
                            {
                            <select
                                ref={genInputRef(i)}
                                onChange={e=>{
                                    const temp=e.target.value.split(".")
                                    //console.log('temp')
                                    //console.log(temp)

                                    //changeInputState(e,i)
                                    setFormInputState({...formInputState,
                                        //"branchId":parseInt(temp[0]),
                                        //"branchName":temp[1]
                                    })
                                }}
                                onKeyDown={e=>{
                                    goToNextRef(e,i)
                                }}
                            >
                            <option value="" hidden>...</option>
                          
                            </select>
                            }
                    </div> 
                    :<div className="w-70">
                            <select
                                ref={genInputRef(i)}
                                onChange={e=>{
                                    changeInputState(e,i)
                                }}
                                onKeyDown={e=>{goToNextRef(e,i)}}
                            >
                            <option value="" hidden>...</option>
                            {
                                basicData[i].map((i,idx)=>
                                <option key={idx}>{i}</option>
                                )
                            }
                            </select>
                    </div>
                :<div className="w-70" style={{position:"relative"}}>
                    {
                     i=="password"
                     ?<input
                        style={{height:"1px",visibility:"hidden"}}
                        type="passworo"
                        value="dummy"
                        onChange={e=>{}}
                     />
                     :null
                    }
                    <input
                        ref={genInputRef(i)}
                        type={genInputType(i)}
                        onChange={e=>{
                            changeInputState(e,i)
                        }}
                        onKeyDown={e=>{goToNextRef(e,i)}}
                        autoComplete={i=='password'?'one-time-code':null}
                        autoFocus={i=="id"?"autoFocus":null}
                    />
                    
                    {
                        i=="password"
                        ? hidePassword
                            ?<MdVisibilityOff 
                                className="sm-icon" 
                                style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                onClick={e=>{
                                    setHidePassword(!hidePassword)
                                }}
                            />
                            :<MdVisibility 
                                className="sm-icon" 
                                style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                onClick={e=>{
                                    setHidePassword(!hidePassword)
                                }}
                            />
                        :null
                    }
                </div>

                }
              
            </div>
        )
    }))
}
*/
//====================
//console.log('ShopChangePassword')
const delFunc=()=>{
       const {show,inputStateName,myI}=showKeyPad
       let tempStr=formInputState[myI].slice(0, -1)
       setFormInputState({
          ...formInputState,
          [myI]:tempStr
       })
}
const charFunc=(myChar)=>{
    const {show,inputStateName,myI}=showKeyPad
       setFormInputState ({
          ...formInputState,
          [myI]:formInputState[myI]+myChar
       })
    
}
const entFunc=()=>{
    const {show,inputStateName,myI}=showKeyPad

       setShowKeyPad ({
          show:false,
          inputStateName:"",
          myI:""
       })
       refUserLevel.current.focus()
}

//====================
const renderKeyPad=()=>{
    return <MyKeyPad
       charFunc={charFunc}
       delFunc={delFunc}
       entFunc={entFunc}
    />
 }
 
//====================
const renderFooter=()=>{
    return(
    <div style={{display:"flex",position:"fixed",bottom:"1rem",right:"2rem",zIndex:"100"}}
    >
        <div>
            <button
                ref={refSubmitForm}
                onKeyDown={e=>{
                    //if(e.key=="ArrowRight"){
                    //    refCancelForm.current.focus()
                    //}
                }}
                onClick={e=>{
                    if(submitFunction){
                        submitFunction(formInputState)
                    }
                }}
            >
                <FaCheck/>
            </button>
        </div>
        
        <div>
            <button
                ref={refCancelForm}
                onKeyDown={e=>{
                    //if(e.key=="ArrowLeft"){
                    //    refCancelForm.current.focus()
                    //}
                }}
                onClick={e=>{
                    setShow(false)
                }}
            >
                <FaBan/>
            </button>
        </div>

    </div>
    )
}


//=========================
return (
    <div className="Modal-background">
        <div className="Modal-box" 
             style={{paddingBottom:"1rem",minWidth:"70%",minHeight:"100px"}}>
            <div className="Modal-header">
                <div>
                    <h5>{pageData.addUser.formHead}</h5>
                </div>
                {
                  renderFooter()
                }
            </div>
            <div className="Modal-body" >
                <div className="ModalInsideBody">
                {
                  renderBody()
                }
                </div>
            </div>
            {showKeyPad.show&&
            <div style={{position:"fixed",width:"100vw",
            height:"30vh",bottom:"0",left:"0",backgroundColor:"#666"}}>
            {
                renderKeyPad()
            }
            </div>
            }
        </div>
    </div>
  );
}


AddUserModal.defaultProps={
    lb:"Form",
    setShow:()=>{},
    submitFunction:null,
    //submitCancel:null
}


export default AddUserModal;

import React from 'react';

import {FaBan,FaCheck} from 'react-icons/fa';
import Ticon from '../../component/ticon/Ticon';
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'

import '../../component/navbar/Navbar.css';

function LogOutTool(props) {

   const {//useShopLogOut,haveShopToken,haveUserToken,userName,
      useShopLogOut,
      tokenSt,
      setReloadCheckToken,
      user,
      useHomeIcon,
      myheader,
      useBillWaveIcon,
      pageData,
      Language
   }=props
  
   //console.log('LogOutToo....l')
   //console.log(pageData)

   const shouldRender= React.useRef(true)
   React.useEffect(()=>{
      return () => {
         //console.log('end................................')
         shouldRender.current=false
      } 
   },[])

   const {haveShopToken,haveUserToken}=tokenSt
  //const {myheader,tokenSt,setReloadCheckToken}=React.useContext(MainContext)
  //const {reloadCheckToken,haveShopToken,haveUserToken,userName}=tokenSt
  const refHome=React.createRef()

  const [showConfirmBeforeShopLogout,
         setShowConfirmBeforeShopLogout
        ]=React.useState(false)

  const getIconStyle=()=>{
     
   if(shouldRender.current){
     const toDate=new Date()
     
     let temp1=localStorage.getItem("expirationDate")
     const expirationDate=new Date(temp1)
     
     if(!temp1){
        return null
     }

     const temp3=new Date(expirationDate.toISOString())
     temp3.setDate(temp3.getDate()-3)
     const threeDayBeforeExpirationDate=new Date(temp3)
      if(toDate>expirationDate){
         return {color:"red"}
      }
      else if(toDate>threeDayBeforeExpirationDate){
         return {color:"yellow"}
      }
      else{
         return {color:"green"}
      }
   }
  }
//=======================
const renderWaringModal=()=>{
   return(
   <div className="Modal-background">
      <div className="Modal-box" style={{margin:"5rem"}}>
          <div className="Modal-header" 
               style={{fontSize:"1.2rem"}}
          >
            {pageData.confirmBeforeShopLogoutForm.formHead}
          </div>
          <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                           localStorage.removeItem('shopauthorization');
                           localStorage.removeItem('expirationDate');
                           setShowConfirmBeforeShopLogout(false)
                           setReloadCheckToken(true)
                           window.location.reload()
                        }}
                        
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        onClick={e=>{
                           setShowConfirmBeforeShopLogout(false)
                              //if(setShow){setShow(false)}
                        }}
                     >
                        <FaBan/>
                    </button>
                </div>

            </div>
      </div>
   </div>
   )
}
//=======================
return (
<div className="" style={{position:"fixed",right:"0.1rem",top:"0.1rem"}}>

   
   
   
   
   
   <div className="" 
        style={{borderRadius:"1.2rem",padding:"0 0.5rem",height:"2rem",
                display:"flex",justifyContent:"space-between",alignItems:"center",
                flexWrap:"nowrap"}}>
        
        
       {user //haveUserToken
       ?<div className="flex-center-center h-100"
             style={{color:"white",margin:"0 0.4rem"}}
        >
          {`${user.username}@${user.shopId}_${user.branchId}`}
         </div>
       :null
       }

       {/*
       haveUserToken
          ?<div className="iconbox"
               onClick={e=>{
                  localStorage.removeItem('userauthorization');
                  localStorage.removeItem('username');
                  setReloadCheckToken(true)
                  window.location.reload()
               }}
          >
            <Ticon iconName="MdVpnKey" className="navIcon"
                    Language={Language}
             />
          </div>
          :haveShopToken
            ?useShopLogOut&&
            <div/>
            :null
       */
       }

        {
         haveUserToken
          ?<div className="iconbox"
               onClick={e=>{
                  localStorage.removeItem('userauthorization');
                  localStorage.removeItem('username');
                  setReloadCheckToken(true)
                  window.location.reload()
               }}
          >
            <Ticon iconName="MdVpnKey" className="navIcon"
                    Language={Language}
             />
          </div>
          
          :haveShopToken&&useShopLogOut&&
            <div className="iconbox"
               
               onClick={e=>{
                  setShowConfirmBeforeShopLogout(true)
               
               }}
            >
               <Ticon iconName="MdLogout" className="navIcon"
                      iconStyle={{color:"#a6001a"}}
                        Language={Language}
               />
            </div>
         }

   </div>

  
   {
   /*
   haveShopToken&&!haveUserToken&&useShopLogOut&&
         <div className="iconbox"
            style={{ width:"100%",display:"flex",
               justifyContent:"center",
               paddingRight:"",
               padding:"0.5rem"
            }} 
         onClick={e=>{
            setShowConfirmBeforeShopLogout(true)
         
         }}
         >
            <Ticon iconName="MdLogout" className="navIcon"
                    Language={Language}
            />
         </div>
   */
   }

   {showConfirmBeforeShopLogout&&
   renderWaringModal()
   }
   
      
   <div className="" 
        style={{
                position:"fixed",right:"0.1rem",top:"2rem",
                borderRadius:"1.2rem",padding:"0 0.5rem",height:"2rem",
                display:"flex",justifyContent:"space-between",alignItems:"center",
                flexWrap:"nowrap"}}>
        
        {useHomeIcon&&
        <div className="iconbox"
         onClick={e=>{
            //refHome.current.click()
            window.location.href = 'https://www.mmdigitzone.net'
         }}
        >
            <Ticon iconName="MdInfoOutline" className="navIcon" 
                   iconStyle={{color:"black"}}
                   Language={Language}
            />
        </div>
         }

      {useHomeIcon&&
        <div className="iconbox"
         onClick={e=>refHome.current.click()}
        >
            <Ticon iconName="FaHome" 
                   className="navIcon" 
                   iconStyle={getIconStyle()}
                   Language={Language}
            />
        </div>
         }
   </div>



   <div style={{display:"none"}}>
         <div>
            <Link ref={refHome} to="/pos/home"/>
         </div>
   </div>


</div>

)}

LogOutTool.defaultProps={
   useShopLogOut:true,
   useHomeIcon:true
}
 


export default LogOutTool;

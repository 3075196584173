
import React from 'react';
import {FaFolderPlus,FaFolder,FaFolderOpen, 
       FaCaretDown,FaCaretUp,FaMinusCircle,
       FaPlusCircle
} from 'react-icons/fa';
import {MdSearch} from 'react-icons/md';
import ModalConfirm from '../../render/ModalConfirm';
import ModalEditGroupForm from './ModalEditGroupForm'
import ModalAddGroupForm from './ModalAddGroupForm'
import Ticon from '../../component/ticon/Ticon'
import {MainContext} from '../../context/MainContext';

import axios from '../../util/axiosVerify';
import newGroupUtil  from './newGroupUtil';
import console from '../../util/consoleVerify'

const {
    genTree,
    genTreeWithI,
    genTreeWithIAndOpenAll,
    checkEditGroup,
    checkEditGroupColor,
    closeFolder,
    openFolder,
    loadMainGroup,
    genColor
} = newGroupUtil

function NewGroup(props) {

const {myheader,dataUrl,filterByGroupId,
      captureEditGroup,canGroupChange,bgColor,
      pageData,basicData,
      editData,
      limitRow,
      pageNumber,
      count
      
    }=props

//console.log('NewGroup')
//console.log(editData)

const {groupPage,editGroupForm,addGroupForm}=pageData



const { mainContextGroupSt,
    setMainContextGroupSt
    //widthLeft,setWidthLeft
}=React.useContext(MainContext)


//const refFaCaretDown=React.createRef()
//const refFaCaretUp=React.createRef()


const refLockFolder=React.createRef()
const editRef=React.createRef()
const searchLocationRef=React.createRef()
/*
const groupPage={
    formHead:"กลุ่มสินค้า"
}

const editGroupForm={
    formHead:"แก้ไขกลุ่ม",
    treeTitle:"เลือกกลุ่มสินค้าแม่ใหม่ถ้าต้องการย้ายกลุ่ม",
    id:"ไอดี",
    gcode:"โค้ดกลุ่ม",
    groupName:"ชื่อกลุ่ม",
    level:"ระดับกลุ่ม",
}

const addGroupForm={
    formHead:"เพิ่มกลุ่ม",
    treeTitle:"เลือกกลุ่มสินค้าแม่",
    id:"ไอดี",
    gcode:"โค้ดกลุ่ม",
    groupName:"ชื่อกลุ่ม",
    level:"ระดับกลุ่ม"
}
*/

const blankData={
    id:null,
    gcode:null,
    level:null,
    groupName:""
}

const pullMainContextTree=()=>{
    if(mainContextGroupSt.tree){
        return mainContextGroupSt.tree
    }
    else {
        return null
    }
   
}




const pullMainContextEditGroup=()=>{
    if(mainContextGroupSt.editGroup){
        return mainContextGroupSt.editGroup
    }
    else {
        return null
    }
}

const genImgHeight=()=>{

    if(mainContextGroupSt.imgHeight){
        return mainContextGroupSt.imgHeight
    }
    else {
        let tempImgHeight=0
    
        if(basicData.imgGroupHeight){
            if(basicData.imgGroupHeight<0){
                tempImgHeight=0
            }
            else {
                tempImgHeight=basicData.imgGroupHeight
            }
        }
        return tempImgHeight
    }
}

const genShowTree=()=>{
    return mainContextGroupSt.showTree
}
const genLastRecordId=()=>{
    return mainContextGroupSt.lastRecordId
}

const defaultGroupSt={
    tree:pullMainContextTree(),
    reloadGroup:false,
    editGroup:pullMainContextEditGroup(),

    qry:{   sort:{gcode:1},
            $or:[{level:1},{level:2},{level:3}]
        },
    lastRecordId:genLastRecordId(),
    showAddForm:false,
    showEditForm:false,
    showConfirm:false,
    showError:false,
    showTree:genShowTree(),
    imgHeight:genImgHeight(),
}


const [groupSt,setGroupSt]=React.useState(defaultGroupSt)

//const [showTree,setShowTree]=React.useState(true)
//const [imgHeight,setImgHeight]=React.useState(genImgHeight())

const setShowTree=(data)=>{
    //console.log('setShowTree.')
    //console.log(data)
    setGroupSt({...groupSt,
        showTree:data
    })
    setMainContextGroupSt({
        ...mainContextGroupSt,
        showTree:data
    })
}

const setImgHeight=(data)=>{
    setGroupSt({...groupSt,
        imgHeight:data
    })
    setMainContextGroupSt({
        ...mainContextGroupSt,
        imgHeight:data
    })
}
//---------------------
const genEditRef=(i)=>{
    let tempEditRef=null
    if(groupSt){
        if(groupSt.editGroup){
            if(i.id==groupSt.editGroup.id){
                tempEditRef=editRef
            }
        }
    }
    return tempEditRef
}


//---------------------
const submitAddGroupFunc=(inputState)=>{
    axios.post(`/${dataUrl}/addcustom`,inputState,myheader)
    .then(result=>{
        setGroupSt({...groupSt,
            reloadGroup:true,
            showAddForm:false,
            //editGroup:null
        })
    })
    .catch(error=>{
        //console.log('error')
        //console.log(error)
    })

}
//---------------------
const submitUpdateGroupFunc=(inputState)=>{
    //console.log('submitUpdateGroupFunc')

    let tempDeleteArray=[]
    let tempArray=[]

    groupSt.tree.map(i=>{
     
        if(i.id==inputState.id){
            const newGcode=i.gcode.replace(groupSt.editGroup.gcode,inputState.gcode)
            const newLevel=newGcode.split("/").length
            
            tempArray=[...tempArray,
                {...i,
                    level:newLevel,
                    gcode:newGcode,
                    groupName:inputState.groupName}
              ]
            tempDeleteArray=[...tempDeleteArray,i.id]
        }
        else{
            const newGcode=i.gcode.replace(groupSt.editGroup.gcode,inputState.gcode)
            const newLevel=newGcode.split("/").length

            const isChildren=new RegExp("^"+groupSt.editGroup.gcode+"\/","gi").test(i.gcode)
            if(isChildren){
                tempArray=[...tempArray,
                    {...i,
                        level:newLevel,
                        gcode:newGcode}
                  ]
                tempDeleteArray=[...tempDeleteArray,i.id]
            }
        }
    })
    //console.log(tempArray)
    //console.log(tempDeleteArray)
    
    if(tempDeleteArray.length>0){

        const qry={
            toDelete:{id:{$in:tempDeleteArray}},
            toUpdate:tempArray
        }

        axios.post(`/${dataUrl}/updategroup`,qry,myheader)
        .then(result=>{
            setGroupSt({...groupSt,
                reloadGroup:true,
                showEditForm:false,
                editGroup:null
            })
        })
        .catch(error=>{
            //console.log('error')
            //console.log(error)
        })
    }
    
}
//-------------------------
const submitDeleteGroupFunc=()=>{
    //console.log(groupSt.editGroup)
    //const {id,parentId,allDeleteId}=req.body
    //const isChildren=new RegExp("^"+groupSt.editGroup.gcode+"*","gi").test(i.gcode)
    let tempArray=[groupSt.editGroup.id]

    groupSt.tree.map(i=>{
        if(i.level>groupSt.editGroup.level){
            const isChildren=new RegExp("^"+groupSt.editGroup.gcode+"\/","gi").test(i.gcode)
            if(isChildren){
                tempArray=[...tempArray,i.id]
            }
        }
        
    })

    //console.log(tempArray)
    const qry={allDeleteId:tempArray}
    axios.post(`/${dataUrl}/deletegroup`,qry,myheader)
    .then(result=>{
        setGroupSt({...groupSt,
            reloadGroup:true,
            editGroup:null,
            showConfirm:false
        })
    })
    .catch(error=>{
        //console.log('error')
    })
    
}


//-------------------------
React.useEffect(()=>{

    if(groupSt.reloadGroup){
        //axios.post(`/${dataUrl}/getcustom`,groupSt.qry,myheader)
        axios.post(`/${dataUrl}/getcustom`,groupSt.qry,myheader)
        .then(result=>{
            //console.log('result newgroup....getcustom')
            //console.log(result.data)
            
            const tempTree=genTree(result.data.data)


            setGroupSt({...groupSt,
                tree:tempTree,
                reloadGroup:false,
                lastRecordId:result.data.lastRecordId,
            })

            setMainContextGroupSt({
                ...mainContextGroupSt,
                tree:tempTree,
                //tree:null,
                editGroup:null,
                lastRecordId:result.data.lastRecordId
            })

        })
        .catch(error=>{
            //console.log('error')
        })
    }

},[groupSt])

//------------------------------

const renderTree=(array)=>{
    return(
        array.map((i,idx)=>{
            const {id,show,gcode,level,groupName,
                   open,haveChild}=i
            return (
            show
            ?<div key={idx}
                  className=""
                  style={{display:"flex",height:"100%",alignItems:"center",
                          marginLeft:`${level}rem`}}>
                  
                    {open||idx==0
                    ?<div className="" style={{height:"100%",display:"flex",alignItems:"center"}}>
                        <FaFolderOpen className="sm-icon"
                            style={{color:genColor(i.level)}}
                            onClick={e=>{
                                if(i.level>2){
                                    const tempArray=closeFolder(groupSt.tree,i)
                                    setGroupSt({
                                        ...groupSt,
                                        tree:tempArray
                                    })
 
                                    setMainContextGroupSt({
                                        ...mainContextGroupSt,
                                        tree:tempArray,
                                        editGroup:groupSt.editGroup,
                                    })


                                }
                            
                                if(i.level==2){
                                    //console.log('ssssss')
                                    //console.log(i)
                                    
                                    let tempArray=[]
                                    groupSt.tree.map(j=>{
                                        const isChildren=new RegExp("^"+i.gcode+"\/","gi").test(j.gcode)

                                        if(i.id==j.id){
                                            const tempObj={...j,open:false}
                                            tempArray=[...tempArray,tempObj]
                                        }
                                        else if(isChildren){
                                            const tempObj={...j,show:false}
                                            tempArray=[...tempArray,tempObj]
                                        }
                                        else{
                                            tempArray=[...tempArray,j]
                                        }
                                        
                                    })


                                    setGroupSt({
                                        ...groupSt,
                                        tree:tempArray
                                    })
                                    
                                    setMainContextGroupSt({
                                        ...mainContextGroupSt,
                                        tree:tempArray,
                                        editGroup:groupSt.editGroup
                                    })

                                }
                            }}
                        />
                    </div>
                    :haveChild
                        ?<div className="" style={{height:"100%",display:"flex",alignItems:"center"}}>
                           <FaFolderPlus className="sm-icon"
                              style={{color:genColor(i.level)}}
                              
                              onClick={e=>{   
                                if(i.level>2){ 
                                    const tempArray=openFolder(groupSt.tree,i)
                                    setGroupSt({
                                        ...groupSt,
                                        tree:tempArray
                                    })
                                    setMainContextGroupSt({
                                        ...mainContextGroupSt,
                                        tree:tempArray,
                                        editGroup:groupSt.editGroup
                                    })
                                }
                            
                             }}
                          />
                        </div>
                        :<div className="" style={{height:"100%",display:"flex",alignItems:"center"}}>
                            <FaFolder className="sm-icon"
                               
                                style={{color:genColor(i.level)}}
                        
                                onClick={e=>{
                                    //console.log('tttttt')
                                }}
                            />
                        </div>
                    
                    }
                    
                    <div className="bill-p" style={checkEditGroupColor(i,groupSt)}
                            ref={genEditRef(i)}
                            onClick={e=>{
                            if(canGroupChange){
                                if(i.level==2&&i.haveChild){
                                    
                                    loadMainGroup(i,dataUrl,myheader)
                                    .then(result=>{
                                        //console.log('loadMainGroup........')
                                        //console.log(result)
                                        const tempArray=genTreeWithI(result,i)
                                        //console.log('tempArray')
                                        //console.log(tempArray)
                                        setGroupSt({...groupSt,
                                            editGroup:i,
                                            tree:tempArray
                                        })

                                        setMainContextGroupSt({
                                            ...mainContextGroupSt,
                                            tree:tempArray,
                                            editGroup:i
                                        })
                                    })
                                    
                                }
                                if(i.level==2){
                                    setGroupSt({...groupSt,
                                        editGroup:i
                                    })
                                    setMainContextGroupSt({
                                        ...mainContextGroupSt,
                                        tree:groupSt.tree,
                                        editGroup:i
                                    })
                                }
                                else if(i.level>2){
                                    setGroupSt({...groupSt,
                                        editGroup:i
                                    })
                                    setMainContextGroupSt({
                                        ...mainContextGroupSt,
                                        tree:groupSt.tree,
                                        editGroup:i
                                    })
                                }
                            }
                            else{
                                
                            }
                        }}>
                            {`${id}.${groupName}`}
                    </div>



                    <div className="" style={{display:"flex",alignItems:"center"}}>
                       
                        <div className='flex-center-center'
                            style={{height:"100%",marginLeft:"1rem"}}
                        >
                            <MdSearch className="sm-icon" 
                                style={{}}
                                onClick={e=>{
                                    filterByGroupId(i)
                                   
                                    //setShowTree(!groupSt.showTree)
                                }}
                            />
                        </div>
                        {
                        i.photoUrl1[0]    
                        ?<div style={{
                                    height:`${groupSt.imgHeight}rem`,padding:"2px",
                                   }}
                              onClick={e=>{
                                  setImgHeight(0)
                              }}
                        >
                                <img style={{height:"100%",
                                            borderRadius:"0.25rem",
                                            }}
                                    src={i.photoUrl1[0]}
                                    alt={"No Image"}
                                />
                        </div>
                        :null
                        }
                        {i.photoUrl1[0] 
                        ?<div className="group-img" 
                                style={{ display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    backgroundColor:"rgba(0,0,0,0)"
                                }}
                        >
                                <FaPlusCircle className="md-icon"
                                    onClick={e=>{
                                        setImgHeight(groupSt.imgHeight+3)
                                    }}
                                />
                        </div>
                        :null
                        }
                        {i.photoUrl1[0]   
                        ?<div className="group-img" 
                                style={{ display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    backgroundColor:"rgba(0,0,0,0)"
                                }}
                        >
                                <FaMinusCircle className="md-icon"
                                    onClick={e=>{
                                        if(groupSt.imgHeight<=0){
                                        
                                        }
                                        else{
                                            setImgHeight(groupSt.imgHeight-3)
                                        }
                                    }}
                                />
                        </div>
                        :null
                        }
                    </div>
             </div>
            :null
            )
        })
    )
}


const renderBadge=()=>{
    return (
    <div className=""
        style={{height:"2rem",width:"100%",
                display:"flex",backgroundColor:bgColor
        }}
    >
        <div className="iconbox"
             onClick={e=>{

                /*
                setMainContextGroupSt({
                    ...mainContextGroupSt,
                    tree:null,
                    editGroup:null
                })
                */
                
                setGroupSt({...groupSt,
                    reloadGroup:true,
                    editGroup:null
                })
                
            }}
        >
            <Ticon 
                iconName="MdRefresh" 
                className="sm-icon"
                textStyle={{color:"black"}} 
            />
        </div>
        
        <div className="iconbox"
             style={{display:"none"}}
            ref={refLockFolder}
            onClick={e=>{
                //setGroupSt({
                //    ...groupSt,
                //    showConfirm:true
                //})
                setMainContextGroupSt({
                    ...mainContextGroupSt,
                    tree:groupSt.tree,
                    editGroup:groupSt.editGroup
                })
            }}
        >
            <Ticon
                iconName="RiFolderLockFill" 
                className="sm-icon"
                textStyle={{color:"black"}}

            />
        </div>
        




        {canGroupChange&&
        <div className="iconbox"
            onClick={e=>{
                setMainContextGroupSt({
                    ...mainContextGroupSt,
                    tree:null,
                    editGroup:null
                })

                setGroupSt({
                        ...groupSt,
                        showAddForm:true
                })
            }}
        >
            <Ticon 
                iconName="MdAddCircle" 
                className="sm-icon"
                textStyle={{color:"black"}}

            />
        </div>
        }
        {checkEditGroup(groupSt)&&canGroupChange&&
        <div className="iconbox"
            onClick={e=>{
                setMainContextGroupSt({
                    ...mainContextGroupSt,
                    tree:null,
                    editGroup:null
                })

                setGroupSt({
                    ...groupSt,
                    showEditForm:true
                })
            }}
        >
            <Ticon
                iconName="MdEdit" 
                className="sm-icon"
                textStyle={{color:"black"}}

            />
        </div>
        }
        {checkEditGroup(groupSt)&&canGroupChange&&
        <div className="iconbox"
            onClick={e=>{
                setMainContextGroupSt({
                    ...mainContextGroupSt,
                    tree:null,
                    editGroup:null
                })

                setGroupSt({
                    ...groupSt,
                    showConfirm:true
                })
            }}
        >
            <Ticon
                iconName="MdDelete" 
                className="sm-icon"
                textStyle={{color:"black"}}

            />
        </div>
        }


        {checkEditGroup(groupSt)&&canGroupChange&&
        <div className="iconbox"
            onClick={e=>{
                captureEditGroup(groupSt.editGroup)
            }}
        >
            <   Ticon
                iconName="RiShareForwardLine" 
                className="sm-icon"
                textStyle={{color:"black"}}

            />
        </div>
        }
       
        {checkEditGroup(groupSt)&&
        <div className="iconbox"
            onClick={e=>{
                //groupSt.tree
                if(groupSt){
                    if(groupSt.editGroup){
                        
                        let keepChecking=true
                        let tempIdx=0
                        groupSt.tree.map((cc,idxCC)=>{
                            if(keepChecking){
                                if(groupSt.editGroup.id==cc.id){
                                    tempIdx=idxCC
                                }
                            }
                        }
                        )
                        if(tempIdx>0){
                            editRef.current.scrollIntoView()
                        }
                        
                    }
                }
            }}
        >
             <   Ticon
                iconName="FaSearchLocation" 
                className="sm-icon"
                textStyle={{color:"black"}}

            />
            
        </div>
        }

        {editData&&
        <div className="iconbox"
            ref={searchLocationRef}
            onClick={e=>{
                //groupSt.tree
                //aabbcc
                //console.log('editData.........')
                //console.log(editData)
                if(editData.groupId){
                        const temp={id:editData.groupId}

                        //console.log(temp)
                        axios.post(`/${dataUrl}/getlimit`,temp,myheader)
                        .then(result=>{
                            //console.log('result newgroup....getcustom')
                            //console.log(result.data.data[0])
                            const foundGroup=result.data.data[0]
                            if(foundGroup){
                                if(foundGroup.gcode&&foundGroup.id){
                                    const tempArray=foundGroup.gcode.split("/")

                                    if(tempArray.length>=2){
                                            const tempI={gcode:tempArray[0]+"/"+tempArray[1]}

                                            //console.log('tempI....')
                                            //console.log(tempI)

                                            loadMainGroup(tempI,dataUrl,myheader)
                                            .then(result=>{
                                                const tempArray=genTreeWithIAndOpenAll(result,tempI)
                                                //console.log('tempArray')
                                                //console.log(tempArray)
                                                setGroupSt({...groupSt,
                                                    editGroup:foundGroup,
                                                    tree:tempArray
                                                })

                                                setMainContextGroupSt({
                                                    ...mainContextGroupSt,
                                                    tree:tempArray,
                                                    editGroup:foundGroup
                                                })

                                                //searchLocationRef.current.click()
                                            })



                                    }
                                }
                            }
                            
                        // const tempTree=genTree(result.data.data)
                
                
                            //setGroupSt({...groupSt,
                            //    tree:tempTree,
                            //    reloadGroup:false,
                            //    lastRecordId:result.data.lastRecordId,
                            //})
                
                            //setMainContextGroupSt({
                            //    ...mainContextGroupSt,
                            //    tree:tempTree,
                                //tree:null,
                            //    editGroup:null,
                            //    lastRecordId:result.data.lastRecordId
                        // })
                
                        })
                        .catch(error=>{
                            //console.log('error')
                        })
                }

            }}
        >
             <   Ticon
                iconName="RiFolderDownloadFill" 
                className="sm-icon"
                textStyle={{color:"black"}}

            />
            
        </div>
        }



    </div>
    )
}


//=============================
return(
<div className="bd-pureWhite"
     style={{height:groupSt.showTree?"60vh":"",
             //maxHeight:"65vh",
             width:"95%",paddingBottom:"0.5rem",backgroundColor:"rgba(0,0,0,0.1)"}}>

    
        <div style={{height:groupSt.showTree?"10%":"",width:"100%"}}>
            <div className="flex-center-center">
                <h5>{groupPage.formHead}</h5>
                {
                    groupSt.showTree
                    ?<FaCaretDown className="sm-icon"
                        //ref={refFaCaretDown}
                        onClick={e=>{
                            setShowTree(!groupSt.showTree)
                        }}
                    />
                    :<FaCaretUp className="sm-icon"
                        //ref={refFaCaretUp}
                        onClick={e=>{
                            setShowTree(!groupSt.showTree)
                        }}
                    />
                }
            </div>
            
            {groupSt.showTree&&
                renderBadge()
            }
        </div>

        <div style={{height:groupSt.showTree?"85%":"",
                    width:"100%",
                    marginTop:"1.5rem",
                    overflow:"auto"}}
        >
        {
        groupSt.showTree&&groupSt.tree&&
        <div style={{height:"",width:"100%",}}
        >
            {
             renderTree(groupSt.tree)
            }
        </div>
        }
        </div>
        
        {groupSt.showEditForm&&
            <ModalEditGroupForm
                loadData={groupSt.editGroup}
                groupTree={groupSt.tree}
                submitFunc={submitUpdateGroupFunc}
                cancelFunc={()=>setGroupSt({...groupSt,showEditForm:false})}
                //setTree={setTree}
                myheader={myheader}
                pageData={editGroupForm}
                dataUrl={dataUrl}
                basicData={basicData}
            />
        }

        {groupSt.showAddForm&&
            <ModalAddGroupForm
                loadData={{...blankData,id:parseInt(groupSt.lastRecordId)+1}}
                groupTree={groupSt.tree}
                submitFunc={submitAddGroupFunc}
                cancelFunc={()=>setGroupSt({...groupSt,showAddForm:false})}
                //setTree={setTree}
                myheader={myheader}
                pageData={addGroupForm}
                dataUrl={dataUrl}
                basicData={basicData}
            />
        }

        {groupSt.showConfirm&&
            <ModalConfirm
                setShow={(data)=>{setGroupSt({...groupSt,showConfirm:data})}}
                submitFunction={submitDeleteGroupFunc}
            />
        }

</div>


)
}

NewGroup.defaultProps={
    myheader:null,
    dataUrl:"",
    filterByGroupId:()=>{},
    captureEditGroup:()=>{},
    canGroupChange:true,
    bgColor:"#72a2d9",
    changingPage:false,
    editData:null,

}


export default NewGroup;




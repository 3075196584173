import React from 'react'
import Barcode from 'react-barcode'
import console from '../../util/consoleVerify'

const renderBarcodeOut=(props)=>{

//console.log('renderBarcodeOut')
//console.log(props)

const inputState=props

const genArray=(x)=>{
    let tempArray=[]    
    for (let i=0;i<x;i++){
        tempArray=[...tempArray,i]
    }
    return tempArray
}


const genFr=(x)=>{
    let tempString=""
    for (let i=1;i<=x;i++){
        tempString=`${tempString} 1fr`
    }
    return tempString
}

const renderPriceAndUnit =({price,unit,black})=>{

    const {showPrice,showUnit}=inputState
    if(showPrice&&showUnit){
        return (
            <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <p className="sticker-p" 
                    style={{textAlign:"center",fontSize:`${inputState.fontSizePrice}px`,
                            color:black?"black":"white"
                    }}
                >{price?price:""}</p>

                <p className="sticker-p" 
                    style={{textAlign:"center",fontSize:`${inputState.fontSizeUnit}px`,
                            color:black?"black":"white"
                    }}
                >{`/${unit}`}</p>
            </div>
        )
    } 
    else if(showPrice){
        return (
            <p className="sticker-p" 
                style={{textAlign:"center",fontSize:`${inputState.fontSizeUnit}px`,
                        color:black?"black":"white"
            }}
            >{`${price}`}</p>
        )
    }
    else if(showUnit){
        return (
            <p className="sticker-p" 
                style={{textAlign:"center",fontSize:`${inputState.fontSizeUnit}px`,
                        color:black?"black":"white"
                }}
            >{`${unit}`}</p>
        )
    }
    else {
        return null
    }
}

const renderBarcode=(obj,idxs)=>{
    const {code,name,price,unit,black,selectedLine}=obj
    return(
        
        <div  
                style={{border:inputState.showBorder
                        ?black?"1px solid rgba(0,0,0,1)":"1px solid white"
                        :"1px solid rgba(0,0,0,0)",
                    }}
                key={idxs} >


            <div className="flex-center-center" style={{paddingTop:'3px'}}>
                {inputState.showBarCode
                ?<div style={{marginTop:`${inputState.marginTopBarcode}px`,
                              marginBottom:`${inputState.marginBottomBarcode}px`}}
                 >
                    <div style={{transform:`scale(${inputState.scale})`}}>
                        <Barcode
                            value={code}
                            height={inputState.heightB}
                            width={inputState.widthB}
                            displayValue={false}
                            font={"monocode"}
                            marginTop={-1}
                            marginBottom={-1}
                            lineColor={black?"black":"white"}
                        />
                    </div>
                </div>
                :null
                }
            </div>
            
            {inputState.showCode
            ?<p  className="sticker-p" 
                style={{textAlign:"center",fontSize:`${inputState.fontSizeCode}px`,
                        color:black?"black":"white"
                        }}
                >{code}</p>
            :null
            }

            {inputState.showName
            ?<p className="sticker-p" 
                style={{textAlign:"center",fontSize:`${inputState.fontSizeName}px`,
                        color:black?"black":"white"
                }}
                >{name}</p>
            :null
            }

            {
              renderPriceAndUnit({price:price,unit:unit,black:black})
            }
            
        </div>
    )
}

    
    
const renderAllBarcode=(obj,idx)=>{
    const {qty,...remaining}=obj
    return(
        genArray(qty).map((i,idx2)=>renderBarcode(remaining,`${idx}-${idx2}`))
    )
}
    
const renderBarcodePage=({heightP,widthP,
            paddingTopP,paddingBottomP,paddingLeftP,paddingRightP,useMm,
            marginTopP,marginBottomP,marginLeftP,marginRightP,
            borderBottomP,
            scale,marginTopBarcode,marginBottomBarcode
        })=>{
    //console.log('marginRightP')
    //console.log(marginRightP)
    return (
        <div className="bgc-white sticker-border" 
        style={{
            height:useMm?`${heightP}mm`:`${heightP}%`,//mm
            width:useMm?`${widthP}mm`:`${widthP}%`,//mm
            paddingTop:`${paddingTopP}mm`,
            paddingBottom:`${paddingBottomP}mm`,
            paddingLeft:`${paddingLeftP}mm`,
            paddingRight:`${paddingRightP}mm`,

            marginTop:`${marginTopP}mm`,
            marginBottom:`${marginBottomP}mm`,
            marginLeft:`${marginLeftP}mm`,
            marginRight:`${marginRightP}mm`,

            borderBottom:borderBottomP?"1px dotted black":"",
        
            backgroundColor:"white",
            }}
        >
        
        <div 
            style={{
                width:"100%",
                display:"grid",
                gridTemplateColumns:genFr(inputState.xPerLine),
                gridRowGap:`${inputState.gridRowGap}mm`,
                gridColumnGap:`${inputState.gridColumnGap}mm`
            }}
        >
            {
                inputState.productData
                .map((obj,idx)=>renderAllBarcode(obj,idx))
            }


        </div>


    </div>
    )
}

return renderBarcodePage({
            heightP:inputState.heightP,
            widthP:inputState.widthP,
            paddingTopP:inputState.paddingTopP,
            paddingBottomP:inputState.paddingBottomP,
            paddingRightP:inputState.paddingRightP,
            paddingLeftP:inputState.paddingLeftP,

            marginTopP:inputState.marginTopP,
            marginBottomP:inputState.marginBottomP,
            marginRightP:inputState.marginRightP,
            marginLeftP:inputState.marginLeftP,
            
            borderBottomP:inputState.borderBottomP,
            
            useMm:inputState.useMm
        })
    
}

export default renderBarcodeOut




import React from 'react';
import MainContext from '../../context/MainContext'

function test() {
    //const {basicData,setBasicData}=React.useContext(MainContext)

}


/*
const shopChangePasswordForm={
    shopName:
        {   lb:'deedee',//lb:'ชื่อร้าน', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"text", 
            placeholder:'', 
            autoFocus:"autoFocus"
        },
    password:
        {   lb:'deedee',//lb:'รหัสร้าน', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"password", 
            placeholder:'',   
        },
    newPassword1:
        {   lb:'deedee',//lb:'รหัสร้านใหม่', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"password", 
            placeholder:'',   
        },
    newPassword2:
        {   lb:'deedee',//lb:'ยืนยันรหัสร้านใหม่', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"password", 
            placeholder:'',   
        },
}

const addUserForm={
    id:{
        lb:'deedee',//lb:'รหัส', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        autoFocus:"autoFocus"
    },
    username:{
        lb:'deedee',//lb:'ยูสเซอร์เนม', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:''
    },
    password:{
        lb:'deedee',//lb:'รหัส', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"password", 
        placeholder:'',   
    },
    userLevel:{
        lb:'deedee',//lb:'ระดับผู้ใช้', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"select", 
        placeholder:'', 
        //selectObj:["นาย","นาง"]
        selectDataKey:"basicData",
        selectObj:'userLevel'
    },
    name:{
        lb:'deedee',//lb:'ชื่อจริง', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    surname:{
        lb:'deedee',//lb:'นามสกุล', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    branch:{
        lb:'deedee',//lb:'สาขา', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'branch'
    }
}

*/



/*
const testForm={
    id:{
        lb:'deedee',//lb:'รหัส', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        autoFocus:"autoFocus"
    },
    date:{
        lb:'deedee',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc3 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"thaiDate", 
        placeholder:'', 
    },
    title:{
        lb:'deedee',//lb:'คำนำหน้า', 
        templateType:"string" , 
        cName:"pl-0 xc3 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'title'
    },
    name:{
        lb:'deedee',//lb:'ชื่อ', 
        templateType:"string" , 
        cName:"pl-0 xc3 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    password:
    {   lb:'deedee',//lb:'รหัสร้าน', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"password", 
        placeholder:'',   
    },
    icon:{
        lb:'deedee',//lb:"กลุ่มคู่ค้า",
        templateType:"icon",
        cName:"pl-0 xc4 sc12 pt-1",  
        subCName:["xc6","xc6"], 
        inputType:"searchIcon",
        iconActionIdx:0,
    },
    groupId:{
        lb:'deedee',//lb:'รหัสกลุ่ม', 
        templateType:"string" , 
        cName:"pl-0 xc4 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
    groupName:{
        lb:'deedee',//lb:'ประเภทกลุ่ม', 
        templateType:"string" , 
        cName:"pl-0 xc3 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
        unchangeable:true
    },
    isRawMat:{
        lb:'deedee',//lb:'เป็นวัตถุดิบ', 
        templateType:"string" , 
        cName:"pl-0 xc3 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
    remark:{
        lb:'deedee',//lb:'หมายเหตุ', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2 alignSelfStart","xc10"], 
        inputType:"textarea", 
        placeholder:'', 
        textRow:3
    },
    phone:{
        lb:'deedee',//lb:'โทรศัพท์', 
        templateType:"array" , 
        cName:"pl-0 xc3 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    address:{
        lb:'deedee',//lb:'ที่อยู่รอง',    
        templateType:"arrayObject", 
        //cName:["xc12 pt-1 bd-lightGrayxx","form-row flex-justify-start flex-align-stretch"], 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc12","xc12"], 

        placeholder:'',
        subFormTemplate:{
            number:{
                lb:'deedee',//lb:'เลขที่', 
                templateType:"string" , 
                cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                subCName:["xc4","xc8"], 
                inputType:"text", 
                placeholder:'', 
            },
            tambon:{
                lb:'deedee',//lb:'ตำบล', 
                templateType:"string" , 
                cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                subCName:["xc4","xc8"], 
                inputType:"text", 
                placeholder:'', 
            }
          
        }
      
    }, 
    mainaddress:{
        lb:'deedee',//lb:'ที่อยู่หลัก',    
        templateType:"object", 
        //cName:["xc12 pt-1 bd-lightGrayxx","form-row flex-justify-start flex-align-stretch"], 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc12","xc12"], 

        placeholder:'',
        subFormTemplate:{
            number:{
                lb:'deedee',//lb:'เลขที่', 
                templateType:"string" , 
                cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                subCName:["xc4","xc8"], 
                inputType:"text", 
                placeholder:'', 
            },
            tambon:{
                lb:'deedee',//lb:'ตำบล', 
                templateType:"string" , 
                cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                subCName:["xc4","xc8"], 
                inputType:"text", 
                placeholder:'', 
            }
        }
      
    }, 
    detail:{
        lb:'deedee',//lb: "รายละเอียด",
        templateType:"arrayObjectInTable",
        cName:"pl-0 ",
        subFormTemplate:{
          
          icon:{
            templateType:"icon",
            subCName:[60],
            inputType:"icon",
            iconActionIdx:1,
          },
          
          id:{
            lb:'deedee',//lb:"ไอดี",
            templateType:"number",
            subCName:[60,""],
            inputType:"number",
            placeholder:"",
            aotuFocus:"autoFocus"
          },
          barcode:{
            lb:'deedee',//lb:"บาร์โค้ด",
            templateType:"string",
            subCName:[100,""],
            inputType:"text",
            placeholder:"", 
            nextEnter:{nextKey1:["detail",0,"quantity"],lastKey:null}
          },
          name:{
            lb:'deedee',//lb:"ชื่อ",
            templateType:"string",
            subCName:[240,""],
            inputType:"text",
            placeholder:"", 
          },

          groupId:{
            lb:'deedee',//lb:"ไอดีกลุ่ม",
            templateType:"number",
            subCName:[60,""],
            inputType:"number",
            placeholder:"",
            aotuFocus:"autoFocus"
          },
          groupName:{
            lb:'deedee',//lb:"ชื่อกลุ่ม",
            templateType:"string",
            subCName:[90,""],
            inputType:"text",
            placeholder:"", 
          },
          quantity:{
            lb:'deedee',//lb:"จำนวน",
            templateType:"string",
            subCName:[60,""],
            inputType:"number",
            placeholder:"", 
            nextEnter:{nextKey1:["detail",1,"barcode"],lastKey:"endForm"}
          },

          unit:{
            lb:'deedee',//lb:"หน่วย",
            templateType:"string",
            subCName:[80,""],
            inputType:"select",
            placeholder:"", 
            //selectObj:["นาย","นาง"]
            selectDataKey:"basicData",
            selectObj:'unit'
          },
          price:{
            lb:'deedee',//lb:"ราคา",
            templateType:"string",
            subCName:[100,""],
            inputType:"number",
            placeholder:"", 
          },
          result:{
            lb:'deedee',//lb:"รวม",
            templateType:"string",
            subCName:[100,""],
            inputType:"number",
            placeholder:"", 
          },
          remark:{
            lb:'deedee',//lb:"หมายเหตุ",
            templateType:"string",
            subCName:[200,""],
            inputType:"text",
            placeholder:"", 
          },
          isRawMat:{
            lb:'deedee',//lb:"เป็นวัตถุดิบ",
            templateType:"boolean",
            subCName:[40,""],
            inputType:"checkbox",
            placeholder:"", 
          }
        }
      }
}

const shopSignUpForm={
    shopName:
        {  lb:'deedee',//lb:'ชื่อร้าน', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"text", 
            placeholder:'', 
            autoFocus:"autoFocus"
        },
    password:
        {   lb:'deedee',//lb:'รหัสร้าน', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"password", 
            placeholder:'',   
        },
    ownerName:
        {   lb:'deedee',//lb:'ชื่อผู้ใช้', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"text", 
            placeholder:'', 
        },
    ownerPassword:
        {   lb:'deedee',//lb:'รหัสผู้ใช้', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"password", 
            placeholder:'',   
        },
    ownerEmail: {  
            lb:'deedee',//lb:'อีเมลผู้ใช้', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"text", 
            placeholder:'',   
        },     
}

//====================

const shopLogInForm={
    shopName:
        {  lb:'deedee',//lb:'ชื่อร้าน', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"text", 
            placeholder:'', 
            autoFocus:"autoFocus"
        },
    password:
        {   lb:'deedee',//lb:'รหัสร้าน', 
            templateType:"string" , 
            cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
            subCName:["xc4","xc8"], 
            inputType:"password", 
            placeholder:'',   
        },

}

//=======================


const logInForm={
    username:{
        lb:'deedee',//lb:'ยูสเซอร์เนม', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'',
        autoFocus:"autoFocus"
    },
    password:{
        lb:'deedee',//lb:'รหัส', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"password", 
        placeholder:'',   
    }    
}

const changePasswordForm={
    username:{
        lb:'deedee',//lb:'ยูสเซอร์เนม', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
        autoFocus:"autoFocus"
    },
    password:{
        lb:'deedee',//lb:'รหัส', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"password", 
        placeholder:'',   
    },
    newPassword1:{   
        lb:'deedee',//lb:'รหัสใหม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"password", 
        placeholder:'',   
    },
    newPassword2:{   
        lb:'deedee',//lb:'ยืนยันรหัสใหม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"password", 
        placeholder:'',   
    },
}

*/


/*
const groupForm={
    id:{
        lb:'deedee',//lb:'ไอดีกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        autoFocus:"autoFocus"
    },
    groupName:{
        lb:'deedee',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },

    parentId:{
        lb:'deedee',//lb:'ไอดีกลุ่มแม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //disabled:"disabled"
    },
}
//===============================
const groupEditForm={
    id:{
        lb:'deedee',//lb:'ไอดีกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        autoFocus:"autoFocus",
        disabled:"disabled"
    },
    newId:{
        lb:'deedee',//lb:'ไอดีกลุ่มสินค้าใหม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },

    groupName:{
        lb:'deedee',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },

    parentId:{
        lb:'deedee',//lb:'ไอดีกลุ่มแม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        disabled:"disabled"
    },
    newParentId:{
        lb:'deedee',//lb:'ไอดีกลุ่มแม่ใหม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
}
//================================
//======================================
*/


const partnerForm={
    
    isSupplier:{
        lb:'deedee',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
    isCustomer:{
        lb:'deedee',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },

    id:{
        lb:'deedee',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"genId", 
        placeholder:'', 
        autoFocus:"autoFocus",
        //calculation:{method:"autoId"},
    },
    taxId:{
        lb:'deedee',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
        //calculation:{method:"autoId"},
    },
    partnerType:{
        lb:'deedee',//lb:'ประเภทคู่ค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'partnerType'
    },

    active:{
        lb:'deedee',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc12 lc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
    remainingCustomerPoint:{
        lb:'deedee',//lb:'แต้มสะสม', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //calculation:{method:"autoId"},
    },
    totalSpending:{
        lb:'deedee',//lb:'ยอดใช้จ่าย', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //calculation:{method:"autoId"},
    },



    title:{
        lb:'deedee',//lb:'คำนำหน้า', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'title'
    },
    name:{
        lb:'deedee',//lb:'ชื่อ', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },
   
   
    phone:{
        lb:'deedee',//lb:'โทรศัพท์', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },
    //=====================
    contact:{
        lb:'deedee',//lb:'ติดต่อ', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },
    //==============================  
    address:{
        lb:'deedee',//lb:'ที่อยู่',    
        templateType:"string", 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'',       
    }, 


    delivery:{
            lb:'',
            templateType:"arrayObject", 
            
            cName:"pl-0 xc12 lc12 sc12 pt-1 bd-lightGrayxx",  
            subCName:["xc12","xc12"], 

            placeholder:'',
            subFormTemplate:{
                title:{
                    lb:'deedee',//lb:'คำนำหน้า', 
                    templateType:"string" , 
                    cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"select", 
                    placeholder:'', 
                    selectDataKey:"basicData",
                    selectObj:'title'
                },
                name:{
                    lb:'deedee',//lb:'',    
                    templateType:"string", 
                    cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"text", 
                    placeholder:'',       
                }, 
                phone:{
                    lb:'deedee',//lb:'',    
                    templateType:"string", 
                    cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"text", 
                    placeholder:'',       
                }, 
                contact:{
                    lb:'deedee',//lb:'',    
                    templateType:"string", 
                    cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"text", 
                    placeholder:'',       
                }, 
                address:{
                    lb:'deedee',//lb:'',    
                    templateType:"string", 
                    cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"text", 
                    placeholder:'',       
                }, 
                remark:{
                    lb:'deedee',//lb:'ท',    
                    templateType:"string", 
                    cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"text", 
                    placeholder:'',       
                }, 
            }
            
            //cName:"xc12 lc12 sc12 mt-1 p-1 bd-lightGray",  
            //subCName:["xc12","xc12"], 
    
            //placeholder:'',
            //subFormTemplate:{


            //}
    },

    remark:{
        lb:'deedee',//lb:'หมายเหตุ', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2 alignSelfStart","xc10"], 
        inputType:"textarea", 
        placeholder:'', 
        textRow:3
    },

}



const partnerEditForm={
    isSupplier:{...partnerForm.isSupplier,cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",subCName:["xc4","xc8"]},
    isCustomer:{...partnerForm.isCustomer,cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",subCName:["xc4","xc8"]},

    id:{...partnerForm.id,inputType:"number",cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx"},
    newId:{
        lb:'deedee',//lb:'ไอดีใหม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"genId", 
        placeholder:'', 
    },
    taxId:{...partnerForm.taxId,inputType:"text",cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx"},

    partnerType:{...partnerForm.partnerType,cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx"},
    
    active:{...partnerForm.active,cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",subCName:["xc2","xc10"]},

    remainingCustomerPoint:{...partnerForm.remainingCustomerPoint,cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",subCName:["xc4","xc8"]},
    totalSpending:{...partnerForm.totalSpending,cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",subCName:["xc4","xc8"]},


    title:{...partnerForm.title,cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",subCName:["xc2","xc10"]},
    name:{...partnerForm.name,cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",subCName:["xc2","xc10"]},

    phone:{...partnerForm.phone,cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",subCName:["xc2","xc10"]},
    contact:{...partnerForm.contact,cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",subCName:["xc2","xc10"]},


    address:{...partnerForm.address,cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",subCName:["xc2","xc10"]},
    
    delivery:partnerForm.delivery,
    remark:{...partnerForm.remark,cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",subCName:["xc2","xc10"]},
    
}

//=======================
//=======================

const taskForm={
    id:{ //0
        lb:'deedee',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"genId", 
        placeholder:'', 
        autoFocus:"autoFocus",
        //calculation:{method:"autoId"},
    },

    active:{ //1
        lb:'deedee',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc12 lc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"checkbox", 
        placeholder:'', 
    },

    taskName:{ //2
        lb:'deedee',//lb:'ชื่อ', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },

    taskType:{ //3
        lb:'deedee',//lb:'ประเภทคู่ค้า', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'taskType'
    },

    taskStatus:{ //4
        lb:'deedee',//lb:'ประเภทคู่ค้า', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'taskStatus'
    },

    date:{ //5
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date",//"date", 
        placeholder:'', 
    },

    remindDate:{ //6
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date",//"date", 
        placeholder:'', 
    },

    startDate:{  //7
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date",//"date", 
        placeholder:'', 
    },

    endDate:{  //8
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date",//"date", 
        placeholder:'', 
    },

    title:{  //9
        lb:'deedee',//lb:'คำนำหน้า', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'title'
    },

    name:{  //10
        lb:'deedee',//lb:'ชื่อ', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },
   
    phone:{ //1
        lb:'deedee',//lb:'โทรศัพท์', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },

    address:{ //12
        lb:'deedee',//lb:'โทรศัพท์', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },

    contact:{  //13
        lb:'deedee',//lb:'ติดต่อ', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },

    tableName:{   //14
        lb:'deedee',//lb:'ประเภทคู่ค้า', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"table", 
        placeholder:'', 
     
    },

    remark:{  //15
        lb:'deedee',//lb:'หมายเหตุ', 
        templateType:"string" , 
        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2 alignSelfStart","xc10"], 
        inputType:"textarea", 
        placeholder:'', 
        textRow:3
    },

}



const taskEditForm={
    id:taskForm.id,
    newId:{
        lb:'deedee',//lb:'ไอดีใหม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"genId", 
        placeholder:'', 
    },

    active:taskForm.active,

    taskName:taskForm.taskName,
    taskType:taskForm.taskType,
    taskStatus:taskForm.taskStatus,

    date:taskForm.date,
    remindDate:taskForm.remindDate,
    startDate:taskForm.startDate,
    endDate:taskForm.endDate,

    title:taskForm.title,
    name:taskForm.name,
    phone:taskForm.phone,
    address:taskForm.address,

    contact:taskForm.contact,
    tableName:taskForm.tableName,

    remark:taskForm.remark
}


const repeatedTaskForm={
    id:taskForm.id,  //1

    active:taskForm.active,//2

    taskName:taskForm.taskName, //3
    taskType:taskForm.taskType, //4
    taskStatus:taskForm.taskStatus, //5

    date:taskForm.date,   //6
    remindDate:taskForm.remindDate, //7

    repeatedDate:{  //7
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc12 lc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"repeatedCalendar",//"date", 
        placeholder:'', 
    },

    title:taskForm.title, //10
    name:taskForm.name, //11
    phone:taskForm.phone, //12
    address:taskForm.address, //13

    contact:taskForm.contact, //14
    tableName:taskForm.tableName, //15

    remark:taskForm.remark
}

//======================
//======================

const productForm={
    
    id:{
        lb:'deedee',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"genId", 
        placeholder:'', 
        autoFocus:"autoFocus",
        //calculation:{method:"autoId"},
    },
    barcode:{
        lb:'deedee',//lb:'บาร์โค้ด', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"genId", 
        placeholder:'', 
    },
    sn:{
        lb:'deedee',//lb:'บาร์โค้ด', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"genId", 
        placeholder:'', 
    },
    productName:{
        lb:'deedee',//lb:'ชื่อ', 
        templateType:"string" , 
        cName:"pl-0 xc12 lc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2","xc10"], 
        inputType:"text", 
        placeholder:'', 
    },
    active:{
        lb:'deedee',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
    isRawMat:{
        lb:'deedee',//lb:'เป็นวัตถุดิบ', 
        templateType:"string" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
        //disabled:"disabled"
    },
    //==================
    point:{
        lb:'deedee',//lb:'แต้ม', 
        templateType:"number" , 
        cName:"pl-0 xc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //disabled:"disabled"
    },

    //==========================
    price:{
        lb:'deedee',//lb:'ราคา', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
    unit:{
        lb:'deedee',//lb:'หน่วย', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'unit'
    },
    storage:{
        lb:'deedee',//lb:'ยอดสต็อค', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
       
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'storage'
    },
    /*
    order:{
        lb:'deedee',//lb:'ยอดจอง', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //disabled:"disabled"
    },

    plan:{
        lb:'deedee',//lb:'ยอดแผน', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //disabled:"disabled"
    },
    */
    //========================
    priceLevel:{
        lb:'deedee',//lb:'ระดับราคา',    
        templateType:"arrayObject", 
        //cName:["xc12 pt-1 bd-lightGrayxx","form-row flex-justify-start flex-align-stretch"], 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc12","xc12"], 

        placeholder:'',
        subFormTemplate:{
            mark:{
                lb:'deedee',//lb:'หมายเหตุ', 
                templateType:"string" , 
                cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                subCName:["xc4 alignSelfStart","xc8"], 
                inputType:"select", 
                placeholder:'', 
                //selectObj:["นาย","นาง"]
                selectDataKey:"basicData",
                selectObj:'mark'
            }, 

            price:{
                lb:'deedee',//lb:'ราคา', 
                templateType:"string" , 
                cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                subCName:["xc4","xc8"], 
                inputType:"number", 
                placeholder:'', 
            },
            remark:{
                lb:'deedee',//lb:'หมายเหตุ', 
                templateType:"string" , 
                cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                subCName:["xc4 alignSelfStart","xc8"], 
                inputType:"text", 
                placeholder:'',            
            }, 
            
        }
    },

    remark:{
        lb:'deedee',//lb:'หมายเหตุ', 
        templateType:"string" , 
        cName:"pl-0 xc12 lc12 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc2 alignSelfStart","xc10"], 
        inputType:"textarea", 
        placeholder:'', 
        textRow:5,
        lastRef:true
    }, 
    //======================
    icon:{
        lb:'deedee',//lb:"กลุ่มสินค้าที่เลือกไว้",
        templateType:"icon-group",
        cName:"pl-0 xc12 lc12 sc12  pt-1",  
        subCName:["xc1 flex-center-center jc-start","xc11"], 
        inputType:"searchIcon",
        iconActionIdx:0,
    },
    
    groupId:{
        lb:'deedee',//lb:'ไอดีกลุ่ม', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //unchangeable:true
        //disabled:"disabled"
    },
    
    groupName:{
        lb:'deedee',//lb:'ชื่อกลุ่ม', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
        //unchangeable:true
        //disabled:"disabled"
    },


    //======================
   
    detail:{
        lb:'deedee',//lb: "รายละเอียด",
        templateType:"arrayObjectInTable",
        cName:"pl-0 xc12 lc12 sc12",
        subFormTemplate:{
          _id:{
            templateType:"string",
          },
          selectedLine:{
            templateType:"boolean",
          },
          id:{
            lb:'deedee',//lb:"ไอดี",
            templateType:"number",
          },
          barcode:{
            lb:'deedee',//lb:"บาร์โค้ด",
            templateType:"string",
          },
          productName:{
            lb:'deedee',//lb:"ชื่อ",
            templateType:"string",
          },

          groupId:{
            lb:'deedee',//lb:"ไอดีกลุ่ม",
            templateType:"number",
          },
          groupName:{
            lb:'deedee',//lb:"ชื่อกลุ่ม",
            templateType:"string",
          },
          quantity:{
            lb:'deedee',//lb:"จำนวน",
            templateType:"string",
          },

          unit:{
            lb:'deedee',//lb:"หน่วย",
            templateType:"string",
          },
          price:{
            lb:'deedee',//lb:"ราคา",
            templateType:"string",
          },
          result:{
            lb:'deedee',//lb:"รวม",
            templateType:"string",
          },
          remark:{
            lb:'deedee',//lb:"หมายเหตุ",
            templateType:"string",
          },
          isRawMat:{
            lb:'deedee',//lb:"เป็นวัตถุดิบ",
            templateType:"boolean",
          },
          photoUrl1:{
            lb:'deedee',//lb:'โทรศัพท์', 
            templateType:"array" , 
          }
        
        
        }
    }
}

//==========================
const tempStocking={
    stock:{
        lb:'deedee',//lb:'ยอดสต็อค', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //disabled:"disabled"
    },
    minStock:{
        lb:'deedee',//lb:'ยอดสต็อค', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //disabled:"disabled"
    },
    maxStock:{
        lb:'deedee',//lb:'ยอดสต็อค', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //disabled:"disabled"
    },
    storage:{
        lb:'deedee',//lb:'ยอดสต็อค', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
       
        inputType:"select", 
        placeholder:'', 
        selectDataKey:"basicData",
        selectObj:'storage'
    },
}

//====================================
const {id,...remaining}=productForm

const productEditForm = {
    id:{...productForm.id,inputType:"genId"},
    newId:{
        lb:'deedee',//lb:'ไอดีใหม่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"genId", 
        placeholder:'', 
    },
    barcode:productForm.barcode,
    sn:productForm.sn,
    productName:productForm.productName,
    active:productForm.active,
    isRawMat:productForm.isRawMat,
    point:productForm.point,
    price:productForm.price,
    unit:productForm.unit,
    stock:tempStocking.stock,
    minStock:tempStocking.minStock,
    maxStock:tempStocking.maxStock,
    storage:tempStocking.storage,


    //order:productForm.order,
    //plan:productForm.plan,
    priceLevel:productForm.priceLevel,
    remark:productForm.remark,

    icon:productForm.icon,
    groupId:productForm.groupId,
    groupName:productForm.groupName,
    detail:productForm.detail,
} 
//id,barcode,productName,isRawMat,
//icon,groupId,groupName,
//price,unit,stock,order,plan,
//priceLevel,remark,detail
//====================================
const shopForm={
    shopName:{
        lb:'',//lb:'ไอดีกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
        disabled:"disabled"
        //autoFocus:"autoFocus"
    },
    active:{
        lb:'',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc12 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
    dateIn:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date",//"date", 
        placeholder:'', 
    },
    ownerName:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    ownerSurName:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    ownerEmail:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    expirationDate:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date",//"date", 
        placeholder:'', 
    },
    tempExpirationDate:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date",//"date", 
        placeholder:'', 
    },
    monthlyFee:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
    remainingBalance:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
}
const userForm={
    id:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        autoFocus:"autoFocus",
        //disabled:"disabled"
    },
    username:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    active:{
        lb:'',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
    userLevel:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    shopId:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    dateIn:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date", 
        placeholder:'', 
    },
    branchId:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
    branchName:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    name:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    surname:{
        lb:'',//lb:'ชื่อกลุ่มสินค้า', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
}
const shopPayForm={
    id:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        autoFocus:"autoFocus",
        //disabled:"disabled"
    },
    userId:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
        //disabled:"disabled"
    },
    shopId:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
        //disabled:"disabled"
    },
    isCheck:{
        lb:'',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
    hasProblem:{
        lb:'',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
    transferDate:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date", 
        placeholder:'', 
    },
    accountName:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    accountNumber:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    transferAmount:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
    fromBank:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    checkBy:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    addMonth:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
    newExpirationDate:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc3 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date", 
        placeholder:'', 
    },
    dateIn:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc3 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date", 
        placeholder:'', 
    },
    ownerName:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    ownerSurName:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    ownerEmail:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"text", 
        placeholder:'', 
    },
    expirationDate:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date", 
        placeholder:'', 
    },
    tempExpirationDate:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"date", 
        placeholder:'', 
    },
    monthlyFee:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
    remainingBalance:{
        lb:'',//lb:'วันที่', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"number", 
        placeholder:'', 
    },
    active:{
        lb:'',//lb:'แอคทีฟ', 
        templateType:"string" , 
        cName:"pl-0 xc6 lc6  sc12 pt-1 bd-lightGrayxx",  
        subCName:["xc4","xc8"], 
        inputType:"checkbox", 
        placeholder:'', 
    },
}


const basicDataForAdminForm={
        id:{
            lb:'',//lb:'ไอดี', 
            templateType:"string" , 
            cName:"xc12 lc12 sc12 p-1 bd-lightGray",  
            subCName:["xc2","xc10"], 
            inputType:"number", 
            placeholder:'', 
            autoFocus:"autoFocus",
            //disabled:"disabled"
        },
        routeAuth:{
            lb:'',//lb:'ระดับราคา',    
            templateType:"arrayObject", 
            //cName:["xc12 pt-1 bd-lightGrayxx","form-row flex-justify-start flex-align-stretch"], 
            cName:"xc12 lc12 sc12 mt-1 p-1 bd-lightGray",  
            subCName:["xc12","xc12"], 
    
            placeholder:'',
            subFormTemplate:{
                id:{
                    lb:'',
                    templateType:"string" , 
                    cName:"xc12 lc12 sc12 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"number", 
                    placeholder:'', 
                },
                routeAddress:{
                    lb:'',
                    templateType:"string" , 
                    cName:"xc12 sc12 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"text", 
                    placeholder:'', 
                },
                routeName:{
                    lb:'',
                    templateType:"string" , 
                    cName:"xc12 sc12 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"text", 
                    placeholder:'',            
                }, 
                userLevel:{
                    lb:'',//lb:'โทรศัพท์', 
                    templateType:"array" , 
                    cName:"xc12 sc12 bd-lightGrayxx",  
                    subCName:["xc2","xc10"], 
                    inputType:"text", 
                    placeholder:'', 
                },
            }
        },
}

const branchForAdminForm={
    id:{
        lb:'',//lb:'ไอดี', 
        templateType:"string" , 
        cName:"xc12 lc12 sc12 mt-1 p-1 bd-lightGray",  
        subCName:["xc2","xc10"], 
        inputType:"number", 
        placeholder:'', 
        autoFocus:"autoFocus",
    },



    tableTemplate:{
        lb:'',
        templateType:"arrayObject", 
        cName:"xc12 lc12 sc12 mt-1 p-1 bd-lightGray",  
        subCName:["xc12","xc12"], 

        placeholder:'',
        subFormTemplate:{
            tableName:{
                lb:'',
                templateType:"string" , 
                cName:"xc12 sc12 pt-1 bd-lightGrayxx",  
                subCName:["xc2","xc10"], 
                inputType:"text", 
                placeholder:'', 
            },

            
            tableTemplate:{
                lb:'',
                templateType:"arrayObject", 
                cName:"xc11 sc11 pt-1 bd-lightGrayxx",  
                subCName:["xc12","xc12"], 
        
                placeholder:'',
                subFormTemplate:{
                    
                    colName:{
                        lb:'',//lb:'ราคา', 
                        templateType:"string" , 
                        cName:"pl-0 xc12 sc12 pt-1 bd-lightGrayxx",  
                        subCName:["xc4","xc8"], 
                        inputType:"text", 
                        placeholder:'', 
                    },

                    
                    colOrder:{
                        lb:'',
                        templateType:"string" , 
                        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
                        subCName:["xc4","xc8"], 
                        inputType:"number", 
                        placeholder:'', 
                    },
                    lb:{
                        lb:'',
                        templateType:"string" , 
                        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
                        subCName:["xc4","xc8"], 
                        inputType:"text", 
                        placeholder:'', 
                    },
                    type:{
                        lb:'',
                        templateType:"string" , 
                        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
                        subCName:["xc4","xc8"], 
                        inputType:"text", 
                        placeholder:'', 
                    },
                    width:{
                        lb:'',
                        templateType:"string" , 
                        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
                        subCName:["xc4","xc8"], 
                        inputType:"text", 
                        placeholder:'', 
                    },
                    showCol:{
                        lb:'',//lb:'แอคทีฟ', 
                        templateType:"string" , 
                        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
                        subCName:["xc4","xc8"], 
                        inputType:"checkbox", 
                        placeholder:'', 
                    },
                    showColHead:{
                        lb:'',//lb:'แอคทีฟ', 
                        templateType:"string" , 
                        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
                        subCName:["xc4","xc8"], 
                        inputType:"checkbox", 
                        placeholder:'', 
                    },
                    showSum:{
                        lb:'',//lb:'แอคทีฟ', 
                        templateType:"string" , 
                        cName:"pl-0 xc6 lc6 sc12 pt-1 bd-lightGrayxx",  
                        subCName:["xc4","xc8"], 
                        inputType:"checkbox", 
                        placeholder:'', 
                    },
                    


                }
                
            }
            
        }
    }
    
}
//====================================

//====================================

const FormTemplate={
    //shopChangePasswordForm,addUserForm,
    partnerForm,partnerEditForm,
    //groupForm,groupEditForm,
    productForm,productEditForm,

    shopForm,userForm,shopPayForm,

    //productDetailForm,
    basicDataForAdminForm,
    branchForAdminForm,

    taskForm,
    taskEditForm,
    repeatedTaskForm,
}

export default FormTemplate
//disabled:"disabled",

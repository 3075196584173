import React from 'react';
import Table from '../../component/table/Table'
import tableUtil from '../../component/table/tableUtil';
import {FaBan} from 'react-icons/fa';
import {GrNew} from 'react-icons/gr';
import {MdEdit,MdSwapHoriz} from 'react-icons/md';
import {RiSplitCellsVertical,RiMergeCellsVertical} from 'react-icons/ri'

import uuid from 'react-uuid';
import EditBillForm from './EditBillForm';
import axiosUtil from '../../util/axiosUtil';
import axios from '../../util/axiosVerify';
import Ticon from '../../component/ticon/Ticon';
import GalleryoneHeight from '../../component/galleryone/GalleryoneHeight'
import renderGrid from '../../render/renderForm/renderGrid'

import console from '../../util/consoleVerify'

const {numberWithCommas}=tableUtil
const {genId,genInt}=axiosUtil

function BillForm(props) {

const {blankData,dataIdx,closeBillForm,
       //data,
       billFormData,
       setBillFormData,

       selectOfSelectProduct,
       clearSelectOfSelectProduct,
       selectOfCustomer,
       setResetProductList,
       calDigit,
       pageData,
       basicData,
       user,
       tableTemplate,
       saveTableTemplateFunc,
       submitFunctionAddFunc,
       submitFunctionEditFunc,
       submitFunctionDeleteFunc,
       //runIsGenId,
       //isGenIdOfBillForm,
       updateData,
       myheader,
       setPrintDataFunc,
       bgColor,
       calEndBill,
       calEffect,
       footerHeight,
       setFooterHeight,
       
       buttonLastRef,
       transactionType,
       printPage,

       showTableInBillForm,
       setShowTableInBillForm
       }=props

//console.log('BillForm.................')
//console.log(basicData)

//console.log(billFormData)
//console.log('lllllllllllllll===============')

const refMdAddCircle=React.useRef()

const blankDetail={
    _id:uuid(),
    id:"",barcode:"",sn:"",
    productName:"",
    groupId:"",groupName:"",
    unit:"",price:0,
    priceLevel:[{price:0,remark:""}],
    quantity:0,result:0,remark:"",isRawMat:false,
    point:0,resultPoint:0,
    partnerId:"",name:"",jobStatus:"open",
    detailTime:"",
    selectedLine:false,
    
}

const {billForm}=pageData
/*
const billForm={
    formHead:"",
    id:"ID",
    date:"Date",
    time:"Time",
    table:"Table",
    partnerId:"Partner ID",
    phone:"Phone",
    address:"Address",
    total:"Total",
    totalTax:"Total Tax",
    totalReduction:"Total Reduction",
    grandTotal:"GrandTotal"
}
*/
const shouldRender= React.useRef(true)

React.useEffect(()=>{
    if(!billFormData){
        refMdAddCircle.current.click()
    }
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

React.useEffect(()=>{
    //console.log('UseEffect selectOfSelectProduct...............')
    //console.log(selectOfSelectProduct)
    
    if(selectOfSelectProduct&&billFormData){
        if(selectOfSelectProduct.selectProduct.length>0){

            if(selectOfSelectProduct.dataIdx==dataIdx){
                //console.log('aeiou....')
                //console.log(selectOfSelectProduct.selectProduct)


                const tempArray=[...billFormData.detail,...selectOfSelectProduct.selectProduct]

                //console.log(tempArray)
                //console.log('billFormData')
                //console.log(billFormData)

                let tempArray2=[]
                tempArray.map(i=>{
                   tempArray2=[...tempArray2,
                    {...i,resultIncludeVat:i.quantity*i.priceIncludeVat}
                ]

                })


                setBillFormData({...billFormData,
                    reCal:true,
                    detail:tempArray2
                })    
                clearSelectOfSelectProduct()

            }
        }

    }
},[selectOfSelectProduct])

React.useEffect(()=>{
    //console.log('useEffect SelectOfCustomer')
    if(selectOfCustomer&&billFormData){
        if(selectOfCustomer.dataIdx==dataIdx){
            
            //const tempArray=[...billFormData.detail,...selectOfSelectProduct.selectProduct]
            setBillFormData({...billFormData,
                ...selectOfCustomer.selectCustomer
            })    
        }
    }
},[selectOfCustomer])


const [showTag,setShowTag]=React.useState(false)


const [showEditBillForm,setShowEditBillForm]=React.useState(false)

//const [billFormData,setBillFormData]=React.useState(genBillFormData(data))//({...data,reCal:true})

const [editData,setEditData]=React.useState(null)

let [formTableTemplate,setFormTableTemplate]=React.useState(tableTemplate)

//const [footerHeight,setFooterHeight]=React.useState(25)


React.useEffect(()=>{
    //console.log('editData')
    //console.log(editData)
},[editData])


React.useEffect(()=>{
    
    if(billFormData){
        //console.log(`useEffect billFormData............`)
        //console.log(billFormData)
        
        if(billFormData.reCal){
            //console.log('reCal 1')
            let tempTotal=0
            let tempTotalPoint=0
            let tempTotalIncludeVat=0
            let tempTotalExcludeVat=0
            //let tempGrandTotal=0
            let tempReductCustomerPoint=0

            const {total,endBill,detail,effect,reductCustomerPoint,
                useInnerVat,innerVatRate
                }=billFormData  
            //console.log('reCal2')

            let tempArray=[]

            detail.map(i=>{
                //console.log('i....')
                //console.log(i)
                
                let tempPrice=0
                let tempResult=0
                let tempPriceIncludeVat=0
                let tempResultIncludeVat=0

                if(i.quantity&&i.price){
                    
                    const tempQuantity=genInt(i.quantity,calDigit)
                    tempPrice=genInt(i.price,calDigit)
                    const temp3=tempPrice*tempQuantity
                    tempResult=genInt(temp3,calDigit)


                    tempPriceIncludeVat=tempPrice
                    tempResultIncludeVat=tempResult

                    if(useInnerVat&&innerVatRate){
                        let temp2=tempPrice+tempPrice*innerVatRate/100
                        tempPriceIncludeVat=genInt(temp2,calDigit)
                        const temp4=tempPriceIncludeVat*tempQuantity
                        tempResultIncludeVat=genInt(temp4,calDigit)
                    }

                    tempTotalIncludeVat=tempTotalIncludeVat+tempResultIncludeVat
                    tempTotalExcludeVat=tempTotalExcludeVat+tempResult
                   
                }

                if(i.point){
                    const tempPoint=genInt(i.point,calDigit)
                    const tempQuantity=genInt(i.quantity,calDigit)
                    let temp=tempPoint*tempQuantity
                    let tempResultPoint=genInt(temp,calDigit)
                    tempTotalPoint=tempTotalPoint+tempResultPoint
                }

                tempArray=[tempArray,{
                    ...i,
                    priceIncludeVat:tempPriceIncludeVat,
                    resultIncludeVat:tempResultIncludeVat,
                    price:tempPrice,
                    result:tempResult
                }]

            })
            //console.log('reCal3')
            tempTotal=tempTotalExcludeVat
            if(useInnerVat&&innerVatRate){
                tempTotal=tempTotalIncludeVat
            }
         
            const tempCalEndBill=calEndBill(endBill,tempTotal)
            const tempEndBill=tempCalEndBill.endBill
            const tempGrandTotal=tempCalEndBill.grandTotal
            //console.log('plus .endbill.......................')
            //console.log(endBill)
            //function isNumeric(n) {
              //  return !isNaN(parseFloat(n)) && isFinite(n);
            //}
            //console.log('tempTotalObj')
            //console.log(tempTotalObj)

            //console.log('endBill.........')
            //console.log(endBill)

         
            //console.log('reCal4')
            //console.log('tempEndBill++++++++++')
            //console.log(tempEndBill)
            
            tempReductCustomerPoint=genInt(reductCustomerPoint,calDigit)

            let temp={
                reCal:false,
                //detail:tempArray,
                endBill:tempEndBill,
                effect:calEffect(effect,tempTotal,tempTotalPoint,tempGrandTotal,tempEndBill),
                grandTotal:tempGrandTotal,
                totalPoint:tempTotalPoint,
                reductCustomerPoint:tempReductCustomerPoint,
                total:tempTotal,
                totalIncludeVat:tempTotalIncludeVat,
                totalExcludeVat:tempTotalExcludeVat,
            }

            //console.log('temp.......')
            //console.log(temp)
            
            setBillFormData({...billFormData,...temp})
        }
    }
},[billFormData])

React.useEffect(()=>{
    //console.log('billFormData')
    //console.log('formTableTemplate')
   // //console.log(formTableTemplate)
},[formTableTemplate])

/*
const runGenId=()=>{
    setBillFormData({
        ...billFormData,
        isGenId:true
    })
}
*/
/*
const findDateTime=(date)=>{

    const date1=new Date(date).toISOString()

    const date3=new Date(date1).toLocaleString('en-GB')

   const tempDate= date3.substring(0,10)
   const tempTime=date3.substring(12,17)
   return {date:tempDate,time:tempTime}
}
*/

const findDateTime=(date)=>{

    //const date1=new Date(date).toISOString()
    let date3=""

    if(date){
        let temp
        if(basicData.dateFormat){
            temp=basicData.dateFormat.split("format:")[0]
        }
        else {
            temp='en-GB'
        }

        date3=new Date(date).toLocaleString(temp)
    }
    
   return date3
}
const genArrayToString=(array)=>{
    const genSeparator=(str)=>{
        const tempArray=Array.from(str)
        let tempString=''
        tempArray.map((i,idx)=>{
            if((idx==3)||(idx==6)){
                tempString=`${tempString} ${i}`
            }
            else {
                tempString=`${tempString}${i}`
            }
        })
        return tempString
    }

    let temp=''
    array.map((i,idx)=>{

        if(idx==array.length-1){
            temp=`${temp}${genSeparator(i)}`
        }
        else{
            temp=`${temp}${genSeparator(i)},`
        }
    })
    return temp
}

const setTableTemplateFunc=(value)=>{
    //console.log('setTableTemplateFunc')
    setFormTableTemplate(value)
}
//id,date,branch,
//transactionType,transactionStatus,active
//table,tableStatus
//partnerId,title,name,phone,address,

const setFilterDataFunc=(value)=>{
    //console.log('setFilterDataFunc')
    //console.log(value)
    if(value){
        if(Array.isArray(value)){
            setBillFormData({...billFormData,detail:value})
        }
    }
}

const setEditDataFunc=(value)=>{
    //console.log('setEditDataFunc')

    billFormData.detail.map(i=>{
        if(i._id==value._id){
            setEditData(i)
        }
    })
}


const moveUp=()=>{
    if(editData){
        
        let tempArray=[]
        let targetIdx=null

        billFormData.detail.map((i,idx)=>{
            if(i._id==editData._id){
                targetIdx=idx
            }
        })

        if((targetIdx!=null)&&(targetIdx>0)){
            
            billFormData.detail.map((i,idx)=>{
               
                if(idx==targetIdx-1){
                    tempArray=[...tempArray,billFormData.detail[targetIdx]]
                }
                else if(idx==targetIdx){
                    tempArray=[...tempArray,billFormData.detail[targetIdx-1]]
                }
                else {
                    tempArray=[...tempArray,i]
                }

            })
            setBillFormData({...billFormData,detail:tempArray})
        }
    
    }
}

const moveDown=()=>{
    if(editData){
        
        let tempArray=[]
        let targetIdx=null
        const lastIndex=billFormData.detail.length-1

        billFormData.detail.map((i,idx)=>{
            if(i._id==editData._id){
                targetIdx=idx
            }
        })

        if((targetIdx!=null)&&(targetIdx<lastIndex)){
            
            billFormData.detail.map((i,idx)=>{
               
                if(idx==targetIdx){
                    tempArray=[...tempArray,billFormData.detail[targetIdx+1]]
                }
                else if(idx==targetIdx+1){
                    tempArray=[...tempArray,billFormData.detail[targetIdx]]
                }
                else {
                    tempArray=[...tempArray,i]
                }

            })
            setBillFormData({...billFormData,detail:tempArray})
        }
    
    }
}


/*
const deleteLine=()=>{
    if(editData){

    let tempArray=[]

        billFormData.detail.map((i,idx)=>{
            if(i._id!=editData._id){
                tempArray=[...tempArray,i]
            }
        })

        setBillFormData({...billFormData,reCal:true,detail:tempArray})
    }
}
*/

const selectSameGroup=()=>{
    if(editData){
        if(billFormData.detail){
            if(billFormData.detail.length){
                let tempArray=[]

                billFormData.detail.map((i,idx)=>{
                    if(i.groupId==editData.groupId){
                        tempArray=[...tempArray,{...i,selectedLine:true}]
                    }
                    else{
                        tempArray=[...tempArray,{...i,selectedLine:false}]
                    }
                })
         
                setBillFormData({...billFormData,reCal:true,detail:tempArray})

            }
        }
    }
}

const selectAll=()=>{
    if(billFormData.detail){
        if(billFormData.detail.length){
            let tempArray=[]

            billFormData.detail.map((i,idx)=>{
                tempArray=[...tempArray,{...i,selectedLine:true}]
            })

            setBillFormData({...billFormData,reCal:true,detail:tempArray})

        }
    }
}

const unSelectAll=()=>{
    if(billFormData.detail){
        if(billFormData.detail.length){
            let tempArray=[]

            billFormData.detail.map((i,idx)=>{
                tempArray=[...tempArray,{...i,selectedLine:false}]
            })

            setBillFormData({...billFormData,reCal:true,detail:tempArray})

        }
    }
}

const deleteLine=()=>{

    if(billFormData.detail){
        if(billFormData.detail.length>0){
            
            
            if(editData){

                let tempArray=[]

                billFormData.detail.map((i,idx)=>{
                    if(i.selectedLine){
                        //tempArray=[...tempArray,i]

                        if(editData._id==i._id){
                            //deleteEditData=true
                            setEditData(null)
                        }
                    }
                    else{
                        tempArray=[...tempArray,i]
                    }
                })
    
                setBillFormData({...billFormData,reCal:true,detail:tempArray})

                
            }
            else{
                let tempArray=[]

                billFormData.detail.map((i,idx)=>{
                    if(i.selectedLine){
                        //tempArray=[...tempArray,i]
                    }
                    else{
                        tempArray=[...tempArray,i]
                    }
                })
    
                setBillFormData({...billFormData,reCal:true,detail:tempArray})
            }
            
        }
    }

}

const insertLine=()=>{

    let tempObj=blankDetail

    if(editData){

        //console.log('1')

        let tempArray=[]

        billFormData.detail.map((i,idx)=>{
            if(i._id==editData._id){
                //const tempObj=blankDetail
                tempArray=[...tempArray,i,
                    {...tempObj,_id:uuid(),detailTime:new Date().toISOString()},
                    ]
            }
            else{
                tempArray=[...tempArray,i]
            }
        })
        //console.log(tempArray)
        setBillFormData({...billFormData,reCal:true,detail:tempArray})
    }
    else{

        let tempArray=[]
        if(!billFormData.detail){
        
            //console.log('2')

            //const tempObj=blankDetail

            tempArray=[
                {...tempObj,_id:uuid(),detailTime:new Date().toISOString()},
                ]
            setBillFormData({...billFormData,reCal:true,detail:tempArray})
        }
        else{
            if(billFormData.detail.length==0){
            
                //console.log('3')
                
                //const tempObj=blankDetail

                tempArray=[
                    {...tempObj,_id:uuid(),detailTime:new Date().toISOString()},
                    ]
                
                setBillFormData({...billFormData,reCal:true,detail:tempArray})

            }
            else {
                //console.log('4')

                tempArray=[...billFormData.detail,{...tempObj,_id:uuid(),detailTime:new Date().toISOString()}]
                setBillFormData({...billFormData,reCal:true,detail:tempArray})

            }
        }
      
    }
}

const updateFilterData=(idx,value)=>{
    //console.log('updateFilterData,,,,,,,,,,,,')
    //console.log(value)
    let tempDetail=billFormData.detail
    tempDetail[idx]=value

    setBillFormData({...billFormData,
        reCal:true,
        detail:tempDetail
    })
}


const updateFilterDataForGrid=(idx,value,useRecal)=>{


    let tempDetail=billFormData.detail
    tempDetail[idx]=value

    if(useRecal){
        setBillFormData({...billFormData,
            reCal:true,
            detail:tempDetail
        })

    }
    else {

        setBillFormData({...billFormData,
            //reCal:true,
            detail:tempDetail
        })

    }

    
}




const mergeUp=()=>{

    let tempArray=[]

    billFormData.detail.map(i=>{
        tempArray=[...tempArray,i.id]
    })

    let uniqueArray = [...new Set(tempArray)];
    uniqueArray=uniqueArray.sort()

    let tempDetail=[]

    uniqueArray.map(i=>{
        let tempQuantity=0
        let tempResult=0
        let tempResultPoint=0
        let tempObj=null
        billFormData.detail.map(j=>{
            
            if(j.id==i){
                tempQuantity=tempQuantity+j.quantity
                tempResult=tempResult+j.result
                tempResultPoint=tempResultPoint+j.resultPoint
                tempObj={...j,
                            quantity:tempQuantity,
                            result:tempResult,
                            resultPoint:tempResultPoint,
                            _id:uuid()}    
            }
        })
        if(tempObj){
            tempDetail=[...tempDetail,tempObj]
        }
    })

    setBillFormData({...billFormData,reCal:true,detail:tempDetail})

}
//------------------------------------
const updatePrice=()=>{

    if(editData){

        axios.post(`/p35product/getlimit`,{id:editData.id},myheader,shouldRender)
        .then(result=>{
            //console.log('result')
            const foundProduct=result.data.data[0]
            if(foundProduct){

                let tempDetail=[]
                let tempObj

                billFormData.detail.map(i=>{
                    if(i._id==editData._id){
                        let tempResult
                        if(foundProduct.price){
                            const tempPrice=genInt(foundProduct.price,calDigit)
                            const tempQuantity=genInt(i.quantity,calDigit)
        
                            const temp=tempPrice*tempQuantity
                            tempResult=genInt(temp,calDigit)
                        }
                        let tempResultPoint
                        let tempPoint
                        if(foundProduct.point){
                            tempPoint=genInt(foundProduct.point,calDigit)
                            const tempQuantity=genInt(i.quantity,calDigit)
                            let temp=tempPoint*tempQuantity
                            tempResultPoint=genInt(temp,calDigit)
                        }

                        tempObj={...i,
                            barcode:foundProduct.barcode,
                            sn:foundProduct.sn,
                            productName:foundProduct.productName,
                            groupId:foundProduct.groupId,
                            groupName:foundProduct.groupName,
                            unit:foundProduct.unit,
                            price:foundProduct.price,
                            priceLevel:foundProduct.priceLevel,
                            remark:foundProduct.remark,
                            isRawMat:foundProduct.isRawMat,
                            hasDetailProduct:foundProduct.hasDetailProduct,
                            photoUrl1:foundProduct.photoUrl1,
                        

                            result:tempResult,
                            point:tempPoint,
                            resultPoint:tempResultPoint

                        }
                        tempDetail=[...tempDetail,tempObj]
                    }
                    else{
                        tempDetail=[...tempDetail,i]
                    }
                })

                if(tempObj){
                    setBillFormData({...billFormData,reCal:true,detail:tempDetail})
                    setEditData(tempObj)
                }
            }
        })
        .catch(error=>{
            //console.log('error')
            //console.log(error)
            /*
            const tempError={...filterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterData(tempError)
            */
        })

    }

}

//------------------------------------
const rawUp=()=>{
    //console.log('rawUp..........1.')

    if(editData){
        if(editData.hasDetailProduct){
            //console.log('rawUp..........2.')
            axios.post(`/p35product/getlimit`,{id:editData.id},myheader,shouldRender)
            .then(result=>{
                //console.log('result')
                const foundProduct=result.data.data[0]
                //console.log(foundProduct)

                if(foundProduct){
                    if(foundProduct.detail){
                        if(Array.isArray(foundProduct.detail)){

                            let tempArray=[]

                            foundProduct.detail.map(i=>{
                                const tempQEditData=parseInt(editData.quantity*calDigit)/calDigit
                                const tempQi=parseInt(i.quantity*calDigit)/calDigit
                                const tempQ1=tempQEditData*tempQi
                                const tempQ2=parseInt(tempQ1*calDigit)/calDigit

                                const tempPrice=parseInt(i.price*calDigit)/calDigit
                                const tempPoint=parseInt(i.point*calDigit)/calDigit
                                const tempResult1=tempPrice*tempQ2
                                const tempResult2=parseInt(tempResult1*calDigit)/calDigit
                                const tempResultPoint1=tempPoint*tempQ2
                                const tempResultPoint2=parseInt(tempResultPoint1*calDigit)/calDigit

                                const tempObj={...i,_id:uuid(),
                                        selectedLine:false,
                                        priceLevel:[{price:0,remark:""}],
                                        jobStatus:"open",
                                        quantity:tempQ2,
                                        result:tempResult2,
                                        resultPoint:tempResultPoint2
                                    }
                                tempArray=[...tempArray,tempObj]
                            })
            
                            let tempDetail=[]
                            billFormData.detail.map(i=>{
                                if(i._id==editData._id){
                                    tempDetail=[...tempDetail,...tempArray]
                                }
                                else{
                                    tempDetail=[...tempDetail,i]
                                }
                            })
            
                            setBillFormData({...billFormData,reCal:true,detail:tempDetail})
                            setEditData(null)

                        }
                    }
                }
              
            })
            .catch(error=>{
                //console.log('error')
                //console.log(error)
                /*
                const tempError={...filterData,
                    reloadData:false,
                    message:catchErrorToMessage(error),
                    showModalConfirm:false,
                    showModalError:true,
                }
                //console.log(tempError.message)
                setFilterData(tempError)
                */
            })
            
        }
    }
}

//------------------------------------
const genFilterDataWithIndex=(filterData)=>{

    let tempArray=[]

    filterData.map((i,idx)=>{
        //const temp={...i,tempIndex:idx,selectedLine:false}
        let tempStocking={}

        if(i.stocking){
            let isSearching=true
            i.stocking.map(k=>{
                if(isSearching){
                    if(k.branchId==basicData.branchId){
                        tempStocking={
                            branchId:k.branchId,
                            stock:k.stock,
                            minStock:k.minStock,
                            maxStock:k.maxStock,
                            storage:k.storage
                        }
                        isSearching=false
                    }
                }   
            })
        }


        const temp={...i,selectedLine:false,_id:uuid(),...tempStocking}
        tempArray=[...tempArray,temp]

    })

    return tempArray
}

//------------------------------------
const findProductIdByKeyDown=(e,j,inputStateJ,idx)=>{
    if(e.key=="Enter"){
        axios.post('/p35product/getlimit',{id:e.target.value},myheader,shouldRender)
        .then(result=>{
            if(result.data.count>=1){


                const tempResultDataData=genFilterDataWithIndex(result.data.data)


                const {id,barcode,productName,
                    groupId,groupName,unit,price,priceLevel,
                    point,
                    remark,isRawMat,photoUrl1,
                    branchId,
                    stock,
                    minStock,
                    maxStock,
                    storage

                } = tempResultDataData[0] //result.data.data[0]


                let tempUseInnerVat=false
                let tempInnerVatRate=0

                tempUseInnerVat=billFormData.useInnerVat

                if(billFormData.innerVatRate>0||billFormData.innerVatRate<0){
                    tempInnerVatRate=parseInt(billFormData.innerVatRate*calDigit)/calDigit
                }

                let tempPriceIncludeVat=0
                let tempResultIncludeVat=0
                let tempPrice=price
                let tempResult=price

                if(tempUseInnerVat){
                    const tempPr=(price*tempInnerVatRate)/100+price
                    tempPriceIncludeVat=parseInt(tempPr*calDigit)/calDigit
                    tempResultIncludeVat=tempPriceIncludeVat
                    //tempResult=tempPriceIncludeVat
                    //tempPrice=tempPriceIncludeVat
                }

                const tempObj={
                    _id:uuid(),
                    id:id,barcode:barcode,productName:productName,
                    groupId:groupId,groupName:groupName,unit:unit,
                    
                    priceLevel:priceLevel,

                    result:tempResult,
                    resultIncludeVat:tempResultIncludeVat,

                    resultPoint:point,
                    point:point,
                    price:tempPrice,
                    priceIncludeVat:tempPriceIncludeVat,

                    selectedLine:true,
                    remark:remark,isRawMat:isRawMat,
                    quantity:1,result:price,
                    partnerId:billFormData.partnerId,
                    name:billFormData.name,
                    jobStatus:"open",
                    photoUrl1:photoUrl1,

                    branchId,
                    stock,
                    minStock,
                    maxStock,
                    storage

                }

                let tempArray=[]

                billFormData.detail.map((i,index)=>{
                    if(index==idx){
                        tempArray=[...tempArray,tempObj]
                    }
                    else {
                        tempArray=[...tempArray,i]
                    }
                })
                setBillFormData({...billFormData,
                    detail:tempArray
                })
            }
        })
        .catch(error=>{
            //console.log('error')
        })
    }
}

const findBarcodeByKeyDown=(e,j,inputStateJ,idx)=>{
    if(e.key=="Enter"){
        axios.post('/p35product/getlimit',{barcode:e.target.value},myheader,shouldRender)
        .then(result=>{
            if(result.data.count>=1){

                const tempResultDataData=genFilterDataWithIndex(result.data.data)

                const {id,barcode,productName,
                    groupId,groupName,unit,price,priceLevel,
                    point,
                    remark,isRawMat,photoUrl1,

                    branchId,
                    stock,
                    minStock,
                    maxStock,
                    storage

                } = tempResultDataData[0]


                let tempUseInnerVat=false
                let tempInnerVatRate=0

                tempUseInnerVat=billFormData.useInnerVat

                if(billFormData.innerVatRate>0||billFormData.innerVatRate<0){
                    tempInnerVatRate=parseInt(billFormData.innerVatRate*calDigit)/calDigit
                }

                let tempPriceIncludeVat=0
                let tempResultIncludeVat=0

                let tempPrice=price
                let tempResult=price

                if(tempUseInnerVat){
                    const tempPr=(price*tempInnerVatRate)/100+price
                    tempPriceIncludeVat=parseInt(tempPr*calDigit)/calDigit
                    tempResultIncludeVat=tempPriceIncludeVat
                    //tempResult=tempPriceIncludeVat
                    //tempPrice=tempPriceIncludeVat
                }

                const tempObj={
                    _id:uuid(),
                    id:id,barcode:barcode,productName:productName,
                    groupId:groupId,groupName:groupName,unit:unit,
                    priceLevel:priceLevel,
                    
                    
                    result:tempResult,
                    resultIncludeVat:tempResultIncludeVat,
                    resultPoint:point,
                    point:point,
                    price:tempPrice,
                    priceIncludeVat:tempPriceIncludeVat,
                    //priceExcludeVat:tempPriceExcludeVat,

                    selectedLine:true,
                    remark:remark,isRawMat:isRawMat,
                    quantity:1,result:price,
                    partnerId:billFormData.partnerId,
                    name:billFormData.name,
                    jobStatus:"open",
                    photoUrl1:photoUrl1,
                    branchId,
                    stock,
                    minStock,
                    maxStock,
                    storage
                }

                let tempArray=[]

                billFormData.detail.map((i,index)=>{
                    if(index==idx){
                        tempArray=[...tempArray,tempObj]
                    }
                    else {
                        tempArray=[...tempArray,i]
                    }
                })
                setBillFormData({...billFormData,
                    detail:tempArray
                })
            }
        })
        .catch(error=>{
            //console.log('error')
        })
    }
}

const addSelectOfSelectProduct=()=>{
    if(selectOfSelectProduct){
        if(selectOfSelectProduct.length>0){

            let tempSelectOfSelectProduct=[]
            selectOfSelectProduct.map(i=>{
                if(i.quantity>0){
                    const tempPrice=parseInt(i.price*calDigit)/calDigit
                    const tempQuantity=parseInt(i.quantity*calDigit)/calDigit

                    const tempObj={
                        ...i,
                        _id:uuid(),
                        price:tempPrice,
                        quantity:tempQuantity,
                        result:parseInt(tempPrice*tempQuantity*calDigit)/calDigit
                    }
                    tempSelectOfSelectProduct=[...tempSelectOfSelectProduct,tempObj]
                }
            })

            let tempArray=[]
            let targetIdx = null

            billFormData.detail.map((i,idx)=>{
                if(i._id==editData._id){
                
                    targetIdx=idx
                }
            })

            if(targetIdx>=0){
                billFormData.detail.map((i,idx)=>{
                    if(idx<targetIdx){
                        tempArray=[...tempArray,i]
                    }
                    else if(idx==targetIdx){
                        tempArray=[...tempArray,i,...tempSelectOfSelectProduct]
                    }
                    else {
                        tempArray=[...tempArray,i]
                    }
                })
            }

            setBillFormData({...billFormData,detail:tempArray})
            setResetProductList(true)

        }
    }

}

const editBillFormSubmitFunc=(data)=>{
    updateData(data)
    setBillFormData({...data,reCal:true})
    setShowEditBillForm(false)
}
const editBillFormCancelFunc=()=>{
    setShowEditBillForm(false)
}


const findFooterHeight=()=>{
    
    return `${footerHeight}%`

}

const findTableHeight=()=>{
    const temp=100-footerHeight
    return `${temp}%`
}


//==================================
const renderButton=()=>{
   
return(

    <div className=""
        style={{height:"100%",width:"100%",display:"flex",
                justifyContent:"flex-start",alignItems:"center",
                overflowX:"auto",
                overflowY:"hidden",
                //backgroundColor:bgColor
                //background: "rgb(233,187,67)",
                //background: "linear-gradient(90deg, rgba(233,187,67,1) 0%, rgba(172,124,66,1) 100%)"
                
                background:"rgb(240,207,121)",
                background:"linear-gradient(90deg, rgba(240,207,121,1) 0%, rgba(153,106,48,1) 100%)"
             }}
    >


        <button  style={{marginRight:"1.2rem"}}
            onClick={e=>{
                closeBillForm(billFormData)
            }}
        >{dataIdx=="cancel"
         ?<FaBan/>
         :dataIdx
        }
        </button>


        <div className="iconbox"
            ref={refMdAddCircle}
            onClick={async(e)=>{
                
            try{
                //console.log('plus....')
                const result=await axios.post('/p35transaction/getlimitfew',
                                    {limitRow:1,sort:{id:-1}},
                                    myheader,shouldRender) 
                
                const result2=await axios.post('/p35partner/getcustomerlimit',
                                    {id:basicData.defaultCustomerId},
                                    myheader,shouldRender) 
                
                let customer={
                    partnerType:"",
                    id:null,

                    title:"",
                    name:"",
                    phone:"",
                    address:"",
                    contact:"",

                    delivery:[],
                    isSupplier:false,
                    isCustomer:true,

                    taxId:"",
                    remainingCustomerPoint:0,
                    totalSpending:0
                }  
              
                if(result2.data.data[0]){
                    customer=result2.data.data[0]
                    
                    let tempRecipientTitle=""
                    let tempRecipientName=""
                    let tempRecipientPhone=""
                    let tempRecipientAddress=""
                    let tempRecipientContact=""
              
                    if(customer.title){
                        tempRecipientTitle=customer.title
                    }
                    if(customer.name){
                        tempRecipientName=customer.name
                    }
                    if(customer.phone){
                        tempRecipientPhone=customer.phone
                    }
                    if(customer.address){
                        tempRecipientAddress=customer.address
                    }
                    if(customer.contact){
                        tempRecipientContact=customer.contact
                    }

                    customer={...customer,
                                partnerId:customer.id,

                                recipientTitle:tempRecipientTitle,
                                recipientName:tempRecipientName,
                                recipientPhone:tempRecipientPhone,
                                recipientContact:tempRecipientContact,
                                recipientAddress:tempRecipientAddress,
                            }
                }

                let nextQueue=1

                let lastBill=null
                //console.log('2....')
                if(result.data.data[0]){
                    //console.log('result.data.data[0]')
                    lastBill=result.data.data[0]
                    const toDate=new Date().getDate()  
                    const lastBillDate=new Date(lastBill.date).getDate()
                         
                    if(lastBillDate==toDate){
                       nextQueue=lastBill.queue+1
                    }
                }
                //console.log('3')
                //=============================
                let tempEndBillArray=[]
                let tempEffectArray=[]
                //console.log('3.5............')
                //console.log(basicData.endBill)
                let tempEndBill
                let tempEffect

                let tempTransactionTypeId=0
                let tempTransactionType=""
                
                let tempUseInnerVat=false
                let tempInnerVatRate=0

                let tempPrintId=0
                let tempPrintPageName=""

                let isCheckUserLevel=true

                //console.log(transactionType)
                transactionType.data0.map(t=>{
                        if(isCheckUserLevel){

                            if(t.id==basicData.defaultTransactionTypeId){

                                if(t.userLevel.includes(user.userLevel)||user.userLevel=="admin"){

                                    //console.log('found You........')
                                    isCheckUserLevel=false

                                    tempEndBill=t.endBill
                                    tempEffect=t.effect
                                    
                                    tempTransactionTypeId=t.transactionTypeId
                                    tempTransactionType=t.transactionType

                                    tempUseInnerVat=t.useInnerVat
                                    tempInnerVatRate=t.innerVatRate

                                    tempPrintId=t.printId
                                    tempPrintPageName=t.printPageName

                                }
                            }
                        }
                })


                if(tempEndBill){
                    tempEndBill.map(eb=>{
                        if(eb.endBillActive){
                            tempEndBillArray=[ ...tempEndBillArray,
                                               {...eb,endBillAmount:0}
                                             ]
                        }
                    })
                }
               
                if(tempEffect){
                    tempEffect.map(eb=>{
                        tempEffectArray=[ ...tempEffectArray,
                                            {...eb,effectAmount:0}
                                        ]
                    })
                }

                //console.log('tempEndBillArray')
                //console.log(tempEndBillArray)
                
                //console.log('tempEffectArray......')
                //console.log(tempEffectArray)
            
                //============================
               
                //============================
                const toDate=new Date().toISOString()
                const tempObj=({...blankData,...customer,
                    id:genId(),
                    date:toDate,
                    remindDate:null,
                    queue:nextQueue,
                    queueStatus:"open",
                    branchId:user.branchId,
                    branchName:user.branchName,
                    //shopInfo:basicData.shopInfo,


                    ////table:tempTable,
                    ////tableStatus:"open",
                    ////branchId:basicData.branchId,
                    ////branchName:basicData.branchName,

                    //partnerId:customer.id,
                    //partnerType:customer.partnerType,
                    //title:customer.title,
                    //name:customer.name,
                    //phone:customer.phone,
                    //address:customer.address,
                    paymentType:basicData.paymentType[0],
                    cash:0,
                    change:0,
                    
                    transactionTypeId:tempTransactionTypeId,
                    transactionType:tempTransactionType,

                    useInnerVat:tempUseInnerVat,
                    innerVatRate:tempInnerVatRate,
                    
                    printId:tempPrintId,
                    printPageName:tempPrintPageName,

                    endBill:tempEndBillArray,
                    effect:tempEffectArray,
                    
                    detail:[],
                    reCal:true,
                    isGenIdOfBillForm:true
                  
                })

                //console.log("tempObj.....")
                //console.log(tempObj)
                setBillFormData(tempObj)
            }
            catch (error){
                //console.log('error......')
                //console.log(error)
            }         
            
            }}
        >
            <Ticon

                iconName="MdAddCircle" 
                className="md-icon" 
                textStyle={{color:"black"}}
            />
        </div>

        {billFormData&&
        <div className="iconbox"
            onClick={e=>{
                setShowEditBillForm(true)
            }}
        >
            <Ticon
                iconName="MdEdit" 
                className="md-icon" 
                textStyle={{color:"black"}}
            /> 
        </div>
        }
        {billFormData&&   
        <div className="iconbox"
            onClick={e=>{
                if(billFormData.isGenIdOfBillForm){
                    //console.log(`${billFormData.isGenIdOfBillForm} : add`)
                    submitFunctionAddFunc(billFormData)
                }
                else {
                    //console.log(`${billFormData.isGenIdOfBillForm} : edit`)
                    submitFunctionEditFunc(billFormData)
                }
            }}
        >
            {billFormData.isGenIdOfBillForm
            ?<Ticon
                iconName="MdSave" 
                className="md-icon"
                textStyle={{color:"black"}}    
            />
            :<Ticon
                iconName="VscSaveAs" 
                className="md-icon"
                textStyle={{color:"black"}}    
            />
            }
        </div>
        }

        {billFormData&&
        <div className="iconbox"
            onClick={e=>{
                submitFunctionDeleteFunc(billFormData)
            }}
        >
            <Ticon
                iconName="MdDelete" 
                className="md-icon"
                textStyle={{color:"black"}}
            />
        </div>
        }
        {billFormData&&
        <div className="iconbox"
            onClick={e=>{
                console.log('MdPrint.....')
                console.log(billFormData)
                
                setPrintDataFunc(null)

                setTimeout(() => {
                    setPrintDataFunc(billFormData)
                }, 400);
                
            }}
        >
            <Ticon
                iconName="MdPrint" 
                className="md-icon" 
                textStyle={{color:"black"}}
            /> 
        </div>
        }
         {billFormData&&
        <div className="iconbox"
            onClick={e=>{
                //console.log('MdPrint')
                setBillFormData({...billFormData,reCal:true})
            }}
        >
            <Ticon
                iconName="MdCalculate" 
                className="md-icon" 
                textStyle={{color:"black"}}
            /> 
        </div>
        }


            <div
                style={{               
                }}
            >
                <img
                    src={basicData.photoUrl1[0]} 
                    style={{
                        width:'2rem',
                        objectFit:"cover",
                        padding:"0.1rem"
                    }}
                />
            </div>






    </div>
)

}
//=================================


//-----------------------------------
const renderHeader=()=>{

    const {id,date,remindDate,branchId,branchName,transactionType,transactionStatus,active,
           table,tableStatus,partnerId,title,name,phone,address,taxId,
           
           queue,queueStatus,
           isGenIdOfBillForm,pic
    }=billFormData

    //<div style={{color:"blue"}}>{`${billForm.date}: ${findDateTime(date).date} ${billForm.time}: ${findDateTime(date).time}`}</div>

    return(
        <div className="h-100 w-100" 
             style={{padding:"0 0.5rem",
                     backgroundColor:"#f5e7a1"}}>
            <div className="flex-center-center jc-space-between">
                <div style={{display:"flex"}}>
                    {
                    isGenIdOfBillForm
                    ?<GrNew
                        style={{fontSize:"1.2rem",color:"blue"}}
                    />
                    :<MdEdit
                        style={{fontSize:"1.2rem",color:"blue"}}
                    />
                    }
                    <div style={{color:"red"}}>{`${transactionType} ${billForm.id}: ${id}`}</div>
                </div>

                <div style={{color:""}}>{`${branchId}: ${branchName}`}</div>

                <div style={{color:"blue"}}>{`${billForm.date}: ${findDateTime(date)}`}</div>
                
                {queue
                ?<div style={{color:"brown"}}>{`${billForm.queue}: ${queue}(${queueStatus})`}</div>
                :null
                }
                {
                 table
                 ?<div>{`${billForm.table}: ${table}(${tableStatus})`}</div>
                 :null
                }
                {
                 pic
                 ?<div>{`${billForm.pic}:${pic}`}</div>
                 :null   
                }

            </div>
            <div className="flex-center-center jc-space-between">
                <div>{`${billForm.partnerId}:${partnerId}`}</div>
                <div>{`${billForm.taxId}:${taxId}`}</div>
                <div>{`${title} ${name}`}</div>
                {
                    //<div>{`${billForm.phone}: ${genArrayToString(phone)}`}</div>
                }
                <div>{`${billForm.phone}: ${phone}`}</div>

                <div>{`${billForm.address}: ${address}`}</div>

            </div>

        </div>
    )
}

//------------------------------
//------------------------------
const renderTable=()=>{

    return showTableInBillForm
    ?<div className='h-100 w-100 hide-one-print'>
        
        <Table
            colorHead={"#ac7c42"}
            tableTemplate={formTableTemplate}
            setTableTemplate={setTableTemplateFunc}

            filterData={billFormData.detail}
            setFilterData={setFilterDataFunc}//{setFilterDataData0}
            
            editData={editData}
            setEditData={setEditDataFunc}//{updateEditData}
            saveTableTemplateFunc={saveTableTemplateFunc}
            isSubTable={false}
            useInput={true}
            updateFilterData={updateFilterData}//{updateEditData}

            basicData={basicData}

            findProductIdByKeyDown={findProductIdByKeyDown}
            findBarcodeByKeyDown={findBarcodeByKeyDown}
            calDigit={calDigit}

            buttonLastRef={buttonLastRef}
            useInnerVat={billFormData.useInnerVat}
            innerVatRate={billFormData.innerVatRate}
        />
        
        
        
    </div>
    :<div className='h-100 w-100 hide-one-print'>
        {
        renderGrid({ data0:billFormData.detail,
                     tableTemplate:formTableTemplate,
                     editData:editData
                    },
                    formTableTemplate,
                    setEditDataFunc,
                    updateFilterDataForGrid,
                    true,
                    basicData,
                    showTag,
                    setShowTag,
                    billFormData.useInnerVat,
                    billFormData.innerVatRate,
                    findProductIdByKeyDown,
                    findBarcodeByKeyDown
                )
        }
    </div>
        
    
    
}
const styleRight={
  position:"fixed",
  top:"3rem",right:"0.5rem",zIndex:"100",
  width:"",backgroundColor:"white",
  borderRadius:"10px",margin:""
}
const styleLeft={position:"fixed",
  top:"3rem",Left:"0.5rem",zIndex:"100",
  width:"",backgroundColor:"white",
  borderRadius:"10px",margin:""
}

const mystyle={
    
    //width:"3rem",//5%
    minWidth:"2.5rem",
    zIndex:"100",
    backgroundColor:"#dbac50"//"#f5e7a1"
    //background:"rgb(250,226,165)",
    //background: "linear-gradient(183deg, rgba(250,226,165,1) 0%, rgba(236,193,61,1) 100%)"
}

const [swapStyleRight,setSwapStyleRight]=React.useState(true)

const renderToolBox=()=>{
    return(
    <div //style={swapStyleRight?styleRight:styleLeft}
             style={mystyle}
    >   

        <div className="iconbox2"
            onClick={e=>{
                setShowTableInBillForm(!showTableInBillForm)
            }}
        >
            <MdSwapHoriz
                style={{fontSize:"2rem"}}
            />

        </div>

        <div className="iconbox2"
            onClick={e=>{
                if(footerHeight<100){

                    if(footerHeight<90){
                        setFooterHeight(footerHeight+10)
                    }
                    else{
                        setFooterHeight(100)
                    }
                }
            }}
        >
            <RiSplitCellsVertical
                style={{fontSize:"2rem"}}
            />
        </div>


        <div className="iconbox2"
            onClick={e=>{
                if(footerHeight>0){
                    if(footerHeight<10){
                        setFooterHeight(0)
                    }
                    else{
                        setFooterHeight(footerHeight-10)
                    }

                }
            }}
        >
            <RiMergeCellsVertical
                style={{fontSize:"2rem"}}
            />
        </div>
        
   




        <div className='w-100 h-100'
             style={{}}
        >

            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    deleteLine()
                }}
            >
                <Ticon
                    iconName="MdDelete" 
                    className="md-icon"
                    textStyle={{color:"black"}} 
                />
            </div>
            }


            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    insertLine()
                }}
            >
                <Ticon
                    iconName="MdAddCircle" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>

            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    selectAll()
                }}
            >
                <Ticon
                    iconName="MdRadioButtonChecked" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }

            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    unSelectAll()
                }}
            >
                <Ticon
                    iconName="FaBullseye" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }

            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    //unSelectAll()
                    selectSameGroup()
                }}
            >
                <Ticon
                    iconName="FaRegFolderOpen" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>    
            }

            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    moveUp()
                }}
            >
                <Ticon
                    iconName="FaRegArrowAltCircleUp" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }

            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                onClick={e=>{
                    moveDown()
                }}
            >
                <Ticon
                    iconName="FaRegArrowAltCircleDown" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
           
            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    mergeUp()
                    //setSwapStyleRight(!swapStyleRight)
                 }}
            >
                <Ticon 
                    iconName="MdMergeType" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }

            {editData
            ?editData.hasDetailProduct&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    rawUp()
                    //setSwapStyleRight(!swapStyleRight)
                 }}
            >
                <Ticon
                    iconName="MdRawOn" 
                    className="md-icon"
                    textStyle={{color:"black"}} 
                />
            </div>
            :null
            }
            
            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    updatePrice()
                    //setSwapStyleRight(!swapStyleRight)
                 }}
            >
                <Ticon 
                    iconName="FaTags" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }

            {editData&&
            <div className="iconbox2"
                 style={{height:"2.5rem"}}
                 onClick={e=>{
                    setEditData(null)
                    updateData(billFormData)
                 }}
            >    
                <Ticon
                    iconName="MdClose"
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
        
        </div>

    
    </div>
    )
}

const renderFooter=()=>{

  const {total,totalReduction,reduction,totalTax,tax,grandTotal,
           paymentType,endBill,remark,photoUrl1
  }=billFormData
 
  const checkPhotoUrl=()=>{
        let temp=false

        if(photoUrl1){
            if(photoUrl1.length>0){
                if(photoUrl1[0]){
                    temp=true
                }
            }
        }

        return temp
  }
 
 
 return(
     <div className="h-100 w-100 flex-center-center jc-start" 
          style={{padding:"0 1.2rem",
                  backgroundColor:"#f5e7a1",
                  alignItems:"flex-start",
                  }}>
            

            <div  className={checkPhotoUrl()?"xc4 mc6 sc12":"xc6 mc6 sc12"} 
                  style={{textAlign:"end"}}
            >
                <div style={{display:"flex",justifyContent:"flex-start"}}>
                    <div style={{padding:"0 0.5rem",backgroundColor:"#8cc5bb"}}>
                    {`${billForm.total} = ${numberWithCommas(total,calDigit,"total")}`}
                    </div>
                </div>

                {
                    endBill.map((y,idxY)=>{

                        return(
                        <div key={idxY} 
                            style={{display:"flex",justifyContent:"flex-start"}}>
                            <div style={{padding:"0 0.5rem",backgroundColor:""}}>
                            {
                                `${y.endBillName} = ${numberWithCommas(y.endBillAmount,calDigit)}`
                            }
                           
                            </div>
                        </div>
                        )
                    })
                }

                <div style={{display:"flex",justifyContent:"flex-start"}}>
                    <div style={{padding:"0 0.5rem",backgroundColor:"#a7c6eb",fontSize:"1.4rem"}}>
                        {`${billForm.grandTotal} = ${numberWithCommas(grandTotal,calDigit,"grandTotal")}`}
                    </div>
                </div>
            </div>
            
            <div className={checkPhotoUrl()?"xc4 mc6 sc12":"xc6 mc6 sc12"} 
            >
                {remark}
            </div>

            {checkPhotoUrl()&&
            <div className="xc4 mc6 sc12"
                 style={{height:"100%"}}
            >
                <GalleryoneHeight imgarrs={billFormData.photoUrl1}/>            
            </div>
            }
     </div>
 )

}

//------------------------------
const renderForm=()=>{
    return (
        <div className="w-100 h-100">
            
            <div className="w-100 h-5 ">
                {
                 renderButton()
                }

            </div>


            <div className="w-100 h-10 "
                 style={{overflowY:"auto",
                 backgroundColor:"#f5e7a1"
                }}
            >
                {
                billFormData&&
                    renderHeader()
                }

            </div>


            <div className="w-100 h-85"
                 style={{display:"flex"}}
            >

                <div className='h-100'
                     style={{width:"2.5rem",
                             backgroundColor:"#dbac50"
                           }}
                >
                        {
                        billFormData&&formTableTemplate&&
                            renderToolBox()   
                        }
                </div>

                <div className='h-100'
                     style={{width:"95%"}}
                >
                        <div className="w-100 h-75"
                                style={{
                                    display:"flex",
                                    height:findTableHeight()
                                }}
                            >
                            {
                            billFormData&&formTableTemplate&&
                               renderTable()  
                            }
                        </div>
                        <div className="w-100"
                            style={{overflowY:"auto",
                                    backgroundColor:"#f5e7a1",
                                    height:findFooterHeight(),
                                }}
                        >
                            {
                            billFormData&&
                                renderFooter()
                            }
                        </div>

                </div>

                    {
                    /*
                    <div className="w-100 h-65"
                        style={{
                            display:"flex",
                        }}
                    >
                        {
                        billFormData&&formTableTemplate&&
                            renderToolBox()   
                        }
                        {
                        billFormData&&formTableTemplate&&
                            renderTable()
                        }
                        
                    </div>
                    
                    <div className="w-100 h-20"
                        style={{overflowY:"auto",
                        backgroundColor:"#f5e7a1"
                        }}
                        >
                        {
                        billFormData&&
                            renderFooter()
                        }
                    </div>
                    */
                    }
            </div>
        </div>
    )
}


return(
<div className="bgc-lightGray app-container h-100 w-100" >

    {
     renderForm()
    }
    {
     showEditBillForm&&basicData
     ?<EditBillForm
         billFormData={billFormData}
         setBillFormData={setBillFormData}
         basicData={basicData}
         submitFunc={editBillFormSubmitFunc}
         cancelFunc={editBillFormCancelFunc}
         calDigit={calDigit}
         pageData={pageData}
         user={user}
         myheader={myheader}
         calEndBill={calEndBill}
         transactionType={transactionType}
         printPage={printPage}
         //runIsGenId={runIsGenId}
      />
     :null
    }
   
</div>

)

}

BillForm.defaultProps={
    calDigit:100,
    bgColor:null,
    buttonLastRef:null
}
  

export default BillForm;



import React from 'react';
import {MainContext} from '../../context/MainContext'
import BackEndNavbar from '../../component/navbar/BackEndNavbar';

import PageComponent  from '../../component/pageComponent/PageComponent';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'

import console from '../../util/consoleVerify'


function Shop() {

//console.log('Shop')

const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
       mainContextShop,setMainContextShop
}=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
<div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
    <div className="h-5 w-100">
        <BackEndNavbar/>
    </div>
    <div className="h-95 w-100">
        <PageComponent
            setReloadBasicData={setReloadBasicData}
            filterDataTemplate={filterDataTemplate}
            filterTemplate={basicDataSt.pageFilter.shopFilter}
            inputState={inputState.shopInputState}
            basicDataSt={basicDataSt}
            myheader={myheader}
            
            formTemplate={basicDataSt.modalFormTemplate.shopForm}
            editFormTemplate={basicDataSt.modalFormTemplate.shopForm}
            
            stateTemplate={StateTemplate.shopState}
            pageFilterForm={basicDataSt.pageFilterForm.shopFilterForm}


            modalFormTemplate={basicDataSt.pageData.ModalForm}
            addFormTitle={basicDataSt.pageData.ModalForm.shopAddForm}
            editFormTitle={basicDataSt.pageData.ModalForm.shopEditForm}

            bgColor={"#FF6F61"}
            calDigit={100}
            controller={"getlimitbyadmin"}
            mainContextData={mainContextShop}
            setMainContextData={setMainContextShop}

        />
    </div>

</div>
)

}
export default Shop;

/*
import React from 'react';
import {MainContext} from '../../context/MainContext'
import PageComponent  from '../../component/pageComponent/PageComponent';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import Navbar from '../../component/navbar/Navbar';
import console from '../../util/consoleVerify'


function Partner() {

//console.log('Partner')

const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData}=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
<div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
    <div className="h-5 w-100">
        <Navbar/>
    </div>
   
    <div className="h-95 w-100">
        {basicDataSt.basicData&&myheader&&
        <PageComponent
            setReloadBasicData={setReloadBasicData}
            filterDataTemplate={filterDataTemplate}
            filterTemplate={basicDataSt.pageFilter.partnerFilter}
            inputState={inputState.partnerInputState}
            basicDataSt={basicDataSt}
            myheader={myheader}
            
            formTemplate={basicDataSt.modalFormTemplate.partnerForm}
            editFormTemplate={basicDataSt.modalFormTemplate.partnerEditForm}
            
            stateTemplate={StateTemplate.partnerState}
            pageFilterForm={basicDataSt.pageFilterForm.partnerFilterForm}

            modalFormTemplate={basicDataSt.pageData.ModalForm}
            addFormTitle={basicDataSt.pageData.ModalForm.partnerAddForm}
            editFormTitle={basicDataSt.pageData.ModalForm.partnerEditForm}
            bgColor={"#74b979"}
            calDigit={100}
        />
        }
    </div>
</div>
)

}
export default Partner;

*/
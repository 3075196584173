
import React from 'react';
import uuid from 'react-uuid';
import axios from '../../util/axiosVerify';

import {FaPlusSquare,FaMinusSquare,FaCheck,FaBan,FaCopy,FaArrowCircleUp,
        FaArrowAltCircleDown,FaArrowAltCircleUp
} from 'react-icons/fa'; 
import {MdEdit} from 'react-icons/md'; 
import TransactionTypeSetting from './TransactionTypeSetting';
import console from '../../util/consoleVerify'

import axiosUtil from '../../util/axiosUtil'

const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,
    catchErrorToMessage}=axiosUtil

function TransactionSetting(props) {

const {submitFunc,cancelFunc,basicData,pageData,
        tableTemplate,saveTableTemplateWithTableNameFunc,
        myheader
    }=props

//console.log('TransactionSetting')
//console.log(basicData.transaction)

const refSubmitForm=React.createRef() //ตกลง
const refCancelForm=React.createRef() //ตกลง


const genEditColor=(i)=>{
    let temp="black"

    
    if(editData){
        if(editData.id){
            if(editData.id==i.id){
                temp="red"
            }
        }
    }
    
    return temp
}

const genEditColor2=(i)=>{
    let temp="black"

    
    if(editAdminTransactionType){
        if(editAdminTransactionType.id){
            if(editAdminTransactionType.id==i.id){
                temp="green"
            }
        }
    }
    
    return temp
}






const genTransactionTypeArray=(array)=>{
    let tempArray=[]
    array.map(i=>{
        tempArray=[...tempArray,i.transactionType]
    })

    //console.log('genTransactionTypeArray')
    //console.log(tempArray)

    return tempArray
}


const getRefArray=(array)=>{
    let tempRefArray=[]

    array.map(k=>{
        tempRefArray=[...tempRefArray,React.createRef()]
    })

    return tempRefArray
}

const checkArrayEmpty=(array)=>{
    if(array.length==0){
        return [""]
    }
    else {
        return array
    }
}

//==============================
//==============================

const blankData={
    transactionType:"",
    useInnerVat:false,
    innerVatRate:0,
    effect:[],
    endBill:[],
    userLevel:["admin"],
    printId:1,
    printPageName:""
}

const genTransaction=(array)=>{
    let tempArray=[]
    array.map(i=>{
        const {_id,...remaining}=i

        let tempEndBill=[]
        i.endBill.map(j=>{
            const {_id,...remaining2}=j
            tempEndBill=[...tempEndBill,remaining2]
        })

        let tempEffect=[]
        i.effect.map(k=>{
            const {_id,...remaining3}=k
            tempEffect=[...tempEffect,remaining3]
        })

        const tempObj={...remaining,endBill:tempEndBill,effect:tempEffect}
        tempArray=[...tempArray,tempObj]
    })

    return tempArray
}

let [transaction,setTransaction]=React.useState(basicData.transaction)




let [formInputState,setFormInputState]=
    React.useState(checkArrayEmpty(genTransactionTypeArray(basicData.transaction)))

let [refOfInputState,setRefOfInputState]=React.useState(getRefArray(formInputState))

const [editData,setEditData]=React.useState(null)


const [showTransactionTypeSetting,setShowTransactionTypeSetting]=React.useState(false)
const [editTransactionType,setEditTransactionType]=React.useState(null)
const [editTransactionIdx,setEditTransactionIdx]=React.useState(null)


const [adminTransactionType,setAdminTransactionType]=React.useState([])
const [editAdminTransactionType,setEditAdminTransactionType]=React.useState(null)
const [showTransactionTypeSetting2,setShowTransactionTypeSetting2]=React.useState(false)


const [printPage,setPrintPage]=React.useState({
                                data0:[],
                                count:0,
                                lastRecordId:0
                              })

const [transactionType,setTransactionType]=React.useState({
                            data0:[],
                            count:0,
                            lastRecordId:0,
                            reloadData:true,
                            message:"",
                            showModalConfirm:false,
                            showModalError:false,
                        })

const shouldRender= React.useRef(true)
                       
React.useEffect(()=>{


    axios.post(`/p35printpage/getlimit`,
                    {pageNumber:1,limitRow:50,sort:{id:1}},
                    myheader,shouldRender)

    .then(result=>{

            let tempArray=[]

            if(result.data.data){
                result.data.data.map(i=>{
                    tempArray=[...tempArray,{...i,printId:i.id}]
                })
            }
            
            setPrintPage({
                data0:tempArray,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
            })
            
    })

    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])


React.useEffect(()=>{

    if(transactionType.reloadData){


        let result1

        axios.post(`/p35transactiontype/getlimit`,
                {pageNumber:1,
                limitRow:100,
                sort:{id:1},
                adminShopId:process.env.REACT_APP_ADMINSHOP
                },
                myheader,shouldRender)

        .then(result=>{

                //console.log('result')
                //console.log(result.data.data)


                let tempArray=[]
                if(result){
                    result.data.data.map(k=>{
                        tempArray=[...tempArray,{...k,id:uuid()}]
                    })
                }
                result1=tempArray

                return axios.post(`/p35transactiontype/getlimit`,
                        {
                            pageNumber:1,
                            limitRow:100,
                            sort:{id:1},
                        },
                        myheader,shouldRender)

        })

        .then(result=>{
            //console.log('result......')
            //console.log(result.data)
            //const temp2= genFilterDataWithIndex(result.data.data)
            setAdminTransactionType(result1)

            const tempResult={...transactionType,
                data0:result.data.data,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                //isCsv:false,
                //isXlsx:false,
                reloadData:false,   
            }
            setTransactionType(tempResult)
        })
        .catch(error=>{
            const tempError={...transactionType,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setTransactionType(tempError)
        })
    }


},[transactionType])



const genTransactionAfterEdit=(data)=>{
    //console.log('genTransactionAfterEdit')

    let tempArrayTransaction=[]
    let tempArrayFormInputState=[]

    transaction.map((j,idxJ)=>{
        if(idxJ==editTransactionIdx){
            tempArrayTransaction=[...tempArrayTransaction,data]
            tempArrayFormInputState=[...tempArrayFormInputState,
                data.transactionType
            ]
        }
        else{
            tempArrayTransaction=[...tempArrayTransaction,j]

            tempArrayFormInputState=[...tempArrayFormInputState,
                j.transactionType
            ]
        }

    })

    setFormInputState(tempArrayFormInputState)
    setTransaction(tempArrayTransaction)
    //submitFunc(tempArray)
}



//===========================


const addTransactionType=()=>{

    const {_id,...tempObj}=editData

    const tempObj2={
        ...tempObj,
        id:transactionType.lastRecordId+1,
        transactionType:editData.transactionType+'-copy'
    }


    axios.post(`/p35transactiontype/addcustom`,
    tempObj2,myheader,shouldRender)
    .then(result=>{
        //console.log('pass update')
       
        setTransactionType({
            ...transactionType,
            reloadData:true,
            showModalConfirm:false
        })
        
    })
    .catch(error=>{
        const tempError = {...transactionType,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setTransactionType(tempError)
    })
    
}


const addTransactionTypeFromAdmin=()=>{
    
    const {_id,createdAt,updatedAt,
        shopId,effect,endBill,...tempObj}=editAdminTransactionType

    let tempArray1=[]
    effect.map(j=>{
        const {_id,...rmn}=j
        tempArray1=[...tempArray1,rmn]
    })


    let tempArray2=[]
    endBill.map(j=>{
        const {_id,...rmn2}=j
        tempArray2=[...tempArray2,rmn2]
    })


    const tempObj2={
        ...tempObj,
        shopId:basicData.shopId,
        id:transactionType.lastRecordId+1,
        transactionType:editAdminTransactionType.transactionType+'-copy_from_admin',
        effect:tempArray1,
        endBill:tempArray2

    }

    axios.post(`/p35transactiontype/addcustom`,
    tempObj2,myheader,shouldRender)
    .then(result=>{
       
        setTransactionType({
            ...transactionType,
            reloadData:true,
            showModalConfirm:false
        })
        
    })
    .catch(error=>{
        console.log('error')
        console.log(error)

        const tempError = {...transactionType,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setTransactionType(tempError)
    })
    
}



const deleteTransactionType=()=>{

    if(editData.id>2){
        axios.post(`/p35transactiontype/deletecustom`,
            {id:editData.id},myheader,shouldRender)
            .then(result=>{
                //console.log('pass update')
                setTransactionType({
                    ...transactionType,
                    reloadData:true,
                    showModalConfirm:false
                })
                
            })
            .catch(error=>{
                const tempError = {...transactionType,
                    reloadData:false,
                    message:catchErrorToMessage(error),
                    showModalConfirm:false,
                    showModalError:true,
                }
                setTransactionType(tempError)
            })
    }

}


const updateTransactionType=(i)=>{
    const {_id,...tempObj}=i
    //console.log('updatePrintPage....')
    let tempArray1=[]
    if(i.effect){
        i.effect.map(j=>{
            const {_id,...rmn1}=j
            tempArray1=[...tempArray1,rmn1]
        })
    }
    let tempArray2=[]
    if(i.endBill){
        i.endBill.map(k=>{
            const {_id,...rmn2}=k
            tempArray2=[...tempArray2,rmn2]
        })
    }

    const tempObj2={...tempObj,
        effect:tempArray1,
        endBill:tempArray2
    }

    axios.post(`/p35transactiontype/updatecustom`,
                tempObj2,myheader,shouldRender)
    .then(result=>{
        //console.log('pass update')
       
        setTransactionType({
            ...transactionType,
            reloadData:true,
            editData:null
        })
        setEditData(tempObj)
        setShowTransactionTypeSetting(false)

    })
    .catch(error=>{
        const tempError = {...transactionType,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setTransactionType(tempError)
    })

}


//===========================
const renderTransactionTypeOfAdminShop=()=>{

    return (
        <div className='w-100 bd-pureWhite'>
            <div className="w-100">
                <div className="flex-center-center">
                    <h5>{`${pageData.setting.dataFromAdminTeam}`}</h5>   
                </div>     
            </div>


            <div className='w-100' style={{display:"flex"}}>
                <div  style={{
                    marginRight:"1rem",
                    visibility:editAdminTransactionType?"visible":"hidden"

                }}>
                    <FaArrowCircleUp
                        className='md-icon'
                        onClick={e=>{
                            //setShowPrintPageForm(true)
                            addTransactionTypeFromAdmin()
                        }}
                    />
                </div>
                <div  style={{
                    marginRight:"1rem",
                    visibility:editAdminTransactionType?"visible":"hidden"

                }}>
                    <MdEdit
                        className='md-icon'
                        onClick={e=>{
                            setShowTransactionTypeSetting2(true)
                            //setShowStickerPage2(true)
                            //setShowStickerPage2(true)
                        }}
                    />
                </div>        


            </div>

            <div className="w-100">
                {
                adminTransactionType.map((i,idxI)=>{
                        return(
                            <div key={idxI}
                                style={{
                                        color:genEditColor2(i),
                                        marginBottom:"0.5rem"
                                    }}
                                onClick={e=>{
                                    setEditAdminTransactionType({
                                        ...i
                                    })
                                    setEditData(null)
                                }}
                            >
                                {`${idxI+1}.${i.transactionType}`}
                            </div>

                        )
                    })
                }
            </div>

        </div>
    )

}
//===========================
const renderFooter=()=>{
    return(
        <div className="Modal-footer">
              
               <div>
                   <button
                       onClick={e=>{
                          cancelFunc()
                          //setShowToolBox(false)
                       }}
                   >
                       <FaBan/>
                   </button>
               </div>
           </div>
    )
}
//-----------------------------
//================================
const renderToolBar=()=>{

    return(
        <div 
            className='w-100'
            style={{display:"flex",marginBottom:"1rem"}}
        >
            <div style={{
                    marginRight:"1rem",
                    visibility:editData?"visible":"hidden"
                    }}
                onClick={e=>{
                    addTransactionType()
                }}        
            >
                <FaPlusSquare
                    className='md-icon'
                />
            </div>

            <div  style={{
                marginRight:"1rem",
                visibility:editData?"visible":"hidden"
            }}>
                <FaMinusSquare
                    className='md-icon'
                    onClick={e=>{
                        deleteTransactionType()
                    }}
                />
            </div>

            <div  style={{
                marginRight:"1rem",
                visibility:editData?"visible":"hidden"

            }}>
                <MdEdit
                    className='md-icon'
                    onClick={e=>{
                        setShowTransactionTypeSetting(true)
                    }}
                />
            </div>

        </div>
    )

}   

//-----------------------------
const renderBody=()=>{

    return (
     <div 
         className="w-100 flex-center-start jc-start" 
         style={{marginBottom:"0.2rem"}}>
         
         {
            renderToolBar()
        }

         
         <div className="w-100">
            {
            transactionType.data0.map((i,idx)=>{
                return(
                    <div key={idx}
                        style={{
                                color:genEditColor(i),
                                marginBottom:"0.5rem"
                               }}
                        onClick={e=>{
                            setEditData(i)

                            setEditAdminTransactionType(null)
                        }}
                    >
                        {`${i.id}.${i.transactionType}`}
                    </div>

                )
            })
        }
         </div>
 
     </div>
 )}
 



return (
     <div className="Modal-background">
       <div className="Modal-box" 
            style={{
                    //width:"100%",
                    backgroundColor:"white"
                    }}>
            <div className="Modal-header">
                  <h3>{pageData.transactionForm.formHead}</h3>
            </div>
            <div className="Modal-body" >
                <div className="ModalInsideBody">
                 {                
                  renderBody()
                 }
                </div>
            </div>


            {
              renderTransactionTypeOfAdminShop() 
            }



            {
              renderFooter()
            }
        </div>
        {showTransactionTypeSetting||showTransactionTypeSetting2
        ?showTransactionTypeSetting
            ?<TransactionTypeSetting
                submitFunc={updateTransactionType}
                cancelFunc={()=>setShowTransactionTypeSetting(false)}
                basicData={{...basicData,printPage:printPage.data0}}
                pageData={pageData}
                tableTemplate={tableTemplate}
                editTransactionType={editData}
                saveTableTemplateWithTableNameFunc={saveTableTemplateWithTableNameFunc}
            />
            :<TransactionTypeSetting
                submitFunc={null}
                cancelFunc={()=>setShowTransactionTypeSetting2(false)}
                basicData={{...basicData,printPage:printPage.data0}}
                pageData={pageData}
                tableTemplate={tableTemplate}
                editTransactionType={editAdminTransactionType}
                saveTableTemplateWithTableNameFunc={saveTableTemplateWithTableNameFunc}
            />
        :null
        }
    </div>
)


}
export default TransactionSetting;







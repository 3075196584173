import React from 'react';

const genRef=()=>{
    return [1,2,3].map(i=>React.createRef())
}

const cName="xc12 h-100"

const subCNameMinMax = ["xc1 h-100",
                        "xc3 h-100",
                        "xc8 h-100"]
  
const subCNameInput= subCNameMinMax

let partnerFilter=[
    //active
    //0
    { 
      templateType:"boolean",
      lb:"active",
      cName,  
      subCName:subCNameInput,    
      keyName:"active",
      refInput:genRef(),
      inputType:"checkbox",
      filterCommand:["active"]
    },
    
    //isCustomer
    //1
    { 
      templateType:"boolean",
      lb:"Is Customer",
      cName,  
      subCName:subCNameInput,    
      keyName:"isCustomer",
      refInput:genRef(),
      inputType:"checkbox",
      filterCommand:["isCustomer"]
    },
    
     //isSupplier
     //2
     { 
      templateType:"boolean",
      lb:"Is Supplier",
      cName,  
      subCName:subCNameInput,    
      keyName:"isSupplier",
      refInput:genRef(),
      inputType:"checkbox",
      filterCommand:["isSupplier"]
    },
    //3
    { 
      templateType:"number",
      lb:"id",
      cName,  
      subCName:subCNameMinMax,
      keyName:"id",
      refInput:genRef(),
      inputType:"number",
      filterCommand:["id"]
    },
    //taxId
    //4
    { 
      templateType:"string",
      lb:"Tax ID",
      cName,  
      subCName:subCNameInput,
      keyName:"taxId",
      refInput:genRef(),
      inputType:"text",
      filterCommand:["taxId"]
    },
    //==============
    //title
    //5
    { 
      templateType:"selectObj",
      lb:"คำนำหน้า",
      cName,  
      subCName:subCNameInput,    
      keyName:"title",
      refInput:genRef(),
      inputType:"text",
      filterCommand:["title"],
      selectObj:"title"
    },
    //name
    //6
    { 
      templateType:"string",
      lb:"ชื่อ",
      cName,  
      subCName:subCNameInput,    
      keyName:"name",
      refInput:genRef(),
      inputType:"text",
      filterCommand:["name"]
    },
    //phone
    //7
    { 
      templateType:"string",
      lb:"โทรศัพท์",
      cName,  
      subCName:subCNameInput,      
      keyName:"phone",
      refInput:genRef(),
      inputType:"text",
      filterCommand:["phone"]
    },
  
    //remark
    //8
    { 
      templateType:"string",
      lb:"หมายเหตุ",
      cName,  
      subCName:subCNameInput,    
      keyName:"remark",
      refInput:genRef(),
      inputType:"text",
      filterCommand:["remark"]
    },
    //9
    { 
      templateType:"number",
      lb:"แต้ม",
      cName,  
      subCName:subCNameMinMax,
      keyName:"remainingCustomerPoint",
      refInput:genRef(),
      inputType:"number",
      filterCommand:["remainingCustomerPoint"]
    },
    //10
    { 
      templateType:"number",
      lb:"ยอดใช้จ่าย",
      cName,  
      subCName:subCNameMinMax,
      keyName:"totalSpending",
      refInput:genRef(),
      inputType:"number",
      filterCommand:["totalSpending"]
    },
    { 
      templateType:"number",
      lb:"updatedAt",
      cName,  
      subCName:subCNameMinMax,
      keyName:"updatedAt",
      refInput:genRef(),
      inputType:"dateUseTimeSetting",
      filterCommand:["updatedAt"]
    },
]


let customerFilter=[
  //active
  partnerFilter[0],
  //partnerFilter[1],
  //partnerFilter[2],
  partnerFilter[3],
  partnerFilter[4],
  partnerFilter[5],
  partnerFilter[6],
  partnerFilter[7],
  partnerFilter[8],
  partnerFilter[9],
  partnerFilter[10],
  { 
    templateType:"number",
    lb:"updatedAt",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"dateUseTimeSetting",
    filterCommand:["updatedAt"]
  },
]

const groupFilter=[
 
  //id 1
  { 
    templateType:"number",
    lb:"ไอดี",
    cName,  
    subCName:subCNameMinMax,
    keyName:"id",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["id"]
  },
 //name 4
 { 
  templateType:"string",
  lb:"ชื่อ",
  cName,  
  subCName:subCNameInput,    
  keyName:"groupName",
  refInput:genRef(),
  inputType:"text",
  filterCommand:["groupName"]
 },
//id 1
{ 
  templateType:"number",
  lb:"ไอดี",
  cName,  
  subCName:subCNameMinMax,
  keyName:"level",
  refInput:genRef(),
  inputType:"number",
  filterCommand:["level"]
},

]

let productFilter=[
  //active 0
  { 
    templateType:"boolean",
    lb:"active",
    cName,  
    subCName:subCNameInput,    
    keyName:"active",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["active"]
  }, 
  //id 1
  { 
    templateType:"number",
    lb:"ไอดี",
    cName,  
    subCName:subCNameMinMax,
    keyName:"id",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["id"]
  },
  //barcode 2
  { 
    templateType:"string",
    lb:"บาร์โค้ด",
    cName,  
    subCName:subCNameInput,    
    keyName:"barcode",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["barcode"]
  },
  //3
  { 
    templateType:"string",
    lb:"sn",
    cName,  
    subCName:subCNameInput,    
    keyName:"sn",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["sn"]
  },
  //name 4
  { 
    templateType:"string",
    lb:"ชื่อ",
    cName,  
    subCName:subCNameInput,    
    keyName:"productName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["productName"]
  },
  //groupId 5
  { 
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"groupId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["groupId"]
  },
  //groupName 6
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"groupName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["groupName"]
  },
  //isRawMat 7
  { 
    templateType:"boolean",
    lb:"เป็นวัตถุดิบ",
    cName,  
    subCName:subCNameInput,    
    keyName:"isRawMat",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["isRawMat"]
  },
 
  //price 8
  { 
    templateType:"number",
    lb:"ราคา",
    cName,  
    subCName:subCNameInput,    
    keyName:"price",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["price"]
  },
  //=================
 
  //stock 9
  { 
    templateType:"stocking-arrayObjectNumber",
    lb:"ยอดสต็อค",
    cName,  
    subCName:subCNameMinMax,
    keyName:"stocking_stock",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["stocking","stock"]
  },
  //storage 10
  { 
    templateType:"stocking-arrayObjectString",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"stocking_storage",
    refInput:genRef(),
    inputType:"text",
    selectObj:"storage",
    filterCommand:["stocking","storage"]
  },
  
  //remark 11
    { 
      templateType:"string",
      lb:"หมายเหตุ",
      cName,  
      subCName:subCNameInput,    
      keyName:"remark",
      refInput:genRef(),
      inputType:"text",
      filterCommand:["remark"]
    },
    { 
      templateType:"number",
      lb:"updatedAt",
      cName,  
      subCName:subCNameMinMax,
      keyName:"updatedAt",
      refInput:genRef(),
      inputType:"dateUseTimeSetting",
      filterCommand:["updatedAt"]
    },
]


const productBranchFilter=[
  productFilter[0],//active
  productFilter[1],//id
  productFilter[2],//barcode
  productFilter[3],//sn
  productFilter[4],//productName
  productFilter[5],//groupId
  productFilter[6],//groupName
  productFilter[7],//isRawMat
  productFilter[8],//price
  { 
    templateType:"arrayObjectNumber",
    lb:"ยอดสต็อค",
    cName,  
    subCName:subCNameMinMax,
    keyName:"stocking_stock",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["stocking","stock"]
  },
  
  { 
    templateType:"arrayObjectString",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"stocking_storage",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["stocking","storage"]
  },
  
  { 
    templateType:"string",
    lb:"หมายเหตุ",
    cName,  
    subCName:subCNameInput,    
    keyName:"remark",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["remark"]
  },

]

//>............
//>>>>>>>>>>>>>>

let transactionFilter=[
    //active 0
    { 
      templateType:"boolean",
      lb:"active",
      cName,  
      subCName:subCNameInput,    
      keyName:"active",
      refInput:genRef(),
      inputType:"checkbox",
      filterCommand:["active"]
    },
  //id 1
  { 
    templateType:"number",
    lb:"id",
    cName,  
    subCName:subCNameMinMax,
    keyName:"id",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["id"]
  },
  //date 2
  { 
    templateType:"number",
    lb:"วันที่",
    cName,  
    subCName:subCNameMinMax,
    keyName:"date",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["date"]
  },
  //3
  { 
    templateType:"number",
    lb:"วันที่",
    cName,  
    subCName:subCNameMinMax,
    keyName:"remindDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["remindDate"]
  },
  //4
  { 
    templateType:"transactionType",//"selectArrayObj",
    lb:"ประเภทเอกสาร",
    cName,  
    subCName:subCNameInput,    
    keyName:"transactionType",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["transactionType"],
    selectArrayObj:["transaction","transactionType"]
  },
  //5
  { 
    templateType:"selectObj",
    lb:"สถานะเอกสาร",
    cName,  
    subCName:subCNameInput,    
    keyName:"transactionStatus",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["transactionStatus"],
    selectObj:"transactionStatus"
  },


 //6
  { 
    templateType:"number",
    lb:"ไอดีคู่ค้า",
    cName,  
    subCName:subCNameMinMax,
    keyName:"partnerId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["partnerId"]
  },
  //7
  { 
    templateType:"selectObj",
    lb:"คำนำหน้า",
    cName,  
    subCName:subCNameInput,    
    keyName:"title",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["title"],
    selectObj:"title"
  },

  //7+1=8
  //name 
  { 
    templateType:"string",
    lb:"ชื่อ",
    cName,  
    subCName:subCNameInput,    
    keyName:"name",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["name"]
  },
  //8+1=9
  //phone
  { 
    templateType:"string",
    lb:"โทรศัพท์",
    cName,  
    subCName:subCNameInput,      
    keyName:"phone",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["phone"]
  },
   //9+1=10
  { 
    templateType:"selectObj",
    lb:"สถานะคิว",
    cName,  
    subCName:subCNameInput,    
    keyName:"queueStatus",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["queueStatus"],
    selectObj:"queueStatus"
  },

  //10+1=11
  { 
    templateType:"selectObj",
    lb:"pic",
    cName,  
    subCName:subCNameInput,    
    keyName:"pic",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["pic"],
    selectObj:"pic"
  },
  //11+1==12
  { 
    templateType:"string",
    lb:"ชื่อ",
    cName,  
    subCName:subCNameInput,    
    keyName:"remark",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["remark"]
  },
  //12+1=13
  { 
    templateType:"number",
    lb:"userId",
    cName,  
    subCName:subCNameMinMax,
    keyName:"userId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["userId"]
  },
 //13+1=14
  { 
    templateType:"string",
    lb:"ประเภทการชำระเงิน",
    cName,  
    subCName:subCNameInput,    
    keyName:"paymentType",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["paymentType"]
  },
  //14+1=15
  { 
    templateType:"number",
    lb:"updatedAt",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"dateUseTimeSetting",
    filterCommand:["updatedAt"]
  },
  //15+1=16
  { 
    templateType:"boolean",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"spareBoolean1",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["spareBoolean1"]
  },
  //16+1=17
  { 
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"grandTotal",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["grandTotal"]
  },
  { 
    templateType:"number",
    lb:"updatedAt",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"dateUseTimeSetting",
    filterCommand:["updatedAt"]
  },
]

const transactionBranchFilter=[
  transactionFilter[0],//active
  { 
    templateType:"number",
    lb:"id",
    cName,  
    subCName:subCNameMinMax,
    keyName:"branchId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["branchId"]
  },
  transactionFilter[1],//idid
  transactionFilter[2],//date
  transactionFilter[3],//remindDate

  transactionFilter[4],//transactionType
  transactionFilter[5],//transactionStatus

  transactionFilter[6],//partnerId
  transactionFilter[7],//title

  transactionFilter[8],//name
  transactionFilter[9],//phone
  transactionFilter[10],//queueStatus
  transactionFilter[11],//pic
  
  transactionFilter[12],//remark
  transactionFilter[13],//userId
  transactionFilter[14],//paymentType
  transactionFilter[15],//updatedAt
  transactionFilter[16],//spareBoolean1
  transactionFilter[17],//grandTotal
  { 
    templateType:"number",
    lb:"updatedAt",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"dateUseTimeSetting",
    filterCommand:["updatedAt"]
  },
]



const billMenuFilter=[
  transactionFilter[0],//active
  transactionFilter[1],//id
  transactionFilter[2],//date
  transactionFilter[3],//remindDae

  transactionFilter[4],//transactionType

  transactionFilter[5],//transactionStatus
  transactionFilter[6],//partnerId
  transactionFilter[7],//title
  transactionFilter[8],//name
  transactionFilter[9],//phone
  transactionFilter[10],//queueStatus

  //11
  { 
    templateType:"arrayObjectString",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"productId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["detail","id"]
  },
  //12
  { 
    templateType:"arrayObjectString",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"barcode",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["detail","barcode"]
  },
   //13
   { 
    templateType:"arrayObjectString",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"sn",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["detail","sn"]
  },
  //14
  { 
    templateType:"arrayObjectString",
    lb:"ชื่อ",
    cName,  
    subCName:subCNameInput,    
    keyName:"productName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["detail","productName"]
  },
  //15
  { 
    templateType:"arrayObjectString",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"groupName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["detail","groupName"]
  },
  transactionFilter[17],//grandTotal

  { 
    templateType:"number",
    lb:"updatedAt",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"dateUseTimeSetting",
    filterCommand:["updatedAt"]
  },
]

const billBranchMenuFilter=[
  transactionFilter[0],//active

  { 
    templateType:"number",
    lb:"ไอดี",
    cName,  
    subCName:subCNameMinMax,
    keyName:"branchId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["branchId"]
  },
  transactionFilter[1],//id
  transactionFilter[2],//date
  transactionFilter[3],//remindDate

  transactionFilter[4],//transactionType
  transactionFilter[5],//transactionStatus
  transactionFilter[6],//partnerId
  transactionFilter[7],//title

  transactionFilter[8],//name
  transactionFilter[9],//phone
  transactionFilter[10],//queueStatus

  billMenuFilter[11],  //["detail","productId"]
  billMenuFilter[12],  //["detail","barcode"]
  billMenuFilter[13], //["detail","sn"]
  billMenuFilter[14], //["detail","productName"]
  billMenuFilter[15],  //["detail","groupName"]
  transactionFilter[17],//grandTotal
  { 
    templateType:"number",
    lb:"updatedAt",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"dateUseTimeSetting",
    filterCommand:["updatedAt"]
  },
]


const stickerFilter=[
  { 
    templateType:"boolean",
    lb:"active",
    cName,  
    subCName:subCNameInput,    
    keyName:"active",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["active"]
  },
  { 
    templateType:"number",
    lb:"ไอดี",
    cName,  
    subCName:subCNameMinMax,
    keyName:"id",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["id"]
  },
  //date
  { 
    templateType:"number",
    lb:"วันที่",
    cName,  
    subCName:subCNameMinMax,
    keyName:"date",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["date"]
  },
  //transactionType
  { 
    templateType:"string",
    lb:"ชื่อ",
    cName,  
    subCName:subCNameInput,    
    keyName:"recordName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["recordName"]
  },
  { 
    templateType:"string",
    lb:"บันทึกโดย",
    cName,  
    subCName:subCNameInput,    
    keyName:"recordBy",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["recordBy"]
  },
  
]



const taskFilter=[
  { 
    templateType:"boolean",
    lb:"active",
    cName,  
    subCName:subCNameInput,    
    keyName:"active",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["active"]
  },
  { 
    templateType:"number",
    lb:"ไอดี",
    cName,  
    subCName:subCNameMinMax,
    keyName:"id",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["id"]
  },
  { 
    templateType:"string",
    lb:"ชื่องาน",
    cName,  
    subCName:subCNameInput,    
    keyName:"taskName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["taskName"]
  },


  { 
    templateType:"number",
    lb:"ไอดีคู่ค้า",
    cName,  
    subCName:subCNameMinMax,
    keyName:"partnerId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["partnerId"]
  },
  { 
    templateType:"selectObj",
    lb:"คำนำหน้า",
    cName,  
    subCName:subCNameInput,    
    keyName:"title",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["title"],
    selectObj:"title"
  },
  { 
    templateType:"string",
    lb:"ชื่อ",
    cName,  
    subCName:subCNameInput,    
    keyName:"name",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["name"]
  },
  { 
    templateType:"string",
    lb:"โทรศัพท์",
    cName,  
    subCName:subCNameInput,      
    keyName:"phone",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["phone"]
  },

  { 
    templateType:"number",
    lb:"วันที่",
    cName,  
    subCName:subCNameMinMax,
    keyName:"date",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["date"]
  },

  { 
    templateType:"number",
    lb:"วันที่เตือน",
    cName,  
    subCName:subCNameMinMax,
    keyName:"remindDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["remindDate"]
  },
  { 
    templateType:"number",
    lb:"วันที่เริ่ม",
    cName,  
    subCName:subCNameMinMax,
    keyName:"startDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["startDate"]
  },
  { 
    templateType:"number",
    lb:"วันที่จบ",
    cName,  
    subCName:subCNameMinMax,
    keyName:"endDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["endDate"]
  },
  //remark
  //8
  { 
    templateType:"string",
    lb:"หมายเหตุ",
    cName,  
    subCName:subCNameInput,    
    keyName:"remark",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["remark"]
  },






]








const shopFilter=[
  { //1
    templateType:"boolean",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"active",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["active"]
  },
  { //2
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"shopName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["shopName"]
  },
  { //3
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"ownerName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["ownerName"]
  },
  { //4
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"ownerEmail",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["ownerEmail"]
  },
  { //5
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"expirationDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["expirationDate"]
  },
  { //6
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"tempExpirationDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["tempExpirationDate"]
  },

  { //7
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"monthlyFee",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["monthlyFee"]
  },
  { //8
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"remainingBalance",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["remainingBalance"]
  },
  { //9
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"dateIn",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["dateIn"]
  },



]

const userFilter=[
  { 
    templateType:"boolean",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"active",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["active"]
  },
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"username",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["username"]
  },
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"userLevel",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["userLevel"]
  },
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"shopId",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["shopId"]
  },
  { 
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"branchId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["branchId"]
  },
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"name",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["name"]
  },
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"surname",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["surname"]
  },
  { //9
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"dateIn",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["dateIn"]
  },



]

const shopPaymentFilter=[
  { 
    templateType:"boolean",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"active",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["active"]
  },
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"shopId",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["shopId"]
  },
  { 
    templateType:"boolean",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"isCheck",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["isCheck"]
  },
  { 
    templateType:"boolean",
    lb:"",
    cName,  
    subCName:subCNameInput,    
    keyName:"hasProblem",
    refInput:genRef(),
    inputType:"checkbox",
    filterCommand:["hasProlem"]
  },
  { //9
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"transferDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["transferDate"]
  },
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"accountName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["accountName"]
  },
  {
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"accountNumber",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["accountNumber"]
  },

  { 
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"transferAmount",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["transferAmount"]
  },
  {
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"checkBy",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["checkBy"]
  },
  {
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"ownerName",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["ownerName"]
  },
  {
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"ownerEmail",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["ownerEmail"]
  },

  { //9
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"expirationDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["expirationDate"]
  },

  { //9
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"tempExpirationDate",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["tempExpirationDate"]
  },

  { 
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"monthlyFee",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["monthlyFee"]
  },
  { 
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"remainingBalance",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["remainingBalance"]
  },

]

const branchForAdminFilter=[
  { 
    templateType:"number",
    lb:"id",
    cName,  
    subCName:subCNameMinMax,
    keyName:"id",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["id"]
  },
  
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"shopId",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["shopId"]
  },
  
  { 
    templateType:"number",
    lb:"วันที่",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["updatedAt"]
  },
  { 
    templateType:"number",
    lb:"",
    cName,  
    subCName:subCNameMinMax,
    keyName:"branchId",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["branchId"]
  }
]

const basicDataForAdminFilter=[
  { 
    templateType:"number",
    lb:"id",
    cName,  
    subCName:subCNameMinMax,
    keyName:"id",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["id"]
  },
  
  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"shopId",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["shopId"]
  },
  
  { 
    templateType:"number",
    lb:"วันที่",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["updatedAt"]
  }
  
]




const groupForAdminFilter=[
  { 
    templateType:"number",
    lb:"id",
    cName,  
    subCName:subCNameMinMax,
    keyName:"id",
    refInput:genRef(),
    inputType:"number",
    filterCommand:["id"]
  },

  { 
    templateType:"string",
    lb:"",
    cName,  
    subCName:subCNameInput,
    keyName:"shopId",
    refInput:genRef(),
    inputType:"text",
    filterCommand:["shopId"]
  },
  { 
    templateType:"number",
    lb:"วันที่",
    cName,  
    subCName:subCNameMinMax,
    keyName:"updatedAt",
    refInput:genRef(),
    inputType:"date",
    filterCommand:["updatedAt"]
  },
]

const StateTemplate={
    groupForAdminFilter:groupForAdminFilter,
    productForAdminFilter:groupForAdminFilter,
    partnerForAdminFilter:groupForAdminFilter,
    transactionForAdminFilter:groupForAdminFilter,
    stickerForAdminFilter:groupForAdminFilter,

    customerFilter,
    partnerFilter,
    productFilter,
    groupFilter,
    productBranchFilter,
    transactionFilter,
    transactionBranchFilter,
    billMenuFilter,
    billBranchMenuFilter,
    stickerFilter,

    shopFilter,
    userFilter,
    shopPaymentFilter,

    basicDataForAdminFilter,
    branchForAdminFilter,

    taskFilter
}
  
export default StateTemplate
  
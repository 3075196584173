import React from 'react';
import {MdVisibility,MdVisibilityOff} from 'react-icons/md';
import {FaPlusSquare,FaMinusSquare,FaFolder} from 'react-icons/fa'; 
import Calendar from '../../component/calendar/Calendar'
import RepeatedCalendar from '../../component/repeatedCalendar/RepeatedCalendar'

import {MdSearch,MdRefresh,MdDelete,} from 'react-icons/md';
import Table from '../../component/table/Table'

import StateUtil from '../../model/StateUtil'

import Ticon from '../../component/ticon/Ticon'

import axios from '../../util/axiosVerify';
import console from '../../util/consoleVerify'


//import DatePicker, { registerLocale } from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.min.js';
//import th from "date-fns/locale/th";


//registerLocale("th", th);

const {revRefAndValue}=StateUtil  

const renderForm=({
    cName,
    template,
    refAndValue,ref1,iconAction,
    setRefAndValue,
    
    basicData,
    selectData,
    blankData,
    refAndValueBlankData,
    loadData,setLoadData,
    hidePassword,setHidePassword,

    tableTemplate,setTableTemplate,
    showTable,setShowTable,
    selectRow,setSelectRow,
    editData,setEditData,
    
    updateFilterData,
    insertLine,//insertUp,insertDown,
    selectAll,unSelectAll,deleteLine,getSelectAll,
    moveSelectedDown,moveSelectedUp,
    moveUp,moveDown,setFilterDataDetail,
    findProductIdByKeyDown,
    findBarcodeByKeyDown,
    calDigit,

    setCalculate,
    sumAmount,
    saveDetailTableTemplateForFormFunc,
    selectGroup,
    selectProduct,
    pageDataModalForm,
    dataUrl,myheader,
    shouldRender
    })=>{
    //================================
    //console.log('..............renderForm')
    //console.log(refAndValue)

    const changeInputStateForNumber=({location,value})=>{
     

      const  pattern = "^\-?[0-9]+(?:\.[0-9]{1,2})?$"
      
      const regex = new RegExp(pattern);
      const result=regex.test(value.toString())
      
      //console.log('changeInputStateForNumber')
      //console.log(result)

      if(result||value==""){
        let temp= refAndValue
        location.map(i=>{ temp=temp[i]})
        temp.value=value
        setRefAndValue({...refAndValue})
        let tempRev=revRefAndValue({template,refAndValue})
        setLoadData({...loadData,...tempRev})
        setCalculate(true)
      }
    } 

    const changeInputStateForGenId=({location,value})=>{
     
      //const  pattern = "^\-?[0-9]+(?:\.[0-9]{1,2})?$"
      const pattern="^[a-zA-Z0-9+-_*]*$"
      const regex = new RegExp(pattern);
      const result=regex.test(value.toString())
      
      //console.log('changeInputStateForGenId')
      //console.log(result)

      if(result||value==""){
        let temp= refAndValue
        location.map(i=>{ temp=temp[i]})
        temp.value=value
        setRefAndValue({...refAndValue})
        let tempRev=revRefAndValue({template,refAndValue})
        setLoadData({...loadData,...tempRev})
        setCalculate(true)
      }
    } 

    //================================
    const changeInputState=({location,value})=>{
        let temp= refAndValue
        location.map(i=>{ temp=temp[i]})
        temp.value=value
        setRefAndValue({...refAndValue})
        let tempRev=revRefAndValue({template,refAndValue})
        setLoadData({...loadData,...tempRev})
        setCalculate(true)
    }
    //================================
    const goToNextRef=(e,nextRef,lastRef)=>{
      
       //console.log('geToNextRef')
       //console.log(refAndValue)

        if(e.key=="Enter"){
            if(Array.isArray(nextRef)){

               if(nextRef[0]!="detail"){
                 if(lastRef){
                   //console.log('lastRef')  
                   ref1.current.focus()
                 }
                 else{
                    let temp= refAndValue
                    nextRef.map(i=>{ 
                      temp=temp[i]
                    })
                    temp.ref.current.focus()
                  }
               }
               
            }
            else{
              ref1.current.focus()
            }
        }
        
      
    }
    //========================
    const genRef=(location)=>{

      let temp=refAndValue
      if(Array.isArray(location)){
        location.map(i=>{
          temp=temp[i]
        })
      }

      return temp.ref 
    }
    //========================
    const goToNextRefInTable=(e,rAv,tpKey,tPtTpKey,idx,length)=>{
      
      //console.log('e.key')
      //console.log(e.key)

      if((e.key=="Enter")||(e.key=="ArrowRight")){  
          
          if(Array.isArray(rAv.nextRef)){
              let temp= refAndValue
              rAv.nextRef.map(i=>{ temp=temp[i]})
              temp.ref.current.focus()
          }
          else{ //out of form to OK button
            //refButtonOK.current.focus()
            ref1.current.focus()
          }
          
      }

      /*
      if(e.key=="Enter"){
        
        if(tPtTpKey.nextEnter){
          //last filed and last line in table
          if((idx==length-1)&&tPtTpKey.nextEnter.lastKey){

            if(Array.isArray(tPtTpKey.nextEnter.lastKey)){ //out of table to next ref in form
              
              let temp= refAndValue
              tPtTpKey.nextEnter.lastKey.map(i=>{ temp=temp[i]})
              temp.ref.current.focus()
            
            }
            else{ //out of form to OK button
              //refButtonOK.current.focus()
              ref1.current.focus()

            }

          }
          else{ //compare 
            let temp=refAndValue
            
            tPtTpKey.nextEnter.nextKey1.map(i=>{
              if(i===0){ temp=temp[idx] } //not last in row
              else  if (i===1){ temp=temp[idx+1] } //last in row go to next line
              else{ temp=temp[i] } //not last in row
            })
            temp.ref.current.focus()
          }  

        }
      }
      */
    }
    //========================
    const renderEachInputType=({
        lb,cName,subCName,templateType,
        inputType,subFormTemplate,
        placeholder,disabled,autoFocus,unchangeable,
        selectObj,selectDataKey,textRow,
        selectOption,
        location,nextRef,ref,value,lastRef,
        tpKey
    })=>{

        if(inputType=="select"){
            
            const renderSelectCondition=(value)=>{
              //เงื่อนไข 1
                //console.log('renderSelectCondition......')
                //console.log(selectData[selectDataKey][selectObj])
                //console.log(value)

                if(!selectData [ selectDataKey ]){return null}

                const tempSelectObjSplit=selectObj.split(",")

              
                if(tempSelectObjSplit.length==2){
                    let temp2=selectData[selectDataKey][tempSelectObjSplit[0]]
                    return temp2.map((m,index)=>(
                      <option key={index} value={m[tempSelectObjSplit[1]]}

                              >{m[tempSelectObjSplit[1]]}</option>
                    ))
                }

                if(tempSelectObjSplit.length==1){
                  
                  if(Array.isArray(selectData[selectDataKey][selectObj])) {
                    
                    return selectData[selectDataKey][selectObj].map((m,index)=>(
                        <option key={index} value={m}
                            selected={value==m?"selected":""}
                        >{m}</option>
                    ))

                  }

                }

            }
            
            return(
            <select className="w-100"
                style={{height:"1.5rem"}}
                type={inputType}
                dafaultValue={value}
                ref={genRef(location)}
                onChange={e=>{
                    if(!unchangeable){
                      changeInputState({location,value:e.target.value})
                    }
                }}
                onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
                disabled={disabled}
                autoFocus={autoFocus}
            >  
                <option value="" hidden

                
                >...</option>
                 {
                  renderSelectCondition(value)
                 }
            </select>
            )
        }

        else if(inputType=="select2"){
          return(
              <select className="w-100"
                  style={{height:"1.5rem"}}
                  type={inputType}
                  value={value}
                  ref={genRef(location)}
                  onChange={e=>{
                      if(!unchangeable){
                        changeInputState({location,value:e.target.value})
                      }
                  }}
                  onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
                  disabled={disabled}
                  autoFocus={autoFocus}
              >  
                  <option value="" hidden>...</option>

                  {
                   selectOption.map((i,index)=>{
                     return(
                       <option key={index} 
                               value={i}

                       >{i}</option>
                     )
                   })
                  }
              </select>
          )
        }

        else if(inputType=="checkbox"){
            return(
              <div className="h-100 w-100"
                   style={{display:"flex",justifyContent:"center",alignItems:"center"}}
              >
                <input 
                  style={{height:"1.5rem",margin:"0",border:"0"}}
                  type={inputType}
                  checked={value}
                  ref={genRef(location)}
                  onChange={e=>{
                    if(!unchangeable){
                      changeInputState({location,value:e.target.checked})
                    }
                  }}
                  onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
                  disabled={disabled}
                  autoFocus={autoFocus}
                />
            </div>
            )
        }
        else if(inputType=="password"){
            return(
            <div style={{position:"relative",width:"100%"}} >
                <input
                    style={{height:"1.5rem"}}
                    type={hidePassword?inputType:"text"}
                    value={value}
                    ref={genRef(location)}
                    onChange={e=>{
                      if(!unchangeable){
                        changeInputState({location,value:e.target.value})
                      }
                    }}
                    onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
                    autoComplete='one-time-code'

                    disabled={disabled}
                    autoFocus={autoFocus}
                />
                {
                hidePassword
                ?<MdVisibilityOff 
                    className="sm-icon" 
                    style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                    onClick={e=>{
                    setHidePassword(!hidePassword)
                    }}
                />
                :<MdVisibility 
                    className="sm-icon" 
                    style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                    onClick={e=>{
                    setHidePassword(!hidePassword)
                    }}
                />
                }
            </div>
            )
        }
        else if(inputType=="date"){
        
          return (
            <Calendar
                    style={{height:"1.6rem",fontSize:"0.8rem"}}
                    showDateOption={"datetime"}
                    useTimeSetting={true}
                    onMyClick={
                        (e)=>{
                    
                          if(!unchangeable){
                            changeInputState({location,value:e})
                          }
                       
                        }
                    }
                    value={value}//{tempInputState[i.keyName].max}
            />
          )

        }
        //=======================
      
        else if (inputType=="textarea"){
          return (
            <textarea
              style={{width:"100%"}}
              value={value}
              rows={textRow}
              ref={genRef(location)}
              onChange={e=>{
               
                  if(!unchangeable){
                    changeInputState({location,value:e.target.value})
                  }
              }}
              //onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
              disabled={disabled}
              autoFocus={autoFocus}
            />
          )
        }
        //=======================
        else if(inputType=="repeatedCalendar"){
          return (
            <RepeatedCalendar
                basicData={basicData}
                onMyClick={(data)=>{
                  //console.log('data')
                  //console.log(data)
                  changeInputState({location,value:data})
                }}
            />
          )

        }
        //=======================
        else if(inputType=="number"){
 
          return (
            <input
                style={{height:"1.5rem"}}
                type={inputType}
                value={value}
                rows={textRow}
                ref={genRef(location)}
                onChange={e=>{
                  if(!unchangeable){
                    changeInputStateForNumber({location,value:e.target.value})
                  }
                }}
                onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
                disabled={disabled}
                autoFocus={autoFocus}
            />
            )
        }
        //------------------------------
        else if(inputType=="genId"){
 
          return (
            <input
                style={{height:"1.5rem"}}
                type={inputType}
                value={value}
                rows={textRow}
                ref={genRef(location)}
                onChange={e=>{
                  if(!unchangeable){
                    changeInputStateForGenId({location,value:e.target.value})
                  }
                }}
                onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
                disabled={disabled}
                autoFocus={autoFocus}
            />
            )
        }
        //=======================
        else if(inputType=="table"){

          if(basicData.table){
            if(basicData.table.length>0){
   
               let tempTableArray=[]
               basicData.table.map(i=>{
                  if(i.tableActive){
                     tempTableArray=[...tempTableArray,i.tableName]
                  }
               })
                   
               return(
            
            
                     <select className="w-100"
                            type="select"
                            ref={genRef(location)}
                            onChange={e=>{
                              if(!unchangeable){
                                changeInputState({location,value:e.target.value})
                              }
                            }}
                            onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
                     >
                        <option>....</option>
                        {tempTableArray.map((j,idx2)=>(
                           <option key={idx2}
                                   selected={value==j?"selected":""}
                                   value={j}
                           >{j}</option>
                        ))}
                     </select>
                  )
                
            }
         }
        }

        else{
            return (
            <input
                style={{height:"1.5rem"}}
                type={inputType}
                value={value}
                rows={textRow}
                ref={genRef(location)}
                onChange={e=>{
                  if(!unchangeable){
                    changeInputState({location,value:e.target.value})
                  }
                }}
                onKeyDown={e=>{goToNextRef(e,nextRef,lastRef)}}
                disabled={disabled}
                autoFocus={autoFocus}
            />
            )
        }
    }  
    //==================================

    const renderIconGroup=({tPt,rAv,bDt,rAvbDt,lDt,tpKey,tpIdx})=>{
      const { lb,cName,subCName,templateType,
        inputType,subFormTemplate,
        placeholder,disabled,autoFocus,unchangeable,
        selectObj,selectDataKey,iconActionIdx}=tPt[tpKey]

      return(
        <div key={tpIdx} className={`${cName}`}>
  
          <div  className="flex-center-center">
            <div className={subCName[0]}
                 style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}
            >
                <p className="label table-p"
                  style={{margin:"auto 0"}}
                >{lb}</p>

            </div>
            <div className={subCName[1]}>
              <div style={{display:"flex",alignItems:"flex-end"}}>
                <FaFolder style={{fontSize:"1.5rem"}}/>
              </div>
            </div>
            </div>
        </div>
        )
    }
    //======================================
    const renderSingleInput=({tPt,rAv,bDt,rAvbDt,lDt,tpKey,tpIdx})=>{
        const { lb,cName,subCName,templateType,
                inputType,subFormTemplate,
                placeholder,disabled,autoFocus,unchangeable,
                selectObj,selectDataKey,textRow,
                selectOption
                //calculation
              }=tPt[tpKey]
        const {location,nextRef,ref,value}=rAv[tpKey]
        
               return(
        <div key={tpIdx} className={`${cName}`}>


          <div  className="flex-center-center">
         
            <div className={subCName[0]}> 
              <div className="" style={{display:"flex",paddingLeft:"0.5rem"}}>
              
                <p style={{margin:"auto 0"}} className="table-p" >{lb}</p>
                {inputType=="genId"
                ?<MdRefresh className="sm-icon"
                  onClick={e=>{
                    //console.log('dataUrl')
                    axios.post(`/${dataUrl}/getlimit`,{},myheader,shouldRender)
                    .then(result=>{
                      //console.log('result')
                      if(result){
                        if(result.data){
                          const nextId=parseInt(result.data.lastRecordId)+1
                          //console.log(location)  
                          changeInputState({location,value:nextId})

                        }
                      }

                    })
                    .catch(error=>{
                      //console.log('error')
                    })
                  }}
                />
                :null
                }  
              </div>
            </div>

            <div className={subCName[1]}>
              {  true
                  ?renderEachInputType({
                    lb,cName,subCName,templateType,
                    inputType,subFormTemplate,
                    placeholder,disabled,autoFocus,unchangeable,
                    selectObj,selectDataKey,textRow,
                    selectOption,
                    location,nextRef,ref,value,
                    tpKey
                    //calculation
                  })
                  :null
              }
            </div>

            
            </div>
        </div>
        )
    }
    //====================================
    const renderArrayInput=({tPt,rAv,bDt,rAvbDt,lDt,tpKey,tpIdx})=>{
        const { lb,cName,subCName,templateType,
                inputType,subFormTemplate,
                placeholder,disabled,autoFocus,unchangeable,
                
                selectObj,selectDataKey}=tPt[tpKey]        
        return(
          <div key={tpIdx} className={`${cName}`}>
            
            <div  className="flex-center-center">
                <div className={subCName[0]} 
                     style={{padding:"0.5rem"}}
                >
                    <p className="label table-p"
                        style={{margin:"auto 0"}}
                    >{lb}</p>
                </div>
                <div className={subCName[1]}></div>
            </div>
            {
            rAv[tpKey].map((i,idx)=>{
                const {location,nextRef,ref,value}=i
              return(
                <div  key={idx} className="flex-center-baseline">
                  
                  <div className={subCName[0]}>
                    
                    {disabled=="disabled"
                    ?null
                    :<div className="flex-center-center">  
                      <div className="px-1">
                        <FaPlusSquare
                          className="sm-icon" 
                          onClick={e=>{
                            //we do not use revRefAndValue anymore
                            //instead we update lDt 
                            //After we lDt update, ReactEffect of [editData] in ModalForm
                            //it will update genRefAndValue automatically
                            lDt[tpKey].splice(idx+1,0,bDt[tpKey][0])
                            upDateLoadData()
                            //below code no use
                            //rAv[tpKey].splice(idx+1,0,rAvbDt[tpKey][0])
                            //let temp=revRefAndValue({template,refAndValue})
                            //setLoadData({...loadData,...temp})
                          }}
                        />   
                      </div>
                      <div className="px-1">
                        <FaMinusSquare
                          className="sm-icon" 
                          style={{visibility:idx>0?"visible":"hidden"}}
                          onClick={e=>{
                            //we do not use revRefAndValue anymore
                            //instead we update lDt 
                            //After we lDt update, ReactEffect of [editData] in ModalForm
                            //it will update genRefAndValue automatically
                            lDt[tpKey].splice(idx,1)
                            upDateLoadData()
                            //below code no use
                            //rAv[tpKey].splice(idx,1)
                            //console.log(refAndValue)
                            //let temp=revRefAndValue({template,refAndValue})
                            //setLoadData({...loadData,...temp})
                            //setRefAndValue({...refAndValue})
                          }}
                        />   
                      </div>
                    </div>
                    }
                  </div>
                  <div className={subCName[1]} style={{position:"relative"}}>
                   {renderEachInputType({
                       lb,cName,subCName,templateType,
                       inputType,subFormTemplate,
                       placeholder,disabled,autoFocus,unchangeable,
                       //calculation
                       selectObj,selectDataKey,

                       location,nextRef,ref,value
                   })}
                  </div>  
                </div>
              )
            })
            }
          </div>
        )
    }
    //====================================

    const renderFunction=({tPt,rAv,bDt,rAvbDt,lDt})=>{

        const arrayTemplate=Object.keys(tPt) 
        //console.log('renderFunction: arrayTemplate')
        //console.log(arrayTemplate)
        return ( arrayTemplate.map((tpKey,tpIdx)=>{  
          const {templateType}=tPt[tpKey]

          const { lb,cName,subCName,subFormTemplate,iconActionIdx}=tPt[tpKey]
          
          if(templateType=="object"){
            return(
              <div key={tpIdx} className={`${cName}`}>
                  <div className={subCName[0]}>

                    <p className="label table-p"
                      style={{margin:"auto 0"}}
                      >{lb}</p>
                  </div>
                  <div className={subCName[1]}>
                    {
                      renderFunction({
                        tPt:subFormTemplate,
                        rAv:rAv[tpKey],
                        bDt:bDt[tpKey],
                        rAvbDt:rAvbDt[tpKey],
                        lDt:lDt[tpKey],
                      })
                    }
                  </div>
              </div>
            )
          }
          else if(templateType=="arrayObject"){
       
            return rAv[tpKey].map((i,idx)=>{
                const {location}=i
              return(
                <div key={`${tpKey}-${idx}`} className={`${cName}`}>
                    <div className={subCName[0]}>
                      <p style={{margin:"auto"}} className="table-p">{lb}</p>
                    </div>                
                    
                    <div className={subCName[1]}> 

                      <div className="flex-center-center jc-start">  
                        <div className="px-1">
                            <FaPlusSquare
                            className="sm-icon" 
                            onClick={e=>{
                                //we do not use revRefAndValue anymore
                                //instead we update lDt 
                                //After we lDt update, ReactEffect of [editData] in ModalForm
                                //it will update genRefAndValue automatically
                                lDt[tpKey].splice(idx+1,0,bDt[tpKey][0])
                                upDateLoadData()
                                //below code no use
                                //rAv[tpKey].splice(idx+1,0,rAvbDt[tpKey][0])
                                //console.log('FaPlus RefAndValue arrayObject')
                                //console.log(refAndValue)

                                //let temp=revRefAndValue({template,refAndValue})
                                //setLoadData({...loadData,...temp})
                            }}

                            />   
                        </div>
                        <div className="px-1">
                            <FaMinusSquare
                            className="sm-icon" 
                            style={{visibility:idx>0?"visible":"hidden"}}
                            onClick={e=>{
                                //we do not use revRefAndValue anymore
                                //instead we update lDt 
                                //After we lDt update, ReactEffect of [editData] in ModalForm
                                //it will update genRefAndValue automatically
                                lDt[tpKey].splice(idx,1)
                                upDateLoadData()
                                //below code no use
                                //rAv[tpKey].splice(idx,1)
                                //let temp=revRefAndValue({template,refAndValue})
                                //setLoadData({...loadData,...temp})
                                //setRefAndValue({...refAndValue})
                            }}
                            />   
                        </div>
                      </div>
                      <div className="flex-center-start jc-start">      
                        { 
                        renderFunction({
                          tPt:subFormTemplate,
                          rAv:i,
                          bDt:bDt[tpKey][0],
                          rAvbDt:rAvbDt[tpKey][0],
                          lDt:lDt[tpKey][idx]
                        })
                        }
                      </div>
                  </div>
                </div>
              )
            })
          }  



          else if(templateType=="arrayObjectInTable"){
            if(!tableTemplate){
              return null
            }
            else{
              return(
                <div  key={tpIdx}
                     style={{width:"100%",height:"100%",overflow:"hidden"}}>
                  <div className="flex-center-center jc-start" 
                       style={{margin:"2rem 0 0 0",
                              backgroundColor:"#e5d1db",
                              height:"2rem"
                              }}>
                    
                    <div className="iconbox"
                        onClick={e=>{
                          //if(editData){
                              insertLine()
                          //}
                          }}
                    > 
                        <Ticon
                          iconName="MdAddCircle"
                          className="sm-icon"
                          textStyle={{color:"black"}}
                        />
                    </div>
                
                    <div
                      className="iconbox"
                      onClick={e=>{
                        if(editData){
                          moveUp()
                        }
                      }}
                    > 
                        <Ticon
                        iconName="FaRegArrowAltCircleUp"
                        className="sm-icon"
                        textStyle={{color:"black"}}
                      />
                    </div>
                 
                    <div  className="iconbox"
                        onClick={e=>{
                          if(editData){
                            moveDown()
                          }
                        }}
                    >
                      <Ticon
                        iconName="FaRegArrowAltCircleDown"
                        className="sm-icon"
                        textStyle={{color:"black"}}  
                      />
                    </div>
                   
                    <div  className="iconbox"
                        onClick={e=>{
                          if(editData){
                            deleteLine()
                          }
                        }}
                    > 
                        <Ticon
                          iconName="MdDelete"
                          className="sm-icon"
                          textStyle={{color:"black"}}
                        />
                    </div>
                  
                    <div className='iconbox'
                         onClick={e=>{
                          getSelectAll()
                          }}
                    >
                         <Ticon
                           iconName="RiShareForwardLine"
                           className="sm-icon"
                           textStyle={{color:"black"}}
                      />        
                    </div>
                

                  </div>

                  <div className="w-100 h-100" style={{marginTop:"0rem"}}>
                    
                    <Table
                        colorHead={"#777"}
                        tableTemplate={tableTemplate}
                        setTableTemplate={setTableTemplate}

                        filterData={loadData[tpKey]}
                        setFilterData={setFilterDataDetail}
                        
                        editData={editData}
                        setEditData={setEditData}

                        useInput={true}
                        saveTableTemplateFunc={saveDetailTableTemplateForFormFunc}
                        isSubTable={false}
                        basicData={selectData.basicData}
                        updateFilterData={updateFilterData}
                        //insertUp,insertDown,selectAll,unSelectAll,deleteLine,getSelectAll,
                        //moveSelectedDown,moveSelectedUp,
                        findProductIdByKeyDown={findProductIdByKeyDown}
                        findBarcodeByKeyDown={findBarcodeByKeyDown}
                        calDigit={calDigit}
                        
                    />
                    
                  </div>
                </div>
              )
            }
          }
          else if(templateType=="array"){      
            return(
              renderArrayInput({tPt,rAv,bDt,rAvbDt,lDt,tpKey,tpIdx})
            )
          }
          else if(templateType=="icon-group"){

            if(iconAction){
              return (
              renderIconGroup({tPt,rAv,bDt,rAvbDt,lDt,tpKey,tpIdx})
              )
            }

          }

          else{
            return(
              renderSingleInput({tPt,rAv,bDt,rAvbDt,lDt,tpKey,tpIdx})
            )
          }
        }))
    }
    //=========================================
    let globalTemp={...loadData}  
    const upDateLoadData=()=>{
      //console.log('upDateLoadData')
      setLoadData({...globalTemp})
    }
    //========================================
    return (
        <div className="flex-center-start jc-start">
        {  
          renderFunction({
             tPt:template,
             rAv:refAndValue,
             bDt:blankData,
             rAvbDt:refAndValueBlankData,
             lDt:globalTemp,
          }) 
        }
        </div>
    )
}

export default renderForm

import React from 'react';
import pageUtil from '../../component/pageComponent/pageUtil'
import filterDataTemplate from './filterDataTemplate'
import {MainContext} from '../../context/MainContext'
import {MdRefresh} from 'react-icons/md';
import {FaPlusCircle,FaMinusCircle} from 'react-icons/fa'

//import renderModalImage from '../../component/galleryone/renderModalImage';
import console from '../../util/consoleVerify'

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete
} = pageUtil
//==================================
function BillProductGridComponent(props) {

const {selectProduct,setHideProductGridFunc,
      captureProductListFromSelectProductInProductGrid,
      resetProductList,setResetProductList,
      refreshProductFunc,
      setSelectProductToBillForm,

      billGroupComponentFilterData2,
      setBillGroupComponentFilterData2
}=props

const {basicDataSt,
        setReloadBasicData,
        myheader,
        setBasicData
        //widthLeft,setWidthLeft
}=React.useContext(MainContext)

const {basicData}=basicDataSt

//console.log('BillProductGridComponent')


const defaultFilterData2={
    count:0,
    data0:null,
    dataUrl:"p35product",
    lastRecordId:null,
    limitRow:basicData.limitRow,//3,
    pageNumber:1,

    reloadData:false,
    sort:{id:-1},

}


const genFilterData2=()=>{
    if(billGroupComponentFilterData2){
        return billGroupComponentFilterData2
    }
    else{
        return defaultFilterData2
    }
}

let [filterData2,setFilterData2]=React.useState(genFilterData2())

//let [filterData,setFilterData]=React.useState({...filterDataTemplate.product})
const [productList,setProductList]=React.useState([])


React.useEffect(()=>{
   //console.log('selectProduct')
   //console.log(selectProduct)

},[selectProduct])


const sortSelectProduct=(selectProduct)=>{
    let tempArray=[]
    selectProduct.map(i=>{
        tempArray=[...tempArray,i.productName]
    })

    let sortArray=tempArray.sort()

    let tempFinalArray=[]

    sortArray.map(k=>{
        selectProduct.map(l=>{
            if(l.productName==k){
                tempFinalArray=[...tempFinalArray,l]
            }
        })
    })

    return tempFinalArray
}

let tempSelectProduct=[]

if(selectProduct){
    tempSelectProduct=sortSelectProduct(selectProduct)
}

//==========================
React.useEffect(()=>{
    //console.log('productList')
    //console.log(productList)
    captureProductListFromSelectProductInProductGrid(productList)
},[productList])

React.useEffect(()=>{
    if(resetProductList){
        setProductList([])
    }
},[resetProductList])


const addToList=(i)=>{
    let tempProductList=[]
    let matchId=false

    for (let j=0;j<productList.length;j++){
        if(i.id==productList[j].id){
            matchId=true
            const tempExistingQuantity=parseInt(productList[j]["quantity"])
            const tempObj={...productList[j],["quantity"]:tempExistingQuantity+1}
            tempProductList=[...tempProductList,tempObj]
        }
        else {
            tempProductList=[...tempProductList,productList[j]]
        }
    }

    if(!matchId){
        tempProductList=[...tempProductList,
                {...i,
                    ["quantity"]:1,
                    ["detailTime"]:new Date().toISOString()
                }
            ]
    }
    setResetProductList(false)
    setProductList(tempProductList)
} 

const deleteFromList=(i)=>{
    let tempProductList=[]
    let matchId=false

    for (let j=0;j<productList.length;j++){
        if(i.id==productList[j].id){
            matchId=true
            const tempExistingQuantity=parseInt(productList[j]["quantity"])
            if(tempExistingQuantity>0){
                const tempObj={...productList[j],["quantity"]:tempExistingQuantity-1}
                tempProductList=[...tempProductList,tempObj]
            }
            else {
                tempProductList=[...tempProductList,productList[j]]
            }
        }
        else {
            tempProductList=[...tempProductList,productList[j]]
        }
    }
    setResetProductList(false)
    setProductList(tempProductList)
}




const addStockToList=(i)=>{
    let tempProductList=[]
    let matchId=false

    let tempStock=0
    if(i.stock==0){
        tempStock=0
    }
    else if(i.stock<0){
        tempStock=i.stock
    }
    else if(!i.stock){
        tempStock=0
    }
    else{
        tempStock=i.stock
    }

    for (let j=0;j<productList.length;j++){
        if(i.id==productList[j].id){
            matchId=true
            const tempExistingQuantity=parseInt(productList[j]["quantity"])
            const tempObj={...productList[j],["quantity"]:tempExistingQuantity+tempStock}
            tempProductList=[...tempProductList,tempObj]
        }
        else {
            tempProductList=[...tempProductList,productList[j]]
        }
    }

    if(!matchId){
        tempProductList=[...tempProductList,
                {...i,
                    ["quantity"]:tempStock,
                    ["detailTime"]:new Date().toISOString()
                }
            ]
    }
    setResetProductList(false)
    setProductList(tempProductList)
} 



const findQuanityFromProductList=(i)=>{
    let quantity=0

    productList.map(j=>{
        if(i.id==j.id){
            quantity=j.quantity
        }
    })

    return quantity
}

                //"w-100 h-100 flex-center-center"


//==========================
const renderImage=()=>{
    return(
        <div className="grid-flex"
             style={{margin:"0.2rem"}}
        >
            <div className='grid-flextileMother'
                 style={{width:`${basicData.tileMotherWidth}rem`,
                         height:`${basicData.tileMotherHeight}rem`,
                        }}
            >
                <div className="grid-flextile"
                    style={{
                        border:"2px solid white",
                        height:`${basicData.imgHeight}%`,
                        width:`${basicData.imgWidth}%`,
                    }}
                >
                    <div className='w-100 h-100 flex-center-center bd-blue'>
                        <MdRefresh className="md-icon"
                            onClick={e=>refreshProductFunc({isRawMat:false})}
                        />
                    </div>
                
                </div>
            </div>
            {
                tempSelectProduct.map((i,idx)=>{

                        const genPhoto=()=>{
                            let temp=null
                            if(i.photoUrl1){
                                if(i.photoUrl1[0]){
                                    temp=i.photoUrl1[0]
                                }
                            }
                            return temp
                        }

                        const producNameHeight=100-basicData.imgHeight
                        return(
                        <div  key={idx}
                                className="grid-flextileMother"
                                style={{
                                    width:`${basicData.tileMotherWidth}rem`,
                                    height:`${basicData.tileMotherHeight}rem`,
                                   
                                }}
                        >

                            <div key={idx}
                                    className="grid-flextile"
                                    style={{
                                        position:"relative",
                                        border:"1px solid white",
                                        height:`${basicData.imgHeight}%`,
                                        width:`${basicData.imgWidth}%`,
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center",
                                        overflow:"hidden"
                                    }}
                            >
                                    
                                   
                                        {i.photoUrl1[0]
                                        ?<img 
                                            style={{
                                                height:"100%",
                                                //objectFit:"cover",
                                                //height:"100%",
                                                borderRadius:"0.5rem",
                                                }}
                                            src={genPhoto()}
                                            alt={`${i.productName}`}
                                        />
                                        :<div style={{height:"100%"}}>
                                            {i.productName}
                                        </div>
                                        }
                                  
                                   
            

                                    <div style={{position:"absolute",bottom:"0px",
                                                left:"0px",textAlign:"center",
                                                width:"1.5rem",height:"1.5rem",
                                                color:"white",backgroundColor:"red",
                                                borderRadius:"50%",margin:"auto 0",
                                                opacity:"0.9",zIndex:"10"
                                            }}
                                    >
                                        {findQuanityFromProductList(i)}
                                    </div>
                                    
                                    <div className='' 
                                        style={{position:"absolute",
                                                top:"0",
                                                height:"100%",
                                                width:"100%"
                                                }}>
                                    
                                        <div style={{width:"100%",
                                                     height:"100%",
                                                     position:"relative"
                                                     }}>
                                        
                                            <div className="grid-price" 
                                                    style={{
                                                        display:"flex",
                                                        justifyContent:"center",
                                                        alignItems:"center",
                                                        backgroundColor:"rgba(0,0,0,0)"
                                                    }}
                                            >
                                                    <FaPlusCircle className="lg-icon"
                                                        onClick={e=>addToList(i)}
                                                    />
                                            </div>        
                                    
                                            <div className="grid-price" 
                                                    style={{
                                                        display:"flex",
                                                        justifyContent:"center",
                                                        alignItems:"center",
                                                        backgroundColor:"rgba(0,0,0,0)"
                                                    }}
                                            >
                                                    <FaMinusCircle className="lg-icon"
                                                        onClick={e=>deleteFromList(i)}
                                                    />
                                            </div>
                                        </div>        

                                        <div className='grid-stock' 
                                            style={{width:"100%",
                                            position:"absolute",
                                            bottom:"0",
                                            textAlign:"right",
                                            color:"black",
                                            opacity:1
                                            }}
                                            onClick={e=>{
                                                addStockToList(i)
                                            }}
                                        >
                                            {i.stock
                                            ?`stock: ${i.stock}`
                                            :`stock: 0`
                                            }
                                        </div>

                                    </div>



                            </div>
                            
                            
                            <div className=''
                                style={{width:"100%",height:`${producNameHeight}%`}}
                            >
                                     {i.photoUrl1[0]
                                     ?`${i.productName}`
                                     :`${i.id}`
                                     }
                            </div>         
                            


                    </div>
                    )
                })
            }
        </div>
    )
}

//====================
return(
<div className="w-100" style={{marginBottom:"4rem"}} >

           {   selectProduct&&
               renderImage()
           }
</div>

)

}
export default BillProductGridComponent;

import React from 'react';
import axios from '../../util/axiosVerify'
import ReCAPTCHA from 'react-google-recaptcha'
import {MainContext} from '../../context/MainContext'
import {MdVisibility,MdVisibilityOff,MdAddCircle,MdVpnKey} from 'react-icons/md';
import {FaUserCircle,FaCheck,FaBan,FaMoneyBillWave,FaKeyboard
} from 'react-icons/fa'
import {BsShop} from 'react-icons/bs'
import photoUtil from '../../component/galleryone_add/photoUtil'
import GallerySinglePhoto_add from '../../component/galleryone_add/GallerySinglePhoto_add'
import StateTemplate from '../../model/StateTemplate';
import { loadCaptchaEnginge, LoadCanvasTemplate, 
   LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import MyKeyPad from './MyKeyPad'

import LogOutTool  from './LogOutTool';
import renderModalError from '../../render/renderModalError'
import axiosUtil from '../../util/axiosUtil'
import showUtil from '../../util/showUtil'

import {Link} from 'react-router-dom';
import LogInLangTemplate from './LogInLangTemplate'
import console from '../../util/consoleVerify'

const {catchErrorToMessage,genFD,addSingleFileNameInsteadOfPhotoUrl}=axiosUtil
const {showPassword}=showUtil
function LogIn() {
  
   const {basicDataSt,myheader,tokenSt,setReloadCheckToken}=React.useContext(MainContext)
   const {reloadCheckToken,haveShopToken,haveUserToken,userName}=tokenSt
   //console.log('LogIn......')
   //console.log(tokenSt)
   //console.log(basicDataSt)
   const {user,forLogInPage}=basicDataSt

   const refHome=React.createRef()

   const refSubmit=React.createRef()
   const refCancel=React.createRef()

   const refTest=React.createRef()
//========================
//=========================
//===============================
const shouldRender= React.useRef(true)
React.useEffect(()=>{

   setTimeout(()=>{
      if(shouldRender.current){
         loadCaptchaEnginge(4)
      }
   },200)

    return () => {
        //console.log('end.................Bill................')
        shouldRender.current=false
    } 
},[])

const genPageData=()=>{
   let pageData=LogInLangTemplate.Thai
   //console.log('genPageData')
  // //console.log(pageData)
   if(basicDataSt){
      if(basicDataSt.pageData){
        //pageData=basicDataSt.pageData
      }   
   }
   return pageData
}
const [showKeyPad,setShowKeyPad]=React.useState({
   show:false,
   inputStateName:"",
   myI:""
})

const [pageData,setPageData]=React.useState(genPageData())

const [capcha,setCapcha]=React.useState(null)

const {shopForgetPasswordForm,
       shopLogInPage,
       shopLogInForm,
       userLogInForm,
       userChangePasswordForm,
       shopSignUpForm,
       shopPaymentShopLogInForm,
       requestRecoveryPasswordForm,
       shopMonthlyFeeForm,
       placeHolderForm,
       tryAccountForm,
       transferForm
      }=pageData

//=============================
//===========================




//===========================

   const allRef={
      shopName:React.createRef(),
      username:React.createRef(),
      password:React.createRef(),
      confirmPassword:React.createRef(),
      newPassword1:React.createRef(),
      newPassword2:React.createRef(),
      ownerName:React.createRef(),
      ownerEmail:React.createRef(),
      dataLanguage:React.createRef(),
      capcha:React.createRef()
   }

   const userLogInNextRef={
      username:()=>allRef.password.current.focus(),
      password:()=>refSubmit.current.focus()
   }

   const shopLogInNextRef={
      shopName:()=>allRef.password.current.focus(),
      password:()=>allRef.capcha.current.focus(),
      capcha:()=>refSubmit.current.focus()
   }

   const requestRecoveryPasswordNextRef={
      shopName:()=>allRef.ownerEmail.current.focus(),
      ownerEmail:()=>allRef.capcha.current.focus(),
      capcha:()=>refSubmit.current.focus()
   }
   const userChangePasswordNextRef={
      username:()=>allRef.password.current.focus(),
      password:()=>allRef.newPassword1.current.focus(),
      newPassword1:()=>allRef.newPassword2.current.focus(),
      newPassword2:()=>refSubmit.current.focus()
   }

   const shopSignUpNextRef={
      shopName:()=>allRef.ownerName.current.focus(),
      ownerName:()=>allRef.ownerEmail.current.focus(),
      ownerEmail:()=>allRef.dataLanguage.current.focus(),
      dataLanguage:()=>allRef.capcha.current.focus(),
      capcha:()=>refSubmit.current.focus()
   }

   const shopForgetPasswordNextRef={
      recoveryPassword:()=>allRef.shopName.current.focus(),
      shopName:()=>allRef.password.current.focus(),
      password:()=>allRef.confirmPassword.current.focus(),
      confirmPassword:()=>refSubmit.current.focus(),
   }


   const genFrame=()=>{
      return "xc6 lc8 mc10 sc12"
   }

   const genFrameWithBorder=()=>{
      return "xc6 lc8 mc10 sc12 bd-pureWhite"
   }

   const [userChangePasswordState,setUserChangePasswordState]=React.useState({
      username:"",
      password:"",
      newPassword1:"",
      newPassword2:""
   })

   const [shopPaymentShopLogInState,setShopPaymentShopLogInState]=React.useState({
      shopName:"",
      password:""
   })

   const [shopState,setShopState]=React.useState({
      shopName:"",
      password:""
   })

   const [userState,setUserState]=React.useState({
      username:"",
      password:""
   })
   const [requestRecoveryPasswordState,setRequestRecoveryPasswordState]=React.useState({
      shopName:"",
      ownerEmail:""
   })
   const [shopSignUpState,setShopSignUpState]=React.useState({
      shopName:"",
      ownerName:"",
      ownerEmail:"",
      dataLanguage:"",
   })

   const [shopForgetPasswordState,setShopForgetPasswordState]=React.useState({
      recoveryPassword:"",
      shopName:"",
      password:"",
      confirmPassword:""
   })

   const [shopMonthlyFeeState,setShopMonthlyFeeState]=React.useState({
      shopName:"",
      token:"",
      expirationDate:"",
      monthlyFee:"",
      photoUrl1:[''],
      file1:null
   })

   const refReCaptcha=React.useRef()

   const [hidePassword,setHidePassword]=React.useState(true)

  //====================================
  const [showModalError,setShowModalError]=React.useState(false)
  
  const [message,setMessage]=React.useState(null)
  //------------------------------------

  const [showUserChangePassword,setShowUserChangePassword]=React.useState(false)
  const [showShopSignUp,setShowShopSignUp]=React.useState(false)


  const [showShopForgetPassword,setShowShopForgetPassword]=React.useState(false)
  const [showRequestRecoveryPassword,setShowRequestRecoveryPassword]=React.useState(false)

  const [showShopMonthlyFee,setShowShopMonthlyFee]=React.useState(false)
  const [showShopPaymentShopLogIn,setShowShopPaymentShopLogIn]=React.useState(false)
  const [Language,setLanguage]=React.useState("Thai")
  //====================================
  const {changeArrayFile}=photoUtil

  const [showImage1,setShowImage1]=React.useState(true)
  const [arrayFile1,setArrayFile1]=React.useState([])
  const [fileUrl1,setFileUrl1]=React.useState([])

  React.useEffect(()=>{
      //console.log('fileUrl1')
      //console.log(fileUrl1)
  },[fileUrl1])

  React.useEffect(()=>{
  //console.log('useEffect ArrayFile1')
  //console.log(arrayFile1)
          
  changeArrayFile({ arrayFile:arrayFile1,
      fileUrl:fileUrl1,
      setFileUrl:setFileUrl1,
      inputState:shopMonthlyFeeState,
      setInputState:setShopMonthlyFeeState,
      keyName:"photoUrl1",
      //fileName,
      //serverFolder,
      //fileName:"file",
      //serverFolder:"/upload/customer",
      setShowImage:setShowImage1})
          
  },[arrayFile1])

  //====================================
  const submitShopLogIn=async(inputState,setInputState)=>{

   try{
      if (validateCaptcha(capcha)==false) {
         throw {message:"Invalid Capcha"}
      }

      const result=await axios.post(`/p35shop/shoplogin`,inputState)
         
      localStorage.setItem('shopauthorization',
      `b ${result.data.shopToken}`)
      localStorage.setItem('expirationDate',
      `${result.data.expirationDate}`)

      setReloadCheckToken(true)
      
      setShopState({
         shopName:"",password:""
      })
      
      setUserState({
         username:"",password:""
      })

      setCapcha("")
      window.location.reload();

      
   }
   catch(error){
      setCapcha("")
      setMessage(catchErrorToMessage(error))
      setShowModalError(true)
   }
  }
  //-------------------------------
  
  //-------------------------------
  
  const submitUserLogIn=(inputState,setInputState)=>{

   const tempKey=Object.keys(inputState)

   axios.post(`/p35user/login`,inputState,myheader)
   .then(result=>{
      //console.log('result')
     localStorage.setItem('userauthorization',
       `b ${result.data.userToken}`)
     localStorage.setItem("username",
       result.data.username)
       setReloadCheckToken(true)
        
       setShopState({
         shopName:"",password:""
      })
      
      setUserState({
         username:"",password:""
      })
      window.location.reload();

   })
   .catch(error=>{
       setMessage(catchErrorToMessage(error))
       setShowModalError(true)
   })
 }
//============================
const submitUserChangePassword=(inputState,setInputState)=>{

   axios.post(`/p35user/changepassword`,inputState,myheader)
   .then(result=>{
      //console.log('result')
      localStorage.removeItem('userauthorization');
      localStorage.removeItem('username');
      
      setUserChangePasswordState({
         username:"",
         password:"",
         newPassword1:"",
         newPassword2:""
       })
      
      setShowUserChangePassword(false)
      //setReloadCheckToken(true)  
      window.location.reload();

   })
   .catch(error=>{
       setMessage(catchErrorToMessage(error))
       setShowModalError(true)
   })

}
//=================================

const submitShopPayment=(inputState)=>{

   const myheader={headers: {'Content-Type': 'application/json',
   'Shopauthorization':`b ${shopMonthlyFeeState.token}`,
   }}

   //console.log('submitShopPayment')
   //console.log(shopMonthlyFeeState)

   const {file1,photoUrl1}=inputState

   let tempFormInputState2

   if(!file1){return }
   if(!file1.length){return }
   if(!file1[0].name){return }
  
   tempFormInputState2=addSingleFileNameInsteadOfPhotoUrl({  
       file1,photoUrl1
   })  

   //console.log('tempFormInputState2')
   //console.log(tempFormInputState2)

   const fd=genFD({
       inputState:tempFormInputState2,
       template:{
           file1:StateTemplate.shopPaymentState.file1,
           photoUrl1:StateTemplate.shopPaymentState.photoUrl1,
       }
   })

   axios.post(`/p35shoppayment/payshopfee`,fd,myheader)
   .then(result=>{
       //console.log('result')
       //console.log(result)
       setShowShopMonthlyFee(false)
       setShowShopPaymentShopLogIn(false)
      window.location.reload()
   })
   .catch(error=>{
       //setMessage(catchErrorToMessage(error))
       //setShowModalError(true)
   })
}


//-----------------------------
const submitShopSignUp=async(inputState)=>{
      //console.log('submitShopSignUp')
      //console.log(inputState)
      try{
      
         if (validateCaptcha(capcha)==false) {
            throw {message:"Invalid capcha"}
         }

         await axios.post(`/p35shop/shopsignup`,inputState,myheader)
       
            //console.log('result')      /
         setShopSignUpState({
            shopName:"",
            //password:"",
            //confirmPassword:"",
            ownerName:"",
            ownerEmail:""
         })
         setCapcha("")
         setShowShopSignUp(false)
         //setReloadCheckToken(true)  
         window.location.reload();
         
        
      }
      catch(error) {
         setCapcha("")
         setMessage(catchErrorToMessage(error))
         setShowModalError(true)

      }
 
}
//------------------------------

const submitCreateShopPassword=(inputState)=>{

   if(inputState.confirmPassword!==inputState.password){
         setShopForgetPasswordState({
            ...shopForgetPasswordState,
            password:"",
            confirmPassword:""
         })

         setMessage("recheck your password and confirm password")
         setShowModalError(true)
   }
   else{

         axios.post(`/p35shop/createshoppassword`,inputState)
         .then(result=>{
            //console.log('result.......resetshoppassword')
            //console.log(result)
            //refReCaptcha.current.reset()
            //setShowReCaptcha(true)
            setShopForgetPasswordState({
               shopName:"",
               password:"",
               recoveryPassword:"",
               confirmPassword:""
            })
            setHidePassword(true)
            setShowShopForgetPassword(false)
            setShowRequestRecoveryPassword(false)
            window.location.reload()
         })
         .catch(error=>{
            setMessage(catchErrorToMessage(error))
            setShowModalError(true)
         })
   }
}
//-----------------------------

//----------------------------
const submitShopLogInForPayment=async(inputState)=>{
   //console.log('shopLogInForPayment......')
   try {
      if (validateCaptcha(capcha)==false) {
         throw {message:"Invalid Capcha"}
      }

      const result=await axios.post(`/p35shop/shoploginforpayment`,inputState)
      const {shopName,monthlyFee,expirationDate,token}=result.data
   
       setShopMonthlyFeeState({
           ...shopMonthlyFeeState,
           shopName:shopName,
           monthlyFee:monthlyFee,
           expirationDate:expirationDate,
           token:token
       })
   
       setShopState({
           shopName:"",password:""
       })
       setCapcha("")
       setShowShopMonthlyFee(true)

   }
   catch (error){
      setCapcha("")
      setMessage(catchErrorToMessage(error))
      setShowModalError(true)
   }
}


//-----------------------------
const requestRecoveryPassword=async(inputState)=>{

   //console.log('requestRecoveryPassword')
   //console.log(inputState)'
   try{
      if (validateCaptcha(capcha)==false) {
         throw {message:"Invalid Capcha"}
      }
      await axios.post(`/p35shop/resetshoppassword`,inputState)
      setRequestRecoveryPasswordState({
         shopName:"",
         ownerEmail:"",
      })
      setCapcha("")
      setShowShopForgetPassword(true)
   }
   catch(error){
      setCapcha("")
      setMessage(catchErrorToMessage(error))
      setShowModalError(true)
   }

}
//=============================
const charFunc=(myChar)=>{
   const {show,inputStateName,myI}=showKeyPad
   if(inputStateName=="shopState"){
      setShopState ({
         ...shopState,
         [myI]:shopState[myI]+myChar
      })
   }
   
   else if(inputStateName=="userState"){
      setUserState({
         ...userState,
         [myI]:userState[myI]+myChar
      })
   }
   else if(inputStateName=="shopSignUpState"){
      setShopSignUpState({
         ...shopSignUpState,
         [myI]:shopSignUpState[myI]+myChar
      })
   }
   else if(inputStateName=="shopForgetPasswordState"){
      setShopForgetPasswordState({
         ...shopForgetPasswordState,
         [myI]:shopForgetPasswordState[myI]+myChar
      })
   }
   else if(inputStateName=="requestRecoveryPasswordState"){
      setRequestRecoveryPasswordState({
         ...requestRecoveryPasswordState,
         [myI]:requestRecoveryPasswordState[myI]+myChar
      })
   }
 
   else if(inputStateName=="userChangePasswordState"){
      setUserChangePasswordState({
         ...userChangePasswordState,
         [myI]:userChangePasswordState[myI]+myChar
      })
   }
   
}
const delFunc=()=>{
   const {show,inputStateName,myI}=showKeyPad

   if(inputStateName=="shopState"){
      let tempStr=shopState[myI].slice(0, -1)
      setShopState ({
         ...shopState,
         [myI]:tempStr
      })
   }
   else if(inputStateName=="userState"){
      let tempStr=userState[myI].slice(0, -1)
      setUserState({
         ...userState,
         [myI]:tempStr
      })
   }
   else if(inputStateName=="shopSignUpState"){
      let tempStr=shopSignUpState[myI].slice(0, -1)
      setShopSignUpState({
         ...shopSignUpState,
         [myI]:tempStr
      })
   }
   else if(inputStateName=="shopForgetPasswordState"){
      let tempStr=shopForgetPasswordState[myI].slice(0, -1)
      setShopForgetPasswordState({
         ...shopForgetPasswordState,
         [myI]:tempStr
      })
   }
   else if(inputStateName=="requestRecoveryPasswordState"){
      let tempStr=requestRecoveryPasswordState[myI].slice(0, -1)
      setRequestRecoveryPasswordState({
         ...requestRecoveryPasswordState,
         [myI]:tempStr
      })
   }
   else if(inputStateName=="userChangePasswordState"){
      let tempStr=userChangePasswordState[myI].slice(0, -1)
      setUserChangePasswordState({
         ...userChangePasswordState,
         [myI]:tempStr
      })
   }
}

const entFunc=()=>{
   const {show,inputStateName,myI}=showKeyPad

   if(inputStateName=="shopState"){
      setShowKeyPad ({
         show:false,
         inputStateName:"",
         myI:""
      })
      shopLogInNextRef[myI]()
   }
   else if(inputStateName=="userState"){
      setShowKeyPad ({
         show:false,
         inputStateName:"",
         myI:""
      })
      userLogInNextRef[myI]()
   }
   else if(inputStateName=="shopSignUpState"){
      setShowKeyPad ({
         show:false,
         inputStateName:"",
         myI:""
      })
      shopSignUpNextRef[myI]()
   }
   else if(inputStateName=="shopForgetPasswordState"){
      setShowKeyPad ({
         show:false,
         inputStateName:"",
         myI:""
      })
      shopForgetPasswordNextRef[myI]()
   }
   else if(inputStateName=="requestRecoveryPasswordState"){
      setShowKeyPad ({
         show:false,
         inputStateName:"",
         myI:""
      })
      requestRecoveryPasswordNextRef[myI]()
   }
   else if(inputStateName=="userChangePasswordState"){
      setShowKeyPad ({
         show:false,
         inputStateName:"",
         myI:""
      })
      userChangePasswordNextRef[myI]()
   }
}

//-----------------------------
const renderKeyPad=()=>{
   return <MyKeyPad
      charFunc={charFunc}
      delFunc={delFunc}
      entFunc={entFunc}
      hidePassword={hidePassword}
      setHidePassword={setHidePassword}
   />
  
}



//-----------------------------
const renderLogin=({pageForm,setInputState,inputState,
                    inputStateName,
                    submitFunc,submitCancel,nextRef,
                    useCapcha,useTryUserAccount,
                    useTryShopAccount})=>{

   const pageFormKey=Object.keys(pageForm)
   const inputStateKey=Object.keys(inputState)
   //console.log(pageForm)
   const renderInput=(i,idx)=>{
      if(i=="password"||i=="newPassword1"||
         i=="newPassword2"||i=="confirmPassword"||
         i=="recoveryPassword"
         ){
         return (
         <div key={idx} 
            className="flex-center-center jc-start w-100"
            style={{marginBottom:"1rem"}}
        >
            <div className="xc12" style={{display:"none"}}>
               <input
                  type="password"
                  value="dummy"
               />
            </div>

            <div className="xc4" 
                 style={{display:"flex",justifyContent:"space-between"}}
            > 
                 {pageForm[i]}
                 <FaKeyboard className="sm-icon"
                     onClick={e=>{

                        let tempInputStateName=""
                        if(!setShowKeyPad.show){
                           tempInputStateName=inputStateName
                        }

                        setShowKeyPad({
                           ...showKeyPad,
                           show:!showKeyPad.show,
                           inputStateName:tempInputStateName,
                           myI:i
                        })
                     }}
                 />
            </div>
            <div className="xc8"
                 style={{position:"relative"}}
            >
                <input
                   type="text"//type={hidePassword?"password":"text"}
                    //value={inputState[i]}
                    value={hidePassword?showPassword(inputState[i]):inputState[i]}
                    ref={allRef[i]}
                    onChange={e=>{}}
                  
                    onKeyDown={e=>{
                        
                           let tempValue=""

                           if(e.key||e.key===0){
                              if(e.key=="Enter"){
                                 nextRef[i]()
                                 tempValue=inputState[i]
                              }
                              else if(e.key=="Backspace"){
                                 let tempStr=inputState[i].slice(0, -1)
                                 tempValue=tempStr

                              }
                              else {
                                 const myReg=new RegExp('[a-zA-Z0-9@#&_]', 'i')
                                 const tempStr=e.key.toString()
                                 const strLength=e.key.length
                                 //console.log(myReg.test(eKey))
                                 if(myReg.test(tempStr)&&strLength==1){
                                    tempValue=inputState[i]+e.key
                                 }
                                 else {
                                    tempValue=inputState[i]
                                 }
                              }
                              setInputState({
                                 ...inputState,
                                 [i]:tempValue
                              })
                           }
                         
                           /*
                           if(tempValue===""){
                              if(e.key=="Enter"||e.key=="Backspace"){}
                              else {
                                 setShowKeyPad({
                                    ...showKeyPad,
                                    show:true
                                 })
                              }
                           }
                           */

                     }}
                     disabled={showKeyPad.show?"disabled":""}
                />
                    {
                    hidePassword
                    ?<MdVisibilityOff 
                       className="sm-icon" 
                       style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                       onClick={e=>{
                       setHidePassword(!hidePassword)
                       }}
                    />
                    :<MdVisibility 
                       className="sm-icon" 
                       style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                       onClick={e=>{
                       setHidePassword(!hidePassword)
                       }}
                    />
                    }
  
            </div>
           
         </div>
         )
      }
      else if(i=="dataLanguage"){
         return(
            <div key={idx}
            className="flex-center-center jc-start w-100"
            style={{marginBottom:"1rem"}}
         >
            <div className="xc4">
               {pageForm[i]}
            </div>
            <div className="xc8">
               <select
                  ref={allRef[i]}

                  onChange={e=>{setInputState({
                     ...inputState,
                     [i]:e.target.value
                  })}}

                  onKeyDown={e=>{
                     if(e.key=="Enter"){
                        //console.log(nextRef[i])
                        nextRef[i]()
                     }
                  }}
               >
                  <option>...</option>
                  {
                   ["English","ไทย"].map((q,idxQ)=>{
                      return(
                         <option key={idxQ}>
                            {q}
                        </option>
                      )
                   })  
                  }

               </select>
               {/*
               <input
                     ref={allRef[i]}
                     type={(i=="id"//||
                           //i=="branchId"
                           )
                           ?"number":"text"}
                     value={inputState[i]}
                     onChange={e=>{setInputState({
                        ...inputState,
                        [i]:e.target.value
                     })}}
                     
                     onKeyDown={e=>{
                        if(e.key=="Enter"){
                           //console.log(nextRef[i])
                           nextRef[i]()
                        }
                     }}

               />
                  */}
            </div>
            
         </div>
         )
      }
      else{
         return(
            
            <div key={idx}
               className="flex-center-center jc-start w-100"
               style={{marginBottom:"1rem"}}
            >
               
               <div className="xc4">
                  {pageForm[i]}
               </div>
               <div className="xc8">
                  <input
                        ref={allRef[i]}
                        type={(i=="id"//||
                              //i=="branchId"
                              )
                              ?"number":"text"}
                        value={inputState[i]}
                        onChange={e=>{setInputState({
                           ...inputState,
                           [i]:e.target.value
                        })}}
                        
                        onKeyDown={e=>{
                           if(e.key=="Enter"){
                              //console.log(nextRef[i])
                              nextRef[i]()
                           }
                        }}

                        disabled={ (
                                    //i=="branchId"||
                                    i=="userLevel")
                                   ?"disabled"
                                   :""
                                 }

                  />
               </div>
               
            </div>
         )
      }
   }

   return (
   <div className="w-100 bd-pureWhite" style={{padding:"1rem"}}>
         <div className="flex-center-center w-100"
            style={{marginBottom:"1rem"}}
         >
            <h3>{pageForm[pageFormKey[0]]}</h3>
         </div>

         {
            inputStateKey.map((i,idx)=>{
               return renderInput(i,idx)
            })
         }

         {
          
          <div className='flex-center-center'
               style={
                     useCapcha
                     ?{marginBottom:"1rem",alignItems:"flex-start",}
                     :{marginBottom:"1rem",alignItems:"flex-start",
                        visibility:"hidden",
                        height:"0.2rem"
                     }
                     }
          >
               <div className='xc4 lc4 mc4 sc12'>
                  <LoadCanvasTemplate/>
               </div>
               <div className='xc8 lc8 mc8 sc12'>
                  <input
                        type='text'
                        ref={allRef.capcha}
                        placeholder={placeHolderForm.placeHolder}
                        value={capcha}
                        onKeyDown={e=>{
                           if(e.key=="Enter"){
                              //console.log(nextRef[i])
                              nextRef["capcha"]()
                           }
                        }}
                        onChange={e=>{
                           setCapcha(e.target.value)
                        }}
                  />
               </div>

          </div>
         }

         <div className="flex-center-center jc-end jc-start w-100"
              style={{justifyContent:"space-between"}}
         >
               {useTryShopAccount||useTryUserAccount
                 ?<div className='bill-p'
                  onClick={e=>{
                     if(useTryShopAccount){
                        setInputState({
                           ...inputState,
                           shopName:process.env.REACT_APP_SHOPNAME,
                           password:process.env.REACT_APP_SHOP_PASSWORD,
                        })
                     }
                     else if(useTryUserAccount){
                        setInputState({
                           ...inputState,
                           username:process.env.REACT_APP_USERNAME,
                           password:process.env.REACT_APP_PASSWORD,
                        })
                     }
                  }}
                 >
                  {tryAccountForm.tryAccount}
                 </div>
                 :<div style={{visibility:"hidden"}}>
                    test
                 </div>
               }
               
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                     <button
                        ref={refSubmit}
                        onClick={e=>submitFunc(inputState,setInputState)}
                     >
                        <FaCheck/>
                     </button>
                     {submitCancel&&
                        <button
                           ref={refCancel}
                           onClick={e=>submitCancel()}
                        >
                           <FaBan/>
                        </button>  
                     }
                  </div>
               
         </div>
   </div>
   )
   
}

const renderAvatar=()=>{
   return user
      ?<div className="">
            <div className="w-100 flex-center-center">
               <FaUserCircle
                  style={{fontSize:"4.5rem",margin:"auto"}}
               />
            </div>
            <div 
               style={{textAlign:"center",fontSize:"1.5rem"}}>
                  {`${user.username}`}
            </div>
            <div className="divlink "
                 style={{textAlign:"center"}}
               onClick={e=>{
                  setShowUserChangePassword(true)
            }}>
                  {shopLogInPage.changeUserPassword}
            </div>
            <div style={{visibility:"hidden",height:"1rem"}}>
                  <LoadCanvasTemplate/>
            </div>
            
      </div>
      :<div style={{visibility:"hidden",height:"1rem"}}>
         <LoadCanvasTemplate/>
      </div>
}


const renderUserLogIn=()=>{
   return (
      <div className={genFrame()} style={{padding:"0rem"}}>
            {
            renderLogin({

                  pageForm:userLogInForm,
                  setInputState:setUserState,
                  inputState:userState,
                  inputStateName:"userState",
                  submitFunc:submitUserLogIn,
                  nextRef:userLogInNextRef,
                  useCapcha:false,
                  useTryUserAccount:true,
                  useTryShopAccount:false
            })
            }
      </div>
   )
}

const renderShopSignUp=()=>{
   //loadCaptchaEnginge(8) 
   return(
      <div className={genFrame()}>
         {
               renderLogin({
                     pageForm:shopSignUpForm,
                     setInputState:setShopSignUpState,
                     inputState:shopSignUpState,
                     inputStateName:"shopSignUpState",

                     submitFunc:submitShopSignUp,
                     nextRef:shopSignUpNextRef,
                     submitCancel:()=>{
                        setShopSignUpState({
                           shopName:"",
                           ownerName:"",
                           ownerEmail:"",
                           dataLanguage:""
                        })
                        setShowShopSignUp(false)
                     },
                     useCapcha:true
               })
            }
      

      </div>
   )
}


const renderShopForgetPassword=()=>{
   //console.log('showShopForgetPass')
   //console.log(process.env.REACT_APP_SITE_KEY)
      return (
         <div className={genFrame()}>
         {
            renderLogin({
                  pageForm:shopForgetPasswordForm,
                  setInputState:setShopForgetPasswordState,
                  inputState:shopForgetPasswordState,
                  inputStateName:"shopForgetPasswordState",

                  submitFunc:submitCreateShopPassword,
                  nextRef:shopForgetPasswordNextRef,
                  submitCancel:()=>{
                     setShopForgetPasswordState({
                        recoveryPassword:"",
                        shopName:"",
                        password:"",
                        confirmPassword:""
                     })
                     setShowShopForgetPassword(false)
                  },
                  useCapcha:false 
            })
         }
      </div>
   )
}

const renderRequestRecoveryPassword=()=>{
   return(
      <div className={genFrame()}>
      {
         renderLogin({
               pageForm:requestRecoveryPasswordForm,
               setInputState:setRequestRecoveryPasswordState,
               inputState:requestRecoveryPasswordState,
               inputStateName:"requestRecoveryPasswordState",
               submitFunc:requestRecoveryPassword,//submitCreateShopPassword,
               nextRef:requestRecoveryPasswordNextRef,
               submitCancel:()=>{
                  setRequestRecoveryPasswordState({
                     shopName:"",
                     ownerEmail:""
                  })
                  setShowRequestRecoveryPassword(false)
               },
               useCapcha:true
         })
      }
      </div>
   )
}


const renderShopMonthlyFee=()=>{
   let inputStateKey=Object.keys(shopMonthlyFeeState)
   let tempArray=[]
   inputStateKey.map(i=>{
      if((i=="token")||(i=="photoUrl1")||(i=="file1")){
      }
      else{
         tempArray=[...tempArray,i]
      }
   })
   inputStateKey=tempArray


   return(

           <div className={genFrameWithBorder()} style={{padding:"1rem"}}>
              <div className="flex-center-center w-100"
                 style={{marginBottom:"1rem"}}
              >
                 <h3>{shopMonthlyFeeForm.formHead}</h3>
              </div>

              <div className="flex-center-center jc-start w-100">
                  <div className="xc12" 
                       style={{fontSize:"1.2rem",color:"green"}}
                  >
                     {transferForm.instruction}
                  </div>
               </div>
              <div className="flex-center-center jc-start w-100">
                  <div className="xc4" 
                      style={{fontSize:"1.2rem",color:"green"}}
                  >
                     {transferForm.bankName}
                  </div>
                  <div className="xc8"  
                      style={{fontSize:"1.2rem",color:"green"}}
                  >
                     {process.env.REACT_APP_BANK_NAME}
                  </div>
              </div>
              <div className="flex-center-center jc-start w-100">
                  <div className="xc4" 
                     style={{fontSize:"1.2rem",color:"green"}}
                  >
                     {transferForm.accountName}
                  </div>
                  <div className="xc8"  
                      style={{fontSize:"1.2rem",color:"green"}}
                  >
                     {process.env.REACT_APP_ACCOUNT_NAME}
                  </div>
              </div>
              <div className="flex-center-center jc-start w-100"
                   style={{marginBottom:"2rem"}}
              >
                  <div className="xc4"
                      style={{fontSize:"1.2rem",color:"green"}}
                  >
                     {transferForm.accountNumber}
                  </div>
                  <div className="xc8"  
                      style={{fontSize:"1.2rem",color:"green"}}
                  >
                     {process.env.REACT_APP_ACCOUNT_NUMBER}
                  </div>
              </div>

              {
                 inputStateKey.map((i,idx)=>{
                    return(
                       <div key={idx} 
                          className="flex-center-center jc-start w-100"
                          style={{marginBottom:"1rem"}}
                       >
                             <div className="xc4">
                                {shopMonthlyFeeForm[i]}
                             </div>
                             <div className="xc8"
                                style={{position:"relative"}}
                             >
                                {i=="expirationDate"
                                ?new Date(shopMonthlyFeeState[i]).toLocaleString('en-GB').substring(0,17)
                                :shopMonthlyFeeState[i]
                                }
                             </div>
                       </div>
                    )    
                       
                 })
              }

                  
              <div className="xc12 form-row h-100"
                    style={{justifyContent:"space-around"}}>
                    <div className="xc4 sc10 bgc-darkGray bd-lightGray h-100">
                          {
                          showImage1
                          ?<GallerySinglePhoto_add 
                             fileUrl={fileUrl1}
                             arrayFile={arrayFile1}
                             setArrayFile={setArrayFile1}
                             keyName={"photoUrl1"}

                             setShowImage={setShowImage1}
                             inputState={shopMonthlyFeeState}
                             setInputState={setShopMonthlyFeeState}
                          />
                          :null
                          }   
                    </div>                       
              </div>




              <div className="flex-center-center jc-end jc-start w-100"
              >
                 <button
                    ref={refCancel}
                    onClick={e=>{
                       submitShopPayment(shopMonthlyFeeState)
                    }}
                 >
                    <FaCheck/>
                 </button>  
                 <button
                    //ref={refSubmit}
                    onClick={e=>{
                     setShowShopPaymentShopLogIn(false)
                       setShowShopMonthlyFee(false)
                    }}
                 >
                    <FaBan/>
                 </button>
               
              </div>       

           </div>

   )
}

const renderShopPaymentShopLogIn=()=>{
   return(
      <div className={genFrame()}>
      {
         renderLogin({
               pageForm:shopPaymentShopLogInForm,
               setInputState:setShopState,
               inputState:shopState,
               inputStateName:"shopState",
               submitFunc:submitShopLogInForPayment,
               nextRef:shopLogInNextRef,
               submitCancel:()=>{
                  setShopState({
                     shopName:"",
                     password:"",
                  })
                  setShowShopPaymentShopLogIn(false)
               },
               useCapcha:true
         })
      }
      </div>
   )
}

const renderShopLogIn=()=>{
  
   return (
         <div className={genFrame()}>
               {
               renderLogin({
                     pageForm:shopLogInForm,
                     setInputState:setShopState,
                     inputState:shopState,
                     inputStateName:"shopState",
                     submitFunc:submitShopLogIn,
                     nextRef:shopLogInNextRef,
                     useCapcha:true,
                     useTryShopAccount:true
               })
              }

               <div className="bill-p"
                  style={{display:"flex",justifyContent:"flex-start",alignItems:"baseline",
                           margin:"1rem 0"}}
                  onClick={e=>{
                     setShowShopSignUp(true)
                  }}
               >
                     <MdAddCircle style={{marginLeft:"-1rem"}}/>
                     <BsShop style={{fontSize:"2rem"}}/>

                     <div style={{marginLeft:"1rem"}}>
                        {shopLogInPage.createNewShopAccount}
                     </div>
               </div>

               <div className="bill-p"
                  style={{display:"flex",justifyContent:"flex-start",alignItems:"baseline",
                           margin:"1rem 0"}}

                  onClick={e=>{
                     //setShowShopForgetPassword(true)
                     setShowRequestRecoveryPassword(true)
                     //setShowReCaptcha(true)
                     //setShowResetRequest(true)
                  }}
               >
                  <MdVpnKey style={{marginLeft:"-1rem"}}/>
                  <BsShop   style={{fontSize:"2rem"}}/>

                  <div style={{marginLeft:"1rem"}}>
                     {shopLogInPage.forgetShopPassword}
                  </div>
               </div>

               <div className="bill-p"
                  style={{display:"flex",justifyContent:"flex-start",alignItems:"baseline",
                           margin:"1rem 0"}}

                  onClick={e=>{
                     setShowShopPaymentShopLogIn(true)
                  }}
               >
                  <FaMoneyBillWave style={{marginLeft:"-1rem"}}/>
                  <BsShop   style={{fontSize:"2rem"}}/>

                  <div style={{marginLeft:"1rem"}}>
                     {shopLogInPage.shopPayment}
                  </div>
               </div>
         </div>
      )
}

//------------------------
const renderLangSwitch=()=>{
   return (
      <div style={{position:"fixed",top:"0",left:"0"}}>
         <div style={{display:"flex",alignItems:"baseline",zIndex:"500"}}>
            <div className='bill-p'
               onClick={e=>{
                  setPageData(LogInLangTemplate.English)
                  setLanguage("English")
               }}
            >Eng</div>
            <div>/</div>
            <div className='bill-p'
               onClick={e=>{
                  setPageData(LogInLangTemplate.Thai)
                  setLanguage("Thai")
               }}
            >ไทย</div>
         </div>
      </div>
   )
}

const renderUserChangePassword=()=>{
   return(
      <div className={genFrame()}>
      {
         renderLogin({
               pageForm:userChangePasswordForm,
               setInputState:setUserChangePasswordState,
               inputState:userChangePasswordState,
               inputStateName:"userChangePasswordState",
               submitFunc:submitUserChangePassword,
               nextRef:userChangePasswordNextRef,
               submitCancel:()=>{
                  setUserChangePasswordState({
                     username:"",
                     password:"",
                     newPassword1:"",
                     newPassword2:""
                  })
                  setShowUserChangePassword(false)
               },
               useCapcha:false
         })
      }
      </div>
   )
}

//=========================
const renderBody=()=>{
  

   if(tokenSt.haveShopToken&&
      tokenSt.haveUserToken&&
      showUserChangePassword
   ){
      return(
         renderUserChangePassword()
   )}
   else if(tokenSt.haveShopToken&&
           tokenSt.haveUserToken
   ){
      return(
         renderAvatar()
   )}
   else if(tokenSt.haveShopToken){
      return(
         renderUserLogIn()
   )}
   else if(showShopSignUp){
      return(
         renderShopSignUp()
   )}
   
   else if(showShopForgetPassword&&
           showRequestRecoveryPassword
      ){
      return renderShopForgetPassword()
   }
   else if(showRequestRecoveryPassword){
      return renderRequestRecoveryPassword()
   }
   else if(showShopMonthlyFee&&
          showShopPaymentShopLogIn
   ){
      return(
            renderShopMonthlyFee()
   )}
   else if(showShopPaymentShopLogIn){
      return renderShopPaymentShopLogIn()
   }
   else {
      return renderShopLogIn()
   }
}   
//=======================

//=======================
return (
   <div style={{height:"100vh",width:"100vw",position:"relative"}}
        className="flex-center-center"
   >
         <div style={{position:"fixed",
            width:"200vw",height:"15vh",
            top:"-2rem",left:"-5rem",
            transform: "rotate(15deg)",
            backgroundColor:"#ff9933",
         }}
         >
            
         </div>

         <div className="w-100 flex-center-start" 
             style={{height:"100%",paddingTop:"15vh"}}
         >         
         {
           renderBody()
         }
         </div>

         {pageData&&
         <LogOutTool
            tokenSt={tokenSt}
            setReloadCheckToken={setReloadCheckToken}
            user={user}
            myheader={myheader}
            useBillWaveIcon={true}
            pageData={pageData}
            Language={Language}
         />
         }
         {
            renderLangSwitch()
         }
         {showKeyPad.show&&
         <div style={{position:"fixed",width:"100vw",
         height:"30vh",bottom:"0",left:"0",
         backgroundColor:"#666"}}>
         {
            renderKeyPad()
         }
         </div>
         }
         {
            showModalError
            ?renderModalError({show:showModalError,
                              setShow:setShowModalError,
                              message,setMessage
                           })
            :null
         }

         <div style={{display:"none"}}>
            <div>
               <Link ref={refHome} to="/home"/>
            </div>
         </div>
        
   </div>

)}

export default LogIn;

/*
 <img alt='robots' src={`https://robohash.org/${3}&200x200`} />




            <button
                  onClick={async(e)=>{
                       const temp=refA.current.getValue()
                        //console.log(temp)
                        refA.current.reset()
                  }}
            >
                  button
            </button>

            <ReCAPTCHA
                  ref={refA}
                  sitekey='6LcCvsQdAAAAAAqWKtgK3Tb6l3LYBjtCVrlaIog0'
                  onChange={value=>{
                        //console.log(value)
                  }}
            />


<div className="w-100 bd-pureWhite" style={{padding:"1rem"}}>
               <div className="flex-center-center w-100"
                  style={{marginBottom:"1rem"}}
               >
                  <h3>{shopLogInPage.requestForRecoveryPassword}</h3>
               </div>
            
               <ReCAPTCHA
                     style={{visibility:showReCaptcha?"visible":"hidden"}}
                     ref={refReCaptcha}
                     sitekey={process.env.REACT_APP_SITE_KEY}//'6LcCvsQdAAAAAAqWKtgK3Tb6l3LYBjtCVrlaIog0'
                     onChange={value=>{
                        //console.log(value)
                        setShowReCaptcha(false)
                     }}
               />
                 
               <div className='w-100'
                     style={{visibility:showReCaptcha?"hidden":"visible"}}
               >

                     <div
                        className="flex-center-center jc-start w-100"
                        style={{marginBottom:"1rem"}}
                     >
                        
                        <div className="xc4">
                           {shopForgetPasswordForm.shopName}
                        </div>
                        <div className="xc8">
                           <input
                              value={shopForgetPasswordState.shopName}
                              style={{width:"100%"}}
                              onChange={e=>{
                                 setShopForgetPasswordState({
                                    ...shopForgetPasswordState,
                                    shopName:e.target.value
                                 })
                              }}
                           />
                        </div>
                     </div>

                     <div
                        style={{marginBottom:"1rem"}}
                     >{`${shopForgetPasswordForm.shopForgetPasswordInfo}`}</div>

               </div>
                     

               <div className="flex-center-center jc-end jc-start w-100"
               >

                  <button
                     ref={refCancel}
                     onClick={e=>{
                        const tempToken=refReCaptcha.current.getValue()
                        //console.log(tempToken)
                        refReCaptcha.current.reset()
                        requestRecoveryPassword({
                              shopName:shopForgetPasswordState.shopName,
                              token:tempToken})
                     }}
                  >
                     <FaCheck/>
                  </button>  
                  <button
                     //ref={refSubmit}
                     onClick={e=>{
                        refReCaptcha.current.reset()
                        setShopForgetPasswordState({
                           ...shopForgetPasswordState,
                           shopName:e.target.value
                        })
                        setShowShopForgetPassword(false)
                        
                     }}
                  >
                     <FaBan/>
                  </button>
           
               </div>               


         </div>









*/
import React from 'react';
import ctUtil from '../../util/ctUtil'
//import uuid from 'react-uuid'
import {MdEdit} from 'react-icons/md';
import {FaPlusSquare,FaMinusSquare,FaCheck,FaBan} from 'react-icons/fa'; 
import LangTemplate from '../../context/LangTemplate'

import console from '../../util/consoleVerify'

import '../Modal.css'
//import tableTemplate from '../../component/table/tableTemplate';
import axios from '../../util/axiosVerify'


const {createTableTemplateForPage,convertTableTemplateObjToArray,
    convertFormTemplateArrayToObj,genLanguageObjFromFormTemplate,
    changeLanguageTableTemplate
  }=ctUtil

function Language({
    pageData,
    basicData,
    setShow,
    submitFunction,
    myheader,

}) {
    //console.log('language')

    const shouldRender= React.useRef(true)
    React.useEffect(()=>{
        return () => {
            //console.log('end.......................................')
            shouldRender.current=false
        } 
    },[])
    
    const genTempLangTemplate=()=>{
        let tempThai=[]
        let tempEnglish=[]

        LangTemplate.map(i=>{
            if(i.formLanguage=="Thai"){
                tempThai=[...tempThai,i]
            }
            if(i.formLanguage=="English"){
                tempEnglish=[...tempEnglish,i]
            }
        })

        return {English:tempEnglish,Thai:tempThai}
    }


    const findLanguageSetting=()=>{
        let tempIdx
        basicData.listOfLanguage.map((i,idx)=>{
            if(i==basicData.languageSetting){
                tempIdx=idx
            }
        })
        return tempIdx
    }


    const tempLangTemplate=genTempLangTemplate()

    const refSubmitForm=React.createRef() //ตกลง
    const refCancelForm=React.createRef() //ยกเลิก    

    //let [filterData,setFilterData]=React.useState(genFilterData(languageObj))
    let [filterData,setFilterData]=React.useState(basicData.listOfLanguage)
    const [langObj,setLangObj]=React.useState(basicData.langTemplate)
    const [showLangObj,setShowLangObj]=React.useState(false)
    const [editIndex,setEditIndex]=React.useState({idx:null,idxJ:null})
    const [editData,setEditData]=React.useState(null)
    const [langIdx,setLangIdx]=React.useState(findLanguageSetting())
    /*
    React.useEffect(()=>{
        //console.log('editIndex')
        //console.log(editIndex)
    },[editIndex])

    React.useEffect(()=>{
        //console.log('filterData')
        //console.log(filterData)
    },[filterData])

    React.useEffect(()=>{
        //console.log('langObj')
        //console.log(langObj)
    },[langObj])
    */
    

  
    const updateLanguage=()=>{
        //console.log('updateLanguage')

        let tempLangTemplate=[]

        if(filterData.length==3){
            const tempFormLanguage=filterData[2]
            
            langObj.map(k=>{
                let tempObj={...k,
                            formLanguage:tempFormLanguage}
                tempLangTemplate=[...tempLangTemplate,tempObj]
            })
        }

        

     
        axios.post('/p35basicdata/updatecustom',
            { id:basicData.id,
              langTemplate:tempLangTemplate, 
              languageSetting:filterData[langIdx]
            },
        myheader,shouldRender)
        .then(result=>{
            //console.log('result')
            setShow(false)
            //setReloadBasicData(true)
            window.location.reload()
        })
        .catch(error=>{  
            //console.log('error')
            setShow(false)
            //const tempObj={showModalError:true,message:catchErrorToMessage(error)}
            //setError(tempObj)  
        })
       
    }
    
//=======================
//=======================
const renderFooter=()=>{
    return(
        <div style={{
                    display:"flex",width:"100%"}}
        >
            <div className="xc8 lc8 sc0"/>
            <div className="xc4 lc4 sc12" style={{display:"flex"}}>
                <div>
                    <button
                        ref={refSubmitForm}
                        onKeyDown={e=>{
                            //if(e.key=="ArrowRight"){
                                //refCancelForm.current.focus()
                            //}
                        }}
                        onClick={e=>{
                            if(submitFunction){
                                setShowLangObj(false)
                                updateLanguage()

                                //submitFunction(filterData,arrayOfChange)
                            }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        ref={refCancelForm}
                        onKeyDown={e=>{
                            //if(e.key=="ArrowLeft"){
                                //refCancelForm.current.focus()
                            //}
                        }}
                        onClick={e=>{
                            setShowLangObj(false)
                            setShow(false)
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>

        </div>   
    )
}
//=====================
const renderBody=()=>{    
    
    return (
    <div 
         className="w-100 flex-center-start jc-start" 
         style={{marginBottom:"0.2rem"}}>
        
         <div className="w-100">
             {
                 filterData.map((i,idx)=>{
                     return(
                         <div className="flex-center-center" 
                              style={{marginBottom:"0.2rem"}}
                               key={idx}>

                            <div  className="w-10">
                                <input
                                    type="checkbox"
                                    checked={langIdx==idx?true:false}
                                    onChange={e=>{
                                        setLangIdx(idx)
                                    }}
                                />
                             </div>
                             <div className="w-10">   
                                {idx==2&&
                                <FaMinusSquare className="sm-icon"
                                    onClick={e=>{
                                       //deleteLanguage(i.id)
                                       setFilterData([
                                           filterData[0],
                                           filterData[1]
                                       ])
                                       setLangObj([])
                                    }}
                                />
                                }
                                {idx<=1
                                ?<FaPlusSquare className="sm-icon"
                                     style={{visibility:filterData.length>2?"hidden":"visible"}}
                                     onClick={e=>{
                                        setFilterData([...filterData,`${i}2`])
                                        setLangObj(tempLangTemplate[i])
                                        //setFilterData
                                        //addLanguage(i)
                                     }}
                                 />
                                 :null
                                }
                            </div> 
                            <div className="w-80">
                                {idx<=1
                                ?<div className='w-100'>
                                    {i}
                                </div>
                                :<div className='w-100'
                                      style={{display:"flex",
                                       }}
                                >
                                    <div className='w-95'
                                    >
                                        <input
                                            value={i}
                                            onChange={e=>{
                                                //console.log(e.target.value)
                                                //console.log(filterData)
                                                setFilterData([filterData[0],filterData[1],e.target.value])

                                            }}
                                        />
                                    </div>
                                    <div className='w-5'>
                                        <MdEdit className="sm-icon"
                                            onClick={e=>{
                                                setShowLangObj(true)
                                                setEditData(i)
                                            }}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                            
                        </div>
                          
                     )
                 })
             }
         </div>
      </div>
    )
 }

 const renderEditLanguage=()=>{
    return langObj.map((i,idx)=>{
         return(
             <div key={idx}
                  className='bd-pureWhite'
                  style={{margin:"0.5rem"}}
                
             >
                 <div>
                     {i.formHead}
                 </div>
                 {
                     i.template.map((j,idxJ)=>{
                         return idx==editIndex.idx&&idxJ==editIndex.idxJ
                            ?<div key={idxJ}
                                    style={{padding:"0.3rem",
                                    paddingLeft:"3rem"
                                    }}
                            >
                                <input
                                    value={editData}
                                    onChange={e=>{
                                        setEditData(e.target.value)
                                    }}
                                    onBlur={e=>{
                                        let temp=[...langObj]
                                        temp[idx]["template"][idxJ]["lb"]=editData
                                        //console.log('onBlur')
                                        //console.log(temp)
                                        //setLangObj(temp)
                                    }}
                                />
                            </div>
                             :<div key={idxJ}
                                style={{padding:"0.3rem",
                                        paddingLeft:"3rem"
                                    }}
                                onClick={e=>{
                                        setEditIndex({idx:idx,idxJ:idxJ})
                                        setEditData(j.lb)
                                    }}
                             >
                                {j.lb}
                             </div>
                     })
                 }
            </div>
         )
     })
 }
 
//======================
//=======================
return (
    <div className="Modal-background">
        <div className="Modal-box" 
             style={{paddingBottom:"1rem",
                     //minWidth:"70%",
                     minHeight:"100px",
                     //width:"100%"
                    }}>
            <div className="Modal-header">
                <div className="flex-center-center">
                    <h5>{pageData.setting.language}</h5>
                </div>
              
            </div>
            <div className="Modal-body">
                <div className="ModalInsideBody"
                >
                    {showLangObj
                    ?renderEditLanguage()
                    :renderBody()
                    }
                </div>

                
            </div>
            {
                renderFooter()
            }
        </div>        
    </div>
  );
}


Language.defaultProps={
    lb:"Form",
    setShow:()=>{},
    submitFunction:null,
    //submitCancel:null
}


export default Language;

/*

  {renderBody(pageDataStandard.shopInfo,"shopInfo")}
                {renderBody(pageDataStandard.setting,"setting")}
                {renderBody(pageDataStandard.basicValue,"basicValue")}

*/
import { FaLastfmSquare } from "react-icons/fa"

const group={
    data0:null,
    
    dataCsv:null,
    count:0,
    lastRecordId:null,
    showRange:true,
    
    widthLeft:30,
    heightTop:30,

    pageNumber:1,
    limitRow:3,
    sort:{id:-1},
    qry:{},
    
    tableTemplateUrl:"p35branch",
    dataUrl:"p35group",
    selectGroup:null,
    groupDataUrl:"p35group",
    

    tableTemplate:null,
    detailTableTemplate:null,
    detailTableTemplateForForm:null,

    tableTemplateName:"groupTableTemplate",
    detailTableTemplateName:"",//"productDetailTableTemplate",
    detailTableTemplateForFormName:"",//"productDetailTableTemplateForForm",//"productDetailTableTemplateForForm",
    showModalFilter:true,
    reloadData:false,
    message:null,
    showModalError:false,
    editData:null,
    badgeState:{
        swapShow:true,swapFunc:()=>{},
        reloadShow:true,reloadFunc:()=>{},
        filterShow:false,filterFunc:()=>{},
        addShow:false,addFunc:()=>{},
        editShow:true,editFunc:()=>{},
        delShow:true,delFunc:()=>{},
        printerShow:false,printerFunc:()=>{},
        csvShow:false,csvFunc:()=>{},
        forwardShow:false,forwardFunc:()=>{},
        bullEyeShow:false,
        unSelectShow:false,
    },


    selectProduct:[],
    
    showAdd:false,
    showModalConfirm:false,
    showModalError:false,
    showModalCsv:false,

    message:null,
    iconActionData:null,
    iconActionDataDetail:null,
    keyName:["photoUrl1"]
}




const filterDataTemplate=group

export default filterDataTemplate

import React from 'react';
import {MainContext} from '../../context/MainContext'
import BackEndNavbar from '../../component/navbar/BackEndNavbar';
import PageComponent  from '../../component/pageComponent/PageComponent';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import console from '../../util/consoleVerify'


function ShopPayment() {

//console.log('ShopPayment')

const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
      mainContextShopPayment,setMainContextShopPayment
}=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
<div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
    <div className="h-5 w-100">
        <BackEndNavbar/>
    </div>
  
    <div className="h-95 w-100">
        <PageComponent
            setReloadBasicData={setReloadBasicData}
            filterDataTemplate={filterDataTemplate}
            filterTemplate={basicDataSt.pageFilter.shopPaymentFilter}
            inputState={inputState.shopPaymentInputState}
            basicDataSt={basicDataSt}
            myheader={myheader}
            
            formTemplate={basicDataSt.modalFormTemplate.shopPayForm}
            editFormTemplate={basicDataSt.modalFormTemplate.shopPayForm}
            
            stateTemplate={StateTemplate.shopPayState}
            pageFilterForm={basicDataSt.pageFilterForm.shopPaymentFilterForm}

            modalFormTemplate={basicDataSt.pageData.ModalForm}
            addFormTitle={basicDataSt.pageData.ModalForm.shopPayAddForm}
            editFormTitle={basicDataSt.pageData.ModalForm.shopPayEditForm}
            bgColor={"#C48F65"}
            calDigit={100}
            controller={"getlimitbyadmin"}
            mainContextData={mainContextShopPayment}
            setMainContextData={setMainContextShopPayment}
        />
    </div>
</div>
)

}
export default ShopPayment;
import React from 'react';
import {Route,Switch,Redirect} from 'react-router-dom';
import {RiHandCoinFill,RiLogoutCircleRLine,RiFileSettingsLine,
        RiLogoutBoxRFill,RiShareForwardLine,RiNumber1,RiNumber0,
        RiFolderLockFill,RiFolderDownloadFill
      } from 'react-icons/ri'
import {FaHome,FaMoneyBill,FaRegMoneyBillAlt,FaMoneyBillWaveมconsole,FaMoneyBillWave,
        FaBullseye,FaFileCsv,FaRegArrowAltCircleDown,FaRegArrowAltCircleUp,
        FaChair,FaRegFolderOpen,FaChevronCircleLeft,FaChevronCircleRight,
        FaBarcode,FaRegArrowAltCircleRight,FaBan,FaWarehouse,
        FaTags,FaFolder,FaSearchLocation,
        FaRegCalendarAlt
} from 'react-icons/fa';
import {MdFastfood,MdPerson,MdTune,MdLogout,
        MdRefresh,MdSwapHoriz,MdSettings,
        MdSearch,MdEdit,MdAddCircle,MdDelete,MdPrint,
        MdChevronLeft,MdChevronRight,MdLastPage,
        MdRadioButtonChecked,MdClose,MdPassword,MdSave,
        MdMergeType,MdRawOn,MdGroups,MdVpnKey,MdCalculate,
        MdOutlineShoppingCart,MdOutlineExposureZero,
        MdPersonSearch,MdOutlinePersonSearch,
        MdExposurePlus1,MdExposureNeg1,MdOutlineLibraryAdd,
        MdInfoOutline
} from 'react-icons/md';
import {GiCook,GiStack,GiBugleCall,GiOpenBook} from 'react-icons/gi'
import {GoPackage} from 'react-icons/go'
import {BsShop} from 'react-icons/bs'
import {TiSortNumericallyOutline} from 'react-icons/ti';
import {SiBookstack,SiAirtable} from 'react-icons/si'
import {VscSaveAs} from 'react-icons/vsc'
import {MainContext} from '../../context/MainContext'
import console from '../../util/consoleVerify'

import '../../App2.css'
import './Ticon.css'

const iconLabelEnglish={
    "FaHome":"Home",
    "MdTune":"Branch",
    "MdPerson":"Partner",
    "MdFastfood":"Product",
    //"FaRegMoneyBillAlt":"บิล",
    "SiBookstack":"Bill",
    "RiLogoutBoxRFill":"Log Out",
    "MdLogout":"Log Out of Shop",
    "MdRefresh":"Reload",
    "MdSwapHoriz":"Swap",
    "MdSettings":"Setting",
    "MdSearch":"Search",
    "MdEdit":"Edit",
    "MdAddCircle":"Add",
    "MdDelete":"Del",
    "MdPrint":"Print",
    "MdRadioButtonChecked":"Select",
    "MdClose":"Close",
    "RiShareForwardLine":"Forward",
    "FaFileCsv":"CSV File",
    "FaRegArrowAltCircleDown":"Down",
    "FaRegArrowAltCircleUp":"Up",
    "MdPassword":"Password",
    "MdSave":"Save New",
    "MdMergeType":"Merge",
    "MdRawOn":"Split",
    "SiAirtable":"Table",
    "FaRegFolderOpen":"Group",
    "GiCook":"Kitchen",
    "FaBullseye":"Drop",
    "FaBarcode":"Barcode",
    "TiSortNumericallyOutline":"Queue",
    "FaRegArrowAltCircleRight":"Pick",
    "RiHandCoinFill":"Close",
    "BsShop":"POS",
    "FaMoneyBillWave":"Monthly Fee",
    "GiStack":"Report",
    "VscSaveAs":"Save Edit",
    "MdVpnKey":"User Logout",
    "MdCalculate":"Calculate",
    "GoPackage":"Product",
    "MdOutlineShoppingCart":"POS",
    "FaWarehouse":"Transaction",
    "RiFileSettingsLine":"Page Setting",
    "FaTags":"PrCheck",
    "FaFolder":"Pr Group",
    "RiNumber0":"Zero",
    "RiNumber1":"One",
    "RiFolderLockFill":"Lock Group",
    "MdPersonSearch":"Supplier",
    "MdOutlinePersonSearch":"Customer",
    "FaSearchLocation":"Selected",
    "MdExposurePlus1":"+1",
    "MdExposureNeg1":"-1",
    "RiFolderDownloadFill":"Find Group",
    "FaRegCalendarAlt":"Appointment",
    "MdOutlineLibraryAdd":"Repeated Add",
    "MdInfoOutline":"Support"
}

const iconLabelThai={
    "FaHome":"หน้าหลัก",
    "MdTune":"ตั้งค่าสาขา",
    "MdPerson":"คู่ค้า",
    "MdFastfood":"สินค้า",
    //"FaRegMoneyBillAlt":"บิล",
    "SiBookstack":"บิล",
    "RiLogoutBoxRFill":"ล็อกเอา",
    "MdLogout":"ล็อกเอาจากร้าน",
    "MdRefresh":"รีโหลด",
    "MdSwapHoriz":"เปลี่ยน",
    "MdSettings":"ตั้งค่า",
    "MdSearch":"ค้นหา",
    "MdEdit":"แก้ไข",
    "MdAddCircle":"เพิ่ม",
    "MdDelete":"ลบ",
    "MdPrint":"พิมพ์",
    "MdRadioButtonChecked":"เลือก",
    "MdClose":"ปิด",
    "RiShareForwardLine":"ดึงค่า",
    "FaFileCsv":"CSVไฟล์",
    "FaRegArrowAltCircleDown":"เลื่อนลง",
    "FaRegArrowAltCircleUp":"เลื่อนขึ้น",
    "MdPassword":"แก้รหัส",
    "MdSave":"บันทึกใหม่",

    "MdMergeType":"รวมสินค้า",
    "MdRawOn":"แตกวัตถุดิบ",
    "SiAirtable":"โต๊ะ",
    "FaRegFolderOpen":"กลุ่มสินค้า",
    "GiCook":"ครัว",
    "FaBullseye":"ไม่เลือก",
    "FaBarcode":"บาร์โค้ด",
    "TiSortNumericallyOutline":"คิว",
    "FaRegArrowAltCircleRight":"เลือก",
    "RiHandCoinFill":"ปิดบิล",
    "BsShop":"POS",
    "FaMoneyBillWave":"ชำระค่ารายเดือน",
    "GiStack":"รายงานสินค้า",
    "VscSaveAs":"บันทึกแก้ไข",
    "MdVpnKey": "ผู้ใช้-ออก",
    "MdCalculate":"คำนวณ",
    "GoPackage":"สินค้า",
    "MdOutlineShoppingCart":"POS",
    "FaWarehouse":"ธุรกรรม",
    "RiFileSettingsLine":"หน้ากระดาษ",
    "FaTags":"เช็คราคา",
    "FaFolder":"กลุ่มสินค้า",
    "RiNumber0":"ศูนย์",
    "RiNumber1":"หนึ่ง",
    "RiFolderLockFill":"จำกลุ่ม",
    "MdPersonSearch":"ผู้จำหน่าย",
    "MdOutlinePersonSearch":"ลูกค้า",
    "FaSearchLocation":"ที่เลือก",
    "MdExposurePlus1":"+1",
    "MdExposureNeg1":"-1",
    "RiFolderDownloadFill":"ค้นหากลุ่ม",
    "FaRegCalendarAlt":"นัดหมาย",
    "MdOutlineLibraryAdd":"เพิ่มซ้ำๆ",
    "MdInfoOutline":"ช่วยเหลือ"

}

function Ticon(props) {

const {iconName,className,textStyle,iconStyle,Language}=props
const {basicDataSt}=React.useContext(MainContext)
let tempLanguageSetting="Thai"

if(basicDataSt){
  if(basicDataSt.basicData){
    if(basicDataSt.basicData.languageSetting){
      tempLanguageSetting=basicDataSt.basicData.languageSetting
    }
  }
}

if(Language){
  tempLanguageSetting=Language
}

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end................................')
        shouldRender.current=false
    } 
},[])

const [showIcon,setShowIcon]=React.useState(true)


React.useEffect(()=>{
    if(!showIcon){
        setTimeout(()=>{
            if(shouldRender.current){
              setShowIcon(true)
            }
        },[500])
    }
},[showIcon])


const renderIcon=()=>{
    switch(iconName) {
        case "FaHome":
          return <FaHome className={className} style={iconStyle}/>
        case "MdTune":
          return <MdTune className={className} style={iconStyle}/>
        case "MdPerson":
          return <MdPerson className={className} style={iconStyle}/>
        case "MdFastfood":
          return <MdFastfood className={className} style={iconStyle}/>
        case "SiBookstack":
          return <SiBookstack className={className} style={iconStyle}/>
        case "MdLogout":
          return <MdLogout className={className} style={iconStyle}/>
        case "RiLogoutBoxRFill":
          return <RiLogoutBoxRFill className={className} style={iconStyle}/>
        case "MdRefresh":
          return <MdRefresh className={className} style={iconStyle}/>
        case "MdRefresh":
          return <MdRefresh className={className} style={iconStyle}/>
        case "MdSwapHoriz":
          return <MdSwapHoriz className={className} style={iconStyle}/>
        case "MdSettings":
          return <MdSettings className={className} style={iconStyle}/>
        case "MdSearch":
          return <MdSearch className={className} style={iconStyle}/>
        case "MdEdit":
          return <MdEdit className={className} style={iconStyle}/>
        case "MdAddCircle":
          return <MdAddCircle className={className} style={iconStyle}/>
        case "MdDelete":
          return <MdDelete className={className} style={iconStyle}/>
        case "MdPrint":
          return <MdPrint className={className} style={iconStyle}/>
        case "MdRadioButtonChecked":
          return <MdRadioButtonChecked className={className} style={iconStyle}/>
        case "MdClose":
          return <MdClose className={className} style={iconStyle}/>
        case "RiShareForwardLine":
          return <RiShareForwardLine className={className} style={iconStyle}/>
        case "FaFileCsv":
          return <FaFileCsv className={className} style={iconStyle}/>
        
        case "FaRegArrowAltCircleUp":
          return <FaRegArrowAltCircleUp className={className} style={iconStyle}/>
        case "FaRegArrowAltCircleDown":
          return <FaRegArrowAltCircleDown className={className} style={iconStyle}/>
        case "MdPassword":
           return <MdPassword className={className} style={iconStyle}/>
        case "MdSave":
           return <MdSave className={className} style={iconStyle}/>  
        case "MdMergeType":
            return <MdMergeType className={className} style={iconStyle}/>
        case "MdRawOn":
            return <MdRawOn className={className} style={iconStyle}/>  
        case "SiAirtable":
            return <SiAirtable className={className} style={iconStyle}/>  
        case "FaRegFolderOpen":
            return <FaRegFolderOpen className={className} style={iconStyle}/>
        case "GiCook":
            return <GiCook className={className} style={iconStyle}/>  
        case "FaBullseye":
            return <FaBullseye className={className} style={iconStyle}/>  
        case "FaBarcode":
            return <FaBarcode className={className} style={iconStyle}/>  
        case "TiSortNumericallyOutline":
            return <TiSortNumericallyOutline className={className} style={iconStyle}/> 
        case "FaRegArrowAltCircleRight":
            return <FaRegArrowAltCircleRight className={className} style={iconStyle}/>
        case "RiHandCoinFill":
            return <RiHandCoinFill className={className} style={iconStyle}/>
        case "FaMoneyBillWave":
            return <FaMoneyBillWave className={className} style={iconStyle}/>
          
        case "BsShop":
            return <BsShop className={className} style={iconStyle}/>     
        case "GiStack":
            return <GiStack className={className} style={iconStyle}/>   
        case "VscSaveAs":
          return <VscSaveAs className={className} style={iconStyle}/> 
        case "MdVpnKey":
          return <MdVpnKey className={className} style={iconStyle}/> 
        case "MdCalculate":
            return <MdCalculate className={className} style={iconStyle}/> 
        case "GoPackage":
            return <GoPackage className={className} style={iconStyle}/> 
        case "MdOutlineShoppingCart":
            return <MdOutlineShoppingCart className={className} style={iconStyle}/>    
        case "FaWarehouse":
            return <FaWarehouse className={className} style={iconStyle}/>      
        case "RiFileSettingsLine":
            return <RiFileSettingsLine className={className} style={iconStyle}/>
        
        case "FaTags":
          return <FaTags className={className} style={iconStyle}/>
        case "FaFolder":
          return <FaFolder className={className} style={iconStyle}/>
        case "RiNumber0":
          return <RiNumber0 className={className} style={iconStyle}/>
        case "RiNumber1":
          return <RiNumber1 className={className} style={iconStyle}/>
        case "RiFolderLockFill":
          return <RiFolderLockFill className={className} style={iconStyle}/>
        case "MdPersonSearch":
          return <MdPersonSearch className={className} style={iconStyle}/>        
        case "MdOutlinePersonSearch":
          return <MdOutlinePersonSearch className={className} style={iconStyle}/>
        case "FaSearchLocation":
          return  <FaSearchLocation className={className} style={iconStyle}/>
        case "MdExposurePlus1":
          return  <MdExposurePlus1 className={className} style={iconStyle}/>
        case "MdExposureNeg1":
          return  <MdExposureNeg1 className={className} style={iconStyle}/>
          
        case "RiFolderDownloadFill":
          return  <RiFolderDownloadFill className={className} style={iconStyle}/>
        case "FaRegCalendarAlt":
          return  <FaRegCalendarAlt className={className} style={iconStyle}/>
        case "MdOutlineLibraryAdd":
          return <MdOutlineLibraryAdd className={className} style={iconStyle}/>
        case "MdInfoOutline":
          return <MdInfoOutline className={className} style={iconStyle}/>
        default:
          return null
      }
}

return (
    <div className=''
        style={{width:"100%",height:"100%", cursor:"pointer"
                
                //display:"flex",alignItems:"center",
                //flexWrap:"nowrap",height:"100%",
              }}
         onMouseOver={e=>{
            setShowIcon(false)
         }}
         onMouseLeave={e=>{
            //setShowIcon(true)
         }}
    >
          {showIcon
          ?<div 
            style={{width:"100%",height:"100%"}}
            className=''
          >
              <div className='w-100 h-100 flex-center-center'
                >
                {renderIcon()}
              </div>
          </div>
         
           :<div className='flex-center-center' 
                  style={{color:"white",whiteSpace:"nowrap",height:"100%",
                           padding:"0.24rem",...textStyle
                         }}
            >
                <div>
                {
                tempLanguageSetting=="Thai"
                ?iconLabelThai[iconName]
                :iconLabelEnglish[iconName]
                }
                </div>
          </div>
          }
         
    </div>

)

}


Ticon.defaultProps={
  iconName:"",
  classNam:"",
  textStyle:null,
  iconStyle:null
}




export default Ticon;



import React from 'react';
import {MdVisibility,MdVisibilityOff,MdAddCircle,MdVpnKey} from 'react-icons/md';

function MyKeyPad(props) {

const {charFunc,entFunc,delFunc,
    hidePassword,
    setHidePassword
}=props

const keyArray1=[
    "1","2","3","4","5","6","7","8","9","0",
    "a","b","c","d","e","f","g","h","i","j",
    "k","l","m","n","o","p","q","r","s","t",
    "u","v","w","x","y","z"
]

const keyArray2=[
    "1","2","3","4","5","6","7","8","9","0",
    "A","B","C","D","E","F","G","H","I","J",
    "K","L","M","N","O","P","Q","R","S","T",
    "U","V","W","X","Y","Z"
]

const keyArray3=[
    "1","2","3","4","5","6","7","8","9","0",
    "@","#","&","_","-"
]

const keyCommand=["abc","ABC","1#_","Del","Ent","Vis"]

const genBackgroundColor=(jj)=>{
    let temp=null
    if(jj=="Ent"){
        temp="#4b6d62"
    }

    if(jj=="Del"){
        temp="red"
    }
    if(jj=="1#_"||jj=="ABC"||jj=="abc"){
        temp="DodgerBlue"
    }
    return temp
}

const genColor=(jj)=>{
    let temp=null
    if(jj=="Ent"){
        temp="white"
    }

    if(jj=="Del"){
        temp="white"
    }
    if(jj=="1#_"||jj=="ABC"||jj=="abc"){
        temp="white"
    }
    return temp
}


const [keySet,setKeySet]=React.useState(keyArray1)

    return (
    <div className='Modal-background'
    >
        <div className='w-100 h-100'
             style={{position:"relative"}}
        >

                <div className='Modal-box' 
                    style={{width:"100%",height:"40%",
                           position:"absolute",bottom:"0"}}
                    //style={{position:"fixed",bottom:"40vh"}}
                >


                    <div className='w-100 flex-center-center' 
                        //className='w-100 h-30 flex-center-center'
                        //style={{position:"absolute",bottom:"0"}}
                    >
                        {
                            keySet.map((ii,idxII)=>{
                            return (
                                <div key={idxII}
                                    className='w-10 bd-pureWhite sm-icon'
                                    style={{
                                            display:"flex",alignItems:"center",
                                            justifyContent:"center",
                                            margin:"0.2rem",
                                        
                                    }}
                                    onClick={e=>{
                                        charFunc(ii)
                                    }}
                                >{ii}</div>
                            )
                            })
                        }
                    </div>

                    
                    <div className='w-100 flex-center-center'>
                        {
                            keyCommand.map((jj,idxJJ)=>{

                            if(jj=="Vis"){
                                return(
                                    <div 
                                        className='w-10 sm-icon'
                                        style={{display:"flex",justifyContent:"center",alignItem:"center"}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                    >
                                        {hidePassword
                                        ?<MdVisibilityOff
                                            className="md-icon"
                                        />
                                        :<MdVisibility
                                            className='md-icon'
                                        />
                                        }
                                    </div>    
                                )
                            }
                            else {
                                

                                return (
                                    <div key={idxJJ}
                                        className='w-10 bd-pureWhite sm-icon'
                                        style={{
                                                display:"flex",alignItems:"center",
                                                justifyContent:"center",
                                                margin:"0.2rem",
                                                backgroundColor:genBackgroundColor(jj),
                                                color:jj==genColor(jj)
                                        }}
                                        onClick={e=>{
                                            if(jj=="abc"){
                                                setKeySet(keyArray1)
                                            }
                                            else if(jj=="ABC"){
                                                setKeySet(keyArray2)
                                            }
                                            else if(jj=="1#_"){
                                                setKeySet(keyArray3)
                                            }
                                            else if(jj=="Ent"){
                                                entFunc()
                                            }
                                            else if(jj=="Del"){
                                                delFunc()
                                            }
                                        }}
                                    >{jj}</div>
                                )
                                }


                            })
                        }
                    </div>

                    


                </div>
        </div>

    </div>
    )
    
}


MyKeyPad.defaultProps={
    delFunc:()=>{},
    entFunc:()=>{},
    charFunc:()=>{},
}

export default MyKeyPad;

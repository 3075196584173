
import React from 'react';
import {MainContext} from '../../context/MainContext'
import PageComponentGroup  from '../../component/pageComponent/PageComponentGroup';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import console from '../../util/consoleVerify'


function Group() {

//console.log('Group')

const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
       mainContextGroupFilterData,setMainContextGroupFilterData,
      }=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
<div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>

    <div className="h-100 w-100">
        {
        basicDataSt.basicData&&myheader&&
        <PageComponentGroup
            setReloadBasicData={setReloadBasicData}
            filterDataTemplate={filterDataTemplate}
            
            filterTemplate={basicDataSt.pageFilter.groupFilter}
            inputState={inputState.groupInputState}
            
            basicDataSt={basicDataSt}
            myheader={myheader}
            
            formTemplate={basicDataSt.modalFormTemplate.proupForm}
            editFormTemplate={basicDataSt.modalFormTemplate.groupEditForm}
            
            stateTemplate={StateTemplate.groupState}
            pageFilterForm={basicDataSt.pageFilterForm.groupFilterForm}

            groupFormTemplate={basicDataSt.modalFormTemplate.groupForm}
            groupEditFormTemplate={basicDataSt.modalFormTemplate.groupEditForm}

            bgColor={"#EC9787"}
            calDigit={100}

            mainContextGroupFilterData={mainContextGroupFilterData}
            setMainContextGroupFilterData={setMainContextGroupFilterData}
        />
        
        }
    </div>
</div>
)

}
export default Group;



import axiosUtil from '../../util/axiosUtil'
import ctUtil from '../../util/ctUtil'
import StateUtil from '../../model/StateUtil'

import axios from '../../util/axiosVerify'
import uuid from 'react-uuid'
import download from 'js-file-download'
import * as XLSX from 'xlsx';

import console from '../../util/consoleVerify'

const {createTableTemplateForPage,convertTableTemplateObjToArray}=ctUtil
const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,
    addSingleFileNameInsteadOfPhotoUrl,
    catchErrorToMessage}=axiosUtil
const {genBlankState,genRefAndValue,revRefAndValue,combineLoadDataBlankState}=StateUtil  

//whet use reloadAxiosAll we add selectedLine = false 
//and _id with uuid() to make it unique
const genFilterDataWithIndex=(filterData,selectProduct,user)=>{
    //console.log('selectProduct')
    //console.log(selectProduct)
    let tempSelectProduct
    if(selectProduct){tempSelectProduct=selectProduct}
    else {tempSelectProduct=[]}

    /*
    let tempArray=[]

    filterData.map((i,idx)=>{
         
        const temp={...i,selectedLine:false,_id:uuid()}
        tempArray=[...tempArray,temp]
        //const temp={...i,tempIndex:idx,selectedLine:false}
    })
    */
    let tempArray=[]

    if(user){
            filterData.map((i,idx)=>{
                //const temp={...i,tempIndex:idx,selectedLine:false}
                let tempStocking={}

                if(i.stocking){
                    let isSearching=true
                    i.stocking.map(k=>{
                        if(isSearching){
                            if(k.branchId==user.branchId){
                                tempStocking={
                                    stock:k.stock,
                                    minStock:k.minStock,
                                    maxStock:k.maxStock,
                                
                                }
                                isSearching=false
                            }
                        }   
                    })
                }


                const temp={...i,selectedLine:false,_id:uuid(),...tempStocking}
                tempArray=[...tempArray,temp]

            })
    }
    else{
        filterData.map((i,idx)=>{
         
            const temp={...i,selectedLine:false,_id:uuid()}
            tempArray=[...tempArray,temp]
            //const temp={...i,tempIndex:idx,selectedLine:false}
        })
    }








    let tempArray2=[]


    tempArray.map(i=>{
        let tempObj=i

        tempSelectProduct.map(j=>{
            if(i.id==j.id){
                //console.log('j.id==i.id')
                tempObj={...tempObj,selectedLine:j.selectedLine}
                //tempArray2=[...tempArray2,]
            }
        })

        tempArray2=[...tempArray2,tempObj]
    })
 
    return tempArray2
}
//------------------------------------
const genData=(length)=>{
    let tempArray=[]

    for (let i=1;i<=length;i++){
        const temp =  {id:i,
                     name:`name ${i}`,
                     quantity:i+100,
                     quantity2:100+i,
                     quantity3:20+i,
                     quantity4:1,
                     quantity5:15+i,
                     _id:uuid()
                    }
        tempArray=[...tempArray,temp]
    }
    return tempArray
}

//==================================
const reloadAxiosTableTemplate=(filterData,myheader,shouldRender)=>{
    //console.log('reloadAxiosAll')
    //console.log(filterData)
    const {pageNumber,limitRow,sort,qry,dataUrl,tableTemplateUrl,
           tableTemplateName,detailTableTemplateName,
           detailTableTemplateForFormName,selectProduct
          } = filterData

    return new Promise(function(resolve,reject){

        axios.post(`/${tableTemplateUrl}/getcustom`,{},myheader,shouldRender)
        .then(result=>{
            const temp = createTableTemplateForPage(result.data.data)
            const tempResult={...filterData,
                tableTemplate:temp[tableTemplateName],
                detailTableTemplate: temp[detailTableTemplateName],
                detailTableTemplateForForm: temp[detailTableTemplateForFormName],
                //tableTemplate:productTableTemplate,
                //detailTableTemplate:productDetailTableTemplate,
                reloadData:false
            } 
            resolve(tempResult)
        })
        .catch(error=>{
            const tempError={...filterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log('tempError')
            reject(tempError)
            //console.log(tempError)
            //setFilterData(tempError)
        })
    })
}


//==================================
//when reloadDat is true => use Effect will start to use reloadAxiosAll()
//when press refresh => will start to use reloadAxiosAll()
//we get data ,count,lastRecordId and all tableTemplate 
const reloadAxiosAll=(filterData,myheader,shouldRender)=>{
    //console.log('reloadAxiosAll..................')
    //console.log(filterData)
    
    const {pageNumber,limitRow,sort,qry,dataUrl,tableTemplateUrl,
           tableTemplateName,detailTableTemplateName,
           detailTableTemplateForFormName,selectProduct,user
          } = filterData

    //console.log('reloadAxiosAll selectProduct')
    //console.log(selectProduct)

    //const temp2 = {pageNumber:pageNumber,limitRow:limitRow,sort:sort,...qry}
    //console.log('reloadAxiosAll..........')
    //console.log(qry)
   

    //console.log(filterData.editData)
    return new Promise(function(resolve,reject){

        const promise1=axios.post(`/${dataUrl}/getlimit`,
        {pageNumber,limitRow,sort,...qry},myheader,shouldRender)
        const promise2=axios.post(`/${tableTemplateUrl}/getcustom`,{},myheader,shouldRender)
        Promise.all([promise1,promise2])
        .then(result=>{
            const temp = createTableTemplateForPage(result[1].data.data)
            
            //console.log(temp["productTableTemplate"])
            const temp2= genFilterDataWithIndex(result[0].data.data,selectProduct,user)
            //console.log('temp2.......')
            //console.log(temp2)
            
            //console.log('detailTableTemplateName')
            //console.log(detailTableTemplateName)
            //console.log(result[1])
            //console.log(result[0].data.lastRecordId)
            //console.log('temp[tableTemplateName')
            //console.log(temp[tableTemplateName])
            const tempResult={...filterData,
                data0:temp2,
                count:result[0].data.count,
                lastRecordId:result[0].data.lastRecordId,
                tableTemplate:temp[tableTemplateName],
                detailTableTemplate: temp[detailTableTemplateName],
                detailTableTemplateForForm: temp[detailTableTemplateForFormName],

                //tableTemplate:productTableTemplate,
                //detailTableTemplate:productDetailTableTemplate,
                reloadData:false
            } 
            //console.log('tempResult')
            //console.log(tempResult)
            resolve(tempResult)
            //console.log(tempResult)
            //setFilterData(tempResult)

        })
        .catch(error=>{
            //console.log(error)
            const tempError={...filterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log('tempError')
            reject(tempError)
            //console.log(tempError)
            //setFilterData(tempError)
        })

    })
}
//=============================================


//=============================================
const submitFunctionAddMany=(formInputState,filterData,productState,myheader,shouldRender,manyImage)=>{
    const {dataUrl} = filterData

        //console.log('submitFunctionAddMany')
        //console.log(formInputState)

        return new Promise ((resolve,reject)=>{

                const {dataUrl} = filterData
                let controller="addmany"
                
                const {_id,file1,detail,photoUrl1,...remaining}=formInputState
                //let tempFormInputState1={id,...remaining,hasDetailProduct:false}
                const tempFormInputState1=remaining
                //console.log('pageUtil ...detail')
                //console.log(detail)

                axios.post(`/${dataUrl}/${controller}`,
                    tempFormInputState1,myheader,shouldRender)
                .then(result=>{
                
                    const tempResult={...filterData,
                        reloadData:true,
                        showEdit:false,
                        showAdd:false,
                        heightTop:95,
                    }   
                    resolve (tempResult)       
                })
                .catch(error=>{
                    const tempError={...filterData,
                        reloadData:false,
                        message:catchErrorToMessage(error),
                        showModalConfirm:false,
                        showModalError:true,
                        editData:tempFormInputState1
                    }
                    reject(tempError)
                })

        })   


}   


//=============================================
// when click confirm in add PageForm it request for addcustom to server
const submitFunctionAdd=(formInputState,filterData,productState,myheader,shouldRender,manyImage)=>{
    const {dataUrl} = filterData

    if(dataUrl=="p35shop"||dataUrl=="p35user"||
       dataUrl=="p35shoppayment"||dataUrl=="p35basicdata"||
       dataUrl=="p35branch"
       ){
            return new Promise ((resolve,reject)=>{
                    
                let controller="addcustombyadmin"

                const {id,file1,photoUrl1,...remaining}=formInputState
                let tempFormInputState1={id,...remaining}

                if(file1){

                }
                else{
                    axios.post(`/${dataUrl}/${controller}`,
                        {...tempFormInputState1,photoUrl1},
                        myheader,
                        shouldRender
                    )
                    .then(result=>{
                    
                        const tempResult={...filterData,
                            reloadData:true,
                            showEdit:false,
                            showAdd:false,
                            heightTop:95,
                        }   
                        resolve (tempResult)       
                    })
                    .catch(error=>{
                        const tempError={...filterData,
                            reloadData:false,
                            message:catchErrorToMessage(error),
                            showModalConfirm:false,
                            showModalError:true,
                            editData:tempFormInputState1
                        }
                        reject(tempError)
                    })

                }
            })
    }

    else {

            return new Promise ((resolve,reject)=>{

                const {dataUrl} = filterData
                let controller="addcustom"
                let updateController="updatecustom"

                if(dataUrl.includes("transaction")){
                    controller="addtransaction"
                    updateController="updatetransaction"
                }
                
                //const tempFormInputState1={...formInputState}
                //const tempFormInputState2=addSingleFileNameInsteadOfPhotoUrl(tempFormInputState1)  
                /////////////const tempFormInputState2=addFileNameInPhotoUrl(tempFormInputState1)  
                //const tempFormInputState3=combineLoadDataBlankState({template:productState,loadData: tempFormInputState2})
                //const fd=genFD({inputState:tempFormInputState3,template:productState})

                const {id,file1,detail,photoUrl1,...remaining}=formInputState
                let tempFormInputState1={id,...remaining,hasDetailProduct:false}
                
                //console.log('pageUtil ...detail')
                //console.log(detail)

                let tempArray=null
                if(detail){
                    tempArray=[]
                    detail.map(j=>{
                        const {_id,...remaining2}=j
                        tempArray=[...tempArray,remaining2]
                    })
                
                    tempFormInputState1={...tempFormInputState1,detail:tempArray}

                    if(detail.length>0){
                        if(detail[0]){
                            tempFormInputState1={...tempFormInputState1,hasDetailProduct:true}
                        }
                    }
                }

                
                if(file1){
                    //console.log('have file1xxxxxxxxxx')
                    let tempFormInputState2
                    if(manyImage){
                        tempFormInputState2=addFileNameInPhotoUrl({id,file1,photoUrl1})  
                    }else{
                        tempFormInputState2=addSingleFileNameInsteadOfPhotoUrl({id,file1,photoUrl1})  

                    }
                    
                    const fd=genFD({
                        inputState:tempFormInputState2,
                        template:{
                            id:productState.id,
                            file1:productState.file1,
                            photoUrl1:productState.photoUrl1,
                        }
                    })
            

                    axios.post(`/${dataUrl}/${controller}`,tempFormInputState1,myheader,shouldRender)
                    .then(result=>{
                        return axios.post(`/${dataUrl}/${updateController}`,fd,myheader,shouldRender)
                    })
                    .then(result=>{
                        //console.log('result')
                        //console.log(result)
                        const tempResult={...filterData,
                            reloadData:true,
                            showEdit:false,
                            showAdd:false,
                            selectGroup:null,
                            heightTop:95,
                        }   
                        resolve (tempResult)       
                    })    
                    .catch(error=>{
                        const tempError={...filterData,
                            reloadData:false,
                            message:catchErrorToMessage(error),
                            showModalConfirm:false,
                            showModalError:true,
                            editData:tempFormInputState1,
                            selectGroup:null
                        }
                        reject(tempError)
                    })
                }
            
                else{
                    axios.post(`/${dataUrl}/${controller}`,{...tempFormInputState1,photoUrl1},myheader,shouldRender)
                    .then(result=>{
                    
                        const tempResult={...filterData,
                            reloadData:true,
                            showEdit:false,
                            showAdd:false,
                            heightTop:95,
                        }   
                        resolve (tempResult)       
                    })
                    .catch(error=>{
                        const tempError={...filterData,
                            reloadData:false,
                            message:catchErrorToMessage(error),
                            showModalConfirm:false,
                            showModalError:true,
                            editData:tempFormInputState1
                        }
                        reject(tempError)
                    })

                }

            })   


    }

}   

//when click confirm in edit PageForm it request updatecustom to server
const submitFunctionEdit=(formInputState,filterData,productState,myheader,shouldRender,manyImage)=>{
    const {dataUrl} = filterData

    if(dataUrl=="p35shop"||dataUrl=="p35user"||dataUrl=="p35shoppayment"||
       dataUrl=="p35basicdata"||dataUrl=="p35branch"){
        return new Promise ((resolve,reject)=>{
                
            let controller="updatecustombyadmin"

     
            const {id,temp_id,file1,photoUrl1,shopId,...remaining}=formInputState
                
            let tempFormInputState1={id,...remaining,temp_id:temp_id,shopId:shopId}

            if(file1){
                //console.log('have file1------------------')
                let tempFormInputState2

                //if(manyImage){
                //    tempFormInputState2=addFileNameInPhotoUrl({id,file1,photoUrl1})  
                //}
                //else{
                    tempFormInputState2=addSingleFileNameInsteadOfPhotoUrl({temp_id,shopId,file1,photoUrl1})  

                //}

                //console.log('tempFormInputState2')
                //console.log(tempFormInputState2)

                const fd=genFD({
                    inputState:tempFormInputState2,
                    template:{
                        temp_id:productState.temp_id,
                        file1:productState.file1,
                        photoUrl1:productState.photoUrl1,
                        shopId:productState.shopId
                    }
                })
                
                    axios.post(`/${dataUrl}/${controller}`,fd,myheader,shouldRender)
                    .then(result=>{
                        return axios.post(`/${dataUrl}/${controller}`,tempFormInputState1,myheader,shouldRender)
                    })
                    .then(result=>{
                        const tempResult=
                        {...filterData,
                            reloadData:true,
                            showEdit:false,
                            showAdd:false,
                            heightTop:95,
                            editData:null,
                            selectGroup:null
                        }
                        resolve(tempResult)
                    })
                    .catch (error=>{
                        const tempError = 
                        {...filterData,
                            reloadData:false,
                            message:catchErrorToMessage(error),
                            showModalConfirm:false,
                            showModalError:true,
                            editData:tempFormInputState1,
                            selectGroup:null
                        }
                        reject (tempError)
                    })
                    
            }
            else{
                
                axios.post(`/${dataUrl}/${controller}`,{...tempFormInputState1,photoUrl1},myheader,shouldRender)
                .then(result=>{
                    const tempResult=
                    {...filterData,
                        reloadData:true,
                        showEdit:false,
                        showAdd:false,
                        heightTop:95,
                        editData:null
                    }
                    resolve(tempResult)
                })
                .catch(error=>{
                    const tempError = 
                    {...filterData,
                        reloadData:false,
                        message:catchErrorToMessage(error),
                        showModalConfirm:false,
                        showModalError:true,
                        editData:tempFormInputState1
                    }
                    reject (tempError)
                })
                
            }

        })

    }
    else {
   
            return new Promise ((resolve,reject)=>{
                
                let controller="updatecustom"

                if(dataUrl.includes("transaction")){
                    controller="updatetransaction"
                }
                
                //const tempFormInputState1={...formInputState}
                //const tempFormInputState2=addSingleFileNameInsteadOfPhotoUrl(tempFormInputState1)  
                /////////const tempFormInputState2=addFileNameInPhotoUrl(tempFormInputState1)  
                //const tempFormInputState3=combineLoadDataBlankState({template:productState,loadData:tempFormInputState2})
                //const fd=genFD({inputState:tempFormInputState3,template:productState})

                const {id,file1,photoUrl1,detail,...remaining}=formInputState
                
                let tempFormInputState1={id,...remaining,hasDetailProduct:false}

                let tempArray=null
                if(detail){
                    tempArray=[]
                    detail.map(j=>{
                        const {_id,...remaining2}=j
                        tempArray=[...tempArray,remaining2]
                    })
                    tempFormInputState1={...tempFormInputState1,detail:tempArray}

                    if(detail.length>0){
                        if(detail[0]){
                            tempFormInputState1={...tempFormInputState1,hasDetailProduct:true}
                        }
                    }
                }

                if(file1){
                    //console.log('have file1------------------')
                    
                    let tempFormInputState2

                    if(manyImage){
                        tempFormInputState2=addFileNameInPhotoUrl({id,file1,photoUrl1})  
                    }
                    else{
                        tempFormInputState2=addSingleFileNameInsteadOfPhotoUrl({id,file1,photoUrl1})  

                    }

                //console.log('tempFormInputState2')
                //console.log(tempFormInputState2)

                    
                    

                    const fd=genFD({
                        inputState:tempFormInputState2,
                        template:{
                            id:productState.id,
                            file1:productState.file1,
                            photoUrl1:productState.photoUrl1,
                        }
                    })

                        axios.post(`/${dataUrl}/${controller}`,fd,myheader,shouldRender)
                        .then(result=>{
                            return axios.post(`/${dataUrl}/${controller}`,tempFormInputState1,myheader,shouldRender)
                        })
                        .then(result=>{
                            const tempResult=
                            {...filterData,
                                reloadData:true,
                                showEdit:false,
                                showAdd:false,
                                heightTop:95,
                                editData:null,
                                selectGroup:null
                            }
                            resolve(tempResult)
                        })
                        .catch (error=>{
                            const tempError = 
                            {...filterData,
                                reloadData:false,
                                message:catchErrorToMessage(error),
                                showModalConfirm:false,
                                showModalError:true,
                                editData:tempFormInputState1,
                                selectGroup:null
                            }
                            reject (tempError)
                        })
                }
                else{
                    
                        axios.post(`/${dataUrl}/${controller}`,{...tempFormInputState1,photoUrl1},myheader,shouldRender)
                        .then(result=>{
                            const tempResult=
                            {...filterData,
                                reloadData:true,
                                showEdit:false,
                                showAdd:false,
                                heightTop:95,
                                editData:null
                            }
                            resolve(tempResult)
                        })
                        .catch(error=>{
                            const tempError = 
                            {...filterData,
                                reloadData:false,
                                message:catchErrorToMessage(error),
                                showModalConfirm:false,
                                showModalError:true,
                                editData:tempFormInputState1
                            }
                            reject (tempError)
                        })
                }
            })
            
    }
}   
    
//============================

//when we click search $in or $or 
//it gen arrayForFilter and reload data again 
//========================================
const filterAxios=(option,inputState,filterTemplate,
                 filterData,controller,myheader,
                 shouldRender,branchId)=>{
        //console.log('filterAxios.............filterAxios.............')
        /*
        console.log('option')
        console.log(option)

        console.log('inputState')
        console.log(inputState)

        console.log('filterTemplate')
        console.log(filterTemplate)

        console.log('filterData')
        console.log(filterData)

        console.log('controller')
        console.log(controller)

        console.log('myheader')
        console.log(myheader)

        console.log('shouldRender')
        console.log(shouldRender)
        */

        //console.log(inputState)
    let myController="getlimit"
    if(controller){
        myController=controller
    }

    return new Promise ((resolve,reject)=>{
        let tempLimitRow=10
        const {pageNumber,limitRow,sort,dataUrl} = filterData

        //console.log(`pageNumber = ${pageNumber}`)
        if(limitRow){
            tempLimitRow=limitRow
        }

        //if(limitRow>100){
        //    tempLimitRow=100
        //}
        //console.log(`limitRow = ${limitRow}`)

        //console.log('sort')
        //console.log(sort)

        let arrayCommand=genArrayForFilterAxios({
            filterTemplate:filterTemplate,
            inputState:inputState,
            branchId:branchId
        })
        

        //console.log('arrayCommand....')
        //console.log(arrayCommand)
        if(arrayCommand){
                let qry = null
                if(option=="and"){ qry={$and:arrayCommand} }
                if(option=="or"){ qry={$or:arrayCommand} }
                //console.log('qry............')
                //console.log(qry)
                /*
                setFilterData({
                    ...filterData,
                    pageNumber:1,
                    qry:qry
                })
                */
                const tempObj={pageNumber:1,limitRow:tempLimitRow,sort,...qry}
                //console.log('tempObj')
                //console.log(tempObj)
                //setPageNumber(1)
                //setQry(qry)
                //axios.post(`/${dataUrl}/${myController}`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
                axios.post(`/${dataUrl}/${myController}`,tempObj,myheader,shouldRender)
                .then(result=>{
                    const tempResult= { ...filterData,
                                        data0:result.data.data,
                                        count:result.data.count,
                                        lastRecordId:result.data.lastRecordId,
                                        reloadData:false,
                                        pageNumber:1,
                                        qry:qry,
                                    }
                    resolve(tempResult)
                })
                .catch(error=>{
                    //catchErrorToMessage(error,setMessage)
                    //setMessage(error.response.data.message)
                    const tempError={...filterData,
                                        reloadData:false,
                                        message:catchErrorToMessage(error),
                                        showModalConfirm:false,
                                        showModalError:true,
                                        pageNumber:1,
                                        qry:qry
                                    }
                    reject(tempError)
                })
        }
    })
}

//=====================================

const saveTableTemplate=(tableTemplate,tableTemplateUrl,
                        tableTemplateName,myheader,shouldRender)=>{
    console.log('saveTableTemplatexxxx')
    console.log(tableTemplate)
    //console.log(tableTemplateUrl)
    //console.log(tableTemplateName)
   
    
    //const {tableTemplateUrl} = filterData
    //const {tableTemplateUrl,tableTemplate} = filterData
    let tempTableTemplate={}
    const object=Object.keys(tableTemplate)
    
    console.log(object)
    //make sure showColHead  and not show MdClose
    object.map(i=>{
        let tempObj=tableTemplate[i]
        tempTableTemplate={...tempTableTemplate,[i]:{...tempObj,["showColHead"]:true}}
    })

    //change formate before save to DB 
    const temp={
        tableName:tableTemplateName,
        template: convertTableTemplateObjToArray(tempTableTemplate)
    }

    //console.log('temp')
    //console.log(temp)
    //console.log(tableTemplateUrl)
    axios.post(`/${tableTemplateUrl}/updatetabletemplate`,temp,myheader,shouldRender)
    .then(result=>{
        console.log(result)
        //window.location.reload()
    })
    .catch(error=>{
        //console.log(catchErrorToMessage(error))
    })
}

//=======================================
//<LineForm/> onBlur => updateFilterData with LineForm
const updateFilterData=(index,i,filterData)=>{
    //<LineForm/>   onBlur => updateFilterData


    const {data0,selectProduct}=filterData
    //console.log(i)

    let tempArray=[] //for update  data0 with... i of LineForm
    data0.map(j=>{
        if(j._id==i._id){
            tempArray=[...tempArray,i]
        }   
        else{
            tempArray=[...tempArray,j]
        }
    })
    //get i from <LineForm/>

    //update selectProduct with LineForm as well 
    let tempArray2=[]

    selectProduct.map(k=>{
        if(k._id==i._id){
            //tempArray2=[...tempArray2,k]
        }
        else {
            tempArray2=[...tempArray2,k]
        }
    })
    tempArray2=[...tempArray2,i]

    return {data0:tempArray,selectProduct:tempArray2}
    /*
    setFilterData({...filterData,
        data0:tempArray,
        selectProduct:tempArray2
    })
    */
}
//=====================================
const updateDetailOfFilterData=(index,i,filterData)=>{

    const {detail0,selectProduct}=filterData

    let tempArray=[] //for update  data0 with... i of LineForm
    detail0.map(j=>{
        if(j._id==i._id){
            tempArray=[...tempArray,i]
        }   
        else{
            tempArray=[...tempArray,j]
        }
    })

    let tempArray2=[]

    selectProduct.map(k=>{
        if(k._id==i._id){
            //tempArray2=[...tempArray2,k]
        }
        else {
            tempArray2=[...tempArray2,k]
        }
    })
    
    tempArray2=[...tempArray2,i]

    return {detail0:tempArray,selectProduct:tempArray2}
    
}


//=====================================
//when click FaBullEyes it change data0.selectedLine 
//and set selectProct to null 
//and uncheck all input radio 
const setUnSelectAll=(filterData)=>{
    let tempArray=[]

    if(filterData.data0){
        filterData.data0.map(i=>{
            tempArray=[...tempArray,{...i,selectedLine:false}]
        })
      
    }
    
    return {...filterData,
        selectProduct:[],
        data0:tempArray
    }
}

//-----------------------------------

//----------------------------------------


//========================================
const submitFunctionDelete=(filterData,myheader,shouldRender)=>{
    const {dataUrl,selectProduct}=filterData

    //console.log('dataUrl.............')
    //console.log(dataUrl)

    if(dataUrl=="p35shop"||
       dataUrl=="p35user"||
       dataUrl=="p35shoppayment"||
       dataUrl=="p35branch"||
       dataUrl=="p35basicdata"
    ){

        //console.log('aaaaaaaaaaaaa')

        return new Promise ((resolve,reject)=>{

            let controller="deletecustombyadmin"
            let tempId=[]

            selectProduct.map(i=>{
                if (i.selectedLine){
                    tempId=[...tempId,i.temp_id]
                }
            })

            const qry = {_id:{$in:tempId}}

            axios.post(`/${dataUrl}/${controller}`,qry,myheader,shouldRender)
            .then(result=>{
                //resolve(filterData)

                const tempResult={...filterData,
                      reloadData:true,
                      showModalConfirm:false,
                      selectProduct:[]
                }
                resolve(tempResult)
            })
            .catch(error=>{
                //console.log('errrrrrr')
                //console.log(error.response.data.error.message)
                const tempError = {...filterData,
                                        reloadData:false,
                                        message:catchErrorToMessage(error),
                                        showModalConfirm:false,
                                        showModalError:true,
                                    }
                reject(tempError)
            })

        })
    }

    else{
            //console.log('bbbbbbbbbbbbb')
            return new Promise ((resolve,reject)=>{

              
                let controller="deletemany"

                if(dataUrl=="transaction"){
                    controller="deletetransaction"
                }
                
                let tempId=[]

                selectProduct.map(i=>{
                    if (i.selectedLine){
                        tempId=[...tempId,i.id]
                    }
                })

                const qry = {id:{$in:tempId}}


                axios.post(`/${dataUrl}/${controller}`,qry,myheader,shouldRender)
                .then(result=>{
          
                    const tempResult={...filterData,
                                        reloadData:true,
                                        showModalConfirm:false,
                                        selectProduct:[]
                                    }
                    resolve(tempResult)
                })
                .catch(error=>{
                    //console.log('errrrrrr')
                    //console.log(error.response.data.error.message)
                    const tempError = {...filterData,
                                            reloadData:false,
                                            message:catchErrorToMessage(error),
                                            showModalConfirm:false,
                                            showModalError:true,
                                        }
                    reject(tempError)
                })
            })
    }
}
//=====================================
const genCsv=(filterData,tableTemplateName)=>{

    //console.log('genCsv')
    //console.log(filterData)
    //console.log('tableTemplateName')
    //console.log(tableTemplateName)

    if(!filterData.data0){return}
    if(!filterData.tableTemplate){return}
     
          let tempFilterData=filterData.data0    
          //console.log(filterData.tableTemplate)
     
          if(tableTemplateName=="partnerTableTemplate"){
                let tempArray=[]

                tempFilterData.map(i=>{
                    const {address,phone}=i

                    let tempPhone=""
                    if(phone){
                        if(Array.isArray(phone)){
                            phone.map(j=>{
                                tempPhone=tempPhone+`phone:${j}`
                            })
                        }
                    }

                    let tempAddress=""
                    if(address){
                        if(Array.isArray(address)){
                            tempAddress=address[0]
                        }
                    }
                    
                    tempArray=[...tempArray,
                                {...i,
                                address:tempAddress,
                                phone:tempPhone
                                }
                            ]
                })
                tempFilterData=tempArray
          }

          if(tableTemplateName=="productTableTemplate")
            {
                let tempArray=[]

                tempFilterData.map(i=>{
                    const {stocking}=i
                    let tempMinStock
                    let tempMaxStock
                    let tempStock
                    
                    if(stocking){
                        if(Array.isArray(stocking)){
                            let keepChecking=true
                            stocking.map(j=>{
                                if(keepChecking){
                                    const {branchId,minStock,maxStock,stock}=j
                                    if(branchId==filterData.basicDataSt.user.branchId){
                                        tempMinStock=minStock
                                        tempMaxStock=maxStock
                                        tempStock=stock 
                                        keepChecking=false
                                    }
                                }
                            })
                        }
                    }

                    tempArray=[...tempArray,
                        {...i,
                        minStock:tempMinStock,
                        maxStock:tempMaxStock,
                        stock:tempStock
                        }
                    ]
                })

                tempFilterData=tempArray
           }

          //(tableTemplateName=="productStockTableTemplate")
          //console.log('tempFilterData=tempArray')
          //console.log(tempFilterData)

          const keys = Object.keys(filterData.tableTemplate)
          let keys2=[]
          keys.map(i=>{
              if(filterData.tableTemplate[i].showCol){
                  keys2=[...keys2,i]
              }
          })
          
          //console.log('keys2')
          //console.log(keys2)

          
          const columnDelimiter = ","
          const lineDelimiter = "\n"
          
          //const keys = Object.keys(filterData.data0[0])
 
          let result = ""
          result += keys2.join(columnDelimiter)
          result += lineDelimiter
 
          tempFilterData.map((item,indexItem)=>{
              keys2.map((key,indexKey)=>{

                if(indexKey>0){
                      result+=columnDelimiter
                
                }

                let itemKey=item[key]

                if(key=="date"){
                    if(itemKey){
                        itemKey=new Date(itemKey).toLocaleString()
                    }
                }

                result += typeof itemKey === "string"&& 
                            itemKey.includes(columnDelimiter) 
                            ? `"${itemKey}"` 
                            : itemKey


 
             })
 
             result += lineDelimiter
          })
 
          download(result,`${Date.now()}.csv`)
          //download(result,`${pageFilterForm.formHead}${filterData.pageNumber}.csv`)
 }
 
//--------------------------------------

 const genXlsx=(filterData,tableTemplateName)=>{

  


    if(!filterData.data0){return}
    if(!filterData.tableTemplate){return}

    let tempFilterData=filterData.data0    

    if(tableTemplateName=="partnerTableTemplate"){
        let tempArray=[]

        tempFilterData.map(i=>{
            const {address,phone}=i

            let tempPhone=""
            if(phone){
                if(Array.isArray(phone)){
                    phone.map(j=>{
                        tempPhone=tempPhone+`phone:${j}`
                    })
                }
            }

            let tempAddress=""
            if(address){
                if(Array.isArray(address)){
                    tempAddress=address[0]
                }
            }
            
            tempArray=[...tempArray,
                        {...i,
                        address:tempAddress,
                        phone:tempPhone
                        }
                    ]
        })
        tempFilterData=tempArray
    }

    if(tableTemplateName=="productTableTemplate")
    {
        let tempArray=[]

        tempFilterData.map(i=>{
            const {stocking}=i
            let tempMinStock
            let tempMaxStock
            let tempStock
            
            if(stocking){
                if(Array.isArray(stocking)){
                    let keepChecking=true
                    stocking.map(j=>{
                        if(keepChecking){
                            const {branchId,minStock,maxStock,stock}=j
                            if(branchId==filterData.basicDataSt.user.branchId){
                                tempMinStock=minStock
                                tempMaxStock=maxStock
                                tempStock=stock 
                                keepChecking=false
                            }
                        }
                    })
                }
            }

            tempArray=[...tempArray,
                {...i,
                minStock:tempMinStock,
                maxStock:tempMaxStock,
                stock:tempStock
                }
            ]
        })

        tempFilterData=tempArray
    }


    const keys = Object.keys(filterData.tableTemplate)
    let keys2=[]
    keys.map(i=>{
        if(filterData.tableTemplate[i].showCol){
            keys2=[...keys2,i]
        }
    })
    
    let tempFinalArray=[]
    tempFilterData.map((item,indexItem)=>{
        //console.log('item')
        //console.log(item)
        let tempObj={}
        let myJson
        keys2.map((key,indexKey)=>{
            let itemKey=item[key]
            
            if(key=="date"){
                if(itemKey){
                    itemKey=new Date(itemKey).toLocaleString()
                }
            }
            //console.log(`key : ${key}, itemKey: ${itemKey}`)

            const tempLb=filterData.tableTemplate[key].lb
            tempObj={...tempObj,[tempLb]:itemKey }
            //myJson=JSON.stringify(tempObj)
        })

        tempFinalArray=[...tempFinalArray,tempObj]
    })

    //console.log('tempFinalArray')
    //const myJsonData=JSON.stringify(tempFinalArray)

    /*
    console.log(myJsonData)
    
    const myData=[
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"},
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"},
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"},
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"},
    ];

    
    const downloads =(data)=>{
        console.log('downloads')
        console.log(data)
        let ws = XLSX.utils.json_to_sheet(data)
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "sheet")
        let buf = XLSX.write(wb, {bookType:'xlsx',type:'buffer'})
        let str = XLSX.write(wb, {bookType:'xlsx',type:'binary'})
  
        XLSX.writeFile(wb,`${Date.now()}.xlsx`)
    }
    */

    //downloads(myData)

    const downloads2 =async(tempFinalArray)=>{
        //console.log(tempFinalArray)
        //console.log('0')
        //const data=JSON.stringify(tempFinalArray)
        //console.log('1')
        //console.log(data)

        let ws = XLSX.utils.json_to_sheet(tempFinalArray)
        //console.log('2')
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "sheet")
        let buf = XLSX.write(wb, {bookType:'xlsx',type:'buffer'})
        let str = XLSX.write(wb, {bookType:'xlsx',type:'binary'})
  
        XLSX.writeFile(wb,`${Date.now()}.xlsx`)
    }
    downloads2(tempFinalArray)

 }
 
//=====================================
const pageUtil={
    reloadAxiosAll,reloadAxiosTableTemplate,saveTableTemplate,
    updateFilterData,updateDetailOfFilterData,
    submitFunctionEdit,submitFunctionAdd,
    submitFunctionAddMany,
    filterAxios,
    setUnSelectAll,submitFunctionDelete,
    genCsv,genXlsx
}

export default pageUtil


import React from 'react';
import axios from '../../util/axiosVerify';

import {MdEdit} from 'react-icons/md';
import {FaPlusSquare,FaMinusSquare,FaBan,FaFolderPlus,FaArrowCircleUp,
        FaCheck,FaArrowAltCircleUp,FaArrowAltCircleDown} from 'react-icons/fa'; 
import PrintPageForm from './PrintPageForm';
import console from '../../util/consoleVerify'
import axiosUtil from '../../util/axiosUtil'
import uuid from 'react-uuid';

const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,
    catchErrorToMessage}=axiosUtil

function PrintPage(props) {

const {pageData,setShow,submitFunction,submitFunction2,
       printPage,basicData,tableTemplate,
       //addPrintPage,
       //deletePrintPage,
       saveTableTemplateFunc,
       setShowPrintFunc,myheader
}=props

const refSubmitForm=React.createRef() //ตกลง
const refCancelForm=React.createRef() //ยกเลิก    

//console.log('printPage')
//console.log(printPage)

const calDigit=100

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const genEditColor=(i)=>{
    let temp="black"

    
    if(editData){
        if(editData.id){
            if(editData.id==i.id){
                temp="red"
            }
        }
    }
    
    return temp
}

const genEditColor2=(i)=>{
    let temp="black"

    
    if(editAdminPrintPage){
        if(editAdminPrintPage.id){
            if(editAdminPrintPage.id==i.id){
                temp="green"
            }
        }
    }
    
    return temp
}


const getRefArray=(array)=>{
    let tempRefArray=[]

    if(Array){
        if(Array.isArray(array)){
            array.map(k=>{
                tempRefArray=[...tempRefArray,React.createRef()]
            })
        }
    }
    
    return tempRefArray
}

let [filterData,setFilterData]=React.useState([])
const [adminPrintPage,setAdminPrintPage]=React.useState([])
const [editAdminPrintPage,setEditAdminPrintPage]=React.useState(null)
const [showPrintPageForm2,setShowPrintPageForm2]=React.useState(false)


React.useEffect(()=>{
    //console.log('filterData')
    //console.log(filterData)
},[filterData])

const [printPageFilterData,setPrintPageFilterData]=React.useState({

        reloadData:true,
        dataUrl:"p35printpage",
        pageNumber:1,
        limitRow:50,
        sort:{id:1},
        qry:{},
        editData:null,

        data0:null,
        count:0,
        lastRecordId:0,

        showModalConfirm:false,
        showModalError:false,
})


React.useEffect(()=>{

    if(printPageFilterData.reloadData){
        const {dataUrl,pageNumber,limitRow,sort,qry}=printPageFilterData


        let result1

        axios.post(`/p35printpage/getlimit`,
                {   pageNumber:1,
                    limitRow:100,
                    sort,
                    adminShopId:process.env.REACT_APP_ADMINSHOP
                },
                myheader,shouldRender)
        .then (result=>{
            //console.log('result mmpos')
            //console.log(result.data)

            let tempArray=[]
            if(result){
                result.data.data.map(k=>{
                    tempArray=[...tempArray,{...k,id:uuid()}]
                })
            }
            result1=tempArray

            return axios.post(`/p35printpage/getlimit`,
                { pageNumber,
                  limitRow,
                  sort,...qry
                },
            myheader,shouldRender)

        })
        .then(result=>{
            //console.log('result1......')
            //console.log(result1.data.data)
            setAdminPrintPage(result1)
            //console.log(result.data)
            //const temp2= genFilterDataWithIndex(result.data.data)
            //console.log('result')
            //console.log(result.data.data)

            const tempResult={...printPageFilterData,
                data0:result.data.data,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                //isCsv:false,
                //isXlsx:false,
                reloadData:false,   
            }

            //console.log('tempResult..')
            //console.log(tempResult)

            setPrintPageFilterData(tempResult)
            setFilterData(result.data.data)

        })
        .catch(error=>{
            const tempError={...printPageFilterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setPrintPageFilterData(tempError)
        })
    }


},[printPageFilterData])

const [showPrintPageForm,setShowPrintPageForm]=React.useState(false)

const [editData,setEditData]=React.useState(null)

React.useEffect(()=>{
    //console.log('editData....')
    //console.log(editData)
},[editData])

//===========================
//============================
const confirmFunc2=(pageSetup)=>{
    submitFunction2(pageSetup,filterData)
} 

const updatePrintPage=(i)=>{

    const {_id,...tempObj}=i
    //console.log('updatePrintPage....')

    let tempArray=[]
    if(i.printSection){
        i.printSection.map(k=>{
            const {_id,...rmn}=k
            tempArray=[...tempArray,rmn]
        })
    }

    const tempObj2={...tempObj,printSection:tempArray}

    axios.post(`/p35printpage/updatecustom`,tempObj2,myheader,shouldRender)
    .then(result=>{
        //console.log('pass update')
       
        setPrintPageFilterData({
            ...printPageFilterData,
            reloadData:true,
            editData:null
        })
        setEditData(tempObj)
        setShowPrintPageForm(false)

    })
    .catch(error=>{
        const tempError = {...filterData,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setFilterData(tempError)
    })

}



const addPrintPage=()=>{
       

        const {_id,...tempObj}=editData

        const tempObj2={
            ...tempObj,
            id:printPageFilterData.lastRecordId+1,
            printPageName:editData.printPageName+'-copy'
        }


        axios.post(`/p35printpage/addcustom`,
        tempObj2,myheader,shouldRender)
        .then(result=>{
            //console.log('pass update')
           
            setPrintPageFilterData({
                ...printPageFilterData,
                reloadData:true,
                showModalConfirm:false
            })
            
        })
        .catch(error=>{
            const tempError = {...printPageFilterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            setPrintPageFilterData(tempError)
        })

}





const addFromAdminPrintPage=()=>{
  

    const {_id,shopId,...tempObj}=editAdminPrintPage

    const tempObj2={
        ...tempObj,
        shopId:basicData.shopId,
        id:printPageFilterData.lastRecordId+1,
        printPageName:editAdminPrintPage.printPageName+'-copy_from_admin'
    }


    axios.post(`/p35printpage/addcustom`,
    tempObj2,myheader,shouldRender)
    .then(result=>{
        //console.log('pass update')
       
        setPrintPageFilterData({
            ...printPageFilterData,
            reloadData:true,
            showModalConfirm:false
        })
        
    })
    .catch(error=>{
        const tempError = {...printPageFilterData,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setPrintPageFilterData(tempError)
    })

}





const deletePrintPage=()=>{

    if(editData.id>2){
        axios.post(`/p35printpage/deletecustom`,
            {id:editData.id},myheader,shouldRender)
            .then(result=>{
                //console.log('pass update')
                setPrintPageFilterData({
                    ...printPageFilterData,
                    reloadData:true,
                    showModalConfirm:false
                })
                
            })
            .catch(error=>{
                const tempError = {...printPageFilterData,
                    reloadData:false,
                    message:catchErrorToMessage(error),
                    showModalConfirm:false,
                    showModalError:true,
                }
                setPrintPageFilterData(tempError)
            })
    }
}

//===========================
const renderPrinPageOfAdminShop=()=>{
    return (
        <div className='w-100 bd-pureWhite'>
            <div className="w-100">
                <div className="flex-center-center">
                    <h5>{`${pageData.setting.dataFromAdminTeam}`}</h5>   
                </div>     
            </div>


            <div className='w-100' style={{display:"flex"}}>
                <div  style={{
                    marginRight:"1rem",
                    visibility:editAdminPrintPage?"visible":"hidden"

                }}>
                    <FaArrowCircleUp
                        className='md-icon'
                        onClick={e=>{
                            //setShowPrintPageForm(true)
                            addFromAdminPrintPage()
                        }}
                    />
                </div>
                <div  style={{
                    marginRight:"1rem",
                    visibility:editAdminPrintPage?"visible":"hidden"

                }}>
                    <MdEdit
                        className='md-icon'
                        onClick={e=>{
                            setShowPrintPageForm2(true)
                        }}
                    />
                </div>        


            </div>

            <div className="w-100">
                {
                adminPrintPage.map((i,idxI)=>{
                        return(
                            <div key={idxI}
                                style={{
                                        color:genEditColor2(i),
                                        marginBottom:"0.5rem"
                                    }}
                                onClick={e=>{
                                    setEditAdminPrintPage({
                                        ...i,
                                        printId:i.id
                                    })
                                    setEditData(null)
                                }}
                            >
                                {`${idxI+1}.${i.printPageName}`}
                            </div>

                        )
                    })
                }
            </div>

        </div>
    )
}



//===========================
const renderFooter=()=>{
    return(
    <div style={{display:"flex",width:"100%",marginTop:"2rem"}}
    >
        <div className="xc8 mc7 sc5"/>
        
        <div className="xc3 mc4 sc6" style={{display:"flex"}}>
                        
            <div>
                <button
                    ref={refCancelForm}
                    onKeyDown={e=>{
                        //if(e.key=="ArrowLeft"){
                            //refCancelForm.current.focus()
                        //}
                    }}
                    onClick={e=>{
                        setShow(false)
                    }}
                >
                    <FaBan/>
                </button>
            </div>
        </div>

    </div>   

    )

}
//================================
const renderToolBar=()=>{

    return(
        <div 
            className='w-100'
            style={{display:"flex",marginBottom:"1rem"}}
        >
            <div style={{
                    marginRight:"1rem",
                    visibility:editData?"visible":"hidden"
                    }}
                onClick={e=>{
                    addPrintPage()
                }}        
            >
                <FaPlusSquare
                    className='md-icon'
                />
            </div>

            <div  style={{
                marginRight:"1rem",
                visibility:editData?"visible":"hidden"
            }}>
                <FaMinusSquare
                    className='md-icon'
                    onClick={e=>{
                        deletePrintPage()
                    }}
                />
            </div>

            <div  style={{
                marginRight:"1rem",
                visibility:editData?"visible":"hidden"

            }}>
                <MdEdit
                    className='md-icon'
                    onClick={e=>{
                        setShowPrintPageForm(true)
                    }}
                />
            </div>

                    

        </div>
    )

}    

//===========================
const renderBody=(obj)=>{

return (
<div 
    className="hide-on-print w-100 flex-center-start jc-start" 
    style={{marginBottom:"0.2rem"}}>
   
    
    {
      renderToolBar()
    }

    <div className="w-100">
        {
            filterData.map((i,idx)=>{
                return(
                    <div key={idx}
                        style={{
                                color:genEditColor(i),
                                marginBottom:"0.5rem"
                               }}
                        onClick={e=>{
                            setEditData({
                                ...i,
                                printId:i.id
                            })

                            setEditAdminPrintPage(null)
                        }}
                    >
                        {`${i.id}.${i.printPageName}`}
                    </div>

                )
            })
        }
    </div>

    
    {
      renderPrinPageOfAdminShop()
    }    
  

    {
      renderFooter()
    }
     
</div>
   )
}
//===========================


//===========================
return(
<div>
 
    <div className="Modal-background hide-on-print">
        {
        showPrintPageForm||showPrintPageForm2
        ?showPrintPageForm   
            ?<div className='Modal-box' 
                style={{width:"100%",height:"100%",padding:"0",overflow:"hidden"}}
            >
                <PrintPageForm
                        printPageEditData={editData}
                        tableTemplate={tableTemplate}
                        pageData={pageData}
                        setShow={setShow}
                        confirmFunc2={updatePrintPage}
                        saveTableTemplateFunc={saveTableTemplateFunc}
                        calDigit={calDigit}
                        basicData={basicData}
                        setShowPrintFunc={setShowPrintFunc}
                        
                />    
            </div>
            :<div className='Modal-box' 
                style={{width:"100%",height:"100%",padding:"0",overflow:"hidden"}}
            >
                <PrintPageForm
                        printPageEditData={editAdminPrintPage}
                        tableTemplate={tableTemplate}
                        pageData={pageData}
                        setShow={setShow}
                        confirmFunc2={null}
                        saveTableTemplateFunc={saveTableTemplateFunc}
                        calDigit={calDigit}
                        basicData={basicData}
                        setShowPrintFunc={setShowPrintFunc}
                        
                />    
            </div>



        :<div className="Modal-box hide-on-print" 
            style={{paddingBottom:"1rem",minWidth:"70%",minHeight:"30%"}}>
        
            <div className="w-100">
                <div className="flex-center-center">
                    <h5>{`${pageData.setting.printPage}`}</h5>   
                </div>     
            </div>

            <div className="Modal-body" >
                    <div className="ModalInsideBody">
                        {
                            renderBody()
                        }
                    </div>
            </div>     
        </div>
        
        }
   
    </div>


</div>

)

}
export default PrintPage;

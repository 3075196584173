import React from 'react';
import stickerInputState from './stickerInputState'
import Calendar from '../../component/calendar/Calendar'
import uuid from 'react-uuid';
import {FaPlusSquare,FaMinusSquare,FaSearch,FaCheck,FaAsterisk,
    FaOrcid,FaBarcode,FaStripeS,
    FaBan,FaCaretUp,FaCaretDown} from 'react-icons/fa'; 
import {
    MdEdit,MdAddCircle,MdDelete
} from 'react-icons/md';
import renderBarcodeOut from './renderBarcodeOut';
import Ticon from '../../component/ticon/Ticon';
import console from '../../util/consoleVerify'


import axios from '../../util/axiosVerify';

import './Sticker.css'




function StickerForm(props) {

const {pageData,basicData,myheader,setShowPrintFunc,
      editData,setShow,setShowModalProduct,
      selectProduct,isAdd,
      submitAddFunc,  
      submitEditFunc
    }=props
const {stickerSettingForm}=pageData
//console.log('StickerForm')


const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])


const [showSetting,setShowSetting]=React.useState(true)
const [showPageSetUp,setShowPageSetUp]=React.useState(true)
const [showBarcodeSetUp,setShowBarcodeSetUp]=React.useState(true)
const [showProductData,setShowProductData]=React.useState(true)

const [editProduct,setEditProduct]=React.useState(null)

const [searchMode,setSearchMode]=React.useState("i")
const [searchInputState,setSearchInputState]=React.useState("")

const inputTemplate0=[
    {inputType:"number",width:["","60%","40%"],key:"id",lb:stickerSettingForm["id"] },
    {inputType:"number",width:["","60%","40%"],key:"newId",lb:stickerSettingForm["newId"] },
    {inputType:"text",
        width:["","60%","40%"],width2:["","80%","20%"],
        key:"recordName",lb:stickerSettingForm["recordName"] 
    },
    {inputType:"date",width:["","60%","40%"],key:"date", lb:stickerSettingForm["date"] },
    {inputType:"text",width:["","60%","40%"],key:"recordBy",lb:stickerSettingForm["recordBy"]},
    {inputType:"checkbox",width:["","60%","40%"],key:"active",lb:stickerSettingForm["active"]}
]

const inputTemplate1=[

    {inputType:"number",width:["","30%","70%"],key:"heightP",lb:stickerSettingForm["heightP"]},
    {inputType:"number",width:["","30%","70%"],key:"widthP",lb:stickerSettingForm["widthP"]},

    {inputType:"number",width:["","30%","70%"],key:"marginTopP",lb:stickerSettingForm["marginTopP"]},
    {inputType:"number",width:["","30%","70%"],key:"marginBottomP",lb:stickerSettingForm["marginBottomP"]},
    {inputType:"number",width:["","30%","70%"],key:"marginLeftP",lb:stickerSettingForm["marginLeftP"]},
    {inputType:"number",width:["","30%","70%"],key:"marginRightP",lb:stickerSettingForm["marginRightP"]},

    {inputType:"number",width:["","30%","70%"],key:"paddingTopP",lb:stickerSettingForm["paddingTopP"]},
    {inputType:"number",width:["","30%","70%"],key:"paddingBottomP",lb:stickerSettingForm["paddingBottomP"]},
    {inputType:"number",width:["","30%","70%"],key:"paddingLeftP",lb:stickerSettingForm["paddingLeftP"]},
    {inputType:"number",width:["","30%","70%"],key:"paddingRightP",lb:stickerSettingForm["paddingRightP"]},
    
    {inputType:"checkbox",width:["","30%","70%"],key:"borderBottomP",lb:stickerSettingForm["borderBottomP"]},

    {inputType:"number",width:["","30%","70%"],key:"scale",lb:stickerSettingForm["scale"]},
    {inputType:"number",width:["","30%","70%"],key:"marginTopBarcode",lb:stickerSettingForm["marginTopBarcode"]},
    {inputType:"number",width:["","30%","70%"],key:"marginBottomBarcode",lb:stickerSettingForm["marginBottomBarcode"]},

]

const inputTemplate2=[
    {inputType:"checkbox",width:["","30%","70%"],key:"showBarCode",lb:stickerSettingForm["showBarCode"]},
    {inputType:"number",width:["","30%","70%"],key:"heightB",lb:stickerSettingForm["heightB"]},
    {inputType:"number",width:["","30%","70%"],key:"widthB",lb:stickerSettingForm["widthB"]},
    {inputType:"number",width:["","30%","70%"],key:"xPerLine",lb:stickerSettingForm["xPerLine"]},
    
    {inputType:"checkbox",width:["","30%","70%"],key:"showBorder",lb:stickerSettingForm["showBorder"]},
    {inputType:"number",width:["","30%","70%"],key:"gridRowGap",lb:stickerSettingForm["gridRowGap"]},
    {inputType:"number",width:["","30%","70%"],key:"gridColumnGap",lb:stickerSettingForm["gridColumnGap"]},

    {inputType:"checkbox",width:["","30%","70%"], key:"showCode",lb:stickerSettingForm["showCode"]},
    {inputType:"number",width:["","30%","70%"], key:"fontSizeCode",lb:stickerSettingForm["fontSizeCode"]},

    {inputType:"checkbox",width:["","30%","70%"], key:"showName",lb:stickerSettingForm["showName"]},
    {inputType:"number",width:["","30%","70%"], key:"fontSizeName",lb:stickerSettingForm["fontSizeName"]},

    {inputType:"checkbox",width:["","30%","70%"], key:"showPrice",lb:stickerSettingForm["showPrice"]},
    {inputType:"number",width:["","30%","70%"], key:"fontSizePrice",lb:stickerSettingForm["fontSizePrice"]},

    {inputType:"checkbox",width:["","30%","70%"], key:"showUnit",lb:stickerSettingForm["showUnit"]},
    {inputType:"number",width:["","30%","70%"], key:"fontSizeUnit",lb:stickerSettingForm["fontSizeUnit"]},

]

const genEditData=(data)=>{
    let tempArray=[]
    data.productData.map(n=>{
        const tempObj={...n,_id:uuid()}
        tempArray=[...tempArray,tempObj]
    })

    return {...data,productData:tempArray}
}

const genSelectInputForBarCode=(data)=>{
    let tempArray=[]
    data.productData.map(n=>{
        const tempObj={showSelect:false}
        tempArray=[...tempArray,tempObj]
    })
    return tempArray
}


const [inputState,setInputState]=React.useState(genEditData(editData))
//const [inputState,setInputState]=React.useState({...stickerInputState})
React.useEffect(()=>{
    //console.log('inputState......')
    //console.log(inputState)
},[inputState])

const [showSelectInputForCode,setShowSelectInputForCode]=
    React.useState(genSelectInputForBarCode(editData))

React.useEffect(()=>{
    //console.log('inputState')
    //console.log(inputState)
},[inputState])

React.useEffect(()=>{
    if(selectProduct){
        
        
        const tempProductData=[...inputState.productData,...selectProduct]
        //console.log('tempProductData')
        //console.log(tempProductData)
        
        setShowSelectInputForCode(genSelectInputForBarCode({...inputState,productData:tempProductData}))
        setInputState({...inputState,productData:tempProductData})

    }
},[selectProduct])

const handleInputState=({e,key,inputType})=>{
    let value
    if(inputType=="checkbox"){
        value=e.target.checked
    }
    else {
        value=e.target.value
    }
    setInputState({...inputState,[key]:value})
}

const moveUpProduct=()=>{
    if(editProduct){
        let tempArray=[]
        let tempIdx=null

        let productDataLength=inputState.productData.length
        //console.log('length')
        //console.log(productDataLength)

        inputState.productData.map((k,idxK)=>{
            if(k._id==editProduct._id){
                tempIdx=idxK
            }
        })

        //console.log('tempIdx')
        //console.log(tempIdx)

        if(tempIdx>0){

            if(productDataLength>2){
                inputState.productData.map((k,idxK)=>{
                    if(idxK==tempIdx-1){
                        tempArray=[...tempArray,inputState.productData[tempIdx]]
                    }
                    else if(idxK==tempIdx){
                        tempArray=[...tempArray,inputState.productData[tempIdx-1]]
                    }
                    else{
                        tempArray=[...tempArray,k]
                    }
                })
            }
            
            if(productDataLength==2){
                tempArray=[inputState.productData[1],inputState.productData[0]]
            }

            const tempInputState={...inputState,productData:tempArray}

            setShowSelectInputForCode(genSelectInputForBarCode(tempInputState))
            setInputState(tempInputState)
        }

    }
}

const moveDownProduct=()=>{
    if(editProduct){
        let tempArray=[]
        let tempIdx=null

        let productDataLength=inputState.productData.length
        //console.log('length')
        //console.log(productDataLength)

        inputState.productData.map((k,idxK)=>{
            if(k._id==editProduct._id){
                tempIdx=idxK
            }
        })

        //console.log('tempIdx')
        //console.log(tempIdx)

        if(tempIdx<productDataLength-1){

            if(productDataLength>2){
                inputState.productData.map((k,idxK)=>{
                    if(idxK==tempIdx){
                        tempArray=[...tempArray,inputState.productData[tempIdx+1]]
                    }
                    else if(idxK==tempIdx+1){
                        tempArray=[...tempArray,inputState.productData[tempIdx]]
                    }
                    else{
                        tempArray=[...tempArray,k]
                    }
                })
            }
            
            if(productDataLength==2){
                tempArray=[inputState.productData[1],inputState.productData[0]]
            }

            const tempInputState={...inputState,productData:tempArray}
            setShowSelectInputForCode(genSelectInputForBarCode(tempInputState))
            setInputState(tempInputState)
        }

    }
}

const deleteProduct=()=>{
    if(editProduct){
        let tempArray=[]

        inputState.productData.map((k,idxK)=>{
            if(k._id!=editProduct._id){
                tempArray=[...tempArray,k]
            }
        })

        const tempInputState={...inputState,productData:tempArray}

        setShowSelectInputForCode(genSelectInputForBarCode(tempInputState))

        setInputState(tempInputState)
    }
}



const searchProduct=(e)=>{
    
    //console.log("searchProduct....")

    if(e.target){
        if(!e.target){return}
    }

   


    if(e.key=="Enter"){
        
        if(!searchInputState){
            let tempProduct={
                _id:uuid(),
                qty:1,
                black: true,

                code:"",
                id:"",
                barcode:"",
                sn:"",

                name:"",
                price:"",
                unit:""
            }

            const tempInputState={...inputState,
                productData:[...inputState.productData,tempProduct]
            }
            setShowSelectInputForCode(genSelectInputForBarCode(tempInputState))

            setInputState(tempInputState)    
        }

        else{

                let tempQry=null

                if(searchMode=="i"){
                    tempQry={id:searchInputState}
                }
                else if(searchMode=="b"){
                    tempQry={barcode:searchInputState}
                }
                else if(searchMode=="s"){
                    tempQry={sn:searchInputState}
                }


                if(!tempQry){return}

                //console.log('findProductById')
                axios.post('/p35product/getlimit',tempQry,myheader,shouldRender)
                .then(result=>{
                    setSearchInputState("")

                    //console.log(result.data.data[0])

                    if(result.data.data[0]){                
                        let temp=result.data.data[0]

                        let tempProduct={
                            _id:uuid(),
                            qty:1,
                            black: true,

                            code:temp.id,
                            id:temp.id,
                            barcode:temp.barcode,
                            sn:temp.sn,

                            name:temp.productName,
                            price:temp.price,
                            unit:temp.unit
                        }
                            
                    
                        const tempInputState={...inputState,
                            productData:[...inputState.productData,tempProduct]
                        }
                        setShowSelectInputForCode(genSelectInputForBarCode(tempInputState))

                        setInputState(tempInputState)

                        //console.log(tempProduct)
                    }
                })
                .catch(error=>{

                })
        }


    }
}


const deleteIdx=(idx)=>{
    let tempArray=[]

    inputState.productData.map((k,idxK)=>{
            
            if(idx!=idxK){
                tempArray=[...tempArray,k]
            }

    })

    const tempInputState={...inputState,productData:tempArray}

    setShowSelectInputForCode(genSelectInputForBarCode(tempInputState))

    setInputState(tempInputState)
}

const setZero=()=>{
    let tempArray=[]

    inputState.productData.map((k,idxK)=>{
            tempArray=[...tempArray,{...k,qty:0}]
    })

    const tempInputState={...inputState,productData:tempArray}
    setInputState(tempInputState)
}

const setOne=()=>{
    let tempArray=[]

    inputState.productData.map((k,idxK)=>{
            tempArray=[...tempArray,{...k,qty:1}]
    })


    const tempInputState={...inputState,productData:tempArray}
    setInputState(tempInputState)
}

const plusOne=()=>{
    let tempArray=[]

    inputState.productData.map((k,idxK)=>{
            tempArray=[...tempArray,
                {...k,qty:k.qty+1}
            ]
    })

    //console.log('plusOne...')
    //console.log(tempArray)

    const tempInputState={...inputState,productData:tempArray}
    setInputState(tempInputState)
}

const minusOne=()=>{
    let tempArray=[]

    inputState.productData.map((k,idxK)=>{
            if(k.qty>=1){
                tempArray=[...tempArray,
                    {...k,qty:k.qty-1}
                ]
            }
            else{
                tempArray=[...tempArray,
                    {...k,qty:k.qty}
                ]
            }
    })

    const tempInputState={...inputState,productData:tempArray}
    setInputState(tempInputState)

}

const handleProductDataInput=(value,idx,key)=>{
    let temp=inputState.productData
    let tempArray=[]

    temp.map((obj,index)=>{
        if(idx==index){
            const tempObj={...obj,[key]:value}
            tempArray=[...tempArray,tempObj]
        }
        else{
            tempArray=[...tempArray,obj]
        }
    })

    setInputState({...inputState,productData:tempArray})
}

const switchBlackBox=(i,idx)=>{

    let tempProductData=inputState.productData

    tempProductData[idx]={...tempProductData[idx],black:!i.black}

    setInputState({...inputState,productData:tempProductData})
}

const checkIsEditProduct=(i)=>{
    let tempColor="black"
    if(editProduct){
        inputState.productData.map(k=>{
            if(i._id==editProduct._id){
                tempColor="red"
            }
        })
    }
    
    return tempColor
}

const renderData=(i,idx)=>{

    const {code,id,sn,barcode,name,unit,price,qty,selectedLine,black}=i
    const optionArray=[id,barcode,sn]

    return (
        <div key={idx} className="sticker-data" 
             style={{
                 display:"flex",width:"100%",flexWrap:"wrap",padding:"0.3rem"
             }}>
        

            <div className="xc1 lc1 mc6 sc6"
                    style={{display:"flex",alignItems:"flex-start", 
                            justifyContent:"center",
                           }}>
               
                <MdDelete className='sm-icon'
                    onClick={e=>{
                        deleteIdx(idx)
                    }}
                
                />

                

                <div style={{width:"1.3rem",marginRight:"1rem"}}>
                    <input
                        type="checkbox"
                        checked={black}
                        onChange={e=>{
                            switchBlackBox(i,idx)
                        }}
                    />
                </div>
                
                <div style={{width:"1.3rem"}}>
                    <input
                        type="checkbox"
                        checked={showSelectInputForCode[idx].showSelect}
                        onChange={e=>{
                            let tempArray=[]

                            showSelectInputForCode.map((j,idxJ)=>{
                                if(idxJ==idx){
                                    tempArray=[...tempArray,{showSelect:e.target.checked}]
                                }    
                                else{
                                    tempArray=[...tempArray,j]
                                }
                            })
                            setShowSelectInputForCode(tempArray)
                            //switchBlackBox(i,idx)
                        }}
                    />
                </div>
            </div>

            <div className="xc2 lc2 mc6 sc6"
                 style={{display:"flex"}}>
                <div className="" 
                    style={{width:"25%",textAlign:"center",
                            margin:"auto 0",
                            //paddingLeft:"1rem",
                            color:checkIsEditProduct(i)
                        }}
                    
                    onClick={e=>{setEditProduct(i)}}
                    >
                    {stickerSettingForm.code}
                </div>

                <div style={{width:"75%"}}>
                   {showSelectInputForCode[idx].showSelect
                   ?<select
                        onChange={e=>{
                            //console.log(e.target.value)
                            let tempArray=[]
                            inputState.productData.map((l,idxL)=>{
                                if(idx==idxL){
                                    tempArray=[...tempArray,{...l,code:e.target.value}]
                                }
                                else{
                                    tempArray=[...tempArray,l]
                                }
                            })
                            //console.log(tempArray)
                            setInputState({...inputState,productData:tempArray})
                        }}
                   >    
                        <option>...</option>
                        {
                        optionArray.map((k,idx)=>{
                            return(
                                <option key={idx} 
                                    value={k}
                                    selected={k==code?"selected":""}    
                                >
                                    {k}
                                </option>
                            )
                        })
                        }
                   </select>
                   :<input
                        value={inputState.productData[idx].code}
                        onChange={e=>{
                            let tempArray=[]
                            inputState.productData.map((k,idxK)=>{
                                if(idxK==idx){ 
                                    const tempObj={...k,code:e.target.value}
                                    tempArray=[...tempArray,tempObj]
                                }
                                else{
                                    tempArray=[...tempArray,k]
                                }
                            })
                            
                            setInputState({...inputState,productData:tempArray})
                        }}
                    />
                    }
                </div>
              
                
            </div>

            <div className="xc6 lc6 mc12 sc12"
                 style={{display:"flex"}}>
                <div className="" 
                     style={{width:"20%",textAlign:"center",
                             margin:"auto 0",
                             paddingLeft:"0.3rem",
                             color:checkIsEditProduct(i)
                            }}
                    onClick={e=>{setEditProduct(i)}}    
                >
                    {stickerSettingForm.name}
                </div>
                <input className="" style={{width:"80%"}} type="text" value={name}
                    onChange = { e => handleProductDataInput(e.target.value,idx,"name")}

                />
                
            </div>

            <div className="xc1 lc1 mc6 sc6"
                 style={{display:"flex"}}>
                <div className="" 
                    style={{width:"40%",textAlign:"center",
                           margin:"auto 0",
                           //paddingLeft:"1rem",
                           color:checkIsEditProduct(i),
                           fontSize:"0.6rem"
                           }}
                    onClick={e=>{setEditProduct(i)}}
                >
                    {stickerSettingForm.price}
                </div>
                
                <input className="" style={{width:"60%"}} type="number" value={price} 
                    onChange = { e => handleProductDataInput(e.target.value,idx,"price")}
                />
                
            </div>

            <div className="xc1 lc1 mc6 sc6" 
                 style={{display:"flex"}}>
                <div className="" 
                     style={{width:"40%",textAlign:"center",
                            margin:"auto 0",
                            //paddingLeft:"1rem",
                            color:checkIsEditProduct(i),
                            fontSize:"0.6rem"
                            }}
                    onClick={e=>{setEditProduct(i)}}            
                >
                    {stickerSettingForm.unit}
                </div>
                <input className="" style={{width:"60%"}} type="text" value={unit}
                    onChange = { e => handleProductDataInput(e.target.value,idx,"unit")}
                />
                
            </div>

            <div className="xc1 lc1 mc6 sc6"
                 style={{display:"flex"}}>
                <div className="" 
                    style={{width:"40%",textAlign:"center",
                            margin:"auto 0",
                            //paddingLeft:"1rem",
                            color:checkIsEditProduct(i),
                            fontSize:"0.6rem"

                    }}
                    onClick={e=>{setEditProduct(i)}}
                >
                    {stickerSettingForm.qty}
                </div>
                <input className="" 
                      style={{width:"60%"}} type="number" value={qty}
                    onChange = { e => handleProductDataInput(e.target.value,idx,"qty")}
                />
            </div>

           
            
        </div>
    )
}


const renderInputFromTemplate=({
    i,idx
})=>{
    const {width,width2,lb,key,inputType} = i
   
    let tempValue=inputState[key]
    if(inputType=="date"){
        if(inputState[key]){
            tempValue=inputState[key]
        }
    }

    return (
        <div key={idx} 
             className={key=="recordName"||key=="date"
                       ?key=="recordName"
                        ?"xc4 lc6 mc6 sc6"
                        :"xc3 lc3 mc6 sc6"
                       :"xc2 lc3 mc4 sc6"
                    }
             style={{display:"flex",
                     margin:"0.2rem 0",padding:"0 6px 0 0"}}>
            
            <div className="" 
             style={{width:key=="recordName"?width2[2]:width[2],
                     display:"flex",alignItems:"center",
                     justifyContent:"flex-end"}}>
                
                {key=="id"
                ?<div
                    style={{marginRight:"0.3rem"}}
                >
                    {
                    isAdd
                    ?<FaAsterisk
                        style={{color:"red"}}
                    />
                    :<MdEdit
                        style={{color:"red"}}
                    />
                    }
                </div>
                :null
                }
                <div style={{textAlign:"right",margin:"auto 0",
                             paddingRight:"0.5rem"}}>
                    {lb}
                </div>

                {key=="widthP"||key=="heightP"
                ?<div style={{textAlign:"right",margin:"auto 0",
                              color:"red",cursor:"pointer"}}
                    onClick={e=>{
                        setInputState({...inputState,useMm:!inputState.useMm})
                    }}
                >
                    {inputState.useMm?`(mm)`:`(%)`}
                </div>
                :null
                }
            </div>
            
            {inputType=="date"
            ?<Calendar
                showDateOption={"datetime"}
                style={{height:"1.6rem"}}
                onMyClick={
                (e)=>{
                    //console.log(e)
                    setInputState({...inputState,[key]:e})
                }
                }
                value={tempValue}
            />
            :inputType=="checkbox"
                ?<div style={{width:width[1]}}>
                    <input  
                        checked={inputState[key]}
                        value={tempValue}
                        type={inputType}
                        onChange={e=>handleInputState({e,key,inputType})}
                    />   
                 </div>
                :<input  
                        style={{width:key=="recordName"?width2[1]:width[1]}}
                        checked={inputState[key]}
                        value={tempValue}
                        type={inputType}
                        onChange={e=>handleInputState({e,key,inputType})}
                />
                }
        </div>
    )
}


const renderInput=()=>{
    return (
        <div className="flex-center-center" style={{width:"100%"}}>
                
                <div className="xc12 bd-pureWhite" 
                     style={{display:"flex",flexWrap:"wrap",
                             margin:"0.5rem"
                     }}
                >

                    <div className="xc12 " 
                         style={{display:"flex"}}
                    >
                        <div
                            style={{fontSize:"1.2rem"}}
                        >
                            {stickerSettingForm.setting}
                        </div>  
                        <div>
                            {
                            showSetting
                            ?<FaCaretDown className="md-icon"
                                onClick={e=>{
                                    setShowSetting(false)
                                }}
                            />
                            :<FaCaretUp className='md-icon'
                                onClick={e=>{
                                    setShowSetting(true)
                                }}
                            />
                            }
                        </div>
                    </div>

                    {   showSetting
                        ?inputTemplate0.map((i,idx)=>{
                            return (
                                renderInputFromTemplate({
                                    i,idx,
                                })
                            )
                        })
                        :null
                    }
                </div>

                <div className="xc12 bd-pureWhite" 
                     style={{display:"flex",flexWrap:"wrap",
                             margin:"0.5rem"
                     }}
                >

                    <div className="xc12" 
                         style={{display:"flex"}}>  
                        <div style={{fontSize:"1.2rem"}}>
                            {stickerSettingForm.pageSetUp}
                        </div>
                        <div>
                            {
                            showPageSetUp
                            ?<FaCaretDown className="md-icon"
                                onClick={e=>{
                                    setShowPageSetUp(false)
                                }}
                            />
                            :<FaCaretUp className='md-icon'
                                onClick={e=>{
                                    setShowPageSetUp(true)
                                }}
                            />
                            }
                        </div>
                       
                    </div>
                    {   showPageSetUp
                        ?inputTemplate1.map((i,idx)=>{
                        //?[].map((i,idx)=>{
                            return (
                                    renderInputFromTemplate({
                                        i,idx,
                                    })
                                )
                        })
                        :null
                    }


                </div>

                <div className="xc12 bd-pureWhite" 
                     style={{display:"flex",flexWrap:"wrap",
                             margin:"0.5rem"
                      }}
                >

                    <div className='xc12' style={{display:"flex"}}>
                        <div style={{fontSize:"1.2rem"}}>
                            {stickerSettingForm.barcodeSetUp}
                        </div>
                        <div>
                            {
                            showBarcodeSetUp
                            ?<FaCaretDown className="md-icon"
                                onClick={e=>{
                                    setShowBarcodeSetUp(false)
                                }}
                            />
                            :<FaCaretUp className='md-icon'
                                onClick={e=>{
                                    setShowBarcodeSetUp(true)
                                }}
                            />
                            }
                        </div>
                    </div>
                        {   
                        showBarcodeSetUp
                            ?inputTemplate2.map((i,idx)=>{
                                return(
                                    renderInputFromTemplate({
                                        i,idx,
                                    })
                                )
                            })
                            :null
                        }
                
                </div>
            
        </div>
    )
}

const renderProductData=()=>{
    return(
        <div className="flex-center-center" style={{width:"100%"}}>
            <div className="xc12 bd-pureWhite" 
                    style={{display:"flex",flexWrap:"wrap",
                        margin:"0.5rem"
                        }}
            >
                <div className='xc12' style={{display:"flex"}}>
                    <div style={{fontSize:"1.2rem"}}>
                        {stickerSettingForm.productData}
                    </div>
                    <div>
                        {
                        showProductData
                        ?<FaCaretDown className="md-icon"
                            onClick={e=>{
                                setShowProductData(false)
                            }}
                        />
                        :<FaCaretUp className='md-icon'
                            onClick={e=>{
                                setShowProductData(true)
                            }}
                        />
                        }
                    </div>
                </div>
                {   showProductData
                    ?inputState.productData.map((i,idx)=>{
                        return(
                            renderData(i,idx)
                        )
                    })
                    :null
                }
            </div>
        </div>
    )
}

const renderTool=()=>{
return (
    <div style={{display:"flex",justifyContent:"flex-start",
               }}
    >
        
        
                <div className="iconbox"
                    onClick={e=>{
                        //printFunc()
                        //setReloadPrint(true)
                        //window.print()
                        setShowPrintFunc(null)
                        setTimeout(()=>{
                                setShowPrintFunc(inputState)
                        },[400])
                        }}
                >
                    <Ticon
                        iconName="MdPrint"
                        className="stickerIcon"
                    />
                </div>
            
                <div className="iconbox"
                    onClick={e=>{
                        setShowModalProduct(true)
                    }}
                >
                    <Ticon
                        iconName="MdAddCircle"
                        className="stickerIcon"
                    />
                </div>

                {/*
                <div className="iconbox"
                    onClick={e=>{
                        deleteProduct()
                    }}
                >
                    <Ticon
                        iconName="MdDelete"
                        className="stickerIcon"
                    />
                </div>    
                */}

                <div className="iconbox"
                    onClick={e=>{
                        moveUpProduct()
                    }}
                >
                    <Ticon
                        iconName="FaRegArrowAltCircleUp"
                        className="stickerIcon"
                    />
                </div>


                <div className="iconbox"
                    onClick={e=>{
                        moveDownProduct()
                    }}
                >
                    <Ticon
                        iconName="FaRegArrowAltCircleDown"
                        className="stickerIcon"
                    />
                </div>

            
                <div className="iconbox"
                    onClick={e=>{
                        if(isAdd){
                            submitAddFunc(inputState)
                        }
                        else{
                            submitEditFunc(inputState)
                        }
                    }}
                >
                    {
                    isAdd
                    ?<Ticon
                        iconName="MdSave"
                        className="stickerIcon"
                    />
                    :<Ticon
                        iconName="VscSaveAs"
                        className="stickerIcon"
                    />
                    }
                </div>   
                

                <div className="iconbox"
                    onClick={e=>{
                        //setZero()
                        minusOne()
                    }}
                >
                    <Ticon
                        iconName="MdExposureNeg1"
                        className="stickerIcon"
                    />
                </div>

                <div className="iconbox"
                    onClick={e=>{
                        setZero()
                        
                    }}
                >
                    <Ticon
                        iconName="RiNumber0"
                        className="stickerIcon"
                    />
                </div>

                <div className="iconbox"
                    onClick={e=>{
                        //setOne()
                        plusOne()
                    }}
                >
                    <Ticon
                        iconName="MdExposurePlus1"
                        className="stickerIcon"
                    />
                </div>



                <div className="iconbox"
                    onClick={e=>{
                        setShow(false)
                    }}
                >
                    <Ticon
                        iconName="MdClose"
                        className="stickerIcon"
                    />
                </div>

        
                <div style={{
                        width:"17%"
                    }}
                >
                    <input
                        value={searchInputState}
                        onChange={e=>{
                            //searchProduct(e.target.value)
                            setSearchInputState(e.target.value)
                        }}

                        onKeyDown={e=>{
                            searchProduct(e)     
                        }}
                    />
                </div>

                <div
                    style={{color:searchMode=="i"?"red":""}}
                    onClick={e=>{
                        setSearchMode("i")
                    }}
                >
                    <FaOrcid className='sm-icon'/>
                </div>
                <div 
                    style={{color:searchMode=="b"?"red":""}}
                    onClick={e=>{
                        setSearchMode("b")
                    }}
                >
                    <FaBarcode className='sm-icon'/>
                </div>
                
                <div 
                    style={{color:searchMode=="s"?"red":""}}
                    onClick={e=>{
                        setSearchMode("s")
                    }}
                >
                    <FaStripeS className='sm-icon'/>
                </div>
    </div>
)}


return(

<div style={{width:"100%",height:"100%",overflow:"hidden"}}> 
    
    <div style={{width:"100%",marginBottom:"1rem"}}>
    {  
        renderInput()
    }
    </div>

    <div style={{width:"100%",height:"2rem"}}>
    {
        renderTool()
    }
    </div>

    <div style={{width:"100%",marginBottom:"1rem"}}>
    {  
        renderProductData()
    }
    </div>
    
    <div className='bd-pureWhite' 
        style={{width:"100%",
               marginBottom:"1rem"
               
               }}>
    {
        renderBarcodeOut(inputState)
    }
    </div>
</div>
)



}
export default StickerForm;
import React from 'react';
import {MainContext} from '../../context/MainContext'
import Navbar from '../../component/navbar/Navbar';
import renderBarcodeOut from './renderBarcodeOut';
import axios from '../../util/axiosVerify'

import PageComponentBarcode  from '../../component/pageComponent/PageComponentBarcode';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import console from '../../util/consoleVerify'

function Sticker() {

//console.log('Sticker')

const {basicDataSt,tokenSt,setReloadCheckToken,
       myheader,setReloadBasicData,
       mainContextBarcodeFilterData,setMainContextBarcodeFilterData
    }=React.useContext(MainContext)

const {basicData,pageData,tableTemplate}=basicDataSt
const {stickerSettingForm}=pageData



const shouldRender= React.useRef(true)

React.useEffect(()=>{

   

    return () => {
        //console.log('end.................Sticker................')
        shouldRender.current=false
    } 
},[])



const [printData,setPrintData]=React.useState(null)

React.useEffect(()=>{
    if(printData){
        window.print()
    }
},[printData])

const setShowPrintFunc=(data)=>{
    setPrintData(data)
}

return (
<div>
    {printData&&
    <div className='hide-on-screen'>
        {
         renderBarcodeOut(printData)
        }
    </div>
    }

    <div className="hide-on-print"
         style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
       
        
        <div className="h-100 w-100">
            {basicDataSt.basicData&&myheader&&
            <PageComponentBarcode
                setReloadBasicData={setReloadBasicData}
                filterDataTemplate={filterDataTemplate}
                filterTemplate={basicDataSt.pageFilter.stickerFilter}
                inputState={inputState.stickerInputState}
                productInputState={inputState.productInputState}
                basicDataSt={basicDataSt}
                myheader={myheader}
                
                formTemplate={basicDataSt.modalFormTemplate.partnerForm}
                editFormTemplate={basicDataSt.modalFormTemplate.partnerEditForm}
                
                stateTemplate={StateTemplate.partnerState}
                pageFilterForm={basicDataSt.pageFilterForm.stickerFilterForm}

                modalFormTemplate={basicDataSt.pageData.ModalForm}
                addFormTitle={basicDataSt.pageData.ModalForm.partnerAddForm}
                editFormTitle={basicDataSt.pageData.ModalForm.partnerEditForm}
                bgColor={"#94abd1"}
                calDigit={100}
                setShowPrintFunc={setShowPrintFunc}
                mainContextBarcodeFilterData={mainContextBarcodeFilterData}
                setMainContextBarcodeFilterData={setMainContextBarcodeFilterData}
            />
            }
        </div>
    </div>
</div>
)}
export default Sticker;


/*


 <div className="h-95 w-100" style={{overflowY:"auto"}}>
            {pageData&&basicData&&
                <StickerForm
                    pageData={pageData}
                    basicData={basicData}
                    myheader={myheader}
                    setShowPrintFunc={setShowPrintFunc}
                />
            }
        </div>


*/
import React from 'react';
import {FaPlusSquare,FaMinusSquare,FaCheck,FaBan} from 'react-icons/fa'; 
import {MdCheckBox,MdCheckBoxOutlineBlank} from 'react-icons/md';
import uuid from 'react-uuid'
import console from '../../util/consoleVerify'

function BranchSettingForm(props) {

const {confirmFunc,setShow,pageData,basicData}=props

console.log('BranchSettingForm')
console.log(basicData)




const refSubmitForm=React.createRef() //ตกลง
const refCancelForm=React.createRef() //ยกเลิก    

const refPrintPageSetting=React.createRef()
const refWidthLeft=React.createRef()
const refLimitRow=React.createRef()
const refPageHeight=React.createRef()

const genTable=(array)=>{
   let tempArray=[]

   if(array){
      if(array.length>0){
         array.map(i=>{
            const {_id,...remaining}=i
      
            tempArray=[...tempArray,remaining]
         })      
      }
   }

   return tempArray
}

const [inputData,setInputDataData]=React.useState({
   //languageSetting:basicData.languageSetting,
   //printPageSetting:basicData.printPageSetting,
   //printPageSetting2:basicData.printPageSetting2,
   widthLeft:basicData.widthLeft,
   //thisBranch:basicData.thisBranch,
   limitRow:basicData.limitRow,
   pageHeight:basicData.pageHeight,
   tileMotherWidth:basicData.tileMotherWidth,
   tileMotherHeight:basicData.tileMotherHeight,
   imgWidth:basicData.imgWidth,
   imgHeight:basicData.imgHeight,
   pic:basicData.pic,
   storage:basicData.storage,
   imgGroupHeight:basicData.imgGroupHeight,
   defaultCustomerId:basicData.defaultCustomerId,
   defaultTransactionTypeId:basicData.defaultTransactionTypeId,
   showTable:basicData.showTable,
   table:genTable(basicData.table)
})
/*
const gendTable=(array)=>{
   let tempArray=[]
   array.map(i=>{
      tempArray=[...tempArray,{...i,_idx:uuid()}]
   })
   return tempArray
}

const [table,setTable]=React.useState(
   gendTable(basicData.table)
)
*/
const [editData,setEditData]=React.useState({
   _idx:null,
   tableName:null,
   tableActive:false
})

const deleteTable=(idx)=>{
   const {table}=inputData

   let tempArray=[]
   table.map((j,idxJ)=>{
      if(idx!=idxJ){
         tempArray=[...tempArray,j]
      }
   })
   setInputDataData({
      ...inputData,
      table:tempArray
   })
}

const addTable=(idx)=>{

   //console.log(i)
   //console.log(table)
   const {table}=inputData

   let tempArray=[]
   table.map((j,idxJ)=>{
      if(idx==idxJ){
         const tempObj={
                        tableName:"",
                        tableActive:true
                     }
         tempArray=[...tempArray,j,tempObj]
      }
      else{
         tempArray=[...tempArray,j]
      }
   })
   //console.log(tempArray)

   setInputDataData({
      ...inputData,
      table:tempArray
   })   
}


const changeTableActive=(e,idx)=>{
   const {table}=inputData

   let tempArray=[]
   table.map((j,idxJ)=>{
      if(idx==idxJ){
         tempArray=[...tempArray,{...j,tableActive:e.target.checked}]
      }
      else{
         tempArray=[...tempArray,j]
      }
   })
   //console.log(tempArray)

   setInputDataData({
      ...inputData,
      table:tempArray
   })   
}

const changeTableName=(e,idx)=>{
   const {table}=inputData

   let tempArray=[]
   table.map((j,idxJ)=>{
      if(idx==idxJ){
         tempArray=[...tempArray,{...j,tableName:e.target.value}]
      }
      else{
         tempArray=[...tempArray,j]
      }
   })
   //console.log(tempArray)

   setInputDataData({
      ...inputData,
      table:tempArray
   })  
}



const handleChange=(e,i)=>{
   setInputDataData({...inputData,[i]:e.target.value})
}

const addRow1=(idx,i)=>{
   setInputDataData({...inputData,[i]:[""]})
}

const addRow=(idx,i)=>{
   let tempArray=[]

   inputData[i].map((j,idxJ)=>{
      if(idx==idxJ){
         tempArray=[...tempArray,j,""]
      }
      else{
         tempArray=[...tempArray,j]
      }
   })
   setInputDataData({...inputData,[i]:tempArray})

}

const deleteRow=(idx,i)=>{
   let tempArray=[]

   inputData[i].map((j,idxJ)=>{
      if(idx!=idxJ){
         tempArray=[...tempArray,j]
      }
   })

   setInputDataData({...inputData,[i]:tempArray})
}

const changeInputState=(e,idx,i)=>{
   
   let tempArray=[]

   inputData[i].map((j,idxJ)=>{
      if(idx==idxJ){
         tempArray=[...tempArray,e.target.value]
      }
      else{
         tempArray=[...tempArray,j]
      }
   })
   //console.log(e.target.value)
   //console.log(tempArray)

   setInputDataData({...inputData,[i]:tempArray})

}
//================================
const renderFooter=()=>{
   return(
   <div style={{display:"flex",
                  position:"fixed",
                  bottom:"1rem",right:"2rem",zIndex:"100"}}
   >
         <div>
            <button
                ref={refSubmitForm}
                onClick={e=>{
                     if(confirmFunc){
                        confirmFunc(inputData)
                     }
               }}
            >
               <FaCheck/>
            </button>
         </div>
         
         <div>
            <button
                  ref={refCancelForm}
                  onClick={e=>{
                     setShow(false)
                  }}
            >
               <FaBan/>
            </button>
         </div>
   </div>
   )
}
//=====================================
const renderInput=(i)=>{

   const tempObj={
      //languageSetting:"listOfLanguage",
     // printPageSetting:"listOfPrintPage",
     // printPageSetting2:"listOfPrintPage",
      //thisBranch:"branch"
   }

   if(i=="languageSetting"
      //i=="printPageSetting"||
      //i=="printPageSetting2"
      ){
        return(
         <select
            onChange={e=>handleChange(e,i)}
         >
            {basicData[tempObj[i]].map((j,idx21)=>(
               <option key={`${idx21}-renderInput`}
                  selected={inputData[i]==j?"selected":""}
               >{j}</option>
            ))}
         </select>
        )
   }
   /*
   else if(i=="thisBranch"){
      return(
      <div>
         {inputData[i]}
      </div>
      )
   }
   */
   else if(i=="showTable") {
      return(
         <input
            type={"checkbox"}
            checked={inputData[i]}
            onChange={e=>{
                  setInputDataData({...inputData,[i]:e.target.checked})
            }}
         />
      )
   }
   else {
      return(
         <input
            type={"number"}
            value={inputData[i]}
            onChange={e=>handleChange(e,i)}
         />
      )
   }
}

//---------------------------
/*
const renderTable=()=>{
return table.map((i,idx)=>{
      return (//editData._idx==i._idx
         <div className="flex-center-center jc-start" key={idx}
            style={{margin:"0.5rem 0"}}
         >
               <FaPlusSquare className="sm-icon"
                                 onClick={e=>{
                                    addTable(i)
                                 }}
               />
               
               <FaMinusSquare className="sm-icon"
                  style={{opacity:idx>0?"1":"0"}}
                  onClick={e=>{
                     deleteTable(i)
                  }}
               />
                
               {
                  i.tableActive
                  ?<MdCheckBox className="md-icon"
                     onClick={e=>{
                        let tempArray=[]

                        table.map(j=>{
                           if(j._idx==i._idx){
                              tempArray=[...tempArray,{...j,tableActive:false}]
                              setEditData({...j,tableActive:false})
                           }
                           else{
                              tempArray=[...tempArray,j]
                           }
                        })
                        setTable(tempArray)
                     }}
                  />
                  :<MdCheckBoxOutlineBlank className="md-icon"
                     onClick={e=>{
                        let tempArray=[]
                        table.map(j=>{
                           if(j._idx==i._idx){
                              tempArray=[...tempArray,{...j,tableActive:true}]
                              setEditData({...j,tableActive:true})
                           }
                           else{
                              tempArray=[...tempArray,j]
                           }
                        })
                        setTable(tempArray)
                     }}
                  />
               }
               {
               editData._idx==i._idx
               ?<input className="h-100 w-50"
                     value={editData.tableName}

                     onKeyDown={e=>{
                        if(e.key=="Enter"){
                           refSubmitForm.current.focus()
                           //refPassword.current.focus()
                       }

                     }}
                     onChange={e=>{
                        setEditData({
                           ...editData,tableName:e.target.value
                        })
                     }}
                     onBlur={e=>{
                        let tempArray=[]
                        table.map(i=>{
                           
                           if(editData._idx==i._idx){
                              tempArray=[...tempArray,editData]
                           }
                           else{
                              tempArray=[...tempArray,i]
                           }
                        })
                        setTable(tempArray)
                     }}
                  />
               :<div className=""
                  onClick={e=>{
                     setEditData(i)
                  }}   
               >
               
                  {i.tableName}
               </div>
               }
         </div>
      
      )
   })    
}
*/
//=====================================
//=====================================


//===================================
const renderBody=()=>{
   const temp=[//"thisBranch",
               //"printPageSetting",
               //"printPageSetting2",

               "widthLeft",
               "pageHeight",
               "limitRow",
               "tileMotherWidth",
               "tileMotherHeight",
               "imgWidth",
               "imgHeight",
               "imgGroupHeight",
               "defaultCustomerId",
               "defaultTransactionTypeId",
               "showTable",
               "pic",
               "storage",
               "table"
            ]

   return (temp.map((i,idx)=>{

      if(i=="table"){
         return(
            <div key={idx} 
               className="w-100 flex-center-center jc-start" 
               style={{marginBottom:"0.2rem"}}>
          
               <div className="w-100"
                  >{pageData.branchSettingPage[i]}
               </div>
               <div className='w-100'>
                  {inputData[i].length>0
                        ?inputData[i].map((k,idxK)=>{
                              return(    
                              <div className="flex-center-center w-100" 
                                 key={`${idxK}-table`}
                                 style={{marginBottom:"0.2rem"}}
                              >
                                    <div className='w-40'
                                         style={{display:"flex"}}
                                    >
                                       <div className="w-10"
                                            style={{marginRight:"1rem"}}
                                       >
                                          <input
                                             type="checkbox"
                                             checked={k.tableActive}
                                             onChange={e=>{
                                                changeTableActive(e,idxK)
                                             }}
                                          />
                                       </div>

                                       <div className="w-10"
                                             style={{marginRight:"1rem"}}
                                       >
                                          <FaPlusSquare className="sm-icon"
                                                onClick={e=>{
                                                   addTable(idxK)
                                                }}
                                          />
                                       </div>
                                       <div className="w-10"
                                          style={{marginRight:"1rem"}}
                                       >
                                          {idxK==0
                                          ?null
                                          :<FaMinusSquare className="sm-icon"
                                             style={{marginRight:"1rem"}}
                                                onClick={e=>{
                                                     deleteTable(idxK)
                                                }}
                                          />
                                          }
                                       </div>
                                    </div>
                                    <div className="w-60">
                                       <input
                                             type="text"
                                             value={k.tableName}
                                        

                                             onChange={e=>{
                                                changeTableName(e,idxK)
                                             }}
                                       />
                                    </div>
                              </div>
                              )
                        })
                        :null
                  }
               </div>
            </div>
         
         )
      }
      else if(i=="pic"||i=="storage"){
         return(
            <div key={idx} 
               className="w-100 flex-center-center jc-start" 
               style={{marginBottom:"0.2rem"}}>
          
               <div className="w-100"
                  >{pageData.branchSettingPage[i]}
               </div>
               <div className='w-100'>
                        {inputData[i].length>0
                          ?inputData[i].map((k,idx9)=>{
                              return(
                                    <div className="flex-center-center w-100" 
                                       key={`${idx9}-pic-storage`}
                                       style={{marginBottom:"0.2rem"}}
                                    >
                                          <div className='w-40'
                                               style={{display:"flex"}}
                                          >
                                             <div className="w-10"/>

                                             <div className="w-10"
                                                   style={{marginRight:"1rem"}}
                                             >
                                                <FaPlusSquare className="sm-icon"
                                                      onClick={e=>{
                                                         addRow(idx,i)
                                                      }}
                                                />
                                             </div>
                                             <div className="w-10"
                                                style={{marginRight:"1rem"}}
                                             >
                                                {idx==0
                                                ?null
                                                :<FaMinusSquare className="sm-icon"
                                                   style={{marginRight:"1rem"}}
                                                      onClick={e=>{
                                                         deleteRow(idx,i)
                                                      }}
                                                />
                                                }
                                             </div>
                                          </div>
                                          <div className="w-60">
                                             <input
                                                   type="text"
                                                   value={k}
                                                
                                                   onKeyDown={e=>{
                                                      if(e.key=="Enter"){
                                                         /*
                                                         if(idx==formInputState.length-1){
                                                               refSubmitForm.current.focus()
                                                         }
                                                         else{
                                                               refOfInputState[idx+1].current.focus()
                                                         }
                                                         */
                                                      }
                                                   }}


                                                   onChange={e=>{
                                                      changeInputState(e,idx9,i)
                                                   }}
                                             />
                                          </div>
                                    </div>
                              )
                           })
                           :<div style={{display:"flex"}}>
                              <div className='w-40'
                                   style={{display:"flex"}}
                              >
                                 <div className="w-10">
                                 </div>
                                 <div className="w-10">
                                       <FaPlusSquare className="sm-icon"
                                             onClick={e=>{
                                                addRow1(idx,i)
                                             }}
                                       />
                                 </div>
                              </div>
                              <div className="w-60">
                              </div>
                           </div>
                        }

                  </div>


               <div className="w-60">
               </div>
            </div>
         )
      }

      else{
         return(
            <div key={idx} 
               className="w-100 flex-center-center jc-start" 
               style={{marginBottom:"0.2rem"}}>
             
               <div className="w-40"
                 
               >{pageData.branchSettingPage[i]}</div>
               
               
               <div className="w-60">
                  {
                    renderInput(i)
                  }
               </div>

           </div>
         )
      }
   
   }))
}


//=====================================
return(
<div className="Modal-background">
        <div className="Modal-box" 
             style={{paddingBottom:"1rem",minWidth:"70%",minHeight:"100px"}}>
            <div className="Modal-header">
                <div>
                    <h5>{pageData.branchSettingPage.formHead}</h5>
                </div>
                {
                  renderFooter()
                }
            </div>
            <div className="Modal-body" >
                <div className="ModalInsideBody">
                {
                    renderBody()
                }
                {
                /*
                <div>
                  {pageData.branchSettingPage["table"]}
                </div>
                */
                }
                {
                  //renderTable() 
                }
                </div>
            </div>
           

           
         
        </div>
</div>

)

}
export default BranchSettingForm;



/*


const renderTable=()=>{
return(
<div 
   className="w-100 flex-center-start jc-start" 
   style={{marginBottom:"0.2rem"}}>
   <div className="w-100">
       {
            table.map((i,idx)=>{
               return(
                   <div className="flex-center-center" key={idx}
                        style={{margin:"0.5rem 0"}}
                   >
                     
                     
                  
                     
                       <div className="w-10">
                          
                       </div>
            
                       <div className="w-10">
                           {idx>0
                           ?<FaMinusSquare className="sm-icon"
                                 onClick={e=>{
                                    deleteTable(i)
                                 }}
                           />
                           :null
                           }
                       </div>
                        

                        {editData._idx==i._idx
                        ?<div className="w-80" style={{display:"flex"}}>
                           <input className="w-20"
                                 type="checkbox"
                                 checked={editData.tableActive}
                                 onChange={e=>{
                                    setEditData({
                                       ...editData,tableActive:e.target.checked
                                    })
                                 }}
                                 
                                 onBlur={e=>{
                                    let tempArray=[]
                                    table.map(i=>{
                                       if(editData._idx==i._idx){
                                          tempArray=[...tempArray,editData]
                                       }
                                       else{
                                          tempArray=[...tempArray,i]
                                       }
                                    })
                                    setTable(tempArray)
                                 }}
                           />
                           <input className="h-100 w-80"
                              value={editData.tableName}
                              onChange={e=>{
                                 setEditData({
                                    ...editData,tableName:e.target.value
                                 })
                              }}
                              onBlur={e=>{
                                 let tempArray=[]
                                 table.map(i=>{
                                    if(editData._idx==i._idx){
                                       tempArray=[...tempArray,editData]
                                    }
                                    else{
                                       tempArray=[...tempArray,i]
                                    }
                                 })
                                 setTable(tempArray)
                              }}
                           />
                        </div>
                        :<div className="w-80" style={{display:"flex"}}
                           onClick={e=>{   
                                 setEditData(i)
                           }}
                        >
                              <div className="w-20" 
                                   style={{display:"flex",
                                           justifyContent:"center",
                                           alignItems:"center"
                                           }}>
                                 {
                                    i.tableActive
                                    ?<MdCheckBox 
                                       className="md-icon"
                                     />
                                    :<MdCheckBoxOutlineBlank 
                                       className="md-icon"
                                    />
                                 }
                              </div>
                              <div className="w-80" style={{margin:"auto 0"}}>
                                 {i.tableName}
                              </div>
                        </div>
                        }
                   </div>
               )
           })
       }
   </div>

</div>
)
}

*/
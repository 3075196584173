import React from 'react'
import axios from '../../util/axiosVerify';

import {MdEdit} from 'react-icons/md';
import {FaPlusSquare,FaMinusSquare,FaBan,FaArrowCircleUp,
        FaCheck,FaArrowAltCircleUp,FaArrowAltCircleDown} from 'react-icons/fa'; 
import StickerSetting from '../../page/sticker/StickerSetting'
import console from '../../util/consoleVerify'
import uuid from 'react-uuid';

import axiosUtil from '../../util/axiosUtil'

const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,
    catchErrorToMessage}=axiosUtil

function StickerPage(props) {

    const {pageData,setShow,
        submitFunction,submitFunction2,
        stickerPage,basicData,tableTemplate,
        //addStickerPage,
        //deleteStickerPage,
        //moveStickerUp,moveStickerDown,
        saveTableTemplateFunc,
        setShowPrintFunc,
        myheader
 }=props

 const refSubmitForm=React.createRef() //ตกลง
 const refCancelForm=React.createRef() //ยกเลิก    
 
 //console.log('printPage')
 //console.log(printPage)
 
 const calDigit=100
 

 const shouldRender= React.useRef(true)
    React.useEffect(()=>{
        return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const genEditColor=(i)=>{
    let temp="black"

    
    if(editData){
        if(editData.id){
            if(editData.id==i.id){
                temp="red"
            }
        }
    }
    
    return temp
}

const genEditColor2=(i)=>{
    let temp="black"

    
    if(editAdminStickerPage){
        if(editAdminStickerPage.id){
            if(editAdminStickerPage.id==i.id){
                temp="green"
            }
        }
    }
    
    return temp
}


const getRefArray=(array)=>{
    let tempRefArray=[]

    if(Array){
        if(Array.isArray(array)){
            array.map(k=>{
                tempRefArray=[...tempRefArray,React.createRef()]
            })
        }
    }
    
    return tempRefArray
}



let [filterData,setFilterData]=React.useState(stickerPage)
React.useEffect(()=>{
    //console.log('filterData')
    //console.log(filterData)
},[filterData])



const [stickerPageFilterData,setStickerPageFilterData]=React.useState({

    reloadData:true,
    dataUrl:"p35stickerpage",
    pageNumber:1,
    limitRow:50,
    sort:{id:1},
    qry:{},
    editData:null,

    data0:null,
    count:0,
    lastRecordId:0,

    showModalConfirm:false,
    showModalError:false,
})



React.useEffect(()=>{

    if(stickerPageFilterData.reloadData){
        const {dataUrl,pageNumber,limitRow,sort,qry}=stickerPageFilterData


        let result1

        axios.post(`/p35stickerpage/getlimit`,
            {  pageNumber:1,
                limitRow:100,
                sort,
                adminShopId:process.env.REACT_APP_ADMINSHOP
            },
            myheader,shouldRender)

        .then(result=>{
            //console.log('result......')
            //console.log(result.data)
            //const temp2= genFilterDataWithIndex(result.data.data)
            
            let tempArray=[]
            if(result){
                result.data.data.map(k=>{
                    tempArray=[...tempArray,{...k,id:uuid()}]
                })
            }
            result1=tempArray

            return axios.post(`/p35stickerpage/getlimit`,
                { pageNumber,
                  limitRow,
                  sort,...qry
                },
            myheader,shouldRender)

        })

        .then(result=>{

            setAdminStickerPage(result1)

            const tempResult={...stickerPageFilterData,
                data0:result.data.data,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                //isCsv:false,
                //isXlsx:false,
                reloadData:false,   
            }
            setStickerPageFilterData(tempResult)
            setFilterData(result.data.data)
        })
        .catch(error=>{
            const tempError={...stickerPageFilterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setStickerPageFilterData(tempError)
        })
    }


},[stickerPageFilterData])

const [showStickerPage,setShowStickerPage]=React.useState(false)

const [editData,setEditData]=React.useState(null)

const [adminStickerPage,setAdminStickerPage]=React.useState([])
const [editAdminStickerPage,setEditAdminStickerPage]=React.useState(null)
const [showStickerPage2,setShowStickerPage2]=React.useState(false)



const updateStickerPage=(i)=>{

    const {_id,...tempObj}=i
    //console.log('updatePrintPage....')

    axios.post(`/p35stickerpage/updatecustom`,tempObj,myheader,shouldRender)
    .then(result=>{
        //console.log('pass update')
       
        setStickerPageFilterData({
            ...stickerPageFilterData,
            reloadData:true,
            editData:null
        })
        setEditData(tempObj)
        setShowStickerPage(false)

    })
    .catch(error=>{
        const tempError = {...filterData,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setFilterData(tempError)
    })

}


const addStickerPage=()=>{
    const {_id,...tempObj}=editData

    const tempObj2={
        ...tempObj,
        id:stickerPageFilterData.lastRecordId+1,
        recordName:editData.recordName+'-copy'
    }

    axios.post(`/p35stickerpage/addcustom`,
    tempObj2,myheader,shouldRender)
    .then(result=>{
        //console.log('pass update')
       
        setStickerPageFilterData({
            ...stickerPageFilterData,
            reloadData:true,
            showModalConfirm:false
        })
        
    })
    .catch(error=>{
        const tempError = {...stickerPageFilterData,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setStickerPageFilterData(tempError)
    })

}


const addStickerPageFromAdmin=()=>{
    const {_id,shopId,...tempObj}=editAdminStickerPage

    const tempObj2={
        ...tempObj,
        shopId:basicData.shopId,
        id:stickerPageFilterData.lastRecordId+1,
        recordName:editAdminStickerPage.recordName+'-copy_from_admin'
    }

    axios.post(`/p35stickerpage/addcustom`,
    tempObj2,myheader,shouldRender)
    .then(result=>{
        //console.log('pass update')
       
        setStickerPageFilterData({
            ...stickerPageFilterData,
            reloadData:true,
            showModalConfirm:false
        })
        
    })
    .catch(error=>{
        const tempError = {...stickerPageFilterData,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setStickerPageFilterData(tempError)
    })

}




const deleteStickerPage=()=>{

if(editData.id>2){
    axios.post(`/p35stickerpage/deletecustom`,
        {id:editData.id},myheader,shouldRender)
        .then(result=>{
            //console.log('pass update')
            setStickerPageFilterData({
                ...stickerPageFilterData,
                reloadData:true,
                showModalConfirm:false
            })
            
        })
        .catch(error=>{
            const tempError = {...stickerPageFilterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            setStickerPageFilterData(tempError)
        })
}
}
//====================================
const renderStickerPageOfAdminShop=()=>{
    return (
        <div className='w-100 bd-pureWhite'>
            <div className="w-100">
                <div className="flex-center-center">
                    <h5>{`${pageData.setting.dataFromAdminTeam}`}</h5>   
                </div>     
            </div>


            <div className='w-100' style={{display:"flex"}}>
                <div  style={{
                    marginRight:"1rem",
                    visibility:editAdminStickerPage?"visible":"hidden"

                }}>
                    <FaArrowCircleUp
                        className='md-icon'
                        onClick={e=>{
                            //setShowPrintPageForm(true)
                            addStickerPageFromAdmin()
                        }}
                    />
                </div>
                <div  style={{
                    marginRight:"1rem",
                    visibility:editAdminStickerPage?"visible":"hidden"

                }}>
                    <MdEdit
                        className='md-icon'
                        onClick={e=>{
                            //setShowStickerPage2(true)
                            setShowStickerPage2(true)
                        }}
                    />
                </div>        


            </div>

            <div className="w-100">
                {
                adminStickerPage.map((i,idxI)=>{
                        return(
                            <div key={idxI}
                                style={{
                                        color:genEditColor2(i),
                                        marginBottom:"0.5rem"
                                    }}
                                onClick={e=>{
                                    setEditAdminStickerPage({
                                        ...i,
                                        printId:i.id
                                    })
                                    setEditData(null)
                                }}
                            >
                                {`${idxI+1}.${i.recordName}`}
                            </div>

                        )
                    })
                }
            </div>

        </div>
    )
}



//====================================
const renderFooter=()=>{
    return(

    <div style={{display:"flex",width:"100%",marginTop:"2rem"}}
    >
        <div className="xc8 mc7 sc5"/>
    
        <div className="xc3 mc4 sc6" 
            style={{display:"flex",justifyContent:"space-between"}}
        >
          
            <div>
                <button
                    ref={refCancelForm}
                    onKeyDown={e=>{
                        //if(e.key=="ArrowLeft"){
                            //refCancelForm.current.focus()
                        //}
                    }}
                    onClick={e=>{
                        setShow(false)
                    }}
                >
                    <FaBan/>
                </button>
            </div>
        </div>
    </div>   


    )
}

//====================================
const renderToolBar=()=>{

    return(
        <div 
            className='w-100'
            style={{display:"flex",marginBottom:"1rem"}}
        >
            <div style={{
                    marginRight:"1rem",
                    visibility:editData?"visible":"hidden"
                    }}
                onClick={e=>{
                    addStickerPage()
                }}        
            >
                <FaPlusSquare
                    className='md-icon'
                />
            </div>

            <div  style={{
                marginRight:"1rem",
                visibility:editData?"visible":"hidden"
            }}>
                <FaMinusSquare
                    className='md-icon'
                    onClick={e=>{
                        deleteStickerPage()
                    }}
                />
            </div>

            <div  style={{
                marginRight:"1rem",
                visibility:editData?"visible":"hidden"

            }}>
                <MdEdit
                    className='md-icon'
                    onClick={e=>{
                        setShowStickerPage(true)
                    }}
                />
            </div>

        </div>
    )

}    

//===================================




//===================================
const renderBody=()=>{

return(

<div 
    className="w-100 flex-center-start jc-start" 
    style={{marginBottom:"0.2rem"}}>

    {
      renderToolBar()
    }

    <div className="w-100">
        {
            filterData.map((i,idx)=>{
                return(
                    <div key={idx}
                         style={{
                                color:genEditColor(i),
                                marginBottom:"0.2rem",
                                }}
                        onClick={e=>{
                            setEditData({
                                ...i,
                                printId:i.id
                            })

                            setEditAdminStickerPage(null)
                        }}
                    >
                        {`${i.id}.${i.recordName}`}  
                    </div>
                )
            })
        }
    </div>


    {
      renderStickerPageOfAdminShop()
    }     


    {
      renderFooter()
    }


</div>


)
}

return(
<div className="Modal-background">

    {showStickerPage||showStickerPage2
    ?showStickerPage
        ?<div className='Modal-box' 
            style={{paddingBottom:"1rem",minWidth:"70%",minHeight:"30%"}}
        >
            <StickerSetting
                stickerPage={editData}
                pageData={pageData}
                submitFunction2={updateStickerPage}
                setShow={setShow}
            />
            
        </div>
        :<div className='Modal-box' 
            style={{paddingBottom:"1rem",minWidth:"70%",minHeight:"30%"}}
        >
            <StickerSetting
                stickerPage={editAdminStickerPage}
                pageData={pageData}
                submitFunction2={null}
                setShow={setShow}
            />
       
        </div>
    :<div className="Modal-box" 
         style={{paddingBottom:"1rem",minWidth:"70%",minHeight:"30%"}}
         >
            
        <div className="w-100">
            <div className="flex-center-center">
                <h5>{`${pageData.setting.stickerPage}`}</h5>   
            </div>     
        </div>
        <div className="Modal-body" >
            <div className="ModalInsideBody">
                {
                    renderBody()
                }
            </div>
        </div>     
    </div>
    }
</div>
)
}

export default StickerPage;

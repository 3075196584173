import React from 'react';
import {Link} from 'react-router-dom';
import {MainContext} from '../../context/MainContext';
import Navbar from '../../component/navbar/Navbar';

import ShopInfo from './ShopInfo'
import StateTemplate from '../../model/StateTemplate';
import FormTemplate from '../../render/renderForm/FormTemplate';
import RenderPrintOut from '../../render/renderForm/RenderPrintOut'
import console from '../../util/consoleVerify'

import '../../component/navbar/Navbar.css';

function BasicData() {

//console.log('BasicData..........')

const { basicDataSt,
        setReloadBasicData,
        myheader,
        setBasicData,
        tokenSt,
        setReloadCheckToken
        //widthLeft,setWidthLeft
}=React.useContext(MainContext)

const {haveUserToken,haveShopToken,userName}=tokenSt  
const {basicData,pageData,tableTemplate,languageObj,user,shop,allBranch,languageArray}=basicDataSt

const refHome=React.createRef()

let [pageState,setPageState]=React.useState({
    reloadData:false,
    pageData:null,
    basicData:null,
    tableTemplate:null
    //pageDataStandard:null
})

React.useEffect(()=>{
    setPageState({
        reloadData:false,
        pageData:pageData,
        basicData:basicData,
        tableTemplate:tableTemplate
    })
},[basicDataSt])


const [printData,setPrintData]=React.useState(null)

React.useEffect(()=>{
    if(printData){
        window.print()
    }
},[printData])

const setShowPrintFunc=(data)=>{
    setPrintData(data)
}

//<div style={{padding:"0 1rem",overflowY:"auto"}}>


return ( 
<div>
    {printData&&
    <div className='hide-on-screen'>

        {
         RenderPrintOut(printData)
        }
    </div>
    }

    <div className='hide-on-print' 
        style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden",position:"relative"}}
    >
            <div className="h-5 w-100">
                <Navbar/>
            </div>       

            <div className="h-95 w-100">
                { 
                pageState.pageData&&pageState.basicData&&pageState.tableTemplate&&(user.userLevel=="admin")&&
                <ShopInfo 
                    pageData={pageState.pageData} 
                    //pageDataStandard={pageState.pageDataStandard}
                    basicData={pageState.basicData}
                    FormTemplate={FormTemplate}
                    user={user}
                    shop={shop}
                    allBranch={allBranch}
                    StateTemplate={StateTemplate}   
                    basicDataTableTemplate={pageState.tableTemplate.basicDataTableTemplate}   
                    myheader={myheader} 
                    setReloadBasicData={setReloadBasicData}
                    languageObj={languageObj}
                    tableTemplate={tableTemplate}
                    setShowPrintFunc={setShowPrintFunc}
                />
            }
            </div>
            <div className="d-none">
                <Link ref={refHome} to="/pos/home"/>
            </div>
    </div>

</div>
)

}
export default BasicData;

/*



    {
    
     pageState.pageData&&pageState.basicData&&pageState.tableTemplate&&
     <ShopInfo pageData={pageState.pageData} 
               //pageDataStandard={pageState.pageDataStandard}
               basicData={pageState.basicData}
               FormTemplate={FormTemplate}
               StateTemplate={StateTemplate}   
               basicDataTableTemplate={pageState.tableTemplate.basicDataTableTemplate}   
               myheader={myheader}     
    />
    
    }




*/


import React from 'react';
import console from '../../util/consoleVerify'
import {MdClose} from 'react-icons/md';


import '../../render/Modal.css'
const renderModalImage=({show,setShow,imgSrc,modalImageWidth,setModalImageWidth,
        isYoutube,setIsYoutube
    })=>{

//console.log('renderModalImage.....')
//console.log('imgSrc')
//console.log(imgSrc)

let tempIsYoutube


return(
    show
    ?<div className="Modal-background">
        <div style={{width:"90%",height:"90%",
                     border:"solid black 1px",
                     position:"relative",
                     overflow:"scroll"}}>

            <div style={{position:"absolute",backgroundColor:"white"}}>
                    <MdClose className='lg-icon'
                        style={{
                            fontSize:"4rem",
                        }}
                        onClick={e=>{
                            setShow(false)
                            setIsYoutube(false)
                        }} 
                    />
            </div>
            {
            isYoutube
            ?<div
                style={{width:`${modalImageWidth}%`,
                        height:`${modalImageWidth}%`,
                    }}
             >  
                <embed 
                    style={{width:`100%`,height:`100%`}}
                    src={imgSrc}
                />
            </div>
            : <img className="" 
                    src={imgSrc} 
                    style={{
                        width:`${modalImageWidth}%`,
                        objectFit:"cover",
                    }} 
                />
           
            }
            <div  style={{position:"fixed",top:"5px",width:"90vw"}}>
                <input type="range" min="10" max="200"   
                    value={modalImageWidth}
                    onChange={e=>{setModalImageWidth(e.target.value)}} 
                />
            </div>       
        </div>
     
    </div>
    :null
)}

export default renderModalImage




  /*
           
*/
import React from 'react';
import Barcode from 'react-barcode'
import tableUtil from '../../component/table/tableUtil';
import console from '../../util/consoleVerify'
import {MainContext} from '../../context/MainContext';

const {numberWithCommas}=tableUtil
//numberWithCommas(total,calDigit)
const RenderPrintOut=(inputObj)=>{
      const {printTest,
           tempTransaction,
           pageSetup,
           //widthUnit,fontUnit,totalWidth,
           calDigit,
           //dateFormat
          }=inputObj

const {widthUnit,fontUnit,totalWidth,
      usePageBreak,rowSpacing,columnSpacing,
      linePerPage,limitCharPerLine,
      scale,
      marginTop,marginBottom,marginLeft,marginRight,
      paddingTop,paddingBottom,paddingLeft,paddingRight,
      editData
      //firstPageLine,middlePageLine,lastPageLine
}=pageSetup

const {basicDataSt,
      //widthLeft,setWidthLeft,
}=React.useContext(MainContext)

let basicData

if(basicDataSt){
      basicData=basicDataSt.basicData

}



let tempScale=1

if(scale>0){
      tempScale=scale
}


const genShortDate=(value)=>{
      let temp=""
      const tempDataFormat='en-GB'
      const date3=new Date(value).toLocaleString(tempDataFormat)

      temp = date3.substring(0,10)
      //console.log(date3)
      return temp
}





const genJustifyContent=(position)=>{
      let temp="flex-start"
      if(position=="right"){
            temp="flex-end"
      }
      if(position=="center"){
            temp="center"
      }
      return temp
}


const genTrempTransaction2=(tempTransaction)=>{
      let tempArray=[]

      if(tempTransaction){
            if(tempTransaction.detail){
                  if(tempTransaction.detail.length>0){
                        tempTransaction.detail.map((i,idxI)=>{
                              if(i.selectedLine){
                                    tempArray=[...tempArray,{...i,["No."]:idxI+1}]
                              }
                        })
                  }
            }
      }
     
      let tempPhotoUrl//='https://picsum.photos/id/206/200/300'

      if(basicData){
            if(Array.isArray(basicData.photoUrl1)){
                  if(basicData.photoUrl1[0]){
                        tempPhotoUrl=basicData.photoUrl1[0]
                  }
            }
      }
      //console.log('tempPhotoUrl....')
      //console.log(basicData)
      
      //console.log(tempPhotoUrl)
      //tempPhotoUrl='https://picsum.photos/id/206/200/300'
      return {...tempTransaction,
            shortDate:genShortDate(tempTransaction.date),
            shortRemindDate:genShortDate(tempTransaction.remindDate),
            detail:tempArray,
            shopInfo:basicData.shopInfo,
            shopLogo:[tempPhotoUrl]
      }
      
}


let tempTransaction2=genTrempTransaction2(tempTransaction)


const convertToDigit=(calDigit,mynumber)=>{
      const temp =calDigit.toString()
      const n=Array.from(temp).length-1
      return mynumber.toFixed(n)
}

const renderImage=(printKey,printValue)=>{
      //console.log("renderImage..........")
      //console.log('printValue')
      //console.log(printValue)

      //console.log('printKey')
      //console.log(printKey)

      if(printValue){
            if(Array.isArray(printValue)){
                  return(
                        <div style={{
                                     display:"flex",
                                     alignItems:"center",
                                     justifyContent:"center"
                                     
                                     }}>
                              <img className="img" 
                              src={printValue[0]} 
                              style={{
                                    width:'100%',
                                    objectFit:"cover",
                                    padding:"0.1rem"
                              }}
                              
                              />
                        </div>
                  )
            }   
      }
                       
}

/*
  <img className="img" 
                                 src={printValue[0]} 
                                 style={{
                                        width:'100%',
                                        objectFit:"cover"
                                    }}
                                 
                  />

*/



const renderValue=(printKey,printValue)=>{
      if(printKey=="date"){
            //const date1=new Date(date).toISOString()
            //const date2=new Date(date1).toLocaleString('fr-ca')
            let tempDataFormat='en-GB'
            //if(dateFormat){   
            //      const temp=dateFormat.split("format:")   
            //      tempDataFormat=temp[1]    
            //}
            const date3=new Date(printValue).toLocaleString(tempDataFormat)
            //const date4=date2.substring(0,10)+'T'+date3.substring(12,date3.length)
            //console.log('date3')
            //console.log(date3) 
            return date3
      }
      
      else if(
            printKey=="shopInfo-1"||
            printKey=="shopInfo-2"||
            printKey=="shopInfo-3"||
            printKey=="shopInfo-4"||
            printKey=="shopInfo-5"
            ){
            let tempShopInfo
           
            if(printValue){
                  if(Array.isArray(printValue)){
                        if(printKey=="shopInfo-1"){
                              if(printValue[0]){
                                    tempShopInfo=printValue[0]   
                              }
                        }
                        else if(printKey=="shopInfo-2"){
                              if(printValue[1]){
                                    tempShopInfo=printValue[1]   
                              }
                        }
                        else if(printKey=="shopInfo-3"){
                              if(printValue[2]){
                                    tempShopInfo=printValue[2]   
                              }
                        }
                        else if(printKey=="shopInfo-4"){
                              if(printValue[3]){
                                    tempShopInfo=printValue[3]   
                              }
                        }
                        else if(printKey=="shopInfo-5"){
                              if(printValue[4]){
                                    tempShopInfo=printValue[4]   
                              }
                        }
                  }
            }
                  
            return tempShopInfo
      }

      else if(printKey=="total"||
            printKey=="grandTotal"||
            printKey=="endBillAmount"||
            printKey=="price"||
            printKey=="result"||
            printKey=="point"||
            printKey=="totalPoint"||
            printKey=="quantity"
            

      ){
      
            if(printValue=="noShow"){
                  return 
            }
            else{
            //return 
                  return numberWithCommas(printValue,calDigit)
             //convertToDigit(calDigit,printValue)
            }
      }
      
      else if(printKey=="phone"){
            if(printValue){
                  if(Array.isArray(printValue)){
                        
                        return printValue[0]
                        /*
                        let tempPhone=''
                        printValue.map((i,idx)=>{
                              if(idx==0){
                              tempPhone=tempPhone+i
                              }
                              else {
                              tempPhone=tempPhone+','+i
                              }
                        })
                        return tempPhone
                        */
                  }   
            }                 
      }
     
    
      else {
            return printValue
      }
}


//=========================================================
const renderBarcode=(idx,printWidth,widthUnit,tempKey0,tempKey1,tempTransaction2)=>{
      //mt,mb,ml,mr,sc,h,w
      // 0, 1, 2, 3, 4,5,6
      //15-5-20-0-1.1-20-1                       
      const tempKeyArray=tempKey1.split("-")
      //console.log(tempKeyArray)
      const tempMt=tempKeyArray[0]
      const tempMb=tempKeyArray[1]
      const tempMl=tempKeyArray[2]
      const tempMr=tempKeyArray[3]
      const tempSc=tempKeyArray[4]
      const tempH =tempKeyArray[5]
      const tempW =tempKeyArray[6]

      let isPass=true

      if(tempKeyArray.length!=7){
            isPass=false
      }
      if(!tempTransaction2[tempKey0]){
            isPass=false
      }

      //console.log(tempKey)
      
      return isPass
            ?<div key={idx} style={{
                  width:`${printWidth}${widthUnit}`,
                  margin:`${rowSpacing}px 0`//"auto 0"
                  //margin:"auto 0"
            }}
            >
                  <div style={{
                        marginTop:`${tempMt}px`,
                        marginBottom:`${tempMb}px`,
                        marginLeft:`${tempMl}px`,
                        marginRight:`${tempMr}px`
                        }}
                  >
                        <div style={{transform:`scale(${tempSc})`}}>
                              <Barcode
                                    value={tempTransaction2[tempKey0]}
                                    height={tempH}//{inputState.heightB}
                                    width={tempW}//{inputState.widthB}
                                    displayValue={false}
                                    font={"monocode"}
                                    marginTop={-1}
                                    marginBottom={-1}
                                    lineColor={"black"}
                              />
                        </div>
                  </div>
            </div>
            :null
      
}

//========================================================
const renderPageNumber=(page,totalPage)=>{
      return(
            <div style={{width:"100%",
                        display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                  {`${page}/${totalPage}`}
            </div>
      )
}

//=========================================================
const renderEach=(sectionName,page,totalPage)=>{

      //console.log(`renderEach : ${sectionName}`)

      return printTest[sectionName].map((i,idx)=>{
            
            if(i.show){
                  
                  const tempKey=i.printKey.split("@scanner@")
                  //console.log(i.printKey)
                  //console.log('lllllllllll')
                  //console.log(tempKey)

                  if(tempKey[1]){
                        return renderBarcode(idx,
                                      i.printWidth,
                                      widthUnit,
                                      tempKey[0],
                                      tempKey[1],
                                      tempTransaction2
                                    )


                        /*
                        //mt,mb,ml,mr,sc,h,w
                        // 0, 1, 2, 3, 4,5,6
                        //15-5-20-0-1.1-20-1                      
                        const tempKeyArray=tempKey[1].split("-")
                        //console.log(tempKeyArray)
                        const tempMt=tempKeyArray[0]
                        const tempMb=tempKeyArray[1]
                        const tempMl=tempKeyArray[2]
                        const tempMr=tempKeyArray[3]
                        const tempSc=tempKeyArray[4]
                        const tempH =tempKeyArray[5]
                        const tempW =tempKeyArray[6]

                        //console.log(tempKey)
                        return ( 
                              <div key={idx} style={{
                                    width:`${i.printWidth}${widthUnit}`,
                                    margin:"auto 0"
                              }}
                              >
                                    <div style={{
                                          marginTop:`${tempMt}px`,
                                          marginBottom:`${tempMb}px`,
                                          marginLeft:`${tempMl}px`,
                                          marginRight:`${tempMr}px`
                                          }}
                                    >
                                          <div style={{transform:`scale(${tempSc})`}}>
                                                <Barcode
                                                 value={tempTransaction[tempKey[0]]}
                                                 height={tempH}//{in:putState.widthB}
                                                 displayValue={false}
                                                 font={"monocode"}
                                                 marginTop={-1}
                                                 marginBottom={-1}
                                                 lineColor={"black"}
                                                />
                                          </div>
                                    </div>
                              </div>
                        )
                        */
                  }
                  else if(i.printKey=="photoUrl1-0"||
                          i.printKey=="photoUrl1-1"||
                          i.printKey=="photoUrl1-2"||
                          i.printKey=="photoUrl1-3"||
                          i.printKey=="photoUrl1-4"||
                          i.printKey=="photoUrl1-5"){
                        
                        const temp=i.printKey.split("-")
                        
                        //console.log(temp[1])
                        const tempIdx=parseInt(temp[1])
                        //console.log('tempTransaction2........')
                        //console.log(tempIdx)
                        //console.log(tempTransaction2["photoUrl1"][tempIdx])

                        if(tempTransaction2["photoUrl1"]){
                              if(tempTransaction2["photoUrl1"][tempIdx]){
                                    return (
                                          <img className="img" 
                                          src={tempTransaction2["photoUrl1"][tempIdx]} 
                                          style={{
                                                padding:"0.1rem",
                                                width:`${i.printWidth}${widthUnit}`,
                                                objectFit:"cover",
                                                textAlign:i.printPosition
                                          }}
                                          
                                          />
                                    )
                              }
                        }


                  }
                  else if(i.printKey=="shopInfo-1"||
                          i.printKey=="shopInfo-2"||
                          i.printKey=="shopInfo-3"||
                          i.printKey=="shopInfo-4"||
                          i.printKey=="shopInfo-5"
                        ){
                        return(
                              <div key={idx} style={{

                                    width:`${i.printWidth}${widthUnit}`,
                                    margin:`${rowSpacing}px 0px`,//"auto 0"
                                    display:"flex",
                                    alignItems:"baseline",
                                    justifyContent:genJustifyContent(i.printPosition)
                              }}
                              >
                                    <div
                                          style={{
                                                fontSize:`${i.printFontSize*tempScale}${fontUnit}`,
                                                visibility:"hidden"
                                          }}
                                    >
                                          ไ
                                    </div>

                                    <div style={{fontSize:`${i.printFontSize*tempScale}${fontUnit}`,
                                                textAlign:i.printPosition}}>
                                    {
                                    renderValue(i.printKey,tempTransaction2["shopInfo"])
                                    }
                                    </div>

                                    <div
                                          style={{
                                                fontSize:`${i.printFontSize*tempScale}${fontUnit}`,
                                                visibility:"hidden"
                                          }}
                                    >
                                          ไ
                                    </div>

                              </div>      
                        )
                  }
                  else if(i.printKey=="shopLogo-1"||
                          i.printKey=="shopLogo-2"||
                          i.printKey=="shopLogo-3"){

                        const tempLogo=tempTransaction2["shopLogo"]

                        let tempWidth=80
                        
                        if(i.printKey=="shopLogo-2"){
                              tempWidth=90
                        }
                        else if(i.printKey=="shopLogo-3"){
                              tempWidth=100
                        }

                        const genPosition=(printPosition)=>{
                              let tempObj={left:"0"}
                              if(printPosition=="right"){
                                    tempObj={right:"0"}
                              }
                              return tempObj
                        }

                        let tempPrintValue

                        if(tempLogo){
                              if(Array.isArray(tempLogo)){
                                    tempPrintValue=[tempLogo[0]]
                              }
                        }
                        
                        return(
                        <div key={idx} style={{
                              width:`${i.printWidth}${widthUnit}`,
                              margin:`${rowSpacing}px 0px`//"auto 0"
                        }}
                        >
                              <div style={{fontSize:`${i.printFontSize*tempScale}${fontUnit}`,
                                          textAlign:i.printPosition,
                                          position:"relative",
                                          width:"100%"
                                          }}>

                                          
                                          {
                                          
                                          <div style={{
                                                       //display:"flex",
                                                       //alignItems:"flex-start",
                                                       //justifyContent:"center",
                                                       width:"100%",
                                                       position:"absolute",
                                                       top:"-1rem",
                                                       ...genPosition(i.printPosition)
                                                       }}>
                                                <img className="img" 
                                                src={tempPrintValue[0]} 
                                                style={{
                                                      width:`${tempWidth}%`,
                                                      //objectFit:"cover",
                                                      padding:"0rem"
                                                }}
                                                
                                                />
                                          </div>
                                          
                                          }
                              </div>
                        </div>      
                        )
                  }

                  else if(i.printKey=="horizontalSolidLine"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"1px solid black",
                                    }}
                              >
                                   
                              </div>
                        )
                  }
                  else if(i.printKey=="horizontalDashedLine"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"1px dashed black",
                                    }}
                              >
                                   
                              </div>
                        )
                  }
                  else if(i.printKey=="horizontalThickLine"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"2px solid black",
                                    }}
                              >
                                   
                              </div>
                        )
                  }




                  else if(i.printKey=="verticalSolidLineStart"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"1px solid black",
                                          position:"relative",
                                    }}
                              >
                                    <div style={{
                                          position:"absolute",
                                          top:"0",
                                          height:`${i.printFontSize}px`,
                                          borderLeft:"1px solid black",
                                          color:"rgba(0,0,0,0)"
                                    }}>
                                          .
                                    </div>
                              </div>
                        )
                  }
                  else if(i.printKey=="verticalSolidLineEnd"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"1px solid black",
                                          position:"relative",
                                    }}
                              >
                                    <div style={{
                                          position:"absolute",
                                          top:"0",right:"0",
                                          height:`${i.printFontSize}px`,
                                          borderRight:"1px solid black",
                                          color:"rgba(0,0,0,0)"
                                    }}>
                                          .
                                    </div>
                              </div>
                        )
                  }


                  else if(i.printKey=="verticalDashedLineStart"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"1px dashed black",
                                          position:"relative",
                                    }}
                              >
                                    <div style={{
                                          position:"absolute",
                                          top:"0",
                                          height:`${i.printFontSize}px`,
                                          borderLeft:"1px dashed black",
                                          color:"rgba(0,0,0,0)"
                                    }}>
                                          .
                                    </div>
                              </div>
                        )
                  }
                  else if(i.printKey=="verticalDashedLineEnd"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"1px dashed black",
                                          position:"relative",
                                    }}
                              >
                                    <div style={{
                                          position:"absolute",
                                          top:"0",right:"0",
                                          height:`${i.printFontSize}px`,
                                          borderRight:"1px dashed black",
                                          color:"rgba(0,0,0,0)"
                                    }}>
                                          .
                                    </div>
                              </div>
                        )
                  }

                  else if(i.printKey=="verticalThickLineStart"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"2px solid black",
                                          position:"relative",
                                    }}
                              >
                                    <div style={{
                                          position:"absolute",
                                          top:"0",
                                          height:`${i.printFontSize}px`,
                                          borderLeft:"2px solid black",
                                          color:"rgba(0,0,0,0)"
                                    }}>
                                          .
                                    </div>
                              </div>
                        )
                  }
                  else if(i.printKey=="verticalThickLineEnd"){
                        return (
                              <div
                                    key={idx} 
                                    style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          borderTop:"2px solid black",
                                          position:"relative",
                                    }}
                              >
                                    <div style={{
                                          position:"absolute",
                                          top:"0",right:"0",
                                          height:`${i.printFontSize}px`,
                                          borderRight:"2px solid black",
                                          color:"rgba(0,0,0,0)"
                                    }}>
                                          .
                                    </div>
                              </div>
                        )
                  }






                  else{
                        if(i.printKey=="page"){
                              return ( 
                                    <div key={idx} style={{
                                          width:`${i.printWidth}${widthUnit}`,
                                          margin:`${rowSpacing}px 0px`//"auto 0"
                                    }}
                                    >
                                          <div style={{fontSize:`${i.printFontSize*tempScale}${fontUnit}`,
                                                      textAlign:i.printPosition}}>
                                          {
                                          `${page}/${totalPage}`
                                          }
                                          </div>
                                    </div>
                              )
                        }
                        else {
                              return ( 
                              <div key={idx} style={{
                                    width:`${i.printWidth}${widthUnit}`,
                                    margin:`${rowSpacing}px 0px`,//"auto 0"
                                    //border:"1px solid red"
                                    display:"flex",
                                    alignItems:"baseline",
                                    justifyContent:genJustifyContent(i.printPosition)
                              }}
                              >
                                    <div
                                          style={{
                                                fontSize:`${i.printFontSize*tempScale}${fontUnit}`,
                                                visibility:"hidden"
                                          }}
                                    >
                                          ไ
                                    </div>

                                    <div style={{
                                          fontSize:`${i.printFontSize*tempScale}${fontUnit}`,
                                          textAlign:i.printPosition,
                                          
                                    }}
                                    >
                                    {i.printKey=="noKey"
                                    ?i.printValue
                                    :renderValue(i.printKey,tempTransaction2[i.printKey])
                                    }
                                    </div>
                                   
                                    <div
                                          style={{
                                                fontSize:`${i.printFontSize*tempScale}${fontUnit}`,
                                                visibility:"hidden"
                                          }}
                                    >
                                          ไ
                                    </div>
                              </div>
                              )
                        }

                  }
            }
      })
}


//===========================================
const renderTableBody=(detail,check_to_show_please)=>{
      return detail.map((i,idx)=>{
                  //console.log("renderTableBody.......i.......")
                  //console.log(i)
                  if(check_to_show_please){
                        
                        if(i.to_show_please){
                  
                                    return printTest.table.map((j,idx2)=>{
                                          //console.log("printTest....j....")
                                          //console.log(j)
                                          const tempKey=j.printKey.split("@scanner@")
                                          //console.log(i.printKey)
                                          //console.log('lllllllllll')
                                          //console.log(tempKey)

                                          if(tempKey[1]){
                                                return renderBarcode(`${idx}-${idx2}`,
                                                j.printWidth,
                                                widthUnit,
                                                tempKey[0],
                                                tempKey[1],
                                                i
                                                )
                                          
                                          
                                          }
                                          else{      
                                                return(
                                                <div key={`${idx}-${idx2}`} 
                                                      
                                                      style={{
                                                            whiteSpace:"nowrap",
                                                            overflow:"hidden",
                                                            width:`${j.printWidth}${widthUnit}`,
                                                            margin:`${rowSpacing}px 0`//"auto 0"
                                                            //verticalAlign:"baseline"
                                                      }}
                                                
                                                      onClick={e=>{
                                                            //console.log(e.target)
                                                      }}
                                                >

                                                      {j.printKey=="photoUrl1"
                                                      ?renderImage(j.printKey,i[j.printKey])

                                                      :<div 
                                                            style={{
                                                                  fontSize:`${j.printFontSize*tempScale}${fontUnit}`,
                                                                  textAlign:j.printPosition,
                                                                  display:"flex",
                                                                  alignItems:"baseline",
                                                                  justifyContent:genJustifyContent(j.printPosition)
                                                            }}
                                                      >

                                                          

                                                            <div
                                                                  style={{
                                                                        fontSize:`${j.printFontSize*tempScale}${fontUnit}`,
                                                                        visibility:"hidden"
                                                                  }}
                                                            >ไ</div>

                                                            {j.printKey=="noKey"||!j.printKey
                                                            ?`-`
                                                            :renderValue(j.printKey,i[j.printKey])
                                                            //`${i[j.printKey]}`
                                                            }
                                                          
                                                            <div
                                                                  style={{
                                                                        fontSize:`${j.printFontSize*tempScale}${fontUnit}`,
                                                                        visibility:"hidden"
                                                                  }}
                                                            >
                                                                  ไ
                                                            </div>
                                                      </div>
                                                      
                                                      }
                                                </div>
                                                )

                                          }
                                    })
                        }

                        else {
                              return printTest.table.map((j,idx2)=>{
                                    return(
                                          <div key={`${idx}-${idx2}`} 
                                                
                                                style={{
                                                      visibility:"hidden",
                                                      //opacity:"1",
                                                      //color:"white",
                                                      fontSize:`${j.printFontSize*tempScale}${fontUnit}`,

                                                      width:`${j.printWidth}${widthUnit}`,
                                                      margin:`${rowSpacing}px 0`//"auto 0"
                                                }}
                                          >
                                                {basicData.languageSetting=="Thai"
                                                ?"ไ"
                                                :"ไ"//noShow"
                                                }
                                          </div>
                                    )
                              })
                        }

                  }
                  else {

                        return printTest.table.map((j,idx2)=>{

                              const tempKey=j.printKey.split("@scanner@")
                              //console.log(i.printKey)
                              //console.log('lllllllllll')
                              //console.log(tempKey)

                              if(tempKey[1]){
                                    return renderBarcode(`${idx}-${idx2}`,
                                                j.printWidth,
                                                widthUnit,
                                                tempKey[0],
                                                tempKey[1],
                                                i
                                                )
                              }
                              else{      

                                    return(
                                    <div key={`${idx}-${idx2}`} 
                                          
                                          style={{
                                                
                                                width:`${j.printWidth}${widthUnit}`,
                                                margin:`${rowSpacing}px 0`//"auto 0"
                                                //verticalAlign:"baseline"
                                          }}>
                                          <div 
                                                style={{
                                                      fontSize:`${j.printFontSize*tempScale}${fontUnit}`,
                                                      textAlign:j.printPosition
                                                }}
                                          >
                                                {j.printKey=="noKey"||!j.printKey
                                                ?`-`
                                                :renderValue(j.printKey,i[j.printKey])
                                                //`${i[j.printKey]}`
                                                }
                                          </div>
                                    </div>
                                    )

                              }
                        })
                  }
      })
}

const renderTableForSinglePage=(detailArrayFromMultiPage)=>{

      if(detailArrayFromMultiPage){
            return (<>
                  {renderEach("tableHead")}
                  {renderTableBody(detailArrayFromMultiPage,true)}
                  {renderEach("tableEnd")}
            </>)
      
      }
      else{
            return (<>
                  {renderEach("tableHead")}
                  {renderTableBody(tempTransaction2.detail,false)}
                  {renderEach("tableEnd")}
            </>)
      
      }


     
}

const renderEndBill=()=>{
      //console.log('renderEndBill')
      //console.log(tempTransaction)

      if(!tempTransaction2){return }
      if(!tempTransaction2["endBill"]){return}
      if(!Array.isArray(tempTransaction2["endBill"])){return null}
      
      return tempTransaction2["endBill"].map((h,idxH)=>{
            return printTest["endBill"].map((s,idxS)=>{
                  return (
                  <div key={`${idxH}-${idxS}`} 
                       style={{
                             margin:`${rowSpacing}px 0`,
                             width:`${s.printWidth}${widthUnit}`,
                             display:"flex",
                             alignItems:"baseline",
                             justifyContent:genJustifyContent(s.printPosition)

                        }}
                  >
                        <div
                              style={{
                                          fontSize:`${s.printFontSize*tempScale}${fontUnit}`,
                                          visibility:"hidden"
                                     }}       
                        >
                              ไ
                        </div>

                        <div style={{fontSize:`${s.printFontSize*tempScale}${fontUnit}`,
                                    textAlign:s.printPosition}}>
                              {s.printKey=="noKey"
                              ?s.printValue
                              :renderValue(s.printKey,h[s.printKey])
                              }
                             
                        </div>
                        <div
                              style={{
                                    fontSize:`${s.printFontSize*tempScale}${fontUnit}`,
                                    visibility:"hidden"
                              }}
                        >
                              ไ
                        </div>
                  </div>
                  )
            })
      })

}



const renderSinglePage=(detailArrayFromMultiPage)=>{

   return(
      <div className="" 
          style={{//position:"fixed---",top:"0",left:"0",
                      //margin:"0",
                      width:`${totalWidth}${widthUnit}`,
                      display:"flex", 
                      alignItems:"baseline",
                      flexWrap:"wrap",
                      border:"dashed 1px white",
                      borderRadius:"0.3rem",
                      //gap:`${rowSpacing}px ${columnSpacing}px` //row and column

                      //gap:`${rowSpacing}px ${columnSpacing}px`,
                      //gap:`0px ${columnSpacing}px`,
                      
                      //marginBottom:`${rowSpacing}px`
                      //rowGap:`${rowSpacing}px`,
                      //columnGap:`${columnSpacing}px`
                      marginTop:`${marginTop}px`,
                      marginBottom:`${marginBottom}px`,
                      marginLeft:`${marginLeft}px`,
                      marginRight:`${marginRight}px`,
                      paddingTop:`${paddingTop}px`,
                      paddingBottom:`${paddingBottom}px`,
                      paddingLeft:`${paddingLeft}px`,
                      paddingRight:`${paddingRight}px`,
                      }}>
            
            {
                renderEach("header",1,1)//renderArray(header1)
            }
            {
                renderTableForSinglePage(detailArrayFromMultiPage) 
            }
            {
                renderEach("total")   
            }
            {
                renderEndBill()
            }
            {
                renderEach("grandTotal")   
            }
            {
                renderEach("footer",1,1)   
            }
      </div>
   )
}




const genStartStopArray=(dataLength,limit)=>{
      let tempStart=0
      let tempStop=null
      let tempArray=[]
  
      for (let i=0;i<dataLength;i++){
          //let last
          if(i==0){
              tempStart=0
              tempStop=0
          }
          else if(i%limit==0){
              tempStop=i
              tempArray=[...tempArray,{startIdx:tempStart,stopIdx:tempStop}]
              tempStart=i
              tempStop=i+1
          }
          else{
              tempStop=i+1
          }
      }
  
      if(tempStop>=0){
          tempArray=[...tempArray,{startIdx:tempStart,stopIdx:tempStop}]
      }
  
      //console.log('tempArray')
      //console.log(tempArray)
  
      return tempArray
  }
  





const renderMultiPage=()=>{

      //const limitCharPerLine=10

      let tempDetailArray=[]
      let tempArray=[]
      //let lineCount=0


      //console.log(tempTransaction2.detail)

      let tempArrayDetail5=[]

      tempTransaction2.detail.map(uu=>{
            
            const tempArrayStartStop=genStartStopArray(uu.productName.length,limitCharPerLine)
            tempArrayStartStop.map((aa,idxAA)=>{
                  if(idxAA==0){
                        const tempProductName=uu.productName.substring(aa.startIdx,aa.stopIdx)
                        const tempObj={...uu,productName:tempProductName}
                        tempArrayDetail5=[...tempArrayDetail5,tempObj]
                  }
                  else{
                        const tempProductName=uu.productName.substring(aa.startIdx,aa.stopIdx)
                        const tempObj={productName:tempProductName,
                              total:"noShow",
                              grandTotal:"noShow",
                              renderEndBill:"noShow",
                              price:"noShow",
                              result:"noShow",
                              point:"noShow",
                              totalPoint:"noShow",
                              quantity:"noShow",
                              priceExcludeVat:"noShow",
                              priceIncludeVat:"noShow"
                        }
                     

                        tempArrayDetail5=[...tempArrayDetail5,tempObj]
                  }

            })

      })

      //console.log(tempArrayDetail5.length)
      //console.log(tempArrayDetail5)


      //let tempLength=tempTransaction2.detail.length
      let tempLength=tempArrayDetail5.length

      const theRemainder=tempLength%linePerPage
      
      let tempTransactionDetail

      if(theRemainder>0){
            const moreDetail=linePerPage-theRemainder

            let moreDetailArray=[]
            for(let md=1;md<=moreDetail;md++){
                  //moreDetailArray=[...moreDetailArray,tempTransaction2.detail[tempLength-1]]
                  moreDetailArray=[...moreDetailArray,tempArrayDetail5[tempLength-1]]
            }
            
            //tempTransactionDetail=[...tempTransaction2.detail,...moreDetailArray]
            tempTransactionDetail=[...tempArrayDetail5,...moreDetailArray]
      }
      else{
            //tempTransactionDetail=tempTransaction2.detail
            tempTransactionDetail=tempArrayDetail5
      }

      let tempTransactionDetail2=[]

      tempTransactionDetail.map((kk,idxKK)=>{
            if(idxKK<tempLength){
                  tempTransactionDetail2=[...tempTransactionDetail2,{...kk,to_show_please:true}]
            }
            else{
                  tempTransactionDetail2=[...tempTransactionDetail2,{...kk,to_show_please:false}]
            }
      })


      /*
      console.log('tempLength')
      console.log(tempLength)
      console.log('tempTransactionDeatal')
      console.log(tempTransactionDetail)
      */
      /*
      tempTransaction.detail.map((f,idxF)=>{
            if(lineCount==0){
                  tempArray=[]
            }
                  tempArray=[...tempArray,f]
                  lineCount=lineCount+1

            if(lineCount==linePerPage-1||idxF==tempLength-1){
                  tempDetailArray=[...tempDetailArray,tempArray]
                  lineCount=0
            }
      })
      */

      tempTransactionDetail2.map((f,idxF)=>{
      //tempTransaction.detail.map((f,idxF)=>{
            if(tempArray.length<linePerPage){
                  tempArray=[...tempArray,f]
            }
            else if(tempArray.length==linePerPage){
                  tempDetailArray=[...tempDetailArray,tempArray]
                  tempArray=[f]
            }
      })

      if(tempArray.length>0){
            tempDetailArray=[...tempDetailArray,tempArray]
      }

  
   if(tempDetailArray.length==1){
      return renderSinglePage(tempDetailArray[0])
   }
   else {
      return (
      <div className="" 
            style={{//position:"fixed---",top:"0",left:"0",
                  margin:"0",
                  width:`${totalWidth}${widthUnit}`,
                  border:"dashed 1px white",
                  borderRadius:"0.3rem"
                  }}>
            {
                   tempDetailArray.map((r,idxR)=>{
                        if(idxR==0){
                              return (
                                    <div
                                    style={{
                                          display:"flex",
                                          flexWrap:"wrap",
                                          
                                          //border:"1px solid black",
                                          alignItems:"baseline",
                                          
                                          //gap:`${rowSpacing}px ${columnSpacing}px`,
                                          //gap:`0px ${columnSpacing}px`,
                                          //marginBottom:`${rowSpacing}px`,
                                          //rowGap:`${rowSpacing}px`,
                                          //columnGap:`${columnSpacing}px`,
                                          pageBreakAfter:"always",


                                          marginTop:`${marginTop}px`,
                                          marginBottom:`${marginBottom}px`,
                                          marginLeft:`${marginLeft}px`,
                                          marginRight:`${marginRight}px`,
                                          paddingTop:`${paddingTop}px`,
                                          paddingBottom:`${paddingBottom}px`,
                                          paddingLeft:`${paddingLeft}px`,
                                          paddingRight:`${paddingRight}px`,

                                    }}
                                    >
                                          {renderEach("header",idxR+1,tempDetailArray.length)}
                                          {renderEach("tableHead")}
                                          {renderTableBody(r,true)}
                                          {renderEach("tableEnd")}
                                     

                                    </div>
                              )
                        }
                        else if(idxR>0&&idxR<tempDetailArray.length-1){
                              return(
                                    <div
                                          style={{
                                                display:"flex",
                                                flexWrap:"wrap",
                                                //alignItems:"stretch",
                                                //backgroundColor:"green",
                                                //border:"1px solid black",
                                                alignItems:"baseline",
                                                
                                                //gap:`${rowSpacing}px ${columnSpacing}px`,
                                                //gap:`${rowSpacing}px ${columnSpacing}px`,
                                                //gap:`0px ${columnSpacing}px`,
                                                //marginBottom:`${rowSpacing}px`,
                                                //rowGap:`${rowSpacing}px`,
                                                //columnGap:`${columnSpacing}px`,
                                                pageBreakAfter:"always",

                                                marginTop:`${marginTop}px`,
                                                marginBottom:`${marginBottom}px`,
                                                marginLeft:`${marginLeft}px`,
                                                marginRight:`${marginRight}px`,
                                                paddingTop:`${paddingTop}px`,
                                                paddingBottom:`${paddingBottom}px`,
                                                paddingLeft:`${paddingLeft}px`,
                                                paddingRight:`${paddingRight}px`,
                                          }}
                                    >
                                          {renderEach("header",idxR+1,tempDetailArray.length)}
                                          {renderEach("tableHead")}
                                          {renderTableBody(r,true)} 
                                          {renderEach("tableEnd")}


                                    </div>
                              )
                        }
                        else {
                              return(
                                    <div
                                          style={{
                                                display:"flex",
                                                flexWrap:"wrap",
                                                //alignItems:"stretch",
                                          
                                                alignItems:"baseline",
                                                //gap:`${rowSpacing}px ${columnSpacing}px`,
                                                //gap:`${rowSpacing}px ${columnSpacing}px`,
                                                //gap:`0px ${columnSpacing}px`,
                                                //marginBottom:`${rowSpacing}px`,
                                                //rowGap:`${rowSpacing}px`,
                                                //columnGap:`${columnSpacing}px`,
                                                pageBreakAfter:"always",

                                                marginTop:`${marginTop}px`,
                                                marginBottom:`${marginBottom}px`,
                                                marginLeft:`${marginLeft}px`,
                                                marginRight:`${marginRight}px`,
                                                paddingTop:`${paddingTop}px`,
                                                paddingBottom:`${paddingBottom}px`,
                                                paddingLeft:`${paddingLeft}px`,
                                                paddingRight:`${paddingRight}px`,
                                          }}
                                    >
                                          {renderEach("header",idxR+1,tempDetailArray.length)}
                                          {renderEach("tableHead")}
                                          {renderTableBody(r,true)}

                                          {renderEach("tableEnd")}
                                          {renderEach("total")}
                                          {renderEndBill()}
                                          {renderEach("grandTotal")}
                                          {renderEach("footer",idxR+1,tempDetailArray.length)}
                                    </div>
                              )
                        }

                   })
            }
            
      </div>
      
      )
   }
   

}
      


return !usePageBreak
    ?renderSinglePage()
    :renderMultiPage()
}

//====================================
export default RenderPrintOut

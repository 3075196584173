import React from 'react';
import stickerInputState from './stickerInputState';
import Calendar from '../../component/calendar/Calendar'
import renderBarcodeOut from './renderBarcodeOut';
import {FaPlusSquare,FaMinusSquare,FaBan,FaCheck} from 'react-icons/fa'; 
import console from '../../util/consoleVerify'

function StickerSetting(props) {

const {stickerPage,pageData,submitFunction2,setShow}=props
const {stickerSettingForm}=pageData


//console.log('StickerSetting')
//console.log(stickerPage)

const refSubmitForm=React.createRef() //ตกลง
const refCancelForm=React.createRef() //ยกเลิก    


/*
const stickerSettingForm={
    id:"เลขที่",
    recordName:"ชื่อรายการ",

    date:"วันที่",
    recordBy:"คนบันทึก",
    
    heightP:"สูง มม.",
    widthP:"กว้าง มม.",
    paddingTopP:"ขอบบน มม.",
    paddingBottomP:"ขอบล่าง มม.",
    paddingLeftP:"ขอบซ้าย มม.",

    paddingRightP:"ขอบขวา มม.",
    showBarCode:"แสดงบาร์โค้ด",
    heightB:"ความสูงบาร์โค้ด",
    widthB:"ความกว้างบาร์โค้ด",
    xPerLine:"จำนวนสติกเกอร์ต่อบรรทัด",
    
    showBorder:"แสดงเส้นขอบ",
    gridRowGap:"ระยะระหว่างแถว มม.",
    gridColumnGap:"ระยะระหว่างคอลัมน์ มม.",
    showCode:"แสดงโค้ด",
    fontSizeCode:"ขนาดฟอนท์โค้ด",
    
    showName:"แสดงชื่อสินค้า",
    fontSizeName:"ขนาดฟอนท์สินค้า",
    showPrice:"แสดงราคา",
    fontSizePrice:"ขนาดฟอนท์ราคา",
    showUnit:"แสดงหน่วย",
    
    fontSizeUnit:"ขนาดฟอนท์หน่วย",


    setting:"ตั้งค่าสติกเกอร์",
    pageSetUp:"ตั้งค่าหน้ากระดาษ",
    barcodeSetUp:"ตั้งค่าบาร์โค้ด"
}
*/


const inputTemplate0=[
    {inputType:"number",width:["","50%","50%"],key:"id",lb:stickerSettingForm["id"] },
    {inputType:"number",width:["","50%","50%"],key:"newId",lb:stickerSettingForm["newId"] },

    {inputType:"text",width:["30%","75%","25%"],key:"recordName",lb:stickerSettingForm["recordName"] },
    //{lb:"วันที่",inputType:"date",width:["30%","50%","50%"],key:"date" },
    //{lb:"คนบันทึก",inputType:"text",width:["25%","50%","50%"],key:"recordBy" },
]

const inputTemplate1=[

    {inputType:"number",width:["","50%","50%"],key:"heightP",lb:stickerSettingForm["heightP"]},
    {inputType:"number",width:["","50%","50%"],key:"widthP",lb:stickerSettingForm["widthP"]},

    {inputType:"number",width:["","50%","50%"],key:"marginTopP",lb:stickerSettingForm["marginTopP"]},
    {inputType:"number",width:["","50%","50%"],key:"marginBottomP",lb:stickerSettingForm["marginBottomP"]},
    {inputType:"number",width:["","50%","50%"],key:"marginLeftP",lb:stickerSettingForm["marginLeftP"]},
    {inputType:"number",width:["","50%","50%"],key:"marginRightP",lb:stickerSettingForm["marginRightP"]},

    {inputType:"number",width:["","50%","50%"],key:"paddingTopP",lb:stickerSettingForm["paddingTopP"]},
    {inputType:"number",width:["","50%","50%"],key:"paddingBottomP",lb:stickerSettingForm["paddingBottomP"]},
    {inputType:"number",width:["","50%","50%"],key:"paddingLeftP",lb:stickerSettingForm["paddingLeftP"]},
    {inputType:"number",width:["","50%","50%"],key:"paddingRightP",lb:stickerSettingForm["paddingRightP"]},

    {inputType:"checkbox",width:["","50%","50%"],key:"borderBottomP",lb:stickerSettingForm["borderBottomP"]},

    {inputType:"number",width:["","50%","50%"],key:"scale",lb:stickerSettingForm["scale"]},
    {inputType:"number",width:["","50%","50%"],key:"marginTopBarcode",lb:stickerSettingForm["marginTopBarcode"]},
    {inputType:"number",width:["","50%","50%"],key:"marginBottomBarcode",lb:stickerSettingForm["marginBottomBarcode"]},

]

const inputTemplate2=[
    {inputType:"checkbox",width:["","50%","50%"],key:"showBarCode",lb:stickerSettingForm["showBarCode"]},
    {inputType:"number",width:["","50%","50%"],key:"heightB",lb:stickerSettingForm["heightB"]},
    {inputType:"number",width:["","50%","50%"],key:"widthB",lb:stickerSettingForm["widthB"]},
    {inputType:"number",width:["","50%","50%"],key:"xPerLine",lb:stickerSettingForm["xPerLine"]},
    
    {inputType:"checkbox",width:["","50%","50%"],key:"showBorder",lb:stickerSettingForm["showBorder"]},
    {inputType:"number",width:["","50%","50%"],key:"gridRowGap",lb:stickerSettingForm["gridRowGap"]},
    {inputType:"number",width:["","50%","50%"],key:"gridColumnGap",lb:stickerSettingForm["gridColumnGap"]},

    {inputType:"checkbox",width:["","50%","50%"], key:"showCode",lb:stickerSettingForm["showCode"]},
    {inputType:"number",width:["","50%","50%"], key:"fontSizeCode",lb:stickerSettingForm["fontSizeCode"]},

    {inputType:"checkbox",width:["","50%","50%"], key:"showName",lb:stickerSettingForm["showName"]},
    {inputType:"number",width:["","50%","50%"], key:"fontSizeName",lb:stickerSettingForm["fontSizeName"]},

    {inputType:"checkbox",width:["","50%","50%"], key:"showPrice",lb:stickerSettingForm["showPrice"]},
    {inputType:"number",width:["","50%","50%"], key:"fontSizePrice",lb:stickerSettingForm["fontSizePrice"]},

    {inputType:"checkbox",width:["","50%","50%"], key:"showUnit",lb:stickerSettingForm["showUnit"]},
    {inputType:"number",width:["","50%","50%"], key:"fontSizeUnit",lb:stickerSettingForm["fontSizeUnit"]},

]


const [inputState,setInputState]=React.useState({...stickerInputState,...stickerPage})

const handleInputState=({e,key,inputType})=>{
    let value
    if(inputType=="checkbox"){
        value=e.target.checked
    }
    else {
        value=e.target.value
    }
    setInputState({...inputState,[key]:value})
}



const renderFooterForStickerPage=()=>{
    return(
        <div style={{display:"flex",width:"100%",margin:"2rem 0 6rem 0"}}
        >
            <div className="xc8 mc7 sc5"/>
            <div className="xc3 mc4 sc6" 
                 style={{display:"flex",justifyContent:"flex-end"}}
            >
                <div>
                    <button
                        style={{visibility:submitFunction2?"visible":"hidden"}}
                        ref={refSubmitForm}
                        onKeyDown={e=>{
                            //if(e.key=="ArrowRight"){
                                //refCancelForm.current.focus()
                            //}
                        }}
                        onClick={e=>{
                            if(submitFunction2){
                                submitFunction2(inputState)
                            }
                
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                
                <div>
                    <button
                        ref={refCancelForm}
                        onKeyDown={e=>{
                            //if(e.key=="ArrowLeft"){
                                //refCancelForm.current.focus()
                            //}
                        }}
                        onClick={e=>{
                            setShow(false)
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>
        </div>   
    )
    }



const renderInputFromTemplate=({
    i,idx
})=>{
    const {width,lb,key,inputType} = i
   
    let tempValue=inputState[key]
    if(inputType=="date"){
        if(inputState[key]){
            tempValue=inputState[key].substring(0,10)
        }
    }

    const genClassName=(key)=>{
        if(key=="recordName"){
            return "xc10 lc9 mc8 sc6"
        }
        else {
            return "xc2 lc3 mc4 sc6"
            //return "xc6 lc6 mc6 sc6"
        }
    }

    return (
        <div key={idx} className={genClassName(i.key)}
             style={{display:"flex",
                     margin:"0.2rem 0",padding:"0 6px 0 0"}}>
             <div className="" 
                style={{width:width[2],display:"flex",
                     justifyContent:"flex-end",margin:"auto"}}>
                <div style={{textAlign:"right",margin:"auto 0"}}>
                    {lb}
                </div>

                {key=="widthP"||key=="heightP"
                ?<div style={{textAlign:"right",margin:"auto 0",color:"red"}}
                    onClick={e=>{
                        setInputState({...inputState,useMm:!inputState.useMm})
                    }}
                >
                    {inputState.useMm?`(mm)`:`(%)`}
                </div>
                :null
                }
            </div>
            {inputType=="date"
            ?<Calendar
                style={{height:"1.6rem"}}
                onMyClick={
                (e)=>{
                    setInputState({...inputState,[key]:e})
                }
                }
                value={tempValue.substring(0,10)}
            />
            :inputType=="checkbox"
                ?<div style={{width:width[1]}}>
                    <input  
                        checked={inputState[key]}
                        value={tempValue}
                        type={inputType}
                        onChange={e=>handleInputState({e,key,inputType})}
                    />   
                 </div>
                :<input  
                        style={{width:width[1]}}
                        checked={inputState[key]}
                        value={tempValue}
                        type={inputType}
                        onChange={e=>handleInputState({e,key,inputType})}
                />
                }
        </div>
    )
}

const renderInput=()=>{
    return (
        <div className="flex-center-center" style={{width:"100%"}}>
                
                <div className="xc12" style={{display:"flex",flexWrap:"wrap",
                     paddingTop:"1rem"
                }}>

                    <div className="xc12" style={{fontSize:"1.2rem"}}>  
                            {stickerSettingForm.setting}
                    </div>

                    { 
                        inputTemplate0.map((i,idx)=>{
                            return (
                                renderInputFromTemplate({
                                    i,idx,
                                })
                            )
                        })
                    }


                    <div className="xc12" style={{fontSize:"1.2rem"}}>  
                            {stickerSettingForm.pageSetUp}
                    </div>

                    {
                        inputTemplate1.map((i,idx)=>{
                            return (
                                renderInputFromTemplate({
                                    i,idx,
                                })
                            )
                        })
                    }

                </div>

                <div className="xc12" style={{display:"flex",flexWrap:"wrap",
                     paddingTop:"1rem"
                }}>

                    <div className="xc12" style={{fontSize:"1.2rem"}}>  
                            {stickerSettingForm.barcodeSetUp}
                    </div>

                   

                    {
                        inputTemplate2.map((i,idx)=>{
                            return(
                                renderInputFromTemplate({
                                    i,idx,
                                })
                            )
                        })
                    }

                    {/*
                    <div style={{display:"flex",width:"100%",marginBottom:"1rem"}}>
                        <div className="xc1" style={{marginTop:"1rem",fontSize:"1.2rem"}}>  
                            ข้อมูลสินค้า
                        </div>
                         <FaPlusSquare  className="sm-icon bd-black" 
                            style={{marginBottom:"0",borderRadius:"0",textAlign:"start"}}
                            onClick={e=>{
                                insertRowZero()
                            }}
                        />
                        


                        <div className="" 
                            style={{marginTop:"1rem",fontSize:"1.2rem",width:"15%",textAlign:"right"}}>
                            {`จำนวนสินค้าที่คัดลอก = ${selectProduct.length}`}
                        </div>
                    
                        <div style={{display:"flex",alignItems:"flex-end"}}>
                            <button 
                                onClick={e=>addToSelectProduct(inputState)}
                            >คัดลอกข้อมูลที่เลือก</button>
                        </div>

                        <div style={{display:"flex",alignItems:"flex-end"}}>
                            <button 
                                onClick={e=>deleteSelectProduct()}
                            >ล้างข้อมูลที่เลือก</button>
                        </div>
                        

                        <div style={{display:"flex",alignItems:"flex-end"}}>
                            <button 
                                onClick={e=>{
                                    //console.log('pullSelectProduct')
                                    //console.log(selectProduct)
                                    const temp=[...inputState.productData,...selectProduct]
                                    const tempInputState={...inputState,productData:temp}
                                    setInputState(tempInputState)
                                    deleteSelectProduct()
                                }
                                }
                            >ดึงข้อมูลที่เลือกมาใส่</button>
                        </div>

                        <div style={{display:"flex",alignItems:"flex-end"}}>
                            <button style={{backgroundColor:"#34568B"}}
                                onClick={e=>{setShowProduct(true)}}
                            >
                                <FaSearch/>    
                                ค้นหาสินค้า
                            </button>
                        </div>

                        <div style={{display:"flex",alignItems:"flex-end"}}>
                            <div style={{backgroundColor:"#34568B",width:"1.2rem",height:"1.2rem",
                                         display:"flex",alignItems:"center",justifyContent:"center",
                                         borderRadius:"5px"
                                 }}
                                onClick={e=>{
                                    setInputState({...inputState,
                                            userIdAsCode:!inputState.userIdAsCode
                                    })
                                }}
                            >                                
                                <FaCheck style={{color:"white"}}/>
                               
                            </div>
                            <div>
                                {inputState.userIdAsCode?"ใช้Idเป็นโค้ด":"ใช้Barcodeเป็นโค้ด"}
                            </div>
                        </div>

                    </div>
                    */
                    }

                    {
                       // inputState.productData.map((i,idx)=>{
                       //     return(
                                //renderData(i,idx)
                        //    )
                        //})
                    }
                
                   

                </div>


        </div>
    )
}




return(
<div style={{width:"100%"}}> 

    {
    renderInput()
    }
    {
    renderFooterForStickerPage()
    }
    {
    renderBarcodeOut(inputState)
    }

</div>
)

}

StickerSetting.defaultProps=stickerInputState

export default StickerSetting;
import React from 'react';
import {FaCheckCircle,FaCheck,FaPlusSquare,FaMinusSquare} from 'react-icons/fa'
import {MdSearch,MdCalculate,MdEdit,MdPrint} from 'react-icons/md'

import pageUtil from '../../component/pageComponent/pageUtil'
import axios from '../../util/axiosVerify';
import uuid from 'react-uuid';
import BarcodeScanner from '../../component/barcodeScanner/BarcodeScanner'
import {BiBarcodeReader} from 'react-icons/bi'

import tableUtil from '../../component/table/tableUtil'
import console from '../../util/consoleVerify'
import { GiReturnArrow } from 'react-icons/gi';

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete
} = pageUtil

const {sortColumn,numberWithCommas} = tableUtil

//==================================
function BillBarcode(props) {

const {
    basicDataSt,
    myheader,
    pageData,
    showBillForm1,
    showBillForm2,
    
    billFormData1,
    billFormData2,

    setData1,
    setData2,

    setDetail1,
    setDetail2,
    calDigit,
    submitConfirm,
    billBarcodeWidthLeft,

    printFunc
}=props


//console.log('BillBarcode')
const {billBarcodeForm}=pageData

const {basicData}=basicDataSt
//--------------
/*
const billBarcodeForm={
    productId:"Product ID",
    barcode:"Barcode",
    partnerId:"Partner ID",
    phone:"Phone",
    table:"Table",
    queue:"Queue",
    paymentType:"Payment Type",
    cash:"เงินสด", 
    grandTotal:"สุทธิ",
    change:"เงินทอน",
}
*/
//----------------
const blankInputState={
    productId:"", //number
    barcode:"", //string
    //partnerId:"", //number

    phone:"", //string
    table:"", //select
    queue:"", //number
    paymentType:basicDataSt.basicData.paymentType[0],
    cash:0,
    grandTotal:0,
    change:0,
}

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const refObj={
    productId:React.useRef(),
    barcode:React.useRef(),
    //partnerId:React.useRef(),
    phone:React.useRef()
}

const [showCash,setShowCash]=React.useState(false)
const [showError,setShowError]=React.useState(false)
const [widthLeft,setWidthLeft]=React.useState(billBarcodeWidthLeft)

const [inputState,setInputState]=React.useState({...blankInputState})

let [filterData,setFilterData]=React.useState(null)
//{...filterDataTemplate.product}
const [showScanner,setShowScanner]=React.useState(false)
const [editInputState,setEditInputState]=React.useState(null)

const [transferQty,setTransferQty]=React.useState(1)

React.useEffect(()=>{
    setWidthLeft(billBarcodeWidthLeft)
},[billBarcodeWidthLeft])


React.useEffect(()=>{
    //console.log('inputState..........')
    //console.log(inputState)
   
    if(inputState.paymentType==basicDataSt.basicData.paymentType[0]){
        setShowCash(true)
    }
    else {
        setShowCash(false)
    }
    
},[inputState])

React.useEffect(()=>{
    
    if(showBillForm1){
        if(billFormData1){
            //console.log(billFormData1.grandTotal)
            setInputState({...inputState,
                productId:"",
                barcode:"",
                //partnerId:"",
                phone:"",
                cash:billFormData1.cash,
                change:billFormData1.change,
                paymentType:billFormData1.paymentType,
                grandTotal:billFormData1.grandTotal})
        }
    }
    if(showBillForm2){
        if(billFormData2){
            //console.log(billFormData2.grandTotal)
            setInputState({...inputState,
                productId:"",
                barcode:"",
                //partnerId:"",
                phone:"",
                cash:billFormData2.cash,
                change:billFormData2.change,
                paymentType:billFormData2.paymentType,
                grandTotal:billFormData2.grandTotal})
        }
    }
    
},[billFormData1,billFormData2])


const submitFunc=(data)=>{
    let tempData=data
    //tempData=1
    //console.log('submitFunc')
    //console.log(data)

    //console.log("editInputState")
    //console.log(editInputState)

    setInputState({
        ...inputState,
        [editInputState]:tempData
    })

    const tempValue=data

    if(tempValue){
        //console.log('tempValue')
        //console.log(tempValue)

        //console.log('editInputState')
        //console.log(editInputState)

        if(editInputState=="productId"){
            findProductById("noE",tempValue)
        }
        if(editInputState=="barcode"){
            findProductByBarcode("noE",tempValue)
        }
        if(editInputState=="partnerId"){
            findCustomerById("noE",tempValue)
        }

    }

}

const cancelFunc=()=>{
    setShowScanner(false)
}


const genInputType=(i)=>{
    if(i=="productId"||i=="partnerId"||i=="queue"||i=="cash"||i=="change"){
        return "number"
    }
    else if(i=="barcode"||i=="phone"){
        return "string"
    }
}



const genFilterDataWithIndex=(filterData)=>{

    let tempArray=[]

    filterData.map((i,idx)=>{
        //const temp={...i,tempIndex:idx,selectedLine:false}
        let tempStocking={}

        if(i.stocking){
            let isSearching=true
            i.stocking.map(k=>{
                if(isSearching){
                    if(k.branchId==basicData.branchId){
                        tempStocking={
                            branchId:k.branchId,
                            stock:k.stock,
                            minStock:k.minStock,
                            maxStock:k.maxStock,
                            storage:k.storage
                        }
                        isSearching=false
                    }
                }   
            })
        }


        const temp={...i,selectedLine:false,_id:uuid(),...tempStocking}
        tempArray=[...tempArray,temp]

    })

    return tempArray
}







const findProductByBarcode=(e,mdSearch)=>{

    if(e.target){
        if(!e.target.value){return}
    }

    if(e.key=="Enter"||mdSearch){
        let tempValue
        if(mdSearch){
            tempValue=mdSearch
        }
        else{
            tempValue=e.target.value
        }
        //console.log('findProductById')
        axios.post('/p35product/getlimit',{barcode:tempValue},myheader,shouldRender)
        .then(result=>{
            //console.log('result')
            //console.log(result.data)

            const tempResultDataData=genFilterDataWithIndex(result.data.data)

            if(tempResultDataData[0]){                
                
                let tempProduct=tempResultDataData[0]

                const {
                    id,barcode,productName,sn,
                    groupId,groupName,
                    unit,price,priceLevel,
                    point,isRawMat,
                    remark,active,
                    photoUrl1,
                    branchId,
                    stock,
                    minStock,
                    maxStock,
                    storage
                }=tempProduct
                

                let tempUseInnerVat=false
                let tempInnerVatRate=0

                if(billFormData1){
                    if(billFormData1.useInnerVat){
                        tempUseInnerVat=billFormData1.useInnerVat

                        if(billFormData1.innerVatRate>0||billFormData1.innerVatRate<0){
                            tempInnerVatRate=parseInt(billFormData1.innerVatRate*calDigit)/calDigit
                        }
                    
                    }
                }

                if(billFormData2){
                    if(billFormData2.useInnerVat){
                        //console.log(billFormData2)
                        tempUseInnerVat=billFormData2.useInnerVat
                        
                        if(billFormData2.innerVatRate>0||billFormData2.innerVatRate<0){
                            tempInnerVatRate=parseInt(billFormData2.innerVatRate*calDigit)/calDigit
                        }
                    
                    }
                }

                let tempPrice=price
                let tempResult=price

                let tempPriceIncludeVat=0
                let tempResultIncludeVat=0

                
                if(tempUseInnerVat){
                    const tempPr=(price*tempInnerVatRate)/100+price
                    tempPriceIncludeVat=parseInt(tempPr*calDigit)/calDigit
                    tempResultIncludeVat=tempPriceIncludeVat
                    //tempResult=tempPriceIncludeVat
                    //tempPrice=tempPriceIncludeVat
                }
                

                const tempObj={
                    id,barcode,productName,sn,
                    groupId,groupName,
                    unit,
                    
                    priceLevel,
                    point,
                    isRawMat,
                    remark,
                    photoUrl1,

                    branchId,
                    stock,
                    minStock,
                    maxStock,
                    storage,

                    _id:uuid(),
                    selectedLine:true,
                    quantity:transferQty?transferQty:1-1,
                    
                    result:tempResult,
                    resultIncludeVat:tempResultIncludeVat,

                    resultPoint:point,

                    price:tempPrice,
                    priceIncludeVat:tempPriceIncludeVat,

                    jobStatus:"open",
                    detailTime:new Date().toISOString(),
                }

                if(showBillForm1==true){
                    //console.log('showBillForm1')
                    setDetail1(tempObj)
                }
                if(showBillForm2==true){
                    //console.log('showBillForm2')
                    setDetail2(tempObj)
                }
                setInputState({
                    ...inputState,
                    barcode:""
                })
                setTransferQty(1)
            }
            else{
                setInputState({
                    ...inputState,
                    barcode:""
                }) 
            }
        })
        .catch(error=>{
           //console.log('error')
        })
     }
}

const findProductById=(e,mdSearch)=>{

    if(e.target){
        if(!e.target.value){return}
    }

    if(e.key=="Enter"||mdSearch){
        let tempValue
        if(mdSearch){
            tempValue=mdSearch
        }
        else{
            tempValue=e.target.value
        }

       // //console.log('findProductById')
        axios.post('/p35product/getlimit',{id:tempValue},myheader,shouldRender)
        .then(result=>{
           // //console.log('result')
            //console.log(result.data)

            const tempResultDataData=genFilterDataWithIndex(result.data.data)

            if(tempResultDataData[0]){                
                const tempProduct=tempResultDataData[0]
                const {
                    id,barcode,productName,sn,
                    groupId,groupName,
                    unit,price,priceLevel,
                    point,isRawMat,
                    remark,active,
                    photoUrl1,

                    branchId,
                    stock,
                    minStock,
                    maxStock,
                    storage

                }=tempProduct
                

                let tempUseInnerVat=false
                let tempInnerVatRate=0

                if(billFormData1){
                    if(billFormData1.useInnerVat){
                        tempUseInnerVat=billFormData1.useInnerVat

                        if(billFormData1.innerVatRate>0||billFormData1.innerVatRate<0){
                            tempInnerVatRate=parseInt(billFormData1.innerVatRate*calDigit)/calDigit
                        }
                    
                    }
                }

                if(billFormData2){
                    if(billFormData2.useInnerVat){
                        //console.log(billFormData2)
                        tempUseInnerVat=billFormData2.useInnerVat
                        
                        if(billFormData2.innerVatRate>0||billFormData2.innerVatRate<0){
                            tempInnerVatRate=parseInt(billFormData2.innerVatRate*calDigit)/calDigit
                        }
                    
                    }
                }


                let tempPriceIncludeVat=0
                let tempResultIncludeVat=0

                let tempPrice=price
                let tempResult=price

                if(tempUseInnerVat){
                    const tempPr=(price*tempInnerVatRate)/100+price
                    tempPriceIncludeVat=parseInt(tempPr*calDigit)/calDigit
                    tempResultIncludeVat=tempPriceIncludeVat
                    //tempResult=tempPriceIncludeVat
                    //tempPrice=tempPriceIncludeVat
                }


                const tempObj={
                    id,barcode,productName,sn,
                    groupId,groupName,
                    unit,
                    
                    priceLevel,
                    point,
                    isRawMat,
                    remark,
                    photoUrl1,

                    branchId,
                    stock,
                    minStock,
                    maxStock,
                    storage,



                    _id:uuid(),
                    selectedLine:true,
                    quantity:transferQty?transferQty:1-1,

                    price:tempPrice,
                    result:tempResult,
                    
                    resultPoint:point,
                    priceIncludeVat:tempPriceIncludeVat,
                    resultIncludeVat:tempResultIncludeVat,
                    //priceExcludeVat:tempPriceExcludeVat,

                    jobStatus:"open",
                    detailTime:new Date().toISOString(),
                    
                }

                if(showBillForm1==true){
                   // //console.log('showBillForm1')
                    setDetail1(tempObj)
                }
                if(showBillForm2==true){
                    //console.log('showBillForm2')
                    setDetail2(tempObj)
                }
                setInputState({
                    ...inputState,
                    productId:""
                })
                setTransferQty(1)
            }
            else{
                setInputState({
                    ...inputState,
                    productId:""
                })
            }

        })
        .catch(error=>{
           //console.log('error')
        })
     }
}

const findCustomerById=(e,mdSearch)=>{

    if(e.target){
        if(!e.target.value){return}
    }


    if(e.key=="Enter"||mdSearch||e.target.value){
        let tempValue
        if(mdSearch){
            tempValue=mdSearch
        }
        else{
            tempValue=e.target.value
        }

       //console.log('findCustomerById')
       axios.post('/p35partner/getlimit',{id:tempValue},myheader,shouldRender)
       .then(result=>{
            //console.log('result')
            //console.log(result.data)

            if(result.data.data[0]){                
                const tempCustomer=result.data.data[0]
                const {id,name,title,phone,address,partnerType}=tempCustomer
                const tempObj={
                    partnerId:id,title:title,name:name,phone:phone,
                    address:address[0],partnerType:partnerType
                 }

                if(showBillForm1==true){
                    //console.log('showBillForm1')
                    setData1(tempObj)
                }
                if(showBillForm2==true){
                    //console.log('showBillForm2')
                    setData2(tempObj)
                }
                setInputState({
                    ...inputState,
                    partnerId:""
                })
            }
            else{
                setInputState({
                    ...inputState,
                    partnerId:""
                })
            }
       })
       .catch(error=>{
          //console.log('error')
       })
    }
}


const findCustomerByPhone=(e,mdSearch)=>{
    //console.log('findCustomerByPhone..')
    //console.log(e.target)
    //console.log(mdSearch)

    if(e.target){
        if(!e.target.value){return}
    }

    if(e.key=="Enter"||mdSearch){
        let tempValue
        if(mdSearch){
            tempValue=mdSearch
        }
        else{
            tempValue=e.target.value
        }

        //console.log('findCustomerByPhone')
        //const temp ={["phone"]:{$elemMatch:{$eq:tempValue}}}
        const temp ={["phone"]:{$eq:tempValue}}

        //{$regex:e.target.value.trim().replace(/\s/,"|"),$options:'gi' }
        //const temp ={["phone"]:{$elemMatch:{$regex:"222",$options:'gi'}}}
       axios.post('/p35partner/getlimit',temp,myheader,shouldRender)
       .then(result=>{
            //console.log('result')
            //console.log(result.data)

            if(result.data.data[0]){
                
                const tempCustomer=result.data.data[0]
                //console.log(tempCustomer)
                const {id,name,title,phone,address,partnerType}=tempCustomer

                const tempObj={
                    partnerId:id,title:title,name:name,phone:phone,
                    address:address[0],partnerType:partnerType
                 }

                if(showBillForm1==true){
                    setData1(tempObj)
                }
                if(showBillForm2==true){
                    setData2(tempObj)
                }
                setInputState({
                    ...inputState,
                    phone:""
                })

            }
            else{
                setInputState({
                    ...inputState,
                    phone:""
                })
            }
       })
       .catch(error=>{
          //console.log('error')
       })
    }
}


const setQueue=(e,faCheck)=>{


    if(e.key=="Enter"||faCheck){
        let tempValue
        if(faCheck){
            tempValue=faCheck
        }
        else{
            tempValue=e.target.value
        }

        const tempObj={
            queue:tempValue,
            queueStatus:"open"
        }

        if(showBillForm1==true){
            setData1(tempObj)
        }
        if(showBillForm2==true){
            setData2(tempObj)
        }
        setInputState(blankInputState)
    }
}

const setTable=(e)=>{

    const tempObj={
        table:e.target.value,
        tableStatus:"open"
    }
    
    if(showBillForm1==true){
        setData1(tempObj)
    }
    if(showBillForm2==true){
        setData2(tempObj)
    }
    setInputState(blankInputState)    
}

const findChange=(e,cash)=>{
   
        const tempCash=parseInt(cash*calDigit)/calDigit
        const tempChange=tempCash-inputState.grandTotal
        setInputState({
            ...inputState,
            change:tempChange
        })

}

const keyDownFunc=(i,e)=>{
    if(i=="partnerId"){
        findCustomerById(e)
    }
    else if(i=="phone"){
        findCustomerByPhone(e)
    }
    else if(i=="queue"){
        setQueue(e)
    }
    else if(i=="productId"){
        findProductById(e)
    }
    else if(i=="barcode"){
        findProductByBarcode(e)
    }
}

const findFunction=(i)=>{

    const tempValue=inputState[i]

    if(tempValue){
        if(i=="productId"){
            findProductById("noE",tempValue)
        }
        if(i=="barcode"){
            findProductByBarcode("noE",tempValue)
        }
        if(i=="partnerId"){
            findCustomerById("noE",tempValue)
        }
        if(i=="phone"){
            findCustomerByPhone("noE",tempValue)
        }
        if(i=="queue"){
            setQueue("noE",tempValue)
        }
        if(i=="cash"){
            findChange("noE",tempValue)
        }

    }


}

const renderInput=(i)=>{
        if(i=="table"){
            return (
                <select className="w-100"
                    onChange={e=>setTable(e)}
                >
                    <option>....</option>
                    {
                    basicDataSt.basicData[i].map((j,idx2)=>j.tableActive
                    ?<option key={idx2}
                   
                    >{j.tableName}</option>
                    :null
                    )
                    }
                </select>
            )
        }
        else if(i=="paymentType"){
            return (
                <select className="w-100"

                    onChange={e=> setInputState({
                        ...inputState,
                        paymentType:e.target.value
                    })}
                >
                    <option>....</option>
                    {
                    basicDataSt.basicData[i].map((j,idx2)=>(
                    <option key={idx2}
                    selected={
                        (inputState["paymentType"]==j)
                        ?"selected"
                        :""
                     }
                    >{j}
                    </option>
                    ))
                    }
                </select>
            )
        }
        else if(i=="cash"){
            if(showCash){
                return (

                    <input
                    type={"number"}
                    value={ inputState[i]}
                    style={{height:"2.2rem",
                            color:inputState[i]<inputState.grandTotal?"red":"black",
                            fontSize:"2rem",backgroundColor:"#fdf07a"
                           }}
                        onKeyDown={e=>{
                        if(e.key=="Enter"){
                            const tempCash=parseInt(e.target.value*calDigit)/calDigit
                            const tempChange=tempCash-inputState.grandTotal
                            setInputState({
                                ...inputState,
                                change:tempChange
                            })
                        }
                    }}
                    onChange={e=>{
                        setInputState({...inputState,
                            [i]:e.target.value
                        })
                    }}
                    />
                )
            }
        }
        else if(i=="grandTotal"){
            return(
            <div style={{backgroundColor:"#a7c6eb",fontSize:"2rem"
                         }}>
                  {numberWithCommas(inputState.grandTotal,calDigit,"grandTotal")}
            </div>
            )
        }
        else if(i=="change"){
            if(showCash){
                return(
                    <div style={{backgroundColor:"#fdf07a",fontSize:"2rem"}}>
                    {numberWithCommas(inputState.change,calDigit,"change")}
                    </div>
                )
            }
        }
        else {
            return <input
                    type={genInputType(i)}
                    value={inputState[i]}
                    onKeyDown={e=>{
                        keyDownFunc(i,e)
                    }}
                    onChange={e=>{
                        setInputState({...inputState,[i]:e.target.value})
                    }}
               />
        }
}
//==========================
const renderBody=()=>{
    //const objKeys=Object.keys(billBarcodeForm)

    const objKeys=["barcode","productId",
                  //"partnerId",
                  "phone",
                  //"queue",
                  //"table",  
                  "paymentType","cash","grandTotal","change",

                  ]
    //console.log('renderBody objKeys')
    //console.log(objKeys)

    return objKeys.map((i,idx)=>(
        <div key={idx} 
            className="flex-center-center jc-start" 
            style={{padding:"0.2rem",width:"100%"}}>
            
            {i=="cash"||i=="change"||i=="grandTotal"
            ?<div className={widthLeft>30
                            ?'xc6 lc6 mc12 sc12'
                            :'xc12 lc12 mc12 sc12'
                            }
                style={{display:"flex",justifyContent:"space-between"}}
            >
                <div style={{fontSize:"1.2rem",color:i=="grandTotal"?"black":"brown"}}>
                {billBarcodeForm[i]}
                </div>
                {i=="cash"&&
                <FaCheck
                    className='sm-icon'
                    onClick={e=>{
                        findFunction(i)
                    }}
                />
                }
            </div>
            :i=="barcode"||i=="productId"||i=="phone"||i=="queue"//i=="partnerId"
                ?<div className={widthLeft>30
                                ?'xc6 lc6 mc12 sc12'
                                :'xc12 lc12 mc12 sc12'
                                } 
                        style={{color:"black",
                        display:"flex",justifyContent:"space-between"}}>
                    <div>
                        
                        {billBarcodeForm[i]}
                    </div>

                    {i=="productId"||i=="barcode"//||i=="partnerId"
                    ?<BiBarcodeReader className="md-icon"
                        onClick={e=>{
                            setEditInputState(i)
                            setShowScanner(true)
                        }}
                    />
                    :null
                    }

                    {i=="queue"
                    ?<FaCheck
                        className='sm-icon'
                        onClick={e=>{
                            findFunction(i)
                        }}
                    />
                    :<MdSearch
                        ref={refObj[i]}
                        className='md-icon'
                        onClick={e=>{
                            findFunction(i)
                        }}
                    />
                    }
                  

                 </div>
                :<div className={widthLeft>30
                    ?'xc6 lc6 mc12 sc12'
                    :'xc12 lc12 mc12 sc12'
                    } 
                    style={{color:"black"}}
                >{billBarcodeForm[i]}</div>

            }


            <div className={widthLeft>30
                            ?'xc6 lc6 mc12 sc12'
                            :'xc12 lc12 mc12 sc12'
                            }
            >
            {
                renderInput(i)
            }
            </div>
            
        </div>
    ))
}
//====================


//====================
return(
<div className="h-100 w-100" 
     style={{paddingTop:"0rem"}} >     

    <div className='w-100 flex-center-center'>
        <div className="xc2" 
             style={{display:"flex",justifyContent:"flex-end",
                     margin:"0.2rem"
                   }}>
            <FaPlusSquare className='md-icon'
                onClick={e=>{
                    setTransferQty(transferQty+1)
                }}
            />
        </div>
        <div className='xc5'>
            <input
                style={{width:"100%",fontSize:"1.2rem",height:"1.5rem"}}
                type="number"
                value={transferQty}
                onChange={e=>{
                    setTransferQty(parseFloat(e.target.value))
                }}
            />
         </div>
         
         <div className="xc2" 
             style={{display:"flex",justifyContent:"flex-start",
                     margin:"0.2rem"
                   }}>
            <FaMinusSquare className='md-icon'
                onClick={e=>{
                    setTransferQty(transferQty-1)
                }}
            />
        </div>
    </div>


    {billFormData1||billFormData2
        ?renderBody()
        :null
    }
    {billFormData1||billFormData2
    ?<div className='w-100 flex-center-center' 
          style={{margin:"0",padding:"0",flexWrap:"nowrap"}}>
         <button
            style={{width:"30%",margin:"0",marginTop:"0.5rem"}}
            onClick={e=>{
                //submitConfirm()
                const temp2=inputState["cash"]
                const tempCash=parseInt(temp2*calDigit)/calDigit
                const tempChange=tempCash-inputState.grandTotal
                
                const tempObj={
                    ...inputState,
                    change:tempChange
                }

                
                setInputState(tempObj)

                if(showBillForm1==true){
                    //console.log('showBillForm1')
                    setData1(tempObj)
                }
                if(showBillForm2==true){
                    //console.log('showBillForm2')
                    setData2(tempObj)
                }

            }}
        >
            <MdCalculate style={{fontSize:"2.5rem"}}/>
        </button>
        
        <button
            style={{width:"30%",margin:"0",marginTop:"0.5rem"}}
            onClick={e=>{
                submitConfirm(inputState)
            }}
        >
            <FaCheckCircle style={{fontSize:"2.5rem"}}/>
        </button>

        <button
            style={{width:"30%",margin:"0",marginTop:"0.5rem"}}
            onClick={e=>{
                //submitConfirm(inputState)
                printFunc(inputState)
            }}
        >
            <MdPrint style={{fontSize:"2.5rem"}}/>
        </button>



    </div>
    :null
    }
    {showScanner
        ?<BarcodeScanner
            submitFunc={submitFunc}
            cancelFunc={cancelFunc}
            pageForm={{
                formHead:"Barcode Scanner",
            }}
        />
        :null
    }
</div>

)

}
export default BillBarcode;

import React from 'react'
import console from '../../util/consoleVerify'

//import $ from 'jquery';
//import './Galleryone.css';

//import Galleryone from './component/galleryone/Galleryone'
//<div style={{width:"300px",border:"1px solid black"}}>
//  <Galleryone imgarrs={["/upload/employee/room-5.jpeg","/upload/employee/room-1.jpeg"]}/>
//</div>

import renderModalImage from './renderModalImage'

function GalleryoneHeight({imgarrs,width}) {

    const [showModalImage,setShowModalImage]=React.useState(false)
    
    let [imageSrc,setImageSrc]=React.useState(null)
    let [isYoutube,setIsYoutube]=React.useState(false)
    let [modalImageWidth,setModalImageWidth]=React.useState(30);


    let totalWidth=100
    if(imgarrs){
        if(imgarrs.length>0){
            totalWidth=imgarrs.length*100
        }
    }


    const renderImg=(arrs)=>{

        if(arrs){
            return arrs.map((i,index)=>{
                if(i!==""){


                    let imgSrc
                    let imgName
                    let isFile
                    if(i.blob){
                        isFile=true
                        imgSrc=i.blob
                        imgName=i.name 
                    }    
                    else {
                        isFile=false
                        imgSrc=i
                        imgName=i
                    }
                    //style={{height:'100%',width:'100%',
                    //display:'grid',placeItems: 'center',
                    //}}
                    let checkYoutubeUrl=false
                    if(typeof i == "string"){
                        if(i.split("/embed/").length==2){
                            checkYoutubeUrl=true
                        }
                    }

                    return    (
                        <div key={index} style={{maxWidth:'100%',maxHeight:"100%"}}>
                           {checkYoutubeUrl
                            ?<div style={{width:"100%",height:"100%",position:"relative"}} >
                                 <div className='' 
                                    style={{position:"absolute",
                                            width:"80%",
                                            height:"80%",
                                            top:"0",
                                            left:"0",
                                            zIndex:"10"
                                            }}
                                    
                                    onDoubleClick={e=>{
                                        setIsYoutube(true)
                                        setImageSrc(imgSrc)
                                        setTimeout(()=>{
                                            setShowModalImage(true)
                                        },50)
                                    }} 
                                >
                                </div>
                                <embed 
                                    style={{maxWidth:"80%",maxHeight:"80%"}}
                                    src={i}
                                />
                               
                            </div>
                           
                            :<img className="img" 
                                 src={imgSrc} 
                                 style={{
                                        width:'100%',
                                        objectFit:"cover"
                                    }}
                                    onDoubleClick={e=>{
                                        setIsYoutube(false)
                                        setImageSrc(imgSrc)
                                        setTimeout(()=>{
                                            setShowModalImage(true)
                                        },50)
                                    }} 
                            />
                            }
                        </div>   
                    )

                } //if(i!=="")


            }) 
        }
    } 
    //console.log($(`#img-id`).width()*0.57)
    //style={{width:'100%',height:imgFrameHeight,overflow:"auto"}}
    // <div style={{height:"100%",width:entparWidth}} >
    //
    //
    //
    //<div style={{height:"100%"}} >
    return (
        <div style={{width:"100%",height:"100%"}} >
        {
                renderModalImage({show:showModalImage,
                                 setShow:setShowModalImage,
                                 imgSrc:imageSrc,
                                 modalImageWidth,
                                 setModalImageWidth,
                                 isYoutube:isYoutube,
                                 setIsYoutube:setIsYoutube

                })
        }

        {
            //getWidthHeight(width,height)
            //width:`${width}px`
        }

        
      
            <div className="" 
                id="img-id"
                style={{
                    height:"100%",
                    width:`${totalWidth}%`,
                    display:"flex",
                    overflowY:"hidden"
                }}    
            >       
                {
                    renderImg(imgarrs)
                }

                {/*
                  imgarrs.map((k,idxK)=>{
                    return(
                      <div className="bd-yellow"
                           key={idxK}
                           style={{height:"100%",width:"50%"}}
                      >
                          {idxK}
                      </div>
                    )
                  })  
                */}
            </div>

        
        </div>
    )
}
//style={{width:'100%',height:imgFrameHeight,overflow:"auto"}}


GalleryoneHeight.defaultProps={
    imgarrs:[]
}

export default GalleryoneHeight;
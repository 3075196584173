
import React from 'react';
import {MainContext} from '../../context/MainContext'
import PageComponentTask  from '../../component/pageComponent/PageComponentTask';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import console from '../../util/consoleVerify'


function Task() {

//console.log('Partner')

const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
      mainContextTaskFilterData,setMainContextTaskFilterData,
      mainContextPartnerFilterDataForTask,
      setMainContextPartnerFilterDataForTask
}=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
<div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
    {
    <div className="h-100 w-100">
        {basicDataSt.basicData&&myheader&&
        <PageComponentTask
            setReloadBasicData={setReloadBasicData}
            filterDataTemplate={filterDataTemplate.task}
            filterTemplate={basicDataSt.pageFilter.taskFilter}
            inputState={inputState.taskInputState}
            basicDataSt={basicDataSt}
            myheader={myheader}
            
            formTemplate={basicDataSt.modalFormTemplate.taskForm}
            editFormTemplate={basicDataSt.modalFormTemplate.taskEditForm}
            repeatedAddFormTemplate={basicDataSt.modalFormTemplate.repeatedTaskForm}

            stateTemplate={StateTemplate.taskState}
            pageFilterForm={basicDataSt.pageFilterForm.taskFilterForm}

            modalFormTemplate={basicDataSt.pageData.ModalForm}
            addFormTitle={basicDataSt.pageData.ModalForm.taskAddForm}
            repeatedAddFormTitle={basicDataSt.pageData.ModalForm.taskRepeatedAddForm}

            editFormTitle={basicDataSt.pageData.ModalForm.taskEditForm}
            bgColor={"#00BFFF"}
            calDigit={100}
            mainContextTaskFilterData={mainContextTaskFilterData}
            setMainContextTaskFilterData={setMainContextTaskFilterData}

            partnerFilterDataTemplate={filterDataTemplate.partner}
            partnerFilterTemplate={basicDataSt.pageFilter.partnerFilter}
            partnerInputState={inputState.partnerInputState}

            mainContextPartnerFilterDataForTask={mainContextPartnerFilterDataForTask}
            setMainContextPartnerFilterDataForTask={setMainContextPartnerFilterDataForTask}
            customerPageFilterForm={basicDataSt.pageFilterForm.customerFilterForm}

        />
        }
    </div>
    
    }
</div>
)

}
export default Task;




import React from 'react';
import {MainContext} from '../../context/MainContext'
import PageComponentPartner  from '../../component/pageComponent/PageComponentPartner';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import console from '../../util/consoleVerify'


function Partner() {

//console.log('Partner')

const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
      mainContextPartnerFilterData,setMainContextPartnerFilterData
}=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
<div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
  
   
    <div className="h-100 w-100">
        {basicDataSt.basicData&&myheader&&
        <PageComponentPartner
            setReloadBasicData={setReloadBasicData}
            filterDataTemplate={filterDataTemplate}
            filterTemplate={basicDataSt.pageFilter.partnerFilter}
            inputState={inputState.partnerInputState}
            basicDataSt={basicDataSt}
            myheader={myheader}
            
            formTemplate={basicDataSt.modalFormTemplate.partnerForm}
            editFormTemplate={basicDataSt.modalFormTemplate.partnerEditForm}
            
            stateTemplate={StateTemplate.partnerState}
            pageFilterForm={basicDataSt.pageFilterForm.partnerFilterForm}

            modalFormTemplate={basicDataSt.pageData.ModalForm}
            addFormTitle={basicDataSt.pageData.ModalForm.partnerAddForm}
            editFormTitle={basicDataSt.pageData.ModalForm.partnerEditForm}
            bgColor={"#74b979"}
            calDigit={100}
            mainContextPartnerFilterData={mainContextPartnerFilterData}
            setMainContextPartnerFilterData={setMainContextPartnerFilterData}
        />
        }
    </div>
</div>
)

}
export default Partner;



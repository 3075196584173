
import React from 'react';
import {Route,Switch,Redirect} from 'react-router-dom';
import Dump from './Dump'
import console from './util/consoleVerify'

import BasicData from './page/basicdata/BasicData'
import Branch from './page/branch/Branch'
import Bill from './page/bill/Bill'
import Partner from './page/partner/Partner'
import Product from './page/product/Product'
import Group from './page/group/Group'
import Task from './page/task/Task'

import Home from './page/home/Home'
import LogIn from './page/login/LogIn'
import Sticker from './page/sticker/Sticker'
import Shop from './page/shop/Shop'
import User from './page/user/User'
import ShopPayment from './page/shoppayment/ShopPayment'
import BranchForAdmin from './page/branchForAdmin/BranchForAdmin'
import BasicDataForAdmin from './page/basicDataForAdmin/BasicDataForAdmin'

import GroupForAdmin from './page/groupForAdmin/GroupForAdmin'
import ProductForAdmin from './page/productForAdmin/ProductForAdmin'
import PartnerForAdmin from './page/partnerForAdmin/PartnerForAdmin'
import TransactionForAdmin from './page/transactionForAdmin/TransactionForAdmin'
import StickerForAdmin from './page/stickerForAdmin/StickerForAdmin'



import {MainContext} from './context/MainContext'

import './App2.css'

function App() {

const {basicDataSt}=React.useContext(MainContext)

return basicDataSt.basicData&&basicDataSt.pageData&&basicDataSt.tableTemplate
?<div className="bgc-lightGray">
      <Route exact path="/" render={()=>(<Redirect to="/pos/home"/>)}/>
      <Route exact path="/pos" render={()=>(<Redirect to="/pos/home"/>)}/>

      <Route exact path="/pos/login" component={LogIn}/>
      <Route exact path="/pos/home" component={Home}/> 
      <Route exact path="/pos/bill" component={Bill}/> 
      <Route exact path="/pos/basicdata" component={BasicData}/> 
      <Route exact path="/pos/branch" component={Branch}/> 

      <Route exact path="/pos/partner" component={Partner}/> 
      <Route exact path="/pos/product" component={Product}/> 
      <Route exact path="/pos/group" component={Group}/> 
      <Route exact path="/pos/barcode" component={Sticker}/> 
      <Route exact path="/pos/task" component={Task}/> 



      <Route exact path="/pos/s_mm23pos" component={Shop}/> 
      <Route exact path="/pos/u_mm23pos" component={User}/> 
      <Route exact path="/pos/sp_mm23pos" component={ShopPayment}/> 

      <Route exact path="/pos/br_mm23pos" component={BranchForAdmin}/> 
      <Route exact path="/pos/ba_mm23pos" component={BasicDataForAdmin}/> 

      <Route exact path="/pos/group_mm23pos" component={GroupForAdmin}/> 
      <Route exact path="/pos/product_mm23pos" component={ProductForAdmin}/> 
      <Route exact path="/pos/partner_mm23pos" component={PartnerForAdmin}/> 
      <Route exact path="/pos/sticker_mm23pos" component={StickerForAdmin}/> 
      <Route exact path="/pos/transaction_mm23pos" component={TransactionForAdmin}/> 
</div>
:<div className="bgc-lightGray" style={{width:"100w",height:"100vh"}}>
      <LogIn/>
</div>



}
export default App;



/*
import React from 'react';
import * as XLSX from 'xlsx';


function App() {
      const downloads =(data)=>{
            let ws = XLSX.utils.json_to_sheet(data)
            let wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, "sheet")
            let buf = XLSX.write(wb, {bookType:'xlsx',type:'buffer'})
            let str = XLSX.write(wb, {bookType:'xlsx',type:'binary'})
      
            XLSX.writeFile(wb,'myfilename.xlsx')
      }
      


      const myData=[
            {"name":"John", "city": "Seattle"},
            {"name":"Mike", "city": "Los Angeles"},
            {"name":"Zach", "city": "New York"}
          ];

      
      return(
            <div>
                  <div>
                        aaaaa
                  </div>
                  <button
                        onClick={e=>{
                              downloads(myData)
                        }}
                  >
                        test
                  </button>

            </div>
      )
      
}
export default App;
*/
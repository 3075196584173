import { relativeTimeRounding } from "moment";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
//import Webcam from "react-webcam";
import {FaCheck,FaBan} from 'react-icons/fa'; 
import {MdSearch} from 'react-icons/md'; 
import console from '../../util/consoleVerify'

import BarcodeScannerComponent from "react-qr-barcode-scanner";

function BarcodeScanner(props) {

const {submitFunc,cancelFunc,pageForm}=props

const submitRef=React.useRef()

const [data, setData] = React.useState(null);

React.useEffect(()=>{
    if(data){
        submitRef.current.click()
    }
},[data])


const renderFooter=()=>{
return(
    <div className="Modal-footer">
        <div>
            <button
                ref={submitRef}
                onClick={e=>{
                    if(submitFunc){

                        if(data){
                            cancelFunc(false)
                            submitFunc(data)

                        }
                    }
                }}
            
            >
                <MdSearch
                    style={{fontSize:"1.2rem"}}
                />
            </button>
        </div>
        <div>
            <button
                onClick={e=>{
                    if(cancelFunc){
                        cancelFunc(false)
                    }
                }}
            >
                <FaBan/>
            </button>
        </div>
    </div>
)
}

return (

<div className="Modal-background">
    <div className="Modal-box  xc8 lc10 mc11 sc11" 
         style={{display:"flex",
                 justifyContent:"center",
                 alignItems:"center",
                 flexWrap:"wrap",
                 backgroundColor:"white"}}>
         
         
            <div className="Modal-header"
                 style={{
                    width:"100%",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                 }}
            >
                <h3>{pageForm.formHead}</h3>
            </div>
      
            
            <div className="flex-center-center w-100"
                    style={{margin:"1rem 0"}}
            >
                <BarcodeScannerComponent
                    width={350}
                    height={400}
                    onUpdate={(err, result) => {
                    if (result) {
                        setData(result.text)
                    }
                    else {
                        setData(null);
                    }
                    }}
                />
            </div>

            <div className="w-100"
                    style={{textAlign:"center",margin:"1rem"}}
            >{data
            ?data
            :"Not Found"
            }
            </div>

             
            {
              renderFooter()
            }

    </div>
  
</div>
)

}
export default BarcodeScanner;



/*

 {showScanner
      ?<BarcodeScanner
        submitFunc={submitFunc}
        cancelFunc={cancelFunc}
        pageForm={{
          formHead:"Barcode Scanner",
          id:"ID",
          barcode:"Barcode",
          sn:"SN"
        }}
      />
      :null
      }


*/
import React from 'react'

import {MdRefresh,MdSwapHoriz,MdSettings,
    MdSearch,MdEdit,MdAddCircle,MdDelete,MdPrint,
    MdChevronLeft,MdChevronRight,MdLastPage,
    MdRadioButtonChecked,MdClose,MdRedo,
    MdChecklistRtl
} from 'react-icons/md';


import {
    GoPackage
} from 'react-icons/go';

import Ticon from '../../component/ticon/Ticon'
import console from '../../util/consoleVerify'

import {RiShareForwardLine} from 'react-icons/ri'
import { FaBullseye, FaRegArrowAltCircleUp,FaRegArrowAltCircleLeft,
    FaRegArrowAltCircleRight
} from 'react-icons/fa';

const renderBadgeForBill = ({
                filterData,setFilterData,
                setPageNumber,
                bgColor,
                backToFilter,
                editData,
                captureEditData,
                setReloadDataFunc,
                genCsv,
                useStock,
                captureProductOfGroup,
                showGoPackage,
                bullEyeShow,
                unSelectShow,
                selectAllShow,
                closeShow,
                closeFunc,
                captureSelectProduct,
                
                cancelSelectProduct,
                selectAll,
                
                mergeProduct,
                setShowTable,
                captureSelectedLine,

                addSelectProductToBillForm,
                productList
                //setShowModalCsv
})=>{
    //console.log('renderBadgeForBill')
    //console.log(filterData)

  
    const {pageNumber,limitRow,count,selectProduct}=filterData

    const calTotalPage =()=>{
       return Math.ceil(count/limitRow)
    }

    let tempSwapShow=false

    if(filterData.badgeState){
        if(filterData.badgeState.swapShow){
            tempSwapShow=true
        }
    }

    let tempCheckListShow=false

    if(filterData.badgeState){
        if(filterData.badgeState.checkListShow){
            tempCheckListShow=true
        }
    }
    
    const countSelectedLine=()=>{

        let tempArray=[]

        if(selectProduct){
            selectProduct.map(i=>{
                tempArray=[...tempArray,i.id]
            })
        }

        tempArray=new Set(tempArray)

        let tempCount=0

        for (let j of tempArray){
            let isCount=false
            
            selectProduct.map(i=>{
                if(i.id==j){
                    if(i.selectedLine){
                        isCount=true
                    }
                    else{
                        isCount=false
                    }
                }
            })

            if(isCount){
                tempCount=tempCount+1
            }

        }

        return tempCount
    }



    return (
    <div className="badge-frame-xc12"
        style={{background:bgColor,
                //height:"2.5rem",
                overflowX:"auto"

        }}
    > 
        
        <div className="flex-center-center flex-no-wrap xc12 jc-start"
              style={{overflow:"",justifyContent:"flex-start"}}>
            
            {/*
            <FaRegArrowAltCircleLeft
                className="sm-icon"
                onClick={e=>backToFilter()}
            />
            */}


            {setReloadDataFunc&&
            <div>
                <MdRefresh
                    className="sm-icon"
                    onClick={e=>{
                        setReloadDataFunc()
                        //captureEditData(editData)
                    }}
                />

            </div>
            }


            {
            /*
            <div className="iconbox"
                onClick={e=>{
                    genCsv()
                    //setShowModalCsv(true)
                    //if(csvFunc){csvFunc()}
                }}
            >
                <Ticon 
                    iconName="FaFileCsv"
                    className="sm-icon" 
                    textStyle={{color:"black"}}
                />
            </div>
            */
            }
            
            {
            /*
            showGoPackage&&
            <div>
                <GoPackage
                    className="sm-icon"
                    onClick={e=>{
                        captureProductOfGroup()
                        //captureEditData(editData,1)
                    }}
                />
            </div>
            */
            }

            {true&&//tempSwapShow&&
             <div>
                <MdSwapHoriz
                    className="sm-icon"
                    onClick={e=>{
                        if(setShowTable){
                            setShowTable(!filterData.showTable)
                        }
                      
                    }}
                />
             </div>
            }



            {addSelectProductToBillForm&&
            <div>
                <FaRegArrowAltCircleRight
                className="sm-icon"
                    style={{color:"red",
                            visibility:
                                productList
                                ?productList.length>0?"visible":"hidden"
                                :"hidden"
                          }}

                    onClick={e=>{

                        if(productList){
                            if(productList.length>0){
                                addSelectProductToBillForm()
                            }
                        }
                    }}
                />
            </div>
            }



            {editData&&
            <div>
                <RiShareForwardLine
                    className="sm-icon"
                    onClick={e=>{

                        captureEditData(editData,1)
                    }}
                />
            </div>
            }


            {//editData&&useStock&&
            /*
            <div
                className="sm-icon"
                onClick={e=>{
                    //console.log('editData')
                    //console.log(editData)
                    captureEditData(editData,editData.stock)
                }}
            >
                Stock
            </div>
            */
            }

            {unSelectShow
            ?
            <div>
                <FaBullseye className="sm-icon"
                    onClick={e=>{
                        if(cancelSelectProduct){cancelSelectProduct()}
                    }}
                />
            </div>
            :null
            }

            {selectAllShow
            ?
            <div>
                <MdRadioButtonChecked className="sm-icon"
                    onClick={e=>{
                        if(selectAll){selectAll()}
                    }}
                />
            </div>
            :null
            }
            


            {tempCheckListShow&&
            <div>
                <MdChecklistRtl
                    className="sm-icon"
                    onClick={e=>{

                        if(captureSelectedLine){
                            captureSelectedLine(filterData.data0)
                        }

                    }}
                />
            </div>
            }


            {bullEyeShow  
            ?
            <div className="bd-black" style={{display:"flex",alignItems:"center"}}>
                <MdRadioButtonChecked
                    style={{color:"#333",marginLeft:"0.5rem"}}
                  
                />
                <div style={{margin:"auto 10px",fontSize:"1.4rem"}}>
                    {
                    countSelectedLine()
                     }
                </div>

                <RiShareForwardLine
                    className="sm-icon"
                    style={{visibility:selectProduct.length>0?"visible":"hidden"}}

                    onClick={e=>{
                        if(captureSelectProduct){
                            captureSelectProduct(selectProduct,1)
                        }
                        //captureEditData(editData,1)
                    }}
                />
                <div
                    className="sm-icon"
                    style={{visibility:selectProduct.length>0?"visible":"hidden"}}
                    onClick={e=>{
                        if(captureSelectProduct){
                            captureSelectProduct(selectProduct,"stock")
                        }
                    }}
                >
                    Stock
                </div>

            </div>
      
            :null
            }


            {true//pageNumberShow
            ?<div>
                <MdChevronLeft
                    className="sm-icon"
                    style={{visibility:(calTotalPage()>1)&&(pageNumber>1)
                            ?"visible":"hidden"}}
                    onClick={e=>{
                        const temp=parseInt(pageNumber)-1
                        setPageNumber(temp)
                        //setReloadData(true)
                    }}
                />
            </div>
            :null
            }

            {calTotalPage()>1//&&pageNumberShow
             ?<input 
                type="number"
                style={{width:"70px"}}
                value={pageNumber.toString()}
                onChange={e=>{
                    const temp=parseInt(e.target.value)
                    if(temp<=calTotalPage()||!temp){ //04-06-2021
                        setPageNumber(temp)
                        //setReloadData(true)
                    }
                }}
              />
             :null
            }        

            {calTotalPage()>1//&&pageNumberShow
            ?<div style={{paddingTop:"0rem"}}>
                <div>{`/${calTotalPage()}`}</div>
            </div>
            :null
            }

            {(calTotalPage()>1)&&(pageNumber<calTotalPage())//&&pageNumberShow
            ?<div>
                <MdChevronRight
                    className="sm-icon"
                    onClick={e=>{
                      const temp=parseInt(pageNumber)+1
                      setPageNumber(temp)
                      //setReloadData(true)
                    }}
                />
            </div>
            :null
            }
               
            {(calTotalPage()>1)&&(pageNumber<calTotalPage())//&&pageNumberShow
             ?<div>
                 <MdLastPage
                    className="sm-icon"
                    onClick={e=>{
                        const temp=parseInt(calTotalPage())
                        setPageNumber(temp)
                        //setReloadData(true)
                    }}
                 />
              </div>   
             :null   
            }

            {closeShow
            ?
            <div>
                <MdClose className="sm-icon"
                    onClick={e=>{
                        if(closeFunc){
                            closeFunc(null)
                        }
                        //if(cancelSelectProduct){cancelSelectProduct()}
                    }}
                />
            </div>
            :null
            }
            {mergeProduct&&filterData.transactionData&&
             <div className="iconbox2"
                 style={{height:"2.5rem",width:"3rem"}}
                 onClick={e=>{
                    mergeProduct(filterData,setFilterData)
                    //setSwapStyleRight(!swapStyleRight)
                 }}
            >
                <Ticon 
                    iconName="MdMergeType" 
                    className="md-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }


        </div>
        
    </div>  
    )
}

export default renderBadgeForBill

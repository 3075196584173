const LangTemplate=[

    {
    formName:'ModalForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[
            {key:'groupAddForm',lb: 'เพิ่มกลุ่ม'},
            {key:'groupEditForm',lb: 'แก้ไขกลุ่ม'},
            {key:'partnerAddForm',lb: 'เพิ่มคู่ค้า'},
            {key:'partnerEditForm',lb: 'แก้ไขคู่ค้า'},
            {key:'productAddForm',lb: 'เพิ่มสินค้า'},
            {key:'productEditForm',lb: 'แก้ไขสินค้า'},
            {key:'shopAddForm',lb: 'เพิ่มร้าน'},
            {key:'shopEditForm',lb: 'แก้ไขร้าน'},
            {key:'userAddForm',lb: 'เพิ่มผู้ใช้'},
            {key:'userEditForm',lb: 'แก้ไขผู้ใช้'},
            {key:'shopPayAddForm',lb: 'เพิ่มบิลรายเดือน'},
            {key:'shopPayEditForm',lb: 'แก้ไขบิลรายเดือน'},

            {key:'taskAddForm',lb: 'เพิ่มนัดหมาย'},
            {key:'taskRepeatedAddForm',lb: 'เพิ่มนัดหมายซ้ำๆ'},
            {key:'taskEditForm',lb: 'แก้ไขนัดหมาย'},

            {key:'delete',lb:'ลบ'},
            {key:'import',lb: 'นำเข้า'},
            {key:'insertDown',lb: 'แทรกล่าง'},
            {key:'insertUp',lb: 'แทรกบน'},
            {key:'moveDown',lb: 'เลื่อนลง'},
            {key:'moveUp',lb: 'เลื่อนขึ้น'},
            {key:'selectAll',lb: 'เลือกทั้งหมด'},
            {key:'selectImage',lb: 'เลือกภาพ'},
            {key:'unSelectAll',lb: 'ยกเลิกเลือก'},
        ]
    },
    
    
    {formName:'ProductDetailTableTemplate',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[
            {key:'barcode',lb: 'บาร์โค้ด'},
            {key:'groupId',lb: 'ไอดีกลุ่ม'},
            {key:'groupName',lb: 'ชื่อกลุ่ม'},
            {key:'id',lb: 'ไอดี'},
            {key:'isRawMat',lb: 'เป็นวัตถุดิบ'},
            {key:'price',lb: 'ราคา'},
            {key:'productName',lb: 'ชื่อสินค้า'},
            {key:'quantity',lb: 'จำนวน'},
            {key:'remark',lb: 'หมายเหตุ'},
            {key:'result',lb: 'รวม'},
            {key:'selectedLine',lb: '#'},
            {key:'unit',lb: 'หน่วย'},
        ]
    },
    
    {
    formName:'ProductDetailTableTemplate',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[
            {key:'barcode',lb: 'บาร์โค้ด'},
            {key:'groupId',lb: 'ไอดีกลุ่ม'},
            {key:'groupName',lb: 'ชื่อกลุ่ม'},
            {key:'id',lb: 'ไอดี'},
            {key:'isRawMat',lb: 'เป็นวัตถุดิบ'},
            {key:'jobStatus',lb: 'สถานะงาน'},
            {key:'name',lb: 'ชื่อ'},
            {key:'partnerId',lb: 'ไอดีคู่ค้า'},
            {key:'price',lb: 'ราคา'},
            {key:'productName',lb: 'ชื่อสินค้า'},
            {key:'quantity',lb: 'จำนวน'},
            {key:'remark',lb: 'หมายเหตุ'},
            {key:'result',lb: 'รวม'},
            {key:'selectedLine',lb: '#'},
            {key:'unit',lb: 'หน่วย'},
        ]
    },
    {
    formName:'addGroupForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[    
        
            {key:'formHead',lb: 'เพิ่มกลุ่มสินค้า'},
            {key:'gcode',lb: 'โค้ดกลุ่ม'},
            {key:'groupName',lb: 'ชื่อกลุ่ม'},
            {key:'id',lb: 'ไอดี'},
            {key:'level',lb: 'ระดับกลุ่ม'},
            {key:'treeTitle',lb: 'เลือกกลุ่มสินค้าแม่'},
        ]
    },
    {
    formName:'addUser',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[   
        {key:'password',lb: 'รหัส'},
        {key:'formHead',lb: 'เพิ่มรายชื่อผู้ใช้.....'},
        {key:'id',lb: 'ไอดี'},
        {key:'name',lb: 'ชื่อ'},
        {key:'surname',lb: 'นามสกุล'},
        {key:'userLevel',lb: 'กลุ่มผู้มีสิทธิ์'},
        {key:'username',lb: 'ยูสเซอร์เนม'},
        {key:'active',lb:'แอคทีฟ'},
        {key:'branchId',lb:'ไอดีสาขา'},
        {key:'branchName',lb:'ชื่อสาขา'},
            
        ]
    },   
    {
    formName:'basicDataTableTemplate',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'id',lb: 'ไอดี'},
            {key:'routeAddress',lb: 'url'},
            {key:'routeName',lb: 'ชื่อสิทธิ์'},
            {key:'userLevel',lb: 'กลุ่มผู้มีสิทธิ์'},
        ]
    },
    
    {
    formName:'basicValue',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[         
            {key:'branch',lb: 'สาขา'},
            {key:'endBill',lb: 'ท้ายบิล'},
            {key:'formHead',lb: 'ตั้งค่าพื้นฐาน'},
            {key:'jobStatus',lb: 'สถานะงาน'},
            {key:'language',lb: 'Eng/ไทย'},
            {key:'partnerType',lb: 'ประเภทคู่ค้า'},
            {key:'customerType',lb: 'ประเภทลูกค้า'},
            {key:'supplierType',lb: 'ประเภทผู้ขาย'},

            {key:'paymentType',lb: 'ประเภทการชำระเงิน'},
            {key:'taskType',lb: 'ประเภทงาน'},


            {key:'queueStatus',lb: 'สถานะคิว'},
            {key:'reduction',lb: 'ส่วนลด'},
            {key:'table',lb: 'โต๊ะ'},
            {key:'tableStatus',lb: 'สถานะโต๊ะ'},
            {key:'tax',lb: 'ภาษี'},
            {key:'title',lb: 'คำนำหน้า'},
            {key:'transaction',lb: 'บิล'},
            {key:'transactionStatus',lb: 'สถานะบิล'},
            {key:'unit',lb: 'หน่วย'},
            {key:'userLevel',lb: 'กลุ่มผู้มีสิทธิ์'},
            {key:'effectType',lb:'ประเภทกระทบบัญชี'}

        ]
    },
    {
    formName:'billBarcodeForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[  
            {key:'barcode',lb: 'บาร์โค้ด'},
            {key:'cash',lb: 'เงินสด'},
            {key:'change',lb: 'เงินทอน'},
            {key:'grandTotal',lb: 'สุทธิ'},
            {key:'partnerId',lb: 'ไอดีคู่ค้า'},
            {key:'paymentType',lb: 'ประเภทการชำระเงิน'},
            {key:'phone',lb: 'โทร'},
            {key:'productId',lb: 'ไอดีสินค้า'},
            {key:'queue',lb: 'คิว'},
            {key:'table',lb: 'โต๊ะ'},
        ]
    },
    {
    formName:'billForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[  
            {key:'address',lb: 'ที่อยู่'},
            {key:'date',lb: 'วันที่'},
            {key:'grandTotal',lb: 'สุทธิ'},
            {key:'id',lb: 'ไอดี'},
            {key:'partnerId',lb: 'ไอดีคู่ค้า'},
            {key:'phone',lb: 'โทร'},
            {key:'queue',lb: 'คิว'},
            {key:'table',lb: 'โต๊ะ'},
            {key:'time',lb: 'เวลา'},
            {key:'total',lb: 'รวม'},
            {key:'totalReduction',lb: 'รวมส่วนลด'},
            {key:'totalTax',lb: 'รวมภาษี'},
            {key:'pic',lb:'pic'},
            {key:'taxId',lb:'เลขประจำตัวผู้เสียภาษี'}
        ]
    },
    {
        formName:'billBranchMenuFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหารายการสินค้าตามบิลตามสาขา'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
        },

    {
    formName:'billMenuFilterForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'allTrue',lb: 'ทุกข้อจริง'},
            {key:'formHead',lb: 'ค้นหารายการสินค้าตามบิล'},
            {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
            {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
            {key:'someTrue',lb: 'บางข้อจริง'},
            {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
            {key:'subject',lb: 'หัวข้อ'},
        ]
    },
    
    {
    formName:'branchSettingPage',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'branch',lb: 'สาขา'},
            {key:'formHead',lb: 'ตั้งค่าสาขา'},
            {key:'languageSetting',lb: 'ภาษา'},
            {key:'pageHeight',lb: 'ความสูงหน้า'},
            {key:'printPageSetting',lb: 'หน้ากระดาษ'},
            {key:'printPageSetting2',lb: 'หน้ากระดาษ2'},

            {key:'table',lb: 'โต๊ะ'},
            {key:'tableActive',lb: 'ใช้งานโต๊ะ'},
            {key:'tableName',lb: 'ชื่อโต๊ะ'},
            {key:'widthLeft',lb: 'ความกว้างฝั่งซ้าย'},
            {key:'limitRow',lb:'จำนวนรายการต่อหน้า'},
            {key:'tileMotherWidth',lb:'ความกว้างกรอบรูปสินค้า'},
            {key:'tileMotherHeight',lb:'ความสูงกรอบรูปสินค้า'},
            {key:'imgWidth',lb:'ความกว้างรูปสินค้า'},
            {key:'imgHeight',lb:'ความสูงรูปสินค้า'},
            {key:'defaultCustomerId',lb:'ค่าเริ่มต้นไอดีลูกค้า'},
            {key:'defaultTransactionTypeId',lb:'ค่าเริ่มต้นไอดีประเภทบิล'},
            {key:'showTable',lb:'แสดงตาราง'},

            {key:'imgGroupHeight',lb:'ความสูงรูปกลุ่มสินค้า'},
            {key:'pic',lb:'pic'},
            {key:'storage',lb:'ที่จัดเก็บ'},

        ]
    },
    
    {
    formName:'changeUserPassword',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb:'แก้ไขรหัสผู้ใช้'},
            {key:'id',lb:'ไอดี'},
            {key:'newPassword1',lb:'รหัสใหม่'},
        ]
    },
    
    {
    formName:'changeUserPasswordForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb:'แก้ไขรหัสผู้ใช้'},
            {key:'newPassword1',lb:'รหัสใหม่'},
            {key:'newPassword2',lb:'ยืนยันรหัสใหม่'},
            {key:'password',lb:'รหัส'},
            {key:'username',lb:'ยูสเซอร์เนม'},
        ]
    },
    {
    formName:'customerConfirmForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ยืนยันข้อมูล'},
            {key:'partnerId',lb: 'ไอดี'},
            //{key:'partnerType',lb: 'ประเภท'},
            {key:'title',lb: 'คำนำหน้า'},
            {key:'name',lb: 'ชื่อ'},
            {key:'phone',lb: 'โทร'},
            {key:'contact',lb: 'ติดต่อ'},
            {key:'address',lb: 'ที่อยู่'},

            {key:'recipientTitle',lb: 'คำนำหน้าผู้รับ'},
            {key:'recipientName',lb: 'ชื่อผู้รับ'},
            {key:'recipientPhone',lb: 'เบอร์โทรผูรับ'},
            {key:'recipientContact',lb: 'ติดต่ออื่นๆผู้รับ'},
            {key:'recipientAddress',lb: 'ที่อยู่ผู้รับ'},
            
            {key:'delivery',lb: 'จัดส่ง'},

        ]
    },
    {
    formName:'customerPointForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
        {key:'formHead',lb: 'แต้มสะสมลูกค้า'},
        {key:'reductCustomerPoint',lb: 'หักแต้มสะสมลูกค้า'},
        {key:'remainingCustomerPoint',lb: 'แต้มสะสมของลูกค้า'},
        {key:'remainingPoint',lb: 'แต้มสะสมคงเหลือของลูกค้า'},
        ]
    },

    {
        formName:'printPageSettingForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
            {key:'formHead',lb: 'ตั้งค่าหน้ากระดาษ'},  
        ]
    },
    {
        formName:'dateSetting',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
            {key:'formHead',lb: 'ตั้งค่าวันทำงานและวันหยุด'},  
            {key:'activeDay',lb:'วันทำงาน'},
            {key:'holiday',lb:'วันหยุด'}

        ]
    },
    {
    formName:'editBillForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
        {key:'active',lb: 'แอคทีฟ'},
        {key:'address',lb: 'ที่อยู่'},
        {key:'date',lb: 'วันที่'},
        {key:'remindDate',lb: 'วันที่เตือน'},
        {key:'endBill',lb: 'ท้ายบิล'},
        {key:'grandTotal',lb: 'สุทธิ'},
        {key:'id',lb: 'ไอดี'},
        {key:'name',lb: 'ชื่อ'},
        {key:'partnerId',lb: 'ไอดีคู่ค้า'},
        {key:'partnerType',lb: 'ประเภทคู่ค้า'},
        {key:'paymentType',lb: 'ประเภทการชำระเงิน'},
        {key:'cash',lb: 'เงินสด'},
        {key:'change',lb: 'เงินทอน'},

        {key:'phone',lb: 'โทร'},
        {key:'contact',lb:'ติดต่ออื่นๆ'},
        {key:'queue',lb: 'คิว'},
        {key:'queueStatus',lb: 'สถานะคิว'},
        {key:'reductCustomerPoint',lb: 'หักแต้มสะสมลูกค้า'},
        {key:'reduction',lb: 'ส่วนลด'},
        {key:'remainingCustomerPoint',lb: 'แต้มสะสมของลูกค้า'},
        {key:'remark',lb: 'หมายเหตุ'},
        {key:'table',lb: 'โต๊ะ'},
        {key:'tableStatus',lb: 'สถานะโต๊ะ'},
        {key:'taxId',lb: 'เลขประจำตัวผู้เสียภาษี'},
        {key:'title',lb: 'คำนำหน้า'},
        {key:'total',lb: 'รวม'},
        {key:'totalPoint',lb: 'รวมแต้ม'},
        {key:'totalReduction',lb: 'รวมส่วนลด'},
        {key:'totalTax',lb: 'รวมภาษี'},
        {key:'transactionStatus',lb: 'สถานะบิล'},
        {key:'transactionType',lb: 'ประเภทบิล'},
        {key:'reference',lb: 'อ้างอิง'},
        {key:'pic',lb:'pic'},
        {key:'useInnerVat',lb:'ใช้แวทใน'},
        {key:'innerVatRate',lb:'อัตราแวทใน'},

        {key:'recipientTitle',lb:'คำนำหน้าผู้รับ'},
        {key:'recipientName',lb:'ชื่อผู้รับ'},
        {key:'recipientPhone',lb:'เบอร์โทรผู้รับ'},
        {key:'recipientContact',lb:'ติดต่ออื่นๆผู้รับ'},
        {key:'recipientAddress',lb:'ที่อยู่ผู้รับ'},
        {key:'delivery',lb:'จัดส่ง'},
        {key:'printPageSetting',lb:'ตั้งค่าหน้ากระดาษ'},
        {key:'printId',lb:'ไอดีหน้ากระดาษ'},
        {key:'printPageName',lb:'ชื่อหน้ากระดาษ'}

        ]
    },
    {
    formName:'editGroupForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'แก้ไขกลุ่มสินค้า'},
            {key:'gcode',lb: 'โค้ดกลุ่ม'},
            {key:'groupName',lb: 'ชื่อกลุ่ม'},
            {key:'id',lb: 'ไอดี'},
            {key:'newId',lb:'ไอดีใหม่'},
            
            {key:'level',lb: 'ระดับกลุ่ม'},
            {key:'treeTitle',lb: 'เลือกกลุ่มสินค้าแม่'},
        ]
    },
    {
    formName:'editUser',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'branch',lb: 'สาขา'},
            {key:'formHead',lb: 'แก้ไขรายชื่อผู้ใช้'},
            {key:'id',lb: 'ไอดี'},
            {key:'name',lb: 'ชื่อ'},
            {key:'surname',lb: 'นามสกุล'},
            {key:'userLevel',lb: 'กลุ่มผู้มีสิทธิ์'},
            {key:'username',lb: 'ยูสเซอร์เนม'},
            {key:'active',lb:'แอคทีฟ'},
            {key:'branchId',lb:'ไอดีสาขา'},
            {key:'branchName',lb:'ชื่อสาขา'},
        ]
    },
    
    {
    formName:'endBillForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'endBillActive',lb: 'ใช้งาน'},
            {key:'endBillEffect',lb: 'กระทบ'},
            {key:'endBillId',lb: 'ไอดี'},
            {key:'endBillInPercentage',lb: 'เป็น %'},
            {key:'endBillInPercentageOf',lb: 'เป็น % ของ'},
            {key:'endBillName',lb: 'ชื่อรายการ'},
            {key:'endBillRate',lb: 'อัตรา'},
            {key:'formHead',lb: 'ตารางท้ายบิล'},
            {key:'selection',lb: '#'},
        ]
    },
    {
        formName:'effectTypeForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'effectId',lb: 'ไอดีการกระทบ'},
                {key:'effectName',lb: 'ชื่อการกระทบ'},
                {key:'effectEndBillId',lb: 'กระทบจากค่า'},
                {key:'effectSign',lb: 'ผลกระทบ'},
                {key:'formHead',lb: 'การกระทบบัญชี'},
            ]
    },
    {
    formName:'groupPage',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
        {key:'formHead',lb:'กลุ่มสินค้า'},
        ]
    },
    {
    formName:'jobStatusForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'สถานะงาน'},
            {key:'jobStatus',lb: 'สถานะงาน'},
        ]
    },
    {
        formName:'stickerFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหาบาร์โค้ด'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
    },
    {
        formName:'groupFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหากลุ่มสินค้า'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
    },


    {
    formName:'partnerFilterForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'allTrue',lb: 'ทุกข้อจริง'},
            {key:'formHead',lb: 'ค้นหาคู่ค้า'},
            {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
            {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
            {key:'someTrue',lb: 'บางข้อจริง'},
            {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
            {key:'subject',lb: 'หัวข้อ'},
        ]
    },

    {
        formName:'supplierFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหาผู้จำหน่าย'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
        },
    {
        formName:'customerFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหาลูกค้า'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
    },
    
    {
    formName:'partnerTableTemplate',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'address',lb: 'ที่อยู่'},
            {key:'id',lb: 'ไอดี'},
            {key:'name',lb: 'ชื่อ'},
            {key:'partnerType',lb: 'ประเภทคู่ค้า'},
            {key:'phone',lb: 'โทร'},
            {key:'remark',lb: 'หมายเหตุ'},
            {key:'selectedLine',lb: '#'},
            {key:'title',lb: 'คำนำหน้า'},
            {key:'taxId',lb:'Tax ID'},
            {key:'isSupplier',lb:'ผู้ขาย'},
            {key:'isCustomer',lb:'ลูกค้า'},
        ]
    },
    {
    formName:'printForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'newId',lb:'ไอดีใหม่'},
            {key:'printPageName',lb:'ชื่อหน้ากระดาษ'},
            {key:'fontUnit',lb: 'หน่วยฟ้อนท์'},
            {key:'footer1',lb: 'ท้ายบิล1'},
            {key:'footer2',lb: 'ท้ายบิล2'},
            {key:'formHead',lb: 'ตั้งค่ากระดาษ'},
            {key:'header1',lb: 'หัวบิล1'},
            {key:'header2',lb: 'หัวบิล2'},
            {key:'linePerPage',lb: 'บรรทัดต่อหน้า'},
            {key:'limitCharPerLine',lb:'อัขระต่อบรรทัด'},
            {key:'printFieldName',lb: ''},
            {key:'printFontSize',lb: 'ขนาดฟอนท์'},
            {key:'printLb',lb: 'ป้าย'},
            {key:'printPosition',lb: 'ตำแหน่ง'},
            {key:'printValue',lb: 'ค่าคงที่'},
            {key:'printWidth',lb: 'ความกว้าง'},
            {key:'showTableHead',lb: 'แสดงหัวตาราง'},
            {key:'table1',lb: 'ตาราง1'},
            {key:'totalWidth',lb: 'ความกว้างรวม'},
            {key:'rowSpacing',lb: 'ระยะระหว่างบรรทัด'},

            {key:'usePageBreak',lb: 'แบ่งหน้า'},
            {key:'widthUnit',lb: 'หน่วยความกว้าง'},
            {key:'scale',lb: 'เสกล'},
            {key:'marginTop',lb: 'ระยะขอบบน'},
            {key:'marginBottom',lb: 'ระยะขอบล่าง'},
            {key:'marginLeft',lb: 'ระยะขอบซ้าย'},
            {key:'marginRight',lb: 'ระยะขอบขวา'},
        ]
    },
    

    {
        formName:'taskFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
               {key:'allTrue',lb: 'ทุกข้อจริง'},
               {key:'formHead',lb: 'ค้นหานัดหมาย'},
                {key:'groupHead',lb: 'กลุ่มงาน'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
    },    

    {
    formName:'productFilterForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
           {key:'allTrue',lb: 'ทุกข้อจริง'},
           {key:'formHead',lb: 'ค้นหาสินค้า'},
            {key:'groupHead',lb: 'กลุ่มสินค้า'},
            {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
            {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
            {key:'someTrue',lb: 'บางข้อจริง'},
            {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
            {key:'subject',lb: 'หัวข้อ'},
        ]
    },    
    {
        formName:'productFilterForm2',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
               {key:'allTrue',lb: 'ทุกข้อจริง'},
               {key:'formHead',lb: 'ค้นหาสินค้าทุกสาขา'},
                {key:'groupHead',lb: 'กลุ่มสินค้า'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
        },    
    {
    formName:'productTableTemplate',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'barcode',lb: 'บาร์โค้ด'},
            {key:'groupId',lb: 'ไอดีกลุ่ม'},
            {key:'groupName',lb: 'ชื่อกลุ่ม'},
            {key:'id',lb: 'ไอดี'},
            {key:'isRawMat',lb: 'เป็นวัตถุดิบ'},
            {key:'price',lb: 'ราคา'},
            {key:'productName',lb: 'ชื่อสินค้า'},
            {key:'remark',lb: 'หมายเหตุ'},
            {key:'selectedLine',lb: '#'},
            {key:'stock',lb: 'ยอดสต็อก'},
            {key:'unit',lb: 'หน่วย'},
        ]
    },
    
    {
    formName:'rawMatFilter',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'barcode',lb: 'บาร์โค้ด'},
            {key:'id',lb: 'ไอดี'},
            {key:'price',lb: 'ราคา'},
            {key:'rawMatGroupId',lb: 'ID กลุ่มวัตถุดิบ'},
            {key:'rawMatGroupName',lb: 'ชื่อกลุ่มวัตถุดิบ'},
            {key:'rawMatName',lb: 'ชื่อวัตถุดิบ'},
            {key:'remark',lb: 'หมายเหตุ'},
            {key:'unit',lb: 'หน่วย'},
        ]
    },
    
    
    
    {formName:'reductionForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ตารางส่วนลด'},
            {key:'reductionAmount',lb: 'รวม'},
            {key:'reductionInPercentage',lb: 'in %'},
            {key:'reductionName',lb: 'ชื่อส่วนลด'},
            {key:'reductionRate',lb: 'อัตราส่วนลด'},
            {key:'selection',lb: '#'},
        ]
    },
    {formName:'setting',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'addNewUser',lb: 'รายชื่อผู้ใช้'},
            {key:'changePassword',lb: 'เปลี่ยนรหัสร้าน'},
            {key:'formHead',lb: 'ตั้งค่าระบบ'},
            {key:'language',lb: 'Eng/ไทย'},
            {key:'pageHeight',lb: 'ความสูงหน้า'},
            {key:'printPage',lb: 'ตั้งค่าหน้ากระดาษ'},
            {key:'stickerPage',lb: 'ตั้งค่าบาร์โค้ด'},
            {key:'routeAuth',lb: 'กำหนดสิทธิ์ผู้ใช้'},
            {key:'transactionType',lb:'ตั้งค่าประเภทบิล'},
            {key:'widthLeft',lb: 'ความกว้างฝั่งซ้าย'},
            {key:'dateSetting',lb:'ตั้งค่าวันทำงานและวันหยุด'},
            {key:'shopPayment',lb:'ชำระค่ารายเดือน'},
            {key:'dataFromAdminTeam',lb: 'ข้อมูลจากทีมงานแอดมิน'},
            
        ]
    },
    {formName:'shopChangePassword',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            
            {key:'formHead',lb: 'เปลี่ยนรหัสร้าน'},
            {key:'newPassword1',lb: 'รหัสใหม่'},
            {key:'newPassword2',lb: 'ยืนยันรหัสใหม่'},
            {key:'password',lb: 'รหัส'},
            {key:'shopName',lb: 'ชื่อร้าน'},
        ]
    },
    {formName:'shopForgetPasswordForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
        {key:'confirmPassword',lb: 'ยืนยันรหัส'},
        {key:'formHead',lb: 'ลืมรหัสร้าน'},
        {key:'password',lb: 'รหัส'},
        {key:'recoveryPassword',lb: 'รหัสชั่วคราว'},
        {key:'shopForgetPasswordInfo',lb: 'รหัสชั่วคราวจะถูกส่งไปยังอีเมลของท่าน โปรดใช้มันสร้างรหัสร้านใหม่'},
        {key:'shopName',lb: 'ชื่อร้าน'},
        ]
    },
    
    
    
    {
    formName:'shopInfo',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ข้อมูลสาขา'},
            {key:'logoActive',lb: 'โลโก้'},
        ]
    },
    {
    formName:'shopLogInForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ล็อกอินร้านค้า'},
            {key:'password',lb: 'รหัส'},
            {key:'shopName',lb: 'ชื่อร้าน'},
        ]
    },
   
    {
    formName:'shopLogInPage',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'changeUserPassword',lb: 'เปลี่ยนรหัสผู้ใช้'},
            {key:'createNewShopAccount',lb: 'สร้างบัญชีร้านใหม่'},
            {key:'forgetShopPassword',lb: 'ลืมรหัสร้าน'},
            {key:'requestForRecoveryPassword',lb: 'ร้องขอรหัสชั่วคราวใหม่'},
            {key:'shopPayment',lb:'ชำระค่ารายเดือน'}
        ]
    },
    {
    formName:'shopSignUpForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            //{key:'confirmPassword',lb: 'ยืนยันรหัส'},
            {key:'formHead',lb: 'สร้างบัญชีผู้ใช้ใหม่'},
            {key:'ownerEmail',lb: 'อีเมลเจ้าของร้าน'},
            {key:'ownerName',lb: 'ชื่อเจ้าของร้าน'},
            //{key:'password',lb: 'รหัส'},
            {key:'shopName',lb: 'ชื่อร้าน'},
        ]
    },
    
    
    {
    formName:'tableSettingModal',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ตั้งค่าตาราง'},
            {key:'show',lb: 'แสดง'},
            {key:'subject',lb: 'หัวข้อ'},
        ]
    },
    {
    formName:'taxForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ตารางภาษี'},
            {key:'selection',lb: '#'},
            {key:'taxAmount',lb: 'รวม'},
            {key:'taxInPercentage',lb: 'in %'},
            {key:'taxName',lb: 'ชื่อภาษี'},
            {key:'taxRate',lb: 'อัตราภาษี'},
        ]
    },    
    {
    formName:'transactionConfirmForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'address',lb: 'ที่อยู่'},
            {key:'branch',lb: 'สาขา'},
            {key:'date',lb: 'วันที่'},
            {key:'formHead',lb: 'ยืนยันข้อมูลบิล'},
            {key:'id',lb: 'ไอดี'},
            {key:'includeTransactionHead',lb: 'รวมข้อมูลหัวบิลท้ายบิล'},
            {key:'partnerId',lb: 'ไอดีคู่ค้า'},
            {key:'phone',lb: 'โทร'},
            {key:'table',lb: 'โต๊ะ'},
        ]
    },
    
    
    {
    formName:'transactionFilterForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'allTrue',lb: 'ทุกข้อจริง'},
            {key:'formHead',lb: 'ค้นหาบิล'},
            {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
            {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
            {key:'someTrue',lb: 'บางข้อจริง'},
            {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
            {key:'subject',lb: 'หัวข้อ'},
        ]
    },
    {
        formName:'transactionBranchFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหาบิลตามสาขา'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
        },
    {
    formName:'transactionForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ตั้งค่าประเภทบิล'},
            {key:'id',lb: 'ไอดี'},
        ]
    },
    {
    formName:'transactionTypeForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ประเภทบิล'},
            {key:'id',lb:'ไอดี'},
            {key:'newId',lb:'ไอดีใหม่'},
            {key:'transactionType',lb: 'ประเภทบิล'},
            {key:'userLevel',lb:'ระดับผู้ใช้'},
            {key:'endBill',lb:'ท้ายบิล'},
            {key:'effect',lb:'กระทบบัญชี'},
            {key:'useInnerVat',lb:'ใช้แวทใน'},
            {key:'innerVatRate',lb:'อัตราแวท'},
            {key:'printPageSetting',lb:'ตั้งค่าหน้ากระดาษ'},

            {key:'printId',lb:'ไอดีหน้ากระดาษ'},
            {key:'printPageName',lb:'ชื่อหน้ากระดาษ'}
        ]
    },
    
    {
    formName:'transactionTableTemplate',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'active',lb: 'แอคทีฟ'},
            {key:'address',lb: 'ที่อยู่'},
            {key:'date',lb: 'วันที่'},
            {key:'effectCustomerPoint',lb: 'กระทบแต้มสะสม'},
            {key:'effectSpending',lb: 'กระทบยอดใช้จ่าย'},
            {key:'effectStock',lb: 'กระทบสต็อก'},
            {key:'grandTotal',lb: 'สุทธิ'},
            {key:'id',lb: 'ไอดี'},
            {key:'name',lb: 'ชื่อ'},
            {key:'partnerId',lb: 'ไอดีคู่ค้า'},
            {key:'partnerType',lb: 'ประเภทคู่ค้า'},
            {key:'paymentType',lb: 'ประเภทการชำระเงิน'},
            {key:'phone',lb: 'โทร'},
            {key:'reduction',lb: 'ส่วนลด'},
            {key:'remark',lb: 'หมายเหตุ'},
            {key:'remindDate',lb: 'วันที่เตือน'},
            {key:'selectedLine',lb: '#'},
            {key:'table',lb: 'โต๊ะ'},
            {key:'tableStatus',lb: 'สถานะโต๊ะ'},
            {key:'tax',lb: 'ภาษี'},
            {key:'title',lb: 'คำนำหน้า'},
            {key:'total',lb: 'รวม'},
            {key:'transactionStatus',lb: 'สถานะบิล'},
            {key:'transactionType',lb: 'ประเภทบิล'},
        ]
    },
    {
    formName:'user',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'รายชื่อผู้ใช้'},
            {key:'monthlyFee',lb:'ค่าบริการรายเดือนขั้นต่ำ(บาท)'},
            {key:'costPerUser',lb:'ค่าบริการที่เพิ่มต่อหนึ่งบัญชีผู้ใช้(บาท)'}
        ]
    },
    
    {
    formName:'userLogInForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'createNewUser',lb: 'สร้างบัญชีผู้ใช้ใหม่'},
            {key:'formHead',lb: 'ล็อกอินผู้ใช้'},
            {key:'password',lb: 'รหัส'},
            {key:'username',lb: 'ยูสเซอร์เนม'},
            {key:'tryAccount',lb:'คลิกที่นี่เพื่อทดลองใช้'}
        ]
    },
    {
    formName:'widthLeftForm',
    formHead:'',
    formLanguage:'Thai',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'ตั้งค่าความกว้าง'},
            {key:'widthLeft',lb: 'ความกว้างฝั่งซ้าย'},
        ]
    },

    {
        formName:'stickerSettingForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
            {lb:'ไอดี',key:'id' },
            {lb:'ไอดีใหม่',key:'newId'},
            {lb:'ชื่อรายการ',key:'recordName' },
            {lb:'วันที่',key:'date' },
            {lb:'คนบันทึก',key:'recordBy' },
            {lb:'แอคทีฟ',key:'active' },
            {lb:'สูง',key:'heightP' },
            {lb:'กว้าง',key:'widthP'  },

            {lb:'มาจินบน มม.',key:'marginTopP' },
            {lb:'มาจินล่าง มม.',key:'marginBottomP'},
            {lb:'มาจินซ้าย มม.',key:'marginLeftP' },
            {lb:'มาจินขวา มม.',key:'marginRightP' },

            {lb:'ขอบบน มม.',key:'paddingTopP' },
            {lb:'ขอบล่าง มม.',key:'paddingBottomP'},
            {lb:'ขอบซ้าย มม.',key:'paddingLeftP' },
            {lb:'ขอบขวา มม.',key:'paddingRightP' },

            {lb:'กรอบล่าง',key:'borderBottomP' },

            {lb:'ขอบขวา มม.',key:'' },
            {lb:'เสกล',key:'scale'},
            {lb:'ช่องว่างบน',key:'marginTopBarcode'},
            {lb:'ช่องว่างล่าง',key:'marginBottomBarcode'},

            {lb:'แสดงบาร์โค้ด',key:'showBarCode' },
            {lb:'ความสูงบาร์โค้ด',key:'heightB'  },
            {lb:'ความกว้างบาร์โค้ด',key:'widthB' },
            {lb:'จำนวนสติกเกอร์ต่อบรรทัด',key:'xPerLine'},
            
            {lb:'แสดงเส้นขอบ',key:'showBorder' },
            {lb:'ระยะระหว่างแถว มม.',key:'gridRowGap' },
            {lb:'ระยะระหว่างคอลัมน์ มม.',key:'gridColumnGap' },
        
            {lb:'แสดงโค้ด', key:'showCode' },
            {lb:'ขนาดฟอนท์โค้ด', key:'fontSizeCode' },
        
            {lb:'แสดงชื่อสินค้า',key:'showName' },
            {lb:'ขนาดฟอนท์สินค้า', key:'fontSizeName' },
        
            {lb:'แสดงราคา',key:'showPrice' },
            {lb:'ขนาดฟอนท์ราคา', key:'fontSizePrice' },
        
            {lb:'แสดงหน่วย',key:'showUnit' },
            {lb:'ขนาดฟอนท์หน่วย', key:'fontSizeUnit' },    


            {lb:'โค้ด',key:'code'},
            {lb:'ชื่อสินค้า',key:'name'},
            {lb:'ราคา',key:'price'},
            {lb:'หน่วย',key:'unit'},
            {lb:'จำนวน',key:'qty'},

            {key:'setting',lb:'ตั้งค่าทั่วไป'},
            {key:'pageSetUp',lb:'ตั้งค่าหน้ากระดาษ'},
            {key:'barcodeSetUp',lb:'ตั้งค่าบาร์โค้ด'},
            {key:'productData',lb:'รายการสินค้า'}
            ]
    },

    {
    formName:'ModalForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'groupAddForm',lb: 'Product Group Add Form'},
            {key:'groupEditForm',lb: 'Product Group Edit Form'},
            {key:'partnerAddForm',lb: 'Partner Add Form'},
            {key:'partnerEditForm',lb: 'Partner Edit Form'},
            {key:'productAddForm',lb: 'Product Add Form'},
            {key:'productEditForm',lb: 'Product Edit Form'},
            {key:'shopAddForm',lb: 'Shop Add Form'},
            {key:'shopEditForm',lb: 'Shop Edit Form'},
            {key:'userAddForm',lb: 'User Add Form'},
            {key:'userEditForm',lb: 'User Edit Form'},
            {key:'shopPayAddForm',lb: 'ShopPayment Add Form'},
            {key:'shopPayEditForm',lb: 'ShopPayment Edit Form'},

            {key:'taskAddForm',lb:'Add Appointment'},
            {key:'taskRepeatedAddForm',lb:'Add Repeated Appointment'},
            {key:'taskEditForm',lb:'Edit Appointment'},

            {key:'delete',lb: 'Delete'},
            {key:'import',lb: 'Import'},
            {key:'insertDown',lb: 'Insert Down'},
            {key:'insertUp',lb: 'Insert Up'},
            {key:'moveDown',lb: 'Move Down'},
            {key:'moveUp',lb: 'Move Up'},
            {key:'selectAll',lb: 'Select All'},
            {key:'selectImage',lb: 'Select Image'},
            {key:'unSelectAll',lb: 'Unselect All'},
        ]
    },
    {
    formName:'ProductDetailTableTemplate',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'barcode',lb: 'Barcode'},
            {key:'groupId',lb: 'Group ID'},
            {key:'groupName',lb: 'Group Name'},
            {key:'id',lb: 'ID'},
            {key:'isRawMat',lb: 'IsRawMat'},
            {key:'price',lb: 'Price'},
            {key:'productName',lb: 'Product Name'},
            {key:'quantity',lb: 'Quantity'},
            {key:'remark',lb: 'Remark'},
            {key:'result',lb: 'Result'},
            {key:'selectedLine',lb: '#'},
            {key:'unit',lb: 'Unit'},
        ]
    },
    
    
    {
    formName:'ProductDetailTableTemplateForForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'barcode',lb: 'Barcode'},
            {key:'groupId',lb: 'Group ID'},
            {key:'groupName',lb: 'Group Name'},
            {key:'id',lb: 'ID'},
            {key:'isRawMat',lb: 'IsRawMat'},
            {key:'jobStatus',lb: 'Job Status'},
            {key:'name',lb: 'Name'},
            {key:'partnerId',lb: 'Partner ID'},
            {key:'price',lb: 'Price'},
            {key:'productName',lb: 'Product Name'},
            {key:'quantity',lb: 'Quantity'},
            {key:'remark',lb: 'Remark'},
            {key:'result',lb: 'Result'},
            {key:'selectedLine',lb: '#'},
            {key:'unit',lb: 'Unit'},
        ]
    },
    {
    formName:'addGroupForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Add Group Form'},
            {key:'gcode',lb: 'Group Code'},
            {key:'groupName',lb: 'Group Name'},
            {key:'id',lb: 'ID'},
            {key:'level',lb: 'Group Level'},
            {key:'treeTitle',lb: 'Select Parent Group'},
        ]
    },
    {
    formName:'addUser',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Add User'},
            {key:'id',lb: 'ID'},
            {key:'name',lb: 'Name'},
            {key:'password',lb: 'Password'},
            {key:'surname',lb: 'Surname'},
            {key:'userLevel',lb: 'User Level'},
            {key:'username',lb: 'Username'},
            {key:'active',lb:'Active'},
            {key:'branchId',lb: 'Branch ID'},
            {key:'branchName',lb: 'Branch Name'},
        ]
    },
    {
    formName:'basicDataTableTemplate',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'id',lb: 'ID'},
            {key:'routeAddress',lb: 'url'},
            {key:'routeName',lb: 'Route Name'},
            {key:'userLevel',lb: 'User Level'},
        ]
    },
    {
    formName:'basicValue',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'branch',lb: 'Branch'},
            {key:'endBill',lb: 'EndBill'},
            {key:'formHead',lb: 'Basic Setting'},
            {key:'jobStatus',lb: 'Job Status'},
            {key:'language',lb: 'Language'},
            {key:'partnerType',lb: 'Partner Type'},

            {key:'customerType',lb: 'Customer Type'},
            {key:'supplierType',lb: 'Supplier Type'},

            {key:'paymentType',lb: 'Payment Type'},
            {key:'taskType',lb: 'Task Type'},


            {key:'queueStatus',lb: 'Queue Status'},
            {key:'reduction',lb: 'Reduction'},
            {key:'table',lb: 'Table'},
            {key:'tableStatus',lb: 'Table Status'},
            {key:'tax',lb: 'Tax'},
            {key:'title',lb: 'Title'},
            {key:'transaction',lb: 'Transaction'},
            {key:'transactionStatus',lb: 'Bill Status'},
            {key:'unit',lb: 'Unit'},
            {key:'userLevel',lb: 'User Level'},
            {key:'effectType',lb:'Effect Type'}
        ]
    },
    {
    formName:'billBarcodeForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'barcode',lb: 'Barcode'},
            {key:'cash',lb: 'Cash'},
            {key:'change',lb: 'Change'},
            {key:'grandTotal',lb: 'Grand Total'},
            {key:'partnerId',lb: 'Partner ID'},
            {key:'paymentType',lb: 'Payment Type'},
            {key:'phone',lb: 'Phone'},
            {key:'productId',lb: 'Product ID'},
            {key:'queue',lb: 'Queue'},
            {key:'table',lb: 'Table'},
        ]
    },
    
    
    {
    formName:'billForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'address',lb: 'Address'},
            {key:'date',lb: 'Date'},
            {key:'grandTotal',lb: 'Grand Total'},
            {key:'id',lb: 'ID'},
            {key:'partnerId',lb: 'Partner ID'},
            {key:'phone',lb: 'Phone'},
            {key:'queue',lb: 'Queue'},
            {key:'table',lb: 'Table'},
            {key:'time',lb: 'Time'},
            {key:'total',lb: 'Total'},
            {key:'totalReduction',lb:'Total Reduction'},
            {key:'totalTax',lb: 'Total Tax'},
            {key:'pic',lb: 'pic'},
            {key:'taxId',lb:'tax ID'}
        ]
    },

    {
        formName:'billBranchMenuFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Product In All Branch Bill Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
        },
    {
    formName:'billMenuFilterForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'allTrue',lb: 'All True'},
            {key:'formHead',lb: 'Product In Bill Filter'},
            {key:'limitRow',lb: 'Limit Row'},
            {key:'recordPerPage',lb: 'Record Per Page'},
            {key:'someTrue',lb: 'Some True'},
            {key:'sortBySubject',lb: 'Sort By Subject'},
            {key:'subject',lb: 'Subject...'},
        ]
    },



    {
        formName:'ShopFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Shop Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },
    {
        formName:'userFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'User Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },
    {
        formName:'shopPaymentFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Shop Payment Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },

    //=========================
    //=========================
    //=========================
    {
        formName:'basicDataForAdminFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'BasicData For Admin Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },
    {
        formName:'branchForAdminFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Branch For Admin Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },
    {
        formName:'basicDataForAdminFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'BasicData For Admin Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },
    {
        formName:'branchForAdminFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Branch For Admin Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },
    //========================= 
    //=========================
    //=========================
    {
    formName:'branchSettingPage',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'branch',lb: 'Branch'},
            {key:'formHead',lb: 'Branch Setting'},
            {key:'languageSetting',lb: 'Language Setting'},
            {key:'pageHeight',lb: 'Page Height'},
            {key:'printPageSetting',lb: 'Print Page Setting'},
            {key:'printPageSetting2',lb: 'Print Page Setting2'},

            {key:'table',lb: 'Table'},
            {key:'tableActive',lb: 'Table Active'},
            {key:'tableName',lb: 'Table Name'},
            {key:'widthLeft',lb: 'Width Left'},
            {key:'limitRow',lb: 'Limit Row'},
            {key:'tileMotherWidth',lb:'Frame Image Product Width'},
            {key:'tileMotherHeight',lb:'Frame Image Product Height'},
            {key:'imgWidth',lb:'Image Product Width'},
            {key:'imgHeight',lb:'Image Product Height'},
            {key:'defaultCustomerId',lb:'Default Customer ID'},
            {key:'defaultTransactionTypeId',lb:'Default Transaction Type ID'},
            {key:'showTable',lb:'ShowTable'},


            {key:'imgGroupHeight',lb:'Image Group Height'},
            {key:'pic',lb:'pic'},
            {key:'storage',lb:'Storage'}
        ]
    },
    
    {
    formName:'changeUserPassword',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Change User Password'},
            {key:'id',lb: 'ID'},
            {key:'newPassword1',lb: 'New Password'},
        ]
    },
    
    
    {
    formName:'changeUserPasswordForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Change User Password'},
            {key:'newPassword1',lb: 'New Password'},
            {key:'newPassword2',lb: 'Confirm New Password'},
            {key:'password',lb: 'Password'},
            {key:'username',lb: 'Username'},
        ]
    },
    {
    formName:'customerConfirmForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Confirm Info'},
            {key:'partnerId',lb: 'Partner ID'},

            {key:'title',lb: 'Title'},
            {key:'name',lb: 'Name'},
            {key:'phone',lb: 'Phone'},
            {key:'contact',lb: ''},
            {key:'address',lb: 'Address'},

            {key:'recipientTitle',lb: 'Recipient Title'},
            {key:'recipientName',lb: 'Recipient Name'},
            {key:'recipientPhone',lb: 'Recipient Phone'},
            {key:'recipientContact',lb: 'Recipient Contact'},
            {key:'recipientAddress',lb: 'Recipient Address'},

            {key:'delivery',lb: 'Delivery'},

            
            //{key:'partnerType',lb: 'Partner Type'},
        ]
    },
    {
    formName:'customerPointForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'CustomerPoint'},
            {key:'reductCustomerPoint',lb: 'Reduct Customer Point'},
            {key:'remainingCustomerPoint',lb: 'Remaining Customer Point'},
            {key:'remainingPoint',lb: 'Remaining Point'},
        ]
    },

    {
        formName:'printPageSettingForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
            {key:'formHead',lb: 'Print Page Setting'},  
        ]
    },
    {
        formName:'dateSetting',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
            {key:'formHead',lb: 'Date Setting'},  
            {key:'activeDay',lb:'Working Day'},
            {key:'holiday',lb:'Holidays'}

        ]
    },
    {
    formName:'editBillForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'active',lb: 'Active'},
            {key:'address',lb: 'Address'},
            {key:'date',lb: 'Date'},
            {key:'remindDate',lb:'Reminding Date'},
            {key:'endBill',lb: 'EndBill'},
            {key:'grandTotal',lb: 'Grand Total'},
            {key:'id',lb: 'ID'},
            {key:'name',lb: 'Name'},
            {key:'partnerId',lb: 'Partner ID'},
            {key:'partnerType',lb: 'Partner Type'},
            {key:'paymentType',lb: 'Payment Type'},
            {key:'cash',lb: 'Cash'},
            {key:'change',lb: 'Change'},

            {key:'phone',lb: 'Phone'},
            {key:'contact',lb: 'Social Contact'},

            {key:'queue',lb: 'Queue'},
            {key:'queueStatus',lb: 'Queue Status'},
            {key:'reductCustomerPoint',lb: 'Reduct Customer Point'},
            {key:'reduction',lb: 'Reduction'},
            {key:'remainingCustomerPoint',lb: 'Remaining Customer Point'},
            {key:'remark',lb: 'Remark'},
            {key:'table',lb: 'Table'},
            {key:'tableStatus',lb: 'Table Status'},
            {key:'taxId',lb: 'Tax ID'},
            {key:'title',lb: 'Title'},
            {key:'total',lb: 'Total'},
            {key:'totalPoint',lb: 'Total Point'},
            {key:'totalReduction',lb: 'Total Reduction'},
            {key:'totalTax',lb: 'Total Tax'},
            {key:'transactionStatus',lb: 'Bill Status'},
            {key:'transactionType',lb: 'Bill Type'},
            {key:'reference',lb:'Reference'},
            {key:'pic',lb:'pic'},
            {key:'useInnerVat',lb:'Use Inner Vat'},
            {key:'innerVatRate',lb:'Inner Vat Rate'},

            {key:'recipientTitle',lb:'Recipient Title'},
            {key:'recipientName',lb:'Recipient Name'},
            {key:'recipientPhone',lb:'Recipient Phone'},
            {key:'recipientContact',lb:'Recipient Contact'},
            {key:'recipientAddress',lb:'Recipient Address'},
        
            {key:'delivery',lb:'Delivery'},
            {key:'printPageSetting',lb:'Print Page Setting'},
            {key:'printId',lb:'Print Page ID'},
            {key:'printPageName',lb:'Print Page Name'}
        ]
    },
    {
    formName:'editGroupForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Edit Group Form'},
            {key:'gcode',lb: 'Group Code'},
            {key:'groupName',lb: 'Group Name'},
            {key:'id',lb: 'ID'},
            {key:'newId',lb:'New ID'},

            {key:'level',lb: 'Group Level'},
            {key:'treeTitle',lb: 'Select Parent Group'},
        ]
    },
    
    
    
    {
    formName:'editUser',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Edit User'},
            {key:'id',lb: 'ID'},
            {key:'name',lb: 'Name'},
            {key:'surname',lb: 'Surname'},
            {key:'userLevel',lb: 'User Level'},
            {key:'username',lb: 'Username'},
            {key:'active',lb:'active'},
            {key:'branchId',lb:'Branch ID'},
            {key:'branchName',lb:'Branch Name'},
        ]
    },
    {
    formName:'endBillForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'endBillActive',lb: 'Active'},
            {key:'endBillEffect',lb: 'Effect'},
            {key:'endBillId',lb: 'ID'},
            {key:'endBillInPercentage',lb: 'in %'},
            {key:'endBillInPercentageOf',lb: 'in % of'},
            {key:'endBillName',lb: 'Name'},
            {key:'endBillRate',lb: 'Rate'},
            {key:'formHead',lb: 'End Bill Table'},
            {key:'selection',lb: '#'},
        ]
    },

    {
        formName:'effectTypeForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'effectId',lb: 'Effect Id'},
                {key:'effectName',lb: 'Effect Name'},
                {key:'effectEndBillId',lb: 'Effect From Value'},
                {key:'effectSign',lb:'Effect'},
                {key:'formHead',lb: 'Effecting'},
            ]
    },
    {
    formName:'groupPage',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
        {key:'formHead',lb:'Group of Product'},
        ]
    },
    {
    formName:'jobStatusForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Job Status Form'},
            {key:'jobStatus',lb: 'Job Status'},
        ]
    },
    {
        formName:'stickerFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Barcode Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },
    {
        formName:'groupFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Group Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },
    {
    formName:'partnerFilterForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'allTrue',lb: 'All True'},
            {key:'formHead',lb: 'Partner Filter'},
            {key:'limitRow',lb: 'Limit Row'},
            {key:'recordPerPage',lb: 'Record Per Page'},
            {key:'someTrue',lb: 'Some True'},
            {key:'sortBySubject',lb: 'Sort By Subject'},
            {key:'subject',lb: 'Subject...'},
        ]
    },
    {
        formName:'supplierFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
            {key:'allTrue',lb: 'All True'},
            {key:'formHead',lb: 'Supplier Filter'},
            {key:'limitRow',lb: 'Limit Row'},
            {key:'recordPerPage',lb: 'Record Per Page'},
            {key:'someTrue',lb: 'Some True'},
            {key:'sortBySubject',lb: 'Sort By Subject'},
            {key:'subject',lb: 'Subject...'},
            ]
        },
    {
        formName:'customerFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Customer Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
        },
       

    {
    formName:'partnerTableTemplate',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'address',lb: 'Address'},
            {key:'id',lb: 'ID'},
            {key:'name',lb: 'Name'},
            {key:'partnerType',lb: 'Type'},
            {key:'phone',lb: 'Phone'},
            {key:'remark',lb: 'Remark'},
            {key:'selectedLine',lb: '#'},
            {key:'title',lb: 'Title'},
            {key:'taxId',lb: 'Tax ID'},
            {key:'isCustomer',lb: 'Supplier'},
            {key:'isSupplier',lb: 'Customer'},

        ]
    },
    {formName:'printForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'newId',lb:'New ID'},
            {key:'printPageName',lb:'Form Name'},
            {key:'fontUnit',lb: 'Font Unit'},
            {key:'footer1',lb: 'Footer 1'},
            {key:'footer2',lb: 'Footer 2'},
            {key:'formHead',lb: 'Paper Setup'},
            {key:'header1',lb: 'Header 1'},
            {key:'header2',lb: 'Header 2'},
            {key:'linePerPage',lb: 'Line Per Page'},
            {key:'limitCharPerLine',lb:'Char Per Line'},
            {key:'printFieldName',lb: ''},
            {key:'printFontSize',lb: 'Font Size'},
            {key:'printLb',lb: 'Label'},
            {key:'printPosition',lb: 'Position'},
            {key:'printValue',lb: 'Value'},
            {key:'printWidth',lb: 'Width'},
            {key:'showTableHead',lb: 'Show Table Head'},
            {key:'table1',lb: 'Table1'},
            {key:'totalWidth',lb: 'Total Width'},
            {key:'rowSpacing',lb:'Row Spacing'},
            {key:'usePageBreak',lb: 'Use Page Break'},
            {key:'widthUnit',lb: 'Width Unit'},

            {key:'scale',lb: 'Scale'},
            {key:'marginTop',lb: 'Margin Top'},
            {key:'marginBottom',lb: 'Margin Bottom'},
            {key:'marginLeft',lb: 'Margin Left'},
            {key:'marginRight',lb: 'Margin Right'},
        ]
    },
 
    {
        formName:'taskFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Appointment Filter'},
                {key:'groupHead',lb: 'Group Of Product'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },

    {
    formName:'productFilterForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'allTrue',lb: 'All True'},
            {key:'formHead',lb: 'Product Filter'},
            {key:'groupHead',lb: 'Group Of Product'},
            {key:'limitRow',lb: 'Limit Row'},
            {key:'recordPerPage',lb: 'Record Per Page'},
            {key:'someTrue',lb: 'Some True'},
            {key:'sortBySubject',lb: 'Sort By Subject'},
            {key:'subject',lb: 'Subject...'},
        ]
    },
    {
        formName:'productFilterForm2',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'Product Filter In All Branch'},
                {key:'groupHead',lb: 'Group Of Product'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
        },
    {
    formName:'productTableTemplate',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'barcode',lb: 'Barcode'},
            {key:'groupId',lb: 'Group ID'},
            {key:'groupName',lb: 'Group Name'},
            {key:'id',lb: 'ID'},
            {key:'isRawMat',lb: 'IsRawMat'},
            {key:'price',lb: 'Price'},
            {key:'productName',lb: 'Product Name'},
            {key:'remark',lb: 'Remark'},
            {key:'selectedLine',lb: '#'},
            {key:'stock',lb: 'Stock'},
            {key:'unit',lb: 'Unit'},
        ]
    },
    {
    formName:'rawMatFilter',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'barcode',lb: 'Barcode'},
            {key:'id',lb: 'ID'},
            {key:'price',lb: 'Price'},
            {key:'rawMatGroupId',lb: 'Group ID'},
            {key:'rawMatGroupName',lb: 'Group Name'},
            {key:'rawMatName',lb: 'Raw Mat Name'},
            {key:'remark',lb: 'Remark'},
            {key:'unit',lb: 'Unit'},
        ]
    },
    
    
    {
    formName:'reductionForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Reduction Table'},
            {key:'reductionAmount',lb: 'Total'},
            {key:'reductionInPercentage',lb: 'in %'},
            {key:'reductionName',lb: 'Reduction Name'},
            {key:'reductionRate',lb: 'Reduction Rate'},
            {key:'selection',lb: '#'},
        ]
    },
    {
    formName:'setting',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'addNewUser',lb: 'User List'},
            {key:'changePassword',lb: 'Change Shop Password'},
            {key:'formHead',lb: 'System Setting'},
            {key:'language',lb: 'Language'},
            {key:'pageHeight',lb: 'Page Height'},
            {key:'printPage',lb: 'Print Page'},
            {key:'stickerPage',lb:'Barcode Page'},
            {key:'routeAuth',lb: 'Authorization'},
            {key:'transactionType',lb:'Transaction Type'},
            {key:'widthLeft',lb: 'Width Left'},
            {key:'dateSetting',lb:'Date and Holiday Setting'},
            {key:'shopPayment',lb:'Monthly Payment'},
            {key:'dataFromAdminTeam',lb: 'Data From Admin Team'}


        ]
    },
    {
    formName:'shopChangePassword',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Shop Change Password'},
            {key:'newPassword1',lb: 'New Password'},
            {key:'newPassword2',lb: 'Confirm New Password'},
            {key:'password',lb: 'Password'},
            {key:'shopName',lb: 'Shop Name'},
        ]
    },
    {
    formName:'shopForgetPasswordForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'confirmPassword',lb: 'Confirm Password'},
            {key:'formHead',lb: 'Forget Shop Password Form'},
            {key:'password',lb: 'Password'},
            {key:'recoveryPassword',lb: 'Recovery Password'},
            {key:'shopForgetPasswordInfo',lb: 'Recovery Password will be sent to your Email, use it to create new shop password'},
            {key:'shopName',lb: 'Shop Name'},
        ]
    },
    {
    formName:'shopInfo',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Shop Info'},
            {key:'logoActive',lb: 'Logo'},
        ]
    },
    {
    formName:'shopLogInForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Shop Log In'},
            {key:'password',lb: 'Password'},
            {key:'shopName',lb: 'Shop Name'},
        ]
    },
    
    {
    formName:'shopLogInPage',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'changeUserPassword',lb: 'Change User Password'},
            {key:'createNewShopAccount',lb: 'Create a New Shop Account'},
            {key:'forgetShopPassword',lb: 'Forget Shop Password'},
            {key:'requestForRecoveryPassword',lb: 'Request For Recovery Password'},
            {key:'shopPayment',lb:'Pay Monthly Fee'}
        ]
    },
    {
    formName:'shopSignUpForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            //{key:'confirmPassword',lb: 'Confirm Password'},
            {key:'formHead',lb: 'Shop Sign Up Form'},
            {key:'ownerEmail',lb: 'Owner Email'},
            {key:'ownerName',lb: 'Owner Name'},
            //{key:'password',lb: 'Password'},
            {key:'shopName',lb: 'Shop Name'},
        ]
    },
    {
    formName:'tableSettingModal',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Table Setting..'},
            {key:'show',lb: 'Show...'},
            {key:'subject',lb: 'Subject...'},
        ]
    },
    {
    formName:'taxForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Tax Table'},
            {key:'selection',lb: '#'},
            {key:'taxAmount',lb: 'Tax Amount'},
            {key:'taxInPercentage',lb: 'in %'},
            {key:'taxName',lb: 'Tax Name'},
            {key:'taxRate',lb: 'Tax Rate'},
        ]
    },
    
    
    
    
    
    {
    formName:'transactionConfirmForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'address',lb: 'Address'},
            {key:'branch',lb: 'Branch'},
            {key:'date',lb: 'Date'},
            {key:'formHead',lb: 'Confirm Transaction Info'},
            {key:'id',lb: 'ID'},
            {key:'includeTransactionHead',lb: 'Include Transaction Head'},
            {key:'partnerId',lb: 'Partner ID'},
            {key:'phone',lb: 'Phone'},
            {key:'table',lb: 'Table'},
        ]
    },
  
    {
    formName:'transactionFilterForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'allTrue',lb: 'All True'},
            {key:'formHead',lb: 'Bill Filter'},
            {key:'limitRow',lb: 'Limit Row'},
            {key:'recordPerPage',lb: 'Record Per Page'},
            {key:'someTrue',lb: 'Some True'},
            {key:'sortBySubject',lb: 'Sort By Subject'},
            {key:'subject',lb: 'Subject...'},
        ]
    },
    {
        formName:'transactionBranchFilterForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'All True'},
                {key:'formHead',lb: 'All Branches Bill Filter'},
                {key:'limitRow',lb: 'Limit Row'},
                {key:'recordPerPage',lb: 'Record Per Page'},
                {key:'someTrue',lb: 'Some True'},
                {key:'sortBySubject',lb: 'Sort By Subject'},
                {key:'subject',lb: 'Subject...'},
            ]
    },

    {
        formName:'shopFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหาร้านค้า'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
    },

    {
        formName:'userFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหาผู้ใช้'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
    },
    {
        formName:'shopPaymentFilterForm',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'allTrue',lb: 'ทุกข้อจริง'},
                {key:'formHead',lb: 'ค้นหาจ่ายรายเดือนร้านค้า'},
                {key:'limitRow',lb: 'จำนวนรายการต่อหน้า'},
                {key:'recordPerPage',lb: 'จำนวนรายการต่อหน้า'},
                {key:'someTrue',lb: 'บางข้อจริง'},
                {key:'sortBySubject',lb: 'เรียงตามหัวข้อ'},
                {key:'subject',lb: 'หัวข้อ'},
            ]
    },


    {
    formName:'transactionForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'TransactionType Setting'},
            {key:'id',lb: 'ID'},
        ]
    },
    {
        formName:'transactionTypeForm',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
                {key:'formHead',lb: 'Transaction Type'},
                {key:'id',lb:'ID'},
                {key:'newId',lb:'New ID'},
                {key:'transactionType',lb: 'Transaction Type'},
                {key:'userLevel',lb:'User Level'},
                {key:'endBill',lb:'End Bill'},
                {key:'effect',lb:'Effect'},
                {key:'useInnerVat',lb:'Use Inner Vat'},
                {key:'innerVatRate',lb:'Inner Vat Rate'},
                {key:'printPageSetting',lb:'Print Page Setting'},
                {key:'printId',lb:'Print Page ID'},
                {key:'printPageForm',lb:'Print Page Name'}
            ]
    },

    {
    formName:'transactionTableTemplate',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'active',lb: 'Active'},
            {key:'address',lb: 'Address'},
            {key:'date',lb: 'Date'},
            {key:'effectCustomerPoint',lb: 'Effect Customer Point'},
            {key:'effectSpending',lb: 'Effect Spending'},
            {key:'effectStock',lb: 'Effect Stock'},
            {key:'grandTotal',lb: 'Grand Total'},
            {key:'id',lb: 'ID'},
            {key:'name',lb: 'Name'},
            {key:'partnerId',lb: 'Partner ID'},
            {key:'partnerType',lb: 'Partner Type'},
            {key:'paymentType',lb: 'Payment Type'},
            {key:'phone',lb: 'Phone'},
            {key:'reduction',lb: 'Reduction'},
            {key:'remark',lb: 'Remark'},
            {key:'remindDate',lb: 'Remind Date'},
            {key:'selectedLine',lb: '#'},
            {key:'table',lb: 'Table'},
            {key:'tableStatus',lb: 'Table Status'},
            {key:'tax',lb: 'Tax'},
            {key:'title',lb: 'Title'},
            {key:'total',lb: 'Total'},
            {key:'transactionStatus',lb: 'Bill Status'},
            {key:'transactionType',lb: 'Bill Type'},
        ]
    },
    {
    formName:'user',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'UserList'},
            {key:'monthlyFee',lb:'Minimum Monthly Fee(Bath)'},
            {key:'costPerUser',lb:'Additional Cost Per User(Bath)'}
        ]
    },
    {
    formName:'userLogInForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'createNewUser',lb: 'Create New User'},
            {key:'formHead',lb: 'User Log In'},
            {key:'password',lb: 'Password'},
            {key:'username',lb: 'Username'},
            {key:'tryAccount',lb:'Click here to try'}
        ]
    },
    {
    formName:'widthLeftForm',
    formHead:'',
    formLanguage:'English',
    id: 3,
    shopId:'shopa',
    template:[ 
            {key:'formHead',lb: 'Set Width'},
            {key:'widthLeft',lb: 'Width Left'}
        ]
    },
    //=================
    //=================
    //=================
    


    {
        formName:'renderFormTemplate',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[ 
            {key:'page',lb:'Page'},
            {key:'No.',lb:'No.'},
            {key:'shortDate',lb:'Short Date'},
            {key:'shortRemindDate',lb:'Short Remind Date'},

            {key:'shopInfo-1',lb:'Shop Info-1'},
            {key:'shopInfo-2',lb:'Shop Info-2'},
            {key:'shopInfo-3',lb:'Shop Info-3'},
            {key:'shopInfo-4',lb:'Shop Info-4'},
            {key:'shopInfo-5',lb:'Shop Info-5'},
            {key:'shopLogo-1',lb:'Shop Logo-1'},
            {key:'shopLogo-2',lb:'Shop Logo-2'},
            {key:'shopLogo-3',lb:'Shop Logo-3'},


            {key:'taxId',lb:'Tax ID'},
            
            {key:'horizontalSolidLine',lb:'Horizontal Solid Line'},
            {key:'horizontalThickLine',lb:'Horizontal Thick Line'},
            {key:'horizontalDashedLine',lb:'Horizontal Dashed Line'},

            {key:'verticalSolidLineStart',lb:'Vertical Solid Line Start'},
            {key:'verticalSolidLineEnd',lb:'Vertical Solid Line End'},

            {key:'verticalDashedLineStart',lb:'Vertical Dashed Line Start'},
            {key:'verticalDashedLineEnd',lb:'Vertical Dashed Line End'},

            {key:'verticalThickLineStart',lb:'Vertical Thick Line Start'},
            {key:'verticalThickLineEnd',lb:'Vertical Thick Line End'},

            {key:'recipientTitle',lb:'Recipient Title'}, // คำนำหน้า
            {key:'recipientName',lb:'Recipient Name'}, // ชื่อ 
            {key:'recipientPhone',lb:'Recipient Phone'}, // โทรศัพท์
            {key:'recipientAddress',lb:'Recipient Address'},
            {key:'recipientContact',lb:'Recipient Contact'},


            {key:'reference',lb:'Reference'},


            {key:'active',lb:'Active'},
            {key:'address',lb:'Address'},
            {key:'barcode',lb:'Barcode'},
            {key:'detail',lb:'Detail'},
            {key:'groupName',lb:'Group Name'},
            {key:'groupId',lb:'Group ID'},

            {key:'id',lb:'ID'},
            {key:'icon',lb:'icon'},
            {key:'isRawMat',lb:'Raw Mat'},
            {key:'name',lb:'Name'},
            {key:'newId',lb:'New ID'},

            {key:'point',lb:'Point'},
            {key:'partnerType',lb:'Partner Type'},
            {key:'phone',lb:'Phone'},
            {key:'contact',lb:'Social Contact'},
            {key:'delivery',lb:'Delivery'},
            {key:'price',lb:'Price'},
            {key:'priceLevel',lb:'Price Level'},
            {key:'productName',lb:'Product Name'},
            {key:'remark',lb:'Remark'},
            {key:'remainingCustomerPoint',lb:'Point'},
            
            {key:'title',lb:'Title'},

            {key:'totalSpending',lb:'Total Spending'},
            {key:'unit',lb:'Unit'},
            {key:'taxId',lb:'Tax ID'},
            {key:'isSupplier',lb:'Supplier'},
            {key:'isCustomer',lb:'Customer'},

            {key:'stock',lb:'Stock'},
            {key:'minStock',lb:'Min Stock'},
            {key:'maxStock',lb:'Max Stock'},
        
            {key:'storage',lb:'Storage'},
            {key:'underMinStock',lb:'Under Min'},
            {key:'overMaxStock',lb:'Over Max'},
            {key:'isUnderMinStock',lb:'Under Min'},
            {key:'isOverMaxStock',lb:'Over Max'},
            {key:'isOutOfStock',lb:'Out Of'},

            {key:'stocking_stock',lb:'Stock'},
            {key:'stocking_storage',lb:'Storage'},
            {key:'stocking_isOverMaxStock',lb:'Over Max Stock'},
            {key:'stocking_isUnderMinStock',lb:'Under Min Stock'},
            {key:'stocking_isOutOfStock',lb:'Out Of Stock'},
            {key:'sn',lb:'sn'},
            {key:'branchId',lb:'Branch ID'},
            {key:'branchName',lb:'Branch Name'},
            {key:'password',lb:'Password'},




            { key: 'shopName',lb:'Shop Name'},
            { key: 'code',lb:'Code'},
            { key: 'dateIn',lb:'Date In'},
            { key: 'ownerName',lb:'Owner Name'},
            { key: 'ownerSurName',lb:'Owner Surname'},

            { key: 'ownerEmail',lb:'Owner Email'},
            { key: 'expirationDate', lb:'Expiration Date'},
            { key: 'tempExpirationDate',lb:'TempExpiration Date'},
            { key: 'monthlyFee',lb:'Monthly Fee'},
            { key: 'remainingBalance', lb:'Remaining Balance'},
            { key: 'isCheck',lb:'Is Check'},
            { key: 'hasProblem',lb:'Has Problem'},
            { key: 'transferDate',lb:'Transfer Date'},
            { key: 'accountName',lb:'Account Name'},
            { key: 'accountNumber',lb:'Account Number'},
            { key: 'transferAmount',lb:'TransferAmount'},
            { key: 'fromBank',lb:'From Bank'},
            { key: 'checkBy',lb:'Check By'},
            { key: 'newExpirationDate',lb:'New Expiration Date'},
            { key: 'updatedAt',lb:'Updated At'},
            { key: 'shopId',lb:'Shop ID'},

            { key: 'userId',lb:'User ID'},
            { key: 'addMonth', lb:'Add Month'},
            { key: 'userLevel', lb:'User Level'},
            { key: 'username', lb:'Username'},
            { key: 'surname',lb:'Surname'},
            { key: 'level',lb:'Level'},

            { key: 'taskName',lb:'Task Name'},
            { key: 'taskType',lb:'Task Type'},
            { key: 'taskStatus',lb:'Task Status'},

            { key: 'date',lb:'Date'},
            { key: 'remindDate',lb:'Remind Date'},
            { key: 'startDate',lb:'Start Date'},
            { key: 'endDate',lb:'End Date'},

            { key: 'tableName',lb:'Table Name'},

            { key: 'startTime',lb:'Start Time'},
            { key: 'endTime',lb:'End Time'},
            { key: 'repeatedDate',lb:'Appointment Date'}

        ]
    },
    {
        formName:'renderFormTemplate',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[ 
            {key:'page',lb:'หน้า'},
            {key:'No.',lb:'ลำดับ'},

            {key:'shortDate',lb:'วันที่แบบสั้น'},
            {key:'shortRemindDate',lb:'วันที่เตือนแบบสั้น'},

            {key:'shopInfo-1',lb:'ข้อมูลร้าน-1'},
            {key:'shopInfo-2',lb:'ข้อมูลร้าน-2'},
            {key:'shopInfo-3',lb:'ข้อมูลร้าน-3'},
            {key:'shopInfo-4',lb:'ข้อมูลร้าน-4'},
            {key:'shopInfo-5',lb:'ข้อมูลร้าน-5'},
            {key:'shopLogo-1',lb:'โลโก้ร้าน-1'},
            {key:'shopLogo-2',lb:'โลโก้ร้าน-2'},
            {key:'shopLogo-3',lb:'โลโก้ร้าน-3'},



            {key:'horizontalSolidLine',lb:'เส้นนอนปกติ'},
            {key:'horizontalThickLine',lb:'เส้นนอนหนา'},
            {key:'horizontalDashedLine',lb:'เส้นนอนปะ'},

            {key:'verticalSolidLineStart',lb:'เส้นตั้งปกติเริ่ม'},
            {key:'verticalSolidLineEnd',lb:'เส้นตั้งปกติท้าย'},

            {key:'verticalDashedLineStart',lb:'เส้นตั้งปะเริ่ม'},
            {key:'verticalDashedLineEnd',lb:'เส้นตั้งปะท้าย'},

            {key:'verticalThickLineStart',lb:'เส้นตั้งหนาเริ่ม'},
            {key:'verticalThickLineEnd',lb:'เส้นตั้งหนาท้าย'},


            {key:'recipientTitle',lb:'คำนำหน้าผู้รับ'}, // คำนำหน้า
            {key:'recipientName',lb:'ชื่อผู้รับ'}, // ชื่อ 
            {key:'recipientPhone',lb:'เบอร์โทรผู้รับ'}, // โทรศัพท์
            {key:'recipientAddress',lb:'ที่อยู่ผู้รับ'},
            {key:'recipientContact',lb:'ติดต่ออื่นๆ ผู้รับ'},

            {key:'taxId',lb:'เลขประจำตัวผู้เสียภาษี'},
            {key:'reference',lb:'อ้างอิง'},

            {key:'active',lb:'แอคทีฟ'},
            {key:'address',lb:'ที่อยู่'},
            {key:'barcode',lb:'บาร์โค้ด'},
            {key:'detail',lb:'รายละเอียด'},
            {key:'groupName',lb:'กลุ่ม'},
            {key:'groupId',lb:'ไอดีกลุ่ม'},
            {key:'id',lb:'ไอดี'},
            {key:'icon',lb:'ไอคอน'},
            {key:'isRawMat',lb:'วัตถุดิบ'},
            {key:'name',lb:'ชื่อ'},
            {key:'newId',lb:'ไอดีใหม่'},

            

            {key:'point',lb:'แต้มสะสม'},
            {key:'partnerType',lb:'ประเภทคู่ค้า'},
            {key:'phone',lb:'โทร'},
            {key:'contact',lb:'ติดต่ออื่นๆ'},
            {key:'delivery',lb:'การจัดส่ง'},

            {key:'price',lb:'ราคา'},
            {key:'priceLevel',lb:'ระดับราคา'},
            {key:'productName',lb:'ชื่อสินค้า'},
            {key:'remark',lb:'หมายเหตุ'},
            {key:'remainingCustomerPoint',lb:'แต้มสะสม'},
            {key:'stock',lb:'สต็อก'},
            {key:'title',lb:'คำนำหน้า'},
            
            {key:'totalSpending',lb:'ยอดใช้จ่าย'},
            {key:'unit',lb:'หน่วย'},
            {key:'taxId',lb:'Tax ID'},
            {key:'isSupplier',lb:'ผู้จำหน่าย'},
            {key:'isCustomer',lb:'ลูกค้า'},

            {key: 'stock',lb:'สต็อก'},
            {key: 'minStock',lb:'สต็อคต่ำสุด'},
            {key: 'maxStock',lb:'สต็อคสูงสุด'},
            {key: 'storage',lb:'ที่จัดเก็บ'},
            {key: 'underMinStock',lb:'ต่ำกว่าสต็อคต่ำสุด'},
            {key: 'overMaxStock',lb:'สูงกว่าสต็อคสูงสุด'},
            {key: 'isUnderMinStock',lb:'ต่ำกว่าสต็อคต่ำสุด'},
            {key: 'isOverMaxStock',lb:'สูงกว่าสต็อคสูงสุด'},
            {key: 'isOutOfStock',lb:'หมดสต็อค'},

            {key: 'stocking_stock',lb:'สต็อก'},
            {key: 'stocking_storage',lb:'ที่จัดเก็บ'},

            {key: 'sn',lb:'sn'},
            {key: 'branchId',lb:'ไอดีสาขา'},
            {key: 'branchName',lb:'ชื่อสาขา'},
            {key: 'password',lb:'รหัส'},



            { key: 'shopName',lb:'Shop Name'},
            { key: 'code',lb:'Code'},
            { key: 'dateIn',lb:'Date In'},
            { key: 'ownerName',lb:'Owner Name'},
            { key: 'ownerSurName',lb:'Owner SurName'},

            { key: 'ownerEmail',lb:'Owner Email'},
            { key: 'expirationDate', lb:'Expiration Date'},
            { key: 'tempExpirationDate',lb:'TempExpiration Date'},
            { key: 'monthlyFee',lb:'Monthly Fee'},
            { key: 'remainingBalance', lb:'Remaining Balance'},
            { key: 'isCheck',lb:'Is Check'},
            { key: 'hasProblem',lb:'Has Problem'},
            { key: 'transferDate',lb:'Transfer Date'},
            { key: 'accountName',lb:'Account Name'},
            { key: 'accountNumber',lb:'Account Number'},
            { key: 'transferAmount',lb:'TransferAmount'},
            { key: 'fromBank',lb:'From Bank'},
            { key: 'checkBy',lb:'Check By'},
            { key: 'newExpirationDate',lb:'New Expiration Date'},
            { key: 'updatedAt',lb:'Time Stamp'},
            { key: 'shopId',lb:'Shop ID'},

            { key: 'userId',lb:'User ID'},
            { key: 'addMonth', lb:'Add Month'},
            { key: 'userLevel', lb:'User Level'},
            { key: 'username', lb:'Username'},
            { key: 'surname',lb:'Surname'},
            { key: 'level',lb:'ระดับ'},

            { key: 'taskName',lb:'ชื่องาน'},
            { key: 'taskType',lb:'ประเภทงาน'},
            { key: 'taskStatus',lb:'สถานะงาน'},

            { key: 'date',lb:'วันที่'},
            { key: 'remindDate',lb:'วันที่เตือน'},
            
            { key: 'startDate',lb:'วันที่เริ่ม'},
            { key: 'endDate',lb:'วันที่จบ'},
            { key: 'tableName',lb:'ชื่อโต๊ะ'},

            { key: 'startTime',lb:'เวลาเริ่ม'},
            { key: 'endTime',lb:'เวลาจบ'},
            { key: 'repeatedDate',lb:'วันที่นัดซ้ำ'}

        ]
    },




















    {
        formName:'renderTableTemplate',
        formHead:'',
        formLanguage:'Thai',
        id: 3,
        shopId:'shopa',
        template:[

            {key:'page',lb:'หน้า'},
            {key:'No.',lb:'ลำดับ'},
            {key:'shortDate',lb:'วันที่แบบสั้น'},
            {key:'shortRemindDate',lb:'วันที่เตือนแบบสั้น'},


            {key:'shopInfo-1',lb:'ข้อมูลร้าน-1'},
            {key:'shopInfo-2',lb:'ข้อมูลร้าน-2'},
            {key:'shopInfo-3',lb:'ข้อมูลร้าน-3'},
            {key:'shopInfo-4',lb:'ข้อมูลร้าน-4'},
            {key:'shopInfo-5',lb:'ข้อมูลร้าน-5'},
            {key:'shopLogo-1',lb:'โลโก้ร้าน-1'},
            {key:'shopLogo-2',lb:'โลโก้ร้าน-2'},
            {key:'shopLogo-3',lb:'โลโก้ร้าน-3'},



            {key:'taxId',lb:'เลขประจำตัวผู้เสียภาษี'},

            {key:'horizontalSolidLine',lb:'เส้นนอนปกติ'},
            {key:'horizontalThickLine',lb:'เส้นนอนหนา'},
            {key:'horizontalDashedLine',lb:'เส้นนอนปะ'},

            
            {key:'verticalSolidLineStart',lb:'เส้นตั้งปกติเริ่ม'},
            {key:'verticalSolidLineEnd',lb:'เส้นตั้งปกติท้าย'},

            {key:'verticalDashedLineStart',lb:'เส้นตั้งปะเริ่ม'},
            {key:'verticalDashedLineEnd',lb:'เส้นตั้งปะท้าย'},

            {key:'verticalThickLineStart',lb:'เส้นตั้งหนาเริ่ม'},
            {key:'verticalThickLineEnd',lb:'เส้นตั้งหนาท้าย'},

            {key:'recipientTitle',lb:'คำนำหน้าผู้รับ'}, // คำนำหน้า
            {key:'recipientName',lb:'ชื่อผู้รับ'}, // ชื่อ 
            {key:'recipientPhone',lb:'เบอร์โทรผู้รับ'}, // โทรศัพท์
            {key:'recipientAddress',lb:'ที่อยู่ผู้รับ'},
            {key:'recipientContact',lb:'ติดต่อผู้รับ'},


            {key:'reference',lb:'อ้างอิง'},
            { key: 'active', lb: 'แอคทีฟ' },
            { key: 'address', lb: 'ที่อยู่' },
            { key: 'barcode', lb: 'บาร์โค้ด' },
            { key: 'date', lb: 'วันที่' },
            { key: 'detailTime', lb: 'เวลา' },
            { key: 'effectCustomerPoint', lb: 'กระทบแต้มสะสม' },
            { key: 'effectSpending', lb: 'กระยอดใช้จ่าย' },
            { key: 'effectStock', lb: 'กระทบสต็อก' },
            { key: 'endBillActive', lb: 'แอคทีฟ' },
            { key: 'endBillEffect', lb: 'กระทบ' },
            { key: 'endBillId', lb: 'ไอดี' },
            { key: 'endBillInPercentage', lb: 'เป็นเปอเซ็นต์' },
            { key: 'endBillInPercentageOf', lb: 'เป็นเปอร์เซ็นต์ของ' },
            { key: 'endBillName', lb: 'รายการท้ายบิล' },
            { key: 'endBillAmount', lb: 'ยอดท้ายบิล' },

            
            { key: 'recipientTitle',lb:'คำนำหน้าผู้รับ'},
            { key: 'recipientName',lb:'ชื่อผู้รับ'},
            { key: 'recipientPhone',lb:'เบอร์โทรผู้รับ'},
            { key: 'recipientContact',lb:'ติดต่ออื่นๆผู้รับ'},
            { key: 'recipientAddress',lb:'ที่อยู่ผู้รับ'},


            { key: 'endBillRate', lb: 'อัตรา' },
            { key: 'grandTotal', lb: 'สุทธิ' },
            { key: 'groupId', lb: 'ไอดีกลุ่ม' },
            { key: 'groupName', lb: 'ชื่อกลุ่ม' },
            { key: 'id', lb: 'ไอดี' },
            { key: 'isRawMat', lb: 'เป็นวัตถุดิบ' },
            { key: 'jobStatus', lb: 'สถานะงาน' },
            { key: 'name', lb: 'ชื่อ' },
            { key: 'partnerId', lb: 'ไอดีคู่ค้า' },
            { key: 'partnerType', lb: 'ประเภทคู่ค้า' },
            { key: 'paymentType', lb: 'ประเภทการจ่าย' },
            { key:'cash',lb: 'เงินสด'},
            { key:'change',lb: 'เงินทอน'},

            { key: 'phone', lb: 'โทร' },
            { key: 'contact',lb:'ติดต่ออื่นๆ'},
            { key: 'photoUrl1', lb: 'รูป' },
            { key: 'point', lb: 'แต้มสะสม' },
            { key: 'price', lb: 'ราคา' },
            { key: 'priceIncludeVat', lb: 'ราคารวมแวท' },
            { key: 'priceExcludeVat', lb: 'ราคาไม่รวมแวท' },

            { key: 'printFontSize', lb: 'ขนาดฟอนท์' },
            { key: 'printKey', lb: 'คีย์' },
            { key: 'printPosition', lb: 'ตำแหน่ง' },
            { key: 'printValue', lb: 'ค่า' },
            { key: 'printWidth', lb: 'ความกว้าง' },
            { key: 'productName', lb: 'ชื่อสินค้า' },
            { key: 'quantity', lb: 'จำนวน' },
            { key: 'queue', lb: 'คิว' },
            { key: 'queueStatus', lb: 'สถานะคิว' },
            { key: 'reductCustomerPoint', lb: 'หักแต้มสะสม' },
            { key: 'remainingCustomerPoint', lb: 'แต้มสะสม' },
            { key: 'remark', lb: 'หมายเหตุ' },
            { key: 'remindDate', lb: 'วันที่เตือน' },
            { key: 'startDate', lb: 'วันที่เริ่ม' },
            { key: 'endDate', lb: 'วันที่จบ' },
            { key: 'taskName', lb: 'ชื่องาน' },
            { key: 'taskType', lb: 'ประเภทงาน' },
            { key: 'taskStatus', lb: 'สถานะงาน' },



            { key: 'result', lb: 'รวม' },
            { key: 'resultIncludeVat', lb: 'รวม (รวมแวท)'},
            { key: 'routeAddress', lb: 'เร้าท์' },
            { key: 'routeName', lb: 'ชื่อเร้าท์' },
            { key: 'sectionName', lb: 'ส่วน' },
            { key: 'selectedLine', lb: '_' },
            { key: 'show', lb: 'แสดง' },

            { key: 'surname', lb: 'นามสกุล' },
            { key: 'table', lb: 'โต๊ะ' },
            { key: 'tableStatus', lb: 'สถานะโต๊ะ' },
            { key: 'time', lb: 'เวลา' },
            { key: 'title', lb: 'คำนำหน้า' },
            { key: 'total', lb: 'รวม' },
            { key: 'totalIncludeVat', lb: 'รวมราคารวมแวท' },
            { key: 'totalExcludeVat', lb: 'รวมราคาไม่รวมแวท' },

            { key: 'totalPoint', lb: 'รวมแต้มสะสม' },
            { key: 'totalSpending', lb: 'รวมยอดใช้จ่าย' },
            { key: 'transactionStatus', lb: 'สถานะบิล' },
            { key: 'transactionType', lb: 'ประเภทบิล' },
            { key: 'transactionTypeId', lb: 'ไอดีประเภทบิล' },

            { key: 'unit', lb: 'หน่วย' },
            { key: 'userLevel', lb: 'ระดับผู้ใช้' },
            { key: 'username', lb: 'ชื่อผู้ใช้' },
            { key: 'recordName', lb:'ชื่อ'},
            { key: 'recordBy',lb:'คนบันทึก'},

            { key: 'effectId', lb: 'ไอดีการกระทบ' },
            { key: 'effectName', lb: 'ชื่อการกระทบ' },
            { key: 'effectEndBillId', lb: 'กระทบจากค่า' },
            { key: 'effectEndBillName', lb: 'กระทบจากค่า' },

            { key: 'effectSign', lb: 'ผลกระทบ' },
            { key: 'taxId',lb:'Tax ID'},
            { key: 'isSupplier', lb:'ผู้ขาย'},
            { key: 'isCustomer', lb:'ลูกค้า'},

            { key: 'stock', lb: 'สต็อค' },
            { key: 'minStock',lb:'สต็อคต่ำสุด'},
            { key: 'maxStock',lb:'สต็อคสูงสุด'},
            { key: 'storage',lb:'ที่จัดเก็บ'},

            { key: 'overMaxStock',lb:'เกินสต็อคสูงสุด'},
            { key: 'underMinStock',lb:'ต่ำกว่าสต็อคต่ำสุด'},
            { key: 'isUnderMinStock',lb:'ต่ำกว่าสต็อคต่ำสุด'},
            { key: 'isOverMaxStock',lb:'เกินสต็อคสูงสุด'},
            { key: 'isOutOfStock',lb:'หมดสต็อค'},
            { key: 'stocking_stock',lb:'สต็อค'},
            { key: 'stocking_storage',lb:'ที่จัดเก็บ'},
            { key: 'stocking_isOverMaxStock',lb:'เกินสต็อกสูงสุด'},
            { key: 'stocking_isUnderMinStock',lb:'ต่ำกว่าสต็อกต่ำสุด'},
            { key: 'stocking_isOutOfStock',lb:'หมดสต็อก'},
            { key: 'sn',lb:'sn'},
            { key: 'resultPoint',lb:'รวมแต้ม'},
            { key: 'branchId',lb:'ไอดีสาขา'},
            { key: 'branchName',lb:'ชื่อสาขา'},
            { key: 'productId',lb:'ไอดีสินค้า'},
            { key: 'productBarcode',lb:'บาร์โค้ดสินค้า'},
            { key: 'pic',lb:'pic'},
            { key: 'stickerQty', lb:'จำนวน'},
            { key: 'gcode', lb:'โค้ดกลุ่ม'},
            { key: 'level',lb:'ระดับ'},



            { key: 'shopName',lb:'Shop Name'},
            { key: 'code',lb:'Code'},
            { key: 'dateIn',lb:'Date In'},
            { key: 'ownerName',lb:'Owner Name'},
            { key: 'ownerSurName',lb:'Owner SurName'},

            { key: 'ownerEmail',lb:'Owner Email'},
            { key: 'expirationDate', lb:'Expiration Date'},
            { key: 'tempExpirationDate',lb:'TempExpiration Date'},
            { key: 'monthlyFee',lb:'Monthly Fee'},
            { key: 'remainingBalance', lb:'Remaining Balance'},
            { key: 'isCheck',lb:'Is Check'},
            { key: 'hasProblem',lb:'Has Problem'},
            { key: 'transferDate',lb:'Transfer Date'},
            { key: 'accountName',lb:'Account Name'},
            { key: 'accountNumber',lb:'Account Number'},
            { key: 'transferAmount',lb:'TransferAmount'},
            { key: 'fromBank',lb:'From Bank'},
            { key: 'checkBy',lb:'Check By'},
            { key: 'newExpirationDate',lb:'New Expiration Date'},
            { key: 'updatedAt',lb:'เวลาบันทึก'},
            { key: 'shopId',lb:'Shop ID'},

            { key: 'userId',lb:'ไอดีผู้ใช้'},
            { key: 'addMonth', lb:'Add Month'},

            {key:'endBillId0',lb:'รายการท้ายบิล0'},
            {key:'endBillAmount0',lb:'ยอดท้ายบิล0'},
            {key:'endBillId1',lb:'รายการท้ายบิล1'},
            {key:'endBillAmount1',lb:'ยอดท้ายบิล1'},
            {key:'endBillId2',lb:'รายการท้ายบิล2'},
            {key:'endBillAmount2',lb:'ยอดท้ายบิล2'},
            {key:'endBillId3',lb:'รายการท้ายบิล3'},
            {key:'endBillAmount3',lb:'ยอดท้ายบิล3'},
            {key:'endBillId4',lb:'รายการท้ายบิล4'},
            {key:'endBillAmount4',lb:'ยอดท้ายบิล4'},
            {key:'endBillId5',lb:'รายการท้ายบิล5'},
            {key:'endBillAmount5',lb:'ยอดท้ายบิล5'},
            {key:'endBillId6',lb:'รายการท้ายบิล6'},
            {key:'endBillAmount6',lb:'ยอดท้ายบิล6'},
            {key:'endBillId7',lb:'รายการท้ายบิล7'},
            {key:'endBillAmount7',lb:'ยอดท้ายบิล7'},
            {key:'endBillId8',lb:'รายการท้ายบิล8'},
            {key:'endBillAmount8',lb:'ยอดท้ายบิล8'},
            {key:'endBillId9',lb:'รายการท้ายบิล9'},
            {key:'endBillAmount9',lb:'ยอดท้ายบิล9'},
            

            {key:'effectId0',lb:'รายการกระทบบิล0'},
            {key:'effectAmount0',lb:'ยอดกระทบบิล0'},
            {key:'effectId1',lb:'รายการกระทบบิล1'},
            {key:'effectAmount1',lb:'ยอดกระทบบิล1'},
            {key:'effectId2',lb:'รายการกระทบบิล2'},
            {key:'effectAmount2',lb:'ยอดกระทบบิล2'},
            {key:'effectId3',lb:'รายการกระทบบิล3'},
            {key:'effectAmount3',lb:'ยอดกระทบบิล3'},
            {key:'effectId4',lb:'รายการกระทบบิล4'},
            {key:'effectAmount4',lb:'ยอดกระทบบิล4'},
            {key:'effectId5',lb:'รายการกระทบบิล5'},
            {key:'effectAmount5',lb:'ยอดกระทบบิล5'},
            {key:'effectId6',lb:'รายการกระทบบิล6'},
            {key:'effectAmount6',lb:'ยอดกระทบบิล6'},
            {key:'effectId7',lb:'รายการกระทบบิล7'},
            {key:'effectAmount7',lb:'ยอดกระทบบิล7'},
            {key:'effectId8',lb:'รายการกระทบบิล8'},
            {key:'effectAmount8',lb:'ยอดกระทบบิล8'},
            {key:'effectId9',lb:'รายการกระทบบิล9'},
            {key:'effectAmount9',lb:'ยอดกระทบบิล9'},

            {key:'spareBoolean1',lb:'มีรูป'},
            {key:'mark',lb:'เครื่องหมาย'},
            {key:'tableName',lb:'ชื่อโต๊ะ'},


        ]
    },

    {
        formName:'renderTableTemplate',
        formHead:'',
        formLanguage:'English',
        id: 3,
        shopId:'shopa',
        template:[
            {key:'page',lb:'Page'},
            {key:'No.',lb:'No.'},
            {key:'shortDate',lb:'Short Date'},
            {key:'shortRemindDate',lb:'Short Remind Date'},

            {key:'shopInfo-1',lb:'Shop Info-1'},
            {key:'shopInfo-2',lb:'Shop Info-2'},
            {key:'shopInfo-3',lb:'Shop Info-3'},
            {key:'shopInfo-4',lb:'Shop Info-4'},
            {key:'shopInfo-5',lb:'Shop Info-5'},
            {key:'shopLogo-1',lb:'Shop Logo-1'},
            {key:'shopLogo-2',lb:'Shop Logo-2'},
            {key:'shopLogo-3',lb:'Shop Logo-3'},


            {key:'taxId',lb:'Tax ID'},

            {key:'horizontalSolidLine',lb:'Horizontal Solid Line'},
            {key:'horizontalThickLine',lb:'Horizontal Thick Line'},
            {key:'horizontalDashedLine',lb:'Horizontal Dashed Line'},

            {key:'verticalSolidLineStart',lb:'Vertical Solid Line Start'},
            {key:'verticalSolidLineEnd',lb:'Vertical Solid Line End'},

            {key:'verticalDashedLineStart',lb:'Vertical Dashed Line Start'},
            {key:'verticalDashedLineEnd',lb:'Vertical Dashed Line End'},

            {key:'verticalThickLineStart',lb:'Vertical Thick Line Start'},
            {key:'verticalThickLineEnd',lb:'Vertical Thick Line End'},

            {key:'recipientTitle',lb:'คำนำหน้าผู้รับ'}, // คำนำหน้า
            {key:'recipientName',lb:'ชื่อผู้รับ'}, // ชื่อ 
            {key:'recipientPhone',lb:'เบอร์โทรผู้รับ'}, // โทรศัพท์
            {key:'recipientAddress',lb:'ที่อยู่ผู้รับ'},
            {key:'recipientContact',lb:'ติดต่ออื่นๆ ผู้รับ'},

            {key:'reference',lb:'Reference'},
            { key: 'active', lb: 'Active' },
            { key: 'address', lb: 'Address' },
            { key: 'barcode', lb: 'Barcode' },
            { key: 'date', lb: 'Date' },
            { key: 'detailTime', lb: 'Detail Time' },
            { key: 'effectCustomerPoint', lb: 'Effect Point' },
            { key: 'effectSpending', lb: 'Effect Spending' },
            { key: 'effectStock', lb: 'Effect Stock' },
            { key: 'endBillActive', lb: 'Active' },
            { key: 'endBillEffect', lb: 'Effect' },
            { key: 'endBillId', lb: 'ID' },
            { key: 'endBillInPercentage', lb: 'In Percentage' },
            { key: 'endBillInPercentageOf', lb: 'In Percentage of' },
            { key: 'endBillName', lb: 'End Bill Name' },
            { key: 'endBillAmount', lb: 'End Bill Amount' },
            { key: 'endBillRate', lb: 'Rate' },
            { key: 'grandTotal', lb: 'Grand Total' },
            { key: 'groupId', lb: 'Group ID' },
            { key: 'groupName', lb: 'Group Name' },
            { key: 'id', lb: 'ID' },
            { key: 'isRawMat', lb: 'Raw Mat' },
            { key: 'jobStatus', lb: 'Job Status' },
            { key: 'name', lb: 'Name' },
            { key: 'partnerId', lb: 'Partner ID' },
            { key: 'partnerType', lb: 'Customer Type' },
            { key: 'paymentType', lb: 'Payment Type' },
            { key: 'cash',lb: 'Cash'},
            { key: 'change',lb: 'Change'},


            { key: 'phone', lb: 'Phone' },
            { key: 'contact', lb:'Social Contact'},
            { key: 'photoUrl1', lb: 'Photo' },
            { key: 'point', lb: 'Point' },
            { key: 'price', lb: 'Price' },
            { key: 'priceIncludeVat', lb: 'Price Include Vat' },
            { key: 'priceExcludeVat', lb: 'Price Exclude Vat' },

            { key: 'recipientTitle',lb:'Recipient Title'},
            { key: 'recipientName',lb:'Recipient Name'},
            { key: 'recipientPhone',lb:'Recipient Phone'},
            { key: 'recipientContact',lb:'Recipient Contact'},
            { key: 'recipientAddress',lb:'Recipient Address'},


            { key: 'printFontSize', lb: 'Font Size' },
            { key: 'printKey', lb: 'Key' },
            { key: 'printPosition', lb: 'Position' },
            { key: 'printValue', lb: 'Value' },
            { key: 'printWidth', lb: 'Width' },
            { key: 'productName', lb: 'Product Name' },
            { key: 'quantity', lb: 'Quantity' },
            { key: 'queue', lb: 'Queue' },
            { key: 'queueStatus', lb: 'Queue Status' },
            { key: 'reductCustomerPoint', lb: 'Reduct Point' },
            { key: 'remainingCustomerPoint', lb: 'Point' },
            { key: 'remark', lb: 'Remark' },
            { key: 'remindDate', lb: 'Remind Date' },
            { key: 'startDate', lb: 'Start Date' },
            { key: 'endDate', lb: 'End Date' },
            { key: 'taskName', lb: 'Task Name' },
            { key: 'taskType', lb: 'Task Type' },
            { key: 'taskStatus', lb: 'Task Status' },


            { key: 'result', lb: 'Result' },
            { key: 'resultIncludeVat', lb:'Result (include Vat)'},
            { key: 'routeAddress', lb: 'Route' },
            { key: 'routeName', lb: 'Route Name' },
            { key: 'sectionName', lb: 'Section Name' },
            { key: 'selectedLine', lb: '_' },
            { key: 'show', lb: 'Show' },
            { key: 'surname', lb: 'Surname' },
            { key: 'table', lb: 'Table' },
            { key: 'tableStatus', lb: 'Table Status' },
            { key: 'time', lb: 'Time' },
            { key: 'title', lb: 'Title' },
            { key: 'total', lb: 'Total' },
            { key: 'totalIncludeVat',lb:'Total (include Vat)'},
            { key: 'totalExcludeVat',lb:'Total (exclude Vat'},
            { key: 'totalPoint', lb: 'Total Point' },
            { key: 'totalIncludeVat', lb:'รวม (ราคารวมแวท)'},
            { key: 'totalExcludeVat', lb:'รวม (ราคาไม่รวมแวท)'},
            { key: 'totalSpending', lb: 'Total Spending' },
            { key: 'transactionStatus', lb: 'Transaction Status' },
            { key: 'transactionType', lb: 'Transaction Type' },

            { key: 'transactionTypeId', lb: 'Transaction Type ID' },
            { key: 'unit', lb: 'Unit' },
            { key: 'userLevel', lb: 'User Level' },
            { key: 'username', lb: 'User Name' },
            { key: 'recordName', lb:'Record Name'},
            { key: 'recordBy',lb:'Record By'},
            { key: 'level',lb:'Level'},


            { key: 'effectId', lb: 'Effect ID' },
            { key: 'effectName', lb: 'Effect Name' },
            { key: 'effectEndBillId', lb: 'Effect From Value' },
            { key: 'effectEndBillName', lb: 'Effect From Value' },
            { key: 'effectSign', lb: 'Effect' },

            { key: 'taxId',lb:'Tax ID'},
            { key: 'isSupplier', lb:'Supplier'},
            { key: 'isCustomer', lb:'Customer'},

            { key: 'stock', lb: 'Stock' },
            { key: 'minStock',lb:'Min Stock'},
            { key: 'maxStock',lb:'Max Stock'},
            { key: 'storage', lb:'Storage'},
            { key: 'overMaxStock',lb:'Over Max'},
            { key: 'underMinStock',lb:'Under Min'},
            { key: 'isUnderMinStock',lb:'Under Min'},
            { key: 'isOverMaxStock',lb:'Over Max'},
            { key: 'isOutOfStock',lb:'Out Of'},
            { key: 'stockig_stock',lb:'Stock'},
            { key: 'sn',lb:'sn'},
            { key: 'resultPoint',lb:'ResultPoint'},
            { key: 'branchId',lb:'Branch ID'},
            { key: 'branchName',lb:'Branch Name'},
            { key: 'productId',lb:'Product ID'},
            { key: 'productBarcode', lb:'Product Barcode'},
            { key: 'pic',lb:'pic'},
            { key: 'stickerQty', lb:'Quantity'},


            { key: 'shopName',lb:'Shop Name'},
            { key: 'code',lb:'Code'},
            { key: 'dateIn',lb:'Date In'},
            { key: 'ownerName',lb:'Owner Name'},
            { key: 'ownerSurName',lb:'Owner SurName'},////
            { key: 'ownerEmail',lb:'Owner Email'},
            { key: 'expirationDate', lb:'Expiration Date'},
            { key: 'tempExpirationDate',lb:'TempExpiration Date'},
            { key: 'monthlyFee',lb:'Monthly Fee'},
            { key: 'remainingBalance', lb:'Remaining Balance'},
            { key: 'isCheck',lb:'Is Check'},
            { key: 'hasProblem',lb:'Has Problem'},
            { key: 'transferDate',lb:'Transfer Date'},
            { key: 'accountName',lb:'Account Name'},
            { key: 'accountNumber',lb:'Account Number'},
            { key: 'transferAmount',lb:'TransferAmount'},
            { key: 'fromBank',lb:'From Bank'},
            { key: 'checkBy',lb:'Check By'},
            { key: 'newExpirationDate',lb:'New Expiration Date'},
            { key: 'updatedAt',lb:'Updated At'},

            { key: 'shopId',lb:'Shop ID'},
            { key: 'userId',lb:'User ID'},

            { key: 'addMonth', lb:'Add Month'},


            {key:'endBillId0',lb:'endBillId0'},
            {key:'endBillAmount0',lb:'EndBill Amount0'},
            {key:'endBillId1',lb:'EndBill ID1'},
            {key:'endBillAmount1',lb:'EndBill Amount1'},
            {key:'endBillId2',lb:'EndBill ID2'},
            {key:'endBillAmount2',lb:'EndBill Amount2'},
            {key:'endBillId3',lb:'EndBill ID3'},
            {key:'endBillAmount3',lb:'EndBill Amount3'},
            {key:'endBillId4',lb:'EndBill ID4'},
            {key:'endBillAmount4',lb:'EndBill Amount4'},
            {key:'endBillId5',lb:'EndBill ID5'},
            {key:'endBillAmount5',lb:'EndBill Amount5'},
            {key:'endBillId6',lb:'EndBill ID6'},
            {key:'endBillAmount6',lb:'EndBill Amount6'},
            {key:'endBillId7',lb:'EndBill ID7'},
            {key:'endBillAmount7',lb:'EndBill Amount7'},
            {key:'endBillId8',lb:'EndBill ID8'},
            {key:'endBillAmount8',lb:'EndBill Amount8'},
            {key:'endBillId9',lb:'EndBill ID9'},
            {key:'endBillAmount9',lb:'EndBill Amount9'},
            

            {key:'effectId0',lb:'Effect ID0'},
            {key:'effectAmount0',lb:'Effect Amount0'},
            {key:'effectId1',lb:'Effect ID1'},
            {key:'effectAmount1',lb:'Effect Amount1'},
            {key:'effectId2',lb:'Effect ID2'},
            {key:'effectAmount2',lb:'Effect Amount2'},
            {key:'effectId3',lb:'Effect ID3'},
            {key:'effectAmount3',lb:'Effect Amount3'},
            {key:'effectId4',lb:'Effect ID4'},
            {key:'effectAmount4',lb:'Effect Amount4'},
            {key:'effectId5',lb:'Effect ID5'},
            {key:'effectAmount5',lb:'Effect Amount5'},
            {key:'effectId6',lb:'Effect ID6'},
            {key:'effectAmount6',lb:'Effect Amount6'},
            {key:'effectId7',lb:'Effect ID7'},
            {key:'effectAmount7',lb:'Effect Amount7'},
            {key:'effectId8',lb:'Effect ID8'},
            {key:'effectAmount8',lb:'Effect Amount8'},
            {key:'effectId9',lb:'Effect ID9'},
            {key:'effectAmount9',lb:'Effect Amount9'},

            {key:'spareBoolean1',lb:'Has Image'},
            {key:'mark',lb:'mark'},
            {key:'tableName',lb:'Table Name'},
        ]
        },

            {
                formName:'stickerSettingForm',
                formHead:'',
                formLanguage:'English',
                id: 3,
                shopId:'shopa',
                template:[ 
                    {lb:'ID',key:'id' },
                    {lb:'New ID',key:'newId'},
                    {lb:'Name',key:'recordName' },
                    {lb:'Date',key:'date' },
                    {lb:'By',key:'recordBy' },
                    {lb:'Active',key:'active' },

                    {lb:'Page Height',key:'heightP' },
                    {lb:'Page Width',key:'widthP'  },

                     
                    {lb:'Margin Top',key:'marginTopP' },
                    {lb:'Margin Bottom',key:'marginBottomP'},
                    {lb:'Margin Left',key:'marginLeftP' },
                    {lb:'Margin Right',key:'marginRightP' },

                    {lb:'Padding Top',key:'paddingTopP' },
                    {lb:'Padding Bottom',key:'paddingBottomP'},
                    {lb:'Padding Left',key:'paddingLeftP' },
                    {lb:'Padding Right',key:'paddingRightP' },

                    {lb:'Border Bottom Page',key:'borderBottomP' },


                    {lb:'Scale',key:'scale' },
                    {lb:'Upper Gap',key:'marginTopBarcode'},
                    {lb:'Bottom Gap',key:'marginBottomBarcode'},


                    {lb:'Show Barcode',key:'showBarCode' },
                    {lb:'Barcode Height',key:'heightB'  },
                    {lb:'Barcode Width',key:'widthB' },
                    {lb:'Sticker Per Line',key:'xPerLine'},
                    
                    {lb:'Show Border',key:'showBorder' },
                    {lb:'Row Gap',key:'gridRowGap' },
                    {lb:'Column Gap',key:'gridColumnGap' },
                
                    {lb:'Show Code', key:'showCode' },
                    {lb:'Barcode Size', key:'fontSizeCode' },
                
                    {lb:'Show Product Name',key:'showName' },
                    {lb:'Product Name Size', key:'fontSizeName' },
                
                    {lb:'Show Price',key:'showPrice' },
                    {lb:'Price Size', key:'fontSizePrice' },
                
                    {lb:'Show Unit',key:'showUnit' },
                    {lb:'Unit Size', key:'fontSizeUnit' },    

                    {lb:'code',key:'code'},
                    {lb:'Name',key:'name'},
                    {lb:'Price',key:'price'},
                    {lb:'Unit',key:'unit'},
                    {lb:'Qty',key:'qty'},

                    {key:'setting',lb:'General Setting'},
                    {key:'pageSetUp',lb:'Page Set Up'},
                    {key:'barcodeSetUp',lb:'Barcode Set Up'},
                    {key:'productData',lb:'Product Data'}

                    ]
        },


      


]

module.exports = LangTemplate

import React from 'react';
import Table from '../../component/table/Table'
import uuid from 'react-uuid'
import RenderPrintOut from './RenderPrintOut'

import {FaPlusCircle,FaRegArrowAltCircleDown,FaRegArrowAltCircleUp,
        FaBan,FaCheck
       } from 'react-icons/fa';
import {MdDelete,MdClose,MdSwapHoriz,MdSave,MdPrint,MdOutlineArticle
       } from 'react-icons/md'
import LangTemplate from '../../context/LangTemplate';

//import PrintOut from './PrintOut'
import renderWidthRangeBar from '../../component/table/renderWidthRangeBar'
import console from '../../util/consoleVerify'


/*
const tempTransaction={

      id:1,  date:new Date().toISOString(),    
      dateOut:new Date().toISOString(),    branch:"main",
      transactionType:"บิลขาย",   transactionSthatus:"open",    active:true,
      table:"A1", tableStatus:"open", paymentType:"เงินสด",
      effectStock:"unChange", effectSpending:"unChange", effectCustomerPoint:"unChange",
      partnerId:1, partnerType:"ผู้ซื้อ", title:"นาย", name:"จิตติ", 
      phone:["0924424349 ","0459213311"], 
      address: "12 ต.ท่าข้าม อ.นครคีรี จ.มหาสารคาม 70183",
      remark:"ของแท้", 
      total:100, 
      
      endBill:[
            {_id:1,endBillName:"vat7%",endBillAmount:100},
            {_id:2,endBillName:"ส่วนลด",endBillAmount:-50}
      ],
     
      grandTotal:280, 
     
      totalPoint:2, customerPointReduction:2,
      shopId:"shopa",userId:"1",
      detail:[
            { id:1, barcode:"1", productName:"สินค้า1", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:2, barcode:"2", productName:"น้ำพริก2", groupId:1, groupName: "main", unit:"ถุง",
            price:50, priceLevel:[{price:50,remark:"ทุน"}],
            quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:3, barcode:"1", productName:"สินค้า3", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:4, barcode:"2", productName:"น้ำพริก4", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:5, barcode:"1", productName:"สินค้า5", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:6, barcode:"2", productName:"น้ำพริก6", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:7, barcode:"1", productName:"สินค้า7", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:8, barcode:"2", productName:"น้ำพริก8", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:9, barcode:"1", productName:"สินค้า9", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:10, barcode:"2", productName:"น้ำพริก10", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:11, barcode:"1", productName:"สินค้า11", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:12, barcode:"2", produchtName:"น้ำพริก12", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:13, barcode:"1", productName:"สินค้า13", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:14, barcode:"2", productName:"น้ำพริก14", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:15, barcode:"1", productName:"สินค้า15", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:16, barcode:"2", productName:"น้ำพริก16", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:17, barcode:"1", productName:"สินค้า17", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:18, barcode:"2", productName:"น้ำพริก18", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
            { id:19, barcode:"1", productName:"สินค้า19", groupId:1, groupName: "main", unit:"เส้น", 
            price:100, priceLevel:[{price:100,remark:"ทุน"}],
            quantity:1, result:100, remark:"ok", isRawMat:false, point:1, jobStatus:"open"
            },
            { id:20, barcode:"2", productName:"น้ำพริก20", groupId:1, groupName: "main", unit:"ถุง",
                  price:50, priceLevel:[{price:50,remark:"ทุน"}],
                  quantity:1, result:50, remark:"ok", isRawMat:false, point:1,jobStatus:"open",
            },
      ]
}
*/
const tempTransaction={
      /*
      shopInfo:[
            "ร้านสวัสดีรุ่งเรือง",
            "สาขา1",
            "999 หมู่ 7 ต.หนองแดง อ.บ้านนา จ.เพชรบุรี  76542",
            "โทร 098-333-4444",
            "www.abc.com",
      ],
      shopLogo:[
            "https://picsum.photos/id/206/200/300",
      ],
      */
      id:109012,  date:new Date().toISOString(), remindDate:new Date().toISOString(),    
      branchId:1,branchName:"branch1",
      refrence:"",
      transactionType:"บิลขาย",   transactionStatus:"open",    
      active:true,
      //table:"A1", tableStatus:"open", 
      paymentType:"เงินสด",
      queue:1,queueStatus:"open",
      //effectStock:"unChange", effectSpending:"unChange", effectCustomerPoint:"unChange",
      partnerId:1, partnerType:"ผู้ซื้อ", isSupplier:false,isCustomer:true,
      title:"นาย", name:"จิตติ", phone:["0004445555 ","0004445556"], 
      address: "12 ต.ท่ามะม่วง อ.ดินดำดี จ.ราชบุรี 99999",
      remark:"ของแท้", total:100, totalReduction:10,
       
      recipientTitle:"นาย", // คำนำหน้า
      recipientName:"จิตติ", // ชื่อ 
      recipientPhone:"0004445555", // โทรศัพท์
      recipientAddress: "12 ต.ท่ามะม่วง อ.ดินดำดี จ.ราชบุรี 99999",
      recipientContact:"",
     

      photoUrl1:[
            "https://picsum.photos/id/201/200/300",
            "https://picsum.photos/id/202/200/300",
            "https://picsum.photos/id/203/200/300",
      ],

      pic:"group1",
      
      endBill:[
          {
          endBillActive:true,
          endBillId:1,
          endBillName:"vat7%",
          endBillEffect:"+",
          endBillInPercentage:true,
          endBillRate:7,
          endBillInPercentageOf:"total",
          endBillAmount:7
          },
          {
          endBillActive:true,
          endBillId:2,
          endBillName:"reduction",
          endBillEffect:"-",
          endBillInPercentage:false,
          endBillRate:0,
          endBillInPercentageOf:"null",
          endBillAmount:0
          },    
      ],
  
  
      total:100,
      grandTotal:107,
      totalPoint:10,
      reductCustomerPoint:0,
  
      cash:500,
      change:393,

      effect:[
          {
          effectId:1,
          effectName:"effectStock",
          effectSign:"-",
          effectEndBillId:null,//no use
          effectEndBillName:"",
          effectAmount:0, //no use
          },
          {
          effectId:2,
          effectName:"effectRemainingCustomerPoint",
          effectSign:"+",
          effectEndBillId:null,//no use
          effectAmount:10,  //no use
          },
          {
          effectId:3,
          effectName:"effectTotalSpending",
          effectSign:"+",
          effectEndBillId:null,//no use
          effectAmount:100, //no use
          },
      ],
  
      shopId:"shopa",userId:"1",
      
      detail:[
      { selectedLine:true,
        id:1, barcode:"1001001", sn:"1",productName:"สินค้า11", groupId:1, groupName: "main", 
        unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
        quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
        point:1,resultPoint:1,
        photoUrl1:["https://picsum.photos/id/200/200/300"]
      },
      
      { selectedLine:true,
      id:2, barcode:"1001002", sn:"2",productName:"สินค้า12", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/201/200/300"]

      },

      { selectedLine:true,
      id:3, barcode:"1001003", sn:"3",productName:"สินค้า13", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/202/200/300"]
      },
      
      { selectedLine:true,
      id:4, barcode:"1001004", sn:"4",productName:"สินค้า14", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/203/200/300"]
      },

      { selectedLine:true,
      id:5, barcode:"1001005", sn:"5",productName:"สินค้า15", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/204/200/300"]
      },

      { selectedLine:true,
      id:6, barcode:"1001006", sn:"6",productName:"สินค้า16", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/205/200/300"]
      },
      
      { selectedLine:true,
      id:7, barcode:"1001007", sn:"7",productName:"สินค้า17", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/206/200/300"]
      },

      { selectedLine:true,
      id:8, barcode:"1001008", sn:"8",productName:"สินค้า18", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/207/200/300"]
      },
      
      { selectedLine:true,
      id:9, barcode:"1001009", sn:"9",productName:"สินค้า19", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/208/200/300"]
      },

      {selectedLine:true, 
      id:10, barcode:"1001010", sn:"10",productName:"สินค้า20", groupId:1, groupName: "main", 
      unit:"เส้น", price:10, priceLevel:[{price:40,remark:"ทุน"}],
      quantity:1, result:10, remark:"ok", isRawMat:false, point:1, 
      point:1,resultPoint:1,
      photoUrl1:["https://picsum.photos/id/209/200/300"]
      },



      ]
  }
  
  

  
const printSelection={      
      header:
        [
           {key:"page",LB:"Page"},
           
           {key:"shopInfo-1",LB:"Shop Info-1"},
           {key:"shopInfo-2",LB:"Shop Info-2"},
           {key:"shopInfo-3",LB:"Shop Info-3"},
           {key:"shopInfo-4",LB:"Shop Info-4"},
           {key:"shopInfo-5",LB:"Shop Info-5"},
           {key:"shopLogo-1",LB:"Shop Logo-1"},
           {key:"shopLogo-2",LB:"Shop Logo-2"},
           {key:"shopLogo-3",LB:"Shop Logo-3"},


           {key:"horizontalSolidLine",LB:"Horizontal Solid Line"},
           {key:"horizontalDashedLine",LB:"Horizontal Dashed Line"},
           {key:"horizontalThickLine",LB:"Horizontal Thick Line"},

           {key:"verticalSolidLineStart",LB:"Vertical Solid Line Start"},
           {key:"verticalSolidLineEnd",LB:"Vertical Solid Line End"},

           {key:"verticalDashedLineStart",LB:"Vertical Dashed Line Start"},
           {key:"verticalDashedLineEnd",LB:"Vertical Dashed Line End"},

           {key:"verticalThickLineStart",LB:"Vertical Thick Line Start"},
           {key:"verticalThickLineEnd",LB:"Vertical Thick Line End"},
            
           {key:"id",LB:"ID"},

           {key:"date",LB:"Date"},
           {key:"remindDate",LB:"Date"},

           {key:"shortDate",LB:"Short Date"},
           {key:"shortRemindDate",LB:"Short Remind Date"},

           {key:"branchId",LB:"Branch ID"},
           {key:"branchName",LB:"Branch Name"},
           {key:"reference",LB:"Reference"},
           
           {key:"table",LB:"Table"},
           {key:"tableStatus",LB:"Table Status"},
           {key:"queue",LB:"Queue"},
           //{key:"queue",LB:"Queue Status"},
           {key:"queueStatus",LB:"Queue Status"},

           {key:"paymentType",LB:"Payment Type"},

           {key:"partnerId",LB:"Customer ID"},
           {key:"partnerType",LB:"Customer Type"},
           
           {key:"title",LB:"Title"},
           {key:"name",LB:"Name"},
           {key:"phone",LB:"Phone"},
           {key:"address",LB:"Address"},

           {key:"taxId",LB:"Tax ID"},

           {key:"recipientTitle",LB:"คำนำหน้าผู้รับ"}, // คำนำหน้า
           {key:"recipientName",LB:"ชื่อผู้รับ"}, // ชื่อ 
           {key:"recipientPhone",LB:"เบอร์โทรผู้รับ"}, // โทรศัพท์
           {key:"recipientAddress",LB:"ที่อยู่ผู้รับ"},
           {key:"recipientContact",LB:"ติดต่ออื่นๆ ผู้รับ"},


           {key:"id@scanner@",LB:"id@scanner@"},
           {key:"id@scanner@15-5-20-0-1.2-20-1",LB:"id@scanner@15-5-20-0-1.2-20-1"},
           {key:"id@scanner@15-5-40-0-1.2-20-1",LB:"id@scanner@15-5-40-0-1.2-20-1"},
           {key:"id@scanner@15-5-60-0-1.2-20-1",LB:"id@scanner@15-5-60-0-1.2-20-1"},
           {key:"id@scanner@15-5-55-0-1.5-20-1",LB:"id@scanner@15-5-55-0-1.5-20-1"},
           {key:"id@scanner@15-5-75-0-1.5-20-1",LB:"id@scanner@15-5-75-0-1.5-20-1"},
           {key:"id@scanner@15-5-95-0-1.5-20-1",LB:"id@scanner@15-5-95-0-1.5-20-1"},
      ],
     tableHead:[
            {key:"horizontalSolidLine",LB:"Horizontal Solid Line"},
            {key:"horizontalDashedLine",LB:"Horizontal Dashed Line"},
            {key:"horizontalThickLine",LB:"Horizontal Thick Line"},

            {key:"verticalSolidLineStart",LB:"Vertical Solid Line Start"},
            {key:"verticalSolidLineEnd",LB:"Vertical Solid Line End"},
 
            {key:"verticalDashedLineStart",LB:"Vertical Dashed Line Start"},
            {key:"verticalDashedLineEnd",LB:"Vertical Dashed Line End"},
 
            {key:"verticalThickLineStart",LB:"Vertical Thick Line Start"},
            {key:"verticalThickLineEnd",LB:"Vertical Thick Line End"},
             
     ], 
     tableEnd:[
            {key:"horizontalSolidLine",LB:"Horizontal Solid Line"},
            {key:"horizontalDashedLine",LB:"Horizontal Dashed Line"},
            {key:"horizontalThickLine",LB:"Horizontal Thick Line"},

            {key:"verticalSolidLineStart",LB:"Vertical Solid Line Start"},
            {key:"verticalSolidLineEnd",LB:"Vertical Solid Line End"},
 
            {key:"verticalDashedLineStart",LB:"Vertical Dashed Line Start"},
            {key:"verticalDashedLineEnd",LB:"Vertical Dashed Line End"},
 
            {key:"verticalThickLineStart",LB:"Vertical Thick Line Start"},
            {key:"verticalThickLineEnd",LB:"Vertical Thick Line End"},
             
      ], 
     table:
        [
           {key:"No.",LB:"No."},
           {key:"id",LB:"Product ID"},
           {key:"barcode",LB:"Product Barcode"},
           {key:"sn",LB:"SN"},
           {key:"productName",LB:"Product Name"},
           {key:"groupId",LB:"Product Group ID"},
           {key:"groupName",LB:"Product Group Name"},
           {key:"unit",LB:"Product Unit"},
           {key:"price",LB:"Product Price"},
           {key:"priceIncludeVat",LB:"Product Include Price"},

           {key:"quantity",LB:"Product Quantity"},
           {key:"result",LB:"Total"},
           {key:"resultIncludeVat",LB:"Total Include Vat"},

           {key:"remark",LB:"Product Remark"},
           {key:"point",LB:"Product Point"},
           {key:"resultPoint",LB:"Product Result Point"},
           {key:"photoUrl1",LB:"Photo"},

           {key:"id@scanner@",LB:"id@scanner@"},
           {key:"id@scanner@15-5-20-0-1.2-20-1",LB:"id@scanner@15-5-20-0-1.2-20-1"},
           {key:"id@scanner@15-5-40-0-1.2-20-1",LB:"id@scanner@15-5-40-0-1.2-20-1"},
           {key:"id@scanner@15-5-60-0-1.2-20-1",LB:"id@scanner@15-5-60-0-1.2-20-1"},
           {key:"id@scanner@15-5-55-0-1.5-20-1",LB:"id@scanner@15-5-55-0-1.5-20-1"},
           {key:"id@scanner@15-5-75-0-1.5-20-1",LB:"id@scanner@15-5-75-0-1.5-20-1"},
           {key:"id@scanner@15-5-95-0-1.5-20-1",LB:"id@scanner@15-5-95-0-1.5-20-1"},

           {key:"barcode@scanner@",LB:"barcode@scanner@"},
           {key:"barcode@scanner@15-5-20-0-1.2-20-1",LB:"barcode@scanner@15-5-20-0-1.2-20-1"},
           {key:"barcode@scanner@15-5-40-0-1.2-20-1",LB:"barcode@scanner@15-5-40-0-1.2-20-1"},
           {key:"barcode@scanner@15-5-60-0-1.2-20-1",LB:"barcode@scanner@15-5-60-0-1.2-20-1"},
           {key:"barcode@scanner@15-5-55-0-1.5-20-1",LB:"barcode@scanner@15-5-55-0-1.5-20-1"},
           {key:"barcode@scanner@15-5-75-0-1.5-20-1",LB:"barcode@scanner@15-5-75-0-1.5-20-1"},
           {key:"barcode@scanner@15-5-95-0-1.5-20-1",LB:"barcode@scanner@15-5-95-0-1.5-20-1"},


           {key:"sn@scanner@",LB:"sn@scanner@"},
           {key:"sn@scanner@15-5-20-0-1.2-20-1",LB:"sn@scanner@15-5-20-0-1.2-20-1"},
           {key:"sn@scanner@15-5-40-0-1.2-20-1",LB:"sn@scanner@15-5-40-0-1.2-20-1"},
           {key:"sn@scanner@15-5-60-0-1.2-20-1",LB:"sn@scanner@15-5-60-0-1.2-20-1"},
           {key:"sn@scanner@15-5-55-0-1.5-20-1",LB:"sn@scanner@15-5-55-0-1.5-20-1"},
           {key:"sn@scanner@15-5-75-0-1.5-20-1",LB:"sn@scanner@15-5-75-0-1.5-20-1"},
           {key:"sn@scanner@15-5-95-0-1.5-20-1",LB:"sn@scanner@15-5-95-0-1.5-20-1"},

        ],

     total:[
            {key:"horizontalSolidLine",LB:"Horizontal Solid Line"},
            {key:"horizontalDashedLine",LB:"Horizontal Dashed Line"},
            {key:"horizontalThickLine",LB:"Horizontal Thick Line"},

            {key:"verticalSolidLineStart",LB:"Vertical Solid Line Start"},
            {key:"verticalSolidLineEnd",LB:"Vertical Solid Line End"},

            {key:"verticalDashedLineStart",LB:"Vertical Dashed Line Start"},
            {key:"verticalDashedLineEnd",LB:"Vertical Dashed Line End"},

            {key:"verticalThickLineStart",LB:"Vertical Thick Line Start"},
            {key:"verticalThickLineEnd",LB:"Vertical Thick Line End"},
            {key:"total",LB:"Total"}
     ],
     endBill:[
            {key:"horizontalSolidLine",LB:"Horizontal Solid Line"},
            {key:"horizontalDashedLine",LB:"Horizontal Dashed Line"},
            {key:"horizontalThickLine",LB:"Horizontal Thick Line"},

            {key:"verticalSolidLineStart",LB:"Vertical Solid Line Start"},
            {key:"verticalSolidLineEnd",LB:"Vertical Solid Line End"},

            {key:"verticalDashedLineStart",LB:"Vertical Dashed Line Start"},
            {key:"verticalDashedLineEnd",LB:"Vertical Dashed Line End"},

            {key:"verticalThickLineStart",LB:"Vertical Thick Line Start"},
            {key:"verticalThickLineEnd",LB:"Vertical Thick Line End"},
            {key:"endBillName",LB:"End Bill Name"},
            {key:"endBillAmount",LB:"End Bill Amount"}
     ],
     grandTotal:[
            {key:"horizontalSolidLine",LB:"Horizontal Solid Line"},
            {key:"horizontalDashedLine",LB:"Horizontal Dashed Line"},
            {key:"horizontalThickLine",LB:"Horizontal Thick Line"},

            {key:"verticalSolidLineStart",LB:"Vertical Solid Line Start"},
            {key:"verticalSolidLineEnd",LB:"Vertical Solid Line End"},

            {key:"verticalDashedLineStart",LB:"Vertical Dashed Line Start"},
            {key:"verticalDashedLineEnd",LB:"Vertical Dashed Line End"},

            {key:"verticalThickLineStart",LB:"Vertical Thick Line Start"},
            {key:"verticalThickLineEnd",LB:"Vertical Thick Line End"},
            {key:"grandTotal",LB:"Grand Total"}
     ],
     footer:
        [   
            {key:"horizontalSolidLine",LB:"Horizontal Solid Line"},
            {key:"horizontalDashedLine",LB:"Horizontal Dashed Line"},
            {key:"horizontalThickLine",LB:"Horizontal Thick Line"},

            {key:"verticalSolidLineStart",LB:"Vertical Solid Line Start"},
            {key:"verticalSolidLineEnd",LB:"Vertical Solid Line End"},
 
            {key:"verticalDashedLineStart",LB:"Vertical Dashed Line Start"},
            {key:"verticalDashedLineEnd",LB:"Vertical Dashed Line End"},
 
            {key:"verticalThickLineStart",LB:"Vertical Thick Line Start"},
            {key:"verticalThickLineEnd",LB:"Vertical Thick Line End"},
             

           {key:"remark",LB:"Remark"},
           {key:"grandTotal",LB:"Grand Total"},
           {key:"totalPoint",LB:"Total Point"},
           {key:"reductCustomerPoint",LB:"Reduct Customer Point"},

           {key:"paymentType",LB:"Payment Type"},
           {key:"cash",LB:"Cash"},
           {key:"change",LB:"Change"},
           {key:"photoUrl1-0",LB:"PhotoUrl1-0"},
           {key:"photoUrl1-1",LB:"PhotoUrl1-1"},
           {key:"photoUrl1-2",LB:"PhotoUrl1-2"},
           {key:"photoUrl1-3",LB:"PhotoUrl1-3"},
           {key:"photoUrl1-4",LB:"PhotoUrl1-4"},
           {key:"photoUrl1-5",LB:"PhotoUrl1-5"},
      ],   
}
  


function PrintPageForm(props) {

const {printPageEditData,tableTemplate,pageData,
       setShow,confirmFunc2,saveTableTemplateFunc,
       calDigit,basicData,setShowPrintFunc
}=props

//console.log('PrintPageForm....')
//console.log(printPageEditData)
//console.log(props)


const genLangTemplate=(printSelection)=>{

      const langSetting=basicData.languageSetting

      let tempRenderTableTemplate
      LangTemplate.map(i=>{
            if(i.formName=="renderTableTemplate"&&
               i.formLanguage==langSetting){
                  tempRenderTableTemplate=i.template
            }
      })

      //console.log('tempRenderTableTemplate')
      //console.log(tempRenderTableTemplate)

      const changeLB=(sectionArray,myTitle)=>{
            
            let tempArray=[]
            sectionArray.map(j=>{
                  let keepChecking=true
                  let tempObj=j

                  tempRenderTableTemplate.map((k,idxK)=>{
                        if(keepChecking){
                              if(j.key==k.key){
                                    tempObj={key:j.key,LB:k.lb}
                                    //tempArray=[...tempArray,tempObj]
                                    keepChecking=false
                              }
                        }
                        
                  })
                  tempArray=[...tempArray,tempObj]
            })
            //console.log(`changeLB Before ${myTitle} = ${sectionArray.length}`)
            //console.log(`changeLB After ${myTitle} = ${tempArray.length}`)
            //console.log("=================")
            return tempArray
      }


      const tempObj={
            header:changeLB(printSelection.header,"header"),
            table:changeLB(printSelection.table,"table"),
            total:changeLB(printSelection.total,"total"),
            tableHead:changeLB(printSelection.tableHead,"tableHead"),
            tableEnd:changeLB(printSelection.tableEnd,"tableEnd"),
            grandTotal:changeLB(printSelection.grandTotal,"grandTotal"),
            endBill:changeLB(printSelection.endBill,"endBill"),
            footer:changeLB(printSelection.footer,"footer")
      }
      
      //console.log('tempObj....')
      //console.log(tempObj)
      return tempObj
}


const tempPrintSelection=genLangTemplate(printSelection)


const basicData2={
      widthUnit:["cm","%","mm"],
      fontUnit:["em","rem","px"],
      header:tempPrintSelection.header,
      table:tempPrintSelection.table,
      total:tempPrintSelection.total,
      tableHead:tempPrintSelection.tableHead,
      tableEnd:tempPrintSelection.tableEnd,
      grandTotal:tempPrintSelection.grandTotal,
      endBill:tempPrintSelection.endBill,
      footer:tempPrintSelection.footer,
}

const convertToDigit=(calDigit,mynumber)=>{
      const temp =calDigit.toString()
      const n=Array.from(temp).length-1
      return mynumber.toFixed(n)
}


const [editData,setEditData]=React.useState(null)
//const [showPrint,setShowPrint]=React.useState(false)

const [widthLeft,setWidthLeft]=React.useState(basicData.widthLeft)

//const [swapStyleRight,setSwapStyleRight]=React.useState(true)
const [printTableTemplate,setPrintTableTemplate]=React.useState(tableTemplate.printTableTemplate)

const [pageSetup,setPageSetup]=React.useState(printPageEditData)
      //genDataForTable(printPageEditData))

const [printTest,setPrintTest]=React.useState(null)

//const printSectionKey=Object.keys(pageSetup.printSection)
const {widthUnit,fontUnit,totalWidth,showTableHead,
       scale,marginTop,marginBottom,marginLeft,marginRight,
       ...remaining2
      }=pageSetup

React.useEffect(()=>{
      
      //console.log('pageSetup.............')
      //console.log(pageSetup)

      let tempHeader=[]
      let tempTable=[]
      let tempFooter=[]
      let tempTableHead=[]
      let tempTableEnd=[]
      let tempTotal=[]
      let tempEndBill=[]
      let tempGrandTotal=[]

      pageSetup.printSection.map(i=>{

            if(i.sectionName=="header"){
                  tempHeader=[...tempHeader,i]
            }
            else if(i.sectionName=="table"){
                  tempTable=[...tempTable,i]
            }
            else if(i.sectionName=="tableHead"){
                  tempTableHead=[...tempTableHead,i]
            }
            else if(i.sectionName=="tableEnd"){
                  tempTableEnd=[...tempTableEnd,i]
            }
            else if(i.sectionName=="total"){
                  tempTotal=[...tempTotal,i]
            }
            else if(i.sectionName=="endBill"){
                  tempEndBill=[...tempEndBill,i]
            }
            else if(i.sectionName=="grandTotal"){
                  tempGrandTotal=[...tempGrandTotal,i]
            }
            else{
                  tempFooter=[...tempFooter,i]
            }
      })

       
      setPrintTest({header:tempHeader,
                    tableHead:tempTableHead,
                    table:tempTable,
                    tableEnd:tempTableEnd,
                    total:tempTotal,
                    endBill:tempEndBill,
                    grandTotal:tempGrandTotal,
                    footer:tempFooter})

},[pageSetup])

React.useEffect(()=>{
      //console.log('printTest')
      //console.log(printTest)
},[printTest])

const setEditDataFunc=(value,e)=>{
      //console.log('setEditDataFunc')
      setEditData(value)
}

const setPageSetupFunc=(value)=>{
      //console.log('setPageSetupFunc')
      //console.log(value)
}

const updatePageSetupFunc=(idx,i)=>{
      
      let tempArray2=[]
      pageSetup.printSection.map((k,idx2)=>{
            if(i._id==k._id){  
                  tempArray2=[...tempArray2,i]
            }
            else{
                  tempArray2=[...tempArray2,k]  
            }
      })

      setPageSetup({...pageSetup,printSection:tempArray2})
      
}


//====================================
const deleteLine=()=>{
      if(editData){
        
          let tempArray=[]

          pageSetup.printSection.map(i=>{
            if(!i.selectedLine){
                  tempArray=[...tempArray,i] 
            }
         })

          /*
          pageSetup.printSection.map(i=>{
                  if(i._id!=editData._id){
                        tempArray=[...tempArray,i] 
                  }
          })
          */

          setPageSetup({...pageSetup,printSection:tempArray})
      }
}
//===================================
const moveUp=()=>{
      let findIdx

      if(editData){
            pageSetup.printSection.map((i,idx)=>{
                  if(i._id==editData._id){
                      findIdx=idx   
                  }
            })

            let tempArray=[]
            if(findIdx>0){
                  pageSetup.printSection.map((i,idx)=>{
                       if(idx==findIdx-1){

                       }
                       else if(idx==findIdx){
                              tempArray=[...tempArray,i,pageSetup.printSection[idx-1]] 
                       }
                       else {
                              tempArray=[...tempArray,i]
                       }
                  })
            }
            
            setPageSetup({...pageSetup,printSection:tempArray})
      }
}
//---------------------------------
const moveDown=()=>{
      let findIdx

      if(editData){
            pageSetup.printSection.map((i,idx)=>{
                  if(i._id==editData._id){
                      findIdx=idx   
                  }
            })

            let tempArray=[]

            if(findIdx<pageSetup.printSection.length-1){
                  pageSetup.printSection.map((i,idx)=>{
                       if(idx==findIdx){
                              tempArray=[...tempArray,pageSetup.printSection[idx+1],i]
                       }
                       else if(idx==findIdx+1){
                              
                       }
                       else {
                              tempArray=[...tempArray,i]
                       }
                  })
            }
            //console.log('moveDown')
            //console.log(tempArray)
            
            setPageSetup({...pageSetup,printSection:tempArray})
      }
}
//---------------------------------
const insertLine=()=>{
      const blankData={
            _id:uuid(),
            selectedLine:false,
            printFontSize:1,
            printKey:"noKey",
            printPosition:"left",
            printValue:"",
            printWidth:0,
            show:true,
            sectionName:""
      }

      let tempArray=[]

      if(editData){
            pageSetup.printSection.map((i,idx)=>{
                  if(i._id==editData._id){
                        tempArray=[...tempArray,i,blankData]
                  }
                  else {
                        tempArray=[...tempArray,i]
                  }
            })
      }
      setPageSetup({...pageSetup,printSection:tempArray})
}
//----------------------------------
const styleRight={position:"fixed",
    top:`3rem`,right:"0.5rem",zIndex:"100",
    width:"3rem",backgroundColor:"white",
    borderRadius:"10px",margin:"1rem"
}
const styleLeft={position:"fixed",
    top:`3rem`,Left:"0.5rem",zIndex:"100",
    width:"3rem",backgroundColor:"white",
    borderRadius:"10px",margin:"1rem"
}
// style={swapStyleRight?styleRight:styleLeft}>
const renderToolBox=()=>{
      return(
          <div className="" style={{width:"100%"}}>
               
              <div className="flex-center-center" style={{margin:"0.5rem 0"}}>
                  <MdPrint className="xl-icon"
                      onClick={e=>{
                        //printFunc()
                        //setReloadPrint(true)
                        //window.print()
                        setShowPrintFunc(null)
                        setTimeout(()=>{
                              setShowPrintFunc({printTest,
                                    tempTransaction,
                                    pageSetup,
                                    //totalWidth,widthUnit,fontUnit,
                                    calDigit,
                                    //dateFormat:basicData.dateFormat
                                   })
                        },[400])
                  }}
                  />
              </div>
              {editData&&
              <div className="flex-center-center" style={{margin:"0.5rem 0"}}>
                  <MdDelete className="lg-icon"
                      onClick={e=>{
                        deleteLine()
                      }}
                  />
              </div>
              }

              {editData&&
              <div className="flex-center-center" style={{margin:"0.5rem 0"}}>
                  <FaPlusCircle className="lg-icon"
                      onClick={e=>{
                          insertLine()
                      }}
                  />
              </div>
              }
              {editData&&
              <div className="flex-center-center" style={{margin:"0.5rem 0"}}>
                  <FaRegArrowAltCircleUp className="lg-icon"
                      onClick={e=>{
                          moveUp()
                      }}
                  />
              </div>
              }
              {editData&&
              <div className="flex-center-center" style={{margin:"0.5rem 0"}}>
                  <FaRegArrowAltCircleDown className="lg-icon"
                      onClick={e=>{
                          moveDown()
                      }}
                  />
              </div>
              }
              {/*
              <div className="flex-center-center" style={{margin:"0.5rem 0"}}>
                  <MdSwapHoriz className="xl-icon"
                      onClick={e=>{
                          setSwapStyleRight(!swapStyleRight)
                      }}
                  />
              </div>
              */}
             
              {/*
              <div className="flex-center-center" style={{margin:"0.5rem 0"}}>
                  <MdClose className="lg-icon"
                      onClick={e=>{
                          setEditData(null)
                      }}
                  />
              </div>
            */}
          </div>
      )
  }






//====================================
//====================================

/*
const RenderPrintOut=(inputObj)=>{
      const {printTest,tempTransaction,totalWidth,
             widthUnit,fontUnit,calDigit,
             dateFormat
            }=inputObj

            const convertToDigit=(calDigit,mynumber)=>{
                  const temp =calDigit.toString()
                  const n=Array.from(temp).length-1
                  return mynumber.toFixed(n)
            }

            const renderValue=(printKey,printValue)=>{
                
                 if(printKey=="date"){
                  //const date1=new Date(date).toISOString()
                  //const date2=new Date(date1).toLocaleString('fr-ca')
                  let tempDataFormat='en-GB'
                  if(dateFormat){   
                     const temp=dateFormat.split("format:")   
                     tempDataFormat=temp[1]    
                  }
                  const date3=new Date(printValue).toLocaleString(tempDataFormat)
                  //const date4=date2.substring(0,10)+'T'+date3.substring(12,date3.length)
                  //console.log('date3')
                  //console.log(date3) 
                  return date3
                 }
                
                 else if(printKey=="total"||printKey=="totalReduction"||
                        printKey=="reductionAmount"||printKey=="grandTotal"||
                        printKey=="totalTax"||printKey=="taxAmount"||
                        printKey=="price"||printKey=="result"||
                        printKey=="quantity"

                 ){
                    return convertToDigit(calDigit,printValue)
                 }
                 
                 else if(printKey=="phone"){
                    if(printValue){
                       if(Array.isArray(printValue)){
                             let tempPhone=''
                             printValue.map((i,idx)=>{
                                 if(idx==0){
                                    tempPhone=tempPhone+i
                                 }
                                 else {
                                    tempPhone=tempPhone+'i'+i
                                 }
                             })
                             return tempPhone
                       }   
                    }                 
                  }
                 else {
                   return printValue
                 }
            }

            const renderHeader=()=>{
                  return printTest.header.map((i,idx)=>{
                        if(i.show){
                              return ( 
                              <div key={idx} style={{width:`${i.printWidth}${widthUnit}`}}>
                                    <div style={{fontSize:`${i.printFontSize}${fontUnit}`,
                                                textAlign:i.printPosition}}>
                                    {i.printKey=="noKey"
                                    ?i.printValue
                                    :renderValue(i.printKey,tempTransaction[i.printKey])
                                    }
                                    </div>
                              </div>
                              )
                        }
                        else {
                              return null
                        }
                  })
            }
            const renderGrandTotal=()=>{
                  return printTest.footer.map((i,idx)=>{
                  if(i.show){
                        if(i.sectionName=="footer"){
                              return ( 
                              <div key={idx} style={{width:`${i.printWidth}${widthUnit}`}}>
                                    <div style={{fontSize:`${i.printFontSize}${fontUnit}`,
                                                textAlign:i.printPosition}}>
                                          {i.printKey=="noKey"
                                          ?i.printValue
                                          :renderValue(i.printKey,tempTransaction[i.printKey])
                                          }
                                    </div>
                              </div>
                              )
                        }    
                  }
                       
                  })
            }
            const renderTable=(detail)=>{
                  return detail.map((i,idx)=>{
                        return printTest.table.map((j,idx)=>{
                              return(
                              <div key={idx} style={{width:`${j.printWidth}${widthUnit}`}}>
                                    <div style={{fontSize:`${j.printFontSize}${fontUnit}`,
                                                textAlign:j.printPosition}}>
                                    {j.printKey=="noKey"||!j.printKey
                                    ?`-`
                                    :renderValue(j.printKey,i[j.printKey])
                                    //`${i[j.printKey]}`
                                    }
                                    </div>
                              </div>
                              )
                        })
                  })
            }

return(
      <div className="" 
            style={{//position:"fixed---",top:"0",left:"0",
                        margin:"0",
                        width:`${totalWidth}${widthUnit}`,
                        display:"flex",    
                        flexWrap:"wrap",
                        border:"dashed 1px white",
                        borderRadius:"0.3rem"
                        }}>
            {
                  renderHeader()//renderArray(header1)
            }
            
            { //showTableHead&&
                  renderTable(tempTransaction.detail)
            }
            {
                  renderGrandTotal()   
            }
      </div>
)
}
*/
//====================================


//====================================
const renderForm=()=>{
      //const temp=Object.keys(pageSetup)

      //console.log('pageSetup Key')
      //console.log(temp)
      const temp=[
      "printSection",
      "_id",
      "newId",
      "printPageName",
      "widthUnit",
      "fontUnit",
      "totalWidth",
      "showTableHead",
      "usePageBreak",
      "linePerPage",
      "limitCharPerLine",
      "rowSpacing",
      "columnSpacing",
      "scale",
      "marginTop",
      "marginBottom",
      "marginLeft",
      "marginRight",
      "paddingTop",
      "paddingBottom",
      "paddingLeft",
      "paddingRight",
      "printId"
      ]
      
      return temp.map((i,idx)=>{
    
          if(i=="widthUnit"||i=="fontUnit") {
                return (    
                <div key={idx} className="flex-center-center jc-start w-50" 
                      style={{padding:"0.3rem",display:"flex",justifyContent:"flex-start",alignItems:"center"}} >
                      <div className="xc4">
                            {pageData.printForm[i]}
                      </div>
                      <select className="xc8">
                        {
                            basicData2[i].map((j,idx2)=>(
                                  <option key={idx2}
                                          selected={j==pageSetup[i]?"selected":""}
                                  >{j}</option>
                            ))     
                        }
                      </select>
                </div>
                )
          }
          else if(i=="totalWidth"||i=="rowSpacing"||
                  i=="scale"||i=="marginTop"||i=="marginBottom"||
                  i=="marginLeft"||i=="marginRight"||i=="newId"

          ){
                return (    
                      <div key={idx} className="flex-center-center jc-start w-50" 
                            style={{padding:"0.3rem",display:"flex",justifyContent:"flex-start",alignItems:"center"}} >
                            <div className="xc4">
                                  {pageData.printForm[i]}
                            </div>
                            <input className="xc8"
                                  value={pageSetup[i]}
                                  type="number"
                                  onChange={e=>setPageSetup({...pageSetup,
                                        [i]:e.target.value
                                  })}
                            />
                      </div>
                )
          }
          else if(i=="showTableHead"){
                return null
                /*
                (    
                <div key={idx} className="flex-center-center jc-start w-50" 
                      style={{padding:"0.3rem",display:"flex",justifyContent:"flex-start",alignItems:"center"}} >
                      <div className="xc4">
                            {pageData.printForm[i]}
                      </div>
                      <div className="xc8">
                            <input 
                                  type="checkbox"
                                  style={{height:"1.4rem",padding:"0",margin:"0"}}
                                  checked={pageSetup[i]}
                                  
                                  onChange={e=>setPageSetup({...pageSetup,
                                        [i]:e.target.checked
                                  })}
                            />
                      </div>
                </div>
                )
                */
          }
          else if(i=="usePageBreak"){
            return (
            <div key={idx} className="flex-center-center jc-start w-50" 
                  style={{padding:"0.3rem",display:"flex",justifyContent:"flex-start",alignItems:"center"}} >
                  <div className="xc4">
                        {pageData.printForm[i]}
                  </div>
                  <div className="xc8">
                        <input
                              
                              type="checkbox"
                              checked={pageSetup[i]}
                              onChange={e=>setPageSetup({...pageSetup,
                                    [i]:e.target.checked
                              })}
                        />
                  </div>
                 
            </div>
            )
          }

          else if(i=="linePerPage"||i=="limitCharPerLine"){
                return pageSetup.usePageBreak    
                      ?<div key={idx} className="flex-center-center jc-start w-50" 
                            style={{padding:"0.3rem",display:"flex",justifyContent:"flex-start",alignItems:"center"}} >
                            <div className="xc4">
                                  {pageData.printForm[i]}
                            </div>
                            <input className="xc8"
                                  value={pageSetup[i]}
                                  type="number"
                                  onChange={e=>setPageSetup({...pageSetup,
                                        [i]:e.target.value
                                  })}
                            />
                      </div>
                      :null
                
          }   

          else if(i=="printPageName"){
            return (
                  <div key={idx} className="flex-center-center jc-start w-50" 
                        style={{padding:"0.3rem",display:"flex",justifyContent:"flex-start",alignItems:"center"}} >
                        <div className="xc4">
                              {pageData.printForm[i]}
                        </div>
                        <div className="xc8">
                              <input
                                    
                                    type="text"
                                    value={pageSetup[i]}
                                    onChange={e=>setPageSetup({...pageSetup,
                                          [i]:e.target.value
                                    })}
                              />
                        </div>
                       
                  </div>
            )

          }

       })
    }

//================================

//===============================
const renderFooter=()=>{
      return(
      <div  className=""
            style={{display:"flex",
                   position:"fixed",
                   bottom:"1rem",right:"2rem",zIndex:"100"}}
      >
          <div>
              <button
                  style={{visibility:confirmFunc2?"visible":"hidden"}}    
                  onClick={e=>{
                     
                        if(confirmFunc2){
                          confirmFunc2(pageSetup)
                        }

                  }}
              >
                  <FaCheck/>
              </button>
          </div>
          
          <div>
              <button
                   onClick={e=>{
                      setShow(false)
                   }}
              >
                  <FaBan/>
              </button>
          </div>
      </div>
      )
   }

//===============================
const genWidth=()=>{
      //const tempWidth=window.innerWidth
      //console.log(tempWidth)
}
genWidth()

const setWidthLeftFunc=(data)=>{
      let temp
      if(data>97){
            temp=97
      }
      else{
          temp=data
      }
      setWidthLeft(temp)
}

//===============================
return(
<div style={{width:"100%",height:"100%"}}>

      <div style={{width:"100%",height:"5%"}}>
            {
            renderWidthRangeBar({showRange:true,
                                setShowRange:()=>{},
                                widthLeft:widthLeft,
                                setWidthLeft:setWidthLeftFunc,
                            })
            }      
      </div>

      <div style={{width:"100%",height:"95%",display:"flex"}}>
                        
            <div className='bd-pureWhite' 
                 style={{width:`${widthLeft}%`,height:"100%",overflow:"auto"
                 }}
            >
                  {
                  printTest&&
                   RenderPrintOut({printTest,
                                  tempTransaction,
                                  //totalWidth,widthUnit,fontUnit,
                                  pageSetup,
                                  calDigit,
                                  basicData,
                                  //editData
                                  //dateFormat:basicData.dateFormat
                                 })
                  }
            </div>

            <div style={{minWidth:"2.5rem"}}>
                  {
                  renderToolBox()   
                  }
            </div>

                  
            <div className="" 
                  style={{width:`${97-widthLeft}%`,height:"100%",
                  paddingBottom:"4rem",overflow:"scroll"
                  }}
            >
                  <div style={{
                              width:"100%",
                              display:"flex",
                              alignItems:"center"
                        }}
                  >
                        <div style={{margin:"0 0 0 1rem"}}>
                              <MdOutlineArticle
                                    style={{fontSize:"2rem"}}
                              />
                        </div>
                        <div style={{margin:"0 1rem"}}>
                              {`=  ${printPageEditData.printId}`}
                        </div>
                    
                  </div>
                  {
                  renderForm()
                  }
                     
                  <div className="w-100"
                        style={{display:"flex"}}
                  >
                        {//true&& //printTableTemplate&&
                        <Table
                              tableTemplate={printTableTemplate}
                              setTableTemplate={setPrintTableTemplate}
            
                              filterData={pageSetup.printSection}//{filterData.data0}
                              setFilterData={setPageSetupFunc}//{setFilterDataData0}
                              
                              editData={editData}//{filterData.editData}
                              setEditData={setEditDataFunc}//{setEditData}
                              saveTableTemplateFunc={saveTableTemplateFunc}
                              isSubTable={false}
                              updateFilterData={updatePageSetupFunc}
                              useInput={true}
                              pageData={pageData.tableSettingModal}
                              basicData={basicData2}
                        />
                        }
                       
                  </div>
                 
            </div>
                  
           
      
            <div className="hide-on-print">
                  {
                  renderFooter()
                  }
            </div>
      </div>

</div>
)

}



export default PrintPageForm;

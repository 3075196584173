
import React from 'react';
import BackEndNavbar from '../../component/navbar/BackEndNavbar';
import {MainContext} from '../../context/MainContext'
import PageComponentBranchForAdmin  from '../../component/pageComponent/PageComponentBranchForAdmin';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import console from '../../util/consoleVerify'


function BranchForAdmin() {
const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
       mainContextBranch,setMainContextBranch
}=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
    <div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
        <div className="h-5 w-100">
            <BackEndNavbar/>
        </div>
        <div className="h-95 w-100">
            <PageComponentBranchForAdmin
                setReloadBasicData={setReloadBasicData}
                filterDataTemplate={filterDataTemplate}
                filterTemplate={basicDataSt.pageFilter.branchForAdminFilter}
                inputState={inputState.branchForAdminInputState}
                basicDataSt={basicDataSt}
                myheader={myheader}
                
              
                pageFilterForm={basicDataSt.pageFilterForm.branchForAdminFilterForm}

                modalFormTemplate={basicDataSt.pageData.ModalForm}
                
                bgColor={"yellow"}
                calDigit={100}
                controller={"getlimitbyadmin"}
                mainContextData={mainContextBranch}
                setMainContextData={setMainContextBranch}
            />

        </div>
    </div>
)



}
export default BranchForAdmin;

/*
  inputState,
      filterTemplate,
      myheader,
      basicDataSt,
      stateTemplate,

      bgColor,
      setReloadBasicData,
      controller,



*/
import React from 'react';
import axios from '../../util/axiosVerify'
//import {MainContext} from '../../context/MainContext'
import uuid from 'react-uuid'
import Navbar from '../../component/navbar/Navbar';

import ModalFilterInput from '../../render/renderFilter/ModalFilterInput'
import pageUtil from './pageUtil'
//import NewGroupComponent from '../../render/renderTree/NewGroupComponent'
import axiosUtil from '../../util/axiosUtil'
import Table from '../../component/table/Table'
import renderWidthRangeBar from '../../component/table/renderWidthRangeBar'
import renderBadge from '../../render/renderBadge/renderBadge'
import renderModalError from '../../render/renderModalError'
import ModalConfirm from '../../render/ModalConfirm'
//import ModalForm from '../../render/renderForm/ModalForm'
//import NewTree from '../../component/newtree/NewTree'
import NewGroup from '../../component/newgroup/NewGroup'

import GroupPageForm from '../../render/renderForm/GroupPageForm'
//import ModalCsv from './ModalCsv'
import renderTransactionConfirm from '../../page/bill/renderTransactionConfirm';
import {FaCheck,FaBan,FaFolder} from 'react-icons/fa';
import console from '../../util/consoleVerify'
import renderGrid from '../../render/renderForm/renderGrid'

const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,catchErrorToMessage}=axiosUtil

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete,
    genCsv,genXlsx
} = pageUtil

function PageComponentGroup(props) {

//console.log('PageComponentGroup')
//const {basicDataSt,tokenSt,setReloadCheckToken,myheader}=React.useContext(MainContext)

const {filterDataTemplate,filterDataTemplate2,filterDataTemplate3,
      pageFilter,pageFilterForm,...remaining}=props



const {filterTitle,inputState,filterTemplate,myheader,basicDataSt,
      formTemplate,editFormTemplate,stateTemplate,
      groupFormTemplate,groupEditFormTemplate,
      pageDataModalForm,
      addFormTitle,
      editFormTitle,
      groupAddFormTitle,
      groupEditFormTitle,
      bgColor,
      calDigit,
      setReloadBasicData,
      controller,
      mainContextGroupFilterData,
      setMainContextGroupFilterData
}=props

const {basicData,pageData,tableTemplate,reloadBasicData,user}=basicDataSt
//console.log('tableTemplate............dddddddddd')
//console.log(tableTemplate)
const refSecrete=React.createRef()

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const [billTableTemplate,setBillTableTemplate]=React.useState(null)

React.useEffect(()=>{
    if(tableTemplate){    
        setBillTableTemplate(tableTemplate.productDetailTableTemplateForForm)
    }
},[basicDataSt])

const defaultFilterData={
    ...filterDataTemplate,...remaining,
    tableTemplate:
        filterDataTemplate.tableTemplateName
        ?tableTemplate[filterDataTemplate.tableTemplateName]
        :null,
    detailTableTemplate:
        filterDataTemplate.detailTableTemplateName
        ?tableTemplate[filterDataTemplate.detailTableTemplateName]
        :null,
    detailTableTemplateForForm:
        filterDataTemplate.detailTableTemplateForFormName
        ?tableTemplate[filterDataTemplate.detailTableTemplateForFormName]
        :null,
    inputState:inputState,
    widthLeft:basicData.widthLeft,
    limitRow:basicData.limitRow,
    isCsv:false,
    isXlsx:false,
    swapState:basicData.showTable
}


const genFilterData=()=>{
    if(mainContextGroupFilterData){
        return mainContextGroupFilterData
    }
    else {
        return defaultFilterData
    }
}

let [filterData,setFilterData]=React.useState(genFilterData())

const setMainContextFilterDataFunc=()=>{
    //console.log('setMainContextFilterDataFunc')
    //console.log(filterData)
    setMainContextGroupFilterData(filterData)
}


React.useEffect(()=>{
    //console.log('UserEffect ..... filterData PageComponentGroup')
    //console.log(filterData.editData)

    if(filterData.reloadData){
        const {dataUrl,pageNumber,limitRow,sort,qry}=filterData
        //let tempController="getcustom"
        let tempController="getlimit"       
        //console.log(qry)

        axios.post(`/${dataUrl}/${tempController}`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
        .then(result=>{
            //console.log('result....eeeeeeeee........')
            //console.log(result)
            const temp2= genFilterDataWithIndex(result.data.data,filterData.selectProduct)
            //console.log('temp2......eeeeeeeee.......')

            //console.log(temp2)
            const tempResult={...filterData,
                data0:temp2,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                isCsv:false,
                isXlsx:false,
                reloadData:false,
                
            }

            if(filterData.isCsv)
            {
                //genCsv(tempResult,filterData.tableTemplateName)
                setFilterData(tempResult)
            }
            else if(filterData.isXlsx){
                //genXlsx(tempResult,filterData.tableTemplateName)
                setFilterData(tempResult)
            }
            else{
                setFilterData(tempResult)

            }

        })
        .catch(error=>{
            const tempError={...filterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterData(tempError)
        })
    }

    if(filterData.isCsv){
        //console.log('isCsv')
        genCsv({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isCsv:false})
    }
    if(filterData.isXlsx){
        //console.log('isXlsx')
        genXlsx({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isXlsx:false})
    }



},[filterData])

/*
React.useEffect(e=>{
    if(filterData.editData){
        const tempBadgeState={
            ...filterData.badgeState,

        }


    }
},[filterData.editData])
*/


const setShowRange=(data)=>{setFilterData({...filterData,showRange:data})}
const setHeightTop=(data)=>{setFilterData({...filterData,heightTop:data})}
const setWidthLeft=(data)=>{setFilterData({...filterData,widthLeft:data})}
const setShowModalError=(data)=>{setFilterData({...filterData,showModalError:data})}
const setShowModalConfirm=(data)=>{setFilterData({...filterData,showModalConfirm:data})}
const setPageNumber=(data)=>{setFilterData({...filterData,pageNumber:data,reloadData:true})}

const setIsCsv=(data)=>{
    if(data){
        setFilterData({...filterData,isCsv:true,isXlsx:false})
    }
    else{
        setFilterData({...filterData,isCsv:false})
    }
}
const setIsXlsx=(data)=>{
    if(data){
        setFilterData({...filterData,isXlsx:true,isCsv:false})
    }
    else{
        setFilterData({...filterData,isXlsx:false})
    }
}

const setShowEdit=(data)=>{
    //console.log('setShowEdit')
    //console.log(data)
    setFilterData({
        ...filterData,
        showEdit:data
    })
}



const setTableTemplate=(data)=>{setFilterData({...filterData,tableTemplate:data})}
const setDetailTableTemplate=(data)=>{setFilterData({...filterData,detailTableTemplate:data})}



const setEditData=(data)=>{
    let tempData=data
    //console.log('data..........')
    //console.log(data)
    setFilterData({...filterData,editData:tempData})
    //captureEditData(data)
}
//to save filterData when sort Up and down each column
const setFilterDataData0=(data)=>{setFilterData({...filterData, data0:data})}
const setSort=(data)=>{setFilterData({...filterData,sort:data})}
const setLimitRow=(data)=>{setFilterData({...filterData,limitRow:data})}
const setInputState=(data)=>{setFilterData({...filterData,inputState:data})}
const setShowModalFilter=(data)=>{setFilterData({...filterData,showModalFilter:data})}


const setSwapState=(data)=>{
    //console.log('setShowAdd')
    //console.log(data)
    setFilterData({
        ...filterData,
        swapState:data
    })
}


const setSelectGroupFunc=(data)=>{
    //console.log('selectGroupFunc....')
    //console.log(data)..................
    setFilterData({...filterData,
        selectGroup:data,
        editData:data,
        data0:[data]
    })
}

const setUnSelectAllFunc=()=>{
    setFilterData(setUnSelectAll(filterData))
}

const genFilterDataWithIndex=(filterData,selectProduct)=>{

    if(filterData){
        if(filterData.detail){
            if(filterData.detail.length()>0){
                let tempArray=[]

                filterData.detail.map(i=>{

                })

            }
        }
    }

    let tempSelectProduct
    if(selectProduct){tempSelectProduct=selectProduct}
    else {tempSelectProduct=[]}

    let tempArray=[]

    filterData.map((i,idx)=>{
        //const temp={...i,tempIndex:idx,selectedLine:false}
        let tempStocking={}

        if(i.stocking){
            let isSearching=true
            i.stocking.map(k=>{
                if(isSearching){
                    if(k.branchId==basicData.branchId){
                        tempStocking={
                            stock:k.stock,
                            minStock:k.minStock,
                            maxStock:k.maxStock,
                          
                        }
                        isSearching=false
                    }
                }   
            })
        }


        const temp={...i,selectedLine:false,temp_id:i._id,_id:uuid(),...tempStocking}
        tempArray=[...tempArray,temp]

    })

    let tempArray2=[]

    tempArray.map(i=>{
        let tempObj=i
        tempSelectProduct.map(j=>{
            if(i.id==j.id){
                //console.log('j.id==i.id')
                tempObj={...tempObj,selectedLine:j.selectedLine}
                //tempArray2=[...tempArray2,]
            }
        })
        tempArray2=[...tempArray2,tempObj]
    })
 
    return tempArray2
}

const refreshPage=()=>{
    //console.log('refreshPage')
    const {limitRow,sort}=filterData
    const temp = {...filterData, limitRow,sort,selectProduct:[],
                editData:null,qry:{},reloadData:true
            }
    setFilterData(temp)
    setReloadBasicData(true)
}


const submitFunctionDeleteFunc=async(data)=>{
    submitFunctionDelete(filterData,myheader,shouldRender)
    .then(result=>setFilterData(result))
    .catch(error=>setFilterData(error))
}

const submitFunctionEditFunc=async (formInputState)=>{

    let tempFormInputState=formInputState
    //console.log('submitFunctionEditFunc')
    //console.log(tempFormInputState)
    if(formInputState){
        if(formInputState.file1){
            if(formInputState.file1.length){
                submitFunctionEdit(tempFormInputState,filterData,stateTemplate,myheader,shouldRender)
                .then(result=>{
                    setFilterData(result)
                })
                .catch(error=>setFilterData(error))
            } 
        }
    }
    
}


//{pageNumber,limitRow,sort,dataUrl} = filterData
const filterAxiosFunc=(option,inputState)=>{
    //console.log('filterAxiosFunc..............')

    let tempController=null

    if(controller){
        tempController=controller
    }

    //console.log(filterTemplate)
    filterAxios(option,inputState,filterTemplate,
                filterData,tempController,myheader,
                shouldRender,user.branchId)
    .then(result=>{
        //console.log("result....yyyyyyyyy.......")
        //console.log(result)
        
        const temp2= genFilterDataWithIndex(result.data0,
                     filterData.selectProduct)
        //console.log('result...yyyyyyy.......')
        //console.log(temp2)

        if(filterData.isCsv)
        {
            //genCsv({...result},filterData.tableTemplateName)

            setFilterData({...result,
                isCsv:false,
                data0:temp2
            })
        }
        else if(filterData.isXlsx){
            //genXlsx({...result},filterData.tableTemplateName)

            setFilterData({...result,
                isXlsx:false,
                data0:temp2
            })
        }
        else{
            setFilterData({...result,
                data0:temp2
            })
        }
    })
    .catch(error=>setFilterData(error))
}


const filterDataByGroup=(i)=>{
    //console.log('filterDataByGroup.........')
    //console.log(i)
    //const {folders,...remaining}=i
    /*
    const temp = {...filterData,
        pageNumber:1,
        qry:{groupId:i.id},
        //selectGroup:remaining,
        reloadData:true}
    setFilterData(temp)
    */
}

const clickRefReload=(filterData)=>{
    
    setFilterData({...filterData,
        reloadData:true,
        showEdit:false
    
    })
    //refReload.current.click()
}

const saveTableTemplateFunc=(tableTemplate)=>{
    //console.log('saveTableTemplateFunc')
    //console.log(filterData)
    const {tableTemplateUrl}=filterData
    saveTableTemplate(tableTemplate,tableTemplateUrl,filterData.tableTemplateName,myheader,shouldRender)
}


const updateFilterDataFunc=(index,i)=>{
    //console.log('updateFilterDataFunc')
    const temp=updateFilterData(index,i,filterData)
    //console.log('temp')
    //console.log(temp)
    setFilterData({...filterData,...temp})
}

const closeBillForm=()=>{
    setFilterData({...filterData,
        showAdd:false,showEdit:false
    })
}
//----------------------------
const transactionConfirmSubmitFunc=()=>{
    let tempEditData=filterData.editData

    if(!filterData.includeTransactionHead){
        tempEditData={...filterData.editData,
            detail:[...filterData.editData.detail,
                    ...filterData.editData.detail
                    ]
        }
    }

    setFilterData({...filterData,
        data1:tempEditData,
        showTransactionConfirm:false,
        showBillForm1:false,
        reloadBillForm1:true
    })
  
}

const transactionConfirmCancelFunc=()=>{
    setFilterData({...filterData,
        showTransactionConfirm:false
    })
}

//=============================
const genCsvFunc=()=>{
    genCsv(filterData)
}
//-----------------------------
const renderTable=()=>{

    //console.log('renderTable')
    //console.log(filterData)

    return (
            filterData.data0
            ?<Table
                colorHead={"#4b6d62"}
                tableTemplate={filterData.tableTemplate}
                setTableTemplate={setTableTemplate}

                filterData={filterData.data0}
                setFilterData={setFilterDataData0}
                
                editData={filterData.editData}
                setEditData={setEditData}
                saveTableTemplateFunc={saveTableTemplateFunc}
                isSubTable={false}
                updateFilterData={updateFilterDataFunc}
                useInput={false}
                
            />
            :null
    )
}

//-----------------------------
const renderFilter=()=>{
    const {limitRow,sort,isCsv,isXlsx,inputState,showModalFilter}=filterData
    return (
        <div className="" style={{marginBottom:"1rem"}}>
            <ModalFilterInput
                    show={showModalFilter} 
                    setShow={setShowModalFilter}
                    
                    filterTemplate={filterTemplate}

                    inputState={inputState} 
                    setInputState={setInputState}
                    
                    limitRow={limitRow} 
                    setLimitRow={setLimitRow}

                    isCsv={isCsv}
                    setIsCsv={setIsCsv}

                    isXlsx={isXlsx}
                    setIsXlsx={setIsXlsx}
                    
                    sort={sort} 
                    setSort={setSort}
                    
                    filterAxios={filterAxiosFunc}
                    basicData={basicData}
                    
                    LB={pageFilterForm}
            />
        </div>
    )
}
//-----------------------------
const renderEditForm=()=>{
    return (
        <GroupPageForm
            lb="aaa"
            loadData={filterData.editData}
            setShow={setShowEdit}
            submitFunction={submitFunctionEditFunc}
            pageData={pageData}
            stateTemplate={stateTemplate}
            myheader={myheader}
            filterData={filterData}
            clickRefReload={clickRefReload}
        />
    )
}

//-----------------------------
const renderGroup=()=>{

    return (
    <div className="w-100" 
         style={{ooverflowX:"hidden",
                 paddingBottom:"5rem"}}>
        {
            renderFilter()
        }
       
        {
        filterData.groupDataUrl&&pageData
        ?<div className="" style={{margin:"1rem 0"}}>
            <NewGroup
                myheader={myheader}
                dataUrl={filterData.groupDataUrl}
                filterByGroupId={filterDataByGroup}
                captureEditGroup={setSelectGroupFunc}
                bgColor={bgColor}
                pageData={pageData}
                basicData={basicData}
            />
        </div>
        :null
        }

       
    </div>
    )
}
//------------------------
//-----------------------------
const renderRight=(filterData)=>{
    //console.log('renderRight')
    //console.log(renderRight)

    return(
    <div className="w-100 h-100">
        <div className="hide-on-printX"
            style={{height:filterData.showAdd||filterData.showEdit
                    ?"0rem"
                    :"5%",
                    width:"100%"
                }}
        >
            { !filterData.showEdit
              ?renderBadge({
                        filterData,
                        setPageNumber:setPageNumber,
                        
                        totalSwapPage:1, 
                        setSwapState:setSwapState,
                        
                        setReloadData:refreshPage,
                        setShowFilter:()=>{},
                        setShowAdd:()=>{},//setShowAdd,
                        setShowEdit:setShowEdit,
                        setShowModalConfirm:setShowModalConfirm,
                        
                        setShowModalCsv:()=>{},//genCsvFunc,
                        //csvFunc:()=>{//console.log('csvFunc')},


                        setUnSelectAll:setUnSelectAllFunc,
                        setShowForwardConfirm:(value)=>{setFilterData({...filterData,showTransactionConfirm:value})},
                        setClose:()=>{},
                        bgColor:bgColor,
                        captureSelect:()=>{}//captureSelect
                    })        
                :null
            }

        </div>

        <div className=""
                style={{width:"100%",
                        height:filterData.showEdit
                            ?"100%"
                            :"95%",
                        }}
        >
          
                { filterData.showEdit
                 ?renderEditForm()
                 :filterData.swapState
                    ?renderTable()
                    :renderGrid(filterData,tableTemplate,setEditData,updateFilterDataFunc)
                    
                }
            
        </div>
    </div>
    )
}
//=============================
//==============================

const renderBody=()=>{
return(
    filterData&&
    <div className=""
             style={{display:"flex",height:"95%",width:"100%"}}
    >
            
            <div className="hide-on-printX" 
                 style={{height:"100%",
                         width:`${filterData.widthLeft}%`,
                         overflowY:"scroll",
                         overflowX:"hidden"
                         }}>
                     {
                       renderGroup()
                     }
            </div>
        
            <div className="" 
                style={{height:"100%",width:`${100-filterData.widthLeft}%`}}>
                 {
                   renderRight(filterData)  
                 }
                 
            </div>
    </div>
)
}

//===============================
//=================================
return(
<div className="w-100 h-100">
            <button style={{display:"none"}}
                ref={refSecrete}
                onClick={e=>{
                    setMainContextFilterDataFunc()
                }}
            >
                secrete
            </button>  

            <div className="h-5 w-100"
            
            >
                <Navbar 
                    setMainContextFilterData={()=>refSecrete.current.click()}
                />
            </div>
    

            <div className="w-100 h-95">
                {   
                renderBody()    
                }
            
                <div className="" 
                    style={{width:"100%",display:"flex",position:"relative"}}>
                        {
                        renderWidthRangeBar({showRange:filterData.showRange,
                                            setShowRange:setShowRange,
                                            widthLeft:filterData.widthLeft,
                                            setWidthLeft:setWidthLeft,
                                            //heightTop:filterData.heightTop,
                                            //setHeightTop:setHeightTop
                                        })
                        }
            
                </div>


                {filterData.showTransactionConfirm&&
                renderTransactionConfirm({
                    editTransaction:filterData.editData,
                    submitFunc:transactionConfirmSubmitFunc,
                    cancelFunc:transactionConfirmCancelFunc,
                    pageData:pageData,
                    changeIncludeTransactionHead:()=>{},//changeIncludeTransactionHead,
                    includeTransactionHead:filterData.includeTransactionHead
                    })
                }
                
                {
                filterData.showModalConfirm
                ?<ModalConfirm
                    setShow={setShowModalConfirm}
                    submitFunction={submitFunctionDeleteFunc}
                />
                :null
                }

                {  
                filterData.showModalError
                ?renderModalError({
                    setShow:setShowModalError,
                    message:filterData.message
                })
                :null
                }
            </div>
</div>
)

}




export default PageComponentGroup ;


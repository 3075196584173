
import React from 'react';
import {MainContext} from '../../context/MainContext'
import BackEndNavbar from '../../component/navbar/BackEndNavbar';

import PageComponent  from '../../component/pageComponent/PageComponent';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'

import console from '../../util/consoleVerify'


function StickerForAdmin() {


const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
       mainContextShop,setMainContextShop
}=React.useContext(MainContext)
const {basicData}=basicDataSt

return (
<div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
    <div className="h-5 w-100">
        <BackEndNavbar/>
    </div>

    <div className="h-95 w-100">
        <PageComponent
            setReloadBasicData={setReloadBasicData}
            filterDataTemplate={filterDataTemplate}
            filterTemplate={basicDataSt.pageFilter.stickerForAdminFilter}
            inputState={inputState.stickerForAdminInputState}
            basicDataSt={basicDataSt}
            myheader={myheader}
            
            formTemplate={null}//{basicDataSt.modalFormTemplate.shopForm}
            editFormTemplate={null}//{basicDataSt.modalFormTemplate.shopForm}
            
            stateTemplate={StateTemplate.stickerForAdminState}
            pageFilterForm={basicDataSt.pageFilterForm.stickerFilterForm}

            modalFormTemplate={null}//{basicDataSt.pageData.ModalForm}
            addFormTitle={null}//{basicDataSt.pageData.ModalForm.shopAddForm}
            editFormTitle={null}//{basicDataSt.pageData.ModalForm.shopEditForm}

            bgColor={"#fbded6"}
            calDigit={100}
            controller={"getlimitbyadmin"}
            mainContextData={null}//{mainContextShop}
            setMainContextData={()=>{}}//{setMainContextShop}

        />
      
    </div>
</div>
)

}
export default StickerForAdmin;
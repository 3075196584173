const LogInLangTemplate={
Thai:
{
    shopLogInForm:{
       formHead:"ล็อคอินร้าน",
       shopName:"ชื่อร้าน",
       password:"รหัส"
    },
    shopPaymentShopLogInForm:{
      formHead:"ล็อคอินร้านก่อนชำระค่ารายเดือน",
      shopName:"ชื่อร้าน",
      password:"รหัส"
   },
    userLogInForm:{
       formHead:"ล็อกอินผู้ใช้",
       username:"ชื่อผู้ใช้",
       password:"รหัส",
       createNewUser:"สร้างผู้ใช้"
    },
    userChangePasswordForm:{
       formHead:"เปลี่ยนรหัสผู้ใช้",
       username:"ชื่อผู้ใช้",
       password:"รหัส",
       newPassword1:"รหัสใหม่",
       newPassword2:"ยืนยันรหัสใหม่"
    },
    shopSignUpForm:{
       formHead:"สมัครร้านใหม่",
       shopName:"ชื่อร้าน",
       password:"รหัส",
       confirmPassword:"ยืนยันรหัส",
       ownerName:"ชื่อเจ้าของร้าน",
       ownerEmail:"อีเมลเจ้าของร้าน",
       dataLanguage:"เลือกภาษา"

    },
    shopForgetPasswordForm:{
       formHead:"ตั้งรหัสร้านใหม่",
       shopForgetPasswordInfo:"รหัสชั่วคราวจะถูกส่งไปยังอีเมลท่าน ใช้มันสร้างรหัสร้านใหม่",
       recoveryPassword:"รหัสชั่วคราว",
       shopName:"ชื่อร้าน",
       password:"รหัส",
       confirmPassword:"ยืนยันรหัส"
    },
    shopLogInPage:{
       formHead:"ล็อกอินร้าน",
       createNewShopAccount:"สมัครร้านใหม่",
       forgetShopPassword:"ลืมรหัสร้าน",
       changeUserPassword:"แก้ไขรหัสผู้ใช้",
       requestForRecoveryPassword:"ขอรหัสชั่วคราว",
       shopPayment:"ชำระค่ารายเดือน"
    },
    
    shopLogInForPaymentForm:{
      formHead:'ล็อกอินร้านก่อน',
      shopName:'ชื่อร้าน',
      password:'รหัส'
   },
   
    shopMonthlyFeeForm:{
         formHead:'ส่งสลิปการโอนเงิน',
         shopName:'ชื่อร้าน',
         expirationDate:'วันหมดอายุ',
         monthlyFee:'ค่าใช้จ่ายรายเดือน',
   },  
   confirmBeforeShopLogoutForm:{
         formHead:'ยืนยันเพื่อล็อกเอ้าออกจากบัญชีร้าน'
   },
   requestRecoveryPasswordForm:{
         formHead:'ร้องขอรหัสชั่วคราว',
         shopName:'ชื่อร้าน',
         ownerEmail:'อีเมลเจ้าของร้าน'
   },
   placeHolderForm:{
         placeHolder:'พิมพ์ตัวอักขระที่เห็นในรูป'
   },
   tryAccountForm:{
         tryAccount:'คลิกที่นี่เพื่อใช้บัญชีทดลอง'
   },
   transferForm:{
         instruction:'กรุณาโอนเงินเข้าบัญชีด้านล่างพร้อมส่งหลักฐานการโอนเงิน',
         bankName:'ชื่อธนาคาร',
         accountName:'ชื่อบัญชี',
         accountNumber:'หมายเลขบัญชี'
   }
},
English:
{
    shopLogInForm:{
       formHead:"Shop Log In",
       shopName:"Shop Name",
       password:"Password"
    },
    shopPaymentShopLogInForm:{
      formHead:"Shop Log In Before Pay Monthly Fee",
      shopName:"Shop Name",
      password:"Password"
   },
    userLogInForm:{
       formHead:"User Log In",
       username:"User Name",
       password:"Password",
       createNewUser:"Create Default User"
    },
    userChangePasswordForm:{
       formHead:"User Change Password",
       username:"User Name",
       password:"Password",
       newPassword1:"New Password",
       newPassword2:"Confirm New Password"
    },
    shopSignUpForm:{
       formHead:"Shop Sign Up",
       shopName:"Shop Name",
       password:"Password",
       confirmPassword:"Confirm Password",
       ownerName:"Owner Name",
       ownerEmail:"Ower Email",
       dataLanguage:"Language Selection"
   },
   shopForgetPasswordForm:{
       formHead:"Set New Shop Password ",
       shopForgetPasswordInfo:"Recovery Password will be sent to your Email, use it to create new shop password",
       recoveryPassword:"Recovery password",
       shopName:"Shop Name",
       password:"Shop Password",
       confirmPassword:"confirm Shop Password"
    },
    shopLogInPage:{
       formHead:"",
       createNewShopAccount:"Create a New Shop Account",
       forgetShopPassword:"Forget Shop Password",
       changeUserPassword:"Change User Password",
       requestForRecoveryPassword:"Request For Recovery Password",
       shopPayment:"Pay Monthly Fee"
      
    },
    
    shopLogInForPaymentForm:{
         formHead:'Shop Log In Before Payment',
         shopName:'Shop Name',
         password:'Password'
      }, 
      shopMonthlyFeeForm:{
         formHead:'Update Your Payment Slip',
         shopName:'Shop Name',
         expirationDate:'Expiration Date',
         monthlyFee:'Monthly Fee',
      },
      confirmBeforeShopLogoutForm:{
         formHead:'Confirm to Log Out of Shop'
      },
      requestRecoveryPasswordForm:{
         formHead:'Request Recovery Password',
         shopName:'Shop Name',
         ownerEmail:'Shop Owner Email'
      },
      placeHolderForm:{
         placeHolder:'Type characters that you see in the box'
      },
      tryAccountForm:{
         tryAccount:'Click here to try account'
      },
      transferForm:{
         instruction:'Please transfer money to account below and send transfer slip',
         bankName:'Bank Name',
         accountName:'Account Name',
         accountNumber:'Account Number'
   }
}
}

export default LogInLangTemplate
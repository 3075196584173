import React from 'react';
import axios from '../../util/axiosVerify'
//import {MainContext} from '../../context/MainContext'
import uuid from 'react-uuid'
import Navbar from '../../component/navbar/Navbar';

import ModalFilterInput from '../../render/renderFilter/ModalFilterInput'
import pageUtil from './pageUtil'
import axiosUtil from '../../util/axiosUtil'
import Table from '../../component/table/Table'
import renderWidthRangeBar from '../../component/table/renderWidthRangeBar'
import renderBadge from '../../render/renderBadge/renderBadge'
import renderModalError from '../../render/renderModalError'
import ModalConfirm from '../../render/ModalConfirm'
import ModalConfirmReload from '../../render/ModalConfirmReload'


import StickerForm from '../../page/sticker/StickerForm'
import ModalAddConfirm from '../../render/renderForm/ModalAddConfirm'
import ModalProductPageComponent from '../../component/pageComponent/ModalProductPageComponent'
import productFilterDataTemplate from '../../page/sticker/productFilterTemplate';

import StateTemplate from '../../model/StateTemplate'

import renderTransactionConfirm from '../../page/bill/renderTransactionConfirm';
import axiosVerify from '../../util/axiosVerify';
import console from '../../util/consoleVerify'
import renderGrid from '../../render/renderForm/renderGrid'


const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,catchErrorToMessage}=axiosUtil

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete,
    genCsv,genXlsx
} = pageUtil

function PageComponentBarcode(props) {

//console.log('PageComponentBarcode')
//const {basicDataSt,tokenSt,setReloadCheckToken,myheader}=React.useContext(MainContext)

const {filterDataTemplate,filterDataTemplate2,filterDataTemplate3,
      pageFilter,pageFilterForm,...remaining}=props


const {filterTitle,
      inputState,
      productInputState,
      filterTemplate,
      myheader,
      basicDataSt,
      formTemplate,
      editFormTemplate,
      stateTemplate,
      //groupFormTemplate,groupEditFormTemplate,
      pageDataModalForm,
      //addFormTitle,
      //editFormTitle,
      //groupAddFormTitle,
      //groupEditFormTitle,
      bgColor,
      calDigit,
      setReloadBasicData,
      setShowPrintFunc,

      mainContextBarcodeFilterData,
      setMainContextBarcodeFilterData,
}=props

const {basicData,pageData,tableTemplate,reloadBasicData}=basicDataSt
//console.log('tableTemplate............dddddddddd')
//console.log(tableTemplate)
//console.log('pageComponentBarcode')
const refSecrete=React.createRef()
const [stickerPage,setStickerPage]=React.useState({
    data0:null,
    count:0,
    lastRecordId:0
})
const shouldRender= React.useRef(true)
React.useEffect(()=>{

    axios.post(`/p35stickerpage/getlimit`,
    {pageNumber:1,limitRow:50,sort:{id:1}},
    myheader,shouldRender)
    .then(result=>{
        //console.log('result.data....')
        //console.log(result.data.data)    
        setStickerPage({
            data0:result.data.data,
            count:result.data.count,
            lastRecordId:result.data.lastRecordId,
        })
    })

    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])


const defaultFilterData={
    ...filterDataTemplate,...remaining,
    tableTemplate:
        filterDataTemplate.tableTemplateName
        ?tableTemplate[filterDataTemplate.tableTemplateName]
        :null,
    detailTableTemplate:
        filterDataTemplate.detailTableTemplateName
        ?tableTemplate[filterDataTemplate.detailTableTemplateName]
        :null,
    detailTableTemplateForForm:
        filterDataTemplate.detailTableTemplateForFormName
        ?tableTemplate[filterDataTemplate.detailTableTemplateForFormName]
        :null,
    inputState:inputState,
    widthLeft:basicData.widthLeft,
    limitRow:basicData.limitRow,
    isCsv:false,
    isXlsx:false,
    swapState:basicData.showTable

}

const genFilterData=()=>{
    if(mainContextBarcodeFilterData){
        return mainContextBarcodeFilterData
    }
    else {
        return defaultFilterData
    }
}


let [filterData,setFilterData]=React.useState(genFilterData())


const [modalConfirmReloadState,setModalConfirmReloadState]=React.useState({
    show:false,
    filterData:null
})

const cancelConfirmReload=()=>{
    
    setFilterData(
        {...modalConfirmReloadState.filterData,
            showEdit:true,
            reloadData:false
        })
    setModalConfirmReloadState({
        filterData:null,
        show:false
    })
}

const confirmReload=(data)=>{
    setFilterData(modalConfirmReloadState.filterData)
    setModalConfirmReloadState({
        filterData:null,
        show:false
    })
}



const setMainContextFilterDataFunc=()=>{
    //console.log('setMainContextFilterDataFunc')
    //console.log(filterData)
    setMainContextBarcodeFilterData(filterData)
}

React.useEffect(()=>{
   
    if(filterData.reloadData){
        const {dataUrl,pageNumber,limitRow,sort,qry}=filterData
    
        axios.post(`/${dataUrl}/getlimit`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
        .then(result=>{
            //console.log('result.data.data')
            //console.log(result.data.data)
            
            const temp2= genFilterDataWithIndex(result.data.data,filterData.selectProduct)
 
            const tempResult={...filterData,
                data0:temp2,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                isCsv:false,
                isXlsx:false,
                reloadData:false,
                
            }
            
            if(filterData.isCsv){
                //genCsv(tempResult,filterData.tableTemplateName)
                setFilterData(tempResult)
            }
            else if(filterData.isXlsx){
                //genXlsx(tempResult,filterData.tableTemplateName)
                setFilterData(tempResult)
            }
            else{
                setFilterData(tempResult)
            }

        })
        .catch(error=>{
            const tempError={...filterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterData(tempError)
        })
    }

    if(filterData.isCsv){
        //console.log('isCsv')
        genCsv({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isCsv:false})
    }
    if(filterData.isXlsx){
        //console.log('isXlsx')
        genXlsx({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isXlsx:false})
    }

},[filterData])

const [reloadStickerForm,setReloadStickerForm]=React.useState(false)
let [selectProduct,setSelectProduct]=React.useState(null)


React.useEffect(()=>{
    if(reloadStickerForm){
        setFilterData({
            ...filterData,
            showEdit:true
        })
        setReloadStickerForm(false)
    }
},[reloadStickerForm])


const setShowRange=(data)=>{setFilterData({...filterData,showRange:data})}
const setHeightTop=(data)=>{setFilterData({...filterData,heightTop:data})}
const setWidthLeft=(data)=>{setFilterData({...filterData,widthLeft:data})}
const setShowModalError=(data)=>{setFilterData({...filterData,showModalError:data})}
const setShowModalConfirm=(data)=>{setFilterData({...filterData,showModalConfirm:data})}
const setPageNumber=(data)=>{setFilterData({...filterData,pageNumber:data,reloadData:true})}
const setIsCsv=(data)=>{
    if(data){
        setFilterData({...filterData,isCsv:true,isXlsx:false})
    }
    else{
        setFilterData({...filterData,isCsv:false})
    }
}
const setIsXlsx=(data)=>{
    if(data){
        setFilterData({...filterData,isXlsx:true,isCsv:false})
    }
    else{
        setFilterData({...filterData,isXlsx:false})
    }
}

const setCloseModalProductFunc=()=>{setFilterData({...filterData,showModalProduct:false})}
const setShowModalFilter=(data)=>{setFilterData({...filterData,showModalFilter:data})}
const setInputState=(data)=>{setFilterData({...filterData,inputState:data})}


const setSwapState=(data)=>{
    //console.log('setSwapState')
    //console.log(data)
    setFilterData({
        ...filterData,
        swapState:data
    })
    //setFilterD1Func({
    //    ...filterData,
    //    swapState:data
    //})
}



const setShowModalProduct=(data)=>{
    setFilterData({
        ...filterData,
        showModalProduct:data
        
    })
}

const setShowAddModal=(data)=>{
    //console.log('setShowAdd')
    //
    setSelectProduct(null)
    //console.log(data)
    setFilterData({
        ...filterData,
        showAddModal:data,
        isAdd:true
    })
}

const setShowEdit=(data)=>{
    setSelectProduct(null)

    if(filterData.editData){
        setFilterData({
            ...filterData,
            showEdit:data,
            isAdd:false
        })
    }
}


const setShowAddWithEditData=(data)=>{
    
    setFilterData({
        ...filterData,
        editData:data,
        showEdit:true,
        showAddModal:false
    })
}



const setTableTemplate=(data)=>{setFilterData({...filterData,tableTemplate:data})}

const setEditData=(data)=>{
    let tempData=data
    //console.log('data..........')
    //console.log(data)
    setFilterData({...filterData,editData:tempData})
    //captureEditData(data)
}
//to save filterData when sort Up and down each column
const setFilterDataData0=(data)=>{setFilterData({...filterData, data0:data})}
const setSort=(data)=>{setFilterData({...filterData,sort:data})}
const setLimitRow=(data)=>{setFilterData({...filterData,limitRow:data})}

const setSelectGroupFunc=(data)=>{
    //console.log('selectGroupFunc....')
    //console.log(data)
    setFilterData({...filterData,selectGroup:data})
}

const setUnSelectAllFunc=()=>{
    setFilterData(setUnSelectAll(filterData))
}

const genFilterDataWithIndex=(filterData,selectProduct)=>{

    if(filterData){
        if(filterData.detail){
            if(filterData.detail.length()>0){
                let tempArray=[]

                filterData.detail.map(i=>{

                })

            }
        }
    }

    let tempSelectProduct
    if(selectProduct){tempSelectProduct=selectProduct}
    else {tempSelectProduct=[]}

    let tempArray=[]

    filterData.map((i,idx)=>{
         
        const temp={...i,selectedLine:false,_id:uuid()}
        tempArray=[...tempArray,temp]
        //const temp={...i,tempIndex:idx,selectedLine:false}
    })

    let tempArray2=[]

    tempArray.map(i=>{
        let tempObj=i
        tempSelectProduct.map(j=>{
            if(i.id==j.id){
                //console.log('j.id==i.id')
                tempObj={...tempObj,selectedLine:j.selectedLine}
                //tempArray2=[...tempArray2,]
            }
        })
        tempArray2=[...tempArray2,tempObj]
    })
 
    return tempArray2
}


const refreshPage=()=>{
    //console.log('refreshPage')
    const {limitRow,sort}=filterData
    const temp = {...filterData, limitRow,sort,selectProduct:[],
                editData:null,qry:{},reloadData:true
            }
    setFilterData(temp)
    setReloadBasicData(true)
}

const submitFunctionDeleteFunc=async(data)=>{
    //console.log('DeleteFunc')
    //console.log(filterData.editData)
    const tempEditData=filterData.editData
    
    if(tempEditData){
        axiosVerify.post(`/p35sticker/deletecustom`,
            {id:tempEditData.id},myheader,shouldRender)
            .then(result=>{
                //console.log('pass update')
                setFilterData({
                    ...filterData,
                    reloadData:true,
                    showModalConfirm:false
                })
            })
            .catch(error=>{
                const tempError = {...filterData,
                    reloadData:false,
                    message:catchErrorToMessage(error),
                    showModalConfirm:false,
                    showModalError:true,
                }
                setFilterData(tempError)
            })

    }

    
    //submitFunctionDelete(filterData,myheader,shouldRender)

    //.then(result=>setFilterData(result))
    //.catch(error=>setFilterData(error))
}

const submitEditFunc=async (formInputState)=>{
    //console.log('submitFunctionEditFunc')
    //axiosVerify.post
    let tempArray=[]
    formInputState.productData.map(i=>{
        const {_id,...remaining}=i
        tempArray=[...tempArray,remaining]
    })

    const tempFormInputState={...formInputState,productData:tempArray}

    axiosVerify.post(`/p35sticker/updatecustom`,tempFormInputState,myheader,shouldRender)
    .then(result=>{
        //console.log('pass update')
        /*
        setFilterData({
            ...filterData,
            showEdit:false,
            reloadData:true
        })
        */
        setModalConfirmReloadState({
            show:true,
            filterData:{
                ...filterData,
                showEdit:false,
                reloadData:true
            }
        })

    })
    .catch(error=>{
        const tempError = {...filterData,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setFilterData(tempError)
    })
}

const submitAddFunc=async (formInputState)=>{
    //console.log('submitFunctionAddFunc')
    let tempArray=[]
    formInputState.productData.map(i=>{
        const {_id,...remaining}=i
        tempArray=[...tempArray,remaining]
    })

    let {_id,...remaining2}=formInputState

    const tempFormInputState={...remaining2,productData:tempArray}

    axiosVerify.post(`/p35sticker/addcustom`,tempFormInputState,myheader,shouldRender)
    .then(result=>{
        //console.log('pass add')
        //setFilterData(result)
        setFilterData({
            ...filterData,
            showEdit:false,
            reloadData:true
        })
    })
    .catch(error=>{
        const tempError = {...filterData,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalError:true,
        }
        setFilterData(tempError)
    })
}


const addSelectProductFunc=async(data)=>{
    //console.log('addSelectProductFunc')
    //console.log(data)
    
    let tempArray=[]
    if(data){
        if(Array.isArray(data)){
            data.map(oo=>{
                let tempQty=1
                if(oo.stickerQty){
                    tempQty=parseInt(oo.stickerQty)
                }

                if(oo.selectedLine){
                    let tempI={
                        code:oo.id,
                        id:oo.id,
                        barcode:oo.barcode,
                        sn:oo.sn,
                        name:oo.productName,
                        price:oo.price,
                        unit:oo.unit,
                        qty:tempQty,
                        black:true,
                        _id:uuid()
                    }
                    tempArray=[...tempArray,tempI]
                }
            })
        }
    }
    //console.log('-----------------')
    
    //console.log(tempArray)

    setSelectProduct(tempArray)
    
    /*
    const tempEditData=
        {...filterData.editData,
            productData:[...filterData.editData.productData,
                            ...tempArray
                        ]
        }
    */

    setFilterData(
        {...filterData,
            //editData:tempEditData,
            showModalProduct:false,
            //showEdit:false
    })
    //setReloadStickerForm(true)
}


const addSelectProductFunc2=async(data)=>{
    let tempArray=[]
    if(data){
        if(Array.isArray(data)){
            data.map(oo=>{
                let tempQty=1
                if(oo.stickerQty){
                    tempQty=parseInt(oo.stickerQty)
                }

                if(oo.selectedLine){
                    let tempI={
                        code:oo.id,
                        id:oo.id,
                        barcode:oo.barcode,
                        sn:oo.sn,
                        name:oo.productName,
                        price:oo.price,
                        unit:oo.unit,
                        qty:tempQty,
                        black:true,
                        _id:uuid()
                    }
                    tempArray=[...tempArray,tempI]
                }
            })
        }
    }
    //console.log('-----------------')
    
    //console.log(tempArray)

    setSelectProduct(tempArray)
}





//{pageNumber,limitRow,sort,dataUrl} = filterData
const filterAxiosFunc=(option,inputState)=>{
    //console.log('filterAxiosFunc..............')
    //console.log('option')
    //console.log(option)
    //console.log('inputState')
    //console.log(inputState)
    //console.log('filterData')
    //console.log(filterData)
    //console.log('filterTemplate')
    //console.log(filterTemplate)

    //console.log(filterTemplate)
    filterAxios(option,inputState,filterTemplate,
                filterData,null,myheader,
                shouldRender)
    .then(result=>{

        const temp2= genFilterDataWithIndex(result.data0,
            filterData.selectProduct)
        //console.log('result......')
        //console.log(result)
        if(filterData.isCsv)
        {
            //genCsv({...result},filterData.tableTemplateName)
            setFilterData({...result,
                data0:temp2,
                isCsv:false,
            })
        }
        else if(filterData.isXlsx){
            //genXlsx({...result},filterData.tableTemplateName)
            setFilterData({...result,
                data0:temp2,
                isXlsx:false
            })
        }
        else{
            setFilterData({...result,
                data0:temp2
            })
        }
    })
    .catch(error=>{
        //console.log('error')
        setFilterData(error)
    })
}

const filterDataByGroup=(i)=>{
    //console.log('filterDataByGroup.........')
    //console.log(i)
    //const {folders,...remaining}=i
    const temp = {...filterData,
        pageNumber:1,
        qry:{groupId:i.id},
        //selectGroup:remaining,
        reloadData:true}
    setFilterData(temp)
}

const saveTableTemplateFunc=(tableTemplate)=>{
    const {tableTemplateUrl}=filterData
    saveTableTemplate(tableTemplate,tableTemplateUrl,filterData.tableTemplateName,myheader,shouldRender)
}


const updateFilterDataFunc=(index,i)=>{
    //console.log('updateFilterDataFunc')
    const temp=updateFilterData(index,i,filterData)
    //console.log('temp')
    //console.log(temp)
    setFilterData({...filterData,...temp})
}

const closeBillForm=()=>{
    setFilterData({...filterData,
        showAdd:false,showEdit:false
    })
}
//----------------------------
const transactionConfirmSubmitFunc=()=>{
    let tempEditData=filterData.editData

    if(!filterData.includeTransactionHead){
        tempEditData={...filterData.editData,
            detail:[...filterData.editData.detail,
                    ...filterData.editData.detail
                    ]
        }
    }

    setFilterData({...filterData,
        data1:tempEditData,
        showTransactionConfirm:false,
        showBillForm1:false,
        reloadBillForm1:true
    })
  
}

const transactionConfirmCancelFunc=()=>{
    setFilterData({...filterData,
        showTransactionConfirm:false
    })
}
//=============================

//-----------------------------
const renderTable=()=>{

    //console.log('renderTable')
    //console.log(filterData)

    return (
            filterData.data0
            ?<Table
                colorHead={"#4b6d62"}
                tableTemplate={filterData.tableTemplate}
                setTableTemplate={setTableTemplate}

                filterData={filterData.data0}
                setFilterData={setFilterDataData0}
                
                editData={filterData.editData}
                setEditData={setEditData}
                saveTableTemplateFunc={saveTableTemplateFunc}
                isSubTable={false}
                updateFilterData={()=>{}}//{updateFilterDataFunc}
                useInput={false}
                
            />
            :null
    )
}
//-----------------------------
const renderFilter=()=>{
    //console.log('filterTemplate...............')
    //console.log(filterTemplate)
    const {limitRow,sort,isCsv,isXlsx,showModalFilter,inputState}=filterData
    //return null
    return (
        <div className="" style={{marginBottom:"1rem"}}>
            <ModalFilterInput
            show={showModalFilter} 
            setShow={setShowModalFilter}
            
            filterTemplate={filterTemplate}

            inputState={inputState} 
            setInputState={setInputState}
            
            limitRow={limitRow} 
            setLimitRow={setLimitRow}

            isCsv={isCsv}
            setIsCsv={setIsCsv}

            isXlsx={isXlsx}
            setIsXlsx={setIsXlsx}
            
            sort={sort} 
            setSort={setSort}
            
            filterAxios={filterAxiosFunc}
            basicData={basicData}
            
            LB={pageFilterForm}
            />
        </div>
    )
}
//-----------------------------
//-----------------------------
const renderGroup=()=>{
    return (
    <div className="w-100" 
         style={{ooverflowX:"hidden",
                 paddingBottom:"5rem"}}>
        {
            renderFilter()
        }
       
       

       
    </div>
    )
}
//------------------------
//-----------------------------
const renderRight=(filterData)=>{
    //console.log('renderRight')
    //console.log(renderRight)

    return(
    <div className="w-100 h-100"

    >
                {
                filterData.showEdit
                ?null
                :<div className=""
                      style={{height:"5%",//"2rem",
                             width:"100%"}}
                >
                    {
                        renderBadge({
                            filterData,
                            setPageNumber:setPageNumber,
                            
                            totalSwapPage:1, 
                            setSwapState:setSwapState,
                            
                            setReloadData:refreshPage,
                            setShowFilter:()=>{},
                            setShowAdd:setShowAddModal,
                            setShowEdit:setShowEdit,
                            setShowModalConfirm:setShowModalConfirm,
                            
                            //setShowModalCsv:()=>{},//genCsvFunc,

                            setUnSelectAll:setUnSelectAllFunc,
                            setShowForwardConfirm:(value)=>{setFilterData({...filterData,showTransactionConfirm:value})},
                            setClose:()=>{},
                            bgColor:bgColor,
                            captureSelect:()=>{}//captureSelect
                        })        
                    }

                </div>
                } 
                <div 
                    style={{height:"95%",
                            width:"100%",
                    }}
                >
                {
                 filterData.swapState
                 ?renderTable() 
                 :renderGrid(filterData,tableTemplate,setEditData)
                }
                </div>
      
             
    </div>
    )
}
//=============================
//==============================
const renderBody=()=>{
return(
    filterData&&
    <div className=""
             style={{display:"flex",height:"95%",width:"100%"}}
    >
            
            <div className="" 
                 style={{height:"100%",
                         width:`${filterData.widthLeft}%`,
                         overflowY:"scroll",
                         overflowX:"hidden"
                         }}>
                     {
                       renderGroup()
                     }
            </div>
        

            {
            filterData.showEdit        
            ?<div className="" 
                    style={{height:"100%",
                    width:`${100-filterData.widthLeft}%`,
                    overflowY:"scroll",
                    overflowX:"hidden",
                   }}
            >
                <div style={{width:"100%"}}>
                    <StickerForm  
                            editData={filterData.editData}
                            pageData={pageData}
                            basicData={{...basicData,stickerPage:stickerPage.data0}}
                            myheader={myheader}
                            setShowPrintFunc={setShowPrintFunc}
                            setShow={setShowEdit}
                            setShowModalProduct={setShowModalProduct}
                            selectProduct={selectProduct}
                            isAdd={filterData.isAdd}
                            submitAddFunc={submitAddFunc}
                            submitEditFunc={submitEditFunc}
                    />
                </div>
            </div>
            :<div className="" 
                style={{height:"100%",
                        width:`${100-filterData.widthLeft}%`,
                        //overflowY:"scroll",
                        //overflowX:"scroll",
                       }}>

                 {
                    renderRight(filterData)  
                 }
                 
                 {  /*
                    filterData.showEdit
                    ?<div className='w-100'>
                        <StickerForm  
                            editData={filterData.editData}
                            pageData={pageData}
                            basicData={{...basicData,stickerPage:stickerPage.data0}}
                            myheader={myheader}
                            setShowPrintFunc={setShowPrintFunc}
                            setShow={setShowEdit}
                            setShowModalProduct={setShowModalProduct}
                            selectProduct={selectProduct}
                            isAdd={filterData.isAdd}
                            submitAddFunc={submitAddFunc}
                            submitEditFunc={submitEditFunc}
                        />
                    </div>
                    :renderRight(filterData) 
                   */
                 }
            </div>

            }


    </div>
)
}
//===============================
//===============================


//================================
//=================================

//=================================
return(
<div className="w-100 h-100">          
            <button style={{display:"none"}}
                        ref={refSecrete}
                        onClick={e=>{
                            setMainContextFilterDataFunc()
                        }}
            >
                        secrete
            </button>
            <div className="h-5 w-100"
            
            >
                <Navbar 
                    setMainContextFilterData={()=>refSecrete.current.click()}
                />
            </div>


            <div className="w-100 h-95">
                   

                {   
                renderBody()    
                }
            
                <div className="hide-on-printX" 
                    style={{width:"100%",display:"flex",position:"relative"}}>
                        {
                        renderWidthRangeBar({showRange:filterData.showRange,
                                            setShowRange:setShowRange,
                                            widthLeft:filterData.widthLeft,
                                            setWidthLeft:setWidthLeft,
                                            //heightTop:filterData.heightTop,
                                            //setHeightTop:setHeightTop
                                        })
                        }
            
                </div>


                {filterData.showTransactionConfirm&&
                renderTransactionConfirm({
                    editTransaction:filterData.editData,
                    submitFunc:transactionConfirmSubmitFunc,
                    cancelFunc:transactionConfirmCancelFunc,
                    pageData:pageData,
                    changeIncludeTransactionHead:()=>{},//changeIncludeTransactionHead,
                    includeTransactionHead:filterData.includeTransactionHead
                    })
                }
                
                {
                filterData.showAddModal&&stickerPage.data0
                ?<ModalAddConfirm
                    setShow={setShowAddModal}
                    submitFunction={setShowAddWithEditData}
                    basicData={{...basicData,stickerPage:stickerPage.data0}}
                    setEditData={setEditData}
                    lastRecordId={filterData.lastRecordId}
                />
                :null
                }

                {
                filterData.showModalProduct
                ?<ModalProductPageComponent
                        //setReloadBasicData={setReloadBasicData}

                        filterDataTemplate={productFilterDataTemplate}
                        filterTemplate={basicDataSt.pageFilter.productFilter}
                        productInputState={productInputState}
                        basicDataSt={basicDataSt}
                        myheader={myheader}
                
                        stateTemplate={StateTemplate.productState}
                        pageFilterForm={basicDataSt.pageFilterForm.productFilterForm}
                        
                        pageDataModalForm={basicDataSt.pageData.ModalForm}
                        
                        bgColor={"#d1adbf"}
                        setCloseModalProduct={setCloseModalProductFunc}
                        addSelectProductFunc={addSelectProductFunc}
                        addSelectProductFunc2={addSelectProductFunc2}
                />
                :null
                }

                {
                modalConfirmReloadState.show
                ?<ModalConfirmReload
                    setShow={cancelConfirmReload}
                    submitFunction={confirmReload}
                />
                :null
                }

                {
                filterData.showModalConfirm
                ?<ModalConfirm
                    setShow={setShowModalConfirm}
                    submitFunction={submitFunctionDeleteFunc}
                />
                :null
                }

                {  
                filterData.showModalError
                ?renderModalError({
                    setShow:setShowModalError,
                    message:filterData.message
                })
                :null
                }
            </div>
</div>
)

}




export default PageComponentBarcode ;

import React from 'react'
import axios from '../util/axiosVerify';
import tableUtil from '../component/table/tableUtil'
import ctUtil from '../util/ctUtil'
import FilterTemplate from '../render/renderFilter/FilterTemplate' 
//import FormTemplate from './FormTemplate';
import LangTemplate from './LangTemplate';
//import LangTemplateCustom from './LangTemplateCustom';
//import TableTemplate from './TableTemplate';
import ModalFormTemplate from '../render/renderForm/FormTemplate'
import console from '../util/consoleVerify'
//import axiosUtil from '../util/axiosUtil'

//const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,catchErrorToMessage}=axiosUtil

const {sortColumn} = tableUtil
const {createTableTemplateForPage,convertTableTemplateObjToArray,
      convertFormTemplateArrayToObj,
      changeLanguageTableTemplate
    }=ctUtil

export const MainContext=React.createContext();

const findHaveToken=(tokenName)=>{
    if(localStorage.getItem(tokenName)){
        return true
    }
    else {
        return false
    }
}



const MainContextProvider=(props)=>{
    //console.log('MainContext....')
  
    const [tokenSt,setTokenSt] = React.useState({
        reloadCheckToken:true,
        haveShopToken:findHaveToken('shopauthorization'),
        haveUserToken:findHaveToken('userauthorization'),
        userName:null
    })

    const [basicDataSt,setBasicDataSt]=React.useState({
        reloadBasicData:true,
        basicData:null,
        pageData:null,
        tableTemplate:null
    })


    const [mainContextGroupSt,setMainContextGroupSt]=React.useState({
        editGroup:null,
        tree:null,
        showTree:false,
        imgHeight:null,
        lastRecordId:0,
    })

    const [mainContextPartnerFilterData,setMainContextPartnerFilterData]=React.useState(
        null
    )

    const [mainContextProductFilterData,setMainContextProductFilterData]=React.useState(
        null
    )
    const [mainContextProductFilterData2,setMainContextProductFilterData2]=React.useState(
        null
    )

    const [mainContextGroupFilterData,setMainContextGroupFilterData]=React.useState(
        null
    )
    const [mainContextBarcodeFilterData,setMainContextBarcodeFilterData]=React.useState(
        null
    )   
    const [mainContextBillFilterData,setMainContextBillFilterData]=React.useState({
                                                                    showPrintPageSetting:null,
                                                                    selectProductToBillForm:null,
                                                                    selectCustomerToBillForm:null,
                                                                    resetProductList:null,
                                                                    billTableTemplate:null,
                                                                    partnerInputState:null,
                                                                    transactionInputState:null,
                                                                    transactionBranchInputState:null,
                                                                    productInputState:null,
                                                                    printData:null,
                                                                    billFormData1:null,
                                                                    billFormData2:null,
                                                                    filterData:null
                                                            })
    const [mainContextTransactionForBillFilterData,
           setMainContextTransactionForBillFilterData]=React.useState({
            filterData:null,
            editData:null,
            filterInputState:null,
            filterInputState2:null,
            tableTemplate:null
           }
    )   
     
    const [mainContextBillMenuFilterData,
        setMainContextBillMenuFilterData]=React.useState(
     null
    )  

    const [mainContextPartnerForBillFilterData,
        setMainContextPartnerForBillFilterData]=React.useState(
     null
    )     

    const [mainContextBill,setMainContextBill]=React.useState({
        filterData:null,
        partnerForBillFilterData:null,
        billGroupComponentFilterData:null,
        billGroupComponentFilterData2:null,
        transactionForBillFilterData:null,
        billMenuComponentFilterData:null,
        billMenuComponentFilterData2:null,
        showPrintPageSetting:null,
        selectProductToBillForm:null,
        selectCustomerToBillForm:null,
        selectOfSelectProduct:null,
        resetProductList:null,
        billTableTemplate:null,
        billFormData1:null,
        billFormData2:null,
        showTableInBillForm:null
    })
    

    const [mainContextUser,setMainContextUser]=React.useState(null)
    const [mainContextShop,setMainContextShop]=React.useState(null)
    const [mainContextShopPayment,setMainContextShopPayment]=React.useState(null)
    const [mainContextBranch,setMainContextBranch]=React.useState(null)
    const [mainContextBasicData,setMainContextBasicData]=React.useState(null)

    const [mainContextTaskFilterData,setMainContextTaskFilterData]=React.useState(null)  
    const [mainContextPartnerFilterDataForTask,setMainContextPartnerFilterDataForTask]=React.useState(null)  

    const [mainContextModalProduct,setMainContextModalProduct]=React.useState(null)
    const [mainContextModalProduct2,setMainContextModalProduct2]=React.useState(null)

    /*
    React.useEffect(()=>{
        console.log('mainContextTaskFilterData')
        console.log(mainContextTaskFilterData)
    },[mainContextTaskFilterData])

    React.useEffect(()=>{
        console.log('mainContextPartnerFilterDataForTask')
        console.log(mainContextPartnerFilterDataForTask)
    },[mainContextPartnerFilterDataForTask])
    */
   
    const myheader={headers: {'Content-Type': 'application/json',
    'Shopauthorization':localStorage.getItem('shopauthorization'),
    'Userauthorization':localStorage.getItem('userauthorization')
    }}
    
    
    const setBasicData=(temp)=>{
        setBasicDataSt({...basicDataSt,
            basicData:temp
        })
    }
    const setReloadBasicData=(value)=>{
        setBasicDataSt({ ...basicDataSt,reloadBasicData:true})
    }
    const setReloadCheckToken=(value)=>{
        setTokenSt({...tokenSt,reloadCheckToken:true})
    }



    React.useEffect(()=>{
        const {
            reloadCheckToken,
            haveShopToken,
            haveUserToken,
            userName
        }=tokenSt
        
        let tempSt={...tokenSt}

        if(reloadCheckToken){

            if(localStorage.getItem('shopauthorization')){
                tempSt={...tempSt,haveShopToken:true}
            }
            else{
                tempSt={...tempSt,haveShopToken:false}
                localStorage.removeItem('userauthorization')
                localStorage.removeItem('username')
            }

            if(localStorage.getItem('userauthorization')&&
            localStorage.getItem('username')){
                
                tempSt={...tempSt,
                        haveUserToken:true,
                        userName:localStorage.getItem('username')
                    }
                }
            else{
                
                localStorage.removeItem('userauthorization')
                localStorage.removeItem('username')

                tempSt={...tempSt,
                    haveUserToken:false,
                    userName:null
                }
            }
            tempSt={...tempSt,
                reloadCheckToken:false
            }
            setTokenSt(tempSt)

        }
    },[tokenSt])




    const genPageData=(LangTemplate)=>{
        let tempArray=[]
        let tempLangTemplate=[]

        LangTemplate.map(i=>{
            tempArray=[...tempArray,i.formLanguage]
            //-------------------------
            let tempObj={}
            i.template.map(j=>{
                tempObj={...tempObj,[j.key]:j.lb}
            })
            tempLangTemplate=[...tempLangTemplate,{...i,templateObj:tempObj}]

        })

        const tempUnique=[...new Set(tempArray)]

        let tempFinalObj
        tempUnique.map(lang=>{
            tempFinalObj={...tempFinalObj,[lang]:null}
        })

        tempLangTemplate.map(k=>{
            const tempFormObj={[k.formName]:k.templateObj}

            tempFinalObj={...tempFinalObj,
                [k.formLanguage]:
                        {...tempFinalObj[k.formLanguage],...tempFormObj}
            }
        })
     
        return {listOfLanguage:tempUnique,
                languageObj:tempFinalObj,

               }
    }


    React.useEffect(()=>{  
        //console.log('------------')
        const {reloadBasicData,basicData}=basicDataSt
        let tempSt
        
    

        if(reloadBasicData){
            if(localStorage.getItem('shopauthorization')&&
               localStorage.getItem('userauthorization')
            ){
                        let tempDataforcontext
                        axios.post('/p35basicdata/getdataforcontext',{},myheader)
                        //.then(result=>{
                        //    //console.log('getdataforcontext')
                        //    tempDataforcontext=result.data
                        //    //console.log(result.data)
                        // })

                        //const promise1=axios.post('/p35basicdata/getcustom',{},myheader)
                        //const promise2=axios.post('/p35formtemplate/getcustom',{},myheader)
                        //const promise3=axios.post('/p35tabletemplate/getcustom',{},myheader)
                        //const promise4=axios.post('/p35branch/getcustom',{},myheader)
                        //const promise5=axios.post('/p35user/getuserbyid',{},myheader)
                        //const promise6=axios.post('/p35printpage/getlimit',{},myheader)

                        //const tempFormTemplate=convertFormTemplateArrayToObj(FormTemplate)
                        
                         //Promise.all([promise1,promise2,promise3,promise4,promise5,promise6])
                        //    .then(result=>{
                        .then(result=>{
                            tempDataforcontext=result.data
                            //console.log('2222222222222')
                            //console.log(tempDataforcontext)
                            //console.log(tempDataforcontext)
                            const tempOriginalRouteAuth=tempDataforcontext.basicData.routeAuth
                            //console.log(result[4].data.data)
                            //console.log(tempDataforcontext)
                            
                            //console.log(LangTemplate)
                            //let tempResultPrintPage=result[5].data.data
                            let tempResultPrintPage=tempDataforcontext.basicData.printPage
                            const tempAllBranch=tempDataforcontext.allBranch
                            const tempAllUser=tempDataforcontext.allUser
                            //console.log('tempResultPrintPage')
                            //console.log(tempResultPrintPage)
                            
                            //let tempPrintPage=[]
                            //if(tempResultPrintPage){
                                //tempResultPrintPage.map(k=>{
                                //    tempPrintPage=[...tempPrintPage,
                                //        {
                                //            ...k,printId:k.id
                                //        }]
                                //})
                            //}
                            //else{
                                //tempPrintPage=[]
                            //}
                            const tempPrintPage=tempResultPrintPage
                            
                            //let tempBasicData=result[0].data.data[0]
                            let tempBasicData=tempDataforcontext.basicData
                            
                            tempBasicData={...tempBasicData,printPage:tempPrintPage}
                            //console.log('1')
                            //console.log('tempBasicData')
                            //console.log(tempBasicData)
                            let listOfPrintPage=[]
                            tempBasicData.printPage.map(i=>{
                                listOfPrintPage=[...listOfPrintPage,i.printPageName]
                            })
                            
                            //const tempRouteAuth=sortColumn(result[0].data.data[0].routeAuth,"id","number","a-b")
                            let tempRouteAuthBeforeSort=[]

                            tempDataforcontext.basicData.routeAuth.map(i=>{
                                
                                if(i.id>999){
                                    tempRouteAuthBeforeSort=[...tempRouteAuthBeforeSort,i]
                                }
                                //const checkUser=i.routeAddress.includes("/user/")
                                //const checkBasicData=i.routeAddress.includes("/basicdata/")
                                //if(!checkUser&&!checkBasicData){
                                //   tempRouteAuthBeforeSort=[...tempRouteAuthBeforeSort,i]
                                //}
                            })
                            
                            const tempRouteAuth=sortColumn(tempRouteAuthBeforeSort,"id","number","a-b")
                            //og('2')
                            //console.log(result[2])
                            //let tempTableTemplate = createTableTemplateForPage(result[2].data.data)
                            let tempTableTemplate = createTableTemplateForPage(tempDataforcontext.branch.tableTemplate)
                            //let tempTableTemplate = TableTemplate

                            //console.log('3')
                            //let tempFormTemplate=result[1].data.data
                            //console.log('tempTableTemplate') 
                            //console.log(tempTableTemplate)                
                            
                            //console.log('TableTemplate')
                            //console.log(TableTemplate)
                            let tempLanguageArray=[...LangTemplate,...tempDataforcontext.basicData.langTemplate]
                            //let tempLanguageArray=[...LangTemplate]//,...LangTemplateCustom]
                            //consoel.log(tempLanguageArray)

                            //let tempLanguageArray=result[1].data.data//*****************
                            //console.log('tempLanguageArray')
                            //console.log(tempLanguageArray)
                            //console.log(tempLanguageArray.length)
                            //console.log(LangTemplate.length)
                            //console.log(LangTemplateCustom.length)
                            //console.log('4')
                            let tempBrD=tempDataforcontext.branch//result[3].data.data[0]

                            //const tempUser=result[4].data.data[0]
                            //console.log('tempBrD')
                            //console.log(tempBrD)
                            
                            const {id,...remaining}=tempBrD 
                            const tempBranchData={...remaining,
                                                branchId:id
                                                }
                                      
                            //console.log('tempBranchData')
                            //console.log(tempBranchData)
                            //==============================
                            //console.log('tempLanguageArray')
                            //console.log(tempLanguageArray)

                            //============================
                            //============================
                            //============================
                            //============================
                            //============================
                            //============================
                            //============================
                            //============================
                            //============================
                            //const tempGenPageData2=genPageData(LangTemplate)
                            const tempGenPageData2=genPageData(tempLanguageArray)

                            //console.log('tempGenPageData2')
                            //console.log(tempGenPageData2)
                            //let listOfLanguage2=tempGenPageData2.listOfLanguage
                            let tempLanguageObj2=tempGenPageData2.languageObj
  
                            /*
                            let listOfLanguage=[]
                            let tempLanguageObj={}

                            tempLanguageArray.map(i=>{
                                    //console.log(i.id)
                                    let tempObj={}

                                    i.template.map(j=>{
                                        tempObj={...tempObj,[j.key]:j.lb}
                                    })
                                    tempObj={...tempObj,["thisId"]:i.id}
                                    tempLanguageObj={...tempLanguageObj,[i.formLanguage]:tempObj}

                                    listOfLanguage=[...listOfLanguage,i.formLanguage]
                            })
                            */

                            //console.log('5')
                            //console.log('tempLanguageObj')
                            //console.log(tempLanguageObj)

                            //console.log('listOfLanugate')
                            //console.log(listOfLanguage)
                            //==============================
                            //const selectLanguage=tempLanguageObj["Thai"]
                           // const selectLanguage=tempLanguageObj[tempBasicData.languageSetting]

                          //  //console.log('selectLanguage')
                          //  //console.log(selectLanguage)

                            //================================
                           //let tempModalFormTemplate=ModalFormTemplate
                            
                           let tempModalFormTemplate2={}

                            Object.keys(ModalFormTemplate).map(formName=>{
                                //productForm,productEditForm,partnerForm,partnerEditForm
                                let temp1=tempLanguageObj2[tempBasicData.languageSetting]['renderFormTemplate']
                             
                                let tempForm={}

                                Object.keys(ModalFormTemplate[formName]).map(fieldName=>{
                                
                                    let tempObjFieldName=ModalFormTemplate[formName][fieldName]
                                    let tempFieldNameObj=tempObjFieldName

                                    if(tempObjFieldName.subFormTemplate){
                                        let tempSubFormObj={}
                                        Object.keys(tempObjFieldName.subFormTemplate).map(n=>{
                                            //console.log(`${fieldName}-${n}`)
                                            //console.log(tempObjFieldName.subFormTemplate[n])
                                            //tempSubFormObj={...tempSubFormObj,tempObjFieldName.subFormTemplate[n]}
                                            let tempa= {...tempObjFieldName.subFormTemplate[n],lb:'-'}
                                            
                                                const tempc=tempLanguageObj2[tempBasicData.languageSetting]["renderTableTemplate"]
                                                if(tempc[n]){
                                                    //console.log(tempc[n])
                                                    tempa={...tempa,lb:tempc[n]}
                                                }
                                                
                                            //console.log(tempa)
                                            tempSubFormObj={...tempSubFormObj,[n]:tempa}
                                        })
                                        //console.log('tempSubFormObj')
                                        //console.log(tempSubFormObj)
                                        tempFieldNameObj={...tempFieldNameObj,subFormTemplate:tempSubFormObj}//"UUUU"}
                                    }

                                    tempFieldNameObj={...tempFieldNameObj,lb:temp1[fieldName]}//"UUUU"}
                                
                                    tempForm={...tempForm,[fieldName]:tempFieldNameObj}
                                })
                                tempModalFormTemplate2={...tempModalFormTemplate2,[formName]:tempForm}
                            })
                        
                            //console.log(6)

                            let pageFilterForm2={
                                ["productFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["productFilterForm"],
                                ["groupFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["groupFilterForm"],

                                ["productFilterForm2"]:tempLanguageObj2[tempBasicData.languageSetting]["productFilterForm2"],
                                ["supplierFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["supplierFilterForm"],

                                ["partnerFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["partnerFilterForm"],
                                ["customerFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["customerFilterForm"],
                                ["transactionFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["transactionFilterForm"],
                                ["transactionBranchFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["transactionBranchFilterForm"],
                                ["stickerFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["stickerFilterForm"],
                                ["taskFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["taskFilterForm"],

                                ["billMenuFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["billMenuFilterForm"],
                                ["billBranchMenuFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["billBranchMenuFilterForm"],


                                ["shopFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["shopFilterForm"],
                                ["userFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["userFilterForm"],
                                ["shopPaymentFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["shopPaymentFilterForm"],

                                ["branchForAdminFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["branchForAdminFilterForm"],
                                ["basicDataForAdminFilterForm"]:tempLanguageObj2[tempBasicData.languageSetting]["basicDataForAdminFilterForm"]

                            }

                            //==============================
                            let pageFilter2={}

                            let tempFilterTemplateName = Object.keys(FilterTemplate)

                            //partnerFilter,productFilter,transactionFilter,billMenuFilter
                            
                            tempFilterTemplateName.map(i=>{
                                let tempArray=[]

                                FilterTemplate[i].map(j=>{
                                    
                                    const tempObj={...j,
                                        //lb:'xxxx',
                                        ["lb"]:tempLanguageObj2[tempBasicData.languageSetting]["renderTableTemplate"][j.keyName]

                                    }

                                    tempArray=[...tempArray,tempObj]
                                })

                                pageFilter2={...pageFilter2,[i]:tempArray}
                            })
                            //================================
                            //console.log(7)
                            //console.log('kkkkktempTableTemplate')
                            //console.log(tempTableTemplate)
                            
                            let tableTemplate2={}    
                
                            let tempTableTemplateName = Object.keys(tempTableTemplate)
                          
                            tempTableTemplateName.map(i=>{ //partnerTP,productTP,basicDtaTP
                                //console.log(i)
                                //console.log(tempLanguageObj2[tempBasicData.languageSetting][i])

                                let tempTp={}

                                const subKey=Object.keys(tempTableTemplate[i])

                                subKey.map(j=>{ //j=>id,routeAddress,routeName 
                                    tempTp={...tempTp,
                                        [j]:{...tempTableTemplate[i][j],
                                            ["lb"]:tempLanguageObj2[tempBasicData.languageSetting]["renderTableTemplate"][j]
                                            } 
                                        }
                                })

                                tableTemplate2={...tableTemplate2 ,[i]:tempTp }
                            })
                            //console.log('tableTemplate2')
                            //console.log(tableTemplate2)
                            //==================================

                            //console.log('9')
                            //console.log('pageFilterForm')
                            //console.log(pageFilterForm)
                            
                            //console.log('++++++++++tableTemplate')
                            //console.log(tableTemplate)
                            

                            tempSt={...basicDataSt,
                                reloadBasicData:false,

                                basicData:{...tempBasicData,
                                    routeAuth:tempRouteAuth,
                                    mark:['','+','-','+%','-%'],
                                    originalRouteAuth:tempOriginalRouteAuth,
                                    ...tempBranchData,
                                    listOfLanguage:tempGenPageData2.listOfLanguage,
                                    listOfPrintPage:listOfPrintPage,
                                    partnerType:[...tempBasicData.supplierType,
                                                 ...tempBasicData.customerType],
                                },
                                pageData:tempLanguageObj2[tempBasicData.languageSetting],//finalFormTemplate,
                                tableTemplate:tableTemplate2,//tableTemplate,
                                pageFilter:pageFilter2,
                                pageFilterForm:pageFilterForm2,
                                languageObj:tempGenPageData2.languageObj,
                                modalFormTemplate:tempModalFormTemplate2,//ชั่วคราว
                                user:tempDataforcontext.user,
                                shop:tempDataforcontext.shop,
                                languageArray:tempLanguageArray,
                                allBranch:tempAllBranch,
                                allUser:tempAllUser
                            }
                            
                            //console.log('tempSt..')
                            //console.log(tempSt)
                            setBasicDataSt(tempSt)

                        })
                        .catch(err=>{
                            //console.log('err')
                            //console.log(err)
                            tempSt={...basicDataSt,
                                reloadBasicData:false
                            }
                            setBasicDataSt(tempSt)
                            //window.alert(catchErrorToMessage(err))
                            //localStorage.removeItem('userauthorization');
                            //localStorage.removeItem('username');
                            //setReloadCheckToken(true)
                            //window.location.reload()
                        })
                    }
            
        }
            
    },[basicDataSt])

//=========================================
//=========================================
return(
        <MainContext.Provider value={
            {
               //allTableTemplate,
               myheader,
               tokenSt,setTokenSt,
               setReloadCheckToken,
               basicDataSt,
               setReloadBasicData,
               setBasicData,
               mainContextGroupSt,setMainContextGroupSt,
               mainContextPartnerFilterData,setMainContextPartnerFilterData,
               mainContextProductFilterData,setMainContextProductFilterData,
               mainContextProductFilterData2,setMainContextProductFilterData2,
               mainContextGroupFilterData,setMainContextGroupFilterData,
               mainContextBarcodeFilterData,setMainContextBarcodeFilterData,
               mainContextBillFilterData,setMainContextBillFilterData,
               mainContextTransactionForBillFilterData,setMainContextTransactionForBillFilterData,
               mainContextBillMenuFilterData,setMainContextBillMenuFilterData,
               mainContextPartnerForBillFilterData,setMainContextPartnerForBillFilterData,
               
               mainContextTaskFilterData,setMainContextTaskFilterData,
               mainContextPartnerFilterDataForTask,setMainContextPartnerFilterDataForTask,

               mainContextBill,setMainContextBill,
               mainContextUser,setMainContextUser,
               mainContextShop,setMainContextShop,
               mainContextShopPayment,setMainContextShopPayment,
               mainContextBranch,setMainContextBranch,
               mainContextBasicData,setMainContextBasicData,

               mainContextModalProduct,setMainContextModalProduct,
               mainContextModalProduct2,setMainContextModalProduct2
            }
        }>
            {props.children}
        </MainContext.Provider>
    )
}

export default MainContextProvider;

import React from 'react';
import tableUtil from '../../component/table/tableUtil'
import {FaTags,FaBan,FaCheck} from 'react-icons/fa'


import {MdRefresh,MdSwapHoriz,MdSettings,
    MdSearch,MdEdit,MdAddCircle,MdDelete,MdPrint,
    MdChevronLeft,MdChevronRight,MdLastPage,
    MdRadioButtonChecked,MdClose,MdCheckBoxOutlineBlank,
    MdCheckBox
} from 'react-icons/md';
import Galleryone from '../../component/galleryone/Galleryone'

//import ShowContent2 from './ShowContent2'

const {sortColumn,numberWithCommas} = tableUtil

const renderGrid=(filterData,tableTemplate,setEditData,
                  updateFilterDataFunc,useInput,basicData,
                  showTag,setShowTag,
                  useInnerVat,
                  innerVatRate,
                  findProductIdByKeyDown,
                  findBarcodeByKeyDown
                  )=>{

    
    //console.log('renderGrid')
    //console.log(useInput)

    const calDigit=process.env.REACT_APP_CALDIGIT

    const genData=(data,b,type)=>{
    

        if(b=="point"||b=="totalPoint"||
           type=="price"||
           b=="result"||b=="quantity"||
           b=="price"||b=="total"||b=="grandTotal"||
           b=="totalReduction"||b=="totalTax"
        ){

            return numberWithCommas(data,calDigit,b)
        }
        else {
            return data
        }

    }


    const priceLevelHeadCell={
        padding:"0.3rem",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        fontSize:"1rem",
        border:"0.5px solid #777"
    }

    const priceLevelHead={
            display:"grid",
            gridTemplateColumns:"150px 100px 200px",
            gridAutoRows:"1.2rem",
            backgroundColor:"#ccc"
    }

    const priceLevelBody={
        display:"grid",
        gridTemplateColumns:"150px 100px 200px",
        gridAutoRows:"1.2rem",
        backgroundColor:"white"
    }




const checkNumberWithDigit=(calDigit,myvalue)=>{
    let pattern
    if(calDigit==1){
        pattern = "^\-?[0-9]?$"
    }
    else if(calDigit==10){
        pattern = "^\-?[0-9]+(?:\.[0-9]{1,1})?$"
    }
    else if(calDigit==1000){
        pattern = "^\-?[0-9]+(?:\.[0-9]{1,3})?$"
    }
    else if(calDigit==10000){
        pattern = "^\-?[0-9]+(?:\.[0-9]{1,4})?$"
    }
    else{
        pattern = "^\-?[0-9]+(?:\.[0-9]{1,2})?$"
    }
    //console.log(`value=${myvalue}`)
    //console.log(calDigit)
    const regex = new RegExp(pattern);
    const result=regex.test(myvalue.toString())
    //console.log(`result of ${value} = ${result}`)
    return result
} 


const checkPriceAndQuantity=(inputState,j,eValue,calDigit)=>{
        let tempResult=null
        if(eValue==""){
            const temp={...inputState,
                [j]:eValue,
                ["result"]:0,
                ["resultPoint"]:0
            }
            tempResult=temp
            //setInputState(temp)
        }
        
        else{
        //calDigit
            const tempCheck =checkNumberWithDigit(calDigit,eValue)

            if(tempCheck){
                
                let myvalue=eValue
            
                let temp
                if(j=="quantity"){
                    
                    if(useInnerVat&&innerVatRate){
                        temp={...inputState,
                            ["quantity"]:eValue,
                            ["result"]:parseInt(myvalue*inputState.price*calDigit)/calDigit,
                            ["resultPoint"]:parseInt(myvalue*inputState.point*calDigit)/calDigit,
                            ["resultIncludeVat"]:parseInt(myvalue*inputState.priceIncludeVat*calDigit)/calDigit,
                        }  
                    }
                    else {
                        temp={...inputState,
                            ["quantity"]:eValue,
                            ["result"]:parseInt(myvalue*inputState.price*calDigit)/calDigit,
                            ["resultPoint"]:parseInt(myvalue*inputState.point*calDigit)/calDigit
                        }  
                    }
                }
                if(j=="price"){

                    if(useInnerVat&&innerVatRate){
                                        
                        const temp1=eValue*innerVatRate/100
                        const temp2=parseInt(eValue)+parseInt(temp1)
                    
                        const temp3=parseInt(temp2*calDigit)/calDigit
                        const tempPriceIncludeVat=temp3

                        temp={...inputState,
                            ["price"]:eValue,
                            ["result"]:parseInt(myvalue*inputState.quantity*calDigit)/calDigit,
                            ["priceIncludeVat"]:tempPriceIncludeVat,
                            ["resultIncludeVat"]:parseInt(tempPriceIncludeVat*inputState.quantity*calDigit)/calDigit,
                        }  
                    }else{

                        temp={...inputState,
                            ["price"]:eValue,
                            ["result"]:parseInt(myvalue*inputState.quantity*calDigit)/calDigit
                        }  

                    }

                }
                if(j=="point"){
                    temp={...inputState,
                        [j]:eValue,
                        ["resultPoint"]:parseInt(myvalue*inputState.quantity*calDigit)/calDigit
                }    
                }

                tempResult=temp
            }
            
        }

        return tempResult
}









    const showContent=(b,tt,data,v,idx)=>{
            
        const {type}=tt
        if(b=="selectedLine"){
            return (
            <div className="h-100" style={{display:"flex",marginLeft:"0.2rem"}}
                onClick={e=>{
                    updateFilterDataFunc(idx,{...v,selectedLine:!v.selectedLine})
                }}
            >
                {data
                ?<MdCheckBox className="md-icon"/>
                :<MdCheckBoxOutlineBlank  className="md-icon"/>
                }
            </div>
            )
        }
        else if(type=="boolean"){
        return (<div className="h-100" style={{display:"flex",marginLeft:"1rem"}}>
                {data
                ?<MdCheckBox style={{fontSize:"1rem"}}/>
                :<MdCheckBoxOutlineBlank  style={{fontSize:"1rem"}}/>
                }
                </div>)
        }
        else if(type=="date"){
            let tempDate=null
            
            if(data){
                const date1=new Date(data).toISOString()
                const date2=new Date(date1).toLocaleString('en-GB')
                tempDate= date2.substring(0,10)
        
            }

            return  (
                    <p  className="table-p"
                        style={{margin:"0"}} >
                    {tempDate
                    ?`${tempDate}`
                    :null
                    }
                    </p>
                    )
        }
        else if(type=="time"){
            let tempTime=null

            if(data){
                const date1=new Date(data).toISOString()
                const date3=new Date(date1).toLocaleString('en-GB')
                tempTime=date3.substring(12,17)
            }
            return  <p className="table-p"
                        style={{margin:"0"}} >
                    {tempTime
                    ?`${tempTime}`
                    :null
                    }
                    </p>
        }
        else if(type=="datetime"){
            let tempDateTime=null
            if(data){
                const date1=new Date(data).toISOString()
                const date3=new Date(date1).toLocaleString('en-GB')
                tempDateTime=date3
            }
            return  <p className="table-p"
                    style={{margin:"0"}} >
            {tempDateTime
            ?`${tempDateTime}`
            :null
            }</p>
        }
    
        else if(type=="number"||type=="price"){
            return <p className="table-p"
                    style={{margin:"0"}}>{genData(data,b)}</p>
        
        }
        else if(type=="string"){
            return <p className="table-p"
                        style={{margin:"0"}}>{data}</p>
                
        }

        else{
            return(
                <div>null</div>
            )
        }
    
    }

    //-----------------------------------------
    //-----------------------------------------



   const showContent2=(b,tt,data,v,idx)=>{


        const {type}=tt
        if(b=="selectedLine"){
            return (
            <div className="h-100" style={{display:"flex",marginLeft:"0.2rem"}}
                onClick={e=>{
                    updateFilterDataFunc(idx,{...v,selectedLine:!v.selectedLine})
                }}
            >
                {data
                ?<MdCheckBox className="md-icon"/>
                :<MdCheckBoxOutlineBlank  className="md-icon"/>
                }
            </div>
            )
        }
        else if(type=="boolean"){
        return  <div className='w-70'
                     style={{display:"flex",
                            justifyContent:"flex-end",
                            margin:"0",
                     }}

                >
                        <input
                            style={{maxWidth:"100%"}}
                            type="checkbox"
                            checked={data}

                            onChange={e=>{
                                updateFilterDataFunc(idx,{...v,[b]:e.target.checked})
                            }}
                        />

                </div>
        }
     
        else if((b=="unit")||(b=="jobStatus")){
            return renderSelect(idx,b,data,v)
        }


        else if(b=="price"){
            return (
                <div className='w-70'
                     style={{display:"flex",justifyContent:"space-between",
                            position:"relative",zIndex:"100"
                            }}
                >

                    {
                    showTag
                    ?<div style={{backgroundColor:"white",
                             position:"absolute",top:"2.5rem"
                            }}
                    >
                    
                        <div style={priceLevelHead}>    
                                <div style={priceLevelHeadCell}>    
                                        เครื่องหมาย/mark
                                </div>
                                <div style={priceLevelHeadCell}>    
                                        ราคา/price
                                </div>

                                <div style={priceLevelHeadCell}>    
                                        หมายเหตุ/remark
                                </div>
                        </div>


                        {
                 
                            v.priceLevel.map((l,index3)=>{
                                return(
                                <div className='bill-p'
                                    key={`priceLevel-${index3}`} 
                                    style={priceLevelBody}
                                    onClick={e=>{
                                        
                                        if(l.price){
                                                let tempPrice=v.price

                                                //calDigit
                                                let myvalue=parseInt(l.price*calDigit)/calDigit
                                                //}

                                                if(l.mark=="+"){
                                                    tempPrice=tempPrice+myvalue
                                                }
                                                else if(l.mark=="-"){
                                                    tempPrice=tempPrice-myvalue
                                                }
                                                else if(l.mark=="+%"){
                                                    const temp1percent=tempPrice/100
                                                    const tempValue1=temp1percent*myvalue
                                                    const tempValue2=tempPrice+tempValue1
                                                    tempPrice=parseInt(tempValue2*calDigit)/calDigit
                                                }
                                                else if(l.mark=="-%"){
                                                    const temp1percent=tempPrice/100
                                                    const tempValue1=temp1percent*myvalue
                                                    const tempValue2=tempPrice-tempValue1
                                                    tempPrice=parseInt(tempValue2*calDigit)/calDigit
                                                }
                                                else{
                                                    tempPrice=myvalue
                                                }

                                                const tempInputState={...v,

                                                    ["price"]:tempPrice,
                                                    ["result"]:parseInt(tempPrice*v.quantity*calDigit)/calDigit
                                                }

                                                //const tempInputState={...inputState,['price']:l.price}
                                                //console.log('tempInputState')
                                                // //console.log(tempInputState)

                                                //setInputState(tempInputState)
                                                setShowTag(false)
                                                updateFilterDataFunc(idx,tempInputState,true)
                                        }
                                        
                                    
                                    }}

                        
                                >    
                                    <div style={priceLevelHeadCell}>    
                                        {l.mark}
                                    </div>

                                    <div style={priceLevelHeadCell}>    
                                        {l.price}
                                    </div>
            
                                    <div style={priceLevelHeadCell}>    
                                        {l.remark}
                                    </div>
                                </div>
                                )
                            })
                            
                        }

                    </div>
                    :null
                    }


                    <div className="w-15" 
                           style={{display:"flex",
                                   justifyContent:"center",
                                   alignItems:"center"
                                }}
                    >     
                            <FaTags
                                onClick={e=>{
                                    setShowTag(!showTag)
                                }}
                            />
                    </div>

                    <div className='w-80'>
                        <input
                            style={{width:"100%"}} 
                            type="number"
                            value={data}

                            onChange={e=>{
                                //console.log('e.target.value')
                                //console.log(e.target.value)

                                const temp=checkPriceAndQuantity(v,b,e.target.value,calDigit)

                                //console.log('temp')
                                //console.log(temp)

                                if(temp){
                                    updateFilterDataFunc(idx,temp,true)
                                }

                            }}

                        />
                    </div>
                </div>
            )
        }
      
        else if(b=="quantity"){
            return  <div className="w-70"
                        style={{
                            display:"flex",
                            justifyContent:"flex-end",
                            margin:"0",
                    }}>

                        <input       
                            style={{width:"100%"}} 
                            type="number"
                            value={data}

                            onChange={e=>{
                                //console.log('e.target.value')
                                //console.log(e.target.value)

                                const temp=checkPriceAndQuantity(v,b,e.target.value,calDigit)

                                //console.log('temp')
                                //console.log(temp)

                                if(temp){
                                    updateFilterDataFunc(idx,temp,true)
                                }

                            }}
                        />

                    </div>  
        }

        else if(b=="point"){
            return  <div className="w-70"
                        style={{
                            display:"flex",
                            justifyContent:"flex-end",
                            margin:"0",
                    }}>

                        <input       
                            style={{width:"100%"}} 
                            type="number"
                            value={data}

                            onChange={e=>{
                                //console.log('e.target.value')
                                //console.log(e.target.value)

                                const temp=checkPriceAndQuantity(v,b,e.target.value,calDigit)
 
                                //console.log('temp')
                                //console.log(temp)

                                if(temp){
                                    updateFilterDataFunc(idx,temp,true)
                                }

                            }}
                        />

                </div>  
        }

        else if(type=="number"){
            return <div className="w-70"
                    style={{
                              display:"flex",
                              justifyContent:"flex-end",
                              margin:"0",
                            }}>

                        <input       
                            style={{width:"100%"}} 
                            type="number"
                            value={data}


                        onKeyDown={e=>{
                            if(b=="id"){
                                findProductIdByKeyDown(e,b,data,idx)
                            }
                           
                        }}

                            onChange={e=>{
                                updateFilterDataFunc(idx,{...v,[b]:e.target.value})

                            }}
                            disabled={b=="result"||b=="priceIncludeVat"||
                                      b=="resultIncludeVat"||b=="resultPoint"?"disabled":null}
                        />

                </div>  
        
        }
        else if(type=="string"){
            return <div className='w-70'
                      style={{display:"flex",
                              justifyContent:"flex-end",
                              margin:"0",
                            }}

                >
                    <input
                        style={{maxWidth:"100%"}}
                        type="text"
                        value={data}

                        onKeyDown={e=>{
                           
                            if(b=="barcode"){
                                findBarcodeByKeyDown(e,b,data,idx)
                            }
                        }}


                        onChange={e=>{
                            updateFilterDataFunc(idx,{...v,[b]:e.target.value})
                        }}
                    />
                </div>
              
        }

        else{
            return(
                <div>null</div>
            )
        }
    
    }


    //---------------------------------

    const renderSelectOption=(b)=>{
        
        if(basicData){
            return basicData[b].map((i,indexI)=>(
                    <option key={`a-${indexI}`} value={i}>{i}</option>
                )
            )
        }
        else {
            return null
        }
    }
    //<option selected="selected"> {inputState[j]}</option>
    
    
    const renderSelect=(idx,b,data,v)=>{
        return(
            <select className="w-70"
                style={{height:"100%",backgroundColor:"white"}} 
                defaultValue={data}
                onChange={e=>{
                    updateFilterDataFunc(idx,{...v,[b]:e.target.value})
                }}
            >
                <option value="" hidden>...</option>
                
                {
                    renderSelectOption(b)
                }
            </select>       
        )
    }

    

    //---------------------------------
    const genColor=(idx)=>{
        let tempColor
        const temp=idx%4
        if(temp==0){tempColor="darkblue"}
        else if(temp==1){tempColor="brown"}
        else if(temp==2){tempColor="darkgreen"}
        else {tempColor="black"}

        return tempColor
    }   


    const renderGridContent=(v,idxV)=>{
    
        return tempArray.map((b,idxB)=>(

            <div className="w-100" 
                 style={{display:"flex",
                        
                        alignItems:"baseline",
                        color:genColor(idxB),
                        wrap:"nowrap",
                        marginTop:"0.3rem"
                    }}
                
                
                 onClick={e=>{
                    if(b=="selectedLine"){
    
                    }
                    else {
                        setEditData(v)
                    }
                }}
            >
                {b=="selectedLine"
                ?null
                :<div 
                    className={checkEditData(v)&&useInput?"w-30":""}
                    style={{
                        marginRight:"0.2rem"
                    }}
                >{`${filterData.tableTemplate[b].lb}:`}</div>
                }
                
                {   useInput&&checkEditData(v)
                    ?showContent2(
                        b,
                        filterData.tableTemplate[b],
                        v[b],
                        v,
                        idxV,
                        updateFilterDataFunc
                    )
                    :showContent(b,filterData.tableTemplate[b],v[b],v,idxV)
                }
    
            </div>
        ))
    }
    



    
    //---------------------------------------
    const checkEditData=(v)=>{
        let checkResult=false
        if(filterData.editData&&v){
            if(filterData.editData._id==v._id){
                checkResult=true
            }
        }
        return checkResult
    }
    //--------------------------------------
    const genBgColor=(v)=>{
        let temp="#bbc9a5"
        if(filterData.editData&&v){
            if(filterData.editData._id==v._id){
                temp="#DFCFBE" 
            }
        }
    
        return temp
    }
    //--------------------------------------------
    const renderGridImage=()=>{

        return filterData.data0.map((v,idxV)=>{
            //console.log(v)
            let tempCheck=false
            if(v.photoUrl1){
                if(v.photoUrl1.length>0){
                    if(v.photoUrl1[0]){
                        //console.log(v.photoUrl1[0])
                        tempCheck=true
                    }
                }
            }
    
            return(
                <div key={idxV}
                     className="xc4 lc3 mc2 sc1"
                     style={{ padding:"0.4rem",
                              margin:"0.5rem",
                              minHeight:"300%",
                              borderRadius:"5px",
    
                              minWidth:checkEditData(v)&&useInput?"35vh":"35vh",
                              //minHeight:"60vh",
                              //margin:"0.3rem",
                              boxShadow:"2px 5px 5px 2px #444",
                              backgroundColor:genBgColor(v),
                     }}
                     onClick={e=>{
                     }}
                    
                >
                    {
                    tempCheck
                    ?<div className="w-100 flex-center-center" 
                         style={{height:"30vh",width:"95%",margin:"auto"}}
                    >
                        
                        <Galleryone imgarrs={v.photoUrl1} 
                        width={"100%"}
                        
                        />
    
                    </div>
                    :null
                    
                    }
    
    
                    <div className="w-100" 
                         style={{paddingBottom:"1rem"}}
                    >   
                       
                        {
                        renderGridContent(v,idxV)
                        }
                    </div>
    
    
                </div>
            )
            })
    }

    //---------------------------------
    let temp=Object.keys(filterData.tableTemplate)
    let tempArray=[]

    temp.map(i=>{
        const tempCheck=filterData.tableTemplate[i].showCol
        if(tempCheck){
            if(i=="photoUrl1"||i=="photoUrl2"
            ){}
            else{
                tempArray=[...tempArray,i]
            }
        }
    })

    
    //----------------------------------
    return filterData.data0
        ?<div  
            style={{display:"flex",height:"100%",overflow:"auto"}}>
            {
             renderGridImage()
            }
        </div>
        :null

}

export default renderGrid

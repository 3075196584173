import React from 'react';
import axios from '../../util/axiosVerify'
import uuid from 'react-uuid'
import axiosUtil from '../../util/axiosUtil'
import pageUtil from '../../component/pageComponent/pageUtil'
import {MainContext} from '../../context/MainContext'
import renderCustomerConfirm from './renderCustomerConfirm';
import renderTransactionConfirm from './renderTransactionConfirm';

import {FaBan} from 'react-icons/fa';
import {MdPrint} from 'react-icons/md'

import LogOutTool from '../login/LogOutTool';
import {Link} from 'react-router-dom';

import ModalConfirm from '../../render/ModalConfirm';
import ModalConfirmReload from '../../render/ModalConfirmReload'

import inputState from '../../component/table/inputState'
import Table from '../../component/table/Table'


import filterDataTemplate from './filterDataTemplate'

import renderWidthRangeBar from '../../component/table/renderWidthRangeBar'

import BillGroupComponent from './BillGroupComponent';
import BillProductGridComponent from './BillProductGridComponent';
import BillTableComponent from './BillTableComponent';
import BillForm from './BillForm'
import PartnerForBill from './PartnerForBill'
import TransactionForBill from './TransactionForBill';
import BillMenuComponent from './BillMenuComponent';
import BillBarcode from './BillBarcode'
import BillQueue from './BillQueue'
import BillKitchen from './BillKitchen'

import RenderPrintOut from '../../render/renderForm/RenderPrintOut'
import renderModalError from '../../render/renderModalError';
import renderBadgeForBill from './renderBadgeForBill'

import Ticon from '../../component/ticon/Ticon'
import console from '../../util/consoleVerify'

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete
} = pageUtil

const {sleep,catchErrorToMessage,genInt,genFD}=axiosUtil


function Bill() {

//console.log('Bill')

const {basicDataSt,
        setReloadBasicData,
        myheader,
        setBasicData,
        tokenSt,
        setReloadCheckToken,
        
        
        mainContextBill,setMainContextBill,
        
        //widthLeft,setWidthLeft,
}=React.useContext(MainContext)


const [transactionType,setTransactionType]=React.useState({
    data0:null,
    count:0,
    lastRecordId:0,
})

const [printPage,setPrintPage]=React.useState({
    data0:null,
    count:0,
    lastRecordId:0,
})

const shouldRender= React.useRef(true)
React.useEffect(()=>{

    axios.post(`/p35transactiontype/getlimit`,
            {pageNumber:1,limitRow:50,sort:{id:1}},
            myheader,shouldRender)
    .then(result=>{
        //console.log('result.data....')
        //console.log(result.data)

        let tempArray=[]

        if(result.data.data){
            result.data.data.map(i=>{
                tempArray=[...tempArray,{...i,transactionTypeId:i.id}]
            })
        }
        setTransactionType({
            data0:tempArray,
            count:result.data.count,
            lastRecordId:result.data.lastRecordId,
        })

        return  axios.post(`/p35printpage/getlimit`,
                {pageNumber:1,limitRow:50,sort:{id:1}},
                myheader,shouldRender)

        })

        .then(result=>{

            let tempArray=[]

            if(result.data.data){
                result.data.data.map(i=>{
                    tempArray=[...tempArray,{...i,printId:i.id}]
                })
            }

            setPrintPage({
                data0:tempArray,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
            })

        })



    return () => {
        //console.log('end.................Bill................')
        shouldRender.current=false
    } 
},[])





const getIconStyle=()=>{
     
    const toDate=new Date()
    
    let temp1=localStorage.getItem("expirationDate")
    const expirationDate=new Date(temp1)
    
    if(!temp1){
       return null
    }

    const temp3=new Date(expirationDate.toISOString())
    temp3.setDate(temp3.getDate()-3)
    const threeDayBeforeExpirationDate=new Date(temp3)

     if(toDate>expirationDate){
        return {color:"red"}
     }
     else if(toDate>threeDayBeforeExpirationDate){
        return {color:"yellow"}
     }
     else{
        return {color:"green"}
     }

 }

const refHome=React.createRef()

const calDigit=100

const {basicData,pageData,tableTemplate,pageFilter,pageFilterForm,user}=basicDataSt

const genDefaultFilterData=()=>{
    //console.log('00000000000000000')
    //console.log(basicDataSt)
    let temp={
        ...filterDataTemplate.bill,
        /*
        data1:{...filterDataTemplate.blankData,
                branchId:basicData.branchId,
                branchName:basicData.branchName
            },
        data2:{...filterDataTemplate.blankData,
                branchId:basicData.branchId,
                branchName:basicData.branchName
        }
        */
    }

    if(basicData){
        temp={...temp,
              widthLeft:basicData.widthLeft,
              limitRow:basicData.limitRow,
              billMenuLimitRow:basicData.limitRow,
              showTableInBillForm:basicData.showTable
              //showTable:basicData.showTable
            }
    }

    return temp
}

const defaultFilterData=genDefaultFilterData()

const genFilterData=()=>{
    if(mainContextBill.filterData){
        return mainContextBill.filterData
    }
    else {
        return defaultFilterData
    }
}

const buttonLastRef1=React.createRef()
const buttonLastRef2=React.createRef()
const [moveToLast,setMoveToLast]=React.useState(false)


React.useEffect(()=>{
    if(moveToLast){
        if(filterData.showBillForm1){
            if(buttonLastRef1.current){
                buttonLastRef1.current.click()
            }
        }

        if(filterData.showBillForm2){
            if(buttonLastRef2.current){
                buttonLastRef2.current.click()
            }
        }
        setMoveToLast(false)

    }

},[moveToLast])


let [filterData,setFilterData]=React.useState(genFilterData())

const [filterDataProductInGroupId,setFilterDataProductInGroupId]=React.useState({
    data0:null,
    selectProduct:[],
    pageNumber:1,
    limitRow:300,
    count:0,
    editData:null,
   
})

const [reloadBill,setReloadBill]=React.useState(false)

const [footerHeight,setFooterHeight]=React.useState(25)


/*
        filterData:null,
        partnerForBillFilterData:null,
        billGroupComponentFilterData:null,
        billGroupComponentFilterData2:null,
        transactionForBillFilterData:null,
        billMenuComponentFilterData:null,
        billMenuComponentFilterData2:null,
        showPrintPageSetting,selectProductToBillForm:null,
        selectCustomerToBillForm,resetProductList:null,
        billTableTemplate:null,
        billFormData1:null,
        billFormData2:null
*/


const setShowTableInBillFormFunc=(data)=>{
    setFilterData({
        ...filterData,
        showTableInBillForm:data
    })

}

const setFilterDataProductInGroupIdFunc=(data)=>{
    //console.log('setFilterDataProductDataFunc')
    //console.log(data)
    setFilterDataProductInGroupId({...filterDataProductInGroupId,
        data0:data   
    })
}

const setEditDataOfProductInGroupId=(data)=>{
    setFilterDataProductInGroupId({...filterDataProductInGroupId,
        editData:data   
    })
}

const updateFilterDataFunc=(index,i)=>{
    //console.log('updateFilterDataFunc')
    const temp=updateFilterData(index,i,filterDataProductInGroupId)
    //console.log('temp...............')
    //console.log(temp)
    setFilterDataProductInGroupId({
        ...filterDataProductInGroupId,...temp
    })
    //setFilterDataProductInGroupIdFunc({...filterDataProductInGroupId,...temp})
}


const closeFunc=(data)=>{
    setFilterDataProductInGroupId({
        ...filterDataProductInGroupId,
        data0:null,
        selectProduct:[]
    })
}

const cancelSelectProductFunc=()=>{
    //console.log('cancelSelectProductFunc.............')
    let tempFilterData2=filterDataProductInGroupId
    if(filterDataProductInGroupId.data0){
        if(filterDataProductInGroupId.data0.length>0){
            //console.log('ssssssss')
            let tempArray=[]

            filterDataProductInGroupId.data0.map(i=>{
                tempArray=[...tempArray,{...i,selectedLine:false}]
            })
            tempFilterData2={...tempFilterData2,data0:tempArray}
        }
    }

    setFilterDataProductInGroupId({...tempFilterData2,selectProduct:[]})
}

const captureSelectProductFunc=(selectProduct,qty)=>{
    captureSelectProductInBillGroupComponent(selectProduct,qty)
    
    //cancelSelectProductFunc()
    setFilterDataProductInGroupId({...filterDataProductInGroupId,
            data0:null,
            selectProduct:[]
    })
}



//====================
const genParterForBillFilterData=()=>{
    if(mainContextBill.partnerForBillFilterData){
        return mainContextBill.partnerForBillFilterData
    }
    else{
        return null
    }
}
const [partnerForBillFilterData,setPartnerForBillFilterData]=React.useState(genParterForBillFilterData())

const genBillGroupComponentFilterData=()=>{
    if(mainContextBill.billGroupComponentFilterData){
        return mainContextBill.billGroupComponentFilterData
    }
    else{
        return null
    }
}
const [billGroupComponentFilterData,setBillGroupComponentFilterData]=React.useState(genBillGroupComponentFilterData())

const genBillGroupComponentFilterData2=()=>{
    if(mainContextBill.billGroupComponentFilterData2){
        return mainContextBill.billGroupComponentFilterData2
    }
    else{
        return null
    }
}
const [billGroupComponentFilterData2,setBillGroupComponentFilterData2]=React.useState(genBillGroupComponentFilterData2())

const genTransactionForBillFilterData=()=>{
    if(mainContextBill.transactionForBillFilterData){
        return mainContextBill.transactionForBillFilterData
    }
    else{
        return null
    }
}
const [transactionForBillFilterData,setTransactionForBillFilterData]=React.useState(genTransactionForBillFilterData())

const genBillMenuComponentFilterData=()=>{
    if(mainContextBill.billMenuComponentFilterData){
        return mainContextBill.billMenuComponentFilterData
    }
    else{
        return null
    }
}
const [billMenuComponentFilterData,setBillMenuComponentFilterData]=React.useState(genBillMenuComponentFilterData())

const genBillMenuComponentFilterData2=()=>{
    if(mainContextBill.billMenuComponentFilterData2){
        return mainContextBill.billMenuComponentFilterData2
    }
    else{
        return null
    }
}
const [billMenuComponentFilterData2,setBillMenuComponentFilterData2]=React.useState(genBillMenuComponentFilterData2())
//========================ok
const genShowPrintPageSetting=()=>{
    if(mainContextBill.showPrintPageSetting){
        return mainContextBill.showPrintPageSetting
    }
    else {
        return {
                    show:false,
                    printPageSetting:basicData.printPageSetting
                }
    }
}
const [showPrintPageSetting,setShowPrintPageSetting]=React.useState(genShowPrintPageSetting())


const genSelectProductToBillForm=()=>{
    if(mainContextBill.selectProductToBillForm){
        return mainContextBill.selectProductToBillForm
    }
    else {
        return null
    }
}
const [selectProductToBillForm,setSelectProductToBillForm]=React.useState(genSelectProductToBillForm()) 

const clearSelectOfSelectProduct=()=>{
    setSelectProductToBillForm(null)
    
    //setFilterData({...filterData,
    //    selectOfSelectProduct:null
    //})
    
    setMainContextBill({
        ...mainContextBill,
        selectProductToBillForm:null
    })
}

const genSelectCustomerToBillForm=()=>{
    if(mainContextBill.selectCustomerToBillForm){
        return mainContextBill.selectCustomerToBillForm
    }
    else {
        return null
    }
}
const [selectCustomerToBillForm,setSelectCustomerToBillForm]=React.useState(genSelectCustomerToBillForm()) 


const genResetProductList=()=>{
    if(mainContextBill.resetProductList){
        return mainContextBill.resetProductList
    }
    else {
        return false
    }
}
const [resetProductList,setResetProductList]=React.useState(genResetProductList())


const genBillTableTemplate=()=>{
    if(mainContextBill.billTableTemplate){
        return mainContextBill.billTableTemplate
    }
    else {
        return null
    }
}
const [billTableTemplate,setBillTableTemplate]=React.useState(genBillTableTemplate())


const [printData,setPrintData]=React.useState(null)

React.useEffect(()=>{
    if(printData){
        window.print()
    }
},[printData])

//----------------

const genBillFormData1=()=>{
    if(mainContextBill.billFormData1){
        return mainContextBill.billFormData1
    }
    else {
        return null
    }
}
const [billFormData1,setBillFormData1]=React.useState(genBillFormData1())//data2)

const genBillFormData2=()=>{
    if(mainContextBill.billFormData2){
        return mainContextBill.billFormData2
    }
    else {
        return null
    }
}
const [billFormData2,setBillFormData2]=React.useState(genBillFormData2())

//React.useEffect(()=>{
    //console.log('billFormData1....')
    //console.log(billFormData1)
//},[billFormData1])

//React.useEffect(()=>{
    //console.log('billFormData2....')
    //console.log(billFormData2)
//},[billFormData2])

const setData1=(data)=>{
    //console.log('data1')
    //console.log(data)
    if(billFormData1){
        setBillFormData1({...billFormData1,...data})
    }
}

const setData2=(data)=>{
    //console.log('data2')
    //console.log(data)
    if(billFormData2){
        setBillFormData2({...billFormData2,...data})
    }
}

const setDetail1=(detailData)=>{
    if(billFormData1){
        const tempDetail=[...billFormData1.detail,detailData]
        setBillFormData1({...billFormData1,detail:tempDetail,reCal:true})

    }
}

const setDetail2=(detailData)=>{
    if(billFormData2){
        const tempDetail=[...billFormData2.detail,detailData]
        setBillFormData2({...billFormData2,detail:tempDetail,reCal:true})

    }
}


const setDetail1BillBarcode=(detailData)=>{
    if(billFormData1){
        const tempDetail=[...billFormData1.detail,detailData]
        setBillFormData1({...billFormData1,detail:tempDetail,reCal:true})

        setMoveToLast(true)
    }
}

const setDetail2BillBarcode=(detailData)=>{
    if(billFormData2){
        const tempDetail=[...billFormData2.detail,detailData]
        setBillFormData2({...billFormData2,detail:tempDetail,reCal:true})
     
        setMoveToLast(true)

    }
}




const setDetailWithSelectProduct1=(selectProduct)=>{
    if(billFormData1){
        const tempDetail=[...billFormData1.detail,...selectProduct]
        setBillFormData1({...billFormData1,detail:tempDetail,reCal:true})
    }
}
const setDetailWithSelectProduct2=(selectProduct)=>{
    if(billFormData2){
        const tempDetail=[...billFormData2.detail,...selectProduct]
        setBillFormData1({...billFormData2,detail:tempDetail,reCal:true})
    }
}



const calEndBill=(endBill,total)=>{
    
    let tempGrandTotal=total
            
    let tempEndBill=[]
    let tempTotalObj={
        total:tempGrandTotal,
    }

    endBill.map(i=>{
        if(i.endBillActive){
            let tempEndBillRate=genInt(i.endBillRate,calDigit)

            //console.log('endBillRate')

            //console.log(tempEndBillRate)
            let tempEndBillAmount=0
            if(i.endBillInPercentage){
                //console.log('tempTotalObj[i.endBillInPercentageOf]')
                //console.log(tempTotalObj[i.endBillInPercentageOf])

                const temp=tempEndBillRate*tempTotalObj[i.endBillInPercentageOf]/100
                //console.log('temp')
                //console.log(temp)
                tempEndBillAmount=genInt(temp,calDigit)
                //console.log('44444')
                //console.log(tempEndBillAmount)
            }
            else{
                tempEndBillAmount=tempEndBillRate
            }
            //console.log('tempEndBillAmount')
            //console.log(tempEndBillAmount)
            if(i.endBillEffect==="+"){
                tempGrandTotal=tempGrandTotal+tempEndBillAmount
            }
            if(i.endBillEffect==="-"){
                tempGrandTotal=tempGrandTotal-tempEndBillAmount
            }

            tempTotalObj={...tempTotalObj,
                [i.endBillId]:tempGrandTotal
             }

             tempEndBill=[...tempEndBill,
                {  ...i,
                   endBillRate:tempEndBillRate,
                   endBillAmount:tempEndBillAmount
                }
             ]                 
        }
    })
    return {grandTotal:tempGrandTotal,
            endBill:tempEndBill
           }

           
}
//calEffect(effect,tempTotal,tempTotalPoint,tempCalEndBill),
// effect:calEffect(effect,tempTotal,tempTotalPoint,tempGrandTotal,tempEndBill),
const calEffect=(effect,total,totalPoint,grandTotal,endBill)=>{
    //console.log('effect.....')
    //console.log(effect)

    //console.log('total....')
    //console.log(total)

    //console.log('totalPoint...')
    //console.log(totalPoint)


    //console.log('grandTotal...')
    //console.log(grandTotal)


    //console.log('endBill...')
    //console.log(endBill)


    let tempArray=[]

    if(effect){
        if(effect.length){
            effect.map(i=>{
                let tempEffectAmount=0
                    
                if(i.effectId>1){

                    if(i.effectId==2){ //
                        if(i.effectSign=="+"){
                            tempEffectAmount=totalPoint
                        }
                
                        if(i.effectSign=="-"){
                            tempEffectAmount=0-totalPoint
                        }
                    }
                    else if(i.effectId>=3&&i.effectId<=7){
                        if(i.effectSign=="+"){
                            tempEffectAmount=grandTotal
                        }
                
                        if(i.effectSign=="-"){
                            tempEffectAmount=0-grandTotal
                        }
                    }
                    

                    
                }
        
                tempArray=[...tempArray,{...i,effectAmount:tempEffectAmount}]
            })
        }
    }

    return tempArray

}


//----------------

React.useEffect(()=>{
    //console.log('basicDataSt....Effect')
    //console.log(basicDataSt)
        if(basicDataSt.tableTemplate){    
            setBillTableTemplate(tableTemplate.productDetailTableTemplateForForm)
        }
    
        if(basicDataSt.basicData){
            //console.log('have basicData===========')
            //console.log(basicDataSt.basicData)
            setFilterData({
                ...filterData,
                widthLeft:basicDataSt.basicData.widthLeft,
                limitRow:basicDataSt.basicData.limitRow,
                billMenuLimitRow:basicDataSt.basicData.limitRow
            })
        }

},[basicDataSt])

const setShowRange=(data)=>{setFilterData({...filterData,showRange:data})}
const setWidthLeft=(data)=>{setFilterData({...filterData,widthLeft:data})}
const setHeightTop=(data)=>{setFilterData({...filterData,heightTop:data})}

React.useEffect(()=>{

    //console.log('filterData.........Bill')
    //console.log(filterData)

        if(filterData.reloadData){
                //console.log('filterData.reloadData')
                let tempArray=[]
            
                /*
                basicData.table.map(i=>{
                    if(i.tableActive){
                        tempArray=[...tempArray,{table:i.tableName,tableStatus:"open"}]
                    }
                })
                //{tableStatus:"open"},

                */
                tempArray=[
                            {queueStatus:"open"},
                            {tableStatus:"open"},
                        ]
                //console.log('tempArray')
                //console.log(tempArray)
                //const promise1=axios.post('/p35product/getlimit',{},myheader,shouldRender)
                axios.post('/p35transaction/getlimitfew',
                    {limitRow:100,$or:tempArray},
                    myheader,shouldRender
                )   
                .then(result=>{
                    //console.log('result......getlimitfew..=====...reloadData')
                    //console.log(result.data)

                    const temp=genFilterDataWithIndex(result.data.data)
                    //console.log(temp)

                    const tempTransaction=temp.tableOpen
                    //console.log('result tempTransation')
                    //console.log(tempTransaction)

                    setFilterData({...filterData,
                        reloadData:false,
                        count:result.data.count,
                        lastRecordId:result.data.lastRecordId,
                        transactionArray:tempTransaction,
                        queueArray:temp.queueOpen
                    })
                })
                .catch(error=>{
                    //console.log('error')
                    
                    //console.log(error)
                    const tempError={...filterData,
                        reloadData:false,
                        message:catchErrorToMessage(error),
                        showModalConfirm:false,
                        showModalError:true,
                    }
                    setFilterData(tempError)
                })
        }

        if(filterData.reloadSelectProduct){
            //console.log('filterData.qryProduct.............')
            //console.log(filterData.qryProduct)
        
            axios.post('/p35product/getlimit',
                    {limitRow:100,sort:{id:1},...filterData.qryProduct},myheader,shouldRender)   
            .then(result=>{

                let tempArray=[]

                result.data.data.map((i,idx)=>{
                    //const temp={...i,tempIndex:idx,selectedLine:false}
                    let tempStocking={}
    
                    if(i.stocking){
                        let isSearching=true
                        i.stocking.map(k=>{
                            if(isSearching){
                                if(k.branchId==user.branchId){
                                    tempStocking={
                                        stock:k.stock,
                                        minStock:k.minStock,
                                        maxStock:k.maxStock,
                                    
                                    }
                                    isSearching=false
                                }
                            }   
                        })
                    }
    
    
                    const temp={...i,selectedLine:false,_id:uuid(),...tempStocking}
                    tempArray=[...tempArray,temp]
    
                })


                setFilterData({...filterData,
                    selectProduct:tempArray,//result.data.data,
                    reloadSelectProduct:false
                })
            })
            .catch(error=>{
                //console.log('error')
                //console.log(error)
                const tempError={...filterData,
                    reloadSelectProduct:false,
                    message:catchErrorToMessage(error),
                    showModalConfirm:false,
                    showModalError:true,
                }
                setFilterData(tempError)
            })

        }
},[filterData])

React.useEffect(()=>{
        if(filterData.data1){
            setBillFormData1(filterData.data1)
        }
},[filterData.data1])

React.useEffect(()=>{
        if(filterData.data2){
            setBillFormData2(filterData.data2)
        }
},[filterData.data2])


const setBillFormData1Func=(data)=>{
    setFilterData({...filterData,
        data1:data
    })
}
const setBillFormData2Func=(data)=>{
    setFilterData({...filterData,
        data2:data
    })
}

const genFilterDataWithIndex=(filterData)=>{

    let tempArray=[]
    let tempTableArray=[]
    let tempQueueArray=[]


    basicData.table.map(i=>{
        if(i.tableActive){
            tempArray=[...tempArray,i.tableName]
        }
    })

    filterData.map((i,idx)=>{         
        const temp={...i,_id:uuid()}

        tempArray.map(j=>{
            if(j==i.table&&i.tableStatus=="open"){
                tempTableArray=[...tempTableArray,temp]
            }
        })

        if(i.queueStatus=="open"){
            tempQueueArray=[...tempQueueArray,temp]
        }
        //const temp={...i,tempIndex:idx,selectedLine:false}
    })

    return {tableOpen:tempTableArray,queueOpen:tempQueueArray}
}


const printFunc=(inputState)=>{
    //console.log('printFunc....')
    //console.log(inputState)
    
    let temp 
    //console.log("filterData.....")
    //console.log(filterData)

    //console.log('billFormData1')
    //console.log(billFormData1)

    //console.log('billFormData2')
    //console.log(billFormData2)
    
    if(filterData.showBillForm1){
        setPrintDataFunc(null)

        temp={
                cash:inputState.cash,
                change:inputState.change,
                grandTotal:inputState.grandTotal,
                paymentType:inputState.paymentType
            }

        setTimeout(() => {
            setPrintDataFunc({
                ...billFormData1,
                ...temp
            })
        }, 400)
    }

    if(filterData.showBillForm2){
        setPrintDataFunc(null)

        temp={
                cash:inputState.cash,
                change:inputState.change,
                grandTotal:inputState.grandTotal,
                paymentType:inputState.paymentType
            }

        setTimeout(() => {
            setPrintDataFunc({
                    ...billFormData2,
                    ...temp
            })
        }, 400)
    }

}

/*
const runIsGenIdOfBillForm1=(value)=>{
    //console.log(`runIsGenIdOfBillForm1 ${value}`)
    setFilterData({...filterData,
        ["isGenIdOfBillForm1"]:true,
        ["widthLeft"]:50
    })
}

const runIsGenIdOfBillForm2=(value)=>{
    //console.log(`runIsGenIdOfBillForm2 ${value}`)
    setFilterData({...filterData,
        ["isGenIdOfBillForm2"]:true,
        ["widthLeft"]:50
    })
}
*/

const captureBillFromTable=(data)=>{
    //console.log('captureBillFromTable')
    //console.log(data)
    let tempArray=[]

    if(data){
        if(data.detail){
            if(data.detail.length){
                
                data.detail.map(i=>{
                    tempArray=[...tempArray,{...i,selectedLine:true}]
                })
            }
        }
    }

    const tempData={...data,detail:tempArray}

    if(filterData.showBillForm1){
        setFilterData({...filterData,
            data1:{...tempData,isGenIdOfBillForm:false},
            showBillForm1:false,
            reloadBillForm1:true,
            //isGenIdOfBillForm1:false,
            //showModalFromTable:true
        })
    }
    if(filterData.showBillForm2){
        setFilterData({...filterData,
            data2:{...tempData,isGenIdOfBillForm:false},
            showBillForm2:false,
            reloadBillForm2:true,
            //isGenIdOfBillForm2:false,
            //showModalFromTable:true
        })
    }
    //setEditData(data)
}

const captureProductOfGroup=(data0)=>{

    //console.log('value.data0')
    //console.log(value.data0)
    setFilterData({...filterData,
        selectProduct:data0,

        showParter:false,
        showGroup:false,
        showProductGrid:true,
        showTable:false,
        showTransaction:false,
        showBillMenu:false,
        showBarcode:false,
        showQueue:false,
        showKitchen:false,
    })
    
}

const setShowGroupFunc=()=>{
    setFilterData({...filterData,

        showParter:false,
        showGroup:true,
        showProductGrid:false,
        showTable:false,
        showTransaction:false,
        showBillMenu:false,
        showBarcode:false,
        showQueue:false,
        showKitchen:false,

    })
}

const setShowProductGridFunc=()=>{
    setFilterData({...filterData,

        showParter:false,
        showGroup:false,
        showProductGrid:true,
        showTable:false,
        showTransaction:false,
        showBillMenu:false,
        showBarcode:false,
        showQueue:false,
        showKitchen:false,

    })
}

const setShowTableFunc=()=>{
    setFilterData({...filterData,

        showParter:false,
        showGroup:false,
        showProductGrid:false,
        showTable:true,
        showTransaction:false,
        showBillMenu:false,
        showBarcode:false,
        showQueue:false,
        showKitchen:false,


    })
}

const setShowPartnerFunc=()=>{
    setFilterData({...filterData,

        showParter:true,
        showGroup:false,
        showProductGrid:false,
        showTable:false,
        showTransaction:false,
        showBillMenu:false,
        showBarcode:false,
        showQueue:false,
        showKitchen:false,

    })
}

const setShowTransactionFunc=()=>{
    setFilterData({...filterData,

        showParter:false,
        showGroup:false,
        showProductGrid:false,
        showTable:false,
        showTransaction:true,
        showBillMenu:false,
        showBarcode:false,
        showQueue:false,
        showKitchen:false,

    })
}


const setShowBarcodeFunc=()=>{
    setFilterData({...filterData,

        showParter:false,
        showGroup:false,
        showProductGrid:false,
        showTable:false,
        showTransaction:false,
        showBillMenu:false,
        showBarcode:true,
        showQueue:false,
        showKitchen:false,

    })
}

const setShowQueueFunc=()=>{
    setFilterData({...filterData,

        showParter:false,
        showGroup:false,
        showProductGrid:false,
        showTable:false,
        showTransaction:false,
        showBillMenu:false,
        showBarcode:false,
        showQueue:true,
        showKitchen:false,

    })
}


const setShowBillMenuFunc=()=>{
    ///console.log('setShowBillMenuFunc')
    setFilterData({...filterData,

        showParter:false,
        showGroup:false,
        showProductGrid:false,
        showTable:false,
        showTransaction:false,
        showBillMenu:true,
        showBarcode:false,
        showQueue:false,
        showKitchen:false,

    })
}

const setShowKitchenFunc=()=>{
    ///console.log('setShowBillMenuFunc')
    setFilterData({...filterData,

        showParter:false,
        showGroup:false,
        showProductGrid:false,
        showTable:false,
        showTransaction:false,
        showBillMenu:false,
        showBarcode:false,
        showQueue:false,
        showKitchen:true,

    })
}


const setFilterDataFunc=(obj)=>{
    setFilterData({
        ...filterData,
        ...obj
    })
}


const captureCustomerFunc=(data)=>{
    const {id,title,name,partnerType,phone,contact,address,delivery}=data
    
    setFilterData({...filterData,
        showCustomerConfirm:true,
        customerConfirm:{
            partnerId:id,
            partnerType:partnerType,

            title:title,
            name:name,
            phone:phone,
            contact:contact,
            address:address,

            recipientTitle:title,
            recipientName:name,
            recipientPhone:phone,
            recipientContact:contact,
            recipientAddress:address,

            delivery:delivery,

        }
    })

}

const captureBillMenuFunc=(editData)=>{
    //console.log('captureBillMenuFunc')
    //console.log(editData)

    axios.post('/p35transaction/getlimit',
    {limitRow:1,
     id:editData.id},
     myheader,shouldRender)   
    .then(result=>{
        //console.log('result.data.data')

        //console.log(result.data.data)
        const foundTransaction=result.data.data[0]

        
                let tempArray=[]

                if(foundTransaction){
                    if(foundTransaction.detail){
                        if(foundTransaction.detail.length){
                            
                            foundTransaction.detail.map(i=>{
                                tempArray=[...tempArray,{...i,selectedLine:true}]
                            })
                        }
                    }
                }

                const tempEditData={...foundTransaction,detail:tempArray}

                if(filterData.showBillForm1){
                    setFilterData({
                        ...filterData,
                        //data1:{...filterData.data1,isGenIdOfBillForm:false},
                        editTransaction:tempEditData,
                        showTransactionConfirm:true,
                        //isGenIdOfBillForm1:false    
                    })
                }
                if(filterData.showBillForm2){
                    setFilterData({
                        ...filterData,
                        //data2:{...filterData.data2,isGenIdOfBillForm:false},
                        editTransaction:tempEditData,
                        showTransactionConfirm:true,
                        //isGenIdOfBillForm2:false    
                    })
                }
    

        /*
        if(filterData.showBillForm1){
            setFilterData({
                ...filterData,
                //data1:{...filterData.data1,isGenIdOfBillForm:false},
                editTransaction:tempEditData,
                showTransactionConfirm:true,
                //isGenIdOfBillForm1:false    
            })
        }
        if(filterData.showBillForm2){
            setFilterData({
                ...filterData,
                //data2:{...filterData.data2,isGenIdOfBillForm:false},
                editTransaction:tempEditData,
                showTransactionConfirm:true,
                //isGenIdOfBillForm2:false    
            })
        }
        */
    })


}


const captureTransactionFunc=(editData)=>{
    //console.log('captureTransactionFunc editData...')
    //console.log(editData)
    let tempArray=[]

    if(editData){
        if(editData.detail){
            if(editData.detail.length){
                
                editData.detail.map(i=>{
                    tempArray=[...tempArray,{...i,selectedLine:true}]
                })
            }
        }
    }

    const tempEditData={...editData,detail:tempArray}

    if(filterData.showBillForm1){
        setFilterData({
            ...filterData,
            //data1:{...filterData.data1,isGenIdOfBillForm:false},
            editTransaction:tempEditData,
            showTransactionConfirm:true,
            //isGenIdOfBillForm1:false    
        })
    }
    if(filterData.showBillForm2){
        setFilterData({
            ...filterData,
            //data2:{...filterData.data2,isGenIdOfBillForm:false},
            editTransaction:tempEditData,
            showTransactionConfirm:true,
            //isGenIdOfBillForm2:false    
        })
    }

}

const captureProductListFromSelectProductInProductGrid=(data)=>{
    //console.log('captureProductListFromSelectProductInProductGrid')


    if(data){
        if(data.length>0){

                let tempUseInnerVat=false
                let tempInnerVatRate=0

                if(filterData.showBillForm1){
                    if(billFormData1){
                        if(billFormData1.useInnerVat){
                            tempUseInnerVat=billFormData1.useInnerVat

                            if(billFormData1.innerVatRate>0||billFormData1.innerVatRate<0){
                                tempInnerVatRate=parseInt(billFormData1.innerVatRate*calDigit)/calDigit
                            }
                        
                        }
                    }
                }
                
                if(filterData.showBillForm2){
                    if(billFormData2){
                        if(billFormData2.useInnerVat){
                            //console.log(billFormData2)
                            tempUseInnerVat=billFormData2.useInnerVat
                            
                            if(billFormData2.innerVatRate>0||billFormData2.innerVatRate<0){
                                tempInnerVatRate=parseInt(billFormData2.innerVatRate*calDigit)/calDigit
                            }
                        
                        }
                    }
                }


                let tempArray=[]

                data.map(i=>{
                    //if(i.quantity>0){
                        //console.log('i=')
                        //console.log(i)
                        let tempPrice=i.price
                        let tempResult=parseInt(i.quantity*i.price*calDigit)/calDigit

                        let tempPriceIncludeVat=0
                        let tempResultIncludeVat=0

                        const tempResultPoint=parseInt(i.quantity*i.point*calDigit)/calDigit
    
                        if(tempUseInnerVat){
                            const tempPr=(i.price*tempInnerVatRate)/100+i.price
                            tempPriceIncludeVat=parseInt(tempPr*calDigit)/calDigit
                            tempResultIncludeVat=parseInt(i.quantity*tempPriceIncludeVat*calDigit)/calDigit

                            //tempPrice=tempPriceIncludeVat
                        }
    
                        const tempObj={...i,
                                    selectedLine:true,
                                    result:tempResult,
                                    resultIncludeVate:tempResultIncludeVat,
                                    resultPoint:tempResultPoint,
                                    _id:uuid(),
                                    jobStatus:"open",
                                    selectedLine: true,
                                    priceIncludeVat:tempPriceIncludeVat,
                                    price:tempPrice

                                }
                        tempArray=[...tempArray,tempObj]
                    //}
                })
                
                setSelectProductToBillForm(null)

                setFilterData({...filterData,
                    selectOfSelectProduct:tempArray
                })
                //setSelectOfSelectProduct(tempArray)
        }
    }    
}

const captureSelectProductInBillGroupComponent=(selectProduct,qty)=>{
    //console.log('captureSelectProductInBillGroupComponent...')


    //console.log(selectProduct)
    //console.log(qty)
    if(selectProduct){
        if(selectProduct.length>0){
            //console.log('000000000000')

            let tempUseInnerVat=false
            let tempInnerVatRate=0

            if(filterData.showBillForm1){
                    if(billFormData1){
                        if(billFormData1.useInnerVat){
                            tempUseInnerVat=billFormData1.useInnerVat

                            if(billFormData1.innerVatRate>0||billFormData1.innerVatRate<0){
                                tempInnerVatRate=parseInt(billFormData1.innerVatRate*calDigit)/calDigit
                            }
                        
                        }
                    }
            }
            if(filterData.showBillForm2){
                    if(billFormData2){
                        if(billFormData2.useInnerVat){
                            //console.log(billFormData2)
                            tempUseInnerVat=billFormData2.useInnerVat
                            
                            if(billFormData2.innerVatRate>0||billFormData2.innerVatRate<0){
                                tempInnerVatRate=parseInt(billFormData2.innerVatRate*calDigit)/calDigit
                            }
                        
                        }
                    }
            }
            //console.log(tempUseInnerVat)
            //console.log(tempInnerVatRate)

            let tempArray=[]

            selectProduct.map(i=>{
                const {_id,...remaining}=i

                if(i.selectedLine){
                   
                    //let tempPriceExcludeVat=i.price
                    let tempPriceIncludeVat=0
                    let tempResultIncludeVat=0

                    let tempPrice=i.price

                    let tempQuantity=1
                    let tempResult=parseInt(i.price*calDigit)/calDigit
                    let tempResultPoint=parseInt(i.point*calDigit)/calDigit
                    

                    if(tempUseInnerVat){
                        const tempPr=(i.price*tempInnerVatRate)/100+i.price
                        tempPriceIncludeVat=parseInt(tempPr*calDigit)/calDigit
                        tempResultIncludeVat=tempPriceIncludeVat
                        //tempResult=tempPriceIncludeVat
                        //tempPrice=tempPriceIncludeVat
                    }

                    //console.log(tempPriceIncludeVat)

                    if(qty=="stock"){
                        i.stocking.map(j=>{
                            if(j.branchId==basicData.branchId){
                                tempQuantity=j.stock

                                tempResult=tempResult*tempQuantity
                                tempResultPoint=tempResultPoint*tempQuantity
                            }
                        })
                    }

                    
                    const tempObj={...remaining,_id:uuid(),
                        
                        quantity:tempQuantity,
                        result:tempResult,
                        resultIncludeVate:tempResultIncludeVat,
                        resultPoint:tempResultPoint,
                        priceIncludeVat:tempPriceIncludeVat,
                        price:tempPrice
                    }

                    tempArray=[...tempArray,tempObj]


                }

            })

            //console.log('tempArray...........')
            //console.log(tempArray)

            //console.log('billFormData1')
            //console.log(billFormData1)
            //console.log('billFormData2')
            //console.log(billFormData2)

            let dataIdx=null

            if(filterData.showBillForm1){
                dataIdx=1
            }
            if(filterData.showBillForm2){
                dataIdx=2
            }
            const tempObj={
                dataIdx:dataIdx,
                selectProduct:tempArray
            }

            setSelectProductToBillForm(tempObj)
            setResetProductList(true)

            /*
            if(billFormData1){
                setDetailWithSelectProduct1(tempArray)
            }
            if(billFormData2){
                setDetailWithSelectProduct2(tempArray)
            }
        
            */
        }
    }

    

}



const captureEditProductInBillGroupComponent=(i,qty)=>{
        //console.log('captureEditProductInBillGroupComponent')
        //console.log(i)
        //console.log('1')
        //console.log(billFormData1)
        //console.log('2')
        //console.log(billFormData2)
        const {_id,...remaining}=i
  
   
        const tempQuantity=qty

    
        const tempResult=parseInt(i.price*calDigit)/calDigit
        const tempResultPoint=parseInt(i.point*calDigit)/calDigit
        const tempObj={...remaining,_id:uuid(),
                        quantity:tempQuantity,
                        result:tempResult,
                        resultPoint:tempResultPoint
                    }
        //console.log('tempObj')
        //console.log(tempObj)
        if(billFormData1){
            setDetail1(tempObj)
        }
        if(billFormData2){
            setDetail2(tempObj)
        }
 
}

const captureEditProductToBillToFindByGroupId=(data)=>{
    //console.log('captureEditProductToBillToFindByGroupId')

    if(data.groupId){

        axios.post('/p35product/getlimit',
                {limitRow:filterDataProductInGroupId.limitRow,
                 sort:{id:1},groupId:data.groupId},
                 myheader,shouldRender)   
        .then(result=>{

            let tempArray=[]

            result.data.data.map((i,idx)=>{
                //const temp={...i,tempIndex:idx,selectedLine:false}
                let tempStocking={}

                if(i.stocking){
                    let isSearching=true
                    i.stocking.map(k=>{
                        if(isSearching){
                            if(k.branchId==user.branchId){
                                tempStocking={
                                    stock:k.stock,
                                    minStock:k.minStock,
                                    maxStock:k.maxStock,
                                
                                }
                                isSearching=false
                            }
                        }   
                    })
                }


                const temp={...i,selectedLine:false,_id:uuid(),...tempStocking}
                tempArray=[...tempArray,temp]

            })

            setFilterDataProductInGroupId({
                ...filterDataProductInGroupId,
                data0:tempArray
            })

        })
        .catch(error=>{
            //console.log('error')
            //console.log(error)
            const tempError={...filterData,
                reloadSelectProduct:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            setFilterData(tempError)
        })

    }
}



const customerConfirmSubmitFunc=()=>{

    //console.log('filterData......................ssssssss')
    //console.log(filterData.customerConfirm)
    
    if(filterData.showBillForm1||filterData.showBillForm2){
        
        let dataIdx=null
        if(filterData.showBillForm1){
            dataIdx=1
        }
        if(filterData.showBillForm2){
            dataIdx=2
        }


        /*
        const {partnerId,title,name,partnerType,phone,address,contact,
               selectedAddress,delivery,
               recipientTitle,recipientName,recipientPhone,recipientAddress,recipientContact
              }=filterData.customerConfirm

        const tempObj={
            partnerId:partnerId,
            title:title,
            name:name,
            partnerType:partnerType,
            phone:phone,
            address:address,
        }
        */
       
        const tempCustomer={
            dataIdx:dataIdx,
            selectCustomer:filterData.customerConfirm
        }
           
        setSelectCustomerToBillForm(tempCustomer)     

        setFilterData({...filterData,
            showCustomerConfirm:false,
        })
    }
    
   
}


const customerConfirmCancelFunc=()=>{
    setFilterData({...filterData,
        showCustomerConfirm:false
    })
}
React.useEffect(()=>{
    if(filterData.reloadBillForm1){
        setTimeout(()=>{
            if(shouldRender.current){
                setFilterData({
                    ...filterData,
                    showBillForm1:true,
                    reloadBillForm1:false
                })
        }
        },100)
    }
},[filterData.reloadBillForm1])

React.useEffect(()=>{
    if(filterData.reloadBillForm2){
        setTimeout(()=>{
            if(shouldRender.current){
                setFilterData({
                    ...filterData,
                    showBillForm2:true,
                    reloadBillForm2:false
                })
            }
        },100)
    }
},[filterData.reloadBillForm2])

const transactionConfirmSubmitFunc=()=>{
    let tempEditData={...filterData.editTransaction,isGenIdOfBillForm:false}

    //console.log('transactionConfirmSubmitFunc')
    //console.log(filterData)
  
    if(filterData.showBillForm1){

        if(!filterData.includeTransactionHead){

            tempEditData={...filterData.data1,
                reference:filterData.editTransaction.id,
                detail:[...filterData.data1.detail,
                        ...filterData.editTransaction.detail
                       ],
                
            }
        }

        setFilterData({...filterData,
            data1:tempEditData,
            showTransactionConfirm:false,
            showBillForm1:false,
            reloadBillForm1:true
        })
    }
    else{
        if(!filterData.includeTransactionHead){
            tempEditData={...filterData.data2,
                detail:[...filterData.data2.detail,
                        ...filterData.editTransaction.detail
                       ]
            }
        }

        setFilterData({...filterData,
            data2:tempEditData,
            showTransactionConfirm:false,
            showBillForm2:false,
            reloadBillForm2:true
        })
    }
}


const transactionConfirmCancelFunc=()=>{
    setFilterData({...filterData,
        showTransactionConfirm:false
    })
}


const closeBillForm1=(data)=>{

    setFilterData({...filterData,
        showBillForm1:false,
        data1:data,
        reloadBillForm2:true
    })
}

const closeBillForm2=(data)=>{

    setFilterData({...filterData,
        showBillForm2:false,
        data2:data,
        reloadBillForm1:true
    })
}
const saveTableTemplateFuncForProduct=(tableTemplate)=>{



    saveTableTemplate(tableTemplate,'p35branch','productTableTemplate',myheader,shouldRender)
}


const saveTableTemplateFunc=(tableTemplate)=>{

    //console.log(tableTemplate)
    //console.log(tempObj)
    //setBillTableTemplate(tempObj)
    saveTableTemplate(tableTemplate,'p35branch',
                    'productDetailTableTemplateForForm',myheader,shouldRender)
}

const submitFunctionDeleteFunc=(data)=>{
    setFilterData({...filterData,
        showModalConfirm:true,
        dataToDelete:data
    })
}

const setShowModalConfirmFunc=(data)=>{
    setFilterData({...filterData,
        showModalConfirm:data,
        dataToDelete:null
    })
}

const submitConfirmToDelete=()=>{
    const {dataToDelete}=filterData
    //console.log('delete.......')
    //const temp = await submitFunctionDelete(data,myheader,shouldRender)
    //setFilterData(temp)
    axios.post(`/p35transaction/deletetransaction`,dataToDelete,myheader,shouldRender)
    .then(result=>{
        //console.log('done delete.............')

        if(filterData.showBillForm1){
            const temp={...filterData,
                reloadData:true,
                data1:null,//filterDataTemplate.blankData,
                reloadBillForm1:true,
                showBillForm1:true,//false,
                //isGenIdOfBillForm1:false,
                showModalConfirm:false

            }
            setFilterData(temp)
            setBillFormData1(null)
        }
        if(filterData.showBillForm2){
            const temp={...filterData,
                reloadData:true,
                data2:null,//filterDataTemplate.blankData,
                reloadBillForm2:true,
                showBillForm2:true,//false,
                //isGenIdOfBillForm2:false,
                showModalConfirm:false

            }
            setFilterData(temp)
            setBillFormData2(null)
        }

        setReloadBill(true)
        
    })
    .catch(error=>{
        //console.log('error')
        //console.log(error)
        const tempError={...filterData,
            //reloadData:false,
            message:catchErrorToMessage(error),
            //showModalConfirm:false,
            showModalError:true,
        }
        //console.log(tempError.message)
        setFilterData(tempError)
    })
}

//=====================
//=====================
//=====================
//=====================

const [modalConfirmReloadState,setModalConfirmReloadState]=React.useState({
    show:false,
    filterData:null
})

const cancelConfirmReload=()=>{
    
    if(filterData.showBillForm1){
        setFilterData(
            {...modalConfirmReloadState.filterData,
                reloadData:false,
                reloadBillForm1:false,
            }
        )

        setBillFormData1({...billFormData1,isGenIdOfBillForm:false})
    }

    if(filterData.showBillForm2){
        setFilterData(
            {...modalConfirmReloadState.filterData,
                reloadData:false,
                reloadBillForm2:false,
            }
        )
        setBillFormData2({...billFormData2,isGenIdOfBillForm:false})

    }

    setModalConfirmReloadState({
        filterData:null,
        show:false
    })
}

const confirmReload=(data)=>{

    if(filterData.showBillForm1){
        setFilterData(modalConfirmReloadState.filterData)
        setBillFormData1(null)
    }
    
    if(filterData.showBillForm2){
        setFilterData(modalConfirmReloadState.filterData)
        setBillFormData2(null)
    }

    setModalConfirmReloadState({
        filterData:null,
        show:false
    })
    setSelectProductToBillForm(null)

    setMainContextBill({
       ...mainContextBill,
       selectProductToBillForm:null
    })

    setReloadBill(true)
    //window.location.reload()
}
//============================
//============================
//============================

const submitFunctionEditFunc=async (data)=>{
    //console.log(`edit.......xxxxxxxxxxxxx`)
    //console.log(data)
    //console.log('submitFunctionEditFunc...........')
    //console.log(data)


    const {_id,detail,endBill,...remaining}=data
    let tempArray=[]
    detail.map(i=>{
        const {_id,...remaining2}=i
        tempArray=[...tempArray,remaining2]
    })

    let tempArray2=[]
    endBill.map(i=>{
        const {_id,...remaining3}=i
        tempArray2=[...tempArray2,remaining3]
    })


    const tempObj={...remaining,detail:tempArray,endBill:tempArray2}

    //console.log('tempObj')
    //console.log(tempObj)

    //const temp = await submitFunctionEdit(formInputState,filterData,stateTemplate,myheader,shouldRender)
    //setFilterData(temp)

  
    try{
        const result=await axios.post(`/p35transaction/updatetransaction`,tempObj,myheader,shouldRender)
            

        if(data.file1){
            if(data.file1.length>0){
                 //console.log('there is file')
                const fd=genFD({
                    inputState:{id:data.id,
                                file1:data.file1,
                                photoUrl1:data.photoUrl1,
                                spareBoolean1:true
                            },
                    template:{
                        id:{stType:"number",lb:"รหัสสินค้า"},
                        file1:{stType:"file",lb:"ไฟล์1"},
                        photoUrl1:{stType:"array", lb:"รูป1"},
                    }
                })
            
                const result2=await axios.post(`/p35transaction/updatecustom`,fd,myheader,shouldRender)

            }
       }
       

        let temp=null
            
        if(filterData.showBillForm1){
                temp={...filterData,
                    reloadData:true,
                    data1:null,//filterDataTemplate.blankData,
                    reloadBillForm1:true,
                    showBillForm1:true,//false,
                    //isGenIdOfBillForm1:false,
                }
        }
        
        if(filterData.showBillForm2){
            temp= temp={...filterData,
                reloadData:true,
                data2:null,//filterDataTemplate.blankData,
                reloadBillForm2:true,
                showBillForm2:true,//false,
                //isGenIdOfBillForm2:false,
            }
        }
            
        setModalConfirmReloadState({show:true,filterData:temp})

    }
    catch(error){
        const tempError={...filterData,
            //reloadData:false,
            message:catchErrorToMessage(error),
            //showModalConfirm:false,
            showModalError:true,
        }
        //console.log(tempError.message)
        setFilterData(tempError)
    }
}

const submitFunctionAddFunc=async (data)=>{
 
    //console.log('submitFunctionAddFunc...........')
    //console.log(data)

    
    const {_id,detail,endBill,...remaining}=data
    let tempArray=[]
    detail.map(i=>{
        const {_id,...remaining2}=i
        tempArray=[...tempArray,remaining2]
    })

    let tempArray2=[]
    endBill.map(i=>{
        const {_id,...remaining3}=i
        tempArray2=[...tempArray2,remaining3]
    })



    const tempObj={...remaining,detail:tempArray,endBill:tempArray2}
    
    //console.log('tempObj')
    //console.log(tempObj)
  

    try{
        const result=await axios.post(`/p35transaction/addtransaction`,tempObj,myheader,shouldRender)
        
        if(data.file1){
            //console.log('there is file')
            if(data.file1.length>0){
                const fd=genFD({
                    inputState:{id:data.id,
                                file1:data.file1,
                                photoUrl1:data.photoUrl1,
                                spareBoolean1:true
                            },
                    template:{
                        id:{stType:"number",lb:"รหัสสินค้า"},
                        file1:{stType:"file",lb:"ไฟล์1"},
                        photoUrl1:{stType:"array", lb:"รูป1"},
                    }
                })
            
                const result2=await axios.post(`/p35transaction/updatecustom`,fd,myheader,shouldRender)
            }
       }
       
        let temp=null
        if(filterData.showBillForm1){
            temp={...filterData,
                reloadData:true,
                data1:null,//filterDataTemplate.blankData,
                reloadBillForm1:true,
                showBillForm1:true//false,
                //isGenIdOfBillForm1:false,
            }
        }
        if(filterData.showBillForm2){
            temp={...filterData,
                reloadData:true,
                data2:null,//filterDataTemplate.blankData,
                reloadBillForm2:true,
                showBillForm2:true//false,
                //isGenIdOfBillForm2:false,
            }
        }
        
        setModalConfirmReloadState({show:true,filterData:temp})
    }
    catch(error){
        const tempError={...filterData,
            //reloadData:true,
            message:catchErrorToMessage(error),
            //showModalConfirm:false,
            showModalError:true,
        }
        //console.log(tempError.message)
        setFilterData(tempError)
    }
}

const submitSaveCloseBillFunc=(inputState)=>{

    //console.log('submitSaveCloseBillFunc')
    //console.log(inputState)
    //console.log('billFormData1...')
    //console.log(billFormData1)
    //console.log('billFormData2...')
    //console.log(billFormData2)
    
    if(filterData.showBillForm1){
        if(billFormData1){
            if(billFormData1.isGenIdOfBillForm){
                const tempObj={...billFormData1,
                                    tableStatus:"close",
                                    queueStatus:"close",
                                    cash:inputState.cash,
                                    change:inputState.change,
                                    paymentType:inputState.paymentType
                                }
                submitFunctionAddFunc(tempObj)
            }else{
                const tempObj={...billFormData1,
                                    tableStatus:"close",
                                    queueStatus:"close",
                                    cash:inputState.cash,
                                    change:inputState.change,
                                    paymentType:inputState.paymentType
                                }
                submitFunctionEditFunc(tempObj)
            }
        }
    }
    else{
        if(billFormData2){
            if(billFormData2.isGenIdOfBillForm){
                const tempObj={...billFormData2,
                                    tableStatus:"close",
                                    queueStatus:"close",
                                    cash:inputState.cash,
                                    change:inputState.cash,
                                    paymentType:inputState.paymentType
                               }
                submitFunctionAddFunc(tempObj)
            }else{
                const tempObj={...billFormData2,
                                    tableStatus:"close",
                                    queueStatus:"close",
                                    cash:inputState.cash,
                                    change:inputState.cash,
                                    paymentType:inputState.paymentType
                                }
                submitFunctionEditFunc(tempObj)
            }
        }
    }


}
//======================================
const updateQueueFunc=(e,data)=>{
    
    if(e.target.value=="close"){
        const {_id,...remaining}=data

        let tempObj={...remaining,
                        queueStatus:"close"
                    }
     
        axios.post(`/p35transaction/updatetransactionstatus`,tempObj,myheader,shouldRender)
        .then(result=>{
            //console.log('done edit.............')
            if(filterData.showBillForm1){
                const temp={...filterData,
                    reloadData:true,
                    data1:filterDataTemplate.blankData,
                    reloadBillForm1:true,
                    showBillForm1:false
                }
                setFilterData(temp)
            }

            if(filterData.showBillForm2){
                const temp={...filterData,
                    reloadData:true,
                    data2:filterDataTemplate.blankData,
                    reloadBillForm2:true,
                    showBillForm2:false
                }
                setFilterData(temp)
            }

        })
        .catch(error=>{
            //console.log('error')
            //console.log(error)
            const tempError={...filterData,
                //reloadData:false,
                message:catchErrorToMessage(error),
                //showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterData(tempError)

        })
    }
}
//=======================================
const updateAllTransaction=(e,tableIdArray)=>{

    //console.log('tableIdArray')
    //console.log(tableIdArray)

    
    if(e.target.value=="close"){
        if(filterData.transactionArray.length>0){
            let allPromise=[]

            filterData.transactionArray.map(i=>{
                //if(tableName==i.table){
                tableIdArray.map(k=>{
                    if(k==i.id){
                        const {_id,...remaining}=i
                        let tempObj={...remaining,
                                tableStatus:"close",
                                queueStatus:"close"
                        }
                        allPromise=[...allPromise,
                            axios.post(`/p35transaction/updatetransactionstatus`,tempObj,myheader,shouldRender)
                        ]
                    }
                })    
                //}
            })

            //console.log('allPromise.length')
            //console.log(allPromise.length)
            Promise.all(allPromise)
            .then(result=>{
                //console.log('done edit.............')
                if(filterData.showBillForm1){
                    const temp={...filterData,
                        reloadData:true,
                        data1:filterDataTemplate.blankData,
                        reloadBillForm1:true,
                        showBillForm1:false
                    }
                    setFilterData(temp)
                }
    
                if(filterData.showBillForm2){
                    const temp={...filterData,
                        reloadData:true,
                        data2:filterDataTemplate.blankData,
                        reloadBillForm2:true,
                        showBillForm2:false
                    }
                    setFilterData(temp)
                }
    
            })
            .catch(error=>{
                //console.log('error')
                //console.log(error)
                const tempError={...filterData,
                    //reloadData:false,
                    message:catchErrorToMessage(error),
                    //showModalConfirm:false,
                    showModalError:true,
                }
                //console.log(tempError.message)
                setFilterData(tempError)
    
            })
       }
    }
    

}
//==================================
const updateProductToCloseOrOpen=(e,i,queue_idArray)=>{
    
    //console.log('updateProductToCloseOrOpen..........')
    //console.log(i)

    let tempDetailArray=[]

    i.detail.map(j=>{
        let isMatch=false
        //console.log(`j._id=${j._id}`)
        
        queue_idArray.map(k=>{
            //console.log(`k=${k}`)
            if(k==j._id){
                isMatch=true
            }
        })

        const {_id,...remaining}=j

        if(isMatch){
            tempDetailArray=[...tempDetailArray,{...remaining,jobStatus:e.target.value}]
        }
        else{
            tempDetailArray=[...tempDetailArray,remaining]
        }
        
    })

    
    //console.log('tempDetailArray')
    //console.log(tempDetailArray)
    
    const {_id,...remaining2}=i
    const tempObj={...remaining2,detail:tempDetailArray}
    
    //console.log(tempObj)
    
    axios.post(`/p35transaction/updatetransactionstatus`,tempObj,myheader,shouldRender)
    .then(result=>{
          if(filterData.showBillForm1){
                const temp={...filterData,
                    reloadData:true,
                    data1:filterDataTemplate.blankData,
                    reloadBillForm1:true,
                    showBillForm1:false
                }
                setFilterData(temp)
            }

            if(filterData.showBillForm2){
                const temp={...filterData,
                    reloadData:true,
                    data2:filterDataTemplate.blankData,
                    reloadBillForm2:true,
                    showBillForm2:false
                }
                setFilterData(temp)
            }


    })
    .catch(error=>{
        //console.log('error')
        //console.log(error)
        const tempError={...filterData,
            //reloadData:false,
            message:catchErrorToMessage(error),
            //showModalConfirm:false,
            showModalError:true,
        }
        //console.log(tempError.message)
        setFilterData(tempError)

    })
    

}


//==================================
const captureAllTransaction=(tableIdArray)=>{

    //console.log('captureAllTransaction')
    //console.log(tableIdArray)

    if(filterData.transactionArray.length>0){
        let allDetailArray=[]
        filterData.transactionArray.map(i=>{
            tableIdArray.map(k=>{
                if(k==i.id){

                    let tempDetailArray=[]

                    if(i.detail){
                        i.detail.map(d=>{
                            tempDetailArray=[...tempDetailArray,
                                {...d,_id:uuid(),selectedLine:true}
                            ]

                        })
                    }

                    //console.log(i.detail)
                    allDetailArray=[...allDetailArray,...tempDetailArray]
                }
            })
            //if(tableName==i.table){
            //}
        })     
        //console.log('allDetailArray')  
        //console.log(allDetailArray)   

      


        if(filterData.showBillForm1){
         
            if(billFormData1){
                const tempObj={
                    ...billFormData1,
                    //...tempObj0,

                    detail:[...billFormData1.detail,...allDetailArray],
                    reCal:true
                }
                setBillFormData1(tempObj)
            }
        }

        if(filterData.showBillForm2){
            if(billFormData2){
                const tempObj={
                    ...billFormData2,
                    //...tempObj0,
                    
                    detail:[...billFormData2.detail,...allDetailArray],
                    reCal:true
                }
                setBillFormData2(tempObj)
            }
        }
    }

    
}
//=======================================
const updateData=(data)=>{
    //console.log('updateData....')

    if(filterData.showBillForm1){
        const temp={...filterData,
            data1:data,
            reloadBillForm1:true,
            showBillForm1:false,
        }
        setFilterData(temp)
    }
    if(filterData.showBillForm2){
        const temp={...filterData,
            data2:data,
            reloadBillForm2:true,
            showBillForm2:false,
        }
        setFilterData(temp)
    }

}

//=======================================
const addSelectProductToBillForm=()=>{

    //console.log('addSelectProductToBillForm....')
    //console.log(filterData.selectOfSelectProduct)
    
    let dataIdx=null
    if(filterData.showBillForm1){
        dataIdx=1
    }
    if(filterData.showBillForm2){
        dataIdx=2
    }

    const tempObj={
        dataIdx:dataIdx,
        selectProduct:filterData.selectOfSelectProduct
    }

    setSelectProductToBillForm(tempObj)
    setFilterData({
        ...filterData,
        selectOfSelectProduct:null
    })

    setMainContextBill({...mainContextBill,
        selectOfSelectProduct:null,
        selectProductToBillForm:filterData.selectOfSelectProduct
    })
    setResetProductList(true)

}

//-----------------------
const refreshProductFunc=(data)=>{
    setFilterData({...filterData,
        reloadSelectProduct:true,
        qryProduct:data
    })
}
//------------------------
const refreshTransactionFunc=()=>{
    setFilterData({...filterData,
        reloadData:true
    })
}

const changeIncludeTransactionHead=(value)=>{
    setFilterData({...filterData,
        includeTransactionHead:value
    })
}


const captureSelectedLineFunc=(data)=>{
    //console.log('captureSelectedLine')
    //console.log(data)


     //console.log(editData)
     let tempArray=[]

     if(data){
        if(data.length>0){

            data.map(i=>{

                if(i.selectedLine){
                        let tempDetail=[]
                        
                        if(i.detail){
                            if(i.detail.length>0){
                                tempDetail=i.detail
                            }
                        }

                        tempDetail.map(k=>{
                            tempArray=[...tempArray,
                                {...k,selectedLine:true,_id:uuid()}
                            ]
                        })
                }

            })


        }
     }

     //console.log('tempArray')
     //console.log(tempArray)

     /*
    tempEditData={...filterData.data1,
                reference:filterData.editTransaction.id,
                detail:[...filterData.data1.detail,
                        ...filterData.editTransaction.detail
                       ],
                
            }

     */

    //console.log(filterData)
    
    
    
     if(filterData.showBillForm1&&filterData.data1){

        const tempEditData= {...filterData.data1,
                                detail:[...filterData.data1.detail,...tempArray]
                        }
        //console.log('tempEditData')
        //console.log(tempEditData)
        
        setFilterData({
            ...filterData,
            data1:tempEditData,
        })
        
    }

    if(filterData.showBillForm2&&filterData.data2){


        const tempEditData= {...filterData.data2, 
                                detail:[...filterData.data2.detail,...tempArray]
                            }
        //console.log('tempEditData')
        //console.log(tempEditData)
        
        setFilterData({
            ...filterData,
            data2:tempEditData,
 
        })
        
    }


}


//======================================
const renderPrintPageSetting=()=>{
return (
    <div className="Modal-background">
        <div className="Modal-box">
            <div className="Modal-header">
                <div>
                    {pageData.printPageSettingForm.formHead}
                </div>
            </div>
            <div className="Modal-body">
                <div className ="flex-center-center">
                    <select
                        onChange={e=>{
                            setShowPrintPageSetting({
                                ...showPrintPageSetting,
                                printPageSetting:e.target.value,
                                //show:false
                            })

                            setPrintDataFunc(null)

                            //setTimeout(() => {
                            //    if(billFormData1){
                            //        setPrintDataFunc(billFormData1)
                            //    }
                            //    else if(billFormData2){
                            //        setPrintDataFunc(billFormData2)
                            //    }
                            //}, 400);
                            
                        }}
                    >
                        <option>...</option>
                        {
                         printPage.data0.map((o,idxO)=>{
                             return(
                                 <option
                                    key={idxO}
                                    value={o.printId}
                                    //selected={o.printPageName==showPrintPageSetting.printPageSetting
                                    //    ?"selected":""}
                                 >{`${o.printId}.${o.printPageName}`}</option>
                             )
                         })   
                        }
                    </select>
                </div>
            </div>
            <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                            //</div>setShowPrintPageSetting({
                            //    ...showPrintPageSetting,
                            //    show:false
                            //})
                            //setShow(false)
                            //if(setMessage){setMessage(null)}
                            if(billFormData1){
                                        setPrintDataFunc({...billFormData1,
                                            printId:showPrintPageSetting.printPageSetting
                                        })
                            }
                            else if(billFormData2){
                                        setPrintDataFunc({...billFormData2,
                                            printId:showPrintPageSetting.printPageSetting
                                        })
                            }
                            setShowPrintPageSetting({
                                    ...showPrintPageSetting,
                                    show:false
                            })
                        }}
                    >
                        <MdPrint/>
                    </button>
                </div>
                <div>
                    <button
                        onClick={e=>{
                            setShowPrintPageSetting({
                                ...showPrintPageSetting,
                                show:false
                            })
                            //setShow(false)
                            //if(setMessage){setMessage(null)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>

        </div>
    </div>
)}

//======================================
const renderLeftBadge=()=>{
    return(
            <div    className=""
                    style={{height:"",width:"100%",
                        display:"flex",
                        flexWrap:"wrap",
                        //overflowX:"scroll",
                        //overflowY:"hidden",
                        //backgroundColor:"#8cc5bb",
                        marginBottom:"0rem",
                        background:"rgb(140,197,187)",
                        background:"linear-gradient(0deg, rgba(131,195,184,1) 43%, rgba(219,255,249,1) 100%)"            
            }}>
                    
                    <div className="iconbox"
                         onClick={e=>{
                             
                             setMainContextBill({
                                filterData:filterData,
                                partnerForBillFilterData:partnerForBillFilterData,
                                billGroupComponentFilterData:billGroupComponentFilterData,
                                billGroupComponentFilterData2:billGroupComponentFilterData2,
                                transactionForBillFilterData:transactionForBillFilterData,
                                billMenuComponentFilterData:billMenuComponentFilterData,
                                billMenuComponentFilterData2:billMenuComponentFilterData2,
                                showPrintPageSetting:showPrintPageSetting,
                                selectProductToBillForm:selectProductToBillForm,
                                selectCustomerToBillForm:selectCustomerToBillForm,
                                resetProductList:resetProductList,
                                billTableTemplate:billTableTemplate,
                                billFormData1:billFormData1,
                                billFormData2:billFormData2
                             })
                             

                             refHome.current.click()
                        }}
                    >
                        <Ticon
                            iconName="FaHome" 
                            className={filterData.showPartner?"md-icon ft-brown":"md-icon"}
                            //className="md-icon" 
                            textStyle={{color:"black"}}
                            iconStyle={getIconStyle()}
                        />
                    </div>
                    
                    <div className="iconbox"
                         onClick={e=>{
                             window.location.reload()
                            //setFilterData({...filterData,reloadData:true})
                         }
                        }
                    >
                        <Ticon
                            iconName="MdRefresh" 
                            className={"md-icon"}
                            //className="md-icon" 
                            textStyle={{color:"black"}}
                        />
                    </div>

                    <div style={{display:"none"}}>
                        <div>
                            <Link ref={refHome} to="/pos/home"/>
                        </div>
                    </div>

                    <div className="iconbox"
                         onClick={e=>setShowPartnerFunc()}
                    >
                        <Ticon
                            iconName="MdPerson" 
                            className={filterData.showPartner?"md-icon ft-brown":"md-icon"}
                            //className="md-icon" 
                            textStyle={{color:"black"}}
                        />
                    </div>

                    <div className="iconbox"
                         onClick={e=>setShowGroupFunc()} 
                    >
                        <Ticon 
                            iconName="GoPackage"//"FaRegFolderOpen" 
                            className={filterData.showGroup?"md-icon ft-brown":"md-icon"}
                            //className="md-icon" 
                            textStyle={{color:"black"}}
                        //style={{marginRight:"1.2rem",
                        //color:filterData.showGroup?"#634739":"black"
                        //}}   
                        />
                    </div>

                  
                    {
                    filterData.selectOfSelectProduct
                    ?filterData.selectOfSelectProduct.length>0
                      ? <div className='iconbox'
                            onClick={e=>{
                                addSelectProductToBillForm()
                            }}
                        >
                          <Ticon
                            iconName="FaRegArrowAltCircleRight" 
                            className="md-icon ft-red"
                             
                            textStyle={{color:"black"}}
                            />
                        </div>
                      :null
                    :null
                    }


                    <div className="iconbox"
                        onClick={e=>setShowTransactionFunc()}
                    >
                        <Ticon
                            iconName="SiBookstack"
                            className={filterData.showTransaction?"md-icon ft-brown":"md-icon"}
                            textStyle={{color:"black"}}
                    />
                    </div>

                    <div className="iconbox"
                        onClick={e=>
                            setShowBillMenuFunc()
                        }
                    >
                        <Ticon
                            iconName="GiStack"
                            className={filterData.showBillMenu?"md-icon ft-brown":"md-icon"}
                            textStyle={{color:"black"}}
                    />
                    </div>


                    <div className='iconbox'
                        onClick={e=>{
                            setShowBarcodeFunc()
                        }}
                    >
                        <Ticon
                            iconName="RiHandCoinFill" 
                            className={
                                false//filterData.showBillMenu
                                ?"md-icon ft-brown"
                                :"md-icon"}
                            textStyle={{color:"black"}}
                          
                        />
                    </div>

                   

                    <div className='iconbox'
                        onClick={e=>{
                            setShowQueueFunc()
                        }}
                    >
                        <Ticon
                            iconName="TiSortNumericallyOutline" 
                            className={
                                false//filterData.showBillMenu
                                ?"md-icon ft-brown"
                                :"md-icon"}
                            textStyle={{color:"black"}}
                          
                        />
                    </div>

                    <div className='iconbox'
                        onClick={e=>{
                            setShowTableFunc()
                           
                        }}
                    >
                        <Ticon
                            iconName="SiAirtable" 
                            className={
                                false//filterData.showBillMenu
                                ?"md-icon ft-brown"
                                :"md-icon"}
                            textStyle={{color:"black"}}
                          
                        />
                    </div>      

                    <div className='iconbox'
                        onClick={e=>{
                            //setShowQueueFunc()
                            setShowPrintPageSetting({
                                ...showPrintPageSetting,
                                show:true
                            })
                        }}
                    >
                        <Ticon
                            iconName="MdPrint" 
                            className={
                                false//filterData.showBillMenu
                                ?"md-icon ft-brown"
                                :"md-icon"}
                            textStyle={{color:"black"}}
                          
                        />
                    </div>            
                    
                   
                </div>
    )
}

//=======================================
const renderBody=()=>{
    return(
        filterData&&
        <div className=""
             style={{display:"flex",height:"95%"}}
        >

           
            <div className="bd-darkGray hide-on-printX" 
                 style={{height:"",width:`${filterData.widthLeft}%`,
                         overflowY:"scroll",overflowX:"hidden"}}>
               

                {!filterData.reloadData
                ?renderLeftBadge()
                //?filterData.data1||filterData.data2
                    //?renderLeftBadge()
                    //:null
                :null
                }
               <div style={{height:""}}>

               {filterData.showGroup&&pageData&&
               <BillGroupComponent
                    captureProductOfGroup={captureProductOfGroup}
                    pageData={pageData}
                    basicDataSt={basicDataSt}
                    productInputState={inputState.productInputState}
                    captureEditProduct={captureEditProductInBillGroupComponent}
                    billGroupComponentFilterData={billGroupComponentFilterData}
                    setBillGroupComponentFilterData={setBillGroupComponentFilterData}
                    billGroupComponentFilterData2={billGroupComponentFilterData2}
                    setBillGroupComponentFilterData2={setBillGroupComponentFilterData2}
                    

                    captureSelectProduct={captureSelectProductInBillGroupComponent}
                    //captureSelectProduct={captureProductListFromSelectProductInProductGrid}
                    
                    captureEditProductToBillToFindByGroupId={captureEditProductToBillToFindByGroupId}


                    selectProduct={filterData.selectProduct}
                    captureProductListFromSelectProductInProductGrid={captureProductListFromSelectProductInProductGrid}
                    setSelectProductToBillForm={setSelectProductToBillForm}
                    resetProductList={resetProductList}
                    setResetProductList={setResetProductList}
                    refreshProductFunc={refreshProductFunc}
                    
                    addSelectProductToBillForm={addSelectProductToBillForm}
               />}

               {filterData.showProductGrid&&
                   <BillProductGridComponent
                        selectProduct={filterData.selectProduct}
                        captureProductListFromSelectProductInProductGrid={captureProductListFromSelectProductInProductGrid}
                        setSelectProductToBillForm={setSelectProductToBillForm}
                        resetProductList={resetProductList}
                        setResetProductList={setResetProductList}
                        refreshProductFunc={refreshProductFunc}
                        
                        
                        billGroupComponentFilterData2={billGroupComponentFilterData2}
                        setBillGroupComponentFilterData2={setBillGroupComponentFilterData2}

                   />
               }

               {filterData.showTable&&
                   <BillTableComponent
                        transactionArray={filterData.transactionArray}
                        captureBillFromTable={captureBillFromTable}
                        basicDataSt={basicDataSt}
                        updateAllTransaction={updateAllTransaction}
                        captureAllTransaction={captureAllTransaction}
                   />
               }
               {filterData.showParter&&basicDataSt&&myheader&&
                    <PartnerForBill
                        basicDataSt={basicDataSt}
                        myheader={myheader}
                        captureCustomer={captureCustomerFunc}
                        partnerInputState={inputState.partnerInputState}
                        //setPartnerInputState={setPartnerInputState}
                        partnerForBillFilterData={partnerForBillFilterData}
                        setPartnerForBillFilterData={setPartnerForBillFilterData}
                  
                        
                    />
               }
               {filterData.showTransaction&&transactionType.data0&&
                    <TransactionForBill
                        basicDataSt={basicDataSt}
                        myheader={myheader}
                        pageFilter={pageFilter}
                        pageFilterForm={pageFilterForm}
                        captureTransaction={captureTransactionFunc}
                        transactionInputState={ inputState.transactionInputState}
                        
                        transactionBranchInputState={inputState.transactionBranchInputState}
                        transactionForBillFilterData={transactionForBillFilterData}
                        
                        setTransactionForBillFilterData={setTransactionForBillFilterData}
                        setReloadBill={setReloadBill}
                        reloadBill={reloadBill}
                        transactionType={transactionType}
                        captureSelectedLine={captureSelectedLineFunc}

                    />
               }
               {
                //filterData.showModalFromTable&&
                //renderModalFromTable()
                   
               }
               
               {
                filterData.showKitchen&&pageData&&
                <BillKitchen
                    queueArray={filterData.queueArray}
                    basicDataSt={basicDataSt}
                    captureBillFromTable={captureBillFromTable}
                    //updateQueue={updateQueueFunc}
                    updateProductToCloseOrOpen={updateProductToCloseOrOpen}//{updateProductToClose}
                />
               }


               {
                filterData.showBillMenu&&pageData&&transactionType.data0&&
                   <BillMenuComponent
                        //transactionArray={filterData.transactionArray}
                        //refreshTransactionFunc={refreshTransactionFunc}
                        pageData={pageData}
                        basicDataSt={basicDataSt}
                        myheader={myheader}
                        billMenuSort={filterData.billMenuSort}
                        billMenuInputState={filterData.billMenuInputState}
                        billBranchMenuInputState={filterData.billBranchMenuInputState}
                      

                        billMenuComponentFilterData={billMenuComponentFilterData}
                        setBillMenuComponentFilterData={setBillMenuComponentFilterData}
                        billMenuComponentFilterData2={billMenuComponentFilterData2}
                        setBillMenuComponentFilterData2={setBillMenuComponentFilterData2}
                        //setFilterDataFunc={setFilterDataFunc}
                        //mainContextBillMenuFilterData={mainContextBillMenuFilterData}
                        //setMainContextBillMenuFilterData={setMainContextBillMenuFilterData}
                        reloadBill={reloadBill}
                        setReloadBill={setReloadBill}
                        transactionType={transactionType}
                        captureBillMenu={captureBillMenuFunc}
                   />
               }

               {
                   filterData.showBarcode&&
                    <BillBarcode
                        pageData={pageData}
                        basicDataSt={basicDataSt}
                        myheader={myheader}
                        billBarcodeWidthLeft={filterData.widthLeft}

                        showBillForm1={filterData.showBillForm1}
                        showBillForm2={filterData.showBillForm2}
                        
                        setData1={setData1}
                        setData2={setData2}

                        setDetail1={setDetail1BillBarcode}
                        setDetail2={setDetail2BillBarcode}

                        billFormData1={billFormData1}
                        billFormData2={billFormData2}

                        calDigit={calDigit}

                        submitConfirm={submitSaveCloseBillFunc}

                        printFunc={printFunc}
                    />
               }

               {
                   filterData.showQueue&&
                    <BillQueue
                        queueArray={filterData.queueArray}
                        basicDataSt={basicDataSt}
                        captureBillFromTable={captureBillFromTable}
                        updateQueue={updateQueueFunc}
                    />
               }

               </div>
            </div>


            <div className="bd-darkGray hide-on-printX" 
                    style={{height:"100%",width:`${100-filterData.widthLeft}%`}}>

                        {filterDataProductInGroupId.data0
                            ?<div className="h-100 w-100" style={{marginTop:"2rem"}}>
                                 <div className="w-100">
                                    { 
                                        renderBadgeForBill({
                                            filterData:filterDataProductInGroupId,//filterData2,
                                            setPageNumber:()=>{},//setPageNumberFunc,
                                            bgColor:"#D8AE47",
                                            backToFilter:()=>{},//backToFilter,
                                            editData:null,//filterData2.editData,
                                            captureEditData:()=>{},//captureEditProduct,
                                            captureProductOfGroup:()=>{},//captureProductOfGroupFunc,
                                            setReloadDataFunc:()=>{},//setReloadDataFunc,
                                            useStock:true,
                                            showGoPackage:false,
                                            unSelectShow:true,
                                            bullEyeShow:true,
                                            closeShow:true,
                                            closeFunc:closeFunc,
                                            captureSelectProduct:captureSelectProductFunc,
                                            cancelSelectProduct:cancelSelectProductFunc
                                            //setUnSelectAll:setUnSelectAll
                                        }) 
                                }
                                </div>   

                                <div className='w-100 h-90'
                                >
                                    <Table
                                        colorHead={"#D2691E"}
                                        //tableTemplate={basicDataSt.tableTemplate.partnerTableTemplate}
                                        tableTemplate={billGroupComponentFilterData2.tableTemplate}
                                        setTableTemplate={()=>{}}////{setTableTemplate}//setTableTemplateFunc}

                                        filterData={filterDataProductInGroupId.data0}
                                        setFilterData={setFilterDataProductInGroupIdFunc}
                                        //setFilterDataFunc}//{setFilterDataData0}
                                        
                                        editData={filterDataProductInGroupId.editData}//{filterData2.editData}
                                        setEditData={setEditDataOfProductInGroupId}//{setEditData}//{updateEditData}
                                        saveTableTemplateFunc={saveTableTemplateFuncForProduct}
                                        isSubTable={false}
                                        useInput={false}
                                        //updateFilterData={()=>{}}//{updateEditData}
                                        updateFilterData={updateFilterDataFunc}

                                        basicData={basicData}
                                    />
                                </div>
                            </div>
                            :<>
                                    {filterData.showBillForm1&&pageData&&basicData&&
                                     billTableTemplate&&transactionType.data0&&
                                     printPage.data0
                                    ?<BillForm
                                    blankData={{...filterDataTemplate.blankData,
                                                //branchId:basicData.branchId,
                                                //branchName:basicData.branchName
                                                }}
                                    dataIdx={"1"}
                                    closeBillForm={closeBillForm1}
                                    
                                    
                                    selectOfSelectProduct={selectProductToBillForm}
                                    clearSelectOfSelectProduct={clearSelectOfSelectProduct}
                                    selectOfCustomer={selectCustomerToBillForm}
                                    setResetProductList={setResetProductList}
                                    pageData={pageData} 
                                    basicData={basicData}
                                    user={user}
                                    tableTemplate={billTableTemplate}
                                    saveTableTemplateFunc={saveTableTemplateFunc}
                                    submitFunctionAddFunc={submitFunctionAddFunc}
                                    submitFunctionEditFunc={submitFunctionEditFunc}
                                    submitFunctionDeleteFunc={submitFunctionDeleteFunc}
                                    //isGenIdOfBillForm={filterData.isGenIdOfBillForm1}
                                    //runIsGenId={runIsGenIdOfBillForm1}
                                    updateData={updateData}
                                    myheader={myheader}
                                    calDigit={calDigit}
                                    setPrintDataFunc={setPrintDataFunc}
                                    bgColor={"#e9bb43"}
                                    
                                    //data={filterData.data1}
                                    billFormData={billFormData1}
                                    setBillFormData={setBillFormData1Func}
                                    calEndBill={calEndBill}
                                    calEffect={calEffect}
                                    footerHeight={footerHeight}
                                    setFooterHeight={setFooterHeight}

                                    buttonLastRef={buttonLastRef1}
                                    transactionType={transactionType}
                                    printPage={printPage}

                                    showTableInBillForm={filterData.showTableInBillForm}
                                    setShowTableInBillForm={setShowTableInBillFormFunc}
                                    />
                                    :null
                                    }


                                    {filterData.showBillForm2&&pageData&&basicData&&
                                     billTableTemplate&&transactionType.data0&&
                                     printPage.data0
                                    ?<BillForm
                                        blankData={{...filterDataTemplate.blankData,
                                                    branchId:basicData.branchId,
                                                    branchName:basicData.branchName
                                                    }}
                                        dataIdx={"2"}
                                        closeBillForm={closeBillForm2}
                                        
                                        selectOfSelectProduct={selectProductToBillForm}
                                        clearSelectOfSelectProduct={clearSelectOfSelectProduct}

                                        selectOfCustomer={selectCustomerToBillForm}
                                        setResetProductList={setResetProductList}
                                        pageData={pageData}
                                        basicData={basicData}
                                        user={user}
                                        filterData={filterData}
                                        tableTemplate={billTableTemplate}
                                        saveTableTemplateFunc={saveTableTemplateFunc}
                                        submitFunctionAddFunc={submitFunctionAddFunc}
                                        submitFunctionEditFunc={submitFunctionEditFunc}
                                        submitFunctionDeleteFunc={submitFunctionDeleteFunc}
                                        //isGenIdOfBillForm={filterData.isGenIdOfBillForm2}
                                        //runIsGenId={runIsGenIdOfBillForm2}
                                        updateData={updateData}
                                        myheader={myheader}
                                        calDigit={calDigit}
                                        setPrintDataFunc={setPrintDataFunc}
                                        bgColor={"#e9bb43"}

                                        //data={filterData.data2}
                                        billFormData={billFormData2}
                                        setBillFormData={setBillFormData2Func}
                                        calEndBill={calEndBill}
                                        calEffect={calEffect}
                                        footerHeight={footerHeight}
                                        setFooterHeight={setFooterHeight}

                                        buttonLastRef={buttonLastRef2}

                                        transactionType={transactionType}
                                        printPage={printPage}

                                        showTableInBillForm={filterData.showTableInBillForm}
                                        setShowTableInBillForm={setShowTableInBillFormFunc}
                                        />
                                    :null
                                    }

                            </>
                        }
            </div>
    
                                    
        </div>
    )
}



/*
const RenderPrintOut=()=>{

    if(filterData.showBillForm1){
        if(billFormData1){
            return(
                <PrintOut
                    printPage={basicData.printPage}
                    printPageSetting={basicData.printPageSetting}
                    calDigit={calDigit}
                    transaction={billFormData1}
                />
            )
        }
    }
    else if(filterData.showBillForm2){
        if(billFormData2){
            return(
                <PrintOut
                    printPage={basicData.printPage}
                    printPageSetting={basicData.printPageSetting}
                    calDigit={calDigit}
                    transaction={billFormData2}
                />
            )
        }
    }
    else {
        return null
    }
  
}
*/


//==========================================
const setPrintDataFunc=(data)=>{
    //console.log('setPrintDataFunc....')
    //console.log(printPage.data0)
    //console.log(basicData)
    //console.log(data)
    
    
    //console.log(data.detail)
    //setPrintData(data)

    if(!data){return }
    //console.log(data)

    //const {printPageSetting,printPage,
    //    //dateFormat
    //}=basicData

    let pageSetup=printPage.data0[0]

    printPage.data0.map(i=>{
        //if(i.printPageName==showPrintPageSetting.printPageSetting){
        if(i.printId==data.printId){
            pageSetup=i
        }
    })

    if(pageSetup){

        let tempHeader=[]
        let tempTable=[]
        let tempFooter=[]
        let tempTableHead=[]
        let tempTableEnd=[]
        let tempTotal=[]
        let tempEndBill=[]
        let tempGrandTotal=[]

//ppppppppppp
        pageSetup.printSection.map(i=>{

            if(i.sectionName=="header"){
                tempHeader=[...tempHeader,i]
            }
            else if(i.sectionName=="table"){
                tempTable=[...tempTable,i]
            }
            else if(i.sectionName=="tableHead"){
                tempTableHead=[...tempTableHead,i]
            }
            else if(i.sectionName=="tableEnd"){
                tempTableEnd=[...tempTableEnd,i]
            }
            else if(i.sectionName=="total"){
                tempTotal=[...tempTotal,i]
            }
            else if(i.sectionName=="endBill"){
                tempEndBill=[...tempEndBill,i]
            }
            else if(i.sectionName=="grandTotal"){
                tempGrandTotal=[...tempGrandTotal,i]
            }
            else{
                tempFooter=[...tempFooter,i]
            }
        })
//ppppppppppppppppppp

        const printTest={header:tempHeader,
            tableHead:tempTableHead,
            table:tempTable,
            tableEnd:tempTableEnd,
            total:tempTotal,
            endBill:tempEndBill,
            grandTotal:tempGrandTotal,
            footer:tempFooter}

      
        const tempObj={
            printTest:printTest,
            tempTransaction:data,
            pageSetup:pageSetup,
            calDigit:calDigit,
            //dateFormat:dateFormat
        }

        setPrintData(tempObj)

    }
    
}
//========================================
//=====================================
return(

<div>
    
    {printData&&
    <div className='hide-on-screen'>
        {
         RenderPrintOut(printData)
        }
    </div>
    }

    <div className="hide-on-print" 
         style={{width:"100vw",height:`${basicData.pageHeight}vh`,
             padding:"0 0rem",position:"",overflow:""}}>    
        <div className="hide-logouttool">
            <LogOutTool
                tokenSt={tokenSt}
                setReloadCheckToken={setReloadCheckToken}
                user={user}
                useHomeIcon={false}
                useShopLogOut={false}
            />
        </div>
    
        <div>
            <Link ref={refHome} to="/home"/>
        </div>
        
        {
            renderBody()    
        }
    

        <div className="hide-on-printX" 
            style={{width:"100%",display:"flex",position:"relative"}}>
                {
                renderWidthRangeBar({    showRange:filterData.showRange,
                                    setShowRange:setShowRange,
                                    widthLeft:filterData.widthLeft,
                                    setWidthLeft:setWidthLeft,
                                    //heightTop:filterData.heightTop,
                                    //setHeightTop:setHeightTop
                                })
                }
    
        </div>
        
        {filterData.showCustomerConfirm&&
        renderCustomerConfirm({
            filterData,
            setFilterData,
            basicData,
            customerConfirmForm:pageData.customerConfirmForm,
            submitFunc:customerConfirmSubmitFunc,
            cancelFunc:customerConfirmCancelFunc
            })
        }
        
        {filterData.showTransactionConfirm&&
        renderTransactionConfirm({
            editTransaction:filterData.editTransaction,
            submitFunc:transactionConfirmSubmitFunc,
            cancelFunc:transactionConfirmCancelFunc,
            pageData:pageData,
            changeIncludeTransactionHead:changeIncludeTransactionHead,
            includeTransactionHead:filterData.includeTransactionHead
            })
        }

        {
          showPrintPageSetting.show&&printPage.data0
          ?renderPrintPageSetting()
          :null  
        }
        

        {
            modalConfirmReloadState.show
            ?<ModalConfirmReload
                setShow={cancelConfirmReload}
                submitFunction={confirmReload}
            />
            :null
        }


        {
        filterData.showModalConfirm
        ?<ModalConfirm
            setShow={setShowModalConfirmFunc}
            submitFunction={submitConfirmToDelete}
        />
        :null
        }

        {  
        filterData.showModalError
        ?renderModalError({
            setShow:(data)=>{setFilterData({...filterData,showModalError:data})},
            message:filterData.message
        })
        :null
        }

    </div>
</div>
)

}
export default Bill;


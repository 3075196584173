import FormTemplate from '../../render/renderForm/FormTemplate'
import StateTemplate from '../../model/StateTemplate'
import FilterTemplate from '../../render/renderFilter/FilterTemplate'
import inputState from '../../component/table/inputState'
import console from '../../util/consoleVerify'


const {partnerForm,productForm,transactionForm}=FormTemplate
const {partnerState,productState,transactionState}=StateTemplate
const {partnerFilter,productFilter,transactionFilter,billMenuFilter,
       billBranchMenuFilter
      }=FilterTemplate
const {partnerInputState,productInputState,
        transactionInputState,billMenuInputState,billBranchMenuInputState}=inputState

const toDate=new Date().toISOString()


const task={
    data0:null,
    dataCsv:null,
    count:0,
    lastRecordId:null,
    showRange:true,
    
    widthLeft:30,
    heightTop:30,

    pageNumber:1,
    limitRow:3,
    sort:{id:-1},
    qry:{},
    tableTemplateUrl:"p35branch",
    dataUrl:"p35task",
    selectGroup:null,

    tableTemplate:null,
    detailTableTemplate:null,
    detailTableTemplateForForm:null,

    tableTemplateName:"taskTableTemplate",
    detailTableTemplateName:null,
    detailTableTemplateForFormName:null,

    reloadData:false,
    message:null,
    showModalError:false,
    editData:null,
    badgeState:{
        swapShow:true,swapFunc:()=>{},
        reloadShow:true,reloadFunc:()=>{},
        filterShow:false,filterFunc:()=>{},
        addShow:true,addFunc:()=>{},
        editShow:true,editFunc:()=>{},
        delShow:true,delFunc:()=>{},
        printerShow:true,printerFunc:()=>{},
        csvShow:true,csvFunc:()=>{},
        forwardShow:false,forwardFunc:()=>{},
        bullEyeShow:true,
        unSelectShow:true,
        repeatedAddShow:true
    },
    selectProduct:[],
    showAdd:false,
    showRepeatedAdd:false,
    showEdit:false,
    showModalConfirm:false,
    showModalError:false,
    showModalCsv:false,
    message:null,
    iconActionData:null,
    iconActionDataDetail:null,
    keyName:["photoUrl1"],
    showModalFilter:true,
    swapState:true,

    leftBadge:0,
}


const partner={

    data0:null,
    dataCsv:null,
    count:0,
    lastRecordId:null,
    showRange:true,
    
    widthLeft:30,
    heightTop:30,

    pageNumber:1,
    limitRow:3,
    sort:{id:-1},
    qry:{},

    dataUrl:"p35partner",
    tableTemplateUrl:"p35branch",
    tableTemplateName:"partnerTableTemplate",
    dataState:partnerState,
    dataFilter:partnerFilter,
    inputState:partnerInputState,
    dataForm:partnerForm,

    badgeState:{
        swapShow:false,swapFunc:()=>{},
        reloadShow:true,reloadFunc:()=>{},////////////////refreshPage
        filterShow:false,filterFunc:()=>{},
        addShow:false,addFunc:()=>{},
        editShow:false,editFunc:()=>{},
        delShow:true,delFunc:()=>{},
        printerShow:true,printerFunc:()=>{},
        csvShow:true,csvFunc:()=>{}

    },
    reloadData:false,
    message:null,
    showModalError:false,
    editData:null,
    showAdd:false,
    showModalConfirm:false,
    showModalError:false,
    showModalCsv:false,
    showModalFilter:true,
    iconActionData:null
}




const filterDataTemplate={task:task,partner:partner}

export default filterDataTemplate
import React from 'react';
import {FaCheck,FaBan} from 'react-icons/fa';
import console from '../../util/consoleVerify'

const renderCustomerConfirm=({
   filterData,
   setFilterData,
   basicData,
   customerConfirmForm,
   submitFunc,
   cancelFunc
})=>{
   /*
   const customerConfirmForm={
       formHead:"ยืนยันข้อมูลลูกค้า",
       partnerId:"Partner ID",
       title:"Title",
       name:"Name",
       partnerType:"Customer Type",
       phone:"Phone",
       address:"Address"
   }
   */


   //console.log('filterData')
   //console.log(filterData)

   const handleChange=(e,i)=>{
       const tempObj={
           ...filterData.customerConfirm,
           [i]:e.target.value
       }
       setFilterData({...filterData,customerConfirm:tempObj})
   }

   const handleChangePhone=(e,i,idx2)=>{
       let tempArray=[]
       filterData.customerConfirm[i].map((k,idx3)=>{
           if(idx2==idx3){
               tempArray=[...tempArray,e.target.value]
           }
           else {
               tempArray=[...tempArray,k]
           }
       })
       const tempObj={
           ...filterData.customerConfirm,
           [i]:tempArray
       }
       setFilterData({...filterData,customerConfirm:tempObj})

   }

   const handleChangeAddress=(e,i)=>{
      const tempObj={...filterData.customerConfirm,["selectedAddress"]:e.target.value}
       setFilterData({...filterData,customerConfirm:tempObj})
   }

   const renderBody=(i,idx)=>{
       if(i.formHead){
           return null
       }
       else if(i=="partnerId"){
        return(
            <div className="flex-center-center jc-start w-100" 
            key={idx}>
             <div className="xc4">
                 {customerConfirmForm[i]}
             </div>
             <div className="xc8">
                 <input
                     value={filterData.customerConfirm[i]}
                     onChange={e=> handleChange(e,i)}
                     disabled="disabled"
                 />
             </div>
         </div>
        )
       }
       else if(i=="name"||i=="address"||i=="phone"||i=="contact"){
           return (
               <div className="flex-center-center jc-start w-100" 
                  key={idx}>
                   <div className="xc4">
                       {customerConfirmForm[i]}
                   </div>
                   <div className="xc8">
                       <input
                           value={filterData.customerConfirm[i]}
                           onChange={e=> handleChange(e,i)}
                       />
                   </div>
               </div>
           )
       }

       else if(i=="recipientName"||i=="recipientAddress"||i=="recipientPhone"||i=="recipientContact"){
        return (
            <div className="flex-center-center jc-start w-100" 
               key={idx}>
                <div className="xc4" style={{color:"blue"}}>
                    {customerConfirmForm[i]}
                </div>
                <div className="xc8">
                    <input
                        value={filterData.customerConfirm[i]}
                        onChange={e=> handleChange(e,i)}
                    />
                </div>
            </div>
        )
    }

       else if(i=="partnerType"){
            return(
                <div className="flex-center-center jc-start w-100" 
                    key={idx}>
                    <div className="xc4">
                        {customerConfirmForm[i]}
                    </div>
                    <div className="xc8">
                      <input
                          value={filterData.customerConfirm[i]}
                          onChange={e=> handleChange(e,i)}
                          disabled="disabled"
                      />

                    </div>
                </div>
            )
        }
       else if(i=="title"){
           return(
               <div className="flex-center-center jc-start w-100" 
                    key={idx}>
                   <div className="xc4">
                       {customerConfirmForm[i]}
                   </div>
                   <div className="xc8">
                       <select
                           onChange={e=>{
                               handleChange(e,i)
                           }}
                       >
                           <option>...</option>
                           {
                           basicData[i].map((j,idx2)=>(
                               <option key={idx2} 
                                    selected={filterData.customerConfirm[i]==j?"selected":""}>
                                        {j}
                               </option>
                           ))
                           }
                       </select>

                   </div>
               </div>
           )
       }

       else if(i=="recipientTitle"){
        return(
            <div className="flex-center-center jc-start w-100" 
                 key={idx}>
                <div className="xc4" style={{color:"blue"}}>
                    {customerConfirmForm[i]}
                </div>
                <div className="xc8">
                    <select
                        onChange={e=>{
                            handleChange(e,i)
                        }}
                    >
                        <option>...</option>
                        {
                        basicData["title"].map((j,idx2)=>(
                            <option key={idx2} 
                                 selected={filterData.customerConfirm[i]==j?"selected":""}>
                                     {j}
                            </option>
                        ))
                        }
                    </select>

                </div>
            </div>
        )
    }

       else if(i=="delivery"){
        return(
                <div className="flex-center-center jc-start w-100" 
                     key={idx}>
                    <div className="xc12">
                        {customerConfirmForm[i]}
                    </div>
                    <div className="xc12">
                        <select
                            onChange={e=>{
                                //handleChangeAddress(e,i)
                                const temp=e.target.value.split("_@,@_")
                                //handleChange(e,i)
                                    

                                const tempObj={
                                    ...filterData.customerConfirm,
                                    recipientTitle:temp[0],
                                    recipientName:temp[1],
                                    recipientPhone:temp[2],
                                    recipientContact:temp[3],
                                    recipientAddress:temp[4],
                                }

                                setFilterData({...filterData,customerConfirm:tempObj})


                            }}
                        >
                            <option>...</option>
                            {
                            filterData.customerConfirm[i].map((j,idx2)=>{
                                let temp=`${j.title},${j.name},${j.phone},${j.contact},${j.address}`
                                return (
                                    <option key={idx2} 
                                        value={`${j.title}_@,@_${j.name}_@,@_${j.phone}_@,@_${j.contact}_@,@_${j.address}`}

                                    >
                                            {temp}
                                    </option>
                                
                                    )
                                })}
                        </select>

                    </div>
                </div>
        )
        }




       /*
       else if(i=="phone"){
           return( filterData.customerConfirm[i].map((j,idx2)=>(
           <div className="flex-center-center jc-start w-100" 
                style={{margin:"0.2rem 0"}}
                key={idx2}>
               <div className="xc4">
                   {idx2==0?customerConfirmForm[i]:null}
               </div>
               <div className="xc8">
                   <input
                       value={j}
                       onChange={e=>{
                           handleChangePhone(e,i,idx2)
                       }}
                   />
               </div>
           </div>
           ))
          
           )
       }
       else if(i=="address"){
           return(
                   <div className="flex-center-center jc-start w-100" 
                        key={idx}>
                       <div className="xc4">
                           {customerConfirmForm[i]}
                       </div>
                       <div className="xc8">
                           <select
                               onChange={e=>{
                                   handleChangeAddress(e,i)
                               }}
                           >
                               {
                               filterData.customerConfirm[i].map((j,idx2)=>(
                                   <option key={idx2} 
                                       selected={idx2==0?"selected":""}>
                                           {j}
                                   </option>
                               ))
                               }
                           </select>

                       </div>
                   </div>
           )
       }
       */
   }
  
   return(
   <div className="Modal-background">
       <div className="Modal-box">
           <div className="Modal-header">
               <h4>{customerConfirmForm.formHead}</h4>
           </div>

           <div className="w-100"
                style={{marginBottom:"1rem"}}
           >
               {Object.keys(customerConfirmForm).map((i,idx)=>{
                   return(
                       <div key={idx} style={{
                           margin:"0.2rem 0"
                       }}>
                        {renderBody(i,idx)}
                        </div>
                   )
               }
               )
               }

           </div>
           <div className="Modal-footer">
               <div>
                   <button
                       onClick={e=>{
                           submitFunc()
                           //submitFunction()
                       }}
                    
                   >
                       <FaCheck/>
                   </button>
               </div>
               <div>
                   <button
                       onClick={e=>{
                          cancelFunc()
                       }}
                   >
                       <FaBan/>
                   </button>
               </div>

           </div>

       </div>
   </div>
   )
}

export default renderCustomerConfirm
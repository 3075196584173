
import React from 'react';
import {MainContext} from '../../context/MainContext'
import {Link} from 'react-router-dom';

import console from '../../util/consoleVerify'


function BackEndNavbar() {

//console.log('BackEndNavbar')

const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData}=React.useContext(MainContext)
const {basicData}=basicDataSt

const refHome=React.createRef()
const refShop=React.createRef()
const refUser=React.createRef()
const refShopPayment=React.createRef()
const refBranchForAdmin=React.createRef()
const refBasicDataForAdmin=React.createRef()

const refGroupForAdmin=React.createRef()
const refProductForAdmin=React.createRef()
const refPartnerForAdmin=React.createRef()
const refTransactionForAdmin=React.createRef()
const refStickerForAdmin=React.createRef()

return (
<div style={{display:"flex"}}>
    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refHome.current.click()}
    >
        Home
    </div>
    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refShop.current.click()}
    >
        Shop
    </div>
    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refShopPayment.current.click()}
    >
        Shop Payment
    </div>

    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refUser.current.click()}
    >
        User
    </div>

    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refBasicDataForAdmin.current.click()}
    >
        BasicData
    </div>
    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refBranchForAdmin.current.click()}
    >
        Branch
    </div>

    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refProductForAdmin.current.click()}
    >
        Product
    </div>
    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refGroupForAdmin.current.click()}
    >
        Group
    </div>
    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refPartnerForAdmin.current.click()}
    >
        Partner
    </div>
    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refStickerForAdmin.current.click()}
    >
        Sticker
    </div>
    <div style={{margin:"0 1rem",fontSize:"1.2rem"}}
        onClick={e=>refTransactionForAdmin.current.click()}
    >
        Transaction
    </div>
    <div className="d-none">
        <Link ref={refHome} to="/pos/home"/>
        <Link ref={refShop} to="/pos/s_mm23pos"/>
        <Link ref={refUser} to="/pos/u_mm23pos"/>
        <Link ref={refShopPayment} to="/pos/sp_mm23pos"/>
        <Link ref={refBasicDataForAdmin} to="/pos/ba_mm23pos"/>
        <Link ref={refBranchForAdmin} to="/pos/br_mm23pos"/>
        <Link ref={refGroupForAdmin} to="/pos/group_mm23pos"/>
        <Link ref={refProductForAdmin} to="/pos/product_mm23pos"/>
        <Link ref={refPartnerForAdmin} to="/pos/partner_mm23pos"/>
        <Link ref={refTransactionForAdmin} to="/pos/transaction_mm23pos"/>
        <Link ref={refStickerForAdmin} to="/pos/sticker_mm23pos"/>


    </div>
</div>
)

}
export default BackEndNavbar;


import React from 'react';
import BackEndNavbar from '../../component/navbar/BackEndNavbar';
import {MainContext} from '../../context/MainContext'
import PageComponentBasicDataForAdmin  
  from '../../component/pageComponent/PageComponentBasicDataForAdmin';
import inputState from '../../component/table/inputState'
import filterDataTemplate from './filterDataTemplate';
import StateTemplate from '../../model/StateTemplate'
import console from '../../util/consoleVerify'


function BasicDataForAdmin() {
const {basicDataSt,tokenSt,setReloadCheckToken,myheader,setReloadBasicData,
       mainContextBasicData,setMainContextBasicData
}=React.useContext(MainContext)
const {basicData}=basicDataSt
return (
    <div style={{height:`${basicData.pageHeight}vh`,width:"100vw",overflow:"hidden"}}>
        <div className="h-5 w-100">
            <BackEndNavbar/>
        </div>

        <div className="h-95 w-100">
        <PageComponentBasicDataForAdmin
                setReloadBasicData={setReloadBasicData}
                filterDataTemplate={filterDataTemplate}
                filterTemplate={basicDataSt.pageFilter.basicDataForAdminFilter}
                inputState={inputState.basicDataForAdminInputState}
                basicDataSt={basicDataSt}
                myheader={myheader}
                
                formTemplate={basicDataSt.modalFormTemplate.basicDataForAdminForm}
                editFormTemplate={basicDataSt.modalFormTemplate.basicDataForAdminForm}
                
                stateTemplate={StateTemplate.basicDataForAdminState}
                pageFilterForm={basicDataSt.pageFilterForm.basicDataForAdminFilterForm}

                modalFormTemplate={basicDataSt.pageData.ModalForm}
                
                bgColor={"orange"}
                calDigit={100}
                controller={"getlimitbyadmin"}
                mainContextData={mainContextBasicData}
                setMainContextData={setMainContextBasicData}
            />

        </div>
    </div>
)



}
export default BasicDataForAdmin;
import React from 'react';
import axios from '../../util/axiosVerify'
//import {MainContext} from '../../context/MainContext'
import uuid from 'react-uuid'

import Navbar from '../../component/navbar/Navbar';

import ModalFilterInput from '../../render/renderFilter/ModalFilterInput'
import pageUtil from './pageUtil'
//import NewGroupComponent from '../../render/renderTree/NewGroupComponent'
import axiosUtil from '../../util/axiosUtil'
import Table from '../../component/table/Table'
import renderWidthRangeBar from '../../component/table/renderWidthRangeBar'
import renderBadge from '../../render/renderBadge/renderBadge'
import renderModalError from '../../render/renderModalError'
import ModalConfirm from '../../render/ModalConfirm'
import ModalConfirmReload from '../../render/ModalConfirmReload'
import Ticon from '../../component/ticon/Ticon'
import TaskCalendar from '../../component/taskCalendar/TaskCalendar'
import Galleryone from '../galleryone/Galleryone'

import {MdRefresh,MdSwapHoriz,MdSettings,
    MdSearch,MdEdit,MdAddCircle,MdDelete,MdPrint,
    MdChevronLeft,MdChevronRight,MdLastPage,
    MdRadioButtonChecked,MdClose,MdCheckBoxOutlineBlank,
    MdCheckBox
} from 'react-icons/md';
//import ModalForm from '../../render/renderForm/ModalForm'
//import NewTree from '../../component/newtree/NewTree'

import PageForm from '../../render/renderForm/PageForm'
//import ModalCsv from './ModalCsv'
import renderTransactionConfirm from '../../page/bill/renderTransactionConfirm';
import {FaCheck,FaBan,FaFolder} from 'react-icons/fa';
import console from '../../util/consoleVerify'
import renderGrid from '../../render/renderForm/renderGrid'

const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,catchErrorToMessage}=axiosUtil

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,
    submitFunctionAddMany,
    filterAxios,
    setUnSelectAll,submitFunctionDelete,
    genCsv,genXlsx
} = pageUtil

function PageComponentTask(props) {

//console.log('PageComponentPartner')
//const {basicDataSt,tokenSt,setReloadCheckToken,myheader}=React.useContext(MainContext)

const {filterDataTemplate,filterDataTemplate2,filterDataTemplate3,
      pageFilter,pageFilterForm,...remaining}=props

const {filterTitle,
      inputState,
      filterTemplate,myheader,basicDataSt,
      formTemplate,
      editFormTemplate,
      repeatedAddFormTemplate,  
      stateTemplate,
      groupFormTemplate,groupEditFormTemplate,
      pageDataModalForm,
      addFormTitle,
      repeatedAddFormTitle,
      editFormTitle,
      groupAddFormTitle,
      groupEditFormTitle,
      bgColor,
      calDigit,
      setReloadBasicData,
      controller,
      mainContextTaskFilterData,
      setMainContextTaskFilterData,

      partnerFilterDataTemplate,
      partnerFilterTemplate,//={basicDataSt.pageFilter.partnerFilter}
      partnerInputState,//={inputState.partnerInputState}
      mainContextPartnerFilterDataForTask,
      customerPageFilterForm,
      setMainContextPartnerFilterDataForTask,
      
}=props

const {basicData,pageData,tableTemplate,reloadBasicData,user}=basicDataSt
//console.log('tableTemplate............dddddddddd')
//console.log(tableTemplate)
const refSecrete=React.createRef()

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
     shouldRender.current=false
    } 
},[])



const defaultFilterData={
    ...filterDataTemplate,...remaining,
    tableTemplate:
        filterDataTemplate.tableTemplateName
        ?tableTemplate[filterDataTemplate.tableTemplateName]
        :null,
    detailTableTemplate:
        filterDataTemplate.detailTableTemplateName
        ?tableTemplate[filterDataTemplate.detailTableTemplateName]
        :null,
    detailTableTemplateForForm:
        filterDataTemplate.detailTableTemplateForFormName
        ?tableTemplate[filterDataTemplate.detailTableTemplateForFormName]
        :null,
    inputState:inputState,
    widthLeft:basicData.widthLeft,
    limitRow:basicData.limitRow,
    isCsv:false,
    isXlsx:false,
    swapState:basicData.showTable,

}

const defaultFilterData2={
    ...partnerFilterDataTemplate,
    tableTemplate:
    partnerFilterDataTemplate.tableTemplateName
        ?tableTemplate[partnerFilterDataTemplate.tableTemplateName]
        :null,
    inputState:partnerInputState,
    widthLeft:basicData.widthLeft,
    limitRow:basicData.limitRow,
    isCsv:false,
    isXlsx:false
}

const genFilterData=()=>{
    if(mainContextTaskFilterData){
        return mainContextTaskFilterData
    }
    else {
        return defaultFilterData
    }
}


const genFilterData2=()=>{
    if(mainContextPartnerFilterDataForTask){
        return mainContextPartnerFilterDataForTask
    }
    else {
        return defaultFilterData2
    }
}

const [filterData,setFilterData]=React.useState(genFilterData())
const [filterData2,setFilterData2]=React.useState(genFilterData2())

const setFilterD1Func=(data)=>{

    setFilterData(data)
    setMainContextTaskFilterData(data)
}

const setFilterD2Func=(data)=>{
    setFilterData2(data)
    setMainContextPartnerFilterDataForTask(data)
}


const [modalConfirmReloadState,setModalConfirmReloadState]=React.useState({
    show:false,
    filterData:null
})



const cancelConfirmReload=()=>{
    
    setFilterD1Func(
        {...modalConfirmReloadState.filterData,
            showEdit:true,
            reloadData:false
        })
    setModalConfirmReloadState({
        filterData:null,
        show:false
    })
}

const confirmReload=(data)=>{
    setFilterD1Func(modalConfirmReloadState.filterData)
    setModalConfirmReloadState({
        filterData:null,
        show:false
    })
}


const setMainContextFilterDataFunc=(filterData)=>{
    //console.log('setMainContextFilterDataFunc')
    //console.log(filterData)
    //setMainContextTaskFilterData(filterData)
}


React.useEffect(()=>{
    //console.log('UserEffect ..... filterData PageComponentPartner. editData........')
    //console.log(filterData)
    //console.log(filterData.editData)


    if(filterData.reloadData){
        const {dataUrl,pageNumber,limitRow,sort,qry}=filterData
        let tempController="getlimit"
        if(controller){
            tempController="getlimitbyadmin"
        }

        axios.post(`/${dataUrl}/${tempController}`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
        .then(result=>{
            //console.log('result....eeeeeeeee........')
            //console.log(result.data.data)
            //console.log('new Date().toISOString()')
            //console.log(new Date().toISOString())
            const temp2= genFilterDataWithIndex(result.data.data,filterData.selectProduct)
            //console.log('temp2......eeeeeeeee.......')

            //console.log(temp2)
            const tempResult={...filterData,
                data0:temp2,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                isCsv:false,
                isXlsx:false,
                reloadData:false,
                
            }

            if(filterData.isCsv)
            {
                //genCsv(tempResult,filterData.tableTemplateName)
                setFilterD1Func(tempResult)
            }
            else if(filterData.isXlsx){
                //genXlsx(tempResult,filterData.tableTemplateName)
                setFilterD1Func(tempResult)
            }
            else{
                setFilterD1Func(tempResult)
            }

        })
        .catch(error=>{
            const tempError={...filterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterD1Func(tempError)
        })
    }

    if(filterData.isCsv){
        //console.log('isCsv')
        genCsv({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterD1Func({...filterData,isCsv:false})
    }
    if(filterData.isXlsx){
        //console.log('isXlsx')
        genXlsx({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterD1Func({...filterData,isXlsx:false})
    }

},[filterData])


const setShowRange=(data)=>{setFilterD1Func({...filterData,showRange:data})}
const setHeightTop=(data)=>{setFilterD1Func({...filterData,heightTop:data})}
const setWidthLeft=(data)=>{setFilterD1Func({...filterData,widthLeft:data})}
const setShowModalError=(data)=>{setFilterD1Func({...filterData,showModalError:data})}
const setShowModalConfirm=(data)=>{setFilterD1Func({...filterData,showModalConfirm:data})}
const setPageNumber=(data)=>{setFilterD1Func({...filterData,pageNumber:data,reloadData:true})}

const setIsCsv=(data)=>{
    if(data){
        setFilterD1Func({...filterData,isCsv:true,isXlsx:false})
    }
    else{
        setFilterD1Func({...filterData,isCsv:false})
    }
}
const setIsXlsx=(data)=>{
    if(data){
        setFilterD1Func({...filterData,isXlsx:true,isCsv:false})
    }
    else{
        setFilterD1Func({...filterData,isXlsx:false})
    }
}

const setShowAdd=(data)=>{
    //console.log('setShowAdd')
    //console.log(data)
    setFilterD1Func({
        ...filterData,
        showAdd:data,
        showEdit:false,
        showRepeatedAdd:false
    })
}

const setShowEdit=(data)=>{
    //console.log('setShowEdit')
    //console.log(data)
    setFilterD1Func({
        ...filterData,
        showEdit:data,
        showAdd:false,
        showRepeatedAdd:false
    })
}

const setShowRepeatedAdd=(data)=>{
    //console.log('setShowAdd')
    //console.log(data)
    setFilterD1Func({
        ...filterData,
        showAdd:false,
        showEdit:false,
        showRepeatedAdd:data
    })
}

const setSwapState=(data)=>{
    //console.log('setShowAdd')
    //console.log(data)
    
    setFilterD1Func({
        ...filterData,
        swapState:data
    })
}




const setTableTemplate=(data)=>{setFilterD1Func({...filterData,tableTemplate:data})}
const setDetailTableTemplate=(data)=>{setFilterD1Func({...filterData,detailTableTemplate:data})}

const setEditData=(data)=>{
    let tempData=data
    //console.log('data..........')
    //console.log(data)
    setFilterD1Func({...filterData,editData:tempData})
    //captureEditData(data)
}
//to save filterData when sort Up and down each column
const setFilterD1FuncData0=(data)=>{
    console.log('setFilterD1FuncData0.........')
    console.log(data)
    setFilterD1Func({...filterData, data0:data})
}
const setSort=(data)=>{
    setFilterD1Func({...filterData,sort:data})
}
const setLimitRow=(data)=>{
    setFilterD1Func({...filterData,limitRow:data})
}
const setInputState=(data)=>{
    setFilterD1Func({...filterData,inputState:data})
}
const setShowModalFilter=(data)=>{
    setFilterD1Func({...filterData,showModalFilter:data})
}



React.useEffect(()=>{
    //console.log('UserEffect ..... filterData PageComponentPartner. editData........')
    //console.log(filterData.editData)
    
    if(filterData2.reloadData){
        const {dataUrl,pageNumber,limitRow,sort,qry}=filterData2
        let tempController="getcustomerlimit"
      

        axios.post(`/${dataUrl}/${tempController}`,{pageNumber,limitRow,sort,...qry},
                    myheader,shouldRender)
        .then(result=>{
            //console.log('result....eeeeeeeee........')
            //console.log(result.data.data)
            //const temp2= genFilterDataWithIndex(result.data.data,filterData.selectProduct)
            //console.log('temp2......eeeeeeeee.......')
            const temp2=result.data.data
            //console.log(temp2)
            const tempResult={...filterData2,
                data0:temp2,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                isCsv:false,
                isXlsx:false,
                reloadData:false,
                
            }

            if(filterData2.isCsv)
            {
                //genCsv(tempResult,filterData.tableTemplateName)
                setFilterD2Func(tempResult)
            }
            else if(filterData2.isXlsx){
                //genXlsx(tempResult,filterData.tableTemplateName)
                setFilterD2Func(tempResult)
            }
            else{
                setFilterD2Func(tempResult)
            }

        })
        .catch(error=>{
            const tempError={...filterData2,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterD2Func(tempError)
        })
    }

    if(filterData2.isCsv){
        //console.log('isCsv')
        genCsv({data0:filterData2.data0,tableTemplate:filterData2.tableTemplate})
        setFilterD2Func({...filterData2,isCsv:false})
    }
    if(filterData2.isXlsx){
        //console.log('isXlsx')
        genXlsx({data0:filterData2.data0,tableTemplate:filterData2.tableTemplate})
        setFilterD2Func({...filterData2,isXlsx:false})
    }

    
},[filterData2])






const setSort2=(data)=>{
    setFilterD2Func({...filterData2,sort:data})
}
const setLimitRow2=(data)=>{
    setFilterD2Func({...filterData2,limitRow:data})
}
const setInputState2=(data)=>{
    setFilterD2Func({...filterData2,inputState:data})
}
const setShowModalFilter2=(data)=>{
    setFilterD2Func({...filterData2,showModalFilter:data})
}
const setPageNumber2=(data)=>{
    setFilterD2Func({...filterData2,
        reloadData:true,
        pageNumber:data
    })
}
const setIconActionData2=(data)=>{
    //console.log('setIconActionData2...')
    //console.log(data)
    if(data){
        const tempObj={
            partnerId:data.id,
            partnerType:data.partnerType,
            isSupplier:data.isSupplier,
            isCustomer:data.isCustomer,
            title:data.title,
            name:data.name,
            phone:data.phone,
            address:data.address,
            contact:data.contact
        }
        

        setFilterD2Func({...filterData2,
            iconActionData:tempObj
        })
    }
}


const setIsCsv2=(data)=>{
    if(data){
        setFilterD2Func({...filterData2,isCsv:true,isXlsx:false})
    }
    else{
        setFilterD2Func({...filterData2,isCsv:false})
    }
}
const setIsXlsx2=(data)=>{
    if(data){
        setFilterD2Func({...filterData2,isXlsx:true,isCsv:false})
    }
    else{
        setFilterD2Func({...filterData2,isXlsx:false})
    }
}

const setEditData2=(data)=>{
    setFilterD2Func({...filterData2,editData:data})
}



const setSelectGroupFunc=(data)=>{
    //console.log('selectGroupFunc....')
    //console.log(data)
    setFilterD1Func({...filterData,selectGroup:data})
}

const setUnSelectAllFunc=()=>{
    setFilterD1Func(setUnSelectAll(filterData))
}

const genFilterDataWithIndex=(filterData,selectProduct)=>{

    if(filterData){
        if(filterData.detail){
            if(filterData.detail.length()>0){
                let tempArray=[]

                filterData.detail.map(i=>{

                })

            }
        }
    }

    let tempSelectProduct
    if(selectProduct){tempSelectProduct=selectProduct}
    else {tempSelectProduct=[]}

    let tempArray=[]

    filterData.map((i,idx)=>{
        //const temp={...i,tempIndex:idx,selectedLine:false}
        let tempStocking={}

        if(i.stocking){
            let isSearching=true
            i.stocking.map(k=>{
                if(isSearching){
                    if(k.branchId==basicData.branchId){
                        tempStocking={
                            stock:k.stock,
                            minStock:k.minStock,
                            maxStock:k.maxStock,
                          
                        }
                        isSearching=false
                    }
                }   
            })
        }


        const temp={...i,selectedLine:false,temp_id:i._id,_id:uuid(),...tempStocking}
        tempArray=[...tempArray,temp]

    })

    let tempArray2=[]

    tempArray.map(i=>{
        let tempObj=i
        tempSelectProduct.map(j=>{
            if(i.id==j.id){
                //console.log('j.id==i.id')
                tempObj={...tempObj,selectedLine:j.selectedLine}
                //tempArray2=[...tempArray2,]
            }
        })
        tempArray2=[...tempArray2,tempObj]
    })
 
    return tempArray2
}

const refreshPage=()=>{
    //console.log('refreshPage')
    const {limitRow,sort}=filterData
    const temp = {...filterData, limitRow,sort,selectProduct:[],
                editData:null,qry:{},reloadData:true
            }
    setFilterD1Func(temp)
    setReloadBasicData(true)
}


const findTaskId=(id)=>{
        //console.log('findTaskId')
        //console.log(id)
    
        const {pageNumber,limitRow,sort}=filterData

        const tempObj={pageNumber,limitRow,sort,id:id}
        //axios.post(`/${dataUrl}/${myController}`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
        axios.post(`/p35task/getlimit`,tempObj,myheader,shouldRender)
        .then(result=>{
            const tempResult= { ...filterData,
                                data0:result.data.data,
                                count:result.data.count,
                                lastRecordId:result.data.lastRecordId,
                                reloadData:false,
                                pageNumber:1,
                                
                            }

            const temp2= genFilterDataWithIndex(result.data.data,[])
                
            setFilterD1Func({...tempResult,
                data0:temp2
            })
        })
        .catch(error=>{
            //catchErrorToMessage(error,setMessage)
            //setMessage(error.response.data.message)
            const tempError={...filterData,
                                reloadData:false,
                                message:catchErrorToMessage(error),
                                showModalConfirm:false,
                                showModalError:true,
                                pageNumber:1,
                            }
        })    


}

const submitFunctionDeleteFunc=async(data)=>{
    submitFunctionDelete(filterData,myheader,shouldRender)
    .then(result=>setFilterD1Func(result))
    .catch(error=>setFilterD1Func(error))
}

const submitFunctionEditFunc=async (formInputState,loadData)=>{

    //console.log('submitFunctionEditFunc')
    //console.log(loadData)
    const manyImage=true
    let tempFormInputState=formInputState

    if(loadData){
        const {temp_id}=loadData
        if(temp_id){
            tempFormInputState={...tempFormInputState,temp_id:temp_id}
        }
    }

    submitFunctionEdit(tempFormInputState,filterData,stateTemplate,myheader,shouldRender,manyImage)
    .then(result=>{
        
        setModalConfirmReloadState({show:true,filterData:result})
        //setFilterD1Func(result)
    })
    .catch(error=>setFilterD1Func(error))
}

const submitFunctionAddFunc=async (formInputState)=>{
    //console.log('submitFunctionAddFunc')

    const manyImage=true

    submitFunctionAdd(formInputState,filterData,stateTemplate,myheader,shouldRender,manyImage)
    .then(result=>setFilterD1Func(result))
    .catch(error=>setFilterD1Func(error))
}

const submitFunctionAddManyFunc=async (formInputState)=>{
    //console.log('submitFunctionAddFunc')

    submitFunctionAddMany(formInputState,filterData,stateTemplate,myheader,shouldRender)
    .then(result=>setFilterD1Func({...result,showRepeatedAdd:false}))
    .catch(error=>setFilterD1Func(error))
}
//{pageNumber,limitRow,sort,dataUrl} = filterData
const filterAxiosFunc=(option,inputState)=>{
    //console.log('filterAxiosFunc..............')

    let tempController=null

    if(controller){
        tempController=controller
    }

    //console.log(filterTemplate)
    filterAxios(option,inputState,filterTemplate,
                filterData,tempController,myheader,
                shouldRender,user.branchId)
    .then(result=>{
        //console.log("result....yyyyyyyyy.......")
        //console.log(result)
        
        const temp2= genFilterDataWithIndex(result.data0,
                     filterData.selectProduct)
        //console.log('result...yyyyyyy.......')
        //console.log(temp2)

        if(filterData.isCsv)
        {
            //genCsv({...result},filterData.tableTemplateName)

            setFilterD1Func({...result,
                isCsv:false,
                data0:temp2
            })
        }
        else if(filterData.isXlsx){
            //genXlsx({...result},filterData.tableTemplateName)

            setFilterD1Func({...result,
                isXlsx:false,
                data0:temp2
            })
        }
        else{
            setFilterD1Func({...result,
                data0:temp2
            })
        }
    })
    .catch(error=>setFilterD1Func(error))
}

//===========================================

const filterAxiosForCustomerFunc=(option,inputState)=>{

    filterAxios(option,inputState,partnerFilterTemplate,
                 filterData2,'getcustomerlimit',myheader,
                 shouldRender)
    .then(result=>{
        //console.log('customer............')
        //console.log(result)
        
        setFilterD2Func({...result,
            //
            //showSupplier:false,
            //inputStateCustomer:inputState
        })

        //setPartnerInputState(filterInputState)
    })
    .catch(error=>{
        //setFilterD1FuncFunc({...error,
        //    inputStateCustomer:inputState
        //})
        //setPartnerInputState(filterInputState)
    })

}

//============================================


const saveTableTemplateFunc=(tableTemplate)=>{
    const {tableTemplateUrl}=filterData
    saveTableTemplate(tableTemplate,tableTemplateUrl,filterData.tableTemplateName,myheader,shouldRender)
}


const updateFilterDataFunc=(index,i)=>{

    const temp=updateFilterData(index,i,filterData)
  
    setFilterD1Func({...filterData,...temp})
}

const closeBillForm=()=>{
    setFilterD1Func({...filterData,
        showAdd:false,showEdit:false
    })
}
//----------------------------
const transactionConfirmSubmitFunc=()=>{
    let tempEditData=filterData.editData

    if(!filterData.includeTransactionHead){
        tempEditData={...filterData.editData,
            detail:[...filterData.editData.detail,
                    ...filterData.editData.detail
                    ]
        }
    }

    setFilterD1Func({...filterData,
        data1:tempEditData,
        showTransactionConfirm:false,
        showBillForm1:false,
        reloadBillForm1:true
    })
  
}


const transactionConfirmCancelFunc=()=>{
    setFilterD1Func({...filterData,
        showTransactionConfirm:false
    })
}

//=============================
const genCsvFunc=()=>{
    genCsv(filterData)
}
//----------------------------


/*
const showContent=(b,tt,data,v,idx)=>{
       
        const {type}=tt
        if(b=="selectedLine"){
            return (
            <div className="h-100" style={{display:"flex",marginLeft:"0.2rem"}}
                onClick={e=>{
                   updateFilterDataFunc(idx,{...v,selectedLine:!v.selectedLine})
                }}
            >
                {data
                ?<MdCheckBox className="md-icon"/>
                :<MdCheckBoxOutlineBlank  className="md-icon"/>
                }
            </div>
            )
        }
        else if(type=="boolean"){
          return (<div className="h-100" style={{display:"flex",marginLeft:"1rem"}}>
                  {data
                  ?<MdCheckBox style={{fontSize:"1rem"}}/>
                  :<MdCheckBoxOutlineBlank  style={{fontSize:"1rem"}}/>
                  }
                </div>)
        }
        else if(type=="date"){
            let tempDate=null
            
            if(data){
                const date1=new Date(data).toISOString()
                const date2=new Date(date1).toLocaleString('en-GB')
                tempDate= date2.substring(0,10)
        
            }

            return  (
                    <p  className="table-p"
                        style={{margin:"0.1rem 0"}} >
                    {tempDate
                    ?`${tempDate}`
                    :null
                    }
                    </p>
                    )
        }
        else if(type=="time"){
            let tempTime=null

            if(data){
                const date1=new Date(data).toISOString()
                const date3=new Date(date1).toLocaleString('en-GB')
                tempTime=date3.substring(12,17)
            }
            return  <p className="table-p"
                        style={{margin:"0.1rem 0"}} >
                    {tempTime
                    ?`${tempTime}`
                    :null
                    }
                    </p>
        }
        else if(type=="datetime"){
            let tempDateTime=null
            if(data){
                const date1=new Date(data).toISOString()
                const date3=new Date(date1).toLocaleString('en-GB')
                tempDateTime=date3
            }
            return  <p className="table-p"
                      style={{margin:"0.1rem 0"}} >
            {tempDateTime
            ?`${tempDateTime}`
            :null
            }</p>
        }
       
        else if(type=="number"){
            return <p className="table-p"
                      style={{margin:"0.1rem 0"}}>{data}</p>
        
        }
        else if(type=="string"){
            return <p className="table-p"
                        style={{margin:"0.1rem 0"}}>{data}</p>
          
        }

        else{
            return(
                <div>null</div>
            )
        }
   
}
*/
//-----------------------------
/*
const renderGridContent=(v)=>{


    const genColor=(idx)=>{
        let tempColor
        const temp=idx%4
        if(temp==0){tempColor="darkblue"}
        else if(temp==1){tempColor="brown"}
        else if(temp==2){tempColor="darkgreen"}
        else {tempColor="black"}

        return tempColor
    }   

    let temp=Object.keys(filterData.tableTemplate)
    let tempArray=[]

    temp.map(i=>{
        const tempCheck=filterData.tableTemplate[i].showCol
        if(tempCheck){
            if(i=="photoUrl1"||i=="photoUrl2"
            ){}
            else{
                tempArray=[...tempArray,i]
            }
        }
    })

    return tempArray.map((b,idxB)=>(
        <div className="w-100" style={{display:"flex",alignItems:"baseline",color:genColor(idxB)}}
            onClick={e=>{
                if(b=="selectedLine"){

                }
                else {
                    setEditData(v)
                }
            }}
        >
            {b=="selectedLine"
            ?null
            :<div>{`${filterData.tableTemplate[b].lb}:`}</div>
            }
            {
                showContent(b,filterData.tableTemplate[b],v[b],v,idxB)
            }

        </div>
    ))
}

*/
//============================
/*
const genBgColor=(v)=>{
    let temp="" 
    if(filterData.editData&&v){
        if(filterData.editData.id==v.id){
            temp="#bbc9a5" 
        }
    }

    return temp
}
*/
//----------------------------
/*
const renderGridImage=()=>{

    return filterData.data0.map((v,idxV)=>{
        //console.log(v)
        let tempCheck=false
        if(v.photoUrl1){
            if(v.photoUrl1.length>0){
                if(v.photoUrl1[0]){
                    //console.log(v.photoUrl1[0])
                    tempCheck=true
                }
            }
        }

        return(
            <div key={idxV}
                 className="bd-pureWhite xc4 lc3 mc2 sc1"
                 style={{ padding:"0.4rem",
                          height:"200%",

                          minWidth:"40vh",
                          //minHeight:"60vh",
                          margin:"0.3rem",
                          backgroundColor:genBgColor(v),
                 }}
                 onClick={e=>{
                 }}
                
            >
                {
                tempCheck
                ?<div className="w-100 flex-center-center" 
                     style={{height:"30vh",width:"80%",margin:"auto"}}
                >
                    
                    <Galleryone imgarrs={v.photoUrl1} 
                    width={"100%"}
                    
                    />

                </div>
                :null
                
                }


                <div className="w-100" 
                     style={{position:""}}
                >   
                   
                    {
                    renderGridContent(v)
                    }
                </div>


            </div>
        )
        })
}
*/
//----------------------------
/*
const renderGrid=()=>{
    return filterData.data0
        ?<div  
            style={{display:"flex",height:"100%",overflow:"auto"}}>
            {
             renderGridImage()
            }
        </div>
        :null
}
*/
//----------------------------
const renderTable=()=>{

    //console.log('renderTable')
    //console.log(filterData)

    return (
            filterData.data0
            ?<Table
                colorHead={"#4b6d62"}
                tableTemplate={filterData.tableTemplate}
                setTableTemplate={setTableTemplate}

                filterData={filterData.data0}
                setFilterD1Func={setFilterD1FuncData0}
                
                editData={filterData.editData}
                setEditData={setEditData}
                saveTableTemplateFunc={saveTableTemplateFunc}
                isSubTable={false}
                updateFilterData={updateFilterDataFunc}
                useInput={false}
                
            />
            :null
    )
}

//-----------------------------

const renderFilter=()=>{
    //console.log('renderFilter')
    const {limitRow,sort,isCsv,isXlsx,inputState,showModalFilter}=filterData
    return (
        <div className="" style={{marginBottom:"1rem"}}>
            <ModalFilterInput
            show={showModalFilter} 
            setShow={setShowModalFilter}
            
            filterTemplate={filterTemplate}

            inputState={inputState} 
            setInputState={setInputState}
            
            limitRow={limitRow} 
            setLimitRow={setLimitRow}

            isCsv={isCsv}
            setIsCsv={setIsCsv}

            isXlsx={isXlsx}
            setIsXlsx={setIsXlsx}
            
            sort={sort} 
            setSort={setSort}
            
            filterAxios={filterAxiosFunc}
            basicData={basicData}
            
            LB={pageFilterForm}

            />
        </div>
    )
}
//------------------------------
//"MdPersonSearch":"ผู้จำหน่าย",
//"MdOutlinePersonSearch":"ลูกค้า",
const renderCustomerBadge=()=>{

    const {count,limitRow,pageNumber}=filterData2

    const calTotalPage =()=>{
        return Math.ceil(count/limitRow)
     }
 

    return(
        <div className="badge-frame-xc12"
        style={{background:"pink",height:"2rem"}}
        > 
        
            <div className="flex-center-center flex-no-wrap xc12 jc-start"
                style={{justifyContent:"flex-start"}}>

                <div className="iconbox"
                    onClick={e=>{
                        //changeSwapState()
                        //if(swapFunc){swapFunc()}
                        setIconActionData2(filterData2.editData)
                    }}
                >
                    <Ticon
                        iconName="RiShareForwardLine"
                        className="sm-icon"
                        textStyle={{color:"black"}}
                    />
                </div>

                {true//pageNumberShow
            ?<div>
                <MdChevronLeft
                    className="sm-icon"
                    style={{visibility:(calTotalPage()>1)&&(pageNumber>1)
                            ?"visible":"hidden"}}
                    onClick={e=>{
                        const temp=parseInt(pageNumber)-1
                        setPageNumber2(temp)
                        //setReloadData(true)
                    }}
                />
            </div>
            :null
            }

            {calTotalPage()>1//&&pageNumberShow
             ?<input 
                type="number"
                style={{width:"70px"}}
                value={pageNumber.toString()}
                onChange={e=>{
                    const temp=parseInt(e.target.value)
                    if(temp<=calTotalPage()||!temp){ //04-06-2021
                        setPageNumber2(temp)
                        //setReloadData(true)
                    }
                }}
              />
             :null
            }        

            {calTotalPage()>1//&&pageNumberShow
            ?<div style={{paddingTop:"0rem"}}>
                <div>{`/${calTotalPage()}`}</div>
            </div>
            :null
            }

            {(calTotalPage()>1)&&(pageNumber<calTotalPage())//&&pageNumberShow
            ?<div>
                <MdChevronRight
                    className="sm-icon"
                    onClick={e=>{
                        //console.log('pageNumber')
                        //console.log(pageNumber)
                      const temp=parseInt(pageNumber)+1
                      setPageNumber2(temp)
                      //setReloadData(true)
                    }}
                />
            </div>
            :null
            }
               
            {(calTotalPage()>1)&&(pageNumber<calTotalPage())//&&pageNumberShow
             ?<div>
                 <MdLastPage
                    className="sm-icon"
                    onClick={e=>{
                        const temp=parseInt(calTotalPage())
                        setPageNumber2(temp)
                        //setReloadData(true)
                    }}
                 />
              </div>   
             :null   
            }    







            </div>
        </div>

    )

}

//==============================
const renderCustomerFilter=()=>{
    //console.log('renderCustomerFilter')
    //console.log(filterData2)
    
    const {limitRow,sort,isCsv,isXlsx,inputState,showModalFilter}=filterData2

    return(
        <div className="" style={{marginBottom:"1rem"}}>

            <ModalFilterInput
            show={showModalFilter} 
            setShow={setShowModalFilter2}
            
            filterTemplate={partnerFilterTemplate}

            inputState={inputState} 
            setInputState={setInputState2}
            
            limitRow={limitRow} 
            setLimitRow={setLimitRow2}

            isCsv={isCsv}
            setIsCsv={setIsCsv2}

            isXlsx={isXlsx}
            setIsXlsx={setIsXlsx2}
            
            sort={sort} 
            setSort={setSort2}
            
            filterAxios={filterAxiosForCustomerFunc}
            basicData={basicData}
            
            LB={customerPageFilterForm}

            />
        </div>
    )

}

const renderCustomerTable=()=>{

    return (
        filterData2.data0
            ?<Table
                colorHead={"#4b6d62"}
                tableTemplate={filterData2.tableTemplate}
                setTableTemplate={()=>{}}//{setTableTemplate}

                filterData={filterData2.data0}
                setFilterD1Func={()=>{}}//{setFilterD1FuncData0}
                
                editData={filterData2.editData}
                setEditData={setEditData2}
                saveTableTemplateFunc={()=>{}}//{saveTableTemplateFunc}
                isSubTable={false}
                //updateFilterData={()=>{}}//{updateFilterDataFunc}
                useInput={false}
                
            />
            :null
    )
}

const renderCustomer=()=>{
    //console.log('renderCustomer')
    return (
        <div className='w-100'>
            {
                renderCustomerFilter()
            }   
            {
                renderCustomerBadge()
            }
            {
                renderCustomerTable()
            }
        </div>
    )
}


//-----------------------------
const renderBadgeLeft=()=>{
    return (
    <div className="badge-frame-xc12"
        style={{background:bgColor,height:"2.5rem"}}
    > 
        
        <div className="flex-center-center flex-no-wrap xc12 jc-start"
            style={{justifyContent:"flex-start"}}
        >

            <div className="iconbox"
                onClick={e=>{
                    //changeSwapState()
                    //if(swapFunc){swapFunc()}
                    const temp={...filterData,
                        leftBadge:0
                    }
                    setFilterD1Func(temp)
                    //setMainContextFilterDataFunc(temp)
                }}
            >
                <Ticon
                    iconName="MdSearch"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>

           

            <div className="iconbox"
                onClick={e=>{
                    //changeSwapState()
                    //if(swapFunc){swapFunc()}
                    const temp={...filterData,
                        leftBadge:1
                    }
                    setFilterD1Func(temp)
                    //setMainContextFilterDataFunc(temp)
                }}
            >
                <Ticon
                    iconName="MdOutlinePersonSearch"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>    


            <div className="iconbox"
                onClick={e=>{
                    //changeSwapState()
                    //if(swapFunc){swapFunc()}
                    const temp={...filterData,
                        leftBadge:2
                    }
                    setFilterD1Func(temp)
                    //setMainContextFilterDataFunc(temp)
                }}
            >
                <Ticon
                    iconName="FaRegCalendarAlt"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>    


        </div>
    </div>
    )
}
//==============================
//-----------------------------

const renderGroup=()=>{

    return (
    <div className="w-100" 
         style={{overflowX:"hidden",
                 paddingBottom:"5rem"}}>

        {
           renderBadgeLeft()
        }


        {   filterData.leftBadge=="0"
            ?renderFilter()
            :null
        }
       

        {   filterData.leftBadge=="1"
            ?renderCustomer()
            :null
        }

        {   filterData.leftBadge=="2"
            ?<div style={{
                width:"100%",
                minHeight:"80vh"
                }}>
                <TaskCalendar
                    basicData={basicData}
                    myheader={myheader}
                    findTaskId={findTaskId}
                />
            </div>
            :null
        }


        {
        filterData.leftBadge=="0"
         ?filterData.showAdd||filterData.showEdit||filterData.showRepeatedAdd
            ?renderRight({...filterData,
                        badgeState:{...filterData.badgeState,
                            addShow:false,
                            editShow:false,
                            delShow:false,
                            printerShow:false,
                            csvShow:false,
                            forwardShow:false
                        }})
            :null   
         :null
        }
    </div>
    )
}

//------------------------
//-----------------------------

const renderRight=(filterData)=>{
    //console.log('renderRight')
    //console.log(renderRight)

    return(
    <div className="w-100 h-100">
        <div className="hide-on-printX"
        style={{height:filterData.showAdd||filterData.showEdit?"2rem":"5%",width:"100%"}}
        >
            {
                    renderBadge({
                        filterData,
                        setPageNumber:setPageNumber,
                        
                        totalSwapPage:1, 
                        setSwapState:setSwapState,
                        
                        setReloadData:refreshPage,
                        setShowFilter:()=>{},
                        setShowAdd:setShowAdd,
                        setShowEdit:setShowEdit,
                        setShowModalConfirm:setShowModalConfirm,
                        
                        setShowModalCsv:()=>{},//genCsvFunc,
                        //csvFunc:()=>{//console.log('csvFunc')},

                        setShowRepeatedAdd:setShowRepeatedAdd,
                        setUnSelectAll:setUnSelectAllFunc,
                        setShowForwardConfirm:(value)=>{setFilterD1Func({...filterData,showTransactionConfirm:value})},
                        setClose:()=>{},
                        bgColor:bgColor,
                        captureSelect:()=>{}//captureSelect
                    })        
            }

        </div>

        <div className=""
                style={!filterData.showEdit&&!filterData.showAdd
                        ?{width:"100%",height:"95%"}
                        :{width:"100%"}
                    }//height:"95%"
        >
            <div style={!filterData.showEdit&&!filterData.showAdd
                    ?{width:"100%",height:"100%"}
                    :{width:"100%",height:"100%"}
                }//height:"100%"
                className="show-on-print"
            >
                {   filterData.swapState
                    ?renderTable()
                    :renderGrid(filterData,tableTemplate,setEditData,updateFilterDataFunc)
                }
            </div>
            
        </div>
    </div>
    )
}

//=============================
const renderPage=()=>{
    const {showAdd,showEdit,showRepeatedAdd}=filterData

    if(showAdd){
        return renderPageFormAdd()
    }
    else if(showEdit){
        return renderPageFormEdit()
    }
    else if(showRepeatedAdd){
        return renderPageFormRepeatedAdd()
    }
    else{
        return renderRight(filterData) 
    }
}

//==============================

const renderBody=()=>{
return(
    filterData&&
    <div className=""
             style={{display:"flex",height:"95%",width:"100%"}}
    >
            
            <div className="hide-on-printX" 
                 style={{height:"100%",
                         width:`${filterData.widthLeft}%`,
                         overflowY:"scroll",
                         overflowX:"hidden"
                         }}>
                     {
                       renderGroup()
                     }
            </div>
        
            <div className="" 
                style={{height:"100%",width:`${100-filterData.widthLeft}%`}}>
                 {
                    renderPage()
                 }
                 
                 {
                    /*
                    filterData.showAdd
                    ?renderPageFormAdd()
                    :filterData.showEdit
                      ?renderPageFormEdit()
                      :renderRight(filterData) 
                    */
                 }
               
            </div>
    </div>
)
}

//===============================
//===============================

const renderPageFormAdd=()=>{

    return(
        <PageForm
        isAddForm={true}
        lb={addFormTitle}
        formTemplate={formTemplate}
        stateTemplate={stateTemplate}
        selectData={{basicData:basicData}}
    
        iconAction={[()=>{}]}
        iconActionData={filterData2.iconActionData}
        iconActionDataDetail={null}
        loadData={{id:parseInt(filterData.lastRecordId)+1}}
        keyName={filterData.keyName}
        setShow={setShowAdd}
    
        calculation={null}
        submitFunction={submitFunctionAddFunc}
        selectGroup={filterData.selectGroup}
        setSelectGroup={setSelectGroupFunc}
        selectProduct={filterData.selectProduct}
        pageDataModalForm={pageDataModalForm}
        dataUrl={filterData.dataUrl}
        myheader={myheader}
        tableTemplateUrl={filterData.tableTemplateUrl}
        tableTemplateName={filterData.detailTableTemplateForFormName}
        detailTableTemplate={filterData.detailTableTemplateForForm}
        calDigit={calDigit}
    />
    )

}
//----------------------------------


const renderPageFormRepeatedAdd=()=>{

    return(
        <PageForm
        isAddForm={true}
        lb={repeatedAddFormTitle}
        formTemplate={repeatedAddFormTemplate}
        stateTemplate={stateTemplate}
        selectData={{basicData:basicData}}
    
        iconAction={[()=>{}]}
        iconActionData={filterData2.iconActionData}
        iconActionDataDetail={null}
        loadData={{id:parseInt(filterData.lastRecordId)+1}}
        keyName={null}//{filterData.keyName}
        setShow={setShowRepeatedAdd}
    
        calculation={null}
        submitFunction={submitFunctionAddManyFunc}
        selectGroup={filterData.selectGroup}
        setSelectGroup={setSelectGroupFunc}
        selectProduct={filterData.selectProduct}
        pageDataModalForm={pageDataModalForm}
        dataUrl={filterData.dataUrl}
        myheader={myheader}
        tableTemplateUrl={filterData.tableTemplateUrl}
        tableTemplateName={filterData.detailTableTemplateForFormName}
        detailTableTemplate={filterData.detailTableTemplateForForm}
        calDigit={calDigit}
    />
    )

}

//==================================

const renderPageFormEdit=()=>{

        return(
            <PageForm
                lb={editFormTitle}
                formTemplate={editFormTemplate}
                stateTemplate={stateTemplate}
                selectData={{basicData:basicData}}
            
                iconAction={[()=>{}]}
                iconActionData={filterData2.iconActionData}
                iconActionDataDetail={null}
                loadData={filterData.editData}
                keyName={filterData.keyName}
                setShow={setShowEdit}
            
                calculation={null}
                submitFunction={submitFunctionEditFunc}
                selectGroup={filterData.selectGroup}
                setSelectGroup={setSelectGroupFunc}
                selectProduct={filterData.selectProduct}
                pageDataModalForm={pageDataModalForm}
                dataUrl={filterData.dataUrl}
                myheader={myheader}

                tableTemplateUrl={filterData.tableTemplateUrl}
                tableTemplateName={filterData.detailTableTemplateForFormName}
                detailTableTemplate={filterData.detailTableTemplateForForm}
            />  
        )
    
}

//================================
//=================================

//=================================
return(
<div className="w-100 h-100">
        <button style={{display:"none"}}
            ref={refSecrete}
            onClick={e=>{
                setMainContextFilterDataFunc()
            }}
        >
            secrete
        </button>

        <div className="h-5 w-100"
            
        >
            <Navbar 
                setMainContextFilterData={()=>refSecrete.current.click()}
            />
        </div>

        <div className="h-95 w-100">
        {   
          renderBody()    
        }
    
        <div className="" 
            style={{width:"100%",display:"flex",position:"relative"}}>
                {
                renderWidthRangeBar({showRange:filterData.showRange,
                                    setShowRange:setShowRange,
                                    widthLeft:filterData.widthLeft,
                                    setWidthLeft:setWidthLeft,
                                    //heightTop:filterData.heightTop,
                                    //setHeightTop:setHeightTop
                                })
                }
    
        </div>

 

        {
        
        filterData.showTransactionConfirm&&
        renderTransactionConfirm({
            editTransaction:filterData.editData,
            submitFunc:transactionConfirmSubmitFunc,
            cancelFunc:transactionConfirmCancelFunc,
            pageData:pageData,
            changeIncludeTransactionHead:()=>{},//changeIncludeTransactionHead,
            includeTransactionHead:filterData.includeTransactionHead
            })
        
        }
        
        {
        
            modalConfirmReloadState.show
            ?<ModalConfirmReload
                setShow={cancelConfirmReload}
                submitFunction={confirmReload}
            />
            :null
        
        }
        {
        
        filterData.showModalConfirm
        ?<ModalConfirm
            setShow={setShowModalConfirm}
            submitFunction={submitFunctionDeleteFunc}
        />
        :null
        
        }

        {
        
        filterData.showModalError
        ?renderModalError({
            setShow:setShowModalError,
            message:filterData.message
        })
        :null
        
        }
    </div>
</div>
)

}




export default PageComponentTask ;
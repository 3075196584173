import React from 'react';
import axios from '../../util/axiosVerify'
//import {MainContext} from '../../context/MainContext'
import uuid from 'react-uuid'
import Navbar from '../../component/navbar/Navbar';

import ModalFilterInput from '../../render/renderFilter/ModalFilterInput'
import pageUtil from './pageUtil'
//import NewGroupComponent from '../../render/renderTree/NewGroupComponent'
import axiosUtil from '../../util/axiosUtil'
import Table from '../../component/table/Table'
import renderWidthRangeBar from '../../component/table/renderWidthRangeBar'
import renderBadge from '../../render/renderBadge/renderBadge'
import renderModalError from '../../render/renderModalError'
import ModalConfirm from '../../render/ModalConfirm'
import ModalConfirmReload from '../../render/ModalConfirmReload'

//import ModalForm from '../../render/renderForm/ModalForm'
//import NewTree from '../../component/newtree/NewTree'
import NewGroup from '../../component/newgroup/NewGroup'

import PageForm from '../../render/renderForm/PageForm'
//import ModalCsv from './ModalCsv'
import renderTransactionConfirm from '../../page/bill/renderTransactionConfirm';
import {FaCheck,FaBan,FaFolder} from 'react-icons/fa';
import console from '../../util/consoleVerify'

import renderGrid from '../../render/renderForm/renderGrid'


const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,catchErrorToMessage}=axiosUtil

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete,
    genCsv,genXlsx
} = pageUtil

function PageComponentProduct(props) {

//console.log('PageComponent')
//const {basicDataSt,tokenSt,setReloadCheckToken,myheader}=React.useContext(MainContext)

const {filterDataTemplate,filterDataTemplate2,filterDataTemplate3,
      pageFilter,pageFilterForm,pageFilterForm2,...remaining}=props



const {filterTitle,inputState,filterTemplate,filterTemplate2,
      myheader,basicDataSt,
      formTemplate,editFormTemplate,stateTemplate,
      groupFormTemplate,groupEditFormTemplate,
      pageDataModalForm,
      addFormTitle,
      editFormTitle,
      groupAddFormTitle,
      groupEditFormTitle,
      bgColor,
      calDigit,
      setReloadBasicData,

      mainContextProductFilterData,
      setMainContextProductFilterData,
      mainContextProductFilterData2,
      setMainContextProductFilterData2

}=props

//console.log('filterTemplate2')
//console.log(filterTemplate2)

const {basicData,pageData,tableTemplate,reloadBasicData,user,allUser}=basicDataSt
//console.log('tableTemplate............dddddddddd')
//console.log(tableTemplate)
//console.log(filterTemplate2)
//console.log(allUser)
const refSecrete=React.createRef()

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const [billTableTemplate,setBillTableTemplate]=React.useState(null)

React.useEffect(()=>{
    if(tableTemplate){    
        setBillTableTemplate(tableTemplate.productDetailTableTemplateForForm)
    }
},[basicDataSt])



const defaultFilterData={
    ...filterDataTemplate,...remaining,
    tableTemplate:
        filterDataTemplate.tableTemplateName
        ?tableTemplate[filterDataTemplate.tableTemplateName]
        :null,
    detailTableTemplate:
        filterDataTemplate.detailTableTemplateName
        ?tableTemplate[filterDataTemplate.detailTableTemplateName]
        :null,
    detailTableTemplateForForm:
        filterDataTemplate.detailTableTemplateForFormName
        ?tableTemplate[filterDataTemplate.detailTableTemplateForFormName]
        :null,
    inputState:inputState,
    widthLeft:basicData.widthLeft,
    limitRow:basicData.limitRow,
    isCsv:false,
    isXlsx:false,
    showModalFilterSelectEdit:true,
    reloadModalFilterSelectEdit:false,

    swapState:basicData.showTable
}

const defaultFilterData2={
    ...filterDataTemplate,
    tableTemplateUrl:"p35branch",

    tableName:"productStockTableTemplate",
    tableTemplateName:"productStockTableTemplate",
    tableTemplate:tableTemplate["productStockTableTemplate"],
    widthLeft:basicData.widthLeft,
    limitRow:basicData.limitRow,
    editData:null,
    isCsv:false,
    isXlsx:false,
    showModalFilter:false,
    reload:false,
    inputState:inputState,
    badgeState:{
        swapShow:false,swapFunc:()=>{},
        reloadShow:false,reloadFunc:()=>{},
        filterShow:false,filterFunc:()=>{},
        addShow:false,addFunc:()=>{},
        editShow:false,editFunc:()=>{},
        delShow:false,delFunc:()=>{},
        printerShow:false,printerFunc:()=>{},
        forwardShow:false,forwardFunc:()=>{},
        bullEyeShow:false,
        unSelectShow:false,
        closeShow:true
    },
    showModalFilterSelectEdit:true,
    reloadModalFilterSelectEdit:false
}

const genFilterData=()=>{
    if(mainContextProductFilterData){
        return mainContextProductFilterData
    }
    else {
        return defaultFilterData
    }
}

const genFilterData2=()=>{
    if(mainContextProductFilterData2){
        return mainContextProductFilterData2
    }
    else {
        return defaultFilterData2
    }
}

const [filterData,setFilterData]=React.useState(genFilterData())

const [filterData2,setFilterData2]=React.useState(genFilterData2())

const [modalConfirmReloadState,setModalConfirmReloadState]=React.useState({
    show:false,
    filterData:null
})

const cancelConfirmReload=()=>{
    
    setFilterData(
        {...modalConfirmReloadState.filterData,
            showEdit:true,
            reloadData:false
        })
    setModalConfirmReloadState({
        filterData:null,
        show:false
    })
}

const confirmReload=(data)=>{
    setFilterData(modalConfirmReloadState.filterData)
    setModalConfirmReloadState({
        filterData:null,
        show:false
    })
}

const setMainContextFilterDataFunc=()=>{
    //console.log('setMainContextFilterDataFunc')
    //console.log(filterData)
    setMainContextProductFilterData(filterData)
    setMainContextProductFilterData2(filterData2)
}

React.useEffect(()=>{
  
    if(filterData2.reloadData){
        //console.log('filterData2............')
        //console.log(filterData2)
        const {dataUrl,pageNumber,limitRow,sort,qry}=filterData2
        //console.log(limitRow)

        //console.log(sort)

        //console.log(`qry filterData2.....`)
        //console.log(filterData)
        //console.log(Object.keys(qry))
    
        let tempOption="or"
        if( Object.keys(qry)[0]=="$and" ){
            tempOption="and"
        }
        //console.log('tempOption')
        //console.log(tempOption)

        axios.post(`/${dataUrl}/getbranchproductlimit`,
               {pageNumber,limitRow,sort,...qry},myheader,shouldRender)
        .then(result=>{
            //console.log('result............')
            //console.log(result.data.data)
            
            const tempArray=genStockOfAllBranch(result.data.data,inputState,tempOption)
            const tempResult={...filterData2,
                data0:tempArray,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                isCsv:false,
                isXlsx:false,
                reloadData:false,
            }

            if(filterData2.isCsv)
            {
                //genCsv(tempResult,filterData2.tableTemplateName)
                setFilterData2(tempResult)
            }
            else if(filterData2.isXlsx){
                //genXlsx(tempResult,filterData2.tableTemplateName)
                setFilterData2(tempResult)
            }
            else{
                setFilterData2(tempResult)
            }
            
        })
        .catch(error=>{
            const tempError={...filterData2,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterData2(tempError)
        })
    }


    if(filterData2.isCsv){
        //console.log('isCsv')
        genCsv({data0:filterData2.data0,tableTemplate:filterData2.tableTemplate})
        setFilterData2({...filterData2,isCsv:false})
    }
    if(filterData2.isXlsx){
        //console.log('isXlsx')
        genXlsx({data0:filterData2.data0,tableTemplate:filterData2.tableTemplate})
        setFilterData2({...filterData2,isXlsx:false})
    }


},[filterData2])

React.useEffect(()=>{
    //console.log('UserEffect ..... filterData PageComponent')
    //console.log(filterData.editData)

    //console.log(filterData)

    if(filterData.reloadData){
        const {dataUrl,pageNumber,limitRow,sort,qry}=filterData
    
        //console.log('filterData.....effect')
        //console.log(filterData)


        axios.post(`/${dataUrl}/getlimit`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
        .then(result=>{
            //console.log('result............')
            //console.log(result.data.data)
                const temp2= genFilterDataWithIndex(
                    result.data.data,
                    filterData.selectProduct,
                    null,
                    null
                    )
    
                const tempResult={...filterData,
                    data0:temp2,
                    count:result.data.count,
                    lastRecordId:result.data.lastRecordId,
                    isCsv:false,
                    reloadData:false,
                    
                }

                if(filterData.isCsv)
                {
                    //genCsv(tempResult,filterData.tableTemplateName)
                    setFilterData({...tempResult,isCsv:false})
                }
                if(filterData.isXlsx)
                {
                    //genXlsx(tempResult,filterData.tableTemplateName)
                    setFilterData({...tempResult,isXlsx:false})
                }
                else{
                    setFilterData(tempResult)
                }

        })
        .catch(error=>{
            const tempError={...filterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterData(tempError)
        })
    }


    if(filterData.isCsv){
        //console.log('isCsv')
        genCsv({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isCsv:false})
    }
    if(filterData.isXlsx){
        //console.log('isXlsx')
        genXlsx({data0:filterData.data0,tableTemplate:filterData.tableTemplate})
        setFilterData({...filterData,isXlsx:false})
    }



},[filterData])


React.useEffect(()=>{
    if(filterData.checkSelectProduct){
        //console.log('lokkkkk')
        let tempArray=[]
        if(filterData.data0){
            filterData.data0.map(i=>{
                tempArray=[...tempArray,{...i,selectedLine:false}]
            })
        
        }
        setFilterData({...filterData,
            data0:tempArray,
            selectProduct:[],
            checkSelectProduct:false
        })
    }
},[filterData.checkSelectProduct])


const setShowRange=(data)=>{setFilterData({...filterData,showRange:data})}
const setHeightTop=(data)=>{setFilterData({...filterData,heightTop:data})}
const setWidthLeft=(data)=>{setFilterData({...filterData,widthLeft:data})}
const setShowModalError=(data)=>{setFilterData({...filterData,showModalError:data})}
const setShowModalConfirm=(data)=>{setFilterData({...filterData,showModalConfirm:data})}
const setPageNumber=(data)=>{setFilterData({...filterData,pageNumber:data,reloadData:true})}
const setIsCsv=(data)=>{
    if(data){
        setFilterData({...filterData,isCsv:true,isXlsx:false})
    }
    else{
        setFilterData({...filterData,isCsv:false})
    }
}
const setIsXlsx=(data)=>{
    if(data){
        setFilterData({...filterData,isXlsx:true,isCsv:false})
    }
    else{
        setFilterData({...filterData,isXlsx:false})
    }
}
const setShowModalError2=(data)=>{setFilterData2({...filterData2,showModalError:data})}
const setShowModalConfirm2=(data)=>{setFilterData2({...filterData2,showModalConfirm:data})}
const setPageNumber2=(data)=>{
    //console.log('setPageNumber2')
    //console.log(data)
    setFilterData2({...filterData2,
        pageNumber:data,
        reloadData:true
    })
}
const setIsCsv2=(data)=>{
    if(data){
        setFilterData2({...filterData2,isCsv:true,isXlsx:false})
    }
    else{
        setFilterData2({...filterData2,isCsv:false})
    }
}
const setIsXlsx2=(data)=>{
    if(data){
        setFilterData2({...filterData2,isXlsx:true,isCsv:false})
    }
    else{
        setFilterData2({...filterData2,isXlsx:false})
    }
}

const setShowAdd=(data)=>{
    //console.log('setShowAdd')
    //console.log(data)
    setFilterData({
        ...filterData,
        showAdd:data   
    })
}

const setShowEdit=(data)=>{
    //console.log('setShowEdit')
    //console.log(data)
    setFilterData({
        ...filterData,
        showEdit:data
    })
}

const setTableTemplate=(data)=>{setFilterData({...filterData,tableTemplate:data})}
const setDetailTableTemplate=(data)=>{setFilterData({...filterData,detailTableTemplate:data})}
const setTableTemplate2=(data)=>{setFilterData2({...filterData2,tableTemplate:data})}


const setSwapState=(data)=>{
    //console.log('setSwapState')
    //console.log(data)
    setFilterData({
        ...filterData,
        swapState:data
    })
    
}


const setEditData=(data)=>{
    let tempData=data
    //console.log('setEditData.....')
    //console.log(data)

    axios.post(`/p35product/getlimit`,
    { pageNumber:1,
     limitRow:300,
     sort:{id:1},
     groupId:data.groupId

    },myheader,shouldRender)
    .then(result=>{


        const tempArray=genFilterDataWithIndex(result.data.data,null,null,null)

        setFilterData({...filterData,
            editData:tempData,
            /*
            inputState:{
               ...filterData.inputState,
               groupId:{...filterData.inputState.groupId,
                       min:data.groupId.toString(),
                       max:data.groupId.toString(),
                   },
               groupName:{...filterData.inputState.groupName,
                       value:data.groupName,
                   },     
               
            },
            */
            //showModalFilterSelectEdit:false,
            //reloadModalFilterSelectEdit:true,
            data3:tempArray//result.data.data
        })

    })
    .catch(error=>{
            setFilterData({...filterData,
                editData:tempData,
                /*
                inputState:{
                ...filterData.inputState,
                groupId:{...filterData.inputState.groupId,
                        min:data.groupId.toString(),
                        max:data.groupId.toString(),
                    },
                groupName:{...filterData.inputState.groupName,
                        value:data.groupName,
                    },     
                
                },
                */
                //showModalFilterSelectEdit:false,
                //reloadModalFilterSelectEdit:true
            })

    })
}

    /*
    axios.post(`/p35product/getlimit`,
        { pageNumber:1,
         limitRow:300,
         sort:{id:1},
         groupId:data.groupId
    
        },myheader,shouldRender)
        .then(result=>{
            console.log('result............')
            console.log(result.data.data)


            setFilterData({...filterData,
                editData:tempData,
                inputState:{
                   ...filterData.inputState,
                   groupId:{...filterData.inputState.groupId,
                           min:data.groupId.toString(),
                           max:data.groupId.toString(),
                       },
                   groupName:{...filterData.inputState.groupName,
                           value:data.groupName,
                       },     
                   
                },
                showModalFilterSelectEdit:false,
                reloadModalFilterSelectEdit:true,
                data3:result.data.data
            })

        
        .catch(error=>{
        
             setFilterData({...filterData,
             editData:tempData,
             inputState:{
                ...filterData.inputState,
                groupId:{...filterData.inputState.groupId,
                        min:data.groupId.toString(),
                        max:data.groupId.toString(),
                    },
                groupName:{...filterData.inputState.groupName,
                        value:data.groupName,
                    },     
                
             },
             showModalFilterSelectEdit:false,
             reloadModalFilterSelectEdit:true,
        })
            
        })
    */

    /*
    let tempData=data

    setFilterData({...filterData,
        editData:tempData,
        inputState:{
            ...filterData.inputState,
            groupId:{...filterData.inputState.groupId,
                    min:data.groupId.toString(),
                    max:data.groupId.toString(),
                },
            groupName:{...filterData.inputState.groupName,
                    value:data.groupName,
                },     
            
        },
        showModalFilterSelectEdit:false,
        reloadModalFilterSelectEdit:true,
    })
    */
//}

React.useEffect(()=>{
    if(filterData.reloadModalFilterSelectEdit){
        setFilterData({
            ...filterData,
            showModalFilterSelectEdit:true,
            reloadModalFilterSelectEdit:false,
        })
    }
},[filterData.reloadModalFilterSelectEdit])

React.useEffect(()=>{
    if(filterData2.reloadModalFilterSelectEdit){
        setFilterData2({
            ...filterData2,
            showModalFilterSelectEdit:true,
            reloadModalFilterSelectEdit:false,
        })
    }
},[filterData2.reloadModalFilterSelectEdit])

//to save filterData when sort Up and down each column
const setFilterDataData0=(data)=>{setFilterData({...filterData, data0:data})}
const setFilterDataData02=(data)=>{setFilterData2({...filterData2, data0:data})}

const setSort=(data)=>{setFilterData({...filterData,sort:data})}
const setLimitRow=(data)=>{setFilterData({...filterData,limitRow:data})}
const setInputState=(data)=>{setFilterData({...filterData,inputState:data})}
const setShowModalFilter=(data)=>{setFilterData({...filterData,showModalFilter:data})}


const setSort2=(data)=>{setFilterData2({...filterData2,sort:data})}
const setLimitRow2=(data)=>{setFilterData2({...filterData2,limitRow:data})}
const setInputState2=(data)=>{setFilterData2({...filterData2,inputState:data})}
const setShowModalFilter2=(data)=>{setFilterData2({...filterData2,showModalFilter:data})}


const setSelectGroupFunc=(data)=>{

    setFilterData({...filterData,
                        selectGroup:data,
                 })
  

}

const setUnSelectAllFunc=()=>{
    setFilterData(setUnSelectAll(filterData))
}

const setSelectProductFunc=(value)=>{
    //console.log('setSelectProduct')
    //console.log(value)
    if(value){
        setFilterData({...filterData,
            checkSelectProduct:value
        })
    }  
}

const genFilterDataWithIndex=(filterData,selectProduct)=>{
    //console.log('genFilterWithIndex')
    //console.log(filterData)

    if(filterData){
        if(filterData.detail){
            if(filterData.detail.length()>0){
                let tempArray=[]

                filterData.detail.map(i=>{

                })

            }
        }
    }

    let tempSelectProduct
    if(selectProduct){tempSelectProduct=selectProduct}
    else {tempSelectProduct=[]}

    let tempArray=[]

    filterData.map((i,idx)=>{
        //const temp={...i,tempIndex:idx,selectedLine:false}
        let tempStocking={}

        if(i.stocking){
            let isSearching=true
            i.stocking.map(k=>{
                if(isSearching){
                    if(k.branchId==basicData.branchId){
                        tempStocking={
                            stock:k.stock,
                            minStock:k.minStock,
                            maxStock:k.maxStock,
                            storage:k.storage,
                            branchId:k.branchId
                        }
                        isSearching=false
                    }
                }   
            })
        }


        const temp={...i,selectedLine:false,_id:uuid(),...tempStocking}
        tempArray=[...tempArray,temp]

    })

    let tempArray2=[]

    tempArray.map(i=>{
        let tempObj=i
        tempSelectProduct.map(j=>{
            if(i.id==j.id){
                //console.log('j.id==i.id')
                tempObj={...tempObj,selectedLine:j.selectedLine}
                //tempArray2=[...tempArray2,]
            }
        })
        tempArray2=[...tempArray2,tempObj]
    })
 


    const tempKeyArray=["stocking_stock","stocking_storage"]
    let tempToCheckArray=[]

    tempKeyArray.map(i=>{
        
        if(inputState[i].toCheck){
      
            let tempSubKey
            if(i=="stocking_stock"){
                tempSubKey="stock"
            }
            if(i=="stocking_storage"){
                tempSubKey="storage"
            }

            tempToCheckArray=[...tempToCheckArray,
                {...inputState[i],subKey:tempSubKey,key:i}
            ]
        }
    })








    return tempArray2
}

const refreshPage=()=>{
    //console.log('refreshPage')
    const {limitRow,sort}=filterData
    const temp = {...filterData, limitRow,sort,selectProduct:[],
                editData:null,qry:{},reloadData:true
            }
    setFilterData(temp)
    setReloadBasicData(true)
}

const submitFunctionDeleteFunc=async(data)=>{
    submitFunctionDelete(filterData,myheader,shouldRender)
    .then(result=>setFilterData(result))
    .catch(error=>setFilterData(error))
}

const submitFunctionEditFunc=async (formInputState)=>{

    //console.log('submitFunctionEditFunc........')

    const manyImage=true

    submitFunctionEdit(formInputState,filterData,stateTemplate,myheader,shouldRender,manyImage)
    .then(result=>{
        //const {showEdit,...remaining}=result
        //setFilterData(remaining)
        //setFilterData({...result,showEdit:true,reloadData:false})
        setModalConfirmReloadState({show:true,filterData:result})

        
    })
    .catch(error=>setFilterData(error))
}

const submitFunctionAddFunc=async (formInputState)=>{
    //console.log('submitFunctionAddFunc......')
    //console.log(formInputState)

    const manyImage=true


    submitFunctionAdd(formInputState,filterData,stateTemplate,myheader,shouldRender,manyImage)
    .then(result=>setFilterData(result))
    .catch(error=>setFilterData(error))
}

//{pageNumber,limitRow,sort,dataUrl} = filterData
const filterAxiosFunc=(option,inputState,pageNumber1)=>{

    //console.log(`option = ${option}`)
    //console.log('inputState')
    //console.log(inputState)
    //console.log('filterTemplate')
    //console.log(filterTemplate)
    

    filterAxios(option,inputState,filterTemplate,
                filterData,null,myheader,
                shouldRender,user.branchId)
    .then(result=>{
        //console.log("result...aaa..")
        //console.log(result.data0)
        
        const temp2= genFilterDataWithIndex(result.data0,
            filterData.selectProduct,
            null,
            null
            )
        //console.log('result...temp2...')
        //console.log(temp2)

        if(filterData.isCsv)
        {
            //genCsv({...result},filterData.tableTemplateName)
            setFilterData({...result,
                isCsv:false,
                data0:temp2
            })
            
        }
        if(filterData.isXlsx)
        {
            //genXlsx({...result},filterData.tableTemplateName)
            setFilterData({...result,
                isXlsx:false,
                data0:temp2
            })
            
        }
        else{
            setFilterData({...result,
                data0:temp2
            })
        }
    })
    .catch(error=>setFilterData(error))
}


const genStockOfAllBranch=(data,inputState,option)=>{
    //console.log('genStockOfAllBranch....')
    //console.log(data)
    //console.log(option)
    //console.log(allUser)
    //console.log('inputState')
    //console.log(inputState)
    
    const tempKeyArray=["stocking_stock","stocking_storage"]
    let tempToCheckArray=[]

    tempKeyArray.map(i=>{
        
        if(inputState[i].toCheck){
      
            let tempSubKey
            if(i=="stocking_stock"){
                tempSubKey="stock"
            }
            if(i=="stocking_storage"){
                tempSubKey="storage"
            }

            tempToCheckArray=[...tempToCheckArray,
                {...inputState[i],subKey:tempSubKey,key:i}
            ]
        }
    })

    //console.log('tempToCheckArray')
    //console.log(tempToCheckArray)

    let tempArray1=[]
    let tempObj1={}

    allUser.map(i=>{
        tempArray1=[...tempArray1,i.branchId]
        tempObj1={...tempObj1,[i.branchId]:i.branchName}
    })


    let tempArray2=[...new Set(tempArray1)]
    
    //console.log('tempArray2')
    //console.log(tempArray2)

    let tempArray3=[]

    data.map(i=>{
        const {priceLevel,detail,stocking,...remaining}=i

        stocking.map(j=>{   

            tempArray2.map(k=>{
                if(k==j.branchId){
                    const {branchId,stock,minStock,maxStock,storage}=j
                    const tempObj={...remaining,
                        branchId,
                        branchName:tempObj1[branchId],
                        stock,
                        minStock,
                        maxStock,
                        storage
                    }

               
                    tempArray3=[...tempArray3,tempObj]
                }
            })    



        })
    })

    //data.stocking.map(a=>)
    //console.log('tempArray3')
    //console.log(tempArray3)

    let tempArray4=[]
    
    const tempToCheckArrayLength=tempToCheckArray.length

    if(tempToCheckArrayLength>0){
        
                tempArray3.map(j=>{

                    let countCheckPass=0

                    tempToCheckArray.map(k=>{
                        //console.log('k.subKey')
                        //console.log(k.subKey)

                        if(k.subKey=="storage"){
                            
                            if(j["storage"].includes(k.value)){
                                countCheckPass=countCheckPass+1
                            }
                        
                        }
                        else if(k.subKey=="stock"){
                            
                            if(j["stock"]>=parseInt(k.min)&&j["stock"]<=parseInt(k.max)){
                                countCheckPass=countCheckPass+1
                            }
                            
                        }

                    })


                    if(option=="or"){
                        if(countCheckPass>0){
                            tempArray4=[...tempArray4,j]                       
                        }
                    }
                    else {
                        if(countCheckPass==tempToCheckArrayLength){
                            tempArray4=[...tempArray4,j]                        
                        }
                    }


                })

        
        //console.log('tempArray4...')
        //console.log(tempArray4)
        return tempArray4       

    }
  
    else {
        //console.log('tempArray3...')
        //console.log(tempArray3)
        return tempArray3
    }
  

}


const filterAxiosFunc2=(option,inputState,pageNumber1)=>{



    filterAxios(option,inputState,filterTemplate2,
                filterData2,'getbranchproductlimit',myheader,
                shouldRender,user.branchId
                )
    .then(result=>{
        //console.log("result...getbranchproductlimit..")
        //console.log(result.data0)

        //console.log(result.data0)
        //console.log(inputState)
        //console.log(option)

        const tempArray=genStockOfAllBranch(result.data0,inputState,option)
        
        //console.log('result...temp2...')
        //console.log(temp2)
        if(filterData2.isCsv)
        {
          
            //genCsv( {...result,
            //         data0:tempArray
            //        },filterData2.tableTemplateName
            //    )

            setFilterData2({...result,
                isCsv:false,
                data0:tempArray
            })

        }
        else if(filterData2.isXlsx){

            //genXlsx( {...result,
            //    data0:tempArray
            //   },filterData2.tableTemplateName
            //)

            setFilterData2({...result,
                isXlsx:false,
                data0:tempArray
            })
        }
        else{
            setFilterData2({...result,
                data0:tempArray
            })
        }
        
    })
     .catch(error=>{
        
        const tempError={...filterData2,
            reloadData:false,
            message:catchErrorToMessage(error),
            showModalConfirm:false,
            showModalError:true,
        }
        setFilterData2(tempError)
        
    })
}

const filterDataByGroup=(i)=>{
    //console.log('filterDataByGroup.........')
    //console.log(i)
    //const {folders,...remaining}=i
    const temp = {...filterData,
        pageNumber:1,
        qry:{groupId:i.id},
        //selectGroup:remaining,
        reloadData:true}
    setFilterData(temp)
}


const saveTableTemplateFunc=(tableTemplate)=>{

    //console.log('saveTableTemplateFunc')
    //console.log(tableTemplate)

    const {tableTemplateUrl}=filterData
  


    saveTableTemplate(tableTemplate,tableTemplateUrl,filterData.tableTemplateName,myheader,shouldRender)
}

const saveTableTemplateFunc2=(tableTemplate)=>{
    //console.log('saveTableTemplateFunc2')
    //console.log(tableTemplate)

    const {tableTemplateUrl}=filterData2
    saveTableTemplate(tableTemplate,tableTemplateUrl,filterData2.tableTemplateName,myheader,shouldRender)
}

const updateFilterDataFunc=(index,i)=>{
    //console.log('updateFilterDataFunc')
    const temp=updateFilterData(index,i,filterData)
    //console.log('temp')
    //console.log(temp)
    setFilterData({...filterData,...temp})
}
const updateFilterDataFunc2=(index,i)=>{
    //console.log('updateFilterDataFunc')
    const temp=updateFilterData(index,i,filterData2)
    //console.log('temp')
    //console.log(temp)
    setFilterData2({...filterData2,...temp})
}
const closeBillForm=()=>{
    setFilterData({...filterData,
        showAdd:false,showEdit:false
    })
}
//----------------------------
const transactionConfirmSubmitFunc=()=>{
    let tempEditData=filterData.editData

    if(!filterData.includeTransactionHead){
        tempEditData={...filterData.editData,
            detail:[...filterData.editData.detail,
                    ...filterData.editData.detail
                    ]
        }
    }

    setFilterData({...filterData,
        data1:tempEditData,
        showTransactionConfirm:false,
        showBillForm1:false,
        reloadBillForm1:true
    })
  
}


const transactionConfirmCancelFunc=()=>{
    setFilterData({...filterData,
        showTransactionConfirm:false
    })
}

//=============================

//-----------------------------
const renderTable=()=>{

    //console.log('renderTable')
    //console.log(filterData)

    return (
            filterData.data0
            ?<Table
                colorHead={"#4b6d62"}
                tableTemplate={filterData.tableTemplate}
                setTableTemplate={setTableTemplate}

                filterData={filterData.data0}
                setFilterData={setFilterDataData0}
                
                editData={filterData.editData}
                setEditData={setEditData}
                saveTableTemplateFunc={saveTableTemplateFunc}
                isSubTable={false}
                updateFilterData={updateFilterDataFunc}
                useInput={false}
                
            />
            :null
    )
}
//-------------------------------
const renderTable3=()=>{

    //console.log('renderTable')
    //console.log(filterData)

    return (
            filterData.data0
            ?<Table
                colorHead={"#615550"}
                tableTemplate={filterData.tableTemplate}
                setTableTemplate={setTableTemplate}

                filterData={filterData.data3}
                setFilterData={()=>{}}//{setFilterDataData0}
                
                editData={filterData.editData}
                setEditData={setEditData}
                saveTableTemplateFunc={saveTableTemplateFunc}
                isSubTable={false}
                updateFilterData={updateFilterDataFunc}
                useInput={false}
                
            />
            :null
    )
}

//-----------------------------
const renderFilter=()=>{
    const {limitRow,sort,isCsv,isXlsx,inputState,showModalFilter}=filterData
    return (
        <div className="" style={{marginBottom:"1rem"}}>
            <ModalFilterInput
            show={showModalFilter} 
            setShow={setShowModalFilter}
            
            filterTemplate={filterTemplate}

            inputState={inputState} 
            setInputState={setInputState}
            
            limitRow={limitRow} 
            setLimitRow={setLimitRow}

            isCsv={isCsv}
            setIsCsv={setIsCsv}
            
            isXlsx={isXlsx}
            setIsXlsx={setIsXlsx}

            sort={sort} 
            setSort={setSort}
            
            filterAxios={filterAxiosFunc}
            basicData={basicData}
            
            LB={pageFilterForm}

            />
        </div>
    )
}
//-----------------------------
const renderFilter2=()=>{

    const {limitRow,sort,isCsv,isXlsx,inputState,showModalFilter}=filterData2
    return (
        <div className="" style={{margin:"2rem 0"}}>
            <ModalFilterInput
            show={filterData2.showModalFilter} 
            setShow={setShowModalFilter2}
            
            filterTemplate={filterTemplate2}

            inputState={filterData2.inputState} 
            setInputState={setInputState2}
            
            limitRow={filterData2.limitRow} 
            setLimitRow={setLimitRow2}

            isCsv={filterData2.isCsv}
            setIsCsv={setIsCsv2}

            isXlsx={filterData2.isXlsx}
            setIsXlsx={setIsXlsx2}
            
            sort={filterData2.sort} 
            setSort={setSort2}
            
            filterAxios={filterAxiosFunc2}
            basicData={basicData}
            
            LB={pageFilterForm2}
            defaultShowFilter={false}
            />

            
        </div>
    )


}
//===================
const renderTable2=()=>{
    return(
            <Table
                colorHead={"#006600"}                
                tableTemplate={filterData2.tableTemplate}
                setTableTemplate={setTableTemplate2}//{setTableTemplate}

                filterData={filterData2.data0}
                setFilterData={setFilterDataData02}
                
                editData={filterData2.editData}
                setEditData={setEditDataFunc2}
                saveTableTemplateFunc={saveTableTemplateFunc2}//{saveTableTemplateFunc}
                isSubTable={false}
                updateFilterData={updateFilterDataFunc2}
                useInput={false}
                
            />
    )
}
//==================
const setCloseFunc2=()=>{
    //console.log('setCloseFunc2')
    setFilterData2({...filterData2,data0:null})
}

const setEditDataFunc2=(data)=>{
    //console.log('setEditDataFunc2')
    //console.log(data)


    let tempData=data
    //console.log('setEditData.....')
    //console.log(data)

    axios.post(`/p35product/getlimit`,
    { pageNumber:1,
     limitRow:300,
     sort:{id:1},
     groupId:data.groupId

    },myheader,shouldRender)
    .then(result=>{
            //console.log('eeeee')
            //console.log(result.data.data)
            const tempArray=genFilterDataWithIndex(
                result.data.data,
                null,
                null,
                null
                )

            setFilterData({...filterData,
                editData:tempData,
                
                data3:tempArray
            })
            setFilterData2({...filterData2,
                editData:data
            })
    })
    .catch(error=>{
            setFilterData({...filterData,
                editData:tempData,
               
            })
            setFilterData2({...filterData2,
                editData:data
            })
    })








    /*
    setFilterData2({...filterData2,
        editData:data,
        inputState:{
            ...filterData2.inputState,
            groupId:{...filterData2.inputState.groupId,
                    min:data.groupId.toString(),
                    max:data.groupId.toString(),
                },
            groupName:{...filterData2.inputState.groupName,
                    value:data.groupName,
                },     
            
        },
        showModalFilterSelectEdit:false,
        reloadModalFilterSelectEdit:true,
    })
    */
}

const renderRight2=()=>{

    //console.log('renderTable')
    //console.log(filterData)

    return filterData2.data0
            ?<div style={{height:"100%"}}>
                <div style={{height:"5%",maxHeight:"2rem"}}>
                {
                 renderBadge({
                     filterData:filterData2,
                     setPageNumber:setPageNumber2,
                     setClose:setCloseFunc2,
                     bgColor:bgColor
                 })
                }
                </div>
                <div style={{height:"95%"}}>
                {
                renderTable2()
                }
                </div>
            </div>
            :null
    
}

//-----------------------------
const renderGroup=()=>{

    return (
    <div className="w-100" 
         style={{ooverflowX:"hidden",
                 paddingBottom:"5rem"}}>

        {
        filterData.groupDataUrl&&pageData
        ?<div className="" style={{margin:"1rem 0"}}>
            <NewGroup
                myheader={myheader}
                dataUrl={filterData.groupDataUrl}
                filterByGroupId={filterDataByGroup}
                captureEditGroup={setSelectGroupFunc}
                bgColor={bgColor}
                pageData={pageData}
                basicData={basicData}
                editData={filterData.editData}
            />
        </div>
        :null
        }

        {filterData.showModalFilterSelectEdit&&
            renderFilter()
        }
        

        {
         filterData.showAdd||filterData.showEdit
         ?renderRight({...filterData,
                    badgeState:{...filterData.badgeState,
                        addShow:false,
                        editShow:false,
                        delShow:false,
                        printerShow:false,
                        csvShow:false,
                        forwardShow:false
                    }})
         :null   
        }
        {filterData2.showModalFilterSelectEdit&&
            renderFilter2()
        }
        
        <div style={{width:"100%",height:"50vh"}}>
            {renderTable3()}
        </div> 
        
       
    </div>
    )
}
//------------------------
//-----------------------------
const renderRight=(filterData)=>{
    //console.log('renderRight')
    //console.log(renderRight)

    return(
    <div className="w-100 h-100">
        <div className="hide-on-printX"
        style={{height:filterData.showAdd||filterData.showEdit?"2rem":"5%",width:"100%"}}
        >
            {
                    renderBadge({
                        filterData,
                        setPageNumber:setPageNumber,
                        
                        totalSwapPage:1, 
                        setSwapState:setSwapState,
                        
                        setReloadData:refreshPage,
                        setShowFilter:()=>{},
                        setShowAdd:setShowAdd,
                        setShowEdit:setShowEdit,
                        setShowModalConfirm:setShowModalConfirm,
                        
                        setShowModalCsv:()=>{},


                        setUnSelectAll:setUnSelectAllFunc,
                        setShowForwardConfirm:(value)=>{setFilterData({...filterData,showTransactionConfirm:value})},
                        setClose:()=>{},
                        bgColor:bgColor,
                        captureSelect:()=>{}//captureSelect
                    })        
            }

        </div>

        <div className=""
                style={!filterData.showEdit&&!filterData.showAdd
                        ?{width:"100%",height:"95%"}
                        :{width:"100%"}
                    }//height:"95%"
        >
            <div style={!filterData.showEdit&&!filterData.showAdd
                    ?{width:"100%",height:"100%"}
                    :{width:"100%"}
                }//height:"100%"
                className="show-on-print"
            >
                {   
                    filterData.swapState
                    ?renderTable()
                    :renderGrid(filterData,tableTemplate,setEditData,updateFilterDataFunc)
                }
            </div>
            
        </div>
    </div>
    )
}
//=============================
//==============================
const renderBody=()=>{
return(
    filterData&&
    <div className=""
             style={{display:"flex",height:"95%",width:"100%"}}
    >
            
            <div className="hide-on-printX" 
                 style={{height:"100%",
                         width:`${filterData.widthLeft}%`,
                         overflowY:"scroll",
                         overflowX:"hidden"
                         }}>
                     {
                       renderGroup()
                     }
            </div>
        
            <div className="" 
                style={{height:"100%",width:`${100-filterData.widthLeft}%`}}>
                 {filterData2.data0
                 ?renderRight2()
                 :filterData.showAdd
                    ?renderPageFormAdd()
                    :filterData.showEdit
                      ?renderPageFormEdit()
                      :renderRight(filterData) 
                 }
            </div>
    </div>
)
}
//===============================
//===============================
const renderPageFormAdd=()=>{

    return(
        <PageForm
        isAddForm={true}
        lb={addFormTitle}
        formTemplate={formTemplate}
        stateTemplate={stateTemplate}
        selectData={{basicData:basicData}}
    
        iconAction={[()=>{}]}
        iconActionData={null}
        iconActionDataDetail={null}
        loadData={{id:parseInt(filterData.lastRecordId)+1}}
        keyName={filterData.keyName}
        setShow={setShowAdd}
    
        calculation={null}
        submitFunction={submitFunctionAddFunc}
        selectGroup={filterData.selectGroup}
        setSelectGroup={setSelectGroupFunc}
        selectProduct={filterData.selectProduct}
        setSelectProduct={setSelectProductFunc}
        
        pageDataModalForm={pageDataModalForm}
        dataUrl={filterData.dataUrl}
        myheader={myheader}
        tableTemplateUrl={filterData.tableTemplateUrl}
        tableTemplateName={filterData.detailTableTemplateForFormName}
        detailTableTemplate={filterData.detailTableTemplateForForm}
        calDigit={calDigit}
    />
    )

}


//==================================
const renderPageFormEdit=()=>{

        return(
            <PageForm
                lb={editFormTitle}
                formTemplate={editFormTemplate}
                stateTemplate={stateTemplate}
                selectData={{basicData:basicData}}
            
                iconAction={[()=>{}]}
                iconActionData={null}
                iconActionDataDetail={null}
                loadData={filterData.editData}
                keyName={filterData.keyName}
                setShow={setShowEdit}
            
                calculation={null}
                submitFunction={submitFunctionEditFunc}
                submitFunction2={submitFunctionAddFunc}

                selectGroup={filterData.selectGroup}
                setSelectGroup={setSelectGroupFunc}
                selectProduct={filterData.selectProduct}
                setSelectProduct={setSelectProductFunc}

                pageDataModalForm={pageDataModalForm}
                dataUrl={filterData.dataUrl}
                myheader={myheader}

                tableTemplateUrl={filterData.tableTemplateUrl}
                tableTemplateName={filterData.detailTableTemplateForFormName}
                detailTableTemplate={filterData.detailTableTemplateForForm}
            />  
        )
    
}

//================================
//=================================

//=================================
return(
   <div className="w-100 h-100">
        <button style={{display:"none"}}
            ref={refSecrete}
            onClick={e=>{
                setMainContextFilterDataFunc()
            }}
        >
            secrete
        </button>


        <div className="h-5 w-100"
            
        >
            <Navbar 
                setMainContextFilterData={()=>refSecrete.current.click()}
            />
        </div>











        <div className="w-100 h-95">
            {   
            renderBody()    
            }
        
            <div className="" 
                style={{width:"100%",display:"flex",position:"relative"}}>
                    {
                    renderWidthRangeBar({showRange:filterData.showRange,
                                        setShowRange:setShowRange,
                                        widthLeft:filterData.widthLeft,
                                        setWidthLeft:setWidthLeft,
                                        //heightTop:filterData.heightTop,
                                        //setHeightTop:setHeightTop
                                    })
                    }
        
            </div>


            {filterData.showTransactionConfirm&&
            renderTransactionConfirm({
                editTransaction:filterData.editData,
                submitFunc:transactionConfirmSubmitFunc,
                cancelFunc:transactionConfirmCancelFunc,
                pageData:pageData,
                changeIncludeTransactionHead:()=>{},//changeIncludeTransactionHead,
                includeTransactionHead:filterData.includeTransactionHead
                })
            }
            {
            modalConfirmReloadState.show
            ?<ModalConfirmReload
                setShow={cancelConfirmReload}
                submitFunction={confirmReload}
            />
            :null
            }

            
            {
            filterData.showModalConfirm
            ?<ModalConfirm
                setShow={setShowModalConfirm}
                submitFunction={submitFunctionDeleteFunc}
            />
            :null
            }

            {  
            filterData.showModalError
            ?renderModalError({
                setShow:setShowModalError,
                message:filterData.message
            })
            :null
            }

            {  
            filterData2.showModalError
            ?renderModalError({
                setShow:setShowModalError2,
                message:filterData2.message
            })
            :null
            }
        </div>
    </div>
)

}




export default PageComponentProduct ;




/*




















*/
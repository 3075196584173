import console from './consoleVerify'


const moveEditDown=(array,editData,idKey)=>{

    let targetIdx=null
    const lastIndex=array.length-1

    let keepChecking=true
    array.map((i,idx)=>{
         if(keepChecking){
             if(i[idKey]==editData[idKey]){
                 targetIdx=idx
                 keepChecking=false
             }
         }
    })

    let tempArray=[]

    if((targetIdx!=null)&&(targetIdx<lastIndex)){
        
        array.map((i,idx)=>{
            if(idx==targetIdx){
                tempArray=[...tempArray,array[targetIdx+1]]
            }
            else if(idx==targetIdx+1){
                tempArray=[...tempArray,array[targetIdx]]
            }
            else {
                tempArray=[...tempArray,i]
            }

        })
        return tempArray
    }
    else{
        return array
    }
}


const moveEditUp=(array,editData,idKey)=>{

    let targetIdx=null
    let keepChecking=true

    array.map((i,idx)=>{
        if(keepChecking){
            if(i[idKey]==editData[idKey]){
                targetIdx=idx
                keepChecking=false
             }
        }
    })

    let tempArray=[]

    if((targetIdx!=null)&&(targetIdx>0)){
       
       array.map((i,idx)=>{
          
           if(idx==targetIdx-1){
               tempArray=[...tempArray,array[targetIdx]]
           }
           else if(idx==targetIdx){
               tempArray=[...tempArray,array[targetIdx-1]]
           }
           else {
               tempArray=[...tempArray,i]
           }

       })
       return tempArray
    }
    else{
        return array
    }
}


const deleteEdit=(array,editData,idKey)=>{
    
    if(array.length>1){
        let tempArray=[]
    
        array.map((i,idx)=>{
            if(i[idKey]!=editData[idKey]){
                tempArray=[...tempArray,i]
            }
        })
        return tempArray
    }
    else{
        return array
    }
}


const insertAfterEdit=(array,editData,idKey,blankData)=>{
    let tempArray=[]

    array.map((i,idx)=>{
        if(i[idKey]==editData[idKey]){
            tempArray=[...tempArray,i,blankData]
        }
        else{
            tempArray=[...tempArray,i]
        }
    })

    return tempArray
}

const arrayUtil={
    moveEditUp,
    moveEditDown,
    deleteEdit,
    insertAfterEdit
  }
  
  export default arrayUtil
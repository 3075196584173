import React from 'react';

import {RiLogoutBoxRLine,RiLogoutCircleRLine} from 'react-icons/ri'
import {FaWrench,FaMoneyBill,FaMoneyBillWave,FaRegMoneyBillAlt,FaBarcode,FaWarehouse,
        FaRegCalendarAlt
       } from 'react-icons/fa';
import {MdVpnKey,MdTune,MdOutlineShoppingCart,MdClose,MdFastfood,MdPerson,MdSettingsApplications,
        MdInfoOutline
} from 'react-icons/md';
import {BsShop} from 'react-icons/bs'
import {GoPackage} from 'react-icons/go'
import {SiBookstack,SiAirtable} from 'react-icons/si'
import {Link} from 'react-router-dom';
import MMPOSLogo from './MMPOSLOGO'
import console from '../../util/consoleVerify'

import './Home.css'

function Home() {

const refSetting=React.createRef()
const refPartner=React.createRef()
const refProduct=React.createRef()
const refTransaction=React.createRef()
const refLogIn=React.createRef()
//const refTransaction=React.createRef()
const refBill=React.createRef()
const refBranch=React.createRef()
const refBarcode=React.createRef()
const refTask=React.createRef()
const refInfo=React.createRef()


const boxStyle={fontSize:"5rem"}
const bigDivStyle={gridRow:"1/3",gridColumn:"1/2"}
const mdDivStyle={gridRow:"1/2",gridColumn:"3/5"}

const LBThai={
    home:"หน้าร้าน",
    product:"สินค้า",
    transaction:"ธุรกรรม",
    partner:"คู่ค้า",
    barcode:"บาร์โคด",
    branch:"ตั้งค่าสาขา",
    setting:"ตั้งค่าร้าน",
    login:"การเข้าใช้",
    task:"นัดหมาย",
    info:"ช่วยเหลือ"
}

const LBEnglish={
    home:"POS",
    product:"PRODUCT",
    transaction:"TRANSACTION",
    partner:"PARTNER",
    barcode:"BARCODE",
    branch:"BRANCH",
    setting:"SETTING",
    login:"LOGIN",
    task:"APPOINTMENT",
    info:"SUPPORT"
}
const boxArray=[
{bgColor:"#FAE03C",//"rgb(255,102,0)",
 ref:refBill,
 LB:Math.random() < 0.5?LBThai.home:LBEnglish.home,
 color:"#444",
 icon:<MdOutlineShoppingCart style={{...boxStyle,color:"#444"}}/>
},
/*
{bgColor:"#F7786B",//"rgb(214,0,147)",
 ref:refTransaction,
 LB:Math.random() < 0.5?LBThai.transaction:LBEnglish.transaction,
 color:"white",
 icon:<FaWarehouse style={{...boxStyle,color:"white"}}/>
},
*/
{bgColor:"#B76BA3",//"rgb(214,0,147)",
 ref:refProduct,
 LB:Math.random() < 0.5?LBThai.product:LBEnglish.product,
 color:"white",
 icon:<GoPackage style={{...boxStyle,color:"white"}}/>
},
{bgColor:"rgb(0,204,0)",//"rgb(255,192,0)", 
 ref:refPartner,
 LB:Math.random() < 0.5?LBThai.partner:LBEnglish.partner,
 color:"#444",
 icon:<MdPerson style={{...boxStyle,color:"#444"}}/>
},
{bgColor:"rgb(52, 86, 139)",//"rgb(255,192,0)", 
 ref:refBarcode,
 LB:Math.random() < 0.5?LBThai.barcode:LBEnglish.barcode,
 color:"white",
 icon:<FaBarcode style={{...boxStyle,color:"white"}}/>
},
{bgColor:"#00BFFF", 
 ref:refTask,
 LB:Math.random() < 0.5?LBThai.task:LBEnglish.task,
 color:"white",
 icon:<FaRegCalendarAlt style={{...boxStyle,color:"white"}}/>
},

{bgColor:"#B18F6A", 
 ref:refBranch,
 LB:Math.random() < 0.5?LBThai.branch:LBEnglish.branch,
 color:"#444",
 icon:<MdTune style={{...boxStyle,color:"#444"}}/>
},
{bgColor:"rgb(192,0,0)", 
 ref:refSetting,
 LB:Math.random() < 0.5?LBThai.setting:LBEnglish.setting,
 color:"white",
 icon:<MdSettingsApplications style={{...boxStyle,color:"white"}}/>
},
{bgColor:"#ff9933", 
 ref:refLogIn,
 LB:Math.random() < 0.5?LBThai.login:LBEnglish.login,
 color:"#444",
 icon:<MdVpnKey style={{...boxStyle,color:"#444"}}/>
},

{bgColor:"#448d60", 
 ref:refInfo,
 LB:Math.random() < 0.5?LBThai.info:LBEnglish.info,
 color:"#444",
 icon:<MdInfoOutline style={{...boxStyle,color:"white"}}/>
},


]

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const [target,setTarget]=React.useState(0)
const [target2,setTarget2]=React.useState(0)

React.useEffect(()=>{
    if(shouldRender.current){
        let randomInt=0
        let randomInt2=0
        while (randomInt===target&&shouldRender.current){
            //console.log('target........Home.....')
            randomInt=Math.floor(Math.random() * boxArray.length)
            randomInt2=Math.floor(Math.random() * boxArray.length)
        }
    
        setTimeout(()=>{
            if(shouldRender.current){
                //console.log('setTimeout........Home............')
                setTarget(randomInt)
                setTarget2(randomInt2)
            }
        },2000)
    }
},[target])

return (
    <div className="home-hero">
        <div className="home-bg-screen">
            <div className="triangle1"/>
            <div className="triangle2"/>
            <div className="triangle3"/>
            <div className="triangle4"/>
            <div className="triangle5"/>

            
            <div className="home-box">
                    <div 
                        className="home-grid"
                    >
                      
                        {
                            boxArray.map((i,idx)=>{
                            return false//idx==0
                                ?<div className={"home-tile2" }
                                    key={idx} 
                                    style={{//...bigDivStyle,
                                            backgroundColor:`${i.bgColor}`,
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center",
                                            borderRadius:"5%",
                                            position:"relative",

                                            }}

                                    onClick={e=>{i.ref.current.click()}}
                                    >   
                                        {i.icon}
                                        <div 
                                            style={{position:"absolute",
                                                bottom:"0",
                                                right:"0.5rem",
                                                color:i.color,
                                                fontSize:"1.2rem",
                                                display:target==idx||target2==idx?"":"none"
                                            }}
                                        >
                                            {i.LB}
                                        </div>
                                    </div>
                                :<div className="home-tile" 
                                     key={idx} 
                                     style={{
                                            backgroundColor:`${i.bgColor}`,
                                            display:"flex",
                                             justifyContent:"center",
                                             alignItems:"center",
                                             borderRadius:"5%",
                                             position:"relative",

                                            }}
                                    onClick={e=>{i.ref.current.click()}}
                                >   
                                    {
                                    i.icon
                                    }
                                    <div 
                                        style={{position:"absolute",
                                            bottom:"0",
                                            right:"0.5rem",
                                            color:i.color,
                                            fontSize:"1.2rem",
                                            display:target==idx||target2==idx?"none":""
                                        }}
                                    >
                                        {i.LB}
                                    </div>
                                </div>
                            })
                        }
    
                    </div>
                 
                    <div className="" 
                         style={{width:"100%",
                            marginTop:"0rem",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            transform:"scale(1.4)"
                    }}>
                        <div>
                            <MMPOSLogo/>
                        </div>
                        
                    </div>
            </div>
          
   
        </div>
       
        <div className="d-none">
            <Link ref={refSetting} to="/pos/basicdata"/>
            <Link ref={refPartner} to="/pos/partner"/>
            <Link ref={refProduct} to="/pos/product"/>
            <Link ref={refLogIn} to="/pos/login"/>
            <Link ref={refBill} to="/pos/bill"/>
            <Link ref={refBranch} to="/pos/branch"/>
            <Link ref={refBarcode} to="/pos/barcode"/>
            <Link ref={refTask} to="/pos/task"/>
            <div ref={refInfo} onClick={()=>{
                window.location.href = 'https://www.mmdigitzone.net'
            }}/>
        </div>
        
    </div>
)
}

export default Home;
/*

<Link ref={refTransaction} to="/pos/bill"/>


<div className="home-link"
onClick={e=>{refProduct.current.click()}}>
<div>
   <FaWarehouse className="home-icon"/>
</div>
<div>
   <h3>คลังสินค้า</h3>
</div>
</div>
*/



/*


             <div className="home-link" 
                     onClick={e=>{refUser.current.click()}}>
                    <div >
                        <FaUserEdit className="home-icon"/>
                    </div>
                    <div>
                        <h3>ผู้ใช้</h3>
                    </div>
                </div>
                
                <div className="home-link"
                     onClick={e=>{refSetting.current.click()}}>
                    <div>
                        <MdSettingsApplications className="home-icon"/>
                    </div>
                    <div>
                        <h3>ตั้งค่า</h3>
                    </div>
                </div>

                <div className="home-link"
                     onClick={e=>{refPartner.current.click()}}>
                    <div>
                        <FaUsers className="home-icon"/>
                    </div>
                    <div>
                        <h3>คู่ค้า</h3>
                    </div>
                </div>

                <div className="home-link"
                     onClick={e=>{refProduct.current.click()}}>
                    <div>
                        <FaWarehouse className="home-icon"/>
                    </div>
                    <div>
                        <h3>สินค้า</h3>
                    </div>
                </div>

                <div className="home-link"
                     onClick={e=>{refTransaction.current.click()}}>
                    <div>
                        <MdShoppingCart className="home-icon"/>
                    </div>
                    <div>
                        <h4>ธุรกรรม</h4>
                    </div>
                </div>

                <div className="home-link"
                     onClick={e=>{refTransactionLog.current.click()}}>
                    <div>
                        <FaChartLine className="home-icon"/>
                    </div>
                    <div>
                        <h4>บันทึก</h4>
                    </div>
                </div>






*/
import React from 'react';
import {Link} from 'react-router-dom';
import {FaPlusCircle,FaRegArrowAltCircleDown,FaRegArrowAltCircleUp,
    FaBan,FaCheck,FaRegCalendarAlt,FaMinusCircle,FaEdit
   } from 'react-icons/fa';

import {MdOutlineTimerOff,MdOutlineTimer
   } from 'react-icons/md';
   


import {MainContext} from '../../context/MainContext';

import '../../component/navbar/Navbar.css';
import uuid from 'react-uuid';

function DateSetting(props) {

const {pageData,confirmFunc2,setShow,basicData}=props

//console.log('BasicData..........')
//console.log(basicData)

const { basicDataSt,
        setReloadBasicData,
        myheader,
        setBasicData,
        tokenSt,
        setReloadCheckToken
        //widthLeft,setWidthLeft
}=React.useContext(MainContext)

//const {haveUserToken,haveShopToken,userName}=tokenSt  
//const {basicData,pageData,tableTemplate,languageObj,user,shop,allBranch,languageArray}=basicDataSt
//----------------------------------------
const tempWeekDay=[
    {day:0,weekDay:"Sun/อาทิตย์",active:false,startHour:null,startMinute:null,endHour:null,endMinute:null},
    {day:1,weekDay:"Mon/จันทร์",active:false,startHour:null,startMinute:null,endHour:null,endMinute:null},
    {day:2,weekDay:"Tue/อังคาร",active:false,startHour:null,startMinute:null,endHour:null,endMinute:null},
    {day:3,weekDay:"Wed/พุธ",active:false,startHour:null,startMinute:null,endHour:null,endMinute:null},
    {day:4,weekDay:"Thu/พฤหัส",active:false,startHour:null,startMinute:null,endHour:null,endMinute:null},
    {day:5,weekDay:"Fri/ศุกร์",active:false,startHour:null,startMinute:null,endHour:null,endMinute:null},
    {day:6,weekDay:"Sat/เสาร์",active:false,startHour:null,startMinute:null,endHour:null,endMinute:null},
]


const temp24=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
const temp59=[0,5,10,15,20,25,30,35,40,45,50,55]
const tempMonth=[1,2,3,4,5,6,7,8,9,10,11,12]

const dayInMonth=[
    {month:1,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
    {month:2,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29]},
    {month:3,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
    {month:4,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]},

    {month:5,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
    {month:6,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]},
    {month:7,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
    {month:8,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},

    {month:9,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]},
    {month:10,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]},
    {month:11,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]},
    {month:12,date:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]}
]
    



const genWeekDay=()=>{
    let tempArray=[]

    tempWeekDay.map(wd=>{
        let tempObj=wd
        basicData.activeDay.map(ad=>{
            if(wd.day==ad.day){
                tempObj={...wd,...ad,active:true}
            }
        })

        tempArray=[...tempArray,tempObj]
    })

    return tempArray
}

const genHoliday=()=>{
    let tempArray=[]
    if(basicData.holiday){
        tempArray=basicData.holiday
    }
    return tempArray
}


const [weekDay,setWeekDay]=React.useState(genWeekDay())

const [editData,setEditData]=React.useState({date:null,month:null})
const [holiday,setHoliday]=React.useState(genHoliday())


const renderFooter=()=>{
    return(
    <div  className=""
          style={{display:"flex",
                 position:"fixed",
                 bottom:"5rem",right:"4rem",zIndex:"100"}}
    >
        <div>
            <button
                onClick={e=>{
                   
                      if(confirmFunc2){
                        confirmFunc2(weekDay,holiday)
                      }

                }}
            >
                <FaCheck/>
            </button>
        </div>
        
        <div>
            <button
                 onClick={e=>{
                    setShow(false)
                 }}
            >
                <FaBan/>
            </button>
        </div>
    </div>
    )
 }
//====================================
const changeWeekDay=(day)=>{
    //console.log(weekDay)
    let tempArray=[]

    weekDay.map(i=>{
        if(i.day==day){

            if(i.active==false){
                //console.log('1')
                tempArray=[...tempArray,
                    {...i,active:!i.active,startHour:8,startMinute:0,endHour:17,endMinute:0}
                ]
            }
            else{
                //console.log('2')
                tempArray=[...tempArray,{...i,active:!i.active}]
            }
            
        }
        else{
            //console.log('3')
            tempArray=[...tempArray,i]
        }
    })

    setWeekDay(tempArray)
}
//-------------------------------------
const changeInputState=(k,myKey,e)=>{
    
    let tempArray=[]
    
    weekDay.map(i=>{
        if(i.day==k.day){
            tempArray=[...tempArray,{...k,[myKey]:e.target.value}]
        }
        else{
            tempArray=[...tempArray,i]
        }

    })

    setWeekDay(tempArray)
    
}

const changeHolidayInputState=(key,value)=>{
    let tempArray=[]

    //console.log(key)
    //console.log(value)
    holiday.map(j=>{
        if(j._id==editData._id){
            console.log('editData_id')
            tempArray=[...tempArray,{...j,[key]:value}]
        }
        else {
            tempArray=[...tempArray,j]
        }
    })

    setHoliday(tempArray)
}

const addHolidayAtEnd=(k)=>{
    const tempObj={_id:uuid(),date:1,month:1}
    let tempArray=[...holiday,tempObj]
    
    setHoliday(tempArray)
}



const addHoliday=(k)=>{
    let tempArray=[]
    
    holiday.map(j=>{
        if(j._id==k._id){
            const tempObj={_id:uuid(),date:1,month:1}
            tempArray=[...tempArray,j,tempObj]
        }
        else {
            tempArray=[...tempArray,j]
        }
    })
    setHoliday(tempArray)
}

const deleteHoliday=(k)=>{
    let tempArray=[]
    
    holiday.map(j=>{
        if(j._id==k._id){

        }
        else {
            tempArray=[...tempArray,j]
        }
    })

    setHoliday(tempArray)
}

//------------------------------------
const renderSelection=(myArray,myKey,k,dft)=>{   
   
    return(
        <select style={{width:"23%"}}
                value={k[myKey]}
                onChange={e=>{
                    //if(!unchangeable){
                    changeInputState(k,myKey,e)
                    //}
                }}
        
        >
            <option>{dft}</option>
            {
              myArray.map((m,idxM)=>{
                return(
                    <option key={idxM}
                        //selected={value==m?"selected":""}
                    >{m}</option>
                )
              })
            }
        </select>
    )
}


//=====================================
const renderActiveDay=()=>{
    //console.log(weekDay)
    return weekDay.map((k,idxK)=>{
        return (
            <div key={idxK} className=""
                    style={{display:"flex",alignItems:"center",
                    width:"100%",margin:"1rem 0"}}
            >
                <div style={{width:"18%"}}>
                    {k.weekDay}
                </div>
                {
                 k.active   
                 ?<div>
                    <MdOutlineTimer className='md-icon' 
                        onClick={e=>{
                            changeWeekDay(k.day)
                        }}
                    />
                 </div>
                 :<div>
                    <MdOutlineTimerOff className='md-icon'
                        onClick={e=>{
                            changeWeekDay(k.day)
                        }}
                    />
                 </div>
                }
                 {
                 k.active   
                 ?<div style={{display:"flex",width:"75%"}}>
                    {renderSelection(temp24,"startHour",k,"..hr/ชม..")}
                    {renderSelection(temp59,"startMinute",k,"..min/นาที..")}
                    <div>{`>>`}</div>
                    {renderSelection(temp24,"endHour",k,"..hr/ชม..")}
                    {renderSelection(temp59,"endMinute",k,"..min/นาที..")}
                   
                 </div>
                 :null
                }
            </div>
        )
    })
 }

 const renderSelectDate=(k)=>{
    let tempArray=[]
    dayInMonth.map(g=>{
        if(g.month==k.month){
            tempArray=g.date
        }
    })


    return(
        <select style={{width:"15%",textAlign:"center"}}
                onChange={e=>{
                    changeHolidayInputState("date",e.target.value)
                }}
        >
            <option>..date/วันที่..</option>
            {
              tempArray.map((u,idxU)=>{
                return (
                    <option
                        key={idxU}
                        selected={u==editData.date?"selected":""}
                        value={u}
                    >{u}</option>
                )
              })
            }
        </select>
    )
 }

 const renderSelectMonth=(k)=>{
    return(
        <select style={{width:"15%",textAlign:"center"}}
            onChange={e=>{
                changeHolidayInputState("month",e.target.value)
            }}

        >
            <option>..month/เดือน..</option>

            {
              tempMonth.map((u,idxU)=>{
                return (
                    <option
                        key={idxU}
                        selected={u==editData.month?"selected":""}
                        value={u}
                    >{u}</option>
                )
              })
            }
        </select>
    )
 }

 const renderHoliday=()=>{
    return holiday.map((k,idxK)=>{
        return (
            <div key={idxK}
                 style={{display:"flex",justifyContent:"flex-start",alignItems:"center",margin:"1rem 0"}}>
                <div style={{width:"8%",margin:"0 0.3rem"}}>
                    <FaPlusCircle className='md-icon'
                        onClick={e=>{
                            addHoliday(k)
                        }}
                    />
                </div>
                <div style={{width:"8%",margin:"0 0.3rem"}}>
                    <FaMinusCircle className='md-icon'
                          onClick={e=>{
                            deleteHoliday(k)
                         }}
                    />
                </div>
               
                {editData._id==k._id
                ?renderSelectDate(k)
                :<div style={{width:"15%",textAlign:"center"}}
                    onClick={e=>{
                        setEditData(k)
                    }}
                >
                    {k.date}
                </div>
                }
                <div style={{margin:"0 1rem"}}>/</div>
                {editData._id==k._id
                ?renderSelectMonth(k)
                :<div style={{width:"15%",textAlign:"center"}}
                    onClick={e=>{
                        setEditData(k)
                    }}
                >
                    {k.month}
                </div>
                }
            </div>
        )
    })
 }

//----------------------------------------
return ( 
<div className="Modal-background">
        <div className="Modal-box" 
             style={{paddingBottom:"1rem",minWidth:"70%",minHeight:"100px"}}>
            
            <div className="Modal-header">
                <h4>{pageData.dateSetting.formHead}</h4>
            </div>
           
            <div className="Modal-header" style={{justifyContent:"flex-start"}}>
                <h5>
                {pageData.dateSetting.activeDay}
                </h5>
            </div>   
            {basicData.activeDay
            ?renderActiveDay()
            :null
            }
            
            <div className="Modal-header" 
                 style={{display:"flex",justifyContent:"flex-start"}}>
                <h5>
                {pageData.dateSetting.holiday}
                </h5>
                <div style={{marginLeft:"1rem"}}>
                    <FaPlusCircle className='md-icon'
                        onClick={e=>{
                            addHolidayAtEnd()
                        }}
                    />
                </div>
            </div>   
            {
             basicData.holiday
             ?renderHoliday()
             :null   
            }


            {renderFooter()}

        </div>

</div>
)

}


DateSetting.defaultProps={
    confirmFunc2:null,
    setShow:()=>{},
    submitFunction:null,   

}



export default DateSetting;
import React from 'react';
import {FaCheck,FaBan} from 'react-icons/fa';
import console from '../../util/consoleVerify'


function ModalAddConfirm({
    setShow,
    submitFunction,
    basicData,
    setEditData,
    lastRecordId
    }){


    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 

    //const {id,parentId,allDeleteId,routeAuth,detail}=editData


const [filterData,setFilterData]=React.useState(basicData.stickerPage)

const [tempEditData,setTempEditData]=React.useState(basicData.stickerPage[0])


//===============================
//===============================
    return(

    <div className="Modal-background">
        <div className="Modal-box">
            <div className="Modal-header">
                {/*
                <div>
                    Test
                </div>
                */}
            </div>

            <div className='w-100'>
                <select
                    onChange={e=>{
                        //console.log(e.target.value)
                        
                        filterData.map(l=>{

                            if(l.id==e.target.value){
                                /*
                                
                                */
                                setTempEditData(l)
                            }
                        })
                    }}
                >
                    {
                    filterData.map((k,idxK)=>{
                        return(
                            <option key={idxK} value={k.id}>
                                {k.recordName}
                            </option>
                        )
                    })
                    }
                </select>
            </div>
         

            <div className="Modal-footer"
                 style={{marginTop:"1rem"}}
            >
                <div>
                    <button
                        ref={refModalConfirm1}
                        onClick={e=>{
                            
                            let tempLastRecordId
                            
                            if(lastRecordId){
                                tempLastRecordId=parseInt(lastRecordId)+1
                            }
                            else{
                                tempLastRecordId=Date.now()
                            }

                            let temp={
                                ...tempEditData,
                                date:new Date().toISOString(),
                                recordBy:"",
                                id:tempLastRecordId,
                                active:true,
                                productData:[]
                            }

                            submitFunction(temp)
                        }}
                        onKeyDown={e=>{
                            if(e.key=="ArrowRight"){
                                refModalConfirm2.current.focus()
                            }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        ref={refModalConfirm2}
                        onKeyDown={e=>{
                            if(e.key=="ArrowLeft"){
                                refModalConfirm1.current.focus()
                            }
                        }}
                        onClick={e=>{
                            if(setShow){
                                setShow(false)
                            }
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
            
        </div>
    </div>
    )
}

ModalAddConfirm.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}


export default ModalAddConfirm

let toDateMin=new Date(new Date().setHours(0,0,0)).toISOString()

let toDateMax=new Date(new Date().setHours(24,0,0)).toISOString()

const productInputState=
{    
    active:{toCheck:true,value:true},///

    id:     {toCheck:false,min:0,max:0},
    barcode:{toCheck:false,value:""},
    sn:{toCheck:false,value:""},

    productName:   {toCheck:false,value:""},///
    groupId :{toCheck:false,min:0,max:0},
    groupName:{toCheck:false,value:""},

    //groupId:{toCheck:false,min:0,max:0},
    //groupName:{toCheck:false,value:""},
    //unit:   {toCheck:false,value:""},
    isRawMat:{toCheck:false,value:false},///

    price:  {toCheck:false,min:0,max:0},///
    stocking_stock:{toCheck:false,min:0,max:0},///
    stocking_storage:   {toCheck:false,value:""},
    //order:{toCheck:false,min:0,max:0},
    //plan:{toCheck:false,min:0,max:0},
    remark: {toCheck:false,value:""},///
    updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},//2

}


const groupInputState={    
    id:     {toCheck:false,min:0,max:0},
    level:     {toCheck:false,min:0,max:0},
    groupName:   {toCheck:false,value:""}
}

const partnerInputState={
    id:{toCheck:false,min:0,max:0},
    taxId:{toCheck:false,value:""},
    isSupplier:{toCheck:false,value:true},
    isCustomer:{toCheck:false,value:true},
    title:{toCheck:false,value:""},
    name:{toCheck:false,value:""},
    phone:{toCheck:false,value:""},
    active:{toCheck:true,value:true},
    remainingCustomerPoint:{toCheck:false,min:0,max:0},
    totalSpending:{toCheck:false,min:0,max:0},
    //partnerType:{toCheck:false,value:""},
    remark:{toCheck:false,value:""},
    updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},//2

    //address_number:{toCheck:false,value:""},
    //address_tambon:{toCheck:false,value:""},
    //address_district:{toCheck:false,value:""},
    //address_province:{toCheck:false,value:""},
    //address_postcode:{toCheck:false,value:""},
  }

  const customerInputState={
    id:{toCheck:false,min:0,max:0},
    taxId:{toCheck:false,value:""},
    //isSupplier:{toCheck:false,value:false},
    //isCustomer:{toCheck:false,value:false},
    title:{toCheck:false,value:""},
    name:{toCheck:false,value:""},
    phone:{toCheck:false,value:""},
    active:{toCheck:true,value:true},
    remainingCustomerPoint:{toCheck:false,min:0,max:0},
    totalSpending:{toCheck:false,min:0,max:0},
    //partnerType:{toCheck:false,value:""},
    remark:{toCheck:false,value:""},
    //address_number:{toCheck:false,value:""},
    //address_tambon:{toCheck:false,value:""},
    //address_district:{toCheck:false,value:""},
    //address_province:{toCheck:false,value:""},
    //address_postcode:{toCheck:false,value:""},
  }

const transactionInputState={
  active:{toCheck:true,value:true},//0

  id:{toCheck:false,min:0,max:0},//1
  date:{toCheck:false,min:toDateMin,max:toDateMax},//2
  remindDate:{toCheck:false,min:toDateMin,max:toDateMax},//3
  
  //transactionType:{toCheck:false,value:""},//4
  transactionType:{toCheck:false,value:[]},//4

  transactionStatus:{toCheck:false,value:"open"},//5

  partnerId:{toCheck:false,min:0,max:0},//6
  title:{toCheck:false,value:""},//7

  name:{toCheck:false,value:""},//8
  phone:{toCheck:false,value:""},//9
  queueStatus:{toCheck:false,value:"open"},//10
  pic:{toCheck:false,value:""},//11


  remark:{toCheck:false,value:""},//12
  userId:{toCheck:false,min:0,max:0},//13
  paymentType:{toCheck:false,value:""},//14
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},//15

  spareBoolean1:{toCheck:false,value:true},//16
  grandTotal:{toCheck:false,min:0,max:0},//17
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},//2

  //remindDate:{toCheck:false,min:toDate,max:toDate},
  //branch:{toCheck:false,value:""},///
  //transactionName:{toCheck:false,value:""},


  //transactionCode:{toCheck:false,value:""},
  //transactionGroupType:{toCheck:false,value:""},
 
  //reference:{toCheck:false,value:""},

  //partnerType:{toCheck:false,value:""},

  //title:{toCheck:false,value:""},



  /*
  address_number:{toCheck:false,value:""},
  address_tambon:{toCheck:false,value:""},
  address_district:{toCheck:false,value:""},
  address_province:{toCheck:false,value:""},
  address_postcode:{toCheck:false,value:""},

  total:{toCheck:false,min:0,max:0},
  reduction:{toCheck:false,min:0,max:0},
  */
  /*
  detail_id:     {toCheck:false,min:0,max:0},
  detail_barcode:{toCheck:false,value:""},
  detail_productName:   {toCheck:false,value:""},
  */
  /*
  detail_groupId:{toCheck:false,min:0,max:0},
  */
 /*
  detail_groupName:{toCheck:false,value:""},
  

  detail_unit:   {toCheck:false,value:""},
  detail_price:  {toCheck:false,min:0,max:0},

  
  detail_quantity: {toCheck:false,min:0,max:0},
  detail_result: {toCheck:false,min:0,max:0},
  detail_remark: {toCheck:false,value:""},
  detail_isRawMat:   {toCheck:false,value:false},

  detail_partnerId:{toCheck:false,min:0,max:0},
  detail_name:   {toCheck:false,value:""}
  */
}

const stickerInputState={
  active:{toCheck:true,value:true},
  id:{toCheck:false,min:0,max:0},
  recordName:{toCheck:false,value:""},
  date:{toCheck:false,
        min:toDateMin,
        max:toDateMax,
      },
  recordBy:{toCheck:false,value:""},
  
  //productData_code:{toCheck:false,value:""},
  //productData_name:   {toCheck:false,value:""},

}

const transactionBranchInputState={
  active:transactionInputState.active,//0
  branchId:{toCheck:false,min:0,max:0},//

  id:transactionInputState.id, //1
  date:transactionInputState.date, //2
  remindDate:transactionInputState.remindDate,//3

  transactionType:transactionInputState.transactionType,//4
  transactionStatus:transactionInputState.transactionStatus,//5
  
  partnerId:transactionInputState.partnerId,//6
  title:transactionInputState.title,//7

  name:transactionInputState.name,//7
  phone:transactionInputState.phone,//8
  queueStatus:transactionInputState.queueStatus,//9
  pic:transactionInputState.pic,//10


  remark:transactionInputState.remark,//11
  userId:{toCheck:false,min:0,max:0},//12
  paymentType:{toCheck:false,value:""},//13
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},//14

  spareBoolean1:transactionInputState.spareBoolean1,//15
  grandTotal:transactionInputState.grandTotal,//16
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},//2

}

const billMenuInputState={
  active:{toCheck:true,value:true},
  id:{toCheck:false,min:0,max:0},
  date:{toCheck:false,min:toDateMin,max:toDateMax},
  remindDate:{toCheck:false,min:toDateMin,max:toDateMax},

  transactionType:{toCheck:false,value:[]},
  
  transactionStatus:transactionInputState.transactionStatus,
  partnerId:transactionInputState.partnerId,
  title:{toCheck:false,value:""},//7

  name:transactionInputState.name,
  phone:transactionInputState.phone,
  queueStatus:transactionInputState.queueStatus,

  productId:{toCheck:false,value:""},
  barcode:{toCheck:false,value:""},
  sn:{toCheck:false,value:""},
  productName:{toCheck:false,value:""},
  groupName:{toCheck:false,value:""},
  grandTotal:{toCheck:false,min:0,max:0},//16
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},//2

}

const billBranchMenuInputState={

  active:{toCheck:true,value:true},
  branchId:{toCheck:false,min:0,max:0},
  id:{toCheck:false,min:0,max:0},
  date:{toCheck:false,min:toDateMin,max:toDateMax},
  remindDate:{toCheck:false,min:toDateMin,max:toDateMax},

  transactionType:{toCheck:false,value:[]},
  transactionStatus:transactionInputState.transactionStatus,

  partnerId:transactionInputState.partnerId,
  title:{toCheck:false,value:""},//7

  name:transactionInputState.name,
  phone:transactionInputState.phone,
  queueStatus:transactionInputState.queueStatus,

  productId:{toCheck:false,value:""},
  barcode:{toCheck:false,value:""},
  sn:{toCheck:false,value:""},
  productName:{toCheck:false,value:""},
  groupName:{toCheck:false,value:""},
  grandTotal:{toCheck:false,min:0,max:0},//16
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},//2

}

const shopInputState={
  active:{toCheck:true,value:true},
  shopName:{toCheck:false,value:""},
  ownerName:{toCheck:false,value:""},
  ownerEmail:{toCheck:false,value:""},
  expirationDate:{toCheck:false,min:toDateMin,max:toDateMax},///
  tempExpirationDate:{toCheck:false,min:toDateMin,max:toDateMax},///
  monthlyFee:{toCheck:false,min:0,max:0},
  remainingBalance:{toCheck:false,min:0,max:0},
  dateIn:{toCheck:false,min:toDateMin,max:toDateMax},

}

const userInputState={
  active:{toCheck:true,value:true},
  username:{toCheck:false,value:""},
  userLevel:{toCheck:false,value:""},
  shopId:{toCheck:false,value:""},
  branchId:{toCheck:false,min:0,max:0},
  name:{toCheck:false,value:""},
  surname:{toCheck:false,value:""},
  dateIn:{toCheck:false,min:toDateMin,max:toDateMax},
 
}

const shopPaymentInputState={
  active:{toCheck:true,value:true},
  shopId:{toCheck:false,value:""},
  isCheck:{toCheck:false,value:false},
  hasProblem:{toCheck:false,value:false},
  transferDate:{toCheck:false,min:toDateMin,max:toDateMax},
  accountName:{toCheck:false,value:""},
  accountNumber:{toCheck:false,value:""},
  transferAmount:{toCheck:false,min:0,max:0},
  fromBank:{toCheck:false,value:""},
  checkBy:{toCheck:false,value:""},

  ownerName:{toCheck:false,value:""},
  ownerEmail:{toCheck:false,value:""},
  expirationDate:{toCheck:false,min:toDateMin,max:toDateMax},
  tempExpirationDate:{toCheck:false,min:toDateMin,max:toDateMax},
  
  monthlyFee:{toCheck:false,min:0,max:0},
  remainingBalance:{toCheck:false,min:0,max:0},
}

const basicDataForAdminInputState={
  id:{toCheck:false,min:0,max:0},
  shopId:{toCheck:false,value:""},
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},
}

const branchForAdminInputState={
  id:{toCheck:false,min:0,max:0},
  shopId:{toCheck:false,value:""},
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},
  branchId:{toCheck:false,min:0,max:0}
}

const groupForAdminInputState={
  id:{toCheck:false,min:0,max:0},
  shopId:{toCheck:false,value:""},
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},
}

const productForAdminInputState={
  id:{toCheck:false,min:0,max:0},
  shopId:{toCheck:false,value:""},
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},
}

const partnerForAdminInputState={
  id:{toCheck:false,min:0,max:0},
  shopId:{toCheck:false,value:""},
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},
}

const stickerForAdminInputState={
  id:{toCheck:false,min:0,max:0},
  shopId:{toCheck:false,value:""},
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},
}

const transactionForAdminInputState={
  id:{toCheck:false,min:0,max:0},
  shopId:{toCheck:false,value:""},
  updatedAt:{toCheck:false,min:toDateMin,max:toDateMax},
}



const taskInputState={
  active:{toCheck:true,value:true},
  id:{toCheck:false,min:0,max:0},
  taskName:{toCheck:false,value:""},

  partnerId:transactionInputState.partnerId,
  title:{toCheck:false,value:""},//7

  name:{toCheck:false,value:""},
  phone:{toCheck:false,value:""},

  date:{toCheck:false,
        min:toDateMin,
        max:toDateMax,
      },
  remindDate:{toCheck:false,
        min:toDateMin,
        max:toDateMax,
      },
  startDate:{toCheck:false,
        min:toDateMin,
        max:toDateMax,
      },
  endDate:{toCheck:false,
        min:toDateMin,
        max:toDateMax,
      },
  remark:{toCheck:false,value:""},//13

}



const inputState = {
    groupForAdminInputState,
    productForAdminInputState,
    partnerForAdminInputState,
    stickerForAdminInputState,
    transactionForAdminInputState,

    productInputState,
    groupInputState,
    partnerInputState,
    customerInputState,
    transactionInputState,
    transactionBranchInputState,
    stickerInputState,
    billMenuInputState,
    billBranchMenuInputState,

    shopInputState,
    userInputState,
    shopPaymentInputState,

    basicDataForAdminInputState,
    branchForAdminInputState,

    taskInputState
}

export default inputState

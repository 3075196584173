import React from 'react';
import axios from '../../util/axiosVerify';

import pageUtil from '../../component/pageComponent/pageUtil'

import Table from '../../component/table/Table'
import axiosUtil from '../../util/axiosUtil';
import ModalFilterInput from '../../render/renderFilter/ModalFilterInput';
//import renderTable from '../../component/table/renderTable';
import renderModalError from '../../render/renderModalError'
import renderBadgeForBill from './renderBadgeForBill'
import console from '../../util/consoleVerify'
import renderGrid from '../../render/renderForm/renderGrid'

const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,
    catchErrorToMessage}=axiosUtil

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete,genCsv,genXlsx
} = pageUtil
//==================================
function BillMenuComponent(props) {

const {pageData,basicDataSt,myheader,
  
    billMenuSort,
    billMenuInputState,
    billBranchMenuInputState,
  
    billMenuComponentFilterData,
    setBillMenuComponentFilterData,
    billMenuComponentFilterData2,
    setBillMenuComponentFilterData2,

    reloadBill,setReloadBill,
    transactionType,
    captureBillMenu
}=props

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.................Bill................')
        shouldRender.current=false
    } 
},[])

const {basicData}=basicDataSt

const defaultFilterData={
    reloadData:false,
    pageNumber:1,
    limitRow:basicData.limitRow,
    sort:billMenuSort,
    dataUrl:'p35transaction',
    count:null,
    inputState:billMenuInputState,
    //inputState2:filterDataForBill.billBranchMenuInputState,
    qry:null,
    message:"",
    showModalError:false,
    showModalConfirm:false,
    isCsv:false,
    isXlsx:false,
    transactionData:null,
    transactionArray:null,
    tableTemplate:basicDataSt.tableTemplate.billMenuTableTemplate,
    editData:null,
    message:null,
    showModalFilter:true,

    showAllBranch:false,
    showTable:basicData.showTable
}

const defaultFilterData2={
    reloadData:false,
    pageNumber:1,
    limitRow:basicData.limitRow,
    sort:billMenuSort,
    dataUrl:'p35transaction',
    count:null,
    inputState:billBranchMenuInputState,
    qry:null,
    message:"",
    showModalError:false,
    showModalConfirm:false,
    isCsv:false,
    isXlsx:false,
    transactionData:null,
    transactionArray:null,
    tableTemplate:basicDataSt.tableTemplate.billMenuTableTemplate,
    editData:null,
    message:null,
    showModalFilter:false,

    showAllBranch:false,
    showTable:basicData.showTable
}

const genFilterData=()=>{
    if(billMenuComponentFilterData){
        return billMenuComponentFilterData
    }
    else {
        return defaultFilterData
    }
}

const genFilterData2=()=>{
    if(billMenuComponentFilterData2){
        return billMenuComponentFilterData2
    }
    else {
        return defaultFilterData2
    }
}


const [filterData,setFilterData]=React.useState(genFilterData())
const [filterData2,setFilterData2]=React.useState(genFilterData2())


React.useEffect(()=>{
    if(reloadBill){
        setReloadBill(false)
        setFilterData({...filterData,reloadData:true})
        setFilterData2({...filterData2,reloadData:true})
    }
},[reloadBill])


const setFilterDataFunc=(data)=>{
    setFilterData(data)
    setBillMenuComponentFilterData(data)
}

const setFilterDataFunc2=(data)=>{
    setFilterData2(data)
    setBillMenuComponentFilterData2(data)
}


const mergeProductForFilterData=(filterData,setFilterData)=>{
    //console.log(array)    
    //console.log(filterData.transactionData)

    let tempArray=[]
    filterData.transactionData.map(i=>{
        tempArray=[...tempArray,i.productId]
    })

    let unqTempArray=[...new Set(tempArray)]

    let finalArray=[]

    unqTempArray.map(i=>{
        let totalQty=0
        let totalResult=0
        let totalResultPoint=0
        let tempObj={}

        filterData.transactionData.map(k=>{
            if(i==k.productId){
                totalQty=totalQty+k.quantity
                totalResult=totalResult+k.result
                totalResultPoint=totalResultPoint+k.resultPoint

                tempObj={...k,
                quantity:totalQty,
                result:totalResult,
                resultPoint:totalResultPoint
                }
            }
        })
        finalArray=[...finalArray,tempObj]
    })
    //return tempArray

    setFilterData({...filterData,transactionData:finalArray})
}

const mergeProductForFilterData2=(filterData2,setFilterData2)=>{

    //console.log(filterData2.transactionData)

    let tempArray=[]
    filterData2.transactionData.map(i=>{
        tempArray=[...tempArray,i.productId]
    })

    let unqTempArray=[...new Set(tempArray)]

    let finalArray=[]

    unqTempArray.map(i=>{
        let totalQty=0
        let totalResult=0
        let totalResultPoint=0
        let tempObj={}


        filterData2.transactionData.map(k=>{
            if(i==k.productId){
                totalQty=totalQty+k.quantity
                totalResult=totalResult+k.result
                totalResultPoint=totalResultPoint+k.resultPoint
                tempObj={...k,
                quantity:totalQty,
                result:totalResult,
                resultPoint:totalResultPoint
                }
            }
        })
        finalArray=[...finalArray,tempObj]
    })
    //return tempArray
    setFilterData2({...filterData2,transactionData:finalArray})

}


React.useEffect(()=>{
    //console.log('filterData ==inputState======')
    if(filterData.reloadData){

        const {dataUrl,pageNumber,limitRow,sort,qry,showAllBranch}=filterData
   
                axios.post(`/${dataUrl}/getlimit`,
                        {pageNumber,limitRow,sort,...qry},
                        myheader)
                .then(result=>{
                    //console.log("result.....getlimit.....React.useEffect.")
                    //console.log(result)
                    //console.log(result.data)
                    let temp=genTransactionData(result.data.data)
                    temp=genTransactionData2(temp,filterData.inputState,filterData.qry)

                    const tempResult={...filterData,
                        //data0:temp2,
                        transactionArray:result.data.data,
                        transactionData:temp,
                        count:result.data.count,
                        lastRecordId:result.data.lastRecordId,
                        reloadData:false
                    }

                    if(filterData.isCsv){
                        //const tempObj={data0:temp,
                        //    tableTemplate:filterData.tableTemplate}
                        //genCsv(tempObj)
                       
                        setFilterDataFunc({
                            ...tempResult,
                            isCsv:false,
                            showAllBranch:false
                        })
                    }
                    else if(filterData.isXlsx){
                        //const tempObj={data0:temp,
                        //    tableTemplate:filterData.tableTemplate}
                        //genXlsx(tempObj)
                       
                        setFilterDataFunc({
                            ...tempResult,
                            isXlsx:false,
                            showAllBranch:false
                        })
                    }
                    else{
                        setFilterDataFunc({...tempResult,
                            showAllBranch:false
                        })
                    }
                   
                })
                .catch(error=>{
                    //console.log('erooror.....React.useEffect.....1')
                    //console.log(error)

                    const tempError={...filterData,
                        reloadData:false,
                        message:catchErrorToMessage(error),
                        showModalConfirm:false,
                        showModalError:true,
                    }
                    //console.log(tempError.message)
                    setFilterDataFunc(tempError)
                })
    }
    
    if(filterData.isCsv){
            const tempObj={data0:filterData.transactionData,
                           tableTemplate:filterData.tableTemplate}
            genCsv(tempObj)
            setFilterData({...filterData,isCsv:false})
    }

    if(filterData.isXlsx){
            const tempObj={data0:filterData.transactionData,
                           tableTemplate:filterData.tableTemplate}
            genXlsx(tempObj)
            setFilterData({...filterData,isXlsx:false})
    }

},[filterData])

React.useEffect(()=>{
    //console.log('filterData ==inputState======')
    if(filterData2.reloadData){

        const {dataUrl,pageNumber,limitRow,sort,qry,showAllBranch}=filterData2
   
             //console.log('2..................')
                axios.post(`/${dataUrl}/getbranchtransactionlimit`,
                        {pageNumber,limitRow,sort,...qry},
                        myheader)
                .then(result=>{
                    //console.log("result...React.useEffect......getbranchtransactionlimit..")
                    //console.log(result)
                    //console.log(result.data)
                    let temp=genTransactionData(result.data.data)
                    temp=genTransactionData2(temp,filterData2.inputState,filterData2.qry)

                    const tempResult={...filterData2,
                        //data0:temp2,
                        transactionArray:result.data.data,
                        transactionData:temp,
                        count:result.data.count,
                        lastRecordId:result.data.lastRecordId,
                        reloadData:false
                    }

                    if(filterData2.isCsv){
                        //const tempObj={data0:temp,
                        //    tableTemplate:filterData2.tableTemplate}
                        //genCsv(tempObj)
                       
                        setFilterDataFunc2({
                            ...tempResult,
                            isCsv:false,
                            showAllBranch:true
                        })
                    }
                    else if(filterData2.isXlsx){
                        //const tempObj={data0:temp,
                        //    tableTemplate:filterData2.tableTemplate}
                        //genXlsx(tempObj)
                       
                        setFilterDataFunc2({
                            ...tempResult,
                            isXlsx:false,
                            showAllBranch:true
                        })
                    }
                    else{
                        setFilterDataFunc2({...tempResult,
                            showAllBranch:true
                        })
                    }
                   
                })
                .catch(error=>{
                    //console.log('erooror......React.useEffect....1')
                    //console.log(error)

                    const tempError={...filterData2,
                        reloadData:false,
                        message:catchErrorToMessage(error),
                        showModalConfirm:false,
                        showModalError:true,
                    }
                    //console.log(tempError.message)
                    setFilterDataFunc2(tempError)
                })
    }
    
    if(filterData2.isCsv){
            const tempObj={data0:filterData2.transactionData,
                        tableTemplate:filterData2.tableTemplate}
            genCsv(tempObj)
            setFilterData2({...filterData2,isCsv:false})
    }

    if(filterData2.isXlsx){
            const tempObj={data0:filterData2.transactionData,
                        tableTemplate:filterData2.tableTemplate}
            genXlsx(tempObj)
            setFilterData2({...filterData2,isXlsx:false})
    }
    },[filterData2])


const setSortFunc=(data)=>{
    //setFilterDataFunc({["billMenuSort"]:data})
    setFilterDataFunc({...filterData,sort:data})
}
const setSortFunc2=(data)=>{
    //setFilterDataFunc({["billMenuSort"]:data})
    setFilterDataFunc2({...filterData2,sort:data})
}
const setLimitRowFunc=(data)=>{
    //setFilterDataFunc({["billMenuLimitRow"]:data})
    setFilterDataFunc({...filterData,limitRow:data})
}
const setLimitRowFunc2=(data)=>{
    //setFilterDataFunc({["billMenuLimitRow"]:data})
    setFilterDataFunc2({...filterData2,limitRow:data})
}


const setIsCsv=(data)=>{
    if(data){
        setFilterData({...filterData,isCsv:true,isXlsx:false})
    }
    else{
        setFilterData({...filterData,isCsv:false})
    }
}
const setIsXlsx=(data)=>{
    if(data){
        setFilterData({...filterData,isXlsx:true,isCsv:false})
    }
    else{
        setFilterData({...filterData,isXlsx:false})
    }
}



const setIsCsv2=(data)=>{
    if(data){
        setFilterData2({...filterData2,isCsv:true,isXlsx:false})
    }
    else{
        setFilterData2({...filterData2,isCsv:false})
    }
}
const setIsXlsx2=(data)=>{
    if(data){
        setFilterData2({...filterData2,isXlsx:true,isCsv:false})
    }
    else{
        setFilterData2({...filterData2,isXlsx:false})
    }
}




const setShowModalFilterFunc=(data)=>{
    setFilterDataFunc({...filterData,showModalFilter:data})
}
const setShowModalFilterFunc2=(data)=>{
    setFilterDataFunc2({...filterData2,showModalFilter:data})
}

const setShowModalErrorFunc=(data)=>{setFilterDataFunc({...filterData,showModalError:data})}
const setShowModalErrorFunc2=(data)=>{setFilterDataFunc2({...filterData2,showModalError:data})}

const setInputStateFunc=(data)=>{
    //setFilterDataFunc({["billMenuInputState"]:data})
    setFilterDataFunc({...filterData,inputState:data})
}
const setInputStateFunc2=(data)=>{
    //setFilterDataFunc({["billMenuInputState"]:data})
    setFilterDataFunc2({...filterData2,inputState:data})
}
const setPageNumberFunc=(data)=>{
    setFilterDataFunc({...filterData,
        pageNumber:data,
        reloadData:true
    })
}
const setPageNumberFunc2=(data)=>{
    setFilterDataFunc2({...filterData2,
        pageNumber:data,
        reloadData:true
    })
}


const setTransactionDataFunc=(data)=>{
    setFilterDataFunc({...filterData,transactionData:data})

}
const setTransactonArrayFunc=(data)=>{
    setFilterDataFunc({...filterData,transactionArray:data})

}
const setEditDataFunc=(data)=>{
    setFilterDataFunc({...filterData,editData:data})

}
const setTableTemplateFunc=(data)=>{
    setFilterDataFunc({...filterData,tableTemplate:data})
}

const setTransactionDataFunc2=(data)=>{
    setFilterDataFunc2({...filterData2,transactionData:data})

}
const setTransactonArrayFunc2=(data)=>{
    setFilterDataFunc2({...filterData2,transactionArray:data})

}
const setEditDataFunc2=(data)=>{
    setFilterDataFunc2({...filterData2,editData:data})

}
const setTableTemplateFunc2=(data)=>{
    setFilterDataFunc2({...filterData2,tableTemplate:data})
}


const setShowTable=(data)=>{
    //console.log('setSwapState PartnerForBill...')
    //console.log(data)
    
    setFilterData({
        ...filterData,
        showTable:data
    })
}

const setShowTable2=(data)=>{
    //console.log('setSwapState PartnerForBill...')
    //console.log(data)
    
    setFilterData2({
        ...filterData2,
        showTable:data
    })
}


const saveTableTemplateFunc=(tableTemplate)=>{
    saveTableTemplate(tableTemplate,
        "p35branch",
        "billMenuTableTemplate",
        myheader,
        shouldRender
        )
}

//------------------------------
const genTransactionData=(data)=>{
    let tempArray=[]
    data.map(i=>{
       const {_id,detail,date,...remaining}=i
 
       detail.map(j=>{
          const {id,barcode,...remaining2}=j
          const tempObj={...remaining,
                productId:id,
                productBarcode:barcode,
                date:date,
                time:date,
                //detailTime:tempDetailTime,
                ...remaining2
             }
          tempArray=[...tempArray,tempObj]
       })
    })
    
    return tempArray
 }
 //===========================================

 const genTransactionData2=(data,inputState,qry)=>{
   
    //const option="or"
    let tempOption=Object.keys(qry)
    tempOption=tempOption[0]

    //console.log('genTransactionData2')
    //console.log(tempOption)
    const option=tempOption

    const tempKeyArray=["productId", 
                        "barcode",
                        "sn",
                        "productName",
                        "groupName"
                    ]
    
    let tempToCheckArray=[]
    
    tempKeyArray.map(i=>{
        if(inputState[i].toCheck){
            tempToCheckArray=[...tempToCheckArray,{...inputState[i],key:i}]
        }
    })

    //console.log('tempToCheckArray.....')
    //console.log(tempToCheckArray)

    let tempArray4=[]

    const tempToCheckArrayLength=tempToCheckArray.length

    if(tempToCheckArrayLength>0){

            data.map(j=>{

                let countCheckPass=0

                tempToCheckArray.map(k=>{

                    if(k.key=="productId"){

                            if(j[k.key]==parseInt(k.value)){
                                countCheckPass=countCheckPass+1
                            }

                    }
                    else if(k.key=="barcode"||
                            k.key=="sn"||
                            k.key=="productName"||
                            k.key=="groupName"
                    ){

                            if(j[k.key].includes(k.value)){
                                countCheckPass=countCheckPass+1
                            }

                    }


                    if(option=="$or"){
                            if(countCheckPass>0){
                                tempArray4=[...tempArray4,j]                       
                            }
                    }
                    else {
                            if(countCheckPass==tempToCheckArrayLength){
                                tempArray4=[...tempArray4,j]                        
                            }
                    }



                })

            })

            return tempArray4  
    }

    else {
        //console.log('data...')
        //console.log(data)
        return data
    }

    
 }
 
//=============================================== 
//================================================
const filterAxiosFunc=(option,inputState)=>{
    //console.log('filterData')
    //console.log(filterData)
    
    
    const filterTemplate=basicDataSt.pageFilter.billMenuFilter
    
    filterAxios(option,inputState,filterTemplate,
                filterData,null,myheader,
                shouldRender
                )
    .then(result=>{
        const { data0,count,
                lastRecordId,reloadData,
                pageNumber,qry } =result


        //console.log('filterAxiosFunc............1')
        //console.log(result)
        //console.log(result)
        //console.log(result.count)
        let temp=genTransactionData(result.data0)
        temp=genTransactionData2(temp,inputState,qry)

        if(filterData.isCsv)
        {
            //const tempObj={data0:temp,
            //             tableTemplate:filterData.tableTemplate}
            //genCsv(tempObj)

            setFilterDataFunc({...result,
                isCsv:false,
                transactionArray:result.data0,
                transactionData:temp,
                showAllBranch:false,
            })
            setFilterDataFunc2({
                ...filterData2,
                showAllBranch:false
            })

        }
        else if(filterData.isXlsx){
            //const tempObj={data0:temp,
            //    tableTemplate:filterData.tableTemplate}
            //genXlsx(tempObj)

            setFilterDataFunc({...result,
                isXlsx:false,
                transactionArray:result.data0,
                transactionData:temp,
                showAllBranch:false,
            })
            setFilterDataFunc2({
                ...filterData2,
                showAllBranch:false
            })
        }
        else{
            setFilterDataFunc({...result,
                            //count,
                            //lastRecordId,
                            //reloadData,
                            //pageNumber,
                            //qry,
                            transactionArray:result.data0,
                            transactionData:temp,
                            showAllBranch:false
                        })
            
            setFilterDataFunc2({
                ...filterData2,
                showAllBranch:false
            })
            //setTransactonArrayFunc(result.data0)
            //setTransactionDataFunc(temp)
            /*
            setFilterDataFunc({
                ["billMenuShowTransaction"]:true,
                ["billMenuOption"]:option
            })
            */
        }
    })
    .catch(error=>{
        //console.log('error filterAxiosFunc....')
        //console.log(error)

        const tempError={...filterData,
            reloadData:false,
            message:error.message,
            showModalConfirm:false,
            showModalError:true,
        }
    setFilterDataFunc(tempError)
    }
    )
}
//====================
const filterAxiosFunc2=(option,inputState)=>{
    const filterTemplate=basicDataSt.pageFilter.billBranchMenuFilter
    filterAxios(option,inputState,filterTemplate,
                filterData2,"getbranchtransactionlimit",myheader,
                shouldRender
                )
    .then(result=>{

        const { data0,count,
                lastRecordId,reloadData,
                pageNumber,qry } =result
        //console.log('filterAxiosFunc............2')
        //console.log(result)
        let temp=genTransactionData(result.data0)
        temp=genTransactionData2(temp,inputState,qry)


        if(filterData2.isCsv){
            //const tempObj={data0:temp,
            //             tableTemplate:filterData2.tableTemplate}
            //genCsv(tempObj)    

            setFilterDataFunc2({...result,
                isCsv:false,
                transactionArray:result.data0,
                transactionData:temp,
                showAllBranch:true,
            })

            setFilterDataFunc({...filterData,
                showAllBranch:true,
            })

        }
        else if(filterData2.isXlsx){
            //const tempObj={data0:temp,
            //    tableTemplate:filterData2.tableTemplate}
            //genXlsx(tempObj)    

            setFilterDataFunc2({...result,
                isXlsx:false,
                transactionArray:result.data0,
                transactionData:temp,
                showAllBranch:true,
            })

            setFilterDataFunc({...filterData,
                showAllBranch:true,
            })

        }
        else{
                setFilterDataFunc2({...result,
                    isCsv:false,
                    isXlsx:false,
                    transactionArray:result.data0,
                    transactionData:temp,
                    showAllBranch:true,
                })

                setFilterDataFunc({...filterData,
                    showAllBranch:true,
                })
        }
    })
  
    .catch(error=>{
        //console.log('error filterAxiosFunc....2')
        //console.log(error)
        const tempError={...filterData2,
            reloadData:false,
            message:error.message,
            showModalConfirm:false,
            showModalError:true,
        }
    setFilterDataFunc2(tempError)
    })
}

//====================
const renderTableAndBadge=()=>{
    return(
  <div className="w-100 h-100">
      <div className="w-100">
          
          {
          renderBadgeForBill({
              filterData,

              setShowTable:setShowTable,

              setFilterData:setFilterData,
              setPageNumber:setPageNumberFunc,
              bgColor:"#72a2d9",
              editData:filterData.editData,
              setReloadDataFunc:()=>{},//setReloadDataFunc,
              //genCsv:()=>{}//genCsvFunc
              mergeProduct:mergeProductForFilterData,
              captureEditData:captureBillMenu,

          })   
         }
      </div>
      <div className="w-100 h90"></div>
        {filterData.transactionData&&!filterData.showAllBranch&&
        filterData.showTable
        ?<Table
            colorHead={"#2458af"}
            tableTemplate={filterData.tableTemplate}
            setTableTemplate={setTableTemplateFunc}
    
            filterData={filterData.transactionData}
            setFilterData={setTransactionDataFunc}
            
            editData={filterData.editData}
            setEditData={setEditDataFunc}
            saveTableTemplateFunc={saveTableTemplateFunc}
            isSubTable={false}
            updateFilterData={()=>{}}
            useInput={false}
            selectData={null}
        />
        :renderGrid(filterData,filterData.tableTemplate,setEditDataFunc)
        }
      
    </div>
        
    )
}
//====================

const renderTableAndBadge2=()=>{
    return(
  <div className="w-100 h-100">
      <div className="w-100">
          
          {
          renderBadgeForBill({
              filterData:filterData2,
              setShowTable:setShowTable2,
              setFilterData:setFilterData2,
              setPageNumber:setPageNumberFunc2,
              bgColor:"#2458af",
              editData:filterData2.editData,
              setReloadDataFunc:()=>{},//setReloadDataFunc,
              //genCsv:()=>{}//genCsvFunc
              mergeProduct:mergeProductForFilterData2,
              captureEditData:captureBillMenu,

          })   
         }
      </div>
      <div className="w-100 h90"></div>
   
      {filterData2.transactionData&&filterData2.showAllBranch&&
      filterData2.showTable
      ?<Table
          colorHead={"#1f3057"}
          tableTemplate={filterData2.tableTemplate}
          setTableTemplate={setTableTemplateFunc2}
  
          filterData={filterData2.transactionData}
          setFilterData={setTransactionDataFunc2}
          
          editData={filterData2.editData}
          setEditData={setEditDataFunc2}
          saveTableTemplateFunc={saveTableTemplateFunc}
          isSubTable={false}
          updateFilterData={()=>{}}
          useInput={false}
          selectData={null}
      />
      :renderGrid(filterData2,filterData2.tableTemplate,setEditDataFunc2)
      }
    </div>
        
    )
}
//====================
const renderFilter=()=>{
    return(
        <ModalFilterInput
            show={filterData.showModalFilter} 
            setShow={setShowModalFilterFunc}
            
            filterTemplate={basicDataSt.pageFilter.billMenuFilter}
    
            inputState={filterData.inputState}//{filterDataForBill.billMenuInputState} 
            setInputState={setInputStateFunc}
            
            limitRow={filterData.limitRow}//{filterDataForBill.billMenuLimitRow} 
            setLimitRow={setLimitRowFunc}
            
            sort={filterData.sort}//{filterDataForBill.billMenuSort} 
            setSort={setSortFunc}
            
            isCsv={filterData.isCsv}
            setIsCsv={setIsCsv}
    
            isXlsx={filterData.isXlsx}
            setIsXlsx={setIsXlsx}

            filterAxios={filterAxiosFunc}
            basicData={{...basicData,transaction:transactionType.data0}}
    
            LB={basicDataSt.pageFilterForm.billMenuFilterForm}
            />
    )
    
    }

//=============================
const renderFilter2=()=>{
    return(
        <ModalFilterInput
            show={filterData2.showModalFilter} 
            setShow={setShowModalFilterFunc2}
            
            filterTemplate={basicDataSt.pageFilter.billBranchMenuFilter}
    
            inputState={filterData2.inputState}//{filterDataForBill.billMenuInputState} 
            setInputState={setInputStateFunc2}
            
            limitRow={filterData2.limitRow}//{filterDataForBill.billMenuLimitRow} 
            setLimitRow={setLimitRowFunc2}
            
            sort={filterData2.sort}//{filterDataForBill.billMenuSort} 
            setSort={setSortFunc2}
            
            isCsv={filterData2.isCsv}
            setIsCsv={setIsCsv2}

            isXlsx={filterData2.isXlsx}
            setIsXlsx={setIsXlsx2}
    
            filterAxios={filterAxiosFunc2}
            basicData={{...basicData,transaction:transactionType.data0}}
    
            LB={basicDataSt.pageFilterForm.billBranchMenuFilterForm}
            />
        
    )
    
}
//====================
//=====================
//====================
return(
<div className="w-100" 
     style={{marginBottom:"4rem",position:"relative"}} >
      <div className='w-100'
            style={{marginBottom:"2rem"}}
      >
        {
         renderFilter()
        }
      </div>
      
      {
      <div className='w-100'>
      {
        renderFilter2()
      }
      </div>
      }
      {
       //showJobStatus&&basicDataSt&&
       //renderJobStatus() 
      }    


      {
       filterData.transactionData&&!filterData.showAllBranch
        ?renderTableAndBadge()
        :null
      }
     {
       filterData2.transactionData&&filterData2.showAllBranch
        ?renderTableAndBadge2()
        :null
      }
    
      {  
        filterData.showModalError&&!filterData.showAllBranch
        ?renderModalError({
            setShow:setShowModalErrorFunc,
            message:filterData.message
        })
        :null
    
      }
   
      {  
        filterData2.showModalError&&filterData2.showAllBranch
        ?renderModalError({
            setShow:setShowModalErrorFunc2,
            message:filterData2.message
        })
        :null
    
      }
</div>

)

}
export default BillMenuComponent;


import React from 'react';
import ModalFilterInput from '../../render/renderFilter/ModalFilterInput'
import FilterTemplate from '../../render/renderFilter/FilterTemplate'
import Table from '../../component/table/Table'
import renderBadgeForBill from './renderBadgeForBill'
import axios from '../../util/axiosVerify'
import uuid from 'react-uuid'

//import NewTree from '../../component/newtree/NewTree'
import NewGroup from '../../component/newgroup/NewGroup';
import pageUtil from '../../component/pageComponent/pageUtil'
import filterDataTemplate from './filterDataTemplate'
import {MainContext} from '../../context/MainContext'
import axiosUtil from '../../util/axiosUtil'
import ProductGrid from './ProductGrid'
import {MdRefresh} from 'react-icons/md';
import {FaPlusCircle,FaMinusCircle} from 'react-icons/fa'

import console from '../../util/consoleVerify'

const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,catchErrorToMessage}=axiosUtil

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete
} = pageUtil
//==================================
function BillGroupComponent(props) {

const { captureProductOfGroup,
        pageData,
        productInputState,
        captureEditProduct,
        billGroupComponentFilterData,
        setBillGroupComponentFilterData,
        billGroupComponentFilterData2,
        setBillGroupComponentFilterData2,
        captureSelectProduct,
        setSelectProductToBillForm,
        captureEditProductToBillToFindByGroupId,

        selectProduct,
        resetProductList,setResetProductList,
        captureProductListFromSelectProductInProductGrid,
        refreshProductFunc,
        addSelectProductToBillForm
        
      }=props

const {basicDataSt,
        setReloadBasicData,
        myheader,
        setBasicData
        //widthLeft,setWidthLeft
}=React.useContext(MainContext)

const {basicData}=basicDataSt
//console.log('BillGroupComponent')

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.................................')
        shouldRender.current=false
    } 
},[])


const defaultFilterData={...filterDataTemplate.product}
const defaultFilterData2={
    count:0,
    data0:null,
    dataUrl:"p35product",
    lastRecordId:null,
    limitRow:basicData.limitRow,//3,
    pageNumber:1,
    initPageNumber:true,
    qry:{},
    tableName:"productTableTemplate",
    editData:null,
    tableTemplate:basicDataSt.tableTemplate.productTableTemplate,
    inputState:productInputState,

    showModalFilter:false,
    reloadData:false,
    sort:{id:-1},
    message:null,
    showModalError:false,
    selectProduct:[],
    badgeState:{
        swapShow:true,swapFunc:()=>{},
        reloadShow:true,reloadFunc:()=>{},
        filterShow:false,filterFunc:()=>{},
        addShow:false,addFunc:()=>{},
        editShow:false,editFunc:()=>{},
        delShow:false,delFunc:()=>{},
        printerShow:false,printerFunc:()=>{},
        csvShow:false,csvFunc:()=>{},
        forwardShow:false,forwardFunc:()=>{},
        bullEyeShow:true,
        unSelectShow:false,
    },
    showTable:false
}


const genFilterData=()=>{
    if(billGroupComponentFilterData){
        return billGroupComponentFilterData
    }
    else{
        return defaultFilterData
    }
}

const genFilterData2=()=>{
    if(billGroupComponentFilterData2){
        return billGroupComponentFilterData2
    }
    else{
        return defaultFilterData2
    }
}

const [productList,setProductList]=React.useState([])


let [filterData,setFilterData]=React.useState(genFilterData())
//const [filterInputState,setFilterInputState]=React.useState(productInputState)
//const [editData,setEditData]=React.useState(null)
//const [tableTemplate,setTableTemplate]=React.useState(basicDataSt.tableTemplate.productTableTemplate)

let [filterData2,setFilterData2]=React.useState(genFilterData2())



//=============================
React.useEffect(()=>{
    //console.log('selectProduct')
    //console.log(selectProduct)
 
 },[selectProduct])

 /*
//for grid
const sortSelectProduct=(selectProduct)=>{
    let tempArray=[]
    selectProduct.map(i=>{
        tempArray=[...tempArray,i.productName]
    })

    let sortArray=tempArray.sort()

    let tempFinalArray=[]

    sortArray.map(k=>{
        selectProduct.map(l=>{
            if(l.productName==k){
                tempFinalArray=[...tempFinalArray,l]
            }
        })
    })

    return tempFinalArray
}

let tempSelectProduct=[]

if(selectProduct){
    tempSelectProduct=sortSelectProduct(selectProduct)
}
*/
//----------------------------------
React.useEffect(()=>{
    //console.log('productList')
    //console.log(productList)
    captureProductListFromSelectProductInProductGrid(productList)
},[productList])


React.useEffect(()=>{
    if(resetProductList){
        setProductList([])
    }
},[resetProductList])


const addToList=(i)=>{
    let tempProductList=[]
    let matchId=false

    for (let j=0;j<productList.length;j++){
        if(i.id==productList[j].id){
            matchId=true
            const tempExistingQuantity=parseInt(productList[j]["quantity"])
            const tempObj={...productList[j],["quantity"]:tempExistingQuantity+1}
            tempProductList=[...tempProductList,tempObj]
        }
        else {
            tempProductList=[...tempProductList,productList[j]]
        }
    }

    if(!matchId){
        tempProductList=[...tempProductList,
                {...i,
                    ["quantity"]:1,
                    ["detailTime"]:new Date().toISOString()
                }
            ]
    }
    setResetProductList(false)
    setProductList(tempProductList)
} 

const deleteFromList=(i)=>{
    let tempProductList=[]
    let matchId=false

    for (let j=0;j<productList.length;j++){
        if(i.id==productList[j].id){
            matchId=true
            const tempExistingQuantity=parseInt(productList[j]["quantity"])
            if(tempExistingQuantity>0){
                const tempObj={...productList[j],["quantity"]:tempExistingQuantity-1}
                tempProductList=[...tempProductList,tempObj]
            }
            else {
                tempProductList=[...tempProductList,productList[j]]
            }
        }
        else {
            tempProductList=[...tempProductList,productList[j]]
        }
    }
    setResetProductList(false)
    setProductList(tempProductList)
}




const addStockToList=(i)=>{
    let tempProductList=[]
    let matchId=false

    let tempStock=0
    if(i.stock==0){
        tempStock=0
    }
    else if(i.stock<0){
        tempStock=i.stock
    }
    else if(!i.stock){
        tempStock=0
    }
    else{
        tempStock=i.stock
    }

    for (let j=0;j<productList.length;j++){
        if(i.id==productList[j].id){
            matchId=true
            const tempExistingQuantity=parseInt(productList[j]["quantity"])
            const tempObj={...productList[j],["quantity"]:tempExistingQuantity+tempStock}
            tempProductList=[...tempProductList,tempObj]
        }
        else {
            tempProductList=[...tempProductList,productList[j]]
        }
    }

    if(!matchId){
        tempProductList=[...tempProductList,
                {...i,
                    ["quantity"]:tempStock,
                    ["detailTime"]:new Date().toISOString()
                }
            ]
    }
    setResetProductList(false)
    setProductList(tempProductList)
} 


const findQuanityFromProductList=(i)=>{
    let quantity=0

    productList.map(j=>{
        if(i.id==j.id){
            quantity=j.quantity
        }
    })

    return quantity
}



//=============================

const cancelSelectProductFunc=()=>{
    //console.log('cancelSelectProductFunc.............')
    let tempFilterData2=filterData2
    if(filterData2.data0){
        if(filterData2.data0.length>0){
            //console.log('ssssssss')
            let tempArray=[]

            filterData2.data0.map(i=>{
                tempArray=[...tempArray,{...i,selectedLine:false}]
            })
            tempFilterData2={...tempFilterData2,data0:tempArray}
        }
    }

    setFilterData2Func({...tempFilterData2,selectProduct:[]})
}


const saveTableTemplateFunc=(tableTemplate)=>{

    //console.log(tableTemplate)

    saveTableTemplate(tableTemplate,'p35branch','productTableTemplate',myheader,shouldRender)
}


const captureSelectProductFunc=(selectProduct,qty)=>{
    const tempSelectProduct=selectProduct
    cancelSelectProductFunc()

    captureSelectProduct(tempSelectProduct,qty)
}

const setFilterData2Func=(obj)=>{
    setFilterData2(obj)
    setBillGroupComponentFilterData2(obj)
}

const setFilterDataFunc=(obj)=>{
    setFilterData(obj)
    setBillGroupComponentFilterData(obj)
}

const backToFilter=()=>{setFilterData2Func({...filterData2,data0:null})}
const setSortFunc=(data)=>{setFilterData2Func({...filterData2,sort:data})}
const setLimitRowFunc=(data)=>{setFilterData2Func({...filterData2,limitRow:data})}
const setPageNumberFunc=(data)=>{
    //console.log('setPageNumberFunc')
    //console.log(data)
    setFilterData2Func({...filterData2,
        pageNumber:data,
        reloadData:true
    }
)}


const setFilterDataData0Func=(data)=>{
    //console.log('setFilterDataData0Func')
    //console.log(data)
    setFilterData2Func({...filterData2,data0:data})
}
const updateFilterDataFunc=(index,i)=>{
    //console.log('updateFilterDataFunc')
    const temp=updateFilterData(index,i,filterData2)
    //console.log('temp...............')
    //console.log(temp)
    setFilterData2Func({...filterData2,...temp})
}


const setShowTableFunc=(data)=>{
    setFilterData2Func({...filterData2,showTable:data})
}


const setTableTemplate=(data)=>{setFilterData2Func({...filterData2,
    tableTemplate:data
})}
const setEditData=(data)=>{
    //console.log('setEditData')
    //console.log(data)
    setFilterData2Func({...filterData2,
        editData:data
    })
    captureEditProductToBillToFindByGroupId(data)
    //captureEditProduct(data)
}
const setInputState=(data)=>{setFilterData2Func({...filterData2,
    inputState:data
})}
const setShowModalFilter=(data)=>{setFilterData2Func({...filterData2,
    showModalFilter:data
})}
const setReloadDataFunc=()=>{setFilterData2Func({...filterData2,
        reloadData:true
    })
}

React.useEffect(()=>{
    //console.log('filterData2...BillGroupComponent')
    //console.log(filterData2)
    
    //setFilterData2Func
    //({...filterData2,initPageNumber:false})
    //if((filterData2.pageNumber>0)&&(!filterData2.initPageNumber)){
    if(filterData2.reloadData){

        const {dataUrl,pageNumber,limitRow,sort,qry}=filterData2

        axios.post(`/${dataUrl}/getlimit`,{pageNumber,limitRow,sort,...qry},
        myheader,shouldRender)
        .then(result=>{
            //console.log('results....xxxxxxxxxxxx..')
            //console.log(result.data.data)
            const tempResult= { ...filterData2,
                                data0:result.data.data,
                                count:result.data.count,
                                lastRecordId:result.data.lastRecordId,
                                reloadData:false,
                             }

            const tempData0=genFilterDataWithIndex(tempResult.data0)
            setFilterData2Func({...tempResult,data0:tempData0})
            //setFilterData2Func(tempResult)
        })
        .catch(error=>{
            //catchErrorToMessage(error,setMessage)
            //setMessage(error.response.data.message)
            const tempError={...filterData2,
                                reloadData:false,
                                message:catchErrorToMessage(error),
                                showModalConfirm:false,
                                showModalError:true,
                            }
            setFilterData2Func(tempError)
        })
    }
    
},[filterData2])

//==========================



const filterDataByGroup=(i)=>{


    //console.log('filterDataByGroup')
    //console.log(filterData2)
    
    const {limitRow,pageNumber,sort,dataUrl}=filterData2
    
    const tempQry={groupId:i.id}

    const myController="getlimit"
    const tempObj={limitRow,pageNumber:1,sort,...tempQry}
    
    axios.post(`/${dataUrl}/${myController}`,tempObj,myheader,shouldRender)
    //axios.post(`/${dataUrl}/getlimit`,{pageNumber,limitRow,sort,...qry},
    //myheader,shouldRender)
    .then(result=>{
        
        //console.log('filterDataByGroup')
        //console.log(result.data)

        const tempResult= { ...filterData2,
            data0:result.data.data,
            count:result.data.count,
            lastRecordId:result.data.lastRecordId,
            reloadData:false,
            qry:tempQry,
            pageNumber:1,
        }

        const tempData0=genFilterDataWithIndex(tempResult.data0)
        //setFilterData
        setFilterData2Func({...tempResult,data0:tempData0})

    })
    .catch(error=>{
        setFilterData2Func(error)
    })

    
}


const reloadAxiosAllFunc=async()=>{
    //console.log('reloadAxiosAllFunc..........')
    const temp=await reloadAxiosAll({...filterData,user:basicDataSt.user},
                                     myheader,shouldRender)
    
    
    //console.log(temp)                          
    captureProductOfGroup(temp.data0)
    setFilterDataFunc(temp)
    setFilterData2Func({...filterData2,data0:temp.data0})
}
//====================
const captureProductOfGroupFunc=()=>{
    captureProductOfGroup(filterData2.data0)
    //setFilterDataFunc({...filterData2})
    //setFilterDataFunc(filterData2.data0)
}

const cancelSelectProduct=()=>{
    //console.log('cancelSelectProduct........')
}
//====================
//===================
React.useEffect(()=>{
    //console.log('filterData PageComponent')
    //console.log(filterData)
    if(filterData.reloadData){
       reloadAxiosAllFunc()
    }
},[filterData])

//=======================


const genFilterDataWithIndex=(filterData)=>{

    let tempArray=[]

    filterData.map((i,idx)=>{
        //const temp={...i,tempIndex:idx,selectedLine:false}
        let tempStocking={}

        if(i.stocking){
            let isSearching=true
            i.stocking.map(k=>{
                if(isSearching){
                    if(k.branchId==basicData.branchId){
                        tempStocking={
                            branchId:k.branchId,
                            stock:k.stock,
                            minStock:k.minStock,
                            maxStock:k.maxStock,
                            storage:k.storage
                        }
                        isSearching=false
                    }
                }   
            })
        }


        const temp={...i,selectedLine:false,_id:uuid(),...tempStocking}
        tempArray=[...tempArray,temp]

    })

    return tempArray
}


//=======================
//ModalFilterInput

const filterAxiosFunc=(option,inputState)=>{

    //console.log('filterAxiosFunc')
    //console.log(filterData2)

    //console.log("filterAxiosFunc")
    //console.log(`option = ${option}`)
    //console.log('inputState')
    //console.log(inputState)
    //console.log('filterTemplate')
    //console.log(FilterTemplate.productFilter)

    //console.log('basicDataSt.user')
    //console.log(basicDataSt.user)

    filterAxios(option,inputState,FilterTemplate.productFilter,
                 filterData2,'getlimit',myheader,
                 shouldRender,basicDataSt.user.branchId)
    .then(result=>{
        //console.log('result..mmmnnn...kkk.')
        //console.log(result.data0)
                
        const tempData0=genFilterDataWithIndex(result.data0)

        //console.log(tempData0)
        setFilterData2Func({...result,data0:tempData0})

    })
    .catch(error=>{
        setFilterData2Func(error)
    })

}




const renderImage=()=>{
    return(
    <div className="w-100" style={{marginBottom:"4rem"}} >

        <div className="grid-flex"
             style={{margin:"0.2rem"}}
        >
            {/*
            <div className='grid-flextileMother'
                 style={{width:`${basicData.tileMotherWidth}rem`,
                         height:`${basicData.tileMotherHeight}rem`,
                        }}
            >
                <div className="grid-flextile"
                    style={{
                        border:"2px solid white",
                        height:`${basicData.imgHeight}%`,
                        width:`${basicData.imgWidth}%`,
                    }}
                >
                    <div className='w-100 h-100 flex-center-center bd-blue'>
                        <MdRefresh className="md-icon"
                            onClick={e=>{
                                //refreshProductFunc({isRawMat:false})
                            }}
                        />
                    </div>
                
                </div>
            </div>
            */}
            {
                filterData2.data0.map((i,idx)=>{

                        const genPhoto=()=>{
                            let temp=null
                            if(i.photoUrl1){
                                if(i.photoUrl1[0]){
                                    temp=i.photoUrl1[0]
                                }
                            }
                            return temp
                        }

                        const producNameHeight=100-basicData.imgHeight
                        return(
                        <div  key={idx}
                                className="grid-flextileMother"
                                style={{
                                    width:`${basicData.tileMotherWidth}rem`,
                                    height:`${basicData.tileMotherHeight}rem`,
                                   
                                }}
                        >

                            <div key={idx}
                                    className="grid-flextile"
                                    style={{
                                        position:"relative",
                                        border:"1px solid white",
                                        height:`${basicData.imgHeight}%`,
                                        width:`${basicData.imgWidth}%`,
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center",
                                        overflow:"hidden"
                                    }}
                            >
                                    
                                   
                                        {i.photoUrl1[0]
                                        ?<img 
                                            style={{
                                                height:"100%",
                                                //objectFit:"cover",
                                                //height:"100%",
                                                borderRadius:"0.5rem",
                                                }}
                                            src={genPhoto()}
                                            alt={`${i.productName}`}
                                        />
                                        :<div style={{height:"100%"}}>
                                            {i.productName}
                                        </div>
                                        }
                                  
                                   
            

                                    <div style={{position:"absolute",bottom:"0px",
                                                left:"0px",textAlign:"center",
                                                width:"1.5rem",height:"1.5rem",
                                                color:"white",backgroundColor:"red",
                                                borderRadius:"50%",margin:"auto 0",
                                                opacity:"0.9",zIndex:"10"
                                            }}
                                    >
                                        {
                                        findQuanityFromProductList(i)
                                        }
                                    </div>
                                    
                                    <div className='' 
                                        style={{position:"absolute",
                                                top:"0",
                                                height:"100%",
                                                width:"100%"
                                                }}>
                                    
                                        <div style={{width:"100%",
                                                     height:"100%",
                                                     position:"relative"
                                                     }}>
                                        
                                            <div className="grid-price" 
                                                    style={{
                                                        display:"flex",
                                                        justifyContent:"center",
                                                        alignItems:"center",
                                                        backgroundColor:"rgba(0,0,0,0)"
                                                    }}
                                            >
                                                    <FaPlusCircle className="lg-icon"
                                                        onClick={e=>{
                                                            addToList(i)
                                                        }}
                                                    />
                                            </div>        
                                    
                                            <div className="grid-price" 
                                                    style={{
                                                        display:"flex",
                                                        justifyContent:"center",
                                                        alignItems:"center",
                                                        backgroundColor:"rgba(0,0,0,0)"
                                                    }}
                                            >
                                                    <FaMinusCircle className="lg-icon"
                                                        onClick={e=>{
                                                            deleteFromList(i)
                                                        }}
                                                    />
                                            </div>
                                        </div>        

                                        <div className='grid-stock bill-p' 
                                            style={{width:"100%",
                                            position:"absolute",
                                            bottom:"0",
                                            textAlign:"right",
                                            color:"black",
                                            opacity:1
                                            }}
                                            onClick={e=>{
                                                addStockToList(i)
                                            }}
                                        >
                                            {i.stock
                                            ?`stock: ${i.stock}`
                                            :`stock: 0`
                                            }
                                        </div>

                                    </div>



                            </div>
                            
                            
                            <div className=''
                                style={{width:"100%",height:`${producNameHeight}%`}}
                            >
                                     {i.photoUrl1[0]
                                     ?`${i.productName}`
                                     :`${i.id}`
                                     }
                            </div>         
                            


                    </div>
                    )
                })
            }
        </div>
    </div>
    )
}


//====================
const renderTableAndBadge=()=>{
    return(
        <div className="w-100 h-100" style={{paddingTop:"1rem"}}>
            <div className="">
                { 
                    renderBadgeForBill({
                        filterData:filterData2,
                        setPageNumber:setPageNumberFunc,
                        bgColor:"#74b979",
                        backToFilter:backToFilter,
                        editData:null,//filterData2.editData,
                        captureEditData:()=>{},//captureEditProduct,
                        captureProductOfGroup:captureProductOfGroupFunc,
                        setReloadDataFunc:setReloadDataFunc,
                        useStock:true,
                        showGoPackage:true,
                        unSelectShow:true,
                        bullEyeShow:true,
                        captureSelectProduct:captureSelectProductFunc,
                        cancelSelectProduct:cancelSelectProductFunc,
                        setShowTable:setShowTableFunc,
                        addSelectProductToBillForm,
                        productList
                        //setUnSelectAll:setUnSelectAll
                    }) 
             }
            </div>

            {filterData2.showTable
                ?<div className="w-100 h90">
                    <Table
                        colorHead={"#4b6d62"}
                        //tableTemplate={basicDataSt.tableTemplate.partnerTableTemplate}
                        tableTemplate={filterData2.tableTemplate}
                        setTableTemplate={setTableTemplate}//setTableTemplateFunc}

                        filterData={filterData2.data0}
                        setFilterData={setFilterDataData0Func}
                        //setFilterDataFunc}//{setFilterDataData0}
                        
                        editData={filterData2.editData}
                        setEditData={setEditData}//{updateEditData}
                        saveTableTemplateFunc={saveTableTemplateFunc}
                        isSubTable={false}
                        useInput={false}
                        //updateFilterData={()=>{}}//{updateEditData}
                        updateFilterData={updateFilterDataFunc}

                        basicData={basicData}
                    />
                </div>

                :<div className="w-100 h-100">
                        {   
                            renderImage()
                        }
                 </div>
            }

        </div>
    )
}



//====================
return(
<div className="w-100" style={{paddingBottom:"0"}} >
         

            <ModalFilterInput
                title={"ค้นหา"}//filterTitle}
                show={filterData2.showModalFilter} 
                setShow={setShowModalFilter}

                //filterTemplate={FilterTemplate.partnerFilter}
                filterTemplate={basicDataSt.pageFilter.productFilter}

                inputState={filterData2.inputState} 
                setInputState={setInputState}
                
                limitRow={filterData2.limitRow}//limitRow} 
                setLimitRow={setLimitRowFunc}//setLimitRow}

                sort={filterData2.sort}//sort} 
                setSort={setSortFunc}//setSort}
                
                filterAxios={filterAxiosFunc}
                basicData={basicData}

                LB={basicDataSt.pageFilterForm.productFilterForm}
                //LB={pageFilterForm}
            />

            <div className="w-100" style={{marginBottom:"0rem"}}>
                <NewGroup
                    myheader={myheader}
                    dataUrl={"p35group"}
                    filterByGroupId={filterDataByGroup}
                    captureEditGroup={()=>{}}
                    bgColor={"#d1adbf"}
                    pageData={pageData}
                    basicData={basicData}
                    editData={filterData2.editData}
                    
    
                />
            </div>

         
            {
            filterData2.data0//&&filterData2.showModalFilter
            ?renderTableAndBadge()
            :null
            }
            
</div>

)

}
export default BillGroupComponent;
//#e5d1db
/*
<NewGroupComponent
    groupDataUrl={"p35group"}
    selectData={null}
    setSelectGroup={()=>{}}
    filterDataByGroup={filterDataByGroup}
    editData={null}
    canGroupChange={false}
/>
*/




import React from 'react';
import Table from '../../component/table/Table'
import uuid from 'react-uuid'
import {FaCheck,FaBan} from 'react-icons/fa';
import arrayUtil from '../../util/arrayUtil'
import Ticon from '../../component/ticon/Ticon'
import console from '../../util/consoleVerify'

function ModalTable(props) {

const {modalTableState,submitFunc,basicData,cancelFunc,
       saveTableTemplateFunc,pageData,user}=props

const {moveEditUp,moveEditDown,deleteEdit,insertAfterEdit}=arrayUtil

//console.log('modalTableState')
//console.log(modalTableState)

const gendData=(data)=>{
   let tempArray=[]
   data.map(i=>{
      const tempObj={...i,selectedLine:false,_id:uuid()}
      tempArray=[...tempArray,tempObj]
   })
   return tempArray
}

const {pageForm,tableTemplate,data,blankData,effecting,idKey,key}=modalTableState

const findBranchEffect=(effecting)=>{
    let myBranchEffect=null

    if(effecting){
         effecting.map(j=>{
             if(j.branchId==user.branchId){
                 myBranchEffect=j.effect
             }
         })
    }

    return myBranchEffect
}

const [data1,setData1]=React.useState(gendData(data))
const [editData,setEditData]=React.useState(null)
const [tableTemplate1,setTableTemplate1]=React.useState(tableTemplate)
const [showToolBox,setShowToolBox]=React.useState(true)
const [effectOfBranch,setEffectOfBranch]=React.useState(findBranchEffect(effecting))

React.useEffect(()=>{
    //console.log('data1.....----.....')
    //console.log(data1)
},[data1])

React.useEffect(()=>{
    //console.log('effectOfBranch....+++......')
    //console.log(effectOfBranch)
},[effectOfBranch])

const setEditDataFunc=(data)=>{

    //console.log('setEditDataFunc')
    if(modalTableState.key=="effectType"){
        //console.log(data)
        if(data.effectId>7){
            setEditData(data)
        }
    }
    else{
        setEditData(data)
    }

}


const moveUp=()=>{
    if(editData){
        //const [a,b,c,...remaining]=data1
        //const tempArray=(moveEditUp(remaining,editData,idKey))
        //setData1([a,b,c,...tempArray])


        let tempArray

        if(key=="effectType"){
            const [a,b,c,d,e,f,g,...remaining]=data1
            tempArray=[a,b,c,d,e,f,g,...moveEditUp(remaining,editData,idKey)]
        }
        else{
            tempArray=moveEditUp(data1,editData,idKey)
        }
        setData1(tempArray)

        if(effectOfBranch){
            const [a,b,c,...remaining]=effectOfBranch
            const tempArray=(moveEditUp(remaining,editData,idKey))
            setEffectOfBranch([a,b,c,...tempArray])
        }
    }
}

const moveDown=()=>{
   if(editData){
        //const [a,b,c,...remaining]=data1
        //const tempArray=(moveEditDown(remaining,editData,idKey))
        //setData1([a,b,c,...tempArray])

        let tempArray

        if(key=="effectType"){
            const [a,b,c,d,e,f,g,...remaining]=data1
            tempArray=[a,b,c,d,e,f,g,...moveEditDown(remaining,editData,idKey)]
        }
        else{
            tempArray=moveEditDown(data1,editData,idKey)
        }
        setData1(tempArray)


        if(effectOfBranch){
            const [a,b,c,...remaining]=effectOfBranch
            const tempArray=(moveEditDown(remaining,editData,idKey))
            setEffectOfBranch([a,b,c,...tempArray])
        }
   }
}

const deleteLine=()=>{
    if(editData){

        let tempArray

        if(key=="effectType"){
            //const [a,b,c,d,e,...remaining]=data1
            //tempArray=[a,b,c,d,e,...deleteEdit(remaining,editData,idKey)]
            let tempArray2=[]

            data1.map(jj=>{
                if(jj.effectId>7){
                    if(jj.effectId!=editData.effectId){
                        tempArray2=[...tempArray2,jj]
                    }
                }
                else{
                    tempArray2=[...tempArray2,jj]
                }

            })
            tempArray=tempArray2
        }
        else{
            tempArray=deleteEdit(data1,editData,idKey)
        }
        setData1(tempArray)
        //const [a,b,c,...remaining]=data1
        //const tempArray=(deleteEdit(remaining,editData,idKey))
        //setData1([a,b,c,...tempArray])


        if(effectOfBranch){
            const [a,b,c,...remaining]=effectOfBranch
            const tempArray=(deleteEdit(remaining,editData,idKey))
            setEffectOfBranch([a,b,c,...tempArray])
        }
    }
}

const insertLine=()=>{
    /*
    effectEndBillId: null
    effectEndBillName: "จำนวน"
    effectId: 1
    effectName: "กระทบสต็อก"
    _id: "6288fc30db166f001321870a"
    */

    /*
    endBillActive: true
    endBillEffect: "+"
    endBillId: 1
    endBillInPercentage: true
    endBillInPercentageOf: "total"
    endBillName: "vat7%"
    endBillRate: 7
    _id: "6288f99cdb166f0013217713"
    */
    //console.log('insertLine....')

    let tempEffectId=Date.now()
    let tempEndBillId=Date.now()

    let tempBlankData1={...blankData,
        selectedLine:false,
        _id:uuid(),
    }

    if(key=="effectType"){
        let tempArray=[]
        data.map(j=>{
            tempArray=[...tempArray,j.effectId]
        })
        
        const tempArray2=tempArray.sort()

        tempEffectId=tempArray2[tempArray2.length-1]+1
        //tempEffectId=100

        tempBlankData1={...tempBlankData1,effectId:tempEffectId}
    }
    if(key=="endBill"){
        let tempArray=[]
        data.map(j=>{
            tempArray=[...tempArray,j.endBillId]
        })
        
        const tempArray2=tempArray.sort()

        tempEndBillId=tempArray2[tempArray2.length-1]+1
        //tempEndBillId=100

        tempBlankData1={...tempBlankData1,endBillId:tempEndBillId}
    }

    const tempBlankData2={
        selectedLine:false,
        _id:uuid(),
        effectId:tempEffectId,
        effectAmount:0
    }

    if(editData){

        let tempArray

        /*
        if(key=="effectType"){
            const [a,b,c,...remaining]=data1
            tempArray=[a,b,c,...insertAfterEdit(remaining,editData,idKey,tempBlankData1)]
        }
        else{
            tempArray=insertAfterEdit(data1,editData,idKey,tempBlankData1)
        }
        */
        tempArray=[...data1,tempBlankData1]
        
        setData1(tempArray)

        if(effectOfBranch){
            //const [a,b,c,...remaining]=effectOfBranch
            //const tempArray=(insertAfterEdit(remaining,editData,idKey,tempBlankData2))
            //setEffectOfBranch([a,b,c,...tempArray])
            setEffectOfBranch([...effectOfBranch,tempBlankData2])
        }

        //console.log(tempArray)
    }

    else{
      setData1([...data1,tempBlankData1])
      if(effectOfBranch){
        setEffectOfBranch([...effectOfBranch,tempBlankData2])
      }
    }
}

const updateFilterDataFunc=(idx,value)=>{
   //console.log('updateFilterDataFunc.............')
   //console.log(idx)
   //console.log(value)

   let tempArray=[]

   data1.map(i=>{
      if(i._id==editData._id){
         tempArray=[...tempArray,value]
      }
      else{
         tempArray=[...tempArray,i]
      }
   })
   setData1(tempArray)
}

const styleRight={position:"fixed",
  top:"3rem",right:"0.5rem",zIndex:"100",
  width:"",backgroundColor:"white",
  borderRadius:"10px",margin:""
}
const styleLeft={position:"fixed",
  top:"3rem",Left:"0.5rem",zIndex:"100",
  width:"",backgroundColor:"white",
  borderRadius:"10px",margin:""
}

const [swapStyleRight,setSwapStyleRight]=React.useState(true)

const renderToolBox=()=>{
    return(
        <div style={swapStyleRight?styleRight:styleLeft}>
            
            
            <div className="iconbox"
                 style={{height:"3.5rem"}}
                 onClick={e=>{
                    deleteLine()
                 }}
            >
                   <Ticon 
                   iconName="MdDelete"
                   className="lg-icon"
                   textStyle={{color:"black"}}
                />
            </div>

            <div className="iconbox"
                 style={{height:"3.5rem"}}     
                 onClick={e=>{

                    if(modalTableState.key!="effectType"){
                        insertLine()
                        setShowToolBox(false)
                    }
                 }}
            >
                <Ticon
                    iconName="MdAddCircle" 
                    className="lg-icon"
                    textStyle={{color:"black"}}
                    
                />
            </div>

            <div className="iconbox"
                 style={{height:"3.5rem"}} 
                 onClick={e=>{
                    moveUp()
                 }}
            >
                <Ticon
                    iconName="FaRegArrowAltCircleUp" 
                    className="lg-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            
            <div className="iconbox"
                 style={{height:"3.5rem"}} 
                 onClick={e=>{
                    moveDown()
                 }}
            >
                <Ticon
                    iconName="FaRegArrowAltCircleDown" 
                    className="lg-icon"
                    textStyle={{color:"black"}}
                />
            </div>

            <div className="iconbox"
                 style={{height:"3.5rem"}} 
                 onClick={e=>{
                    setSwapStyleRight(!swapStyleRight)
                }}
            >
                <Ticon
                    iconName="MdSwapHoriz" 
                    className="xl-icon"
                    textStyle={{color:"black"}}
                />
            </div>
           
            <div className="iconbox"
                 style={{height:"3.5rem"}} 
                 onClick={e=>{
                    setEditData(null)
                    setShowToolBox(false)
                    //updateData(billFormData)
                }}
            >
                <Ticon 
                    iconName="MdClose" 
                    className="lg-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            
        </div>
    )
}


return(

<div className="Modal-background">
       
       <div className="Modal-box" style={{minWidth:"70%",minHeight:"40%"}}>
         
           <div className="Modal-header">
               <h5>{pageForm.formHead}</h5>
           </div>

           <div className="flex-center-center w-80"
                style={{width:"100%"}}
           >
             
              {
               <Table
                        tableTemplate={tableTemplate1}
                        setTableTemplate={setTableTemplate1}//{setTableTemplate}
        
                        filterData={data1}
                        setFilterData={()=>{}}//{setFilterDataData0}
                        
                        editData={editData}
                        setEditData={setEditDataFunc}
                        saveTableTemplateFunc={saveTableTemplateFunc}
                        isSubTable={false}
                        updateFilterData={updateFilterDataFunc}
                        useInput={true}
                        basicData={basicData}
                        pageData={pageData.tableSettingModal}
               />
              }
           </div>

            {showToolBox
               //?editData
                ?renderToolBox()   
                :null
               //:null
            }    


           <div className="Modal-footer">
               <div>
                   <button
                       onClick={e=>{
                           submitFunc(data1,key,effectOfBranch)
                       }}
                    
                   >
                       <FaCheck/>
                   </button>
               </div>
               <div>
                   <button
                       onClick={e=>{
                          cancelFunc()
                          setShowToolBox(false)
                       }}
                   >
                       <FaBan/>
                   </button>
               </div>
           </div>
        </div>

</div>


)

}
export default ModalTable;


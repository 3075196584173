import React from 'react';
import axios from '../../util/axiosVerify'
import {MainContext} from '../../context/MainContext'
import uuid from 'react-uuid'
import ModalFilterInput from '../../render/renderFilter/ModalFilterInput'
import pageUtil from './pageUtil'
//import NewGroupComponent from '../../render/renderTree/NewGroupComponent'
import axiosUtil from '../../util/axiosUtil'
import Table from '../../component/table/Table'
import renderWidthRangeBar from '../../component/table/renderWidthRangeBar'
import renderBadge from '../../render/renderBadge/renderBadge'

//import ModalForm from '../../render/renderForm/ModalForm'
//import NewTree from '../../component/newtree/NewTree'
import NewGroup from '../../component/newgroup/NewGroup'
import console from '../../util/consoleVerify'
import renderGrid from '../../render/renderForm/renderGrid'


const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,
    catchErrorToMessage}=axiosUtil

const {reloadAxiosAll,saveTableTemplate,updateFilterData,
    submitFunctionEdit,submitFunctionAdd,filterAxios,
    setUnSelectAll,submitFunctionDelete,
    genCsv,genXlsx
} = pageUtil

function ModalProductPageComponent(props) {

//console.log('ModalProductPageComponent')
//const {basicDataSt,tokenSt,setReloadCheckToken,myheader}=React.useContext(MainContext)

const {filterDataTemplate,

      pageFilter,pageFilterForm,...remaining}=props

const {filterTitle,
      productInputState,

      filterTemplate,
      myheader,
      basicDataSt,
      stateTemplate,
    
      bgColor,
      calDigit,
      setReloadBasicData,
      addSelectProductFunc,
      addSelectProductFunc2,
      setCloseModalProduct
}=props

const {basicData,pageData,tableTemplate,reloadBasicData}=basicDataSt

const { mainContextModalProduct,setMainContextModalProduct,
        mainContextModalProduct2,setMainContextModalProduct2
}=React.useContext(MainContext)
//===============================

const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])
//==============================
const defaultFilterData={...filterDataTemplate,
    limitRow:basicData.limitRow,
    badgeState:{...filterDataTemplate.badgeState,
                    closeFunc:setCloseModalProduct,
                },
    tableTemplate:tableTemplate.productTableTemplateForSticker,
    editData:null,
    showModalFilterSelectEdit:true,
    reloadModalFilterSelectEdit:false,
    inputState:productInputState,
    swapState:basicData.showTable,
        
}

const defaultFilterData2={
    badgeState:{
        swapShow:false,swapFunc:()=>{},
        reloadShow:false,reloadFunc:()=>{},
        filterShow:false,filterFunc:()=>{},
        addShow:false,addFunc:()=>{},
        editShow:false,editFunc:()=>{},
        delShow:false,delFunc:()=>{},
        printerShow:false,printerFunc:()=>{},
        csvShow:false,csvFunc:()=>{},
        forwardShow:true,forwardFunc:()=>{},
        bullEyeShow:true,
        unSelectShow:true,
        closeShow:false,
    },
    data0:null,
    editData:null,
    tableTemplate:tableTemplate.productTableTemplateForSticker,
    selectProduct:[]
}
//------------------------------

const genFilterData=()=>{
    if(mainContextModalProduct){
        return mainContextModalProduct
    }
    else {
        return defaultFilterData
    }
}


const genFilterData2=()=>{
    if(mainContextModalProduct2){
        return mainContextModalProduct2
    }
    else {
        return defaultFilterData2
    }
}

//==============================
let [filterData,setFilterData]=React.useState(genFilterData())

const [filterData2,setFilterData2]=React.useState(genFilterData2())

//===============================

React.useEffect(()=>{
    //console.log('UserEffect ..... filterData PageComponent')
    //console.log(filterData)

    if(filterData.reloadData){
        const {dataUrl,pageNumber,limitRow,sort,qry}=filterData
    
        axios.post(`/${dataUrl}/getlimit`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
        .then(result=>{
            //console.log('result......')
            //console.log(result.data.data)
            const temp2= genFilterDataWithIndex(result.data.data,filterData.selectProduct)
 
            const tempResult={...filterData,
                data0:temp2,
                count:result.data.count,
                lastRecordId:result.data.lastRecordId,
                isCsv:false,
                isXlsx:false,
                reloadData:false,
                
            }
            setFilterData(tempResult)
        })
        .catch(error=>{
            const tempError={...filterData,
                reloadData:false,
                message:catchErrorToMessage(error),
                showModalConfirm:false,
                showModalError:true,
            }
            //console.log(tempError.message)
            setFilterData(tempError)
        })
    }

},[filterData])
//=================================

React.useEffect(()=>{
    if(filterData.reloadModalFilterSelectEdit){
        setFilterData({
            ...filterData,
            showModalFilterSelectEdit:true,
            reloadModalFilterSelectEdit:false
        })
    }
},[filterData.reloadModalFilterSelectEdit])

//===============================

const closeFunc=()=>{
    //console.log('closeFunc')
    setMainContextModalProduct(filterData)
    setMainContextModalProduct2(filterData2)
}

const closeFunc2=()=>{
    //console.log('closeFunc2')
}

const setShowRange=(data)=>{setFilterData({...filterData,showRange:data})}
const setWidthLeft=(data)=>{setFilterData({...filterData,widthLeft:data})}

const setFilterDataData0=(data)=>{setFilterData({...filterData, data0:data})}
const setFilterData2Data0=(data)=>{
    setFilterData2({...filterData2, data0:data})
}

const setSort=(data)=>{setFilterData({...filterData,sort:data})}
const setLimitRow=(data)=>{setFilterData({...filterData,limitRow:data})}
const setInputState=(data)=>{
    setFilterData({
        ...filterData,
        inputState:data
    })
}

const setIsCsv=(data)=>{
    if(data){
        setFilterData({...filterData,isCsv:true,isXlsx:false})
    }
    else{
        setFilterData({...filterData,isCsv:false})
    }
}
const setIsXlsx=(data)=>{
    if(data){
        setFilterData({...filterData,isXlsx:true,isCsv:false})
    }
    else{
        setFilterData({...filterData,isXlsx:false})
    }
}

const setPageNumber=(data)=>{setFilterData({...filterData,pageNumber:data,reloadData:true})}

const setTableTemplate=(data)=>{setFilterData({...filterData,tableTemplate:data})}
const setSelectGroupFunc=(data)=>{
    setFilterData({...filterData,selectGroup:data})
}


const setSwapState=(data)=>{
    //console.log('setSwapState')
    //console.log(data)
    setFilterData({
        ...filterData,
        swapState:data
    })
    
}



const updateFilterDataFunc=(index,i)=>{
    //console.log('updateFilterDataFunc')
    const temp=updateFilterData(index,i,filterData)
    //console.log('temp')
    //console.log(temp)

    setFilterData({...filterData,...temp})
}

const updateFilterData2=(index,i)=>{
    //<LineForm/>   onBlur => updateFilterData

    //console.log('updatFilterData2....')
    //console.log(i)

    //console.log('updataFilterData3....')
    //console.log(data3)
    //console.log(selectProduct)
    //console.log('i...........')
    //console.log(i)

    let tempArray=[] //for update  data0 with... i of LineForm
    filterData2.data0.map(j=>{
        if(j.id==i.id){
            tempArray=[...tempArray,{...i,stickerQty:parseInt(i.stickerQty)} ]
        }   
        else{
            tempArray=[...tempArray,j]
        }
    })
    //get i from <LineForm/>
    //console.log('tempArray')
    //console.log(tempArray)
    //update selectProduct with LineForm as well 
    
    let isMatch=false
    let tempArray2=[]

    filterData2.selectProduct.map(k=>{
        if(k.id==i.id){
            tempArray2=[...tempArray2,{...i,stickerQty:parseInt(i.stickerQty)} ]
            isMatch=true
        }
        else {
            tempArray2=[...tempArray2,k]
        }
    })
    
    if(isMatch==false){
        tempArray2=[...tempArray2,{...i,stickerQty:parseInt(i.stickerQty)}]
    }

    return { data0:tempArray,
             selectProduct:tempArray2}
    
    //setFilterData({...filterData,
    //    data0:tempArray,
    //    selectProduct:tempArray2
    //})
    
}


const updateFilterDataFunc2=(index,i)=>{
    //console.log('updateFilterDataFunc')
    //console.log(i)
    const temp=updateFilterData2(index,i)
    //console.log('temp....')
    //console.log(temp)

    setFilterData2({...filterData2,
                    data0:temp.data0,
                    selectProduct:temp.selectProduct
                  })
    //setFilterData({...filterData,selectProduct:[...filterData.selectProduct,temp.selectProduct]})
}

const setEditData2Func=(data)=>{
    setFilterData2({
        ...filterData2,
        editData:data
    })
}

const saveTableTemplateFunc=(tableTemplate)=>{
    //const {tableTemplateUrl}=filterData
    //saveTableTemplate(tableTemplate,tableTemplateUrl,
    //filterData.tableTemplateName,myheader,shouldRender)

    saveTableTemplate(tableTemplate,'p35branch',
        "productTableTemplateForSticker",myheader,shouldRender)    
}

const setUnSelectAllFunc=()=>{
    setFilterData(setUnSelectAll(filterData))
}

const setUnSelectAllFunc2=()=>{
    setFilterData2(setUnSelectAll(filterData2))
}


const setEditDataFunc=(data)=>{

        let tempData=data

        axios.post(`/p35product/getlimit`,
            { pageNumber:1,
            limitRow:300,
            sort:{id:1},
            groupId:data.groupId
            },
        myheader,shouldRender)
        .then(result=>{

            const temp3=genFilterDataWithIndex(result.data.data,filterData2.selectProduct)

            setFilterData2({...filterData2,
                data0:temp3
            })
            setFilterData({...filterData,
                editData:tempData
            })
        
        })

        .catch(error=>{
            setFilterData({...filterData,
                editData:tempData
            })
        })

    
    //setFilterData({...filterData,
    //    editData:tempData,
        //showModalFilterSelectEdit:false,
        //reloadModalFilterSelectEdit:true,
        //inputState:{
        //    ...filterData.inputState,
        //    groupId:{...filterData.inputState.groupId,
        //            min:parseInt(data.groupId),
        //            max:parseInt(data.groupId),
        //        },
        //    groupName:{...filterData.inputState.groupName,
        //            value:data.groupName,
        //        },       
        //},
    //})
}

const addSelectProduct=()=>{
    //console.log('addSelectProduct......')
    //console.log('setShow..................................')
    //console.log(filterData.selectProduct)
    addSelectProductFunc(filterData.selectProduct)

    setMainContextModalProduct(filterData)
}

const addSelectProduct2=()=>{
    //console.log('addSelectProduct2......')
    //console.log('setShow..................................')
    //console.log(filterData.selectProduct)
    addSelectProductFunc2(filterData2.selectProduct)
    const temp=setUnSelectAll(filterData2)

    setFilterData2(temp)

    //setMainContextModalProduct2(temp)
}
//=============================================
//=============================================
const refreshPage=()=>{
    //console.log('refreshPage')
    
    const {limitRow,sort}=filterData
    const temp = {...filterData,limitRow,sort,selectProduct:[],
                editData:null,qry:{active:true},reloadData:true
            }
    //setFilterD1Func(temp)
    //setReloadBasicData(true)
    setFilterData({...filterData,reloadData:true})
}
//=============================================
//=============================================
const genFilterDataWithIndex=(filterData,selectProduct)=>{

    if(filterData){
        if(filterData.detail){
            if(filterData.detail.length()>0){
                let tempArray=[]

                filterData.detail.map(i=>{

                })

            }
        }
    }

    let tempSelectProduct
    if(selectProduct){tempSelectProduct=selectProduct}
    else {tempSelectProduct=[]}

    let tempArray=[]

    filterData.map((i,idx)=>{
         
        let tempStocking={}

        if(i.stocking){
            let isSearching=true
            i.stocking.map(k=>{
                if(isSearching){
                    if(k.branchId==basicData.branchId){
                        tempStocking={
                            stock:k.stock,
                            minStock:k.minStock,
                            maxStock:k.maxStock,
                            storage:k.storage
                        }
                        isSearching=false
                    }
                }   
            })
        }


        const temp={...i,stickerQty:0,selectedLine:false,_id:uuid(),...tempStocking}
        tempArray=[...tempArray,temp]
        //const temp={...i,tempIndex:idx,selectedLine:false}
    })

    let tempArray2=[]

    tempArray.map(i=>{
        let tempObj=i
        tempSelectProduct.map(j=>{
            if(i.id==j.id){
                //console.log('j.id==i.id')
                //tempObj={...tempObj,selectedLine:j.selectedLine}
                tempObj=j
                //tempArray2=[...tempArray2,]
            }
        })
        tempArray2=[...tempArray2,tempObj]
    })
 
    return tempArray2
}
//=============================================

//{pageNumber,limitRow,sort,dataUrl} = filterData
const filterAxiosFunc=(option,inputState)=>{
    //console.log('filterAxiosFunc..............')
    //console.log('option')
    //console.log(option)
    //console.log('inputState')
    //console.log(inputState)
    //console.log(filterTemplate)
    filterAxios(option,inputState,filterTemplate,
                filterData,null,myheader,
                shouldRender)
    .then(result=>{
        //console.log("result.....")
        //console.log(result)
        
        const temp2= genFilterDataWithIndex(result.data0,
            filterData.selectProduct)
        //console.log('result......')
        //console.log(result)
        if(filterData.isCsv)
        {
            setFilterData({...filterData,
                isCsv:false,
                data0:null
            })
            genCsv({...result})
        }
        else if(filterData.isXlsx){
            setFilterData({...filterData,
                isXlsx:false,
                data0:null
            })
            genXlsx({...result})
        }
        else{
            setFilterData({...result,
                data0:temp2
            })
        }
    })
    .catch(error=>setFilterData(error))
}

const filterDataByGroup=(i)=>{
    //console.log('filterDataByGroup.........')
    //console.log(i)
    //const {folders,...remaining}=i
    const temp = {...filterData,
        pageNumber:1,
        qry:{groupId:i.id},
        //selectGroup:remaining,
        reloadData:true}
    setFilterData(temp)
}
//===============================
const renderTable=()=>{

    //console.log('renderTable')
    //console.log(filterData)

    return (
            filterData.data0&&filterData.tableTemplate
            ?<Table
                colorHead={"#4b6d62"}
                tableTemplate={filterData.tableTemplate}
                setTableTemplate={setTableTemplate}

                filterData={filterData.data0}
                setFilterData={setFilterDataData0}
                
                editData={filterData.editData}
                setEditData={setEditDataFunc}
                saveTableTemplateFunc={saveTableTemplateFunc}
                isSubTable={false}
                updateFilterData={updateFilterDataFunc}
                useInput={false}
                
            />
            :null
    )
}

//===============================
const renderTable2=()=>{
    return  filterData2.data0&&
            <Table
                colorHead={"#615550"}
                tableTemplate={filterData2.tableTemplate}
                setTableTemplate={()=>{}}//{setTableTemplate}

                filterData={filterData2.data0}
                setFilterData={setFilterData2Data0}
                
                editData={filterData2.editData}
                setEditData={setEditData2Func}
                saveTableTemplateFunc={saveTableTemplateFunc}
                isSubTable={false}
                updateFilterData={updateFilterDataFunc2}
                useInput={false}
                
            />
    
}

//===============================
const renderRight=(filterData)=>{
    //console.log('renderRight')
    //console.log(renderRight)

    return(
    <div className="w-100 h-100">
        <div style={{height:"2rem",width:"100%"}}
        >
            {
                    renderBadge({
                        filterData,
                        setPageNumber:setPageNumber,
                        setSwapState:setSwapState,
                        totalSwapPage:1, 
                        setReloadData:refreshPage,
                        setUnSelectAll:setUnSelectAllFunc,
                        setClose:closeFunc,
                        bgColor:bgColor,
                        setShowForwardConfirm:addSelectProduct,
                        captureSelect:()=>{}//captureSelect
                    })        
            }

        </div>

        <div className=""
                style={{width:"100%",height:"95%"}}
        >
            {   filterData.swapState
                ?renderTable()
                :renderGrid(filterData,tableTemplate,setEditDataFunc)
            }
        </div>
    </div>
    )
}
//=============================
//=============================
const renderGroup=()=>{

    return (
    <div className="w-100" 
         style={{ooverflowX:"hidden",
                 paddingBottom:"5rem"}}>
        {filterData.showModalFilterSelectEdit&&
           renderFilter()
        }
       
        {
        filterData.groupDataUrl&&pageData
        ?<div className="" style={{margin:"1rem 0"}}>
            <NewGroup
                myheader={myheader}
                dataUrl={filterData.groupDataUrl}
                filterByGroupId={filterDataByGroup}
                captureEditGroup={setSelectGroupFunc}
                bgColor={bgColor}
                pageData={pageData}
                basicData={basicData}
                editData={filterData.editData}
            />
        </div>
        :null
        }

        <div style={{width:"99%",height:"50vh"}}>
            <div style={{height:"2rem",width:"100%"}}
            >
                {
                        renderBadge({
                            filterData:filterData2,
                            setPageNumber:()=>{},//setPageNumber,
                            totalSwapPage:1, 
                            setReloadData:()=>{},
                            setUnSelectAll:setUnSelectAllFunc2,
                            setClose:closeFunc2,

                            bgColor:bgColor,
                            setShowForwardConfirm:addSelectProduct2,
                            captureSelect:()=>{}//captureSelect
                        })        
                }

            </div>




            {renderTable2()}
        </div>

    </div>
    )
}

//==============================
const renderBody=()=>{
    return(
        filterData&&
        <div className=""
                 style={{display:"flex",height:"95%",width:"100%"}}
        >
                
                <div className="hide-on-printX" 
                     style={{height:"100%",
                             width:`${filterData.widthLeft}%`,
                             overflowY:"hidden",
                             overflowX:"hidden"
                             }}>
                         {
                           renderGroup()
                         }
                </div>
            
                <div className="" 
                    style={{height:"100%",width:`${100-filterData.widthLeft}%`}}>
                     {
                       renderRight(filterData) 
                     }
                </div>
        </div>
    )
    }
    //===============================
    //===============================


//================================
//=================================
const renderFilter=()=>{
    const {limitRow,sort,isCsv,isXlsx}=filterData
    return (
        <div className="" style={{marginBottom:"1rem"}}>
            <ModalFilterInput
            show={false} setShow={()=>{}}
            
            filterTemplate={filterTemplate}

            inputState={filterData.inputState} 
            setInputState={setInputState}
            
            limitRow={limitRow} 
            setLimitRow={setLimitRow}

            isCsv={isCsv}
            setIsCsv={setIsCsv}
            
            isXlsx={isXlsx}
            setIsXlsx={setIsXlsx}
            

            sort={sort} 
            setSort={setSort}
            
            filterAxios={filterAxiosFunc}
            basicData={basicData}
            
            LB={pageFilterForm}
            />
        </div>
    )
}
//=================================
return(
<div className="Modal-background">
    <div className="Modal-box" 
         style={{paddingBottom:"1rem",width:"95%",height:"90%"}}>
        
        <div className="" 
            style={{width:"100%",display:"flex",position:"relative"}}>
                {
                renderWidthRangeBar({showRange:filterData.showRange,
                                    setShowRange:setShowRange,
                                    widthLeft:filterData.widthLeft,
                                    setWidthLeft:setWidthLeft,
                                    //heightTop:filterData.heightTop,
                                    //setHeightTop:setHeightTop
                                })
                }
    
        </div>
        
        {
        /*
        <div className="Modal-header">
            {
              //renderFooter()
            }
        </div>
        */
        }

        <div className="Modal-body" >
            <div className="ModalInsideBody">
            {
              renderBody()
            }
            </div>
        </div>
        <div className="" 
            style={{width:"100%",display:"flex",position:"relative"}}>
                {
                renderWidthRangeBar({showRange:filterData.showRange,
                                    setShowRange:setShowRange,
                                    widthLeft:filterData.widthLeft,
                                    setWidthLeft:setWidthLeft,
                                    //heightTop:filterData.heightTop,
                                    //setHeightTop:setHeightTop
                                })
                }
    
        </div>
    </div>
</div>
)

}




export default ModalProductPageComponent ;

import React from 'react';
import './RepeatedCalendar.css';
import console from '../../util/consoleVerify'

import {FaRegCalendarAlt,FaCheck,FaBan} from 'react-icons/fa';
import {MdClose} from 'react-icons/md';
import {BsArrowRight,BsClock} from 'react-icons/bs'

//==================
function RepeatedCalendar(props) {

const {
       onMyClick,
       value,
       style,
       styleIcon,
       showDateOption,
       days,
       thaiMonths,
       basicData
       //useTimeSetting  
    }=props
//value id isostring

/*
const thaiMonths=[
    "มค","กพ","มีค","เมย","พค","มิย",
    "กค","สค","กย","ตค","พย","ธค"
]

const thaiMonths=[
    "01","02","03","04","05","06",
    "07","08","09","10","11","12"
]
*/
/*
const days=[
    "Su","Mo","Tu","Wd","Th","Fr","Sa"
]
*/

/*
const datePattern=/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/gi

const valDate= (pttn,str)=>{
    return new RegExp(pttn).test(str)
}
*/
const temp59=[
               0,1,2,3,4,5,6,7,8,9,
               10,11,12,13,14,15,16,17,18,19,
               20,21,22,23,24,25,26,27,28,29,
               30,31,32,33,34,35,36,37,38,39,
               40,41,42,43,44,45,46,47,48,49,
               50,51,52,53,54,55,56,57,58,59,
             ]
const temp24=[
                0,1,2,3,4,5,6,
                7,8,9,10,11,12,
                13,14,15,16,17,18,
                19,20,21,22,23,24
            ]
const temp15=[
              0,15,30,45
            ]
/*
const convertNewDateToString=(newDate)=>{
        const temp =newDate.toString()

        let tempMonth=temp.substring(4,7)
        if(tempMonth=="Jan"){tempMonth="01"}
        else if(tempMonth=="Feb"){tempMonth="02"}
        else if(tempMonth=="Mar"){tempMonth="03"}
        else if(tempMonth=="Apr"){tempMonth="04"}
        else if(tempMonth=="May"){tempMonth="05"}
        else if(tempMonth=="Jun"){tempMonth="06"}
        else if(tempMonth=="Jul"){tempMonth="07"}
        else if(tempMonth=="Aug"){tempMonth="08"}
        else if(tempMonth=="Sep"){tempMonth="09"}
        else if(tempMonth=="Oct"){tempMonth="10"}
        else if(tempMonth=="Nov"){tempMonth="11"}
        else if(tempMonth=="Dec"){tempMonth="12"}

        const tempDate=temp.substring(8,10)
        const tempYear=temp.substring(11,15)

        const tempNewDate=`${tempYear}-${tempMonth}-${tempDate}`

        return tempNewDate
}
*/

/*
const convertISOtoLocale=(date)=>{
    const date1=new Date(date).toISOString()
    const date2=new Date(date1).toLocaleString('fr-ca')
    const date3=new Date(date1).toLocaleString('en-GB')
    const date4=date2.substring(0,10)+'T'+date3.substring(12,date3.length)
    return date4
}
*/

/*
const convertLocaleToISO=(date)=>{
    const date5=new Date(date).toISOString()
    return date5
}

const genCurrentDate=()=>{
    const date1=new Date().toISOString()
    //2021-10-29T05:43:33.516Z
    const date4=convertISOtoLocale(date1)
    
    return new Date(date4)
}
*/

const [selectDate,setSelectDate]=React.useState([])
const [monthYear,setMonthYear]=React.useState({month:null,year:null})
const [activeDate,setActiveDate]=React.useState([])

const [activity,setActivity]=React.useState(null)

let [date,setDate]=React.useState(new Date())
const [inputState,setInputState]=React.useState({
    startHour:0,
    startMinute:0,
    endHour:24,
    endMinute:0
})

React.useEffect(()=>{
    console.log('inputState')
    console.log(inputState)
},[inputState])


React.useEffect(()=>{
    //console.log('date.....mmmmmmmm...')
    //console.log(date)

        const thisMonth=date.getMonth()+1
        const thisYear=date.getFullYear()
    
        //console.log('ssss')
    
        if(thisMonth!=monthYear.month||thisYear!=monthYear.year){
                const lastDate=new Date(date.getFullYear(),date.getMonth()+1,0).getDate()
                
                let unqDateArray=[]
                let thisMonthActivity=[]
    
                for (let d=1;d<=lastDate;d++){
    
                        const temp=new Date(date).setDate(d)
                        const tempDay=new Date(temp).getDay()
                                //let tempActive=false
                        let tempActiveDay=false
    
                        basicData.activeDay.map(atd=>{
                            if(!tempActiveDay){
                                if(atd.day==tempDay){
                                    tempActiveDay=true
                                }
                            } 
                        })
    
                        basicData.holiday.map(hld=>{
                            if(hld.date==d&&hld.month==thisMonth){
                                tempActiveDay=false
                            }
                        })
                        
                        const tempObj={date:d,active:tempActiveDay}
    
                        if(tempActiveDay){
                            unqDateArray=[...unqDateArray,d]
                        }
                        thisMonthActivity=[...thisMonthActivity,tempObj]
    
                }
                
                setActivity(thisMonthActivity)
                setMonthYear({month:thisMonth,year:thisYear})
                setActiveDate(unqDateArray)
        }
    

},[date])

const [showCalendar,setShowCalendar]=React.useState(false)

const getTimeArray=()=>{
    let tempArray=[]
    //console.log(inputState)
    if(selectDate.length>0){
        let tempStartDate
        let tempEndDate
        selectDate.map(i=>{
            //console.log(i)
            const temp=i.split("-")
            const tempDate=temp[0]
            const tempMonth=temp[1]
            const tempYear=temp[2]

            //console.log(temp)

            tempStartDate=new Date(tempYear,tempMonth,tempDate)
            tempStartDate=tempStartDate.setHours(inputState.startHour,inputState.startMinute,0)
            tempStartDate=new Date(tempStartDate).toISOString()
            
            tempEndDate=new Date(tempYear,tempMonth,tempDate)
            tempEndDate=tempEndDate.setHours(inputState.endHour,inputState.endMinute,0)
            tempEndDate=new Date(tempEndDate).toISOString()

            tempArray=[...tempArray,tempStartDate,tempEndDate]

        })
    }
    //console.log('inputState.startDate')
    //console.log(tempStartDate)
    //console.log('inputState.endDate')
    //console.log(tempEndDate)
    
    return tempArray

}


const checkActiveDate=(i)=>{

    let temp="0.2"

    let keepCheck=true
    activeDate.map(k=>{
        if(keepCheck){
            if(k==i){
                temp="1"
                keepCheck=false
            }
        }
    })
    return temp
}



const checkDateFunc=(i)=>{
    //console.log(`checkDateFunc =${i}`)
    
    let temp=date.setDate(i) 
    temp=new Date(temp)
    setDate(temp)

    const tempYear=date.getFullYear()
    const tempMonth=date.getMonth()
    const tempDate=i

    const temp7=`${tempDate}-${tempMonth}-${tempYear}`
    //console.log(temp7)
    
    let thisIsNewDate=true
    let removeArray=[]

    selectDate.map(sd=>{
        if(sd==temp7){
            thisIsNewDate=false
        }
        else{
            removeArray=[...removeArray,sd]
        }
    })

    let tempArray

    if(thisIsNewDate){
        tempArray=[...selectDate,temp7]
    }else{
        tempArray=removeArray
    }

    //console.log(`thisIsNewDate = ${thisIsNewDate}`)
    //console.log('tempArray')
    //console.log(tempArray)

    return tempArray
    
}


const checkDateFunc2=(i)=>{
    const tempYear=date.getFullYear()
    const tempMonth=date.getMonth()
    const tempDate=i
    const temp7=`${tempDate}-${tempMonth}-${tempYear}`

    let foundDate=false

    selectDate.map(sd=>{
        if(sd==temp7){
            foundDate=true
        }
    })

    return foundDate

}   


const getSelectedDateThisMonth=(i,date)=>{

    let temp=date.setDate(i)
    temp=new Date(temp)

    setDate(temp)

    onMyClick(temp.toISOString())

    //const dateUK="2021-12-31T18:50:41.750Z"
    //Sat Jan 01 2022 01:50:41 GMT+0700 (Indochina Time)
}

const genThaiFullDate=(value)=>{
    //console.log('genThaiFullDate')
    //console.log(value)
    if(value){
        //const shortDate = value.substring(0,10)
        //if(valDate(datePattern,shortDate)){
        let tempDate=new Date(value)
        
       
        const date2=tempDate.toLocaleString('en-GB')

        if(showDateOption=="date"){
            return date2.substring(0,10)
        }
        else if(showDateOption=="datetime"){
            return date2
        }
        else if(showDateOption=="time"){
            return date2.substring(12,21)
        }
        else{
            return '--'
        }
        //}
        //else{
        //}
    }

    else {
        return '--'
    }
    /*
        const thaiDays=[
            "อา","จ","อ","พ","พฤ","ศ","ส"
        ]
       
        const thaiMonth=thaiMonths[date.getMonth()]
        const thaiDay=thaiDays[date.getDay()]
        const thaiYear=date.getFullYear()+543
        const thaiDate=date.getDate()
        const temp=`${thaiDay} ${thaiDate} ${thaiMonth} ${thaiYear}`
        return temp
        */
}

const genThaiMonth=(date)=>{

    const thaiMonth=thaiMonths[date.getMonth()]
    const thaiYear=date.getFullYear()//+543

    const temp=`${thaiMonth}-${thaiYear}`
    return temp
}

const genPrevMonthDay=(date)=>{
    const lastFullDayPrevMonth=new Date(date.getFullYear(),date.getMonth(),0)
    const lastDayPrevMonth=lastFullDayPrevMonth.getDay()
    const lastDatePrevMonth=lastFullDayPrevMonth.getDate()

    let prevMonthDay =[]
    for (let i=lastDatePrevMonth-lastDayPrevMonth;i<=lastDatePrevMonth;i++){
        prevMonthDay=[...prevMonthDay,i]
    }
    return prevMonthDay
}

const genThisMonthDay=(date)=>{
    const lastDate=new Date(date.getFullYear(),date.getMonth()+1,0).getDate()
    let thisMonthDay=[]
    for (let j=1;j<=lastDate;j++){
        thisMonthDay=[...thisMonthDay,j]
    }
    return thisMonthDay
}

const genNextMonthDay=(date)=>{
    const lastFullDayThisMonth=new Date(date.getFullYear(),date.getMonth()+1,0)
    const lastDayThisMonth=lastFullDayThisMonth.getDay()

    let nextMonthDay=[]
    for (let k=1;k<=6-lastDayThisMonth;k++){
        nextMonthDay=[...nextMonthDay,k]
    }
    return nextMonthDay
}
//==================================
const renderSelect=(array,key,dft)=>{
    return(
        <select
            onChange={e=>{
                setInputState({...inputState,[key]:e.target.value})
            }}
        >
            <option>{dft}</option>
            {                            
            array.map((u,idxU)=>{
                    return(
                        <option key={idxU} value={u}>{u}</option>
                    )
                })
            }
        </select>
    )
}

//==================================
return (
    !showCalendar
    ?<div style={{width:"100%",height:"100%",backgroundColor:"white",
                  display:"flex",justifyContent:"flex-start",alignItems:"center",
                  borderRadius:"5px 5px",
                  ...style
    }}>
        <FaRegCalendarAlt className="sm-icon"
            style={styleIcon}
            onClick={e=>setShowCalendar(true)}
        />
        <div>{`x${selectDate.length}`}</div>
    </div>
    :<div className="container" style={{width:"100vw",height:"100vh"}}>

        <div className="calendar">
            <div className="month">
                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setFullYear(date.getFullYear()-1)
                        temp=new Date(temp)
                        setDate(temp)
                      }}
                 >
                    <i>&lt;&lt;</i>
                </div>


                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setMonth(date.getMonth()-1)
                        temp=new Date(temp)
                        setDate(temp)
                      }}
                 >
                    <i>&lt;</i>
                </div>


                <div className="date">
                    <div>{genThaiMonth(date)}</div>
                </div>
               
                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setMonth(date.getMonth()+1)
                        temp=new Date(temp)
                        setDate(temp)
                      }}
                >
                    <i>&gt;</i>
                </div>

                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setFullYear(date.getFullYear()+1)
                        temp=new Date(temp)
                        setDate(temp)
                      }}
                >
                    <i>&gt;&gt;</i>
                </div>

                <FaCheck  className='md-icon'
                    style={{color:"green"}}
                    onClick={e=>{
                        onMyClick(getTimeArray())
                
                        setShowCalendar(!showCalendar)
                    }}
                />

                <FaBan className='md-icon'
                    style={{color:"brown"}}
                    onClick={e=>{
                        setSelectDate([])
                        setShowCalendar(!showCalendar)
                    }}
                />



            </div>



            {
            
            <div className="weekdays">
                {
                    days.map((i,index)=>{
                        return(
                            <div key={index}>{i}</div>
                        )        
                    })
                }
            </div>
            
            }

            {
            
            <div className="days">
                
                {
                genPrevMonthDay(date).map((i,index)=>(
                    <div key={index} className="prev-date">
                        {i}
                    </div>
                ))
                }

                {
                genThisMonthDay(date).map((i,index)=>{
                    const temp=checkActiveDate(i)

                return(
                    <div style={{
                            cursor:"pointer",
                            //opacity:temp,
                            //border:"1px solid white",
                            //backgroundColor:temp=="1"?"#A0DAA9":null
                        
                        }}
                         key={index} 
                         className={checkDateFunc2(i)?"today":null}
                         onClick={e=>{
                            //getSelectedDateThisMonth(i,date)
                            //setShowCalendar(false)
                            setSelectDate(checkDateFunc(i))
                        }}
                    >
                        <div className=''
                            style={{
                               color:temp=="1"?"#282D3C":"black",
                               opacity:temp
                            }}>
                                {i}
                        </div>
                    </div>
                    )
                })
                }
                
                {
                genNextMonthDay(date).map((i,index)=>(
                    <div key={index} className="next-date">
                        {i}
                    </div>
                ))
                }

            </div>   
            
            }

            {
             <div className="bd-black" 
                 style={{width:"100%",display:"flex",alignItem:"center",
                         justifyContent:"center",
                         background:"#999",marginTop:"0.2rem",
                         boxShadow:"0 0.5rem 0.3rem rgba(0,0,0,0.4)"
                         }}>
               

               <div style={{width:"5%",display:"flex",alignItems:"center"}}>
                    <BsClock style={{fontSize:"1.2rem"}}/>
                </div>
                <div style={{width:"40%"}}>

                    <div style={{display:"flex",margin:"0.5rem 0"}}>
                        <div style={{paddingLeft:"0.5rem",width:"40%"}}>
                            {renderSelect(temp24,"startHour","..hr(ชม)..")}
                        </div>
                        <div style={{paddingLeft:"0.5rem",width:"40%"}}>
                            {renderSelect(temp15,"startMinute","..min(นาที)..")}
                        </div>
                    </div>
                </div>

                <div style={{width:"5%",display:"flex",alignItems:"center"}}>
                    <BsArrowRight style={{marginLeft:"1rem",fontSize:"1.2rem"}}/>
                </div>

                <div style={{width:"40%"}}>

                    <div style={{display:"flex",margin:"0.5rem 0"}}>
                        <div style={{paddingLeft:"0.5rem",width:"40%"}}>
                            {renderSelect(temp24,"endHour","..hr..")}
                        </div>
                        <div style={{paddingLeft:"0.5rem",width:"40%"}}>
                            {renderSelect(temp15,"endMinute","..min..")}
                        </div>
                    </div>     
                </div>
                         
            </div>
            
            }
        </div>
       
    </div>
  );
}


RepeatedCalendar.defaultProps={
    onMyClick:()=>{},
    showDateOption:"date",
    timeOption:null,

    value:new Date().toISOString(),
    days:["Su/อา ","Mo/จ","Tu/อ","Wd/พ","Th/พฤ","Fr/ศ","Sa/ส"],
    thaiMonths:[
        "01","02","03","04","05","06",
        "07","08","09","10","11","12"
    ],
    useTimeSetting:false
}

export default RepeatedCalendar;


import React from 'react';

import './App2.css'

function Dump() {

return (
    <h5>
        Dump
    </h5>
)



}
export default Dump;

/*


const commonProduct={
  0 selectedLine           :
  { colOrder:1 ,lb:'_',     type:"radio", 
    width:40,   showCol:true,  showColHead:true,    
  },
  1 id            :
    { colOrder:2 ,lb:'ID',     type:"number",
      width:65,   showCol:true,  showColHead:true,    
    },
  2 barcode           :
    { colOrder:3 ,lb:'บาร์โคด',type:"string",
      width:65,   showCol:true,  showColHead:true,    
    },
  3 sn           :
    { colOrder:4 ,lb:'บาร์โคด',type:"string",
      width:65,   showCol:true,  showColHead:true,    
    },  
  4 productName      :
    { colOrder:5 ,lb:'ชื่อ',type:"string",
      width:100,   showCol:true,  showColHead:true,      
    },
  5 groupId      :
    { colOrder:6 ,lb:'ไอดีกลุ่ม',type:"number",
      width:70,   showCol:true,  showColHead:true,      
    },  
  6 groupName      :
    { colOrder:7 ,lb:'ชื่อกลุ่ม',type:"string",
      width:60,   showCol:true,  showColHead:true,      
    }, 
  7 unit      :
    { colOrder:8 ,lb:'หน่วย',type:"string",
      width:40,   showCol:true,  showColHead:true,      
    },
  8 price     :
    { colOrder:9 ,lb:'ราคา',     type:"number",
      width:60,   showCol:true,  showColHead:true,    
    },
}

const productTableTemplate={
...commonProduct,
9  isRawMat      ://0
  { colOrder:10 ,lb:'เป็นวัตถุดิบ',type:"boolean",
    width:100,   showCol:true,  showColHead:true,      
  },   
10  point      ://1
  { colOrder:11 ,lb:'แต้ม',type:"number",
    width:60,   showCol:true,  showColHead:true,      
  },  
11  stock      ://
  { colOrder:12 ,lb:'ยอดสต็อค',type:"number",
    width:60,   showCol:true,  showColHead:true,      
  },   
12  minStock      :
  { colOrder:13 ,lb:'ยอดสต็อคต่ำสุด',type:"number",
    width:60,   showCol:true,  showColHead:true,      
  }, 

13  maxStock      :
  { colOrder:14 ,lb:'ยอดสต็อคสูงสุด',type:"number",
    width:60,   showCol:true,  showColHead:true,      
  },

14  active      :
  { colOrder:15 ,lb:'แอคทีฟ',type:"boolean",
    width:100,   showCol:true,  showColHead:true,      
  },  
15  remark      :
  { colOrder:16 ,lb:'หมายเหตุ',type:"string",
    width:200,   showCol:true,  showColHead:true,      
  },  
16  photoUrl1      :
  { colOrder:17 ,lb:'รูป',type:"arrayPhoto",
    width:200,   showCol:true,  showColHead:true,
  },
}



*/
import React from 'react';
import ModalFilterInput from '../../render/renderFilter/ModalFilterInput'

import FilterTemplate from '../../render/renderFilter/FilterTemplate'
import pageUtil from '../../component/pageComponent/pageUtil'
import renderModalError from '../../render/renderModalError'
import Table from '../../component/table/Table'
import renderBadgeForBill from './renderBadgeForBill'
import axios from '../../util/axiosVerify'
import axiosUtil from '../../util/axiosUtil'
import console from '../../util/consoleVerify'
import renderGrid from '../../render/renderForm/renderGrid'


const {filterAxios,reloadAxiosAll,saveTableTemplate} =pageUtil
const {genArrayForFilterAxios,genFD,addFileNameInPhotoUrl,catchErrorToMessage}=axiosUtil

function PartnerForBill(props) {

const {basicDataSt,
       myheader,
       captureCustomer,
       partnerInputState,
       partnerForBillFilterData,
       setPartnerForBillFilterData
       }=props
const {basicData}=basicDataSt

//console.log('PartnerForBill')


const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

let tempPartnerTableTemplate=basicDataSt.tableTemplate.partnerTableTemplate
let tempSelectedLine=tempPartnerTableTemplate.selectedLine
    tempSelectedLine={...tempSelectedLine,showCol:false}
    tempPartnerTableTemplate={...tempPartnerTableTemplate,
                                selectedLine:tempSelectedLine
                            }

const defaultFilterData={
        counst:0,
        data0:null,
        dataUrl:"p35partner",
        lastRecordId:null,
        limitRow:basicData.limitRow,//3,
        pageNumber:1,
        initPageNumber:true,
        qry:{},
        reloadData:false,
    
        showModalCustomerFilter:true,
        showModalSupplierFilter:false,
        inputStateCustomer:partnerInputState,
        inputStateSupplier:partnerInputState,
    
        editData:null,
        
        tableTemplate:tempPartnerTableTemplate,
        sort:{id:-1},
        message:null,
        showModalError:false,
        showSupplier:false,
        showTable:basicData.showTable
}

const genFilterData=()=>{
    if(partnerForBillFilterData){
        return partnerForBillFilterData
    }
    else{
        return defaultFilterData
    }
}

const [filterData,setFilterData]=React.useState(genFilterData())



const setFilterDataFunc=(data)=>{
    setFilterData(data)
    setPartnerForBillFilterData(data)
}

const setEditData=(data)=>{
    setFilterDataFunc({...filterData,editData:data})
}

const setTableTemplate=(data)=>{
    setFilterDataFunc({...filterData,tableTemlate:data})
}

React.useEffect(()=>{
    //console.log('pageNumber')
    //console.log(filterData.pageNumber)
    
    setFilterDataFunc({...filterData,initPageNumber:false})

    if((filterData.pageNumber>0)&&(!filterData.initPageNumber)){
        const {dataUrl,pageNumber,limitRow,sort,qry,showSupplier}=filterData

        if(showSupplier){
                axios.post(`/${dataUrl}/getsupplierlimit`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
                .then(result=>{
                    const tempResult= { ...filterData,
                                        data0:result.data.data,
                                        count:result.data.count,
                                        lastRecordId:result.data.lastRecordId,
                                        reloadData:false,
                                    }
                    setFilterDataFunc(tempResult)
                })
                .catch(error=>{
                    //catchErrorToMessage(error,setMessage)
                    //setMessage(error.response.data.message)
                    const tempError={...filterData,
                                        reloadData:false,
                                        message:catchErrorToMessage(error),
                                        showModalConfirm:false,
                                        showModalError:true,
                                    }
                    setFilterDataFunc(tempError)
                })
        }
        else {
                axios.post(`/${dataUrl}/getcustomerlimit`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
                .then(result=>{
                    const tempResult= { ...filterData,
                                        data0:result.data.data,
                                        count:result.data.count,
                                        lastRecordId:result.data.lastRecordId,
                                        reloadData:false,
                                    }
                    setFilterDataFunc(tempResult)
                })
                .catch(error=>{
                    //catchErrorToMessage(error,setMessage)
                    //setMessage(error.response.data.message)
                    const tempError={...filterData,
                                        reloadData:false,
                                        message:catchErrorToMessage(error),
                                        showModalConfirm:false,
                                        showModalError:true,
                                    }
                    setFilterDataFunc(tempError)
                })
        }
    }
    
},[filterData.pageNumber])


const setReloadDataFunc=()=>{
    //console.log('setReloadDataFunc')
    const {dataUrl,pageNumber,limitRow,sort,qry}=filterData
    axios.post(`/${dataUrl}/getlimit`,{pageNumber,limitRow,sort,...qry},myheader,shouldRender)
    .then(result=>{
        const tempResult= { ...filterData,
                            data0:result.data.data,
                            count:result.data.count,
                            lastRecordId:result.data.lastRecordId,
                            reloadData:false,
                         }
        setFilterDataFunc(tempResult)
    })
    .catch(error=>{
        //catchErrorToMessage(error,setMessage)
        //setMessage(error.response.data.message)
        const tempError={...filterData,
                            reloadData:false,
                            message:catchErrorToMessage(error),
                            showModalConfirm:false,
                            showModalError:true,
                        }
        setFilterDataFunc(tempError)
    })
}


const saveTableTemplateFunc=(tableTemplate)=>{
    const {tableTemplateUrl}=filterData
    saveTableTemplate(tableTemplate,'p35branch',"partnerTableTemplate",myheader,shouldRender)
}

const filterAxiosForSupplierFunc=(option,inputState)=>{

    filterAxios(option,inputState,FilterTemplate.partnerFilter,
                 filterData,'getsupplierlimit',myheader,
                 shouldRender)
    .then(result=>{
        //console.log('supplier............')
        //console.log(result)
        setFilterDataFunc({...result,
            showSupplier:true,
            inputStateSupplier:inputState
        })
        //setPartnerInputState(filterInputState)
    })
    .catch(error=>{
        setFilterDataFunc({...error,
            inputState
        })
        //setPartnerInputState(filterInputState)
    })

}

const filterAxiosForCustomerFunc=(option,inputState)=>{

    filterAxios(option,inputState,FilterTemplate.partnerFilter,
                 filterData,'getcustomerlimit',myheader,
                 shouldRender)
    .then(result=>{
        //console.log('customer............')
        //console.log(result)
        setFilterDataFunc({...result,
            showSupplier:false,
            inputStateCustomer:inputState
        })
        //setPartnerInputState(filterInputState)
    })
    .catch(error=>{
        setFilterDataFunc({...error,
            inputStateCustomer:inputState
        })
        //setPartnerInputState(filterInputState)
    })

}
const setFilterDataData0Func=(data)=>{
    //console.log(data)
    setFilterDataFunc({...filterData,data0:data})
}
const backToFilter=()=>{setFilterDataFunc({...filterData,data0:null})}
const setSortFunc=(data)=>{setFilterDataFunc({...filterData,sort:data})}
const setLimitRowFunc=(data)=>{setFilterDataFunc({...filterData,limitRow:data})}
const setShowModalErrorFunc=(data)=>{setFilterDataFunc({...filterData,showModalError:data})}
const setPageNumberFunc=(data)=>{setFilterDataFunc({...filterData,pageNumber:data})}
const updateFilterData=(data)=>{
    //console.log('updateFilterData')
    //console.log(updateFilterData)
}
// qry:{},

const setShowTable=(data)=>{
    //console.log('setSwapState PartnerForBill...')
    //console.log(data)
    
    setFilterData({
        ...filterData,
        showTable:data
    })
}


const captureCustomerFunc=(data)=>{
    captureCustomer(data)
    setEditData(null)
}


const renderTableAndBadge=()=>{
    return(
        <div className="w-100 h-100" style={{paddingTop:"1rem"}}>
            <div className="w-100">
                { renderBadgeForBill({
                    filterData,
                    setShowTable:setShowTable,
                    setPageNumber:setPageNumberFunc,
                    bgColor:"#74b979",
                    backToFilter:backToFilter,
                    editData:filterData.editData,
                    captureEditData:captureCustomerFunc,
                    setReloadDataFunc:null//setReloadDataFunc
                }) }
            </div>

            <div className="w-100 h90">
                {filterData.showTable
                ?<Table
                    colorHead={filterData.showSupplier?"#6B5876":"#282D3C"}
                    //tableTemplate={basicDataSt.tableTemplate.partnerTableTemplate}
                    tableTemplate={filterData.tableTemplate}
                    setTableTemplate={setTableTemplate}//setTableTemplateFunc}

                    filterData={filterData.data0}
                    setFilterDataFunc={setFilterDataData0Func}
                    
                    editData={filterData.editData}
                    setEditData={setEditData}//{updateEditData}
                    saveTableTemplateFunc={saveTableTemplateFunc}
                    isSubTable={false}
                    useInput={false}
                    updateFilterData={()=>{}}//{updateEditData}

                    basicData={basicData}
                />
                :renderGrid(filterData,filterData.tableTemplate,setEditData)
                }

            </div>

        </div>
    )
}

return(
<div className="w-100" >

     
        <ModalFilterInput
            title={"ค้นหา"}//filterTitle}
            show={filterData.showModalCustomerFilter} 
            setShow={(data)=>setFilterDataFunc({...filterData,
                     showModalCustomerFilter:data
                    })}

            //filterTemplate={FilterTemplate.partnerFilter}
            filterTemplate={basicDataSt.pageFilter.customerFilter}

            inputState={filterData.inputStateCustomer} 
            setInputState={(data)=>{setFilterDataFunc({
                                ...filterData,
                                inputStateCustomer:data
                           })}}
            
            limitRow={filterData.limitRow}//limitRow} 
            setLimitRow={setLimitRowFunc}//setLimitRow}

            sort={filterData.sort}//sort} 
            setSort={setSortFunc}//setSort}
            
            filterAxios={filterAxiosForCustomerFunc}//filterAxiosFunc}
            basicData={basicData}

            LB={basicDataSt.pageFilterForm.customerFilterForm}
            //LB={pageFilterForm}
        />

        <ModalFilterInput
            title={"ค้นหา"}//filterTitle}
            show={filterData.showModalSupplierFilter} 
            setShow={(data)=>setFilterDataFunc({...filterData,
                     showModalSupplierFilter:data
                    })}


            //filterTemplate={FilterTemplate.partnerFilter}
            filterTemplate={basicDataSt.pageFilter.customerFilter}

            inputState={filterData.inputStateSupplier} 
            setInputState={(data)=>{setFilterDataFunc({
                ...filterData,
                inputStateSupplier:data
           })}}
            
            limitRow={filterData.limitRow}//limitRow} 
            setLimitRow={setLimitRowFunc}//setLimitRow}

            sort={filterData.sort}//sort} 
            setSort={setSortFunc}//setSort}
            
            filterAxios={filterAxiosForSupplierFunc}//filterAxiosFunc}
            basicData={basicData}

            LB={basicDataSt.pageFilterForm.supplierFilterForm}
            //LB={pageFilterForm}
        />

        {filterData.data0
        ?renderTableAndBadge()
        :null
        }

        {  
        filterData.showModalError
        ?renderModalError({
            setShow:setShowModalErrorFunc,
            message:filterData.message
        })
        :null
        }
</div>

)

}
export default PartnerForBill;


import React from 'react';
import {MdEdit,MdSearch,MdRefresh,MdPersonSearch,MdOutlinePersonSearch} from 'react-icons/md'
import {FaPlusSquare,FaMinusSquare,FaCheck,FaBan} from 'react-icons/fa'; 
import Calendar from '../../component/calendar/Calendar';
import axiosUtil from '../../util/axiosUtil';
import axios from '../../util/axiosVerify';
import Ticon from '../../component/ticon/Ticon';
import Galleryone_add from '../../component/galleryone_add/Galleryone_add'
import photoUtil from '../../component/galleryone_add/photoUtil'

import console from '../../util/consoleVerify'
import tableUtil from '../../component/table/tableUtil'

const {genId,addFileNameInPhotoUrl}=axiosUtil
const {numberWithCommas,
   numberWithCommasWithoutDot}=tableUtil


const EditBillForm=({
   //pageData
   billFormData,
   setBillFormData,
   basicData,
   submitFunc,
   cancelFunc,
   calDigit,
   pageData,
   myheader,
   calEndBill,
   user,
   printPage,
   transactionType
   //runIsGenId,
})=>{
   //console.log('EditBillForm')
   
   //const {transactionConfirmForm}=pageData
   const {editBillForm,reductionForm,taxForm,customerPointForm}=pageData
   //console.log(editBillForm)
   //console.log(pageData)
   const {changeArrayFile}=photoUtil

   const shouldRender= React.useRef(true)
   React.useEffect(()=>{
      return () => {
         //console.log('end.......................................')
         shouldRender.current=false
      } 
   },[])

 
       
   const shopArray=process.env.REACT_APP_VIP_SHOP.split("_@vip@_")
                              
   let imageLimit=process.env.REACT_APP_IMAGE_LIMIT
   if(shopArray.includes(user.shopId)){
      imageLimit=process.env.REACT_APP_IMAGE_LIMIT_VIP
   }


   const genBillFormData=(billFormData)=>{
      let temp
      if(billFormData.isGenIdOfBillForm){
         temp={...billFormData,
            remainingCustomerPoint:0,
            file1:null
         }
      }
      else {
         temp={...billFormData,
            file1:null
         }
      }

      return temp
   }


   const [billFormDataState,setBillFormDataState]=React.useState(
                                                   genBillFormData(billFormData)
                                                   )

   const [reloadPartner,setReloadPartner]=React.useState(true)

   

   React.useEffect(()=>{
      //console.log('billFormDataState............')
      //console.log(billFormDataState)
   },[billFormDataState])

   React.useEffect(()=>{

      //console.log('billFormDataState')
      //console.log(billFormDataState)

      
      if(reloadPartner){
         if(billFormData.isGenIdOfBillForm){
            axios.post('/p35partner/getcustomerlimit',{id:billFormDataState.partnerId},myheader,shouldRender)
            .then(result=>{
               //console.log('/p35basicdata/getcustom')
               //console.log(result.data.data[0])
               if(result.data.data[0]){
                  const tempObj= {remainingCustomerPoint:result.data.data[0].remainingCustomerPoint}
                  setBillFormDataState({...billFormDataState,...tempObj})
               }
               setReloadPartner(false)
               //setPoint({...point,totalPoint})
            })

         }
      }
   },[reloadPartner])

   //-----------------------------

   const [showImage1,setShowImage1]=React.useState(true)
   const [arrayFile1,setArrayFile1]=React.useState([])
   const [fileUrl1,setFileUrl1]=React.useState([])


  React.useEffect(()=>{

         changeArrayFile({ 
            arrayFile:arrayFile1,
            fileUrl:fileUrl1,
            setFileUrl:setFileUrl1,
            inputState:billFormDataState,
            setInputState:setBillFormDataState,
            keyName:"photoUrl1",
            //fileName,
            //serverFolder,
            //fileName:"file",
            //serverFolder:"/upload/customer",
            setShowImage:setShowImage1
         })
     
   },[arrayFile1])
   //------------------------------
   function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
   }

   const findDateTime=(date)=>{
      const date1=new Date(date).toISOString()
      const date2=new Date(date1).toLocaleString('fr-ca')
      const date3=new Date(date1).toLocaleString('en-GB')
  
        const tempDate= date2.substring(0,10)
        const tempTime=date3.substring(12,17)
        return {date:tempDate,time:tempTime}
     }

   const genArrayToString=(array)=>{
      
      
      const genSeparator=(str)=>{
         const tempArray=Array.from(str)
         let tempString=''
         tempArray.map((i,idx)=>{
               if((idx==3)||(idx==6)){
                  tempString=`${tempString} ${i}`
               }
               else {
                  tempString=`${tempString}${i}`
               }
         })
         return tempString
      }

      let temp=''
      
      if(array){
         if(Array.isArray(array)){
            array.map((i,idx)=>{

               if(idx==array.length-1){
                     temp=`${temp}${genSeparator(i)}`
               }
               else{
                     temp=`${temp}${genSeparator(i)},`
               }
            })
         }
      }

      return temp
   }

const handleChange=(e,i)=>{
   setBillFormDataState({...billFormDataState,[i]:e.target.value})
}


const findPartnerById=(eValue,route)=>{
  
      //console.log(eValue)
      axios.post(`/p35partner/${route}`,{id:eValue},myheader,shouldRender)
      .then(result=>{
         //console.log('result.data')
         //console.log(result.data)
         if(result.data.count>=1){
            
            const tempCustomer=result.data.data[0]

            const {id,title,name,phone,address,partnerType,remainingCustomerPoint}=tempCustomer
            //console.log('tempCustomer.....')
            //console.log(tempCustomer)
            const tempObj={
               partnerId:id,
               title:title,
               name:name,
               phone:phone,
               address:address,
               partnerType:partnerType,
               remainingCustomerPoint:remainingCustomerPoint
            }
            setBillFormDataState({...billFormDataState,
               ...tempObj
            })

         }
      })
      .catch(error=>{
         //console.log('error')
      })
   //}
}

//=====================================
const renderInsideBody=(i,idx)=>{

   if(i=="formHead"){
      return null
   }
   else if(i=="id"){
      return(
      <div className={"flex-center-center jc-start w-50"}
         key={idx} style={{padding:"0.3rem"}} >
         <div className="xc4" >
            <div className="flex-center-center jc-start" style={{color:"#bb3016"}}>
               {editBillForm[i]}
               <MdRefresh className="md-icon"
                  onClick={e=>{
                     //runIsGenId(true)
                     const tempId=genId()
                     setBillFormDataState({
                        ...billFormDataState,
                        id:tempId,
                        isGenIdOfBillForm:true   
                     })
                  }}
               />
            </div>
         </div>
      
         <div className="xc8">
            <input
               type={"number"}
               value={billFormDataState[i]}
               disabled="disabled"
               onChange={e=>{
                  setBillFormDataState({
                     ...billFormDataState,
                     [i]:e.target.value
                  })
               }}
            />
         </div>
      </div>
      )
   }
   if(i=="printId"||i=="printPageName"){
      return(
         <div className={"flex-center-center jc-start w-50"}
            key={idx} style={{padding:"0.3rem"}} >
            <div className="xc4" >
               <div className="flex-center-center jc-start" style={{}}>
                  {editBillForm[i]}
               </div>
            </div>
         
            <div className="xc8">
                  {billFormDataState[i]}               
            </div>
         </div>
         )
   }

   else if(i=="printPageSetting"){
      return(
         <div className={"flex-center-center jc-start w-50"}
            key={idx} style={{padding:"0.3rem"}} >
            <div className="xc4" >
               <div className="flex-center-center jc-start" style={{}}>
                  {editBillForm[i]}
               </div>
            </div>
         
            <div className="xc8">
               <select
                    onChange={e=>{
                        //console.log('e.target.value')
                        //console.log(e.target.value)
                        const temp=e.target.value.split("@mmpos@")
                        setBillFormDataState({...billFormDataState,
                           printId:temp[0],
                           printPageName:temp[1]
                        })


                    }}
                 >
                 <option>...</option>
                 {
                 printPage.data0.map((ll,idxLL)=>{
                     return <option key={idxLL}
                            value={`${ll.printId}@mmpos@${ll.printPageName}`}
                          >
                         {`${ll.printId}.${ll.printPageName}`}
                        </option>
                 })
                 }
               </select>
            </div>
         </div>
         )

   }

   else if(i=="date"||i=="remindDate"){ 
   //findDateTime
   return(
      <div className="flex-center-center jc-start w-50" 
            key={idx} style={{padding:"0.3rem"}} >
         <div className="xc4" style={{color:"blue"}}>
            {editBillForm[i]}
         </div>
         <div className="xc8">
         <Calendar
                  style={{height:"1.6rem",fontSize:"0.8rem"}}
                  showDateOption={"datetime"}
                  onMyClick={
                     (e)=>{
                     //console.log("onMyClick")
                     //console.log(e)
                     setBillFormDataState({
                        ...billFormDataState,
                        [i]:e
                     })
                     }
                  }
                  value={billFormDataState[i]}
                  useTimeSetting={true}
         />
         </div>
      </div>
   )
   }
   else if( 
            //(i=="transactionType")||
            (i=="transactionStatus")||
            (i=="tableStatus")||
            //(i=="partnerType")||
            (i=="paymentType")||
            (i=="queueStatus")||
            (i=="title")||
            //(i=="recipientTitle")||
            (i=="pic")
           ){
      return(
      <div className={i=="paymentType"
         ?"flex-center-center jc-start w-100"
         :"flex-center-center jc-start w-50"} 
         key={idx} style={{padding:"0.3rem"}} >
         <div className="xc4">
            {editBillForm[i]}
         </div>
         <select className="xc8"
            onChange={e=>handleChange(e,i)}
         >
            <option>....</option>
            {basicData[i].map((j,idx2)=>(
               <option key={idx2}
                       selected={billFormDataState[i]==j?"selected":""}
               >{j}</option>
            ))}
         </select>
      </div>
     )
   }
   else if(i=="recipientTitle"){
      return(
         <div className="flex-center-center jc-start w-50"
            key={idx} style={{padding:"0.3rem"}} >
            <div className="xc4" style={{color:"blue"}}>
               {editBillForm[i]}
            </div>
            <select className="xc8"
               onChange={e=>handleChange(e,i)}
            >
               <option>....</option>
               {basicData["title"].map((j,idx2)=>(
                  <option key={idx2}
                          selected={billFormDataState[i]==j?"selected":""}
                  >{j}</option>
               ))}
            </select>
         </div>
        )
   }
   else if(i=="table"){

      if(basicData.table){
         if(basicData.table.length>0){

            let tempTableArray=[]
            basicData.table.map(i=>{
               if(i.tableActive){
                  tempTableArray=[...tempTableArray,i]
               }
            })

            return(
               <div className={"flex-center-center jc-start w-50"} 
               key={idx} style={{padding:"0.3rem"}} >
                  <div className="xc4">
                     {editBillForm[i]}
                  </div>
         
                  <select className="xc8"
                     onChange={e=>handleChange(e,i)}
                  >
                     <option>....</option>
                     {tempTableArray.map((j,idx2)=>(
                        <option key={idx2}
                              selected={billFormDataState[i]==j.tableName?"selected":""}
                              value={j.tableName}
                        >{j.tableName}</option>
                     ))}
                  </select>
               </div>
               )
         }
      }
    
   }

   else if(i=="partnerType"){
      return null
   }
  
   else if(i=="active"||i=="useInnerVat"){
      return(
      <div className={"flex-center-center jc-start w-100"}
         key={idx} style={{padding:"0.3rem"}} >
         <div className="xc4">
            {editBillForm[i]}
         </div>
         <div className="xc8">
            <div style={{display:"flex",justifyContent:"flex-start"}}> 
               
               <input className="xc1"
                     style={{textAlign:"left"}}
                     type={"checkbox"}
                     checked={billFormDataState[i]}
                     onChange={e=>{
                        setBillFormDataState({
                           ...billFormDataState,
                           [i]:e.target.checked
                        })
                     }}
                  />     
               <div className="xc11"/>

            </div>
         </div>
      </div>
      )
   }
   else if(i=="transactionType"){
      
      let tempTransactionTypeArray=[]

      transactionType.data0.map(a=>{
         if(a.userLevel.includes(user.userLevel)){
            tempTransactionTypeArray=[...tempTransactionTypeArray,
                                       a.transactionType
                                     ]
         }
      })
      //console.log('tempTransactionTypeArray')
      //console.log(tempTransactionTypeArray)

      return(
         <div className={"flex-center-center jc-start w-50"}
            key={idx} style={{padding:"0.3rem"}} >
               <div className="xc4">
                  {editBillForm[i]}
               </div>
               <select className="xc8"
                  onChange={e=>{
                     //console.log(e.target.value)
                     let tempEndBillArray=[]
                     let tempEffectArray=[]
                     //console.log('3.............')
                     //console.log(basicData.endBill)
                     let tempEndBill
                     let tempEffect

                     let tempTransactionTypeId=0
                     let tempTransactionType

                     let isCheckTransactionType=true
                     
                     let tempUseInnerVat=false
                     let tempInnerVatRate=0

                     let tempPrintId=1
                     let tempPrintPageName=""

                     transactionType.data0.map(t=>{
                        if(isCheckTransactionType){

                           if(t.transactionType==e.target.value){
                              tempEndBill=t.endBill
                              tempEffect=t.effect

                              tempTransactionTypeId=t.transactionTypeId
                              tempTransactionType=t.transactionType
                              
                              tempUseInnerVat=t.useInnerVat
                              tempInnerVatRate=t.innerVatRate
                              isCheckTransactionType=false

                              tempPrintId=t.printId
                              tempPrintPageName=t.printPageName
                           }
                        }
                     })
     
                     if(tempEndBill){
                         tempEndBill.map(eb=>{
                             if(eb.endBillActive){
                                 tempEndBillArray=[ ...tempEndBillArray,
                                                    {...eb,endBillAmount:0}
                                                  ]
                             }
                         })
                     }
                    
                     if(tempEffect){
                         tempEffect.map(eb=>{
                             tempEffectArray=[ ...tempEffectArray,
                                                 {...eb,effectAmount:0}
                                             ]
                         })
                     }

                     

                     const tempA=calEndBill(tempEndBillArray,billFormDataState.total)

                    
                     let tempObj={...billFormDataState,
                        transactionTypeId:tempTransactionTypeId,
                        transactionType:tempTransactionType,

                        useInnerVat:tempUseInnerVat,
                        innerVatRate:tempInnerVatRate,

                        endBill:tempA.endBill,
                        effect:tempEffectArray,
                        
                        grandTotal:tempA.grandTotal,
                        
                        printId:tempPrintId,
                        printPageName:tempPrintPageName
                        }

                     setBillFormDataState(tempObj)
                     /*
                     let tempObj=billFormDataState
                     basicData["transaction"].map(k=>{
                        if(k.transactionType==e.target.value){
                              tempObj={...tempObj,
                                 transactionType:k.transactionType,
                                 effectStock:k.effectStock,
                                 effectCustomerPoint:k.effectCustomerPoint,
                                 effectSpending:k.effectSpending
                              }
                        }
                     })
                     setBillFormDataState(tempObj)
                     */
                  }}
               >
                  <option>...</option>
                  {
                  tempTransactionTypeArray.map((j,idx2)=>(
                  <option key={idx2}
                     value={j}
                     selected={billFormDataState[i]==j?"selected":""}
                  >{j}</option>
                  ))
                  }
               </select>
         </div>
      )
   }
   else if( (i=="total")||
            //(i=="totalReduction")||
            //(i=="totalTax")||
            (i=="grandTotal")||
            (i=="reductCustomerPoint")||
            (i=="totalPoint")||
            (i=="remainingCustomerPoint")
   ){
      const findBgColorLb=()=>{
         if(i=="totalPoint"){
            return "#bbc9a5"
         }
         if(i=="reductCustomerPoint"){
            return "#bcd890"
         }
         if(i=="remainingCustomerPoint"){
            return "#96bf65"
         }
         if(i=="grandTotal"){
            return "#a7c6eb"
         }
         if(i=="total"){
            return "#8cc5bb"
         }
      
      }

      return(
         <div className="flex-center-center jc-start w-100" 
            key={idx} style={{padding:"0.3rem"}} >
            <div className="xc4"
                 style={{fontSize:i=="grandTotal"?"1.4rem":"1.2rem",
                         backgroundColor:findBgColorLb(),
                         borderRadius:"5px"
                 }}
            >
               {editBillForm[i]}
            </div>
            <div className="xc8">
               <input
                  style={{fontSize:i=="grandTotal"?"1.4rem":"1.2rem",
                         color:i=="reductCustomerPoint"||
                         i=="totalReduction"?"red":"black"
                  }}
                  type={
                     i=="reductCustomerPoint"
                     ?"number"
                     :"text"
                  }
                  value={i=="reductCustomerPoint"
                     ?billFormDataState[i]
                     :numberWithCommas(billFormDataState[i])
                  }
                  onChange={e=>{
                     setBillFormDataState({
                        ...billFormDataState,
                        [i]:e.target.value
                     })
                  }}
                     //handleChangeNumber(e,i)
                  
                  disabled={i=="reductCustomerPoint"?null:"disabled"}
               />
            </div>
         </div>
      )
   }

   
   else if(i=="shopInfo"){
      return(
      <div className="flex-center-center jc-start w-50"
         
         key={idx} style={{padding:"0.3rem"}} >
         <div className="xc4">
            {editBillForm[i]}
         </div>
         <div className="xc8">
            <input
               type={"text"}
               value={genArrayToString(billFormDataState[i])}
               disabled="disabled"
            />
         </div>
      </div>
      )
   }
   
   else if(i=="endBill"){
   
            return billFormDataState[i].map((p,idxP)=>{
               return(
                  <div className="flex-center-center jc-start w-50"
                     key={idxP} style={{padding:"0.3rem"}} >
                     
                     <div className="xc4"
                        style={{display:"flex",alignItems:"center",paddingRight:"0.5rem"}}
                     >
                       
                        <input 
                           style={{color:"black"}}
                           value={p.endBillName}
                           onChange={e=>{
                              let tempArray=[]
                              billFormDataState.endBill.map((r,idxR)=>{
                                 
                                 if(r._id==p._id){
                                    const tempObj={...r,endBillName:e.target.value}
                                    tempArray=[...tempArray,tempObj]
                                 }
                                 else{
                                    tempArray=[...tempArray,r]
                                 }

                              })
                              setBillFormDataState({
                                 ...billFormDataState,
                                 endBill:tempArray
                              })
                           }}
                        />
                        
                     </div>

                     <div className='xc3' style={{paddingLeft:"5px"}}>
                           <input
                              type="number"
                              value={p.endBillRate}

                              onChange={e=>{
                                 //console.log('endBillRate onChnage')
                                 //console.log(e.target.value)
                                 let tempArray=[]
                                 billFormDataState.endBill.map(r=>{
                                    if(r.endBillId==p.endBillId){
                                       tempArray=[...tempArray,
                                          {...r,endBillRate:e.target.value}
                                       ]
                                    }
                                    else{
                                       tempArray=[...tempArray,r]
                                    }
                              
                                 })

                                 const tempA=calEndBill(tempArray,billFormDataState.total)

                                 setBillFormDataState({
                                    ...billFormDataState,
                                    endBill:tempA.endBill,
                                    grandTotal:tempA.grandTotal
                                 })
                              }}


                              onBlur={e=>{
                                 let isPass=true
                                 if(!e.target.value){
                                    if(e.target.value===0){
                                       isPass=true
                                    }
                                    else{
                                       const tempRate=parseFloat(e.target.value)
                                       if(isNumeric(tempRate)){
                                          isPass=true
                                       }
                                       else{
                                          isPass=false
                                       }
                                    }
                                 }

                                 if(!isPass){
                                    let tempEndBill=[]
                                 
                                    billFormDataState.endBill.map(l=>{
                                       //console.log(l)
                                       if(l._id==p._id){
                                          const tempObj={...l,endBillRate:0}
                                          tempEndBill=[...tempEndBill,tempObj]
                                       }
                                       else{
                                          tempEndBill=[...tempEndBill,l]
                                       }
                                    })
   
                                    setBillFormDataState({
                                       ...billFormDataState,
                                       endBill:tempEndBill,
                                    })
                                 }

                              }}
                           />
                     </div>
                     <div className='xc1' >
                           {p.endBillInPercentage
                           ?" %"
                           :null
                           }
                     </div>

                     <div className="xc4"style={{paddingLeft:"5px"}}>
                        <input
                           type="text"
                           value={
                              numberWithCommas(p.endBillAmount)
                           }
                           style={{
                              color:p.endBillEffect=="-"
                              ?"red"
                              :"black"
                           }}
                           onChange={e=>{}}
                           disabled="disabled"
                        />
                     </div>
                  </div>
               )
            })

      
   }
   else if(i=="reference"||
           i=="name"||i=="address"||i=="phone"||i=="contact"||i=="taxId"
           ){
      return(
         <div className="flex-center-center jc-start w-50"
            
            key={idx} style={{padding:"0.3rem"}} >
            <div className="xc4">
               {editBillForm[i]}
            </div>
            <div className="xc8">
               <input
                  type={"text"}
                  onChange={e=>handleChange(e,i)}
                  value={billFormDataState[i]}
               />
            </div>
         </div>
         )
   }

   else if(i=="recipientName"||i=="recipientAddress"||i=="recipientPhone"||i=="recipientContact"){
      return(
         <div className="flex-center-center jc-start w-50"
            
            key={idx} style={{padding:"0.3rem"}} >
            <div className="xc4" style={{color:"blue"}}>
               {editBillForm[i]}
            </div>
            <div className="xc8">
               <input
                  type={"text"}
                  onChange={e=>handleChange(e,i)}
                  value={billFormDataState[i]}
               />
            </div>
         </div>
      )
   }

   else if(i=="delivery"){
      return(
         <div className="flex-center-center jc-start w-50" 
            key={idx} style={{padding:"0.3rem"}} >
            <div className="xc4">
               {editBillForm[i]}
            </div>
            <select className="xc8"
               onChange={e=>{
                  //console.log(e.target)
                  const temp=e.target.value.split("_@,@_")
                  //handleChange(e,i)
                      
                  setBillFormDataState({...billFormDataState,
                     recipientTitle:temp[0],
                     recipientName:temp[1],
                     recipientPhone:temp[2],
                     recipientContact:temp[3],
                     recipientAddress:temp[4],
                  })


               }}
            >
               <option>....</option>
               {billFormDataState["delivery"].map((j,idx2)=>{
                  if(j){
                     return (
                        <option key={idx2}   
                           value={`${j.title}_@,@_${j.name}_@,@_${j.phone}_@,@_${j.contact}_@,@_${j.address}`}
                           //selected={billFormDataState[i]==j?"selected":""}
                        >{`${j.title},${j.name},${j.phone},${j.address}`}</option>
                     )
                  }
               })}
            </select>
         </div>
        )
   }

   else if(i=="remark"){
      return(
      <div className="flex-center-center jc-start w-100"
            
         key={idx} style={{padding:"0.3rem"}} >
         <div className="xc4">
            {editBillForm[i]}
         </div>
         <div className="xc8">
            <textarea
               rows={5}
               value={billFormDataState[i]}
               onChange={e=>handleChange(e,i)}
            />
         </div>
      </div>
      )
   }
   else if(i=="partnerId"){
      return(
         <div className="flex-center-center jc-start w-50"
            
            key={idx} style={{padding:"0.3rem"}} >
            <div className="xc4" style={{display:"flex"}}>
               <div className='xc4'>
               {editBillForm[i]}
               </div>
               <div className="xc4"
                    onClick={e=>{
                     findPartnerById(billFormDataState[i],"getsupplierlimit")
                    }}
               >
                    <Ticon
                            iconName="MdPersonSearch"
                            className="md-icon"
                            iconStyle={{color:"black"}}
                        />
               </div>
               <div className="xc4"
                    onClick={e=>{
                      findPartnerById(billFormDataState[i],"getcustomerlimit")
                    }}
               >
                     <Ticon
                            iconName="MdOutlinePersonSearch"
                            className="md-icon"
                            iconStyle={{color:"black"}}
                        />
               </div>
            </div>

         
            <div className="xc8">
               <input
                  type={"number"}
                  value={billFormDataState[i]}
                  onChange={e=>{
                     handleChange(e,i)
                  }}

                  onKeyDown={e=>{
   
                     if(e.key=="Enter"){
                        if(e.target.value){
                           findPartnerById(e.target.value,"getcustomerlimit")
                        }
                     }
   
                  }}
               />
            </div>
         </div>
      )

   }
   else if(i=="queue") {
      //console.log(`i == ${i}`)
      return(
      <div className="flex-center-center jc-start w-50"
         key={idx} style={{padding:"0.3rem"}} >
         <div className="xc4"
              style={{color:"brown"}}
         >
            {editBillForm[i]}
         </div>
         <div className="xc8">
           <input
               type="number"
               value={billFormDataState[i]}
               onChange={e=>{
                  handleChange(e,i)
               }}
              
            />
         </div>
      </div>
      )
   }
   else if(i=="innerVatRate"||i=="cash"||i=="change") {
      //console.log(`i == ${i}`)
      return(
      <div className="flex-center-center jc-start w-50"
         key={idx} style={{padding:"0.3rem"}} >
         <div className="xc4"
         >
            {editBillForm[i]}
         </div>
         <div className="xc8">
           <input
               type="number"
               value={billFormDataState[i]}
               onChange={e=>{
                  handleChange(e,i)
               }}
              
            />
         </div>
      </div>
      )
   }

}

//=================================
const objectKeysRight=["active",
"id","transactionType","transactionStatus","date",
"remindDate","reference",
"useInnerVat",
"innerVatRate",
"paymentType","cash","change",
"total","endBill","grandTotal",
"remark",
"printPageSetting",
"printId",
"printPageName"
//"shopInfo"
]

const objectKeysLeft=[
"table",
"tableStatus",
"queue","queueStatus",
"partnerId","partnerType",
"title","name","phone","contact","address",
"taxId",
"delivery",
"recipientTitle","recipientName","recipientPhone","recipientContact","recipientAddress",


"totalPoint","remainingCustomerPoint","reductCustomerPoint",
"pic"
]
//=================================
const renderBody=(ObjectKeys)=>{
   //console.log('renderBody.........')
   //console.log(Object.keys(editBillForm))

return(ObjectKeys.map((i,idx)=>{

   return (
      <div className='xc12' key={idx}>
         {
         renderInsideBody(i,idx)
         }
      </div>
   )

   }))
}

  
   return(
   <div className="Modal-background">
       <div className="Modal-box" style={{width:"96%",backgroundColor:"white",marginBottom:"4rem"}}>
          
            <div className="Modal-header">
                  <h3>{editBillForm.formHead}</h3>
            </div>

            
            <div className="flex-center-center jc-start w-100"
                 style={{display:"flex",justifyContent:"center",
                        alignItems:"flex-start"}}
            >
               <div className='xc6 mc6 sc12'>
                  {  
                  renderBody(objectKeysLeft)   
                  }
               </div>
               <div className='xc6 mc6 sc12'>
                  {  
                  renderBody(objectKeysRight)
                  }
               </div>
            </div>
            
            <div className="xc5 sc9 bgc-darkGray bd-lightGray"
            >
                        {
                        showImage1
                        ?<Galleryone_add 
                            fileUrl={fileUrl1}
                            arrayFile={arrayFile1}
                            setArrayFile={setArrayFile1}
                            keyName={"photoUrl1"}

                            setShowImage={setShowImage1}
                            inputState={billFormDataState}
                            setInputState={setBillFormDataState}
                            imageLimit={imageLimit}

                        />
                        :null
                        }   
            </div>     


            <div className="Modal-footer"
                 style={{position:"fixed",bottom:"1rem",right:"1rem",zIndex:"100"}}
            >
                  <div>
                     <button
                        onClick={e=>{

                              let tempBillFormDataState=addFileNameInPhotoUrl(billFormDataState)

                         
                              let tempSpareBoolean1=false
                              //let tempPhotoUrl1=[]

                              if(tempBillFormDataState.photoUrl1){
                                    let tempLength=tempBillFormDataState.photoUrl1.length

                                    if(tempLength>1){
                                    tempSpareBoolean1=true  
                                    }
                                    else if(tempLength==1){
                                       if(tempBillFormDataState.photoUrl1[0]){
                                          tempSpareBoolean1=true
                                       }
                                    }

                                 
                                    /*
                                    for(let z=0;z<imageLimit;z++){
                                       if(tempBillFormDataState.photoUrl1[z]){
                                          tempPhotoUrl1=[...tempPhotoUrl1,
                                                         tempBillFormDataState.photoUrl1[z]
                                                         ]
                                       }
                                    }
                                    */

                              }


                              tempBillFormDataState={...tempBillFormDataState,
                                                     spareBoolean1:tempSpareBoolean1,
                                                     //photoUrl1:tempPhotoUrl1
                                                    }
                              //console.log('tempBillFormDtaState')
                              //console.log(tempBillFormDataState)

                              submitFunc(tempBillFormDataState)
                        }}
                     
                     >
                        <FaCheck/>
                     </button>
                  </div>
                  <div>
                     <button
                        onClick={e=>{
                           cancelFunc()
                        }}
                     >
                        <FaBan/>
                     </button>
                  </div>

            </div>

      </div>
     




   </div>
   )
}

export default EditBillForm
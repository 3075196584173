import React from 'react';
import {MdClose, MdRadioButtonChecked,MdRadioButtonUnchecked} from 'react-icons/md';
import {FaTags,FaBan,FaCheck} from 'react-icons/fa'
//import jsImageCompressor from 'js-image-compressor';
import console from '../../util/consoleVerify'

//=================================


//=================================
function LineForm(props) {

const {i,idx,objKeys,tableTemplate,showTable,
       updateFilterData,basicData,
       findProductIdByKeyDown,findBarcodeByKeyDown,calDigit,
       filterData,useInnerVat,innerVatRate
}=props

//console.log('LineForm i....')
//console.log(i)

//console.log(i)
let [inputState,setInputState]=React.useState(i)

let [showSetUpBarcode,setShowSetUpBarcode]=React.useState(false)

React.useEffect(()=>{
    //console.log('inputState useEffect..........')
    //console.log(inputState)

    if(inputState.printKey=="id@scanner@"){
        setShowSetUpBarcode(true)
    }
    else if(inputState.printKey=="sn@scanner@"){
        setShowSetUpBarcode(true)
    }
    else if(inputState.printKey=="barcode@scanner@"){
        setShowSetUpBarcode(true)
    }
},[inputState])

const [showTag,setShowTag]=React.useState(false)
const [showPageValueSelection,setShowPageValueSelection]=React.useState(true)



const checkNumberWithDigit=(calDigit,myvalue)=>{
    let pattern
    if(calDigit==1){
        pattern = "^\-?[0-9]?$"
    }
    else if(calDigit==10){
        pattern = "^\-?[0-9]+(?:\.[0-9]{1,1})?$"
    }
    else if(calDigit==1000){
        pattern = "^\-?[0-9]+(?:\.[0-9]{1,3})?$"
    }
    else if(calDigit==10000){
        pattern = "^\-?[0-9]+(?:\.[0-9]{1,4})?$"
    }
    else{
        pattern = "^\-?[0-9]+(?:\.[0-9]{1,2})?$"
    }
    //console.log(`value=${myvalue}`)
    //console.log(calDigit)
    const regex = new RegExp(pattern);
    const result=regex.test(myvalue.toString())
    //console.log(`result of ${value} = ${result}`)
    return result
} 

const checkPriceAndQuantity=(inputState,j,eValue,calDigit)=>{
    let tempResult=null
    if(eValue==""){
        const temp={...inputState,
            [j]:eValue,
            ["result"]:0,
            ["resultPoint"]:0
        }
        tempResult=temp
        //setInputState(temp)
    }
    
    else{
    //calDigit
        const tempCheck =checkNumberWithDigit(calDigit,eValue)

        if(tempCheck){
            
            let myvalue=eValue
         
            let temp
            if(j=="quantity"){
                
                if(useInnerVat&&innerVatRate){
                    temp={...inputState,
                        ["quantity"]:eValue,
                        ["result"]:parseInt(myvalue*inputState.price*calDigit)/calDigit,
                        ["resultPoint"]:parseInt(myvalue*inputState.point*calDigit)/calDigit,
                        ["resultIncludeVat"]:parseInt(myvalue*inputState.priceIncludeVat*calDigit)/calDigit,
                    }  
                }
                else {
                    temp={...inputState,
                        ["quantity"]:eValue,
                        ["result"]:parseInt(myvalue*inputState.price*calDigit)/calDigit,
                        ["resultPoint"]:parseInt(myvalue*inputState.point*calDigit)/calDigit
                    }  
                }
            }
            if(j=="price"){

                if(useInnerVat&&innerVatRate){
                                      
                    const temp1=eValue*innerVatRate/100
                    const temp2=parseInt(eValue)+parseInt(temp1)
                 
                    const temp3=parseInt(temp2*calDigit)/calDigit
                    const tempPriceIncludeVat=temp3

                    temp={...inputState,
                        ["price"]:eValue,
                        ["result"]:parseInt(myvalue*inputState.quantity*calDigit)/calDigit,
                        ["priceIncludeVat"]:tempPriceIncludeVat,
                        ["resultIncludeVat"]:parseInt(tempPriceIncludeVat*inputState.quantity*calDigit)/calDigit,
                    }  
                }else{

                    temp={...inputState,
                        ["price"]:eValue,
                        ["result"]:parseInt(myvalue*inputState.quantity*calDigit)/calDigit
                    }  

                }

            }
            if(j=="point"){
                temp={...inputState,
                    [j]:eValue,
                    ["resultPoint"]:parseInt(myvalue*inputState.quantity*calDigit)/calDigit
            }    
            }

            tempResult=temp
        }
        
    }

    return tempResult
}




React.useEffect(()=>{
   setInputState(i)
},[i])


const priceLevelHeadCell={
        padding:"0.3rem",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        fontSize:"1rem",
        border:"0.5px solid #777"
}

const priceLevelHead={
        display:"grid",
        gridTemplateColumns:"150px 100px 200px",
        gridAutoRows:"1.2rem",
        backgroundColor:"#ccc"
}

const priceLevelBody={
    display:"grid",
    gridTemplateColumns:"150px 100px 200px",
    gridAutoRows:"1.2rem",
    backgroundColor:"white"
}

const renderSelectOption=(j)=>{
    //console.log(j)
    //console.log(basicData)
    //console.log(basicData[j])
    if(basicData){
        return basicData[j].map((i,index)=>(
                <option key={index} value={i}>{i}</option>
            )
        )
    }
    else {
        return null
    }
}
//<option selected="selected"> {inputState[j]}</option>


const renderSelect=(j)=>{
    return(
        <select style={{height:"100%",backgroundColor:"#e6ffcc"}} 
            defaultValue={inputState[j]}
            onChange={e=>{
                setInputState({...inputState,[j]:e.target.value})
                updateFilterData(idx,{...inputState,[j]:e.target.value})
            }}
        >
            <option value="" hidden>...</option>
            {/*<option selected> {inputState[j]}</option>*/}
            {
                renderSelectOption(j)
            }
        </select>       
    )
}


/*

 return (
        <select className="w-100"
            style={{height:"1.5rem"}}
            value={inputState[j]}
            onChange={e=>{

            }}
            >  
                <option value="" hidden>list below...</option>
                {
                    //renderSelectCondition()
                }
    
    
            </select>
        )

*/

const renderRadio=(idx,inputState_j)=>{
    //return <input type="radio"/>
    
    if(inputState_j){
        return <MdRadioButtonChecked className="sm-icon"
            onClick={e=>{
                setInputState({...inputState,selectedLine:false})
                updateFilterData(idx,{...inputState,selectedLine:false})
            }}
        />
    }
    else{
        return <MdRadioButtonUnchecked className="sm-icon"
            onClick={e=>{
                setInputState({...inputState,selectedLine:true})
                updateFilterData(idx,{...inputState,selectedLine:true})
            }}
        />
    }
}

const renderInput=(j)=>{
   

    if(j=="endBillInPercentage"){
        
        return (
            <div className="flex-center-center" style={{height:"100%",width:"100%"}}>
                <div style={{height:"80%",width:"1.2rem"}}>
                 <input 
                    type="checkbox"
                    checked={inputState[j]}
                    style={{margin:"0",padding:"0"}}
                    onChange={e=>{
                        let temp
                        if(e.target.checked){
                            temp={...inputState,
                                 [j]:e.target.checked,
                                 ["endBillRate"]:0,
                                 ["endBillInPercentageOf"]:"total"
                            }
                        }
                        else{
                            temp={...inputState,
                                [j]:e.target.checked,
                                ["endBillRate"]:0,
                                ["endBillInPercentageOf"]:""
                           }

                        }
                    
                        //const temp={...inputState,[j]:e.target.checked}
                        setInputState(temp)
                    }}
                    onBlur={e=>{
                        updateFilterData(idx,inputState)
                    }}
                />
                </div>
            </div>
        )
        
    }
    else if(j=="endBillEffect"){
        return(
            <select style={{width:"100%"}}
                onChange={e=>{
                    //console.log('.......select')
                    const temp={...inputState,
                        [j]:e.target.value,
                        }
                    setInputState(temp)
                }}
                onBlur={e=>{
                    updateFilterData(idx,inputState)
                }}
                >
                <option>....</option>
                {['+','-','0'].map((k,idx2)=>(
                    <option key={idx2} value={k}
                            //selected={billFormDataState[i]==j?"selected":""}
                    >{k}</option>
                ))}

            </select>
        )
    }
    else if(j=="endBillId"||j=="effectId"){
        return(
            <div style={{textAlign:"left",width:"100%"}}>
                {inputState[j]}   
            </div>
        )
    }
    else if(j=="endBillInPercentageOf"){
       //console.log('endBilInPercentageOf.....')
       //console.log('inputState')
       //console.log(inputState)
       //console.log(filterData)
        let tempEndBillObj={}

        
        filterData.map((h,idxH)=>{
            if(idxH==0){
                tempEndBillObj={...tempEndBillObj,
                    [idxH]:["total"]}
            }
            
            else{
                tempEndBillObj={...tempEndBillObj,
                    [idxH]:[...tempEndBillObj[idxH-1],
                            filterData[idxH-1].endBillId]
                }
            }
        })
       // //console.log('tempEndBillObj')
        //console.log(tempEndBillObj)
        
        return(
            <select style={{width:"100%"}}
                
                defaultValue={inputState[j]}
                onChange={e=>{
                    //console.log('.......select')
                    const temp={...inputState,
                        [j]:e.target.value,
                        }
                    setInputState(temp)
                }}
                onBlur={e=>{
                    updateFilterData(idx,inputState)
                }}
                >
                <option>....</option>
                {tempEndBillObj[idx].map((k,idx2)=>(
                    <option key={idx2} value={k}
                            //selected={inputState[j]==k?"selected":""}
                    >{k}</option>
                ))}

            </select>
        )
    }
    else if(j=="effectEndBillId"){
        //console.log("effectEndBillId")
        //console.log(basicData.endBill[0])

        let tempArray=[ 
                        {endBillId:null,endBillName:"total"},
                        {endBillId:null,endBillName:"grandTotal"}
                      ]
        basicData.endBill.map(o=>{
            tempArray=[...tempArray,
                        {endBillId:o.endBillId,endBillName:o.endBillName}
                      ]
        })
        
        return(
            <select
                defaultValue={inputState.effectEndBillId}

                onChange={e=>{
                    let temp
                    let myvalue=e.target.value.split(",")
                    if(myvalue[1]=="total"||myvalue[1]=="grandTotal"){
                        temp={...inputState,
                            ["effectEndBillId"]:null,
                            ["effectEndBillName"]:myvalue[1]
                        }
                        setInputState(temp)
                    }
                    else{
                        temp={...inputState,
                            ["effectEndBillId"]:myvalue[0],
                            ["effectEndBillName"]:myvalue[1]
                        }
                        setInputState(temp)
                    }

                }}
                onBlur={e=>{
                    updateFilterData(idx,inputState)
                }}
            
            >
                <option>...</option>
                {
                 tempArray.map((a,idxA)=>{
                     return(
                         <option key={idxA}
                                 value={`${a.endBillId},${a.endBillName}`}
                                 //selected={inputState.effectEndBillId==a.endBillId?"selected":""}
                         >{`${a.endBillId},${a.endBillName}`}</option>
                     )
                 })   
                }
            </select>
        )
    }
    //============================
    //============================
    //============================
    //=============================
    else if(tableTemplate[j].type=="price"){

        //console.log('price inputState.....................')
        //console.log(inputState)
        //console.log(inputState.priceLevel)
        //console.log(tableTemplate[j].type)

        return(
            <div className="flex-center-center h-100 w-100" 
                 style={{position:"relative",zIndex:"100"}}>
                {showTag
                ?<div style={{backgroundColor:"white",
                             position:"absolute",top:"2.5rem"
                            }}
                 >
                    
                    <div style={priceLevelHead}>    
                        <div style={priceLevelHeadCell}>    
                                เครื่องหมาย/mark
                        </div>
                        <div style={priceLevelHeadCell}>    
                                ราคา/price
                        </div>

                        <div style={priceLevelHeadCell}>    
                                หมายเหตุ/remark
                        </div>
                    </div>

                    {
                 
                        inputState.priceLevel.map((l,index3)=>{
                            return(
                            <div className='bill-p'
                                 key={index3} 
                                 style={priceLevelBody}
                                 onClick={e=>{
                                    
                                    if(l.price){
                                            let tempPrice=inputState.price

                                            //calDigit
                                            let myvalue=parseInt(l.price*calDigit)/calDigit
                                            //}

                                            if(l.mark=="+"){
                                                tempPrice=tempPrice+myvalue
                                            }
                                            else if(l.mark=="-"){
                                                tempPrice=tempPrice-myvalue
                                            }
                                            else if(l.mark=="+%"){
                                                const temp1percent=tempPrice/100
                                                const tempValue1=temp1percent*myvalue
                                                const tempValue2=tempPrice+tempValue1
                                                tempPrice=parseInt(tempValue2*calDigit)/calDigit
                                            }
                                            else if(l.mark=="-%"){
                                                const temp1percent=tempPrice/100
                                                const tempValue1=temp1percent*myvalue
                                                const tempValue2=tempPrice-tempValue1
                                                tempPrice=parseInt(tempValue2*calDigit)/calDigit
                                            }
                                            else{
                                                tempPrice=myvalue
                                            }

                                            const tempInputState={...inputState,

                                                ["price"]:tempPrice,
                                                ["result"]:parseInt(tempPrice*inputState.quantity*calDigit)/calDigit
                                            }

                                            //const tempInputState={...inputState,['price']:l.price}
                                            //console.log('tempInputState')
                                            // //console.log(tempInputState)

                                            setInputState(tempInputState)
                                            setShowTag(false)
                                            updateFilterData(idx,tempInputState)
                                    }
                                  
                                 }}
 
                    
                            >    
                                <div style={priceLevelHeadCell}>    
                                    {l.mark}
                                </div>

                                <div style={priceLevelHeadCell}>    
                                    {l.price}
                                </div>
        
                                <div style={priceLevelHeadCell}>    
                                    {l.remark}
                                </div>
                            </div>
                            )
                        })
                        
                    }

                </div>
                :null
                }
                <div className="w-15" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>     
                    <FaTags
                        onClick={e=>{
                             setShowTag(!showTag)
                        }}
                    />
                </div>
                <div className="w-85 h-100">
                    <input value={inputState[j]} 
                        checked={inputState[j]}
                        style={{height:j=="selectedLine"?"50%":"100%",
                                backgroundColor:"#e6ffcc"}}
                        type={"number"}
                        onChange={e=>{
                            if(tableTemplate[j].type!="radio"){
                                //console.log('aaaa')
                                //let myvalue=e.target.value
                                //if(tableTemplate[j].type=="number"){
                                const temp=checkPriceAndQuantity(inputState,j,e.target.value,calDigit)
                                if(temp){
                                    setInputState(temp)
                                }
                         
                            }
                        }}
                        onBlur={e=>{
                            updateFilterData(idx,inputState)
                        }}
                    />
                </div>
            </div>
        )
    }
    //===============================
    else if(j=="printKey"||j=="printValue"||
            j=="sectionName"||j=="printPosition"
            ){

        if(j=="printValue"){

            return showPageValueSelection
            ?
            <div className='w-100' 
                  style={{display:"flex"}}
             >
                <div style={{margin:"0.3rem"}}>
                    <FaTags
                        onClick={e=>{
                            //if(inputState.sectionName!="tableHead")
                            //{
                            setShowPageValueSelection(!showPageValueSelection)
                            //}
                        }}
                    />
                </div>
                    <input
                        style={{width:"100%"}}
                        value={inputState[j]} 
                        onChange={e=>{
                            let temp
                            let myvalue=e.target.value
    
                            temp={...inputState,[j]:myvalue,["printKey"]:"noKey"}
                            setInputState(temp)
                        }}
                        onBlur={e=>{
                            updateFilterData(idx,inputState)
                        }}
                    />
            </div>
                
            :<div className='w-100'
                style={{display:"flex"}}
            >
                <div style={{margin:"0.3rem"}}>
                    <FaTags
                        onClick={e=>setShowPageValueSelection(!showPageValueSelection)}
                    />
                </div>
                    <select style={{width:"100%"}}
                        onChange={e=>{
                            //console.log('.......select')
                            //console.log(e.target.value)
                            let tempArray=e.target.value.split(":")
                            let temp
                            let myvalue=tempArray[0]
                            temp={...inputState,[j]:null,
                                  ["printKey"]:myvalue,
                                  ["printValue"]:tempArray[1]
                                }
                            setInputState(temp)
                        }}
                        onBlur={e=>{
                            updateFilterData(idx,inputState)
                        }}
                    >
                    <option>....</option>
                        {basicData[inputState.sectionName].map((k,idx2)=>(
                            <option key={idx2} value={`${k.key}:${k.LB}`}
                                    //selected={billFormDataState[i]==j?"selected":""}
                            >{k.LB}</option>
                        ))}
    
                    </select>
            </div>
            
        }        
        else if(j=="printKey"){
            return(
                <div>
                    <input
                        onChange={e=>{}}
                        value={inputState[j]} 
                        disabled
                    />
                </div>
            )
        }
        else if(j=="sectionName"){
            return(
            <select style={{width:"100%"}}

            defaultValue={inputState.sectionName}

            onChange={e=>{
                let temp
                let myvalue=e.target.value
                temp={...inputState,
                        ["sectionName"]:myvalue,
                        ["printKey"]:"noKey",
                        ["printValue"]:null,
                        ["printWidth"]:0,
                        ["printFontSize"]:1,
                        ["printPosition"]:"left",
                        ["show"]:true
                    }
                setInputState(temp)
            }}
            onBlur={e=>{
                updateFilterData(idx,inputState)
            }}
            >
                <option>....</option>
                {["header","tableHead","table","tableEnd",
                  "total","endBill","grandTotal", 
                  "footer"].map((k,idx2)=>(
                    <option key={idx2} value={k}
                        //selected={inputState.sectionName==k?"selected":""}
                    >{k}</option>
                ))}

            </select>
        )
        }   
        else if(j=="printPosition"){
            return(
                <select style={{width:"100%"}}

                defaultValue={inputState.printPosition}

                onChange={e=>{
                    let temp
                    let myvalue=e.target.value
                    temp={...inputState,
                            ["printPosition"]:myvalue,
                        }
                    setInputState(temp)
                }}
                onBlur={e=>{
                    updateFilterData(idx,inputState)
                }}
                >
                    <option>....</option>
                    {["left","center","right"].map((k,idx2)=>(
                        <option key={idx2} value={k}
                            //selected={inputState.printPosition==k?"selected":""}
                        >{k}</option>
                    ))}
    
                </select>
            )
        }
    }
    //===============================
    
    //===============================

    else if(j=="id"||j=="barcode"){
        return (
            <input value={inputState[j]} 
                style={{height:"100%",
                        backgroundColor:"#e6ffcc"
                       }} 
                
                type={tableTemplate[j].type}
                onChange={e=>{
                    
                    let temp
                    let myvalue=e.target.value

                    temp={...inputState,[j]:myvalue}
                    setInputState(temp)
                    
                }}

                onKeyDown={e=>{
                    if(j=="id"){
                        findProductIdByKeyDown(e,j,inputState[j],idx)
                    }
                    if(j=="barcode"){
                        findBarcodeByKeyDown(e,j,inputState[j],idx)
                    }
                }}

                onBlur={e=>{
                    updateFilterData(idx,inputState)
                }}
            />
        )

    }
    else if((j=="unit")||(j=="jobStatus")||(j=="effectSpending")||
            (j=="effectCustomerPoint") ||(j=="effectStock")||(j=="position")){
        return renderSelect(j)
    }

    else if(tableTemplate[j].type=="boolean"){
        return (
        <div className="flex-center-center" style={{height:"100%",width:"100%"}}>
            <div style={{height:"80%",width:"1.2rem"}}>
             <input 
                type="checkbox"
                checked={inputState[j]}
                style={{margin:"0",padding:"0"}}
                onChange={e=>{
                    const temp={...inputState,[j]:e.target.checked}
                    setInputState(temp)
                }}
                onBlur={e=>{
                    updateFilterData(idx,inputState)
                }}
            />
            </div>
        </div>
        )
    }

    else {
        //console.log('j.......')
        //console.log(j)
        return (

            <input value={inputState[j]} 
                checked={inputState[j]}
                style={{height:j=="selectedLine"?"50%":"100%",
                        backgroundColor:"#e6ffcc"
                        }} 
                
                type={tableTemplate[j].type}
                onChange={e=>{
                    if(tableTemplate[j].type!="radio"){
                        //console.log('aaaa')
                        let temp
                        let myvalue=e.target.value
                        if(tableTemplate[j].type=="number"){
                            
                            if(j=="price"||j=="quantity"||j=="point"){

                                const temp=checkPriceAndQuantity(inputState,j,e.target.value,calDigit)
                          
                                if(temp){
                                    setInputState(temp)
                                }
                                
                            }
                            else {
                                temp={...inputState,[j]:myvalue}
                                setInputState(temp)
                            }
                            
                        }

                        else {
                            temp={...inputState,[j]:myvalue}
                            setInputState(temp)
                        }
                        
                    }
                }}
                onBlur={e=>{
                    updateFilterData(idx,inputState)
                }}

                disabled={j=="result"||j=="priceIncludeVat"||j=="resultIncludeVat"?"disabled":null}
            />
        )
    }
}
//==========================
const [barcodeSetUp,setBarcodeSetUp]=React.useState({
    margin_Top:15,
    margin_Bottom:5,
    margin_Left:20,
    margin_Right:0,
    scale:1.2,
    barcode_height:20,
    barcode_width:1,
})
//==========================
const updateBarcodeSetup=()=>{

    const formKeyArray=Object.keys(barcodeSetUp)

    let tempStr=inputState.printKey

    formKeyArray.map((kk,idxKK)=>{
        if(idxKK==0){
            if(barcodeSetUp[kk]>=0||barcodeSetUp[kk]<0){
                tempStr=tempStr+barcodeSetUp[kk].toString()
            }
            else{
                tempStr=tempStr+"0"
            }
        }
        else{
            if(barcodeSetUp[kk]>=0||barcodeSetUp[kk]<0){
                tempStr=tempStr+"-"+barcodeSetUp[kk].toString()
            }
            else{
                tempStr=tempStr+"-0"
            }
        }
    })
    //console.log('tempStr....................')
    //console.log(tempStr)
    return tempStr
}

//==========================
const renderModalBarcodeSetUp=(idx)=>{
    //mt,mb,ml,mr,sc,h,w
    // 0, 1, 2, 3, 4,5,6
    //15-5-20-0-1.1-20-1   
    const formKeyArray=Object.keys(barcodeSetUp)

    const renderForm=()=>{
        return(
            formKeyArray.map((jj,idxJJ)=>{
                return(
                    <div key={idxJJ} className='w-100' style={{display:"flex",justifyContent:"space-between"}}>
                        <div className='xc4'>
                            {jj}
                        </div>
                        <div className='xc8'>
                            <input
                                type="number"
                                value={barcodeSetUp[jj]}
                                onChange={e=>{
                                    setBarcodeSetUp({
                                        ...barcodeSetUp,
                                        [jj]:e.target.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                )
            })
        )
    }

    return(
    <div className="Modal-background">
        <div className="Modal-box">
            <div className="Modal-header">
                <div>
                    Set Up Barcode
                </div>
            </div>
            <div className="Modal-body">
                <div className ="flex-center-center">
                    {renderForm()}
                </div>
            </div>
            
            <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                            const tempInputState={
                                ...inputState,
                                printKey:updateBarcodeSetup()
                            }

                            setInputState(tempInputState)
                            setShowSetUpBarcode(false)
                            updateFilterData(idx,tempInputState)
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                
                <div>
                    <button
                        onClick={e=>{
                            setShowSetUpBarcode(false)
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>

        </div>
    </div>

    )
}

//==========================
return(
<div 
    className="TableGrid-body" 
    style={{display:"grid",
        gridTemplateColumns:showTable.gridCol,
        gridAutoRows:"minmax(2rem,auto)"
    }}
   
>    
  
    {objKeys.map((j,index2)=>
            tableTemplate[j].showCol
            ?<div 
                key={index2}
                style={{
                    textAlign:"left",
                    width:`${tableTemplate[j].width}px`,
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    backgroundColor:"#e6ffcc"
                }}
                onClick={e=>{
                    /*
                    if(tableTemplate[j].type=="radio"){
                        setInputState({...inputState,[j]:!inputState[j]})
                    }
                    */
                }}
            >

                {
                tableTemplate[j].type=="radio"
                ?renderRadio(idx,inputState[j])
                :renderInput(j)
                }

            </div>
            :null
    )}

    {showSetUpBarcode
     ?renderModalBarcodeSetUp()
     :null
    }


</div>

)
}

LineForm.defaultProps={
  calDigit:100
}





export default LineForm;

